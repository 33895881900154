import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import DepotEntryDetail from "./components/DepotEntryDetail";
import DepotLocationMain from "./index.js";

///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class DepotLocationRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/depotlocation" component={DepotLocationMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={DepotEntryDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={DepotEntryDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default DepotLocationRoute;
