import { normalize, schema } from "normalizr";

// Define a users schema
export const workingSchedule = new schema.Entity(
  "workingSchedules",
  {},
  { idAttribute: "id" },
);

export const arrayOfWorkingSchedules = [workingSchedule];
