import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const CONTRACTORS_FETCH_SUCCESS = "CONTRACTORS_FETCH_SUCCESS";
export const CONTRACTOR_FETCH_SUCCESS = "CONTRACTOR_FETCH_SUCCESS";
export const CONTRACTOR_CREATE_SUCCESS = "CONTRACTOR_CREATE_SUCCESS";
export const CONTRACTOR_UPDATE_SUCCESS = "CONTRACTOR_UPDATE_SUCCESS";
export const CONTRACTOR_DELETE_SUCCESS = "CONTRACTOR_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchContractors = () => {
  return dispatch => {
    return axios
      .get(`/api/contractors/`)
      .then(({ data }) => {
        let contractors = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: CONTRACTORS_FETCH_SUCCESS,
          response: normalize(contractors, schema.arrayOfContractors),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchContractor = contractorId => {
  return dispatch => {
    return axios
      .get(`/api/contractors/` + contractorId)
      .then(({ data }) => {
        let contractor = data;
        let id = contractorId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: CONTRACTOR_FETCH_SUCCESS,
          response: normalize(contractor, schema.contractor),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createContractor = contractor => {
  return dispatch => {
    return axios
      .post("/api/contractors/", contractor)
      .then(({ data }) => {
        // Status looks good
        let contractor = data;
        dispatch({
          type: CONTRACTOR_CREATE_SUCCESS,
          response: normalize(contractor, schema.contractor),
        });
        openNotificationWithIcon(
          "Успешно",
          "Подрадчик успешно добавлен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateContractor = (contractorId, contractor) => {
  return dispatch => {
    return axios
      .put("/api/contractors/" + contractorId, contractor)
      .then(({ data }) => {
        // Status looks good
        let contractor = data;
        dispatch({
          type: CONTRACTOR_UPDATE_SUCCESS,
          response: normalize(contractor, schema.contractor),
        });
        openNotificationWithIcon(
          "Успешно",
          "Подрядчик успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteContractor = contractorId => {
  return dispatch => {
    return axios
      .delete("/api/contractors/" + contractorId)
      .then(({ data }) => {
        let contractor = data;
        dispatch({
          type: CONTRACTOR_DELETE_SUCCESS,
          contractorId: contractorId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Подрадчик успешно удален",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Невожможно удалить так есть записи связанные с этим подрядчиком";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
