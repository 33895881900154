import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  deleteProdDepotLocation,
  fetchProdDepotLocations,
} from "../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../data/prodDepotLocations/reducer";
import ProdDepotEntryTable from "./components/ProdDepotEntryTable";

function ProdDepotLocationMain(props) {
  const { history, prodDepotLocations, fetchProdDepotLocations } = props;

  const [loading, setLoading] = useState(() => {
    return prodDepotLocations.length <= 0;
  });

  const onProdDepotCreate = () => {
    history.push(`/prodDepotLocation/new`);
  };

  const handleEditClick = contractor => {
    history.push(`/prodDepotLocation/` + contractor.id);
  };

  const onRemove = value => {
    const { deleteProdDepotLocation } = props;
    deleteProdDepotLocation(value.id);
  };

  useEffect(() => {
    fetchProdDepotLocations().then(depot => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Склады готовой продукции</h2>
        <Button type="primary" onClick={onProdDepotCreate}>
          Создать
        </Button>
      </div>
      <br />
      <ProdDepotEntryTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        prodDepotLocations={prodDepotLocations}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    prodDepotLocations: getAllProdDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
    deleteProdDepotLocation: id => dispatch(deleteProdDepotLocation(id)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdDepotLocationMain),
);
