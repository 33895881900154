import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";

const openedPattern = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_PATTERN":
      return action.patternId;
    default:
      return state;
  }
};

const showPatternModal = (state = false, action) => {
  switch (action.type) {
    case "SET_SHOW_PATTERN_MODAL":
      return action.showPatternModal;

    default:
      return state;
  }
};

const openedSpread = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_SPREAD":
      return action.spreadId;
    default:
      return state;
  }
};

const openedBom = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_BOM":
      return action.bomId;
    default:
      return state;
  }
};

const showSpreadModal = (state = false, action) => {
  switch (action.type) {
    case "SET_SHOW_SPREAD_MODAL":
      return action.showSpreadModal;

    default:
      return state;
  }
};

const selectedSpread = (state = "", action) => {
  switch (action.type) {
    case "SET_SELECTED_SPREAD":
      return action.spreadId;
    case "RESET_PRODUCT":
      return "";
    default:
      return state;
  }
};

const selectedSpreadTab = (state = {}, action) => {
  switch (action.type) {
    case "SET_SELECTED_SPREAD_TAB":
      return { ...state, [action.bomId]: action.selectedSpreadId };
    default:
      return state;
  }
};

const selectedBom = (state = "", action) => {
  switch (action.type) {
    case "SET_SELECTED_BOM":
      return action.bomId;
    case "RESET_PRODUCT":
      return "";
    default:
      return state;
  }
};

const openedProduct = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_PRODUCT":
      return action.productId;
    default:
      return state;
  }
};

const showBomModal = (state = false, action) => {
  switch (action.type) {
    case "SET_SHOW_BOM_MODAL":
      return action.showBomModal;

    default:
      return state;
  }
};

const specName = (state = "", action) => {
  switch (action.type) {
    case "SET_SPEC_NAME":
      return action.name;
    case "RESET_SPEC_FORM":
      return "";

    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedPattern,
  showPatternModal,
  openedSpread,
  showSpreadModal,
  showBomModal,
  openedBom,
  selectedBom,
  selectedSpread,
  selectedSpreadTab,
  data: dataReducer,
  specName,
});
