import { Button, Input, Select as SelectAntD, Spin } from "antd";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  createDepotLocation,
  fetchDepotLocation,
  updateDepotLocation,
} from "../../../../data/depotLocations/actions";
import { getDepotLocation } from "../../../../data/depotLocations/reducer";
import { depotLocation } from "../../../../data/schema";
import { setOpenedDepotLocationId } from "../../actions";
import FormValidator from "./FormValidator";

const Option = SelectAntD.Option;

let validator = new FormValidator([
  {
    field: "name",
    method: "isEmpty",
    validWhen: false,
    message: "Введите название склада",
  },
]);

function DepotEntryDetail(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    match,
    fetchDepotLocation,
    setOpenedDepotLocationId,
    createDepotLocation,
    updateDepotLocation,
  } = props;
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [isInEditState, toggleEditState] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    user_id: undefined,
  });
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const assignedUser = props.openedDepotLocation
    ? props.openedDepotLocation.user
    : null;

  const onDepotCreate = () => {
    history.push(`/depotlocation/new`);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/depotlocation/` + contractor.id);
  };

  const onRemove = value => {
    const { deleteEmployee } = this.props;
    deleteEmployee(value.id);
    //history.push(`/product-admin/`)
  };

  const createDepotLocationFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var depot = {
      name: formData.name,
      user_id: formData.user_id === undefined ? null : formData.user_id,
    };
    return depot;
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const validated = validator.validate(formData);
    setValidation(validated);

    if (validated.isValid) {
      setSaving(true);
      var depotLocation = createDepotLocationFromValues();
      //Handle update
      if (match.params.id) {
        return updateDepotLocation(match.params.id, depotLocation).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            history.push(`/depotlocation/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
      //Handle create
      else {
        return createDepotLocation(depotLocation).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            history.push(`/depotlocation/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
    }
  };

  const handleNameChange = e => {
    let value = e.target.value;
    const name = e.target.name;

    setFormData(prevData => {
      return { ...prevData, name: value };
    });
  };

  const getUsers = async input => {
    setIsFetchingUsers(true);
    return axios(`/api/users/search?q=${input}`).then(
      ({ data }) => {
        setIsFetchingUsers(false);
        setFetchedUsers(data);
      },
      () => setIsFetchingUsers(false),
    );
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(formData));
    }
  }, [formData]);

  useEffect(() => {
    setOpenedDepotLocationId("");
    if (match.params.id) {
      fetchDepotLocation(match.params.id).then(depot => {
        setOpenedDepotLocationId(depot.id);
        toggleEditState(false);
        setFetchedUsers(depot.user ? [depot.user] : []);
        setFormData(prev => {
          return {
            ...prev,
            name: depot.name,
            user_id: depot.assignedUser_id ? depot.assignedUser_id : undefined,
          };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  let validated = validation;

  return (
    <div className="mt-paper">
      <div>
        <h2>Складское помещение</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={handleSubmit} loading={isSaving}>
          Сохранить
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={handleSubmit} loading={isSaving}>
          Создать
        </Button>
      )}
      {match.params.id && !isInEditState && (
        <Button onClick={() => toggleEditState(true)}>Изменить</Button>
      )}
      <br />
      &nbsp;
      {/* <EmployeeForm teamOptions={teamOptions} enableReinitialize initialValues={{...openedEmployee, team:teamId }} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
      <div
        style={{ marginBottom: 15 }}
        className={validated.name.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="price">
          Имя
        </label>
        <Input
          name="first_name"
          placeholder="Имя"
          //onChange={this.handleInputChange}
          value={formData.name}
          onChange={handleNameChange}
          disabled={!isInEditState}
        />
        <span className="help-block">{validated.name.message}</span>
      </div>
      <label className="mt-label">Заведующий складом</label>
      <SelectAntD
        name="user"
        placeholder={isInEditState ? "Печатайте для поиска..." : "Не выбран"}
        value={formData.user_id}
        showSearch
        allowClear
        style={{
          width: "100%",
        }}
        notFoundContent={isFetchingUsers ? <Spin size="small" /> : null}
        onSearch={value => getUsers(value)}
        filterOption={false}
        disabled={!isInEditState}
        onChange={val => {
          setFormData({
            ...formData,
            user_id: val,
          });
        }}
      >
        {fetchedUsers.map(user => (
          <Option value={user.id} key={user.id}>
            {user.last_name} {user.first_name} [{user.username}]
          </Option>
        ))}
      </SelectAntD>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedDepotLocation: getDepotLocation(
      state,
      state.scenes.depotLocations.openedDepotLocationId,
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDepotLocation: id => dispatch(fetchDepotLocation(id)),
    setOpenedDepotLocationId: id => dispatch(setOpenedDepotLocationId(id)),
    createDepotLocation: location => dispatch(createDepotLocation(location)),
    updateDepotLocation: (id, entry) =>
      dispatch(updateDepotLocation(id, entry)),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DepotEntryDetail),
);
