import { normalize, schema } from "normalizr";

// Define a users schema
export const contractor = new schema.Entity(
  "contractors",
  {},
  { idAttribute: "id" },
);

export const arrayOfContractors = [contractor];
