import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Card, Divider, Empty, Row, Table } from "antd";
import React from "react";
import { connect } from "react-redux";

import {
  getOpenedCuttingOrder,
  getProdBomsByOpenedOrderWithSpreadsPatternsEntries,
} from "../../../selectors";

function CuttingPassportInfo(props) {
  const { openedOrder, prodBoms, passportEntry } = props;

  const values = passportEntry
    ? {
        fabricConsumptions: prodBoms.map(prodBom =>
          prodBom.prodSpreads
            .filter(prodSpread => prodSpread.printOperations)
            .map(prodSpread =>
              passportEntry.fabricConsumptions
                .filter(
                  fabricConsumption =>
                    fabricConsumption.prodBom_id === prodBom.id &&
                    fabricConsumption.prodSpread_id === prodSpread.id,
                )
                .map(fabricConsumption => ({
                  id: fabricConsumption.id,
                  prodSpreadId: fabricConsumption.prodSpread_id,
                  prodBomId: fabricConsumption.prodBom_id,
                  fabric: fabricConsumption.fabric,
                  depotLocation: fabricConsumption.depotLocation,
                  weight: fabricConsumption.weight,
                  lineItems: fabricConsumption.lineItems.map(lineItem => ({
                    sizeId: lineItem.size.id,
                    sizeName: lineItem.size.name,
                    quantity: lineItem.quantity,
                  })),
                })),
            ),
        ),
      }
    : null;

  return (
    <Card size={"small"} title={"Раскроный паспорт"}>
      {!passportEntry && <Empty description={"Паспорт не принят"} />}
      {passportEntry && (
        <div>
          {prodBoms.map((prodBom, prodBomIndex) => (
            <div key={prodBomIndex}>
              <Row>
                <h3>Часть: {prodBom.name}</h3>
              </Row>
              {prodBom.prodSpreads
                .filter(prodSpread => prodSpread.printOperations)
                .map((prodSpread, prodSpreadIndex) => (
                  <div key={prodSpreadIndex}>
                    <br />
                    <h4>Расход материала и количество скроенных:</h4>
                    <Table
                      size={"small"}
                      pagination={false}
                      bordered
                      width={"100%"}
                      rowKey={"key"}
                      columns={[
                        {
                          title: "Материал",
                          dataIndex: "fabric",
                          key: "fabric",
                          width: "auto",
                          render(value) {
                            return {
                              children: (
                                <span>
                                  <span>{value.name}</span>
                                </span>
                              ),
                            };
                          },
                        },
                        ...openedOrder.lineItems.map(
                          (lineItem, lineItemIndex) => ({
                            title: lineItem.size.name,
                            dataIndex: "lineItems",
                            key: "sizeName" + lineItemIndex,
                            align: "center",
                            width: "auto",
                            render(value) {
                              return value.find(
                                item => item.sizeId === lineItem.size.id,
                              ).quantity;
                            },
                          }),
                        ),
                        {
                          title: "Всего скроено",
                          key: "lineItemsSum",
                          align: "center",
                          render(value, record) {
                            return record.lineItems.reduce(
                              (acc, curr) => acc + curr.quantity,
                              0,
                            );
                          },
                        },
                        {
                          title: "Вес (кг)",
                          dataIndex: "weight",
                          key: "weight",
                          align: "center",
                          width: "auto",
                          render(value, record, index) {
                            return values.fabricConsumptions[prodBomIndex][
                              prodSpreadIndex
                            ][index].weight;
                          },
                        },
                        {
                          title: "Склад",
                          dataIndex: "depotLocation",
                          key: "depotLocation",
                          align: "center",
                          width: 250,
                          render(value) {
                            return {
                              props: {
                                style: { height: "38px" },
                              },
                              children: value ? value.name : null,
                            };
                          },
                        },
                      ]}
                      dataSource={values.fabricConsumptions[prodBomIndex][
                        prodSpreadIndex
                      ].map((value, key) => ({
                        ...value,
                        key,
                      }))}
                    />
                  </div>
                ))}
              {prodBomIndex !== prodBoms.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

const mapStateToProps = state => {
  const openedOrder = getOpenedCuttingOrder(state);
  return {
    showModal: state.scenes.cutting.showCuttingSubmitPassportModal,
    prodBoms: getProdBomsByOpenedOrderWithSpreadsPatternsEntries(state),
    openedOrder: openedOrder,
    passportEntry: openedOrder.cuttingPassportEntry,
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    null,
  )(CuttingPassportInfo),
);
