import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ProdStockDetail from "./components/ProdStockDetail";
import ProdStockMain from "./index.js";

class ProdStockRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/prodstock" component={ProdStockMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={ProdStockDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={ProdStockDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default ProdStockRoute;
