import { combineReducers } from "redux";

const bomBuffer = (state = null, action) => {
  switch (action.type) {
    case "COPY_BOM":
      return action.bomData;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  bomBuffer,
});
