export const setOpenedProduct = productId => ({
  type: "SET_OPENED_PRODUCT",
  productId,
});

export const setOpenedPattern = patternId => ({
  type: "SET_OPENED_PATTERN",
  patternId,
});

export const setShowPrintCodeModal = show => ({
  type: "SET_SHOW_PRINT_CODE_MODAL",
  show,
});
