"use strict";

const { object, ref, array } = require("yup");
const dateValidator = require("./dateValidator");
const numberValidator = require("./numberValidator");
const timeStringValidator = require("./timeStringValidator");

const workingScheduleValidator = object({
  weight: numberValidator({
    default: 0,
    integer: true,
    message: "Неверный формат",
  }),

  // January is 0, February is 1, and so on.
  month: numberValidator({
    min: 0,
    max: 11,
    integer: true,
    message: "Неверный формат",
  }),

  // Sunday is 0, Monday is 1, and so on.
  day_of_week: numberValidator({
    min: 0,
    max: 6,
    integer: true,
    message: "Неверный формат",
  }),

  // From 1 to 31
  day_of_month: numberValidator({
    min: 1,
    max: 31,
    integer: true,
    message: "Неверный формат",
  }),

  start_date: dateValidator({
    message: "Неверный формат",
  }),

  end_date: dateValidator({
    min: ref("start_date"),
    message: "Неверный формат",
  }),

  start_time: timeStringValidator({
    max: ref("end_time"),
    message: "Неверный формат",
  }),

  end_time: timeStringValidator({
    min: ref("start_time"),
    message: "Неверный формат",
  }),
});

const workingScheduleWeightValidator = object({
  id: numberValidator({
    min: 1,
    integer: true,
    required: true,
    message: "Неверный формат",
  }),

  weight: numberValidator({
    integer: true,
    required: true,
    message: "Неверный формат",
  }),
});

const workingScheduleWeightListValidator = array()
  .min(1)
  .ensure()
  .of(workingScheduleWeightValidator);

module.exports = {
  workingScheduleValidator,
  workingScheduleWeightValidator,
  workingScheduleWeightListValidator,
};
