import { combineReducers } from "redux";

import {
  ME_FROM_TOKEN_FAILURE,
  ME_FROM_TOKEN_SUCCESS,
  RESET_TOKEN,
  SIGNIN_USER_SUCCESS,
  USERS_FETCH_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_FETCH_SUCCESS,
  USER_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case USERS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.users,
      };
    case USER_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.users,
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.users,
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.users,
      };
    case USER_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.userId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case USERS_FETCH_SUCCESS:
      return action.response.result;
    case USER_CREATE_SUCCESS:
      return [...state, action.response.result];
    case USER_DELETE_SUCCESS:
      return [...state.filter(id => id != action.userId)];
    default:
      return state;
  }
};

let INITIAL_STATE = { user: null, status: null, error: null, loading: false };

const user = (state = INITIAL_STATE, action) => {
  let error;
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: //return authenticated user,  make loading = false and status = authenticated
      return {
        ...state,
        user: action.data.user,
        status: "authenticated",
        error: null,
        loading: false,
      }; //<-- authenticated
      // case 'LOGOUT_USER':
      //     return {...state, user:null, status:'logout', error:null, loading: false};
      // case 'RESET_USER':// reset authenticated user to initial state
      return {
        ...state,
        user: null,
        status: null,
        error: null,
        loading: false,
      };
    // case ME_FROM_TOKEN:// loading currentUser("me") from jwttoken in local/session storage storage,
    //     return { ...state, user: null, status: 'storage', error: null, loading: true };
    case ME_FROM_TOKEN_SUCCESS: //return user, status = authenticated and make loading = false
      return {
        ...state,
        user: action.data.user,
        status: "authenticated",
        error: null,
        loading: false,
      }; //<-- authenticated
    case ME_FROM_TOKEN_FAILURE: // return error and make loading = false
      error = action.data || { message: action.data.message }; //2nd one is network or server down errors
      return {
        ...state,
        user: null,
        status: "storage",
        error: error,
        loading: false,
      };
    case RESET_TOKEN: // remove token from storage make loading = false
      return {
        ...state,
        user: null,
        status: "storage",
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
  user,
});

export const getAllUsers = state => {
  return state.data.users.allIds.map(id => state.data.users.byId[id]);
};

export const getUser = (state, id) => {
  return state.data.users.byId[id];
};
