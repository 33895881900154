import {Button, DatePicker, notification, Select, Spin} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import XLSX from "xlsx";
import { fetchClients } from "../../data/clients/actions";
import { fetchShipments } from "../../data/shipmentReports/actions";
import ShipmentReportTable from "./components/ShipmentReportTable";
import axios from "axios";

const RangePicker = DatePicker.RangePicker;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

function ShipmentReport(props) {
  const { shipmentsReport, fetchShipments, fetchClients, clients } = props;

  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [range, setRange] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [isSearchingClients, setIsSearchingClients] = useState(false)
  const [foundClients, setFoundClients] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchShipments(
        (range && range.length !== 0) ? range[0].format("YYYY-MM-DD HH:mm ZZ") : undefined,
        (range && range.length !== 0) ? range[1].format("YYYY-MM-DD HH:mm ZZ") : undefined,
        clientId ? clientId : null
    ).then(
      () => {
        setLoading(false);
      },
      () => setLoading(false),
    );
  }, [range, clientId]);

  useEffect(() => {
    fetchClients()
  }, [])

  const generateExcelTable = async rows => {
    try {
      const headers = ["Продукт", "Название продукта", "Количество", "Сумма"];

      const data = rows.map(row => [
        "PR-" + row.id,
        row.name,
        row.quantity,
        row.sum,
      ]);

      data.unshift(headers);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Уведомления");

      XLSX.writeFile(workbook, "Отчёт.xlsx");
    } catch (error) {
      openNotificationWithIcon("Ошибка", error.message, "error");
    }
  };

  const searchClient = term => {
        setIsSearchingClients(true)
        axios.get(`/api/clients?q=${term}`).then(
            ({ data }) => {
              setIsSearchingClients(false)
              setFoundClients(data)
            },
            () => {
              setIsSearchingClients(false)
            },
        );
      };

  const handleExport = async () => {
    setIsExporting(true);
    try {
      await generateExcelTable(shipmentsReport);
      setIsExporting(false);
    } catch (e) {
      setIsExporting(false);
    }
  };

  return (
    <div>
      <div>
        <h2>Отчёт о продажах</h2>
        <span>
          <RangePicker
            value={range}
            onChange={e => setRange(e)}
            locale={locale}
          />
          <Select
              style={{marginLeft: "10px", width: "400px"}}
              allowClear={true}
              showSearch
              placeholder="Клиент"
              filterOption={false}
              notFoundContent={
                isSearchingClients ? (
                    <Spin size="small" />
                ) : null
              }
              value={clientId}
              onSearch={e => searchClient(e)}
              onChange={id => setClientId(id)}
          >
            {foundClients.map(client => (
                <Select.Option key={client.id} value={client.id}>
                  {client.name}
                </Select.Option>
              )
            )}
          </Select>
          <Button
            onClick={handleExport}
            style={{ float: "right" }}
            loading={isExporting}
          >
            Экспортировать в Excel
          </Button>
        </span>
      </div>
      <br />
      <ShipmentReportTable
        loading={loading}
        shipmentsReport={shipmentsReport}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: state.data.clients.all,
    shipmentsReport: state.data.shipmentsReport,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchShipments: (fromDate, toDate, clientId) =>
      dispatch(fetchShipments(fromDate, toDate, clientId)),
    fetchClients: () => dispatch(fetchClients())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ShipmentReport),
);
