import { Select } from "antd";
import React from "react";

import { FormField } from "./FormField";

export function FormSelect({ name, label, disabled, ...props }) {
  return (
    <FormField
      name={name}
      label={label}
      render={({ field, form: { isSubmitting, handleBlur, handleChange } }) => (
        <Select
          {...props}
          {...field}
          onBlur={handleBlur(name)}
          onChange={handleChange(name)}
          disabled={disabled || isSubmitting}
        />
      )}
    />
  );
}
