import React from "react";
import ReactSelect from "react-select";
import ReactAsyncSelect from "react-select/lib/Async";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: base => ({
    ...base,
    paddingTop: 1,
    paddingBottom: 1,
  }),
};

function AsyncSelect({ ...props }) {
  let combinedStyles = { ...customStyles, ...props.styles };

  return (
    <ReactAsyncSelect
      noOptionsMessage={() => {
        return "Нет данных";
      }}
      placeholder={"Печатайте для поиска..."}
      {...props}
      styles={combinedStyles}
    />
  );
}

export default AsyncSelect;
