import moment from "moment";
import { createSelector } from "reselect";

export const getProdEntries = state =>
  state.scenes.productionReport.data.prodEntriesProductionReport;
export const getCuttingEntries = state =>
  state.scenes.productionReport.data.cuttingEntriesProductionReport;
export const getDates = state =>
  state.scenes.productionReport.data.datesProductionReport;
export const getPartition = state => state.scenes.productionReport.partition;

export const getProductionReport = createSelector(
  [getDates, getProdEntries, getCuttingEntries, getPartition],
  (dates, prodEntries, cuttingEntries, partition) => {
    if (partition === "days")
      return Object.values(dates).map((date, index) => ({
        name: moment(date).format("DD"),
        Выкроено: cuttingEntries[index],
        "Отдано в цех": prodEntries[index],
      }));
    else {
      let result = [];
      for (let i = 0; i < dates.length - 1; i++) {
        let next =
          i === dates.length - 2
            ? moment.utc(dates[i + 1]).format("DD")
            : moment
                .utc(dates[i + 1])
                .subtract(1, "days")
                .format("DD");
        result.push({
          name: moment(dates[i]).format("DD") + " - " + next,
          Выкроено: cuttingEntries[i],
          "Отдано в цех": prodEntries[i],
        });
      }
      return result;
    }
  },
);
