import { Col, Row } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";

import { createWorkingSchedule } from "../../../../data/workingSchedules/actions";
import {
  WorkingScheduleType,
  isValidWorkingScheduleType,
} from "../../helpers/WorkingScheduleTypeHelpers";
import { WorkingScheduleForm } from "../WorkingScheduleForm";

const enhancer = connect();

function WorkingScheduleAdd({ history, dispatch, match: { params } }) {
  const type = isValidWorkingScheduleType(params.type)
    ? params.type
    : WorkingScheduleType.CommonWorkingHours;

  const [isSaving, setSaving] = useState(false);

  return (
    <div className="mt-paper">
      <div>
        <h2>График работ</h2>
      </div>

      <Row gutter={16}>
        <Col span={12}>
          <WorkingScheduleForm
            key={type}
            type={type}
            onTypeChange={nextType =>
              history.push(`/workingschedule/add/${nextType}`)
            }
            isSaving={isSaving}
            onSubmit={(values, { setSubmitting }) => {
              setSaving(true);
              Promise.resolve(dispatch(createWorkingSchedule(values)))
                .then(() => {
                  setSubmitting(false);
                  setSaving(false);
                  history.push("/workingschedule");
                })
                .catch(error => {
                  console.error(error);
                  setSaving(false);
                  setSubmitting(false);
                });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default enhancer(WorkingScheduleAdd);
