import { Table } from "antd";
import React from "react";

export function TableOfCutFabrics(props) {
  const { data, openedOrder } = props;

  return (
    <Table
      size={"small"}
      pagination={false}
      bordered={true}
      columns={[
        {
          title: "Часть",
          dataIndex: "prodBomName",
          key: "prodBomName",
          align: "center",
          width: 70,
        },
        ...openedOrder.lineItems.map((lineItem, lineItemIndex) => ({
          title: lineItem.size.name,
          key: "sizeName" + lineItemIndex,
          dataIndex: "lineItems",
          align: "center",
          width: 70,
          render: (value, record, index) => {
            return value.find(item => item.sizeId === lineItem.size.id)
              .quantity;
          },
        })),
        {
          title: "Всего скроено",
          key: "lineItemsSum",
          align: "center",
          width: 70,
          render(value, record, index) {
            return record.lineItems.reduce(
              (acc, curr) => acc + curr.quantity,
              0,
            );
          },
        },
      ]}
      dataSource={data}
    />
  );
}
