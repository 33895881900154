import { DatePicker, Select, Skeleton, Spin } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { fetchPeriods } from "../../data/periods/actions";
import { getAllPeriods } from "../../data/periods/reducer";
import { fetchReportByPeriods } from "./data/actions";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class StatisticsByPeriods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingPeriods: true,
      isFetchingReport: true,
      selectedPeriods: [],
    };
  }

  componentDidMount() {
    this.props.fetchPeriods().then(
      periods => {
        this.setState({ isFetchingPeriods: false });
        const lastPeriod = periods[periods.length - 1];
        this.setState({
          selectedPeriods: [lastPeriod.id],
        });
        this.props.fetchReportByPeriods([lastPeriod.id]).then(() =>
          this.setState(
            {
              isFetchingReport: false,
            },
            () => this.setState({ isFetchingReport: false }),
          ),
        );
      },
      () => this.setState({ isFetchingPeriods: false }),
    );
  }

  fetchReport() {
    this.setState({ isFetchingReport: true });
    this.props.fetchReportByPeriods(this.state.selectedPeriods).then(
      () => {
        this.setState({ isFetchingReport: false });
      },
      () => {
        this.setState({ isFetchingReport: false });
      },
    );
  }

  getSumByOperationTypes = (data, operationTypes) =>
    data
      .filter(dataRow => operationTypes.includes(dataRow.operationType_id))
      .reduce((acc, curr) => acc + curr.sum, 0);

  render() {
    return this.state.isFetchingPeriods ? (
      <Spin>
        <div style={{ width: "100%", height: "300px" }} />
      </Spin>
    ) : (
      <div>
        <Select
          mode={"multiple"}
          value={this.state.selectedPeriods}
          style={{ width: "100%" }}
          onChange={selectedPeriods => {
            this.setState({ selectedPeriods }, this.fetchReport);
          }}
        >
          {this.props.periods.map(period => (
            <Option key={period.id} value={period.id}>
              {period.name}
            </Option>
          ))}
        </Select>
        <br />
        {!this.state.isFetchingReport && (
          <BarChart
            width={1000}
            height={400}
            data={this.props.data.map(row => ({
              name: this.props.periods.find(
                period => period.id === row.period_id,
              ).name,
              Выкроено: row.totalCuttingQuantity,
              "Отдано в цех": row.totalSewingQuantity,
            }))}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Выкроено" fill="#1890ff" />
            <Bar dataKey="Отдано в цех" fill="#FF5722" />
          </BarChart>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.scenes.productionReportByPeriods.data,
    periods: getAllPeriods(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPeriods: () => dispatch(fetchPeriods()),
    fetchReportByPeriods: periods => dispatch(fetchReportByPeriods(periods)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StatisticsByPeriods),
);
