import {Button, Input, notification, Table} from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import FormValidator from "../../../FormValidator";
import { setOpenedContractorId } from "../../actions";
import {
  createContractor,
  fetchContractor,
  updateContractor,
} from "../../data/contractors/actions";
import { getContractor } from "../../data/contractors/reducer";
import ShipmentStatusTypes from "@app/shared/constants/shipmentStatusTypes";
import {contractor} from "../../data/contractors/schema";
import moment from "moment";
import XLSX from "xlsx";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

let validator = new FormValidator([
  {
    field: "company_name",
    method: "isEmpty",
    validWhen: false,
    message: "Введите название компании",
  },
  {
    field: "address",
    method: "isEmpty",
    validWhen: false,
    message: "Введите адрес",
  },
  {
    field: "contact_name",
    method: "isEmpty",
    validWhen: false,
    message: "Введите имя контакта",
  },
]);

function ContractorDetail(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    match,
    fetchContractor,
    setOpenedContractorId,
    createContractor,
    updateContractor,
    roles
  } = props;
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [isInEditState, toggleEditState] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [currentDebt, setCurrentDebt] = useState(null);
  const [fabDepotEntries, setFabDepotEntries] = useState(null);
  const [contractorPayments, setContractorPayments] = useState(null);
  const [fabricReturns, setFabricReturns] = useState(null);
  const [formData, setFormData] = useState({
    company_name: "",
    address: "",
    contact_name: "",
    contact_phone1: "",
    contact_phone2: "",
  });

  const createContractorFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var contractor = {
      company_name: formData.company_name,
      address: formData.address,
      contact_name: formData.contact_name,
      contact_phone1: formData.contact_phone1,
      contact_phone2: formData.contact_phone2,
    };
    return contractor;
  };

  const generateReconciliationAct = () => {
    try {
      const headers = [
        "Дата отгрузки",
        "Номер прихода",
        "Материал",
        "Вес (кг)",
        "Номер партии",
        "Количество рулонов",
        "Стоимость поставки",
        "Сумма оплаты"
      ];

      let data = [
        ...fabDepotEntries.map(fabDepotEntry => [
          moment(fabDepotEntry.entry_date),
          fabDepotEntry.id,
          fabDepotEntry.fabric.name,
          fabDepotEntry.weight,
          fabDepotEntry.batchNumber,
          fabDepotEntry.rolls,
          {v: Number(fabDepotEntry.price * fabDepotEntry.weight).toFixed(2), z: "$0.00", w: undefined},
          ""
        ]),
        ...contractorPayments.map(contractorPayment => [
          moment(contractorPayment.transferred_at),
          "Оплата",
          "",
          "",
          "",
          "",
          "",
          contractorPayment.amount
        ]),
        ...fabricReturns.map(fabricReturn => [
          moment(fabricReturn.transferred_at),
          "Возврат",
          fabricReturn.fabDepotEntry.fabric.name,
          fabricReturn.weight,
          fabricReturn.fabDepotEntry.batchNumber,
          "",
          "",
          Number(fabricReturn.price * fabricReturn.weight).toFixed(2)
        ])
      ]

      data.sort((a, b) => {
        if(a[0] < b[0]) {
          return -1;
        } else return 1;
      })

      const combinedTotalPrice = fabDepotEntries.reduce((acc, item) => acc + (item.price * item.weight), 0);
      const totalFabricReturnsPayments = fabricReturns.reduce((acc, item) => acc + (item.price * item.weight), 0);
      const totalPaymentAmount = contractorPayments.reduce((acc, item) => acc + item.amount, 0);
      const diff = combinedTotalPrice - totalPaymentAmount - totalFabricReturnsPayments;

      data = data.map(([date, ...rest]) => [date.format("DD/MM/YYYY"), ...rest])
      data.push(["", "", "", "", "", "", "", ""])
      data.push(["", "", "", "", "", "",
        combinedTotalPrice,
        totalPaymentAmount + totalFabricReturnsPayments
      ])
      data.push(["", "", "", "", "", "", "", ""])
      data.push([`${diff > 0 ? "Долг" : "Предоплата"} на ${moment().format("DD/MM/YYYY")}`, "", "", "", "", "",
        diff > 0 ? diff : -diff,
        ""])
      data.unshift(headers);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      worksheet['!cols'] = [
        {wch:17},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:15}
      ]

      XLSX.utils.book_append_sheet(workbook, worksheet, "Акт сверки");

      XLSX.writeFile(workbook, `Акт сверки ${formData.company_name}.xlsx`);
    } catch (error) {
      openNotificationWithIcon("Ошибка", error.message, "error");
    }
  }

  const handleSubmit = () => {
    setSubmitted(true);

    const validated = validator.validate(formData);
    setValidation(validated);

    if (validated.isValid) {
      setSaving(true);
      var contractor = createContractorFromValues();
      //Handle update
      if (match.params.id) {
        return updateContractor(match.params.id, contractor).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            history.push(`/contractor/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
      //Handle create
      else {
        return createContractor(contractor).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            history.push(`/contractor/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
    }
  };

  const handleRedirectToFabDepotEntry = (e, id) => {
    e.preventDefault();
    history.push(`/fabdepot/${id}`);
  };

  const handleRedirectToFabric = (e, id) => {
    e.preventDefault();
    history.push(`/fabric-admin/${id}`);
  };

  const handleInputChange = e => {
    let value = e.target.value;
    const name = e.target.name;

    setFormData(prevData => {
      return { ...prevData, [name]: value };
    });
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(formData));
    }
  }, [formData]);

  useEffect(() => {
    setOpenedContractorId("");
    if (match.params.id) {
      fetchContractor(match.params.id).then(contractor => {
        setOpenedContractorId(contractor.id);
        toggleEditState(false);

        setFormData(prev => {
          setCurrentDebt(contractor.currentDebt);
          setFabDepotEntries(contractor.fabDepotEntries);
          setContractorPayments(contractor.contractorPayments);
          setFabricReturns(contractor.fabricReturns);
          return {
            ...prev,
            company_name: contractor.company_name,
            address: contractor.address,
            contact_name: contractor.contact_name,
            contact_phone1: contractor.contact_phone1,
            contact_phone2: contractor.contact_phone2,
          };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  const hasAccessToBalance = roles.find(role => role.id === 7 || role.id === 1);
  let validated = validation;

  return (
    <div className="mt-paper">
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
      >
        <h2>Подрядчик</h2>
        {hasAccessToBalance && (
            <span>
              <strong>Текущий баланс:</strong>{" "}
              <span
                  style={{
                    color: currentDebt
                        ? currentDebt >= -0.0001
                            ? "green"
                            : "red"
                        : "green",
                  }}
              >
                {currentDebt ? Number(currentDebt).toFixed(2) : 0}$
              </span>
            </span>
        )}
      </div>
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
      >
        {match.params.id && isInEditState && (
          <Button type="primary" onClick={handleSubmit} loading={isSaving}>
            Сохранить
          </Button>
        )}
        {match.params.id == undefined && (
          <Button type="primary" onClick={handleSubmit} loading={isSaving}>
            Создать
          </Button>
        )}
        {match.params.id && !isInEditState && (
          <Button onClick={() => toggleEditState(true)}>Изменить</Button>
        )}
        <Button
            onClick={generateReconciliationAct}
            disabled={!fabDepotEntries && !contractorPayments}
        >
          Сгенерировать акт сверки
        </Button>
      </div>
      &nbsp;
      {/* <EmployeeForm teamOptions={teamOptions} enableReinitialize initialValues={{...openedEmployee, team:teamId }} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
      <div
        style={{ marginBottom: 15 }}
        className={validated.company_name.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="price">
          Название компании
        </label>
        <Input
          name="company_name"
          placeholder="Название компании"
          value={formData.company_name}
          onChange={handleInputChange}
          disabled={!isInEditState}
        />
        <span className="help-block">{validated.company_name.message}</span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validated.address.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="price">
          Адрес
        </label>
        <Input
          name="address"
          placeholder="Адрес"
          value={formData.address}
          onChange={handleInputChange}
          disabled={!isInEditState}
        />
        <span className="help-block">{validated.address.message}</span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validated.contact_name.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="price">
          Имя контакта
        </label>
        <Input
          name="contact_name"
          placeholder="Имя контакта"
          value={formData.contact_name}
          onChange={handleInputChange}
          disabled={!isInEditState}
        />
        <span className="help-block">{validated.contact_name.message}</span>
      </div>
      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="price">
          Номер телефона 1
        </label>
        <Input
          name="contact_phone1"
          placeholder="Номер телефона 1"
          value={formData.contact_phone1}
          onChange={handleInputChange}
          disabled={!isInEditState}
        />
      </div>
      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="price">
          Номер телефона 2
        </label>
        <Input
          name="contact_phone2"
          placeholder="Номер телефона 2"
          value={formData.contact_phone2}
          onChange={handleInputChange}
          disabled={!isInEditState}
        />
      </div>
      {fabDepotEntries && (
          <Table
              dataSource={fabDepotEntries.map(fabDepotEntry => ({
                id: fabDepotEntry.id,
                price: fabDepotEntry.price,
                fabric: fabDepotEntry.fabric,
                weight: fabDepotEntry.weight,
                rolls: fabDepotEntry.rolls,
              }))}
              size={"small"}
              bordered
              columns={[
                {
                  title: "Приход",
                  dataIndex: "id",
                  key: "id",
                  render(text, record) {
                    return (
                        <a onClick={e => handleRedirectToFabDepotEntry(e, record.id)}>
                          FDE-{record.id}
                        </a>
                    );
                  },
                },
                {
                  title: "Материал",
                  dataIndex: "fabric",
                  key: "fabric",
                  render(fabric) {
                    return (
                        <>
                          {fabric.name}
                          {" "}
                          [<a onClick={e => handleRedirectToFabric(e, fabric.id)}>
                            FAB-{fabric.id}
                          </a>]
                        </>
                    );
                  },
                },
                {
                  title: "Количество рулонов",
                  dataIndex: "rolls",
                  key: "rolls",
                  render(text) {
                    return `${text}`;
                  },
                },
                {
                  title: "Цена",
                  dataIndex: "price",
                  key: "price",
                  render(text) {
                    return `${text}$`;
                  },
                },
                {
                  title: "Вес",
                  dataIndex: "weight",
                  key: "weight",
                  render(text) {
                    return `${text} кг`;
                  },
                },
              ]}
          />
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    roles: state.data.users.user.user.roles,
    openedContractor: getContractor(
      state,
      state.scenes.contractor.openedContractorId,
    ),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchContractor: id => dispatch(fetchContractor(id)),
    setOpenedContractorId: id => dispatch(setOpenedContractorId(id)),
    createContractor: contractor => dispatch(createContractor(contractor)),
    updateContractor: (contractorId, contractor) =>
      dispatch(updateContractor(contractorId, contractor)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractorDetail),
);
