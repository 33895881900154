import { combineReducers } from "redux";

import { ORDER_FETCH_SUCCESS } from "../orders/actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case ORDER_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodPatterns,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  byId,
});
