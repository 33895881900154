import routes from "./routes";

export const isRouteOrViewDisabledForCurrentProductionProcessType = (
  routeOrView,
  domainSettings,
) => {
  if (!routeOrView.disabledForProductionProcessTypes) {
    return false;
  }
  if (!domainSettings) {
    return true;
  }
  const productionProcessType = domainSettings.find(setting => setting.id === 1)
    .value;

  return routeOrView.disabledForProductionProcessTypes.includes(
    productionProcessType,
  );
};

export const getFirstAccessibleRoute = user => {
  // const productionProcessType = user.domainSettings.find(
  //   setting => setting.id === 1,
  // ).value;
  for (let i = 0; i < routes.length; i++) {
    let route = routes[i];

    if (route.views) {
      for (let i = 0; i < route.views.length; i++) {
        let view = route.views[i];
        if (doesCurrentUserHaveRoleForRoute(view, user)) {
          return view.path;
        }
      }
    } else {
      if (doesCurrentUserHaveRoleForRoute(route, user)) {
        return route.path;
      }
    }
  }

  return "/production";
};

export const intersect = (a, b) => {
  return a.filter(value => b.includes(value));
};

export const doesCurrentUserHaveRoleForRoute = (route, user) => {
  if (!user) {
    return false;
  }
  if (!route.roles) {
    return false;
  }

  let res = intersect(
    user.roles.map(role => {
      return role.id;
    }),
    route.roles,
  );

  if (res.length > 0) {
    return true;
  }

  return false;
};
