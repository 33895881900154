import { Button, Card, Col, Input, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { createSize, deleteSize, fetchSizes } from "../../data/sizes/actions";
import { getAllSizes } from "../../data/sizes/reducer";
import SizeTable from "./components/SizeTable";

//import { Modal,  OverlayTrigger, Popover, Tooltip, Panel } from 'react-bootstrap';

//import ProductTable from './ProductTable'

class SizeMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      size: "",
      isSaving: false,
      //sizes: [{id: 1,name:"45"},{id: 2,name:"48"}]
    };
  }

  componentDidMount() {
    this.props.fetchSizes();
  }

  handleProductDoubleClick = id => {
    //this.props.history.push(`/product-admin/`+id);
  };

  handleSizeRemove = size => {
    this.props.deleteSize(size.id);
  };

  handleSizeAdd = () => {
    this.setState({ isSaving: true });
    this.props.createSize({ name: this.state.size }).then(
      json => {
        this.setState({ size: "", isSaving: false });
      },
      error => {
        this.setState({ isSaving: false });
      },
    );
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { sizes } = this.props;
    return (
      <div>
        <div className="col-xs-6">
          {/* <h2>Размеры</h2>
                    <button className="btn btn-primary" onClick={this.handleCreate}>Создать</button> */}

          <h2>Размеры</h2>
          <Row>
            <Col sm={24}>
              <label className="mt-label" htmlFor="size">
                {" "}
                Размер:&nbsp;
              </label>
              <Input
                style={{ width: 200 }}
                placeholder="Размер"
                value={this.state.size}
                onChange={this.handleSizeChange}
                type="text"
                id="operation"
              />
              &nbsp;&nbsp;
              <Button
                type="primary"
                disabled={this.state.size.length <= 0}
                onClick={this.handleSizeAdd}
                loading={this.state.isSaving}
              >
                Добавить
              </Button>
            </Col>
          </Row>

          <p />

          <Card>
            <SizeTable sizes={sizes} onRemove={this.handleSizeRemove} />
          </Card>
        </div>
        {/* <ProductTable onProductDoubleClick={this.handleProductDoubleClick} onRemove={this.handleProductRemove} products={this.props.products} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    sizes: getAllSizes(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchSizes: () => dispatch(fetchSizes()),
    createSize: size => dispatch(createSize(size)),
    deleteSize: id => dispatch(deleteSize(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SizeMain);
