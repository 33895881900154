import { combineReducers } from "redux";

const initialState = [];

const byId = (state = {}, action) => {
  switch (action.type) {
    case "PATTERN_ADD":
      let pattern = { ...action.pattern, id: action.patternId };
      return {
        ...state,
        [action.patternId]: pattern,
      };
    case "PATTERN_UPDATE":
      return {
        ...state,
        [action.pattern.id]: { ...action.pattern },
      };
    case "PATTERN_DELETE":
      var filtered = Object.keys(state)
        .filter(key => key != action.patternId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }
    case "RESET_SPEC_FORM":
      return {};
    default:
      return state;
  }
};

export const patterns = combineReducers({
  byId,
});
