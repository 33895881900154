//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Input } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import {
  claimWageEntryManually,
  unClaimWageEntryManually,
} from "../../../../../data/wages/actions";
import { setOpenedWageId, setShowWageClaimModal } from "../../../actions";
import { getOpenedWage } from "../../../selectors";

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "400px",
        minHeight: "400px",
      },
      root: {
        //alignItems: "initial"
        zIndex: 1000,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class WageClaimModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      selectedEmployee: null,
    };
  }
  close = () => {
    //this.props.setOpenedPattern("")
    this.props.setShowWageClaimModal(false);
    this.props.setOpenedWageId(null);
  };

  handleUpdate = () => {
    const { createWageClaim, openedCuttingWage, unClaimWageEntry } = this.props;
    // let pattern = {
    //     id: openedPattern.id,
    //     name: this.state.name,
    // }

    // savePattern(pattern)
    //     this.props.setShowPatternModal(false);

    if (openedCuttingWage) {
      this.setState({ saving: true });
      if (this.state.selectedEmployee) {
        createWageClaim(this.state.selectedEmployee.id, openedCuttingWage).then(
          data => {
            this.setState({ saving: false });
            this.close();
          },
          error => {
            this.setState({ saving: false });
          },
        );
      } else {
        unClaimWageEntry(null, openedCuttingWage).then(
          data => {
            this.setState({ saving: false });
            this.close();
          },
          error => {
            this.setState({ saving: false });
          },
        );
      }
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.openedCuttingWage) {
      if (this.props.openedCuttingWageId !== prevProps.openedCuttingWageId) {
        let nextEntry = this.props.openedCuttingWage;
        if (nextEntry.employee) {
          this.setState({
            selectedEmployee: {
              ...nextEntry.employee,
              value: nextEntry.employee.id,
              label:
                nextEntry.employee.last_name +
                " " +
                nextEntry.employee.first_name +
                " [" +
                nextEntry.employee.team.name +
                "]",
            },
          });
        } else {
          this.setState({ selectedEmployee: null });
        }
      }
    } else if (
      this.props.openedCuttingWage == undefined &&
      this.props.showModal != prevProps.showModal
    ) {
      this.setState({ selectedEmployee: null, saving: false });
    }
  }

  componentWillReceiveProps(nextProps) {}

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  getEmployeeOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return axios(`/api/employees/search?q=${input}`).then(({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label:
            item.last_name +
            " " +
            item.first_name +
            " " +
            item.middle_name +
            " [" +
            item.team.name +
            "]",
          id: item.id,
          ...item,
        };
      });
    });
  };

  handleEmployeeSelect = employee => {
    this.setState({ selectedEmployee: employee });
  };

  render() {
    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    const { showModal, openedPattern } = this.props;
    //coming from HOC
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Указать работника
            </DialogTitle>
            <DialogContent>
              {/* <div className="form-inline">
                                <div className="form-group">
                                    <label htmlFor="name"> Название детали:</label>&nbsp;&nbsp;
                                    <input placeholder="Введите название" value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="name" />
                                </div>
                            </div> */}

              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="employee">
                  Работник
                </label>
                <AsyncSelect
                  menuContainerStyle={{ zIndex: 99999 }}
                  name="form-field-name"
                  value={this.state.selectedEmployee}
                  loadOptions={this.getEmployeeOptions}
                  onChange={this.handleEmployeeSelect}
                  styles={customStyles}
                  noOptionsMessage={() => {
                    return "Печатайте для поиска...";
                  }}
                  isClearable
                  menuPortalTarget={document.body}
                  //disabled={disabled}
                />
              </div>

              <p />

              <p />
            </DialogContent>
            <DialogActions>
              <Button
                loading={this.state.saving}
                type="primary"
                onClick={this.handleUpdate}
              >
                Сохранить
              </Button>

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

WageClaimModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

//export default withMobileDialog()(SpreadDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //selectedSpreadId: state.scenes.product.selectedSpread,
    openedCuttingWage: getOpenedWage(state),
    openedCuttingWageId: state.scenes.cutting.openedWageId,
    showModal: state.scenes.cutting.showWageClaimModal,
    //openedPattern: getOpenedPattern(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowWageClaimModal: showModal =>
      dispatch(setShowWageClaimModal(showModal)),
    createWageClaim: (employeeId, wage) =>
      dispatch(claimWageEntryManually(employeeId, wage)),
    unClaimWageEntry: (employeeId, wage) =>
      dispatch(unClaimWageEntryManually(employeeId, wage)),

    setOpenedWageId: id => dispatch(setOpenedWageId(id)),

    //addPattern:  (pattern, spreadId) => dispatch(addPattern(pattern, spreadId)),
    //savePattern: ( pattern) => dispatch(savePattern( pattern)),
    //setOpenedPattern: (patternId) => dispatch(setOpenedPattern(patternId))
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WageClaimModal),
);
