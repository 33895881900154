import { combineReducers } from "redux";

import {
  PAYROLL_CREATE_SUCCESS,
  PAYROLL_ENTRIES_UPDATE_SUCCESS,
  PAYROLL_FETCH_SUCCESS,
  PAYROLL_WAGE_REFRESH_SUCCESS,
} from "../payrolls/actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case PAYROLL_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.payrollAttendanceEntries,
      };
    case PAYROLL_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.payrollAttendanceEntries,
      };
    case PAYROLL_ENTRIES_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.payrollAttendanceEntries,
      };
    case PAYROLL_WAGE_REFRESH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.payrollAttendanceEntries,
      };
    default:
      return state;
  }
};

const byEmployeeId = (state = {}, action) => {
  switch (action.type) {
    case PAYROLL_FETCH_SUCCESS: {
      let newState = {};

      let data = action.original;
      data.payrollAttendanceEntries.forEach(entry => {
        if (!newState[entry.employee_id]) {
          newState[entry.employee_id] = entry.id;
        }
      });

      return newState;
    }
    case PAYROLL_WAGE_REFRESH_SUCCESS: {
      let newState = {};

      let data = action.original;
      data.payrollAttendanceEntries.forEach(entry => {
        if (!newState[entry.employee_id]) {
          newState[entry.employee_id] = entry.id;
        }
      });

      return newState;
    }

    default:
      return state;
  }
};

export const reducer = combineReducers({
  byId,
  byEmployeeId,
});
