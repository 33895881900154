import { combineReducers } from "redux";

import {
  SPLIT_ENTRIES_FETCH_SUCCESS,
  SPLIT_ENTRY_CREATE_SUCCESS,
  SPLIT_ENTRY_FETCH_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case SPLIT_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.splitEntries,
      };
    case SPLIT_ENTRY_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.splitEntries,
      };
    case SPLIT_ENTRY_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.splitEntries,
      };

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case SPLIT_ENTRIES_FETCH_SUCCESS:
      return action.response.result;
    case SPLIT_ENTRY_CREATE_SUCCESS:
      return [...state, action.response.result];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});
