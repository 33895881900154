// import React, { Component } from 'react';
// import PropTypes from 'prop-types'
// import { withRouter } from 'react-router'
// import ReactTable from 'react-table'

import { DeleteOutlined } from '@ant-design/icons';

import { Button, Popconfirm } from "antd";
import matchSorter from "match-sorter";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

//import { Popconfirm } from 'antd';

function FabricTable(props) {
  const confirm = (row, e) => {
    const { onRemove } = props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  const cancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  const { fabrics, onEditClick, onRemove, classes, loading } = props;
  const columns = [
    {
      Header: "",
      accessor: "id",
      maxWidth: 100,
      filterable: false,
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            onEditClick(row.original, e);
          }}
          href={"/fabric-admin/" + row.original.id}
        >
          {"FAB-" + row.value}
        </a>
      ),
    },
    {
      Header: "Имя",
      accessor: "name", // String-based value accessors!
      id: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Описание",
      accessor: "description", // String-based value accessors!
      id: "description",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["description"] }),
      filterAll: true,
    },
    {
      Header: "Код цвета",
      accessor: "colorCode",
      Cell: props => <span className="text">{props.value}</span>,
      filterable: false,
    },
    {
      Header: "Производитель",
      accessor: "manufacturer",
      Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
      filterable: false,
    },
    {
      Header: "Удалить",
      //accessor: 'product.name',
      maxWidth: 100,
      filterable: false,
      Cell: row => (
        <div>
          {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >
                  
                </Button> */}
          <Popconfirm
            placement="topRight"
            title="Уверены что хотите удалить эту запись?"
            onConfirm={e => confirm(row.original, e)}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="danger" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ReactTable
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        className="-striped -highlight"
        data={loading ? [] : fabrics}
        getTdProps={(state, rowInfo, column) => {
          //console.log(column);
          if (loading) {
            return {
              className: "pt-skeleton",
            };
          } else {
            return {};
          }
        }}
        columns={columns}
        defaultPageSize={50}
        previousText="Пред"
        nextText="След"
        loadingText="Загрузка..."
        noDataText={loading ? "" : "Нет записей"}
        pageText="Страница"
        ofText="из"
        rowsText="строк"
      />
    </div>
  );
}

export default withRouter(FabricTable);

// class FabricTable  extends Component{

//     confirm=(row, e)=> {
//         const {  onRemove } = this.props;
//         console.log(e);
//         //message.success('Click on Yes');
//         onRemove(row, e);
//       }

//     cancel=(e)=> {
//         console.log(e);
//         //message.error('Click on No');
//       }

//     render() {
//         const { fabrics, onEditClick, onRemove, classes } = this.props;
//         const columns = [{
//             Header: '',
//             accessor: 'id',
//             maxWidth: 60,
//             Cell: row => (
//                 <div>
//                     <Button type="primary" size="small" icon="edit" onClick={(e => onEditClick(row.original, e))}  >
//                         {/* <OpenInNew classes={{ root: classes.iconRoot }} />
//                         <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
//                     </Button>
//                 </div>
//             )
//         }, {
//             Header: 'Название',
//             accessor: 'name' // String-based value accessors!
//         }, {
//             Header: 'Описание',
//             accessor: 'description',
//             Cell: props => <span className='text'>{props.value}</span> // Custom cell components!

//         }, {
//             Header: 'Производитель',
//             accessor: 'manufacturer',
//             Cell: props => <span className='text'>{props.value}</span> // Custom cell components!

//         }, {
//             Header: 'Удалить',
//             //accessor: 'product.name',
//             maxWidth: 100,
//             Cell: row => (
//                 <div>
//                     {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >

//                     </Button> */}
//                     <Popconfirm placement="topRight" title="Уверены что хотите удалить эту запись?" onConfirm={(e)=>this.confirm(row.original,e)} onCancel={this.cancel} okText="Да" cancelText="Нет">
//                     <Button type="danger" size="small" icon="delete"  >

//                       </Button>
//                     </Popconfirm>
//                 </div>
//             )
//         }]

//         return (
//             <div>
//                 <ReactTable
//                     className="-striped -highlight"
//                     data={fabrics}
//                     columns={columns}
//                 />

//             </div>
//         );
//     }
// }

// FabricTable.propTypes = {
//     onEditClick: PropTypes.func.isRequired,
//     onRemove: PropTypes.func.isRequired,

// };

// export default withRouter(FabricTable);
