import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import DomainSettings from "./index";

class DomainSettingsRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/domainSettings" component={DomainSettings} />
        </Switch>
      </div>
    );
  }
}

export default DomainSettingsRoute;
