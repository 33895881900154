import { Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import AsyncSelect from "../../components/AsyncSelect";
import { fetchEmployee } from "../../data/employees/actions";
import { getAllEmployees } from "../../data/employees/reducer";
import { fetchPeriods } from "../../data/periods/actions";
import { getAllPeriods } from "../../data/periods/reducer";
import { selectEmployee, selectEmployeeId, selectPeriod } from "./actions";
import BarComponent from "./components/BarComponent";
import EmployeeReportTable from "./components/EmployeeReportTable";
import { fetchEmployeeReport } from "./data/actions";
import { getEmployeeReport, getEmployeeReportForTable } from "./data/selectors";

const Option = Select.Option;

class EmployeeStatisticsMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchPeriods().then(data => {
      for (let i = data.length - 1; i >= 0; i--) {
        if (!data[i].isClosed) {
          this.props.selectPeriod(data[i].id);
        }
      }
    });
  }

  getEmployeeOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return (
      axios(`/api/employees/search?q=${input}`)
        //.then((response) => response.json())
        .then(({ data }) => {
          return data.items.map(item => {
            return {
              value: item.id,
              label:
                item.last_name +
                " " +
                item.first_name +
                " " +
                item.middle_name +
                " [" +
                item.team.name +
                "]",
              id: item.id,
              ...item,
            };
          });
        })
    );
  };

  onPRDoubleClick = value => {
    this.props.history.push(`/cutting/` + value.PR);
  };

  handleEmployeeSelect = employee => {
    const { fetchEmployee, selectEmployeeId } = this.props;

    if (employee) {
      fetchEmployee(employee.id).then(employee => {
        this.props.fetchEmployeeReport(this.props.periodId, employee.id);
        selectEmployeeId(employee.id);
      });
    } else {
      selectEmployeeId(null);
    }

    this.props.selectEmployee(employee);
  };

  render() {
    const x = this.props.periods
      .filter(x => x.id === this.props.periodId)
      .map(x => x.id);
    let dv = Object.values(x);

    return (
      <div>
        <span style={{ display: "inline-block" }}>
          <div style={{ width: "35em" }}>
            <AsyncSelect
              name="form-field-name"
              value={this.props.employee}
              loadOptions={this.getEmployeeOptions}
              onChange={this.handleEmployeeSelect}
              //disabled={disabled}
              noOptionsMessage={() => {
                return "Печатайте для поиска...";
              }}
              menuPortalTarget={document.body}
            />
          </div>
          <br />

          <Select
            notFoundContent={"Нет периодов"}
            value={this.props.periodId === null ? "Нет периодов" : dv[0]}
            style={{ width: 150, marginRight: "1em" }}
            onSelect={period_id => {
              this.props.selectPeriod(period_id);
              if (this.props.employee_id !== null) {
                this.props.fetchEmployeeReport(
                  period_id,
                  this.props.employee_id,
                );
              }
            }}
          >
            {this.props.periods.map(period => (
              <Option key={period.id} value={period.id}>
                {period.name}
              </Option>
            ))}
          </Select>
        </span>
        <br /> <br /> <br />
        <BarComponent data={this.props.data} />
        <br /> <br />
        <EmployeeReportTable
          onEditClick={this.onPRDoubleClick}
          data={this.props.dataForTable}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getEmployeeReport(state),
    dataForTable: getEmployeeReportForTable(state),
    periodId: state.scenes.employeeReport.period_id,
    employee_id: state.scenes.employeeReport.employee_id,
    employee: state.scenes.employeeReport.employee,
    employees: getAllEmployees(state),
    periods: getAllPeriods(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEmployeeReport: (period_id, employee_id) =>
      dispatch(fetchEmployeeReport(period_id, employee_id)),
    fetchPeriods: () => dispatch(fetchPeriods()),
    selectPeriod: period_id => dispatch(selectPeriod(period_id)),
    selectEmployeeId: employee_id => dispatch(selectEmployeeId(employee_id)),
    selectEmployee: employee => dispatch(selectEmployee(employee)),
    fetchEmployee: employeeId => dispatch(fetchEmployee(employeeId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EmployeeStatisticsMain),
);
