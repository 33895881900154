import { normalize, schema } from "normalizr";

// Define a users schema

export const fabDepotEntry = new schema.Entity(
  "fabDepotEntries",
  {},
  { idAttribute: "id" },
);

export const arrayOfFabDepotEntries = [fabDepotEntry];
