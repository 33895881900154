//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap-theme.css';

//import { Navbar, NavItem, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
//import { LinkContainer } from 'react-router-bootstrap';

import "./App.css";

import { notification } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { meFromToken, resetToken } from "./data/users/actions";
import SiderLayout from "./layouts/SiderLayout";
import Login from "./scenes/Login";
import Shipment from "./scenes/Shipment";
import ShipmentDetail from "./scenes/Shipment/components/ShipmentDetail";
import WageSummaryRoute from "./scenes/WageSummary/route";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { authed: false };
  }

  componentWillMount() {
    const { loadUserFromToken } = this.props;

    this.setAxiosInterceptor();

    let token = localStorage.getItem("jwtToken");

    if (token && token !== "") {
      //if there is no token, dont bother

      //assume that user is authenticated if token is present
      this.setState({ authed: true });

      this.setAxiosHeaders();
      loadUserFromToken(token).then(data => {
        this.setAxiosHeaders();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user != prevProps.user) {
      this.setAxiosHeaders();

      if (user && user.status == "authenticated") {
        this.setState({ authed: true });
      } else {
        this.setState({ authed: false });
      }
    }
  }

  setAxiosHeaders() {
    axios.defaults.headers.authorization =
      "Bearer " + localStorage.getItem("jwtToken");
  }

  setAxiosInterceptor = () => {
    const { history, resetMe } = this.props;
    axios.interceptors.response.use(
      function(response) {
        // Do something with response data
        return response;
      },
      function(error) {
        // Do something with response error
        if (error.response) {
          if (error.response.status === 401) {
            if (error.response.data.type == "TokenExpiredError") {
              // Need to show error only once, if there were multiple requests returning 401
              if (localStorage.getItem("jwtToken")) {
                openNotificationWithIcon(
                  "Сессия истекла",
                  error.response.data.message,
                  "info",
                );
              }

              resetMe();
              history.push("/login");

              let newError = {
                ...error,
                handledGlobally: true,
              };

              return Promise.reject(newError);
            } else {
              resetMe();
              history.push("/login");
            }
          }
        }

        return Promise.reject(error);
      },
    );
  };

  handleLogout = () => {
    const { resetMe, history } = this.props;
    resetMe();
    history.push("/login");
  };

  render() {
    const { user } = this.props;

    const { authed } = this.state;

    return (
      <Switch>
        <Route path="/wagesummary" component={WageSummaryRoute} />
        <Route path="/login" component={Login} />
        <Route
          render={props =>
            authed == true ? (
              <SiderLayout
                {...props}
                user={user.user}
                domainSettings={this.props.domainSettings}
                onLogoutClick={this.handleLogout}
              />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.data.users.user,
    domainSettings: state.domainSettings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadUserFromToken: token => dispatch(meFromToken(token)),

    resetMe: () => {
      localStorage.removeItem("jwtToken"); //remove token from storage
      dispatch(resetToken());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
