import { Button, notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const PRODREVISIONS_FETCH_SUCCESS = "PRODREVISIONS_FETCH_SUCCESS";
export const PRODREVISION_FETCH_SUCCESS = "PRODREVISION_FETCH_SUCCESS";
export const PRODREVISION_CREATE_SUCCESS = "PRODREVISION_CREATE_SUCCESS";
export const PRODREVISION_UPDATE_SUCCESS = "PRODREVISION_UPDATE_SUCCESS";
export const PRODREVISION_DELETE_SUCCESS = "PRODREVISION_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchRevisions = () => {
  return dispatch => {
    return axios
      .get(`/api/prodstock_revisions/`)
      .then(({ data }) => {
        let prodStockRevisions = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: PRODREVISIONS_FETCH_SUCCESS,
          response: normalize(
            prodStockRevisions,
            schema.arrayOfProdStockRevisions,
          ),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchRevision = revisionId => {
  return dispatch => {
    return axios
      .get(`/api/prodstock_revisions/` + revisionId)
      .then(({ data }) => {
        let prodStockRevision = data;
        let id = revisionId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: PRODREVISION_FETCH_SUCCESS,
          response: normalize(prodStockRevision, schema.prodStockRevision),
          id,
        });
        //dispatch({ type: WAGE_ENTRIES_FETCH_SUCCESS, response: normalize(prodDepotEntry, schema.prodDepotEntry) })
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createRevision = revision => {
  return dispatch => {
    return axios
      .post("/api/prodstock_revisions/", revision)
      .then(({ data }) => {
        // Status looks good
        let entry = data;
        dispatch({
          type: PRODREVISION_CREATE_SUCCESS,
          response: normalize(entry, schema.prodStockRevision),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateRevision = (entryId, entry) => {
  return dispatch => {
    return axios
      .put("/api/prodstock_revisions/" + entryId, entry)
      .then(({ data }) => {
        // Status looks good
        let prodStockRevision = data;
        dispatch({
          type: PRODREVISION_UPDATE_SUCCESS,
          response: normalize(prodStockRevision, schema.prodStockRevision),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteRevision = revisionId => {
  return dispatch => {
    return axios
      .delete("/api/prodstock_revisions/" + revisionId)
      .then(({ data }) => {
        let product = data;
        dispatch({ type: PRODREVISION_DELETE_SUCCESS, revisionId });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Невозможно удалить запись так как ее данные используются в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          //console.log("Parsing eror: Most likely server crashed");

          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
