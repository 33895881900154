import { combineReducers } from "redux";

import {
  CONTRACTOR_PAYMENTS_FETCH_SUCCESS,
  CONTRACTOR_PAYMENT_CREATE_SUCCESS,
  CONTRACTOR_PAYMENT_DELETE_SUCCESS,
  CONTRACTOR_PAYMENT_FETCH_SUCCESS,
  CONTRACTOR_PAYMENT_UPDATE_SUCCESS,
} from "./actions";

export const all = (state = [], action) => {
  switch (action.type) {
    case CONTRACTOR_PAYMENT_CREATE_SUCCESS: {
      return [...state, action.response];
    }
    case CONTRACTOR_PAYMENT_FETCH_SUCCESS: {
      return state.map(contractorPayment =>
        contractorPayment.id === action.contractorPaymentId ? action.response : contractorPayment,
      );
    }
    case CONTRACTOR_PAYMENT_UPDATE_SUCCESS: {
      return state.map(contractorPayment =>
        contractorPayment.id === action.contractorPaymentId ? action.response : contractorPayment,
      );
    }
    case CONTRACTOR_PAYMENTS_FETCH_SUCCESS: {
      return action.response;
    }
    case CONTRACTOR_PAYMENT_DELETE_SUCCESS: {
      return state.filter(contractorPayment => contractorPayment.id !== action.contractorPaymentId);
    }
    default:
      return state;
  }
};

export default combineReducers({
  all,
});
