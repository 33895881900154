import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Select as SelectAntD, notification } from "antd";
import axios from "axios";
import { fetchProdDepotLocations } from "data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "data/prodDepotLocations/reducer";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setOpenedProdRevisionId } from "scenes/ProdStockRevision/actions";
import {
  createRevision,
  fetchRevision,
  updateRevision,
} from "scenes/ProdStockRevision/data/prodStockRevisions/actions";
import { getProdStockRevision } from "scenes/ProdStockRevision/data/prodStockRevisions/reducer";

import ProdStockRevisionForm from "../../forms/ProdStockRevisionForm";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const Option = SelectAntD.Option;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function ProdStockRevisionDetail(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    match,
    fetchRevision,
    setOpeenedRevisionId,
    createRevision,
    openedProdStockRevision,
    updateRevision,
    fetchProdDepotLocations,
    fetchProdStock,
    setOpenedProdRevisionId,
    updateProdStock,
    classes,
    openedProdStock,
  } = props;

  const [isInEditState, toggleEditState] = useState(false);
  const [formData, setFormData] = useState({
    prodDepotLocation: null,
    entryDate: new moment(new Date()),
    product: null,
    quantity: null,
  });

  const form = useRef();

  const [prodDepotLocations, setProdDepotLocations] = useState([]);

  const [saving, setSaving] = useState(false);

  const startSubmit = e => {
    e.preventDefault();
    form.current.submitForm();
  };

  const addLabelFieldsToProduct = product => {
    if (product) {
      return {
        value: product.id,
        label: product.name,
        ...product,
      };
    }
    return null;
  };

  const fetchProdDepotLocationsWithStock = () => {
    let productId = "";
    if (formData.product) {
      productId = formData.product.id;
    }
    axios(`/api/proddepotlocations/searchwithstock?q=${productId}`).then(
      ({ data }) => {
        setProdDepotLocations(data);
      },
    );
  };

  useEffect(() => {
    fetchProdDepotLocationsWithStock();
  }, [formData.product]);

  useEffect(() => {
    setOpenedProdRevisionId("");
    if (match.params.id) {
      fetchRevision(match.params.id).then(entry => {
        setOpenedProdRevisionId(entry.id);
        toggleEditState(false);
        fetchProdDepotLocations();
        setFormData(prev => {
          return {
            ...prev,
            quantity: entry.quantity,
            entryDate: entry.entry_date ? new moment(entry.entry_date) : null,
            product: addLabelFieldsToProduct(entry.product),
            prodDepotLocation: entry.prodDepotLocation_id,
          };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  let prodDepotLocationOptions = prodDepotLocations.map(depot => {
    let quantity = 0;
    if (depot.totalQuantity) {
      quantity = depot.totalQuantity;
    }
    if (match.params.id) {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}
        </Option>
      );
    } else {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}
        </Option>
      );
    }
  });

  const onProdDepotEntryClick = id => {
    //console.log(id);
    history.push(`/proddepotentry/` + id);
  };

  const createProdStockRevisionFromValues = values => {
    return {
      quantity: values.quantity,
      entry_date: new moment(values.entryDate).format("YYYY-MM-DD HH:mm:ss"),
      product: values.product,
      prodDepotLocation_id: values.prodDepotLocation,
    };
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    console.log("Values: ", values);
    var revision = createProdStockRevisionFromValues(values);
    //Handle update
    if (match.params.id) {
    }
    //Handle create
    else {
      setSaving(true);
      return createRevision(revision).then(
        json => {
          setSubmitting(false);
          setSaving(false);
          toggleEditState(false);
          history.push(`/prodstockrevision/` + json.id);
        },
        error => {
          setSubmitting(false);
          setSaving(false);
        },
      );
    }
  };

  const createRows = () => {
    let rows = [];
    console.log("opened", openedProdStockRevision);
    if (openedProdStockRevision && openedProdStockRevision.prodStocks) {
      openedProdStockRevision.prodStocks.forEach(stock => {
        rows.push(stock);
      });
    }
    return rows;
  };

  const rows = createRows();

  return (
    <div className="mt-paper">
      <div>
        <h2>Запись ревизии</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={startSubmit} loading={saving}>
          Сохранить
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={startSubmit} loading={saving}>
          Создать
        </Button>
      )}
      {/*{match.params.id && !isInEditState && (*/}
      {/*  <Button onClick={() => toggleEditState(true)}>Изменить</Button>*/}
      {/*)}*/}
      <br />
      &nbsp;
      <ProdStockRevisionForm
        prodDepotLocationOptions={prodDepotLocationOptions}
        disabled={!isInEditState}
        ref={form}
        onSubmit={handleSubmit}
        //prodDepotEntryId={openedProdDepotEntry ? openedProdDepotEntry.id : null}
        initialValues={{
          quantity: formData.quantity,
          entryDate: formData.entryDate,
          product: formData.product,
          prodDepotLocation: formData.prodDepotLocation,
        }}
      />
      {match.params.id && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Из прихода</TableCell>
                <TableCell align="right">Тип</TableCell>
                <TableCell align="right">Склад </TableCell>
                <TableCell align="right">Количество исправления (шт)</TableCell>
                <TableCell align="right">Цена </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <a
                      onClick={e => {
                        e.preventDefault();
                        onProdDepotEntryClick(row.prodDepotEntry_id);
                      }}
                      href={"/proddepot/" + row.prodDepotEntry_id}
                    >
                      {"PDE-" + row.prodDepotEntry_id}
                    </a>
                    {/*{row.prodDepotEntry.entry_date}*/}
                  </TableCell>
                  <TableCell align="right">
                    {row.prodTransactionType.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.prodDepotLocation.name}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedProdStockRevision: getProdStockRevision(
      state,
      state.scenes.prodStockRevision.openedRevisionId,
    ),
    prodDepotLocations: getAllProdDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRevision: id => dispatch(fetchRevision(id)),
    setOpenedProdRevisionId: id => dispatch(setOpenedProdRevisionId(id)),
    createRevision: revision => dispatch(createRevision(revision)),
    updateRevision: (id, entry) => dispatch(updateRevision(id, entry)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

ProdStockRevisionDetail = withStyles(styles)(ProdStockRevisionDetail);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdStockRevisionDetail),
);
