import { combineReducers } from "redux";

import {
  PAYMENTS_FETCH_SUCCESS,
  PAYMENT_CREATE_SUCCESS,
  PAYMENT_DELETE_SUCCESS,
  PAYMENT_FETCH_SUCCESS,
  PAYMENT_UPDATE_SUCCESS,
} from "./actions";

export const all = (state = [], action) => {
  switch (action.type) {
    case PAYMENT_CREATE_SUCCESS: {
      return [...state, action.response];
    }
    case PAYMENT_FETCH_SUCCESS: {
      return state.map(payment =>
        payment.id === action.paymentId ? action.response : payment,
      );
    }
    case PAYMENT_UPDATE_SUCCESS: {
      return state.map(payment =>
        payment.id === action.paymentId ? action.response : payment,
      );
    }
    case PAYMENTS_FETCH_SUCCESS: {
      return action.response;
    }
    case PAYMENT_DELETE_SUCCESS: {
      return state.filter(payment => payment.id !== action.paymentId);
    }
    default:
      return state;
  }
};

export default combineReducers({
  all,
});
