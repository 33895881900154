import { notification } from "antd";
import axios from "axios";
import { serialize } from "helpers/utils";

export const REPORT_FETCH_SUCCESS = "REPORT_FETCH_SUCCESS";
export const REPORT_ENTRIES_FETCH_SUCCESS = "REPORT_ENTRIES_FETCH_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchReports = (period_id, range, partition) => {
  return dispatch => {
    return axios
      .get(
        `/api/production_statistics/production/report?${serialize({
          period_id,
          range,
          partition,
        })}`,
      )
      .then(({ data }) => {
        let reports = data;
        dispatch({
          type: REPORT_FETCH_SUCCESS,
          response: reports,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchEntries = period_id => {
  return dispatch => {
    return axios
      .get(
        `/api/production_statistics/production/entries?${serialize({
          period_id,
        })}`,
      )
      .then(({ data }) => {
        dispatch({
          type: REPORT_ENTRIES_FETCH_SUCCESS,
          response: data,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
