import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Card, Empty, InputNumber, Table } from "antd";
import React from "react";
import { connect } from "react-redux";

import {
  getOpenedCuttingOrder,
  getProdBomsByOpenedOrderWithSpreadsPatternsEntries,
} from "../../../selectors";

function SewingPassportInfo(props) {
  const { openedOrder, prodBoms, passportEntry } = props;

  const values = passportEntry
    ? {
        lineItems: passportEntry.lineItems.map(lineItem => ({
          sizeId: lineItem.size.id,
          sizeName: lineItem.size.name,
          quantity: lineItem.quantity,
        })),
      }
    : null;

  return (
    <Card size={"small"} title={"Швейный паспорт"}>
      {!passportEntry && <Empty description={"Паспорт не принят"} />}
      {passportEntry && (
        <div>
          <h4>Скроено:</h4>
          <Table
            size={"small"}
            pagination={false}
            bordered={true}
            columns={[
              {
                title: "Часть",
                dataIndex: "prodBomName",
                key: "prodBomName",
                align: "center",
                width: 70,
              },
              ...props.openedOrder.lineItems.map((lineItem, lineItemIndex) => ({
                title: lineItem.size.name,
                key: "sizeName" + lineItemIndex,
                dataIndex: "lineItems",
                align: "center",
                width: 70,
                render: value => {
                  return value.find(item => item.sizeId === lineItem.size.id)
                    .quantity;
                },
              })),
              {
                title: "Всего скроено",
                key: "lineItemsSum",
                align: "center",
                width: 70,
                render(value, record) {
                  return record.lineItems.reduce(
                    (acc, curr) => acc + curr.quantity,
                    0,
                  );
                },
              },
            ]}
            dataSource={prodBoms.map((prodBom, key) => {
              return {
                key,
                prodBomName: prodBom.name,
                lineItems: openedOrder.lineItems.map(lineItem => ({
                  sizeId: lineItem.size.id,
                  sizeName: lineItem.size.name,
                  quantity: Math.min(
                    ...prodBom.prodSpreads.map(prodSpread => {
                      return props.cuttingPassportEntry.fabricConsumptions
                        .filter(
                          fabricConsumption =>
                            fabricConsumption.prodBom_id === prodBom.id &&
                            fabricConsumption.prodSpread_id === prodSpread.id,
                        )
                        .reduce(
                          (acc, curr) =>
                            acc +
                            curr.lineItems.find(
                              item => item.size.id === lineItem.size.id,
                            ).quantity,
                          0,
                        );
                    }),
                  ),
                })),
              };
            })}
          />
          <br />
          <h4>Сшито:</h4>
          <Table
            bordered={true}
            size={"small"}
            pagination={false}
            columns={[
              ...props.openedOrder.lineItems.map((lineItem, lineItemIndex) => ({
                title: lineItem.size.name,
                key: "sizeName" + lineItemIndex,
                align: "center",
                width: 70,
                render: () => {
                  return (
                    <InputNumber
                      precision={0}
                      className={"cleanInputNumber"}
                      value={
                        values.lineItems.find(
                          item => item.sizeId === lineItem.size.id,
                        ).quantity
                      }
                      min={0}
                      disabled={true}
                    />
                  );
                },
              })),
              {
                title: "Всего сшито",
                key: "lineItemsSum",
                align: "center",
                width: 70,
                render(value, record) {
                  return record.lineItems.reduce(
                    (acc, curr) => acc + curr.quantity,
                    0,
                  );
                },
              },
            ]}
            dataSource={[{ key: 1, lineItems: values.lineItems }]}
          />
        </div>
      )}
    </Card>
  );
}

const mapStateToProps = state => {
  const openedOrder = getOpenedCuttingOrder(state);
  return {
    showModal: state.scenes.cutting.showSewingSubmitPassportModal,
    prodBoms: getProdBomsByOpenedOrderWithSpreadsPatternsEntries(state),
    openedOrder: openedOrder,
    passportEntry: openedOrder.sewingPassportEntry,
    cuttingPassportEntry: openedOrder.cuttingPassportEntry,
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    null,
  )(SewingPassportInfo),
);
