import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, DatePicker, Input } from "antd";
import { InputNumber, Select as SelectAntD, notification } from "antd";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

import { fetchProdDepotLocations } from "../../../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../../../data/prodDepotLocations/reducer";
import {
  createProdStock,
  fetchProdStock,
  updateProdStock,
} from "../../../../data/prodStocks/actions";
import { getProdStock } from "../../../../data/prodStocks/reducer";
import { setOpenedProdStockId } from "../../actions";
import FormValidator from "./FormValidator";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const Option = SelectAntD.Option;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

let validator = new FormValidator([
  {
    field: "quantity",
    method: "isEmpty",
    validWhen: false,
    message: "Введите количество",
  },
  {
    field: "quantity",
    method: (value, formData) => {
      let val = parseInt(value);
      if (Number.isInteger(val) && val <= 0) {
        return true;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Количество должно быть больше 0",
  },
  {
    field: "entry_dateField",
    method: (value, formData) => {
      if (formData.entry_date) {
        return false;
      } else {
        return true;
      }
    },
    validWhen: false,
    message: "Укажите дату",
  },
  {
    field: "productField",
    method: (args, formData) => {
      if (formData.product) {
        return false;
      } else {
        return true;
      }
    },
    validWhen: false,
    message: "Выберите продукт",
  },
  {
    field: "fromProdDepotLocationField",
    method: (args, formData) => {
      if (Number.isInteger(formData.fromProdDepotLocation)) {
        return false;
      } else return true;
    },
    validWhen: false,
    message: "Выберите склад",
  },
  {
    field: "toProdDepotLocationField",
    method: (args, formData) => {
      if (Number.isInteger(formData.toProdDepotLocation)) {
        return false;
      } else return true;
    },
    validWhen: false,
    message: "Выберите склад",
  },
]);

function ProdStockDetail(props) {
  const {
    history,
    match,
    fetchProdDepotEntry,
    setOpenedProdStockId,
    createProdDepotEntry,
    updateProdDepotEntry,
    fetchProdDepotLocations,
    fetchProdStock,
    updateProdStock,
    classes,
    openedProdStock,
  } = props;
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [saving, setSaving] = useState(false);
  const [isInEditState, toggleEditState] = useState(false);
  const [formData, setFormData] = useState({
    quantity: "",
    entry_date: null,
    entry_dateField: "",
    product: null,
    productField: "",

    fromProdDepotLocation: null,
    fromProdDepotLocationField: "",

    toProdDepotLocation: null,
    toProdDepotLocationField: "",
  });

  const [prodDepotLocations, setProdDepotLocations] = useState([]);

  const onDepotCreate = () => {
    history.push(`/proddepot/new`);
  };

  const handleEditClick = contractor => {
    history.push(`/proddepot/` + contractor.id);
  };

  const onRemove = value => {
    const { deleteEmployee } = this.props;
    deleteEmployee(value.id);
  };

  const createProdStockFromValues = () => {
    var prodStock = {
      quantity: formData.quantity,
      entry_date: new moment(formData.entry_date).format("YYYY-MM-DD HH:mm:ss"),
      product: formData.product,
      fromProdDepotLocation: formData.fromProdDepotLocation,
      toProdDepotLocation: formData.toProdDepotLocation,
    };
    return prodStock;
  };

  const handleSubmit = () => {
    setSubmitted(true);

    const validated = validator.validate(formData);
    setValidation(validated);

    if (validated.isValid) {
      var prodStock = createProdStockFromValues();

      setSaving(true);
      //Handle update
      if (match.params.id) {
        return updateProdStock(match.params.id, prodStock).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            fetchProdDepotLocationsWithStock();
            history.push(`/prodstock/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
      //Handle create
      else {
        return axios
          .post("/api/prodstocks/", prodStock)
          .then(({ data }) => {
            setSaving(false);
            openNotificationWithIcon(
              "Успешно",
              "Перемещение добавлено",
              "success",
            );
            return data;
          })
          .catch(error => {
            if (error.handledGlobally) {
              return Promise.reject();
            }
            setSaving(false);
            if (error.response) {
              let { data } = error.response;

              if (data.errno && (data.errno == 1062 || data.errno == 19)) {
                let message = "Такое перемещение уже существует";
                openNotificationWithIcon("Ошибка", message, "error");
              } else {
                openNotificationWithIcon(
                  "Ошибка",
                  data.errorMessage || "Произошла неизвестная ошибка",
                  "error",
                );
              }
              throw error;
            } else {
              openNotificationWithIcon(
                "Ошибка",
                "Невозможно подключится к серверу",
                "error",
              );
              throw error;
            }
          })
          .then(data => {
            history.push("/prodstock");
          });
      }
    }
  };

  const handleInputChange = e => {
    let value = e.target.value;
    const name = e.target.name;

    setFormData(prevData => {
      return { ...prevData, [name]: value };
    });
  };

  const handleQuantityChange = value => {
    if (value == undefined) {
      setFormData(prevData => {
        return { ...prevData, quantity: "" };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, quantity: value };
      });
    }
  };

  const handleDateChange = day => {
    let value = day;
    setFormData(prevData => {
      return { ...prevData, entry_date: value };
    });
  };

  const getProductOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/products/searchwithstock?q=${input}`).then(
      ({ data }) => {
        return data.map(item => {
          let totalQuantity = 0;
          if (item.totalQuantity) {
            totalQuantity = item.totalQuantity;
          }
          return {
            value: item.id,
            label: item.name + " { всего: " + totalQuantity + " }",
            id: item.id,
            ...item,
          };
        });
      },
    );
  };

  const handleProductSelect = product => {
    if (product) {
      setFormData(prevData => {
        return { ...prevData, product: product };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, product: null };
      });
    }
  };

  const addLabelFieldsToProduct = product => {
    if (product) {
      let selectedProduct = {
        value: product.id,
        label: product.name,
        ...product,
      };
      return selectedProduct;
    }
    return null;
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(formData));
    }
  }, [formData]);

  const fetchProdDepotLocationsWithStock = () => {
    let productId = "";
    if (formData.product) {
      productId = formData.product.id;
    }
    axios(`/api/proddepotlocations/searchwithstock?q=${productId}`).then(
      ({ data }) => {
        setProdDepotLocations(data);
      },
    );
  };

  useEffect(() => {
    fetchProdDepotLocationsWithStock();
  }, [formData.product]);

  useEffect(() => {
    setOpenedProdStockId("");
    if (match.params.id) {
      fetchProdStock(match.params.id).then(entry => {
        setOpenedProdStockId(entry.id);
        toggleEditState(false);
        fetchProdDepotLocations();
        setFormData(prev => {
          return {
            ...prev,
            price: entry.price,
            quantity: entry.quantity,
            entry_date: entry.entry_date ? new moment(entry.entry_date) : null,
            product: addLabelFieldsToProduct(entry.product),
            fromProdDepotLocation: entry.fromProdDepotLocation_id,
            toProdDepotLocation: entry.toProdDepotLocation_id,
          };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  let validated = validation;

  let prodDepotLocationOptions = prodDepotLocations.map(depot => {
    let quantity = 0;
    if (depot.totalQuantity) {
      quantity = depot.totalQuantity;
    }
    if (match.params.id) {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}
        </Option>
      );
    } else {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}, остаток: {quantity}
        </Option>
      );
    }
  });

  const handleFromProdDepotLocationChange = location => {
    setFormData(prevData => {
      return { ...prevData, fromProdDepotLocation: location };
    });
  };

  const handleToProdDepotLocationChange = location => {
    setFormData(prevData => {
      return { ...prevData, toProdDepotLocation: location };
    });
  };

  const createRows = () => {
    let rows = [];
    if (openedProdStock && openedProdStock.prodStocks) {
      openedProdStock.prodStocks.forEach(stock => {
        rows.push(stock);
      });
    }
    return rows;
  };

  const rows = createRows();

  const onProdDepotEntryClick = id => {
    history.push(`/proddepotentry/` + id);
  };

  const disabledDate = current => {
    return current > moment().endOf("day");
  };

  console.log(formData);

  return (
    <div className="mt-paper">
      <div>
        <h2>Запись перемещения</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={handleSubmit} loading={saving}>
          Сохранить
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={handleSubmit} loading={saving}>
          Создать
        </Button>
      )}
      {/*{match.params.id && !isInEditState && (*/}
      {/*  <Button onClick={() => toggleEditState(true)}>Изменить</Button>*/}
      {/*)}*/}
      <br />
      &nbsp;
      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="prodDate">
          Дата перемещения
        </label>
        <br />
        <DatePicker
          disabledDate={disabledDate}
          id="entry_date"
          name="date"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          value={formData.entry_date}
          style={{ zIndex: 9999 }}
          disabled={!isInEditState}
        />
        <br />
        <span className="help-block">{validation.entry_dateField.message}</span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validation.productField.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="product">
          Продукт
        </label>
        <AsyncSelect
          name="product"
          value={formData.product}
          loadOptions={getProductOptions}
          onChange={handleProductSelect}
          isDisabled={!isInEditState}
          styles={customStyles}
          noOptionsMessage={() => {
            return "Печатайте для поиска...";
          }}
          menuPortalTarget={document.body}
        />
        <span className="help-block">{validation.productField.message}</span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={
          validation.fromProdDepotLocationField.isInvalid ? "has-error" : ""
        }
      >
        <label className="mt-label" htmlFor="job_title">
          Откуда
        </label>
        <br />
        <SelectAntD
          disabled={!isInEditState}
          value={formData.fromProdDepotLocation}
          style={{ width: 300 }}
          onChange={handleFromProdDepotLocationChange}
        >
          {prodDepotLocationOptions}
        </SelectAntD>
        <br />
        <span className="help-block">
          {validation.fromProdDepotLocationField.message}
        </span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={
          validation.toProdDepotLocationField.isInvalid ? "has-error" : ""
        }
      >
        <label className="mt-label" htmlFor="job_title">
          Куда
        </label>
        <br />
        <SelectAntD
          disabled={!isInEditState}
          value={formData.toProdDepotLocation}
          style={{ width: 300 }}
          onChange={handleToProdDepotLocationChange}
        >
          {prodDepotLocationOptions}
        </SelectAntD>
        <br />
        <div className="help-block">
          {validation.toProdDepotLocationField.message}
        </div>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validated.quantity.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="quantity">
          Количество
        </label>
        <br />
        <InputNumber
          style={{ width: 300 }}
          name="quantity"
          placeholder="Количество"
          value={formData.quantity}
          onChange={handleQuantityChange}
          disabled={!isInEditState}
        />
        <div className="help-block">{validated.quantity.message}</div>
      </div>
      {match.params.id && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Из прихода</TableCell>
                <TableCell align="right">Тип</TableCell>
                <TableCell align="right">Склад </TableCell>
                <TableCell align="right">Количество (шт)</TableCell>
                <TableCell align="right">Цена </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <a
                      onClick={e => {
                        e.preventDefault();
                        onProdDepotEntryClick(row.prodDepotEntry_id);
                      }}
                      href={"/proddepotentry/" + row.prodDepotEntry_id}
                    >
                      {"PDE-" + row.prodDepotEntry_id}
                    </a>
                    {/*{row.prodDepotEntry.entry_date}*/}
                  </TableCell>
                  <TableCell align="right">
                    {row.prodTransactionType.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.prodDepotLocation.name}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedProdStock: getProdStock(
      state,
      state.scenes.prodStock.openedProdStockId,
    ),
    prodDepotLocations: getAllProdDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProdStock: id => dispatch(fetchProdStock(id)),
    setOpenedProdStockId: id => dispatch(setOpenedProdStockId(id)),
    createProdStock: location => dispatch(createProdStock(location)),
    updateProdStock: (id, entry) => dispatch(updateProdStock(id, entry)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

ProdStockDetail = withStyles(styles)(ProdStockDetail);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdStockDetail),
);
