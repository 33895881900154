//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { setOpenedSplitEntryId, setShowSplitModal } from "../../actions";
import { getOpenedSplitEntry } from "../../selectors";
import WageTable from "../WageTable";

//import { addPattern, updatePattern, } from '../../../../data/patterns/actions';

//import { getOpenedPatternById } from '../../../../data/reducer'

//import './index.css';

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "600px",
      },
      root: {
        //alignItems: "initial"
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class SplitDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  printLabelsForEntry = this.props.printLabelsForEntry.bind(this);

  showConfirm = this.props.showConfirm.bind(this);

  close = () => {
    this.props.setOpenedSplitEntryId("");
    this.props.setShowSplitModal(false);
  };

  // create = () => {
  //     const {addPattern, selectedSpreadId} =this.props;
  //     //this.props.onNastilDetailCreate({ name: this.state.name, material: this.state.selectedMaterial });
  //     let pattern = {
  //         name: this.state.name,
  //     }
  //     addPattern(selectedSpreadId, pattern).then(spread=>{
  //         this.props.setShowPatternModal(false);
  //     })
  // }

  // handleUpdate=()=>{
  //     const {updatePattern, selectedSpreadId, openedPattern} =this.props;
  //     let pattern = {
  //         id: openedPattern.id,
  //         name: this.state.name,
  //     }

  //     updatePattern(selectedSpreadId, pattern).then(nastil=>{
  //         this.props.setShowPatternModal(false);
  //     })
  // }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    //You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.openedPattern) {
      if (nextProps.openedPattern !== this.props.openedPattern) {
        this.setState({
          name: nextProps.openedPattern.name,
        });
      }
    } else {
      this.setState({
        name: "",
      });
    }
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  render() {
    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    const { showModal, openedSplitEntry } = this.props;
    //coming from HOC
    const { fullScreen, classes } = this.props;

    let wages = [];
    if (openedSplitEntry) {
      wages = openedSplitEntry.wages;
    }

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
            style={{ zIndex: 999 }}
          >
            <DialogTitle id="responsive-dialog-title">
              Распечатать штрих коды
            </DialogTitle>
            <DialogContent>
              {/* <div className="form-inline">
                                <div className="form-group">
                                    <label htmlFor="name"> Название детали:</label>&nbsp;&nbsp;
                                    <input placeholder="Введите название" value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="name" />
                                </div>
                            </div> */}

              <WageTable wages={wages} />

              <p />

              <p />
            </DialogContent>
            <DialogActions>
              <Button
                type="primary"
                onClick={() => this.printLabelsForEntry(openedSplitEntry)}
              >
                Распечатать
              </Button>

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

SplitDetail.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

//export default withMobileDialog()(SpreadDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //selectedSpreadId: state.scenes.product.selectedSpread,
    //selectedSpreadId: state.scenes.product.selectedSpread,
    showModal: state.scenes.split.showSplitModal,
    openedSplitEntry: getOpenedSplitEntry(state),
    user: state.data.users.user.user,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowSplitModal: showModal => dispatch(setShowSplitModal(showModal)),
    //addPattern: (spreadId, pattern) => dispatch(addPattern(spreadId, pattern)),
    //updatePattern: (spreadId, pattern) => dispatch(updatePattern(spreadId, pattern)),
    setOpenedSplitEntryId: entryId => dispatch(setOpenedSplitEntryId(entryId)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SplitDetail),
);
