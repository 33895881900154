import { Breadcrumb, Button, Layout, Menu } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  deleteDepotLocation,
  fetchDepotLocations,
} from "../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../data/depotLocations/reducer";
import { fetchTeams } from "../../data/teams/actions";
import DepotEntryTable from "./components/DepotEntryTable";

function DepotLocationMain(props) {
  // Declare a new state variable, which we'll call "count"
  const { history, depotLocations, fetchDepotLocations } = props;

  const [loading, setLoading] = useState(() => {
    if (depotLocations.length > 0) {
      return false;
    } else return true;
  });
  const [searchEnabled, setSearchEnabled] = useState(false);
  //const [fabDepotEntries, setFabDepotEntries] = useState(0);
  const [count, setCount] = useState(0);

  const onDepotCreate = () => {
    history.push(`/depotlocation/new`);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/depotlocation/` + contractor.id);
  };

  const onRemove = value => {
    const { deleteDepotLocation } = props;
    deleteDepotLocation(value.id);
    //history.push(`/product-admin/`)
  };

  useEffect(() => {
    fetchDepotLocations().then(depot => {
      setLoading(false);
    });
  }, []);

  console.log("rendered");

  return (
    <div>
      <div>
        <h2>Склады</h2>
        <Button type="primary" onClick={onDepotCreate}>
          Создать
        </Button>
      </div>
      <br />
      <DepotEntryTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        depotLocations={depotLocations}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    depotLocations: getAllDepotLocations(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
    // fetchTeams: ()=>dispatch(fetchTeams()),
    deleteDepotLocation: id => dispatch(deleteDepotLocation(id)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DepotLocationMain),
);

//export default ContractorMain
