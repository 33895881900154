import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import FabricConsumptionDetail from "./components/FabricConsumptionDetail";
import FabricConsumptionMain from "./index.js";

class FabDepotRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/fabricConsumption"
            component={FabricConsumptionMain}
          />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={FabricConsumptionDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={FabricConsumptionDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default FabDepotRoute;
