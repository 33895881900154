import { combineReducers } from "redux";
const SET_OPENED_FABRIC_CONSUMPTION_ID = "SET_OPENED_FABRIC_CONSUMPTION_ID";

const openedFabricConsumptionId = (state = "", action) => {
  switch (action.type) {
    case SET_OPENED_FABRIC_CONSUMPTION_ID:
      return action.fabricConsumptionId;

    default:
      return state;
  }
};

export default combineReducers({
  openedFabricConsumptionId,
});
