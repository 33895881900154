import { combineReducers } from "redux";

const openedSize = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_SIZE":
      return action.sizeId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedSize,
});
