import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import PaymentDetails from "./components/PaymentDetails";
import PaymentMain from "./index.js";

export default props => (
  <div>
    <Switch>
      <Route exact path="/payment" component={PaymentMain} />
      <Route exact path="/payment/:id" component={PaymentDetails} />
    </Switch>
  </div>
);
