//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select as SelectAnt,
} from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ReactTable from "react-table";

import OperationTable from "../../../../../components/OperationTable";
import { saveSewingProdOperations } from "../../../../../data/prodOperations/actions";
import {
  setOpenedWageId,
  setShowCuttingWageModal,
  setShowSewingOperationsModal,
  setShowWageClaimModal,
} from "../../../actions";
import {
  getCuttingWagesForSelectedProdBom,
  getOpenedCuttingOrder,
  getOpenedPattern,
  getProdOperationsForSelectedProdBom,
  getSelectedProdBomId,
} from "../../../selectors";

const Option = SelectAnt.Option;

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "800px",
      },
      root: {
        alignItems: "initial",

        zIndex: 500,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class SewingOperationsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      activeTab: 1,
      operation: "",
      price: "",
      operationType_id: "",
      sewingOperations: [],
    };

    this.state.sewingOperations = [
      ...this.getFilteredSewingOperationsFromProps(),
    ];
  }
  close = () => {
    // this.props.setOpenedPattern("")
    this.props.setShowSewingOperationsModal(false);
  };

  getFilteredSewingOperationsFromProps = () => {
    return this.props.prodOperations.filter(
      ops => (ops.operationType_id == 2) | (ops.operationType_id == 3),
    );
  };

  create = () => {};

  handleSewingRemove = e => {
    console.log("index is ", e);
    var newArr = [...this.state.sewingOperations];
    newArr.splice(e, 1);
    this.setState({ sewingOperations: newArr });
  };

  handleSewingAdd = () => {
    var sewingOperation = {
      operation: this.state.operation,
      price: this.state.price,
      operationType_id: this.state.operationType_id,
    };
    this.setState(state => ({
      sewingOperations: state.sewingOperations.concat(sewingOperation),
    }));
    this.setState({ operation: "", price: "", operationType_id: "" });
  };

  handleUpdate = () => {
    const { saveSewingProdOperations, selectedProdBomId } = this.props;

    let oldProdOperations = this.getFilteredSewingOperationsFromProps();

    saveSewingProdOperations(
      selectedProdBomId,
      this.state.sewingOperations,
      oldProdOperations,
    ).then(res => {
      this.close();
    });
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  handleNameChange = e => {
    //this.setState({ name: e.target.value });
  };

  handlePriceChange = e => {
    this.setState({ price: e });
  };
  handleOperationChange = e => {
    this.setState({ operation: e.target.value });
  };

  handleOperationTypeChange = e => {
    console.log("sdfsdf", e);

    this.setState({ operationType_id: e });
  };

  render() {
    const { showModal, openedOrder } = this.props;
    //coming from HOC
    const { fullScreen, classes, wages } = this.props;
    //console.log("cutting wages",wages);

    let disabled = !!openedOrder.is_completed;

    const columns = [
      {
        Header: "Операция",
        accessor: "operation",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Цена",
        accessor: "price",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Тип",
        accessor: "operationType_id",
        Cell: props =>
          props.value == 2 ? <span>Швейный</span> : <span>Упаковка</span>, // Custom cell components!
      },
      {
        Header: "Удалить",
        maxWidth: 100,
        Cell: row => (
          <div>
            {!disabled ? (
              <Popconfirm
                placement="topRight"
                title="Уверены что хотите удалить эту операцию?"
                onConfirm={e => this.handleSewingRemove(row.index, e)}
                onCancel={this.handleCancel}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="danger" size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
            ) : (
              <Button type="danger" size="small" icon={<DeleteOutlined />} disabled />
            )}
          </div>
        ),
      },
    ];

    let pageSize =
      this.state.sewingOperations.length == 0
        ? 10
        : this.state.sewingOperations.length;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Швейные операции
            </DialogTitle>
            <DialogContent>
              {/* <div className="form-inline">
                                <div className="form-group">
                                    <label htmlFor="name"> Название детали:</label>&nbsp;&nbsp;
                                    <input placeholder="Введите название" value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="name" />
                                </div>
                            </div> */}

              <Row>
                <Col sm={24}>
                  <label className="mt-label" htmlFor="operation">
                    {" "}
                    Операция:&nbsp;
                  </label>
                  <Input
                    style={{ width: 200 }}
                    placeholder="Операция"
                    value={this.state.operation}
                    onChange={this.handleOperationChange}
                    type="text"
                    id="operation"
                    disabled={disabled}
                  />
                  &nbsp;&nbsp;
                  <label className="mt-label" htmlFor="price">
                    {" "}
                    Цена:&nbsp;
                  </label>
                  <InputNumber
                    placeholder="Цена"
                    value={this.state.price}
                    onChange={this.handlePriceChange}
                    type="text"
                    id="price"
                    disabled={disabled}
                  />
                  &nbsp;&nbsp;
                  <label className="mt-label" htmlFor="price">
                    {" "}
                    Тип:&nbsp;
                  </label>
                  <SelectAnt
                    value={this.state.operationType_id}
                    style={{ width: "100%" }}
                    onChange={this.handleOperationTypeChange}
                    style={{ width: 120 }}
                    disabled={disabled}
                  >
                    <Option key={"2"} value="2">
                      Швейный
                    </Option>
                    <Option key={"3"} value="3">
                      Упаковка
                    </Option>
                  </SelectAnt>
                  &nbsp;&nbsp;
                  <Button
                    disabled={
                      this.state.operation.length <= 0 ||
                      this.state.price <= 0 ||
                      this.state.price == undefined ||
                      disabled
                    }
                    onClick={this.handleSewingAdd}
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>

              <p />

              <ReactTable
                data={this.state.sewingOperations}
                columns={columns}
                className=" -highlight"
                minRows={10}
                showPagination={false}
                pageSize={pageSize}
              />
            </DialogContent>
            <DialogActions>
              {/* {openedPattern == undefined && <Button type="primary" disabled={this.state.name.length <= 0} onClick={this.create}>Создать</Button>}
                        {openedPattern != undefined && <Button type="primary" disabled={this.state.name.length <= 0} onClick={this.handleUpdate}>Сохранить</Button>} */}

              <Button
                disabled={disabled}
                type="primary"
                onClick={this.handleUpdate}
              >
                Сохранить
              </Button>
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

SewingOperationsModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

//export default withMobileDialog()(SpreadDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //selectedSpreadId: state.scenes.product.selectedSpread,
    //selectedSpreadId: state.scenes.forms.specform.selectedSpread,
    showModal: state.scenes.cutting.showSewingOperationsModal,
    //openedPattern: getOpenedPattern(state),
    //wages: getCuttingWagesForSelectedProdBom(state),
    prodOperations: getProdOperationsForSelectedProdBom(state),
    selectedProdBomId: getSelectedProdBomId(state),
    openedOrder: getOpenedCuttingOrder(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowSewingOperationsModal: showModal =>
      dispatch(setShowSewingOperationsModal(showModal)),
    //setShowWageClaimModal: (showModal) => dispatch(setShowWageClaimModal(showModal)),
    saveSewingProdOperations: (prodBomId, operations, oldProdOperations) =>
      dispatch(
        saveSewingProdOperations(prodBomId, operations, oldProdOperations),
      ),
    //setOpenedCuttingWageId: (id) => dispatch(setOpenedWageId(id)),
    //addPattern:  (pattern, spreadId) => dispatch(addPattern(pattern, spreadId)),
    //savePattern: ( pattern) => dispatch(savePattern( pattern)),
    //setOpenedPattern: (patternId) => dispatch(setOpenedPattern(patternId))
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SewingOperationsModal),
);
