import { Breadcrumb, Button, Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { deleteTeam, fetchTeams } from "../../data/teams/actions";
import { getAllTeams } from "../../data/teams/reducer";
import TeamTable from "./components/TeamTable";

class TeamMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchTeams, match } = this.props;
    fetchTeams();
  }

  render() {
    const { history, teams, deleteTeam } = this.props;

    const onTeamCreate = () => {
      history.push(`/team/new`);
    };

    const handleEditClick = contractor => {
      //console.log(id);
      history.push(`/team/` + contractor.id);
    };

    const onRemove = value => {
      deleteTeam(value.id);
      //history.push(`/product-admin/`)
    };

    return (
      <div>
        <div>
          <h2>Бригады</h2>
          <Button type="primary" onClick={onTeamCreate}>
            Создать
          </Button>
        </div>
        <br />
        <TeamTable
          onEditClick={handleEditClick}
          onRemove={onRemove}
          teams={teams}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    teams: getAllTeams(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchTeams: () => dispatch(fetchTeams()),
    deleteTeam: id => dispatch(deleteTeam(id)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TeamMain),
);

//export default ContractorMain
