import { combineReducers } from "redux";

import {
  FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS,
  FABSTOCK_MOVEMENT_REQUEST_DECLINE_SUCCESS,
} from "../fabStocks/actions";
import {
  FABSTOCK_MOVEMENT_REQUESTS_FETCH_SUCCESS,
  FABSTOCK_MOVEMENT_REQUEST_CREATE_SUCCESS,
  FABSTOCK_MOVEMENT_REQUEST_DELETE_SUCCESS,
  FABSTOCK_MOVEMENT_REQUEST_FETCH_SUCCESS,
  FABSTOCK_MOVEMENT_REQUEST_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case FABSTOCK_MOVEMENT_REQUESTS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockMovementRequest,
      };
    case FABSTOCK_MOVEMENT_REQUEST_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockMovementRequest,
      };
    case FABSTOCK_MOVEMENT_REQUEST_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockMovementRequest,
      };
    case FABSTOCK_MOVEMENT_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockMovementRequest,
      };
    case FABSTOCK_MOVEMENT_REQUEST_DECLINE_SUCCESS:
      return {
        ...state,
        [action.fabStockMovementRequestId]: undefined,
      };
    case FABSTOCK_MOVEMENT_REQUEST_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.fabStockMovementRequestId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FABSTOCK_MOVEMENT_REQUESTS_FETCH_SUCCESS:
      return action.response.result;
    case FABSTOCK_MOVEMENT_REQUEST_FETCH_SUCCESS:
      return state.includes(action.response.result)
        ? state
        : [...state, action.response.result];
    case FABSTOCK_MOVEMENT_REQUEST_CREATE_SUCCESS:
      return [...state, action.response.result];
    case FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS:
      return state.filter(id => id !== action.fabStockMovementRequestId);
    case FABSTOCK_MOVEMENT_REQUEST_DECLINE_SUCCESS:
      return state.filter(id => id !== action.fabStockMovementRequestId);
    case FABSTOCK_MOVEMENT_REQUEST_DELETE_SUCCESS:
      return [...state.filter(id => id != action.fabStockMovementRequestId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllFabStockMovementRequests = state => {
  return state.data.fabStockMovementRequests.allIds.map(
    id => state.data.fabStockMovementRequests.byId[id],
  );
};

export const getFabStockMovementRequest = (state, id) => {
  return state.data.fabStockMovementRequests.byId[id];
};
