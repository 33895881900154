import "./cleanInputNumber.css";

import { DeleteOutlined } from '@ant-design/icons';

import {
  Button,
  InputNumber,
  Popconfirm,
  Select as SelectAntD,
  Spin,
  Table,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import isMobile from "../../../utils/mobileCheck";

const Option = SelectAntD.Option;

const blockStyles = {
  display: "inline-block",
  verticalAlign: "top",
  marginBottom: 15,
  marginRight: 10,
};

const styles = {
  productField: {
    width: 260,
    // fontSize: "8pt",
  },
  prodDepotField: {
    width: 260,
    // fontSize: "8pt",
  },
  quantityField: {
    width: 105,
    // fontSize: "8pt",
  },
  priceField: {
    width: 105,
    // fontSize: "8pt",
  },
  totalValueField: {
    width: 160,
    // fontSize: "8pt",
  },
};

const asyncSelectStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 260,
  }),
  menu: base => ({ ...base, width: 280 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

function ShipmentFormTable(props) {
  const {
    user,
    form: { values, errors, touched, setFieldValue, setFieldError },
    disabled,
    name,
    handleDelete,
  } = props;
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [fetchedProducts, setFetchedProducts] = useState([]);

  const getProductOptions = (input, index) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    setIsFetchingProducts(true);
    return axios(`/api/products/searchwithstock?q=${input}`).then(
      ({ data }) => {
        const labeledData = data.map(item => {
          return {
            ...item,
            value: item.id,
            name: item.name,
            label: item.name,
            id: item.id,
          };
        });
        setIsFetchingProducts(false);
        setFetchedProducts(labeledData);
        if (labeledData.length === 1) {
          setFieldValue(`${name}[${index}].product`, labeledData[0]);
          setFieldValue(`${name}[${index}].prodDepotLocation_id`, null);
          document.activeElement.blur();
        }
        return labeledData;
      },
      () => setIsFetchingProducts(false),
    );
  };

  const columns = [
    {
      title: "Продукт",
      key: "product",
      render(text, record, index) {
        return (
          <SelectAntD
            size={"default"}
            name="product"
            className={"cleanSelect"}
            style={{
              border: "none transparent",
              width: isMobile() ? "350px" : "100%",
            }}
            dropdownStyle={{
              maxHeight: 100,
              overflowY: "auto",
              overflowX: "scroll",
            }}
            dropdownMenuStyle={{ overflowX: "scroll" }}
            placeholder={"Печатайте для поиска..."}
            value={
              values[name][index].product
                ? values[name][index].product.name : null
            }
            showSearch
            notFoundContent={isFetchingProducts ? <Spin size="small" /> : null}
            onSearch={value => getProductOptions(value, index)}
            filterOption={false}
            disabled={disabled}
            onChange={val => {
              setFieldValue(
                `${name}[${index}].product`,
                fetchedProducts.find(product => product.id === val),
              );
              setFieldValue(
                `${name}[${index}].prodDepotLocation_id`,
                user ? user.prodDepotLocation_id : null,
              );
            }}
          >
            {fetchedProducts.map(product => (
              <Option value={product.id} key={product.id}>
                {product.name}
              </Option>
            ))}
          </SelectAntD>
        );
      },
    },
    {
      title: "Количество",
      key: "quantity",
      width: "100px",
      render(text, record, index) {
        return (
          <InputNumber
            className={"cleanInputNumber"}
            size={"default"}
            disabled={disabled}
            placeholder="Количество"
            style={styles.quantityField}
            value={values[name][index].quantity}
            onChange={val => setFieldValue(`[${name}][${index}].quantity`, val)}
            formatter={value => {
              let newVal = value;
              if (typeof value === "number") {
                newVal = newVal.toString();
              }
              return newVal.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }}
            parser={value => value.replace(/\D*/g, "")}
          />
        );
      },
    },
    {
      title: "Цена (за шт)",
      key: "pricePerPiece",
      width: "100px",
      render(text, record, index) {
        return (
          <InputNumber
            className={"cleanInputNumber"}
            size={"default"}
            style={styles.priceField}
            placeholder="Цена"
            disabled={disabled}
            value={values[name][index].price}
            onChange={val => setFieldValue(`${name}[${index}].price`, val)}
            formatter={value => {
              let newVal = value;
              if (typeof value === "number") {
                newVal = newVal.toString();
              }
              return newVal.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }}
            parser={value =>
              value
                .replace(/\s*/g, "")
                .replace(/(^\d+\.?)(\d{0,2})?\d*/, (...args) =>
                  args[1].concat(args[2] || ""),
                )
            }
          />
        );
      },
    },
    {
      title: "К-во мешков",
      key: "numberOfBags",
      width: "100px",
      render(text, record, index) {
        return (
          <InputNumber
            className={"cleanInputNumber"}
            size={"default"}
            style={styles.priceField}
            placeholder="Количество мешков"
            disabled={disabled}
            precision={0}
            min={0}
            value={values[name][index].numberOfBags}
            onChange={val =>
              setFieldValue(`${name}[${index}].numberOfBags`, val)
            }
          />
        );
      },
    },
    {
      title: "Общая стоимость",
      key: "totalPrice",
      width: "100px",
      render(text, record, index) {
        return (
          <InputNumber
            className={"cleanInputNumber"}
            size={"default"}
            style={styles.totalValueField}
            placeholder="Стоимость"
            value={
              Number(values[name][index].quantity * values[name][index].price).toFixed(2) || 0
            }
            formatter={value =>
              value
                .replace(/(^\d+\.?)(\d{0,2})?\d*/, (...args) =>
                  args[1].concat(args[2] || ""),
                )
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
            disabled={true}
          />
        );
      },
    },
  ];

  if (!disabled) {
    columns.push({
      title: "Удалить",
      key: "delete",
      width: "50px",
      render(text, record, index) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => handleDelete(record.id)}
              type="danger"
              size="small"
              icon={<DeleteOutlined />}
            />
          </div>
        );
      },
    });
  }

  return (
    <div>
      <Table
        pagination={false}
        rowKey={"id"}
        scroll={{ x: true }}
        dataSource={values.lineItems}
        rowClassName={(record, index) =>
          errors[name] &&
          errors[name][index] &&
          touched[name] &&
          touched[name][index]
            ? "errorBackground"
            : ""
        }
        size={"small"}
        columns={columns}
      />
    </div>
  );
}

export default ShipmentFormTable;
