import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Modal } from "antd";
import axios from "axios";
import {
  createCuttingEntry,
  updateCuttingEntry,
} from "data/cuttingEntries/actions";
import { fetchDepotLocations } from "data/depotLocations/actions";
import { getAllDepotLocations } from "data/depotLocations/reducer";
import { fetchPeriods } from "data/periods/actions";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setOpenedCuttingEntryId,
  setShowCuttingEntryModal,
} from "scenes/Cutting/actions";
import CuttingEntryForm from "scenes/Cutting/forms/CuttingEntryForm.js";
import {
  getModalSelectedSpread,
  getModalSelectedSpreadId,
  getOpenedCuttingEntry,
  getOpenedCuttingEntryId,
  getOpenedCuttingOrder,
  getOpenedCuttingOrderId,
  getProdPatternsBySelectedProdSpread,
  getSelectedProdBom,
} from "scenes/Cutting/selectors";
import {
  checkAvailable,
  decodeStatus,
  generateCuttingData,
  getDefaultPrinter,
  readPrinterStatus,
  sendToPrinter,
} from "scenes/printHelper";

const confirm = Modal.confirm;

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "1600px",
        minWidth: "600px",
      },
      root: {
        zIndex: 500,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class CuttingEntryModal extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      cuttingEntryModel: {},
      selectedDay: undefined,
      weight: 0,
      fabric: null,
      depotLocation: null,
      initialValues: [],
      isSaving: false,
      periodOptions: [],
      selectedPeriod: null,
    };

    const { openedOrder, cuttingEntry } = this.props;

    if (cuttingEntry) {
      this.state.selectedDay = new moment(cuttingEntry.cut_date);
      this.state.weight = cuttingEntry.weight;

      if (cuttingEntry.lineItems) {
        let lineItems = [];
        cuttingEntry.lineItems.forEach(item => {
          lineItems.push({
            size: item.size.name,
            quantity: item.quantity,
            size_id: item.size_id,
          });
        });
        this.state.initialValues = lineItems;
      }

      if (cuttingEntry.period) {
        let period = cuttingEntry.period;
        this.state.selectedPeriod = {
          label: period.name,
          value: period.id,
          ...period,
        };
      }
      if (cuttingEntry.fabric) {
        let fabric = cuttingEntry.fabric;
        this.state.fabric = {
          label: fabric.name,
          value: fabric.id,
          ...fabric,
        };
      }
      if (cuttingEntry.depotLocation) {
        let depotLocation = cuttingEntry.depotLocation;
        this.state.depotLocation = {
          label: depotLocation.name,
          value: depotLocation.id,
          ...depotLocation,
        };
      }
    } else {
      let lineItems = [];
      if (openedOrder && openedOrder.lineItems) {
        openedOrder.lineItems.forEach(item => {
          lineItems.push({
            size: item.size.name,
            quantity: 0,
            size_id: item.size_id,
          });
        });
        this.state.initialValues = lineItems;
      }
    }
  }

  componentDidMount() {
    const { fetchPeriods, fetchDepotLocations } = this.props;
    fetchDepotLocations();
    fetchPeriods().then(periods => {
      let filteredPeriods = periods.filter(period => {
        if (!period.payroll) {
          return true;
        } else if (period.payroll.isClosed == 0) {
          return true;
        } else return false;
      });

      this.setState({
        periodOptions: filteredPeriods.map(period => {
          return { label: period.name, value: period.id, ...period };
        }),
      });
    });
  }

  close = () => {
    this.props.setShowCuttingEntryModal(false);
    this.props.setOpenedCuttingEntryId(null);
  };

  printLabelsForCuttingEntry = cuttingEntry => {
    const { openedOrder, user } = this.props;
    getDefaultPrinter()
      .then(json => {
        //check if the default printer is available for printing
        return checkAvailable().then(available => {
          let foundPrinter = undefined;
          if (available.printer && available.printer.length > 0) {
            //return json
            available.printer.forEach(availablePrinter => {
              if (availablePrinter.name == json.name) {
                foundPrinter = availablePrinter;
              }
            });
          }
          if (foundPrinter == undefined) {
            throw "Принтер не подключен";
          } else {
            //send read printer status request
            return sendToPrinter(json, "~HQES").then(data => {
              return json;
            });
          }
        });
      })
      .then(printer => {
        //check if the printer is ready to print
        return readPrinterStatus(printer)
          .then(text => {
            let status = decodeStatus(text);
            if (status == "Ready to Print") {
              let data = "";
              cuttingEntry.wages.forEach(wage => {
                data += generateCuttingData(
                  "01" + wage.id,
                  wage.prodOperation.operation,
                  wage.quantity,
                  wage.prodOperation.operationType.type,
                  wage.sum,
                  openedOrder.orderNumberStr,
                  openedOrder.product.name,
                  user ? user.domain : "",
                );
              });

              return sendToPrinter(printer, data).then(res => {
                this.close();
              });
            } else {
              throw status;
            }
          })
          .catch(error => {
            this.showConfirm(cuttingEntry, error);
          });
      })
      .catch(error => {
        this.showConfirm(cuttingEntry, error);
      });
  };

  showConfirm = (cuttingEntry, message) => {
    let self = this;
    confirm({
      title: "Попробовать напечатать заного?",
      content: message,
      onOk() {
        self.printLabelsForCuttingEntry(cuttingEntry);
      },
      onCancel() {
        self.close();
      },
    });
  };

  handleFormSubmit = (values, { setSubmitting }) => {
    console.log("values", values);

    const {
      createCuttingEntry,
      openedOrder,
      selectedProdSpreadId,
      setShowCuttingEntryModal,
      cuttingEntry,
      updateCuttingEntry,
      selectedProdBom,
    } = this.props;

    let cuttingEntryModel = {
      id: cuttingEntry ? cuttingEntry.id : null,
      cut_date: new moment(values.cutDate).format("YYYY-MM-DD HH:mm:ss"),
      weight: values.weight,
      order_id: openedOrder.id,
      prodSpread_id: selectedProdSpreadId,
      lineItems: values.sizeValues,
      period: values.period,
      prodBom_id: selectedProdBom.id,
      fabric: values.fabric,
      depotLocation: values.depotLocation,
    };

    this.setState({ isSaving: true });

    if (cuttingEntry) {
      updateCuttingEntry(openedOrder.id, cuttingEntryModel)
        .then(entry => {
          if (entry.wages.length > 0) {
            this.printLabelsForCuttingEntry(entry);
          } else {
            this.close();
          }
        })
        .catch(e => {
          this.setState({ isSaving: false });
        });
    } else {
      createCuttingEntry(openedOrder.id, cuttingEntryModel)
        .then(entry => {
          if (entry.wages.length > 0) {
            this.printLabelsForCuttingEntry(entry);
          } else {
            this.close();
          }
        })
        .catch(e => {
          this.setState({ isSaving: false });
        });
    }
  };

  handleSave = () => {
    this.form.current.submitForm();
  };

  handlePatternQuantityChange = (pattern, lineItems) => {
    let obj = {};
    obj[pattern.id] = Object.assign({}, pattern, { lineItems: lineItems });

    this.setState((prevState, props) => {
      let nextState = { ...prevState.cuttingEntryModel, ...obj };
      return { cuttingEntryModel: { ...prevState.cuttingEntryModel, ...obj } };
    });
  };

  handleDayChange = day => {
    this.setState({ selectedDay: day });
  };

  handleWeightChange = value => {
    this.setState({ weight: value });
  };

  handleSizeTableChange = lineItems => {
    this.setState({ enteredValues: lineItems });
  };

  handlePeriodChange = period => {
    this.setState({ selectedPeriod: period });
  };

  render() {
    const {
      showModal,
      cuttingEntry,
      prodPatterns,
      selectedProdBom,
      selectedProdSpread,
      user,
    } = this.props;
    const { fullScreen } = this.props;
    const { isSaving } = this.state;

    var modelArray = Object.keys(this.state.cuttingEntryModel).map(
      key => this.state.cuttingEntryModel[key],
    );

    console.log("yser yser", user);

    let buttonText = "";
    if (selectedProdSpread) {
      buttonText =
        selectedProdSpread.printOperations == 0
          ? "Создать"
          : "Создать и распечатать";
    }

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Запись раскроя"}
            </DialogTitle>
            <DialogContent>
              <CuttingEntryForm
                periodOptions={this.state.periodOptions}
                initialSizeValues={this.state.initialValues}
                ref={this.form}
                onSubmit={this.handleFormSubmit}
                cuttingEntryId={cuttingEntry ? cuttingEntry.id : null}
                initialValues={{
                  period: this.state.selectedPeriod,
                  cutDate: this.state.selectedDay,
                  weight: this.state.weight,
                  fabric: this.state.fabric,
                  depotLocation: this.state.depotLocation,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                // disabled={
                //   this.state.selectedDay == undefined ||
                //   this.state.weight <= 0 ||
                //   this.state.selectedPeriod == null
                // }
                loading={isSaving}
                type="primary"
                onClick={this.handleSave}
              >
                {buttonText}
              </Button>
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //console.log(state);
  return {
    // You can now say this.props.books

    showModal: state.scenes.cutting.showCuttingEntryModal,
    openedOrderId: getOpenedCuttingOrderId(state),
    prodPatterns: getProdPatternsBySelectedProdSpread(state),
    selectedProdSpreadId: getModalSelectedSpreadId(state),
    selectedProdSpread: getModalSelectedSpread(state),
    //selectedProdSpread: getModalSelectedSpread(state),
    openedOrder: getOpenedCuttingOrder(state),
    cuttingEntry: getOpenedCuttingEntry(state),
    cuttingEntryId: getOpenedCuttingEntryId(state),
    selectedProdBom: getSelectedProdBom(state),
    depotLocations: getAllDepotLocations(state),
    user: state.data.users.user.user,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowCuttingEntryModal: showModal =>
      dispatch(setShowCuttingEntryModal(showModal)),
    setOpenedCuttingEntryId: id => dispatch(setOpenedCuttingEntryId(id)),
    createCuttingEntry: (orderId, cuttingEntry) =>
      dispatch(createCuttingEntry(orderId, cuttingEntry)),
    updateCuttingEntry: (orderId, cuttingEntry) =>
      dispatch(updateCuttingEntry(orderId, cuttingEntry)),
    fetchPeriods: () => dispatch(fetchPeriods()),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};

CuttingEntryModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};
export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CuttingEntryModal),
);
