import {
  Card,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select as SelectAntD,
} from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import { Formik, withFormik } from "formik";
import React, { Component, useRef } from "react";
import * as Yup from "yup";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const EntrySchema = Yup.object().shape({
  quantity: Yup.number()
    .min(0, "Значение слишком маленькое")
    .max(999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  entryDate: Yup.object()
    .required("Выберите дату")
    .nullable(),
  product: Yup.object()
    .required("Выберите продукт")
    .nullable(),
  prodDepotLocation: Yup.number()
    .required("Выберите Склад")
    .nullable(),
});

class ProdStockRevisionForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {}

  submitForm = () => {
    this.form.current.submitForm();
  };

  getProductOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return axios(`/api/products/search?q=${input}`).then(({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  render() {
    const { disabled } = this.props;
    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={EntrySchema}
          onSubmit={this.props.onSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="product">
                  Продукт
                </label>
                <AsyncSelect
                  name="product"
                  loadOptions={this.getProductOptions}
                  isDisabled={disabled}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  value={values["product"]}
                  onChange={e => setFieldValue("product", e)}
                />
                <div className="help-block">
                  {errors.product && touched.product && errors.product}
                </div>
              </div>

              <div
                style={{ marginBottom: 15 }}
                className={
                  errors.prodDepotLocation && touched.prodDepotLocation
                    ? "has-error"
                    : ""
                }
              >
                <label className="mt-label" htmlFor="job_title">
                  Склад
                </label>
                <br />
                <SelectAntD
                  disabled={disabled}
                  value={values["prodDepotLocation"]}
                  style={{ width: 300 }}
                  onChange={e => setFieldValue("prodDepotLocation", e)}
                  //onBlur={e => setFieldTouched("prodDepotLocation", true)}
                >
                  {this.props.prodDepotLocationOptions}
                </SelectAntD>
                <br />
                <div className="help-block">
                  {errors.prodDepotLocation &&
                    touched.prodDepotLocation &&
                    errors.prodDepotLocation}
                </div>
              </div>

              <div
                style={{ marginBottom: 15 }}
                className={
                  errors.entryDate && touched.entryDate ? "has-error" : ""
                }
              >
                <label className="mt-label" htmlFor="prodDate">
                  Дата ревизии
                </label>
                <br />
                <DatePicker
                  id="entry_date"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  value={values["entryDate"]}
                  onChange={e => setFieldValue("entryDate", e)}
                  //onBlur={e => setFieldTouched("entryDate", true)}
                  disabled={true}
                />
                <br />
                <div className="help-block">
                  {errors.entryDate && touched.entryDate && errors.entryDate}
                </div>
              </div>

              <div
                style={{ marginBottom: 15 }}
                className={
                  errors.quantity && touched.quantity ? "has-error" : ""
                }
              >
                <label className="mt-label" htmlFor="quantity">
                  Фактическое количество (шт)
                </label>
                <br />
                <InputNumber
                  style={{ width: 300 }}
                  name="quantity"
                  placeholder="Количество"
                  value={values["quantity"]}
                  onChange={e => setFieldValue("quantity", e)}
                  disabled={disabled}
                />
                <div className="help-block">
                  {errors.quantity && touched.quantity && errors.quantity}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ProdStockRevisionForm;
