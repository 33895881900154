import { DatePicker, InputNumber, Select as SelectAntD } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import Select from "../../../components/Select";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const FabricConsumptionSchema = Yup.object().shape({
  weight: Yup.number()
    .min(1, "Значение слишком маленькое")
    .max(999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  entryDate: Yup.object()
    .required("Выберите дату")
    .nullable(),
  fabric: Yup.object()
    .required("Выберите материал")
    .nullable(),
  depotLocation: Yup.object()
    .required("Выберите склад")
    .nullable(),
});

class FabricConsumptionForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {}

  getFabricOptions = function(input) {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  submitForm = () => {
    this.form.current.submitForm();
  };

  getFabricOptions = input => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  render() {
    const { disabled } = this.props;

    function disabledDate(current) {
      return current > moment().endOf("day");
    }

    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={FabricConsumptionSchema}
          onSubmit={this.props.onSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="fabric">
                  Материал
                </label>
                <AsyncSelect
                  name="fabric"
                  loadOptions={this.getFabricOptions}
                  isDisabled={disabled}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  value={values["fabric"]}
                  onChange={e => {
                    setFieldValue("fabric", e);
                    this.props.fetchDepotLocationsWithStock(e.id);
                  }}
                  //onBlur={e => setFieldTouched("fabric", true)}
                />
                <div className="help-block">
                  {errors.fabric && touched.fabric && errors.fabric}
                </div>
              </div>
              <div
                style={{ marginBottom: 15 }}
                className={errors.weight && touched.weight ? "has-error" : ""}
              >
                <label className="mt-label" htmlFor="weight">
                  Вес (кг)
                </label>
                <br />
                <InputNumber
                  style={{ width: 300 }}
                  name="weight"
                  placeholder="Вес"
                  //onChange={this.handleInputChange}
                  value={values["weight"]}
                  onChange={e => setFieldValue("weight", e)}
                  //onBlur={e => setFieldTouched("weight", true)}
                  disabled={disabled}
                />
                <div className="help-block">
                  {errors.weight && touched.weight && errors.weight}
                </div>
              </div>
              <div
                style={{ marginBottom: 15 }}
                className={
                  errors.entryDate && touched.entryDate ? "has-error" : ""
                }
              >
                <label className="mt-label" htmlFor="prodDate">
                  Дата прихода
                </label>
                <br />
                <DatePicker
                  id="entryDate"
                  name="date"
                  disabledDate={disabledDate}
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  value={values["entryDate"]}
                  onChange={e => setFieldValue("entryDate", e)}
                  //onBlur={e => setFieldTouched("entryDate", true)}
                  disabled={disabled}
                />
                <br />
                <div className="help-block">
                  {errors.entryDate && touched.entryDate && errors.entryDate}
                </div>
              </div>
              <div
                style={{ marginBottom: 15, width: "300px" }}
                className={
                  errors.depotLocation && touched.depotLocation
                    ? "has-error"
                    : ""
                }
              >
                <label className="mt-label" htmlFor="job_title">
                  Склад
                </label>
                <br />
                <Select
                  isDisabled={disabled || !values.fabric}
                  value={values["depotLocation"]}
                  options={this.props.depotLocationsWithStock.map(depot => {
                    let weight = 0;
                    if (depot.totalWeight) {
                      weight = depot.totalWeight;
                    }
                    if (
                      this.props.match.params.id &&
                      values.fabric &&
                      this.props.initialValues.fabric &&
                      values.fabric.id === this.props.initialValues.fabric.id &&
                      this.props.initialValues.depotLocation.id === depot.id
                    ) {
                      weight += this.props.initialValues.weight;
                    }
                    return {
                      label: depot.name + ", остаток: " + weight,
                      value: depot.id,
                      ...depot,
                      weight: weight,
                    };
                  })}
                  onChange={e => setFieldValue("depotLocation", e)}
                />
                <div className="help-block">
                  {errors.depotLocation &&
                    touched.depotLocation &&
                    errors.depotLocation}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default FabricConsumptionForm;
