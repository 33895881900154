import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";
import { Button, Input, InputNumber } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import {
  createPeriod,
  fetchPeriod,
  updatePeriod,
} from "../../../data/periods/actions";
import { getPeriod } from "../../../data/periods/reducer";
import { setOpenedPeriodId } from "../actions";
import FormValidator from "./FormValidator";

class PeriodDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите название",
      },
    ]);

    if (
      props.productionProcessType ===
      ProductionProcessTypes.name.PASSPORT_WITH_FIXED_SALARY
    ) {
      this.validator.addRule({
        field: "numberOfWorkdays",
        method: "isEmpty",
        validWhen: false,
        message: "Введите количество рабочих дней",
      });
    }

    this.state = {
      isInEditState: false,
      name: "",
      numberOfWorkdays: "",

      validation: this.validator.valid(),
      isSaving: false,
    };

    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  mapErrorsToObjectMap = error => {
    let subError = new Object();
    for (var key in error.errors) {
      subError[key] = error.errors[key].message;
    }
    return subError;
  };

  createTeamFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var team = {
      name: this.state.name,
      numberOfWorkdays: this.state.numberOfWorkdays,
    };
    return team;
  };

  handleSubmit = values => {
    const { createPeriod, match, updatePeriod } = this.props;

    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
      this.setState({ isSaving: true });
      var team = this.createTeamFromValues();
      //Handle update
      if (match.params.id) {
        return updatePeriod(match.params.id, team).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/period/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });
          },
        );
      }
      //Handle create
      else {
        return createPeriod(team).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/period/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });
          },
        );
      }
    }
  };

  componentDidMount() {
    this.fetchPeriodOrResetForm();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.fetchPeriodOrResetForm();
    }
  }

  fetchPeriodOrResetForm = () => {
    const { fetchPeriod, match, setOpenedPeriodId } = this.props;
    setOpenedPeriodId("");
    if (match.params.id) {
      fetchPeriod(match.params.id).then(team => {
        setOpenedPeriodId(team.id);
        this.setState({
          isInEditState: false,
          name: team.name,
          numberOfWorkdays: team.numberOfWorkdays ? team.numberOfWorkdays : "",
        });
      });
    } else {
      this.setState({ isInEditState: true });
    }
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleNumberOfWorkdaysChange = e => {
    let newNumber = e;

    if (!e) {
      newNumber = "";
    }

    this.setState({ numberOfWorkdays: newNumber });
  };

  render() {
    const { match } = this.props;

    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    return (
      <div className="mt-paper">
        <div>
          <h2>Период</h2>
        </div>
        {match.params.id && this.state.isInEditState && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Сохранить
          </Button>
        )}
        {match.params.id == undefined && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Создать
          </Button>
        )}
        {match.params.id && !this.state.isInEditState && (
          <Button onClick={this.toggleEditState}>Изменить</Button>
        )}
        <br />
        &nbsp;
        {/* <TeamForm enableReinitialize initialValues={openedTeam} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
        <div
          style={{ marginBottom: 15 }}
          className={validation.name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="name">
            Название периода
          </label>
          <Input
            name="name"
            placeholder="Название"
            //onChange={this.handleInputChange}
            value={this.state.name}
            onChange={this.handleNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.name.message}</span>
        </div>
        {this.props.productionProcessType ===
          ProductionProcessTypes.name.PASSPORT_WITH_FIXED_SALARY && (
          <div
            style={{ marginBottom: 15 }}
            className={validation.numberOfWorkdays.isInvalid ? "has-error" : ""}
          >
            <label className="mt-label" htmlFor="numberOfWorkdays">
              Количество рабочих дней
            </label>
            <br />
            <InputNumber
              name="numberOfWorkdays"
              placeholder="Количество рабочих дней"
              value={this.state.numberOfWorkdays}
              min={1}
              style={{ width: "100%" }}
              onChange={this.handleNumberOfWorkdaysChange}
              disabled={!this.state.isInEditState}
            />
            <span className="help-block">
              {validation.numberOfWorkdays.message}
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const domainSettings = state.domainSettings;
  return {
    // You can now say this.props.books

    openedPeriod: getPeriod(state, state.scenes.period.openedPeriodId),
    productionProcessType: domainSettings.find(setting => setting.id === 1)
      .value,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchPeriod: id => dispatch(fetchPeriod(id)),
    setOpenedPeriodId: id => dispatch(setOpenedPeriodId(id)),
    createPeriod: period => dispatch(createPeriod(period)),
    updatePeriod: (id, period) => dispatch(updatePeriod(id, period)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PeriodDetail),
);
