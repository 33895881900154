import React from "react";
import ReactAsyncCreatableSelect from "react-select/lib/AsyncCreatable";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: base => ({
    ...base,
    paddingTop: 1,
    paddingBottom: 1,
  }),
};

function AsyncCreatableSelect({ ...props }) {
  let combinedStyles = { ...customStyles, ...props.styles };

  return (
    <ReactAsyncCreatableSelect
      placeholder={"Выберите"}
      {...props}
      styles={combinedStyles}
    />
  );
}

export default AsyncCreatableSelect;
