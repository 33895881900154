import { normalize, schema } from "normalizr";

// Define a users schema

export const prodStockRevision = new schema.Entity(
  "prodStockRevisions",
  {},
  { idAttribute: "id" },
);

export const arrayOfProdStockRevisions = [prodStockRevision];
