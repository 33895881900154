import { notification } from "antd";
import axios from "axios";

export const FABRIC_RETURNS_FETCH_SUCCESS = "FABRIC_RETURNS_FETCH_SUCCESS";
export const FABRIC_RETURN_FETCH_SUCCESS = "FABRIC_RETURN_FETCH_SUCCESS";
export const FABRIC_RETURN_CREATE_SUCCESS = "FABRIC_RETURN_CREATE_SUCCESS";
export const FABRIC_RETURN_UPDATE_SUCCESS = "FABRIC_RETURN_UPDATE_SUCCESS";
export const FABRIC_RETURN_DELETE_SUCCESS = "FABRIC_RETURN_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchFabricReturn = id => dispatch =>
  axios
    .get(`/api/fabricReturns/` + id)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_RETURN_FETCH_SUCCESS,
        response: data,
      });

      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const fetchFabricReturns = () => dispatch =>
  axios
    .get(`/api/fabricReturns/`)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_RETURNS_FETCH_SUCCESS,
        response: data,
      });

      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const updateFabricReturn = (id, fabricReturn) => dispatch =>
  axios
    .post("/api/fabricReturns/" + id, fabricReturn)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_RETURN_UPDATE_SUCCESS,
        response: data,
      });

      openNotificationWithIcon("Успешно", "Возврат успешно сохранён", "success");
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        if (data.errno && (data.errno == 1062 || data.errno == 19)) {
          let message = "Такой клиент уже существует";
          openNotificationWithIcon("Ошибка", message, "error");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
        }
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const createFabricReturn = fabricReturn => dispatch =>
  axios
    .post("/api/fabricReturns/", fabricReturn)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_RETURN_CREATE_SUCCESS,
        response: data,
      });

      openNotificationWithIcon("Успешно", "Возврат успешно сохранён", "success");
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        if (data.errno && (data.errno == 1062 || data.errno == 19)) {
          let message = "Такой клиент уже существует";
          openNotificationWithIcon("Ошибка", message, "error");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
        }
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const deleteFabricReturn = fabricReturnId => dispatch =>
  axios
    .delete("/api/fabricReturns/" + fabricReturnId)
    .then(({ data }) => {
      dispatch({ type: FABRIC_RETURN_DELETE_SUCCESS, fabricReturnId });
      openNotificationWithIcon("Успешно", "Возврат успешно удален", "success");
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;

        if (data.errno && (data.errno == 1451 || data.errno == 19)) {
          let message =
            "Удаление невозможно, запись используется в платежах или выгрузках";
          openNotificationWithIcon("Ошибка", message, "error");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
        }
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });
