import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const FABRICS_FETCH_SUCCESS = "FABRICS_FETCH_SUCCESS";
export const FABRIC_FETCH_SUCCESS = "FABRIC_FETCH_SUCCESS";
export const FABRIC_CREATE_SUCCESS = "FABRIC_CREATE_SUCCESS";
export const FABRIC_UPDATE_SUCCESS = "FABRIC_UPDATE_SUCCESS";
export const FABRIC_DELETE_SUCCESS = "FABRIC_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchFabrics = () => {
  return dispatch => {
    return axios
      .get(`/api/fabrics/`)
      .then(({ data }) => {
        let fabrics = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: FABRICS_FETCH_SUCCESS,
          response: normalize(fabrics, schema.arrayOfFabrics),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchFabric = fabricId => {
  return dispatch => {
    return axios
      .get(`/api/fabrics/` + fabricId)
      .then(({ data }) => {
        let fabric = data;
        let id = fabricId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: FABRIC_FETCH_SUCCESS,
          response: normalize(fabric, schema.fabric),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createFabric = fabric => {
  return dispatch => {
    return axios
      .post("/api/fabrics/", fabric)
      .then(({ data }) => {
        // Status looks good
        let fabric = data;
        dispatch({
          type: FABRIC_CREATE_SUCCESS,
          response: normalize(fabric, schema.fabric),
        });
        openNotificationWithIcon(
          "Успешно",
          "Материал успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Материал с таким названием уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateFabric = (fabricId, fabric) => {
  return dispatch => {
    return axios
      .put("/api/fabrics/" + fabricId, fabric)
      .then(({ data }) => {
        // Status looks good
        let fabric = data;
        dispatch({
          type: FABRIC_UPDATE_SUCCESS,
          response: normalize(fabric, schema.fabric),
        });
        openNotificationWithIcon(
          "Успешно",
          "Материал успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Материал с таким названием уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteFabric = fabricId => {
  return dispatch => {
    return axios
      .delete("/api/fabrics/" + fabricId)
      .then(({ data }) => {
        dispatch({ type: FABRIC_DELETE_SUCCESS, fabricId });
        openNotificationWithIcon(
          "Успешно",
          "Материал успешно удален",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Невозможно удалить потомучто в этот материал уже используется в заказах";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
