import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";

const openedContractorId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_CONTRACTOR_ID":
      return action.contractorId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedContractorId,
  data: dataReducer,
});
