import { Table } from "antd";
import { nanoid } from "nanoid";
import React from "react";

const { NEW, EDIT, NORMAL } = {
  NEW: "NEW",
  EDIT: "EDIT",
  NORMAL: "NORMAL",
};

export function FabricConsumptionFormTable(props) {
  const {
    prodBomIndex,
    prodSpreadIndex,
    openedOrder,
    setShowSearchFabricModal,
    form: { errors, touched, values, setFieldValue, status },
    data,
  } = props;

  return (
    <Table
      size={"small"}
      pagination={false}
      bordered
      rowKey={"key"}
      rowClassName={(record, index) =>
        errors &&
        errors.fabricConsumptions &&
        errors.fabricConsumptions[prodBomIndex] &&
        errors.fabricConsumptions[prodBomIndex][prodSpreadIndex] &&
        errors.fabricConsumptions[prodBomIndex][prodSpreadIndex][index] &&
        touched &&
        touched.fabricConsumptions &&
        touched.fabricConsumptions[prodBomIndex] &&
        touched.fabricConsumptions[prodBomIndex][prodSpreadIndex] &&
        touched.fabricConsumptions[prodBomIndex][prodSpreadIndex][index]
          ? "errorBackground"
          : ""
      }
      columns={[
        {
          title: "Материал",
          dataIndex: "fabric",
          key: "fabric",
          width: "auto",
          render(value, record, index) {
            const copyRow = () => {
              const fabricConsumptionsWithExtraRow = [
                ...values.fabricConsumptions[prodBomIndex][prodSpreadIndex],
              ];
              fabricConsumptionsWithExtraRow.push({
                ...values.fabricConsumptions[prodBomIndex][prodSpreadIndex][
                  index
                ],
                id: null,
                fabric: null,
                key: nanoid(5),
                depotLocation: null,
                weight: 0,
                lineItems: openedOrder.lineItems.map(lineItem => ({
                  sizeId: lineItem.size.id,
                  sizeName: lineItem.size.name,
                  quantity: 0,
                })),
              });
              setFieldValue(
                `fabricConsumptions[${prodBomIndex}][${prodSpreadIndex}]`,
                fabricConsumptionsWithExtraRow,
              );
            };
            const deleteRow = () => {
              const fabricConsumptionsWithDeletedRow = [
                ...values.fabricConsumptions[prodBomIndex][prodSpreadIndex],
              ];
              fabricConsumptionsWithDeletedRow.splice(index, 1);
              setFieldValue(
                `fabricConsumptions[${prodBomIndex}][${prodSpreadIndex}]`,
                fabricConsumptionsWithDeletedRow,
              );
            };
            return {
              children: (
                <span>
                  <span>
                    {status === NORMAL && value.name}
                    {[NEW, EDIT].includes(status) &&
                      (value === null ? (
                        <span
                          onClick={() =>
                            setShowSearchFabricModal(
                              true,
                              prodBomIndex,
                              prodSpreadIndex,
                              index,
                            )
                          }
                        >
                          <a>Указать</a>
                        </span>
                      ) : (
                        <span>
                          {
                            <span
                              onClick={() => {
                                setFieldValue(
                                  `fabricConsumptions[${prodBomIndex}][${prodSpreadIndex}][${index}]`,
                                  {
                                    ...values.fabricConsumptions[prodBomIndex][
                                      prodSpreadIndex
                                    ][index],
                                    id: null,
                                    fabric: null,
                                    weight: 0,
                                    depotLocation: null,
                                    lineItems: values.fabricConsumptions[
                                      prodBomIndex
                                    ][prodSpreadIndex][index].lineItems.map(
                                      lineItem => ({
                                        ...lineItem,
                                        quantity: 0,
                                      }),
                                    ),
                                  },
                                );
                              }}
                            >
                              <a
                                style={{
                                  color: "red",
                                }}
                              >
                                X
                              </a>
                            </span>
                          }
                          &nbsp;
                          {value.name}
                        </span>
                      ))}
                    {[NEW, EDIT].includes(status) &&
                      values.fabricConsumptions[prodBomIndex][prodSpreadIndex]
                        .length > 1 && (
                        <span
                          style={{
                            marginLeft: "5px",
                            float: "right",
                          }}
                        >
                          <a
                            onClick={deleteRow}
                            style={{
                              color: "red",
                            }}
                          >
                            -
                          </a>
                        </span>
                      )}
                    {[NEW, EDIT].includes(status) &&
                      index ===
                        values.fabricConsumptions[prodBomIndex][prodSpreadIndex]
                          .length -
                          1 && (
                        <span style={{ float: "right" }} onClick={copyRow}>
                          <span>
                            <a
                              style={{
                                color: "green",
                              }}
                            >
                              +
                            </a>
                          </span>
                        </span>
                      )}
                  </span>
                </span>
              ),
            };
          },
        },
        ...openedOrder.lineItems.map((lineItem, lineItemIndex) => ({
          title: lineItem.size.name,
          dataIndex: "lineItems",
          key: "sizeName" + lineItemIndex,
          align: "center",
          width: "auto",
          render(value, record, index) {
            return value.find(item => item.sizeId === lineItem.size.id)
              .quantity;
          },
        })),
        {
          title: "Всего скроено",
          key: "lineItemsSum",
          align: "center",
          render(value, record, index) {
            return record.lineItems.reduce(
              (acc, curr) => acc + curr.quantity,
              0,
            );
          },
        },
        {
          title: "Вес (кг)",
          dataIndex: "weight",
          key: "weight",
          align: "center",
          width: "auto",
          render(value, record, index) {
            return values.fabricConsumptions[prodBomIndex][prodSpreadIndex][
              index
            ].weight;
          },
        },
        {
          title: "Склад",
          dataIndex: "depotLocation",
          key: "depotLocation",
          align: "center",
          width: "auto",
          render(value, record, index) {
            return {
              props: {
                style: { height: "38px" },
              },
              children: value ? value.name : null,
            };
          },
        },
      ]}
      dataSource={data}
    />
  );
}
