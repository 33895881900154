import "./index.css";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Tabs } from "antd";
import { Button, Card, Col, Input, InputNumber, Row } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import OperationTable from "../../../../../components/OperationTable";
import { setOpenedBom, setShowBomModal } from "../../actions";
import { addBom, saveBom } from "../../data/boms/actions";
import { getOpenedBom } from "../../selectors";

//import Button from 'material-ui/Button';

//import { Modal, Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
//import { Tabs, Tab, Nav, NavItem } from 'react-bootstrap';

const TabPane = Tabs.TabPane;

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the styleSheet
      paperWidthSm: {
        // Name of the rule
        maxWidth: "800px",
        minWidth: "800px",
      },
      root: {
        alignItems: "initial",

        zIndex: 500,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class BomDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      operation: "",
      price: "",
      cuttingOperations: [],
      sewingOperations: [],
      packingOperations: [],
      activeTab: 1,
    };
  }

  close = () => {
    this.props.setShowBomModal(false);
    this.props.setOpenedBom("");
  };

  handleCreate = () => {
    // const {product, addBom} =this.props;
    // addBom(product.id,{ name: this.state.name })
    // .then(spread=>{
    //     this.props.setShowBomModal(false);
    // })

    //////-------------
    const { product, addBom } = this.props;
    //this.props.onNastilDetailCreate({ name: this.state.name, material: this.state.selectedMaterial });
    let operations = [];
    this.state.cuttingOperations.forEach(op => {
      operations.push({ ...op, operationType_id: 1 });
    });
    this.state.sewingOperations.forEach(op => {
      operations.push({ ...op, operationType_id: 2 });
    });
    this.state.packingOperations.forEach(op => {
      operations.push({ ...op, operationType_id: 3 });
    });

    let bom = {
      name: this.state.name,
      operations: operations,
      // sewingOperations: this.state.sewingOperations,
      // packingOperations: this.state.packingOperations,
    };
    addBom(bom);
    this.props.setShowBomModal(false);
  };
  handleUpdate = () => {
    // const {product, updateBom, openedBom} = this.props;
    // updateBom(product.id,{id: openedBom.id, name: this.state.name })
    // .then(bom=>{
    //     this.props.setShowBomModal(false);
    // })

    const { openedBom, saveBom } = this.props;

    let operations = [];
    this.state.cuttingOperations.forEach(op => {
      operations.push({ ...op, operationType_id: 1 });
    });
    this.state.sewingOperations.forEach(op => {
      operations.push({ ...op, operationType_id: 2 });
    });
    this.state.packingOperations.forEach(op => {
      operations.push({ ...op, operationType_id: 3 });
    });

    let bom = {
      id: openedBom.id,
      name: this.state.name,
      operations: operations,
    };

    saveBom(bom);
    this.props.setShowBomModal(false);
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    console.log("yes received new props");
    //You don't have to do this check first, but it can help prevent an unneeded render

    if (nextProps.openedBom) {
      if (nextProps.openedBom !== this.props.openedBom) {
        //console.log("opened semi product ", nextProps.openedPattern)
        this.setState({
          name: nextProps.openedBom.name,
          cuttingOperations: [
            ...nextProps.openedBom.operations.filter(
              ops => ops.operationType_id == 1,
            ),
          ],
          sewingOperations: [
            ...nextProps.openedBom.operations.filter(
              ops => ops.operationType_id == 2,
            ),
          ],
          packingOperations: [
            ...nextProps.openedBom.operations.filter(
              ops => ops.operationType_id == 3,
            ),
          ],
        });
      }
    } else {
      this.setState({
        operation: "",
        price: "",
        cuttingOperations: [],
        sewingOperations: [],
        packingOperations: [],
        name: "",
      });
    }
  }

  handleCuttingAdd = () => {
    var cuttingOperation = {
      operation: this.state.operation,
      price: this.state.price,
    };
    this.setState(state => ({
      cuttingOperations: state.cuttingOperations.concat(cuttingOperation),
    }));
    this.setState({ operation: "", price: "" });
  };
  handleSewingAdd = () => {
    var sewingOperation = {
      operation: this.state.operation,
      price: this.state.price,
    };
    this.setState(state => ({
      sewingOperations: state.sewingOperations.concat(sewingOperation),
    }));
    this.setState({ operation: "", price: "" });
  };

  handlePackingAdd = () => {
    var packingOperation = {
      operation: this.state.operation,
      price: this.state.price,
    };
    this.setState(state => ({
      packingOperations: state.packingOperations.concat(packingOperation),
    }));
    this.setState({ operation: "", price: "" });
  };

  handlePriceChange = e => {
    this.setState({ price: e });
  };
  handleOperationChange = e => {
    this.setState({ operation: e.target.value });
  };

  handleCuttingRemove = e => {
    var newArr = [...this.state.cuttingOperations];
    newArr.splice(e, 1);
    this.setState({ cuttingOperations: newArr });
  };
  handleSewingRemove = e => {
    var newArr = [...this.state.sewingOperations];
    newArr.splice(e, 1);
    this.setState({ sewingOperations: newArr });
  };

  handlePackingRemove = e => {
    var newArr = [...this.state.packingOperations];
    newArr.splice(e, 1);
    this.setState({ packingOperations: newArr });
  };
  handleTabChange = e => {};

  render() {
    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    //coming from HOC
    const { fullScreen, classes } = this.props;

    const { showModal, openedBom, disabled } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
            //classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle id="responsive-dialog-title">
              {openedBom != undefined
                ? "Часть: " + openedBom.name
                : "Добавить часть"}
            </DialogTitle>
            <DialogContent>
              <div style={{ marginBottom: 15 }}>
                <label htmlFor="description">Название</label>
                <Input
                  disabled={disabled}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  type="text"
                  id="description"
                />
              </div>

              <Tabs defaultActiveKey="1" onChange={this.handleTabChange}>
                {/* <Nav bsStyle="pills">
                                    <NavItem eventKey="закройный">
                                        Закройный
                                    </NavItem>
                                    <NavItem eventKey="швейный">
                                        Швейный
                                    </NavItem>
                                    <NavItem eventKey="упаковка">
                                        Упаковка
                                    </NavItem>
                                </Nav> */}

                <TabPane tab="Закройный" key="1">
                  <h4 style={styles.headline}>Закройные операции</h4>

                  <Row>
                    <Col sm={24}>
                      <label className="mt-label" htmlFor="operation">
                        {" "}
                        Операция:&nbsp;
                      </label>
                      <Input
                        style={{ width: 200 }}
                        placeholder="Операция"
                        value={this.state.operation}
                        onChange={this.handleOperationChange}
                        type="text"
                        id="operation"
                      />
                      &nbsp;&nbsp;
                      <label className="mt-label" htmlFor="price">
                        {" "}
                        Цена:&nbsp;
                      </label>
                      <InputNumber
                        placeholder="Цена"
                        value={this.state.price}
                        onChange={this.handlePriceChange}
                        type="text"
                        id="price"
                      />
                      &nbsp;&nbsp;
                      <Button
                        disabled={
                          this.state.operation.length <= 0 ||
                          this.state.price <= 0 ||
                          this.state.price == undefined ||
                          disabled
                        }
                        onClick={this.handleCuttingAdd}
                      >
                        Добавить
                      </Button>
                    </Col>
                  </Row>
                  <p />

                  <OperationTable
                    disabled={disabled}
                    operations={this.state.cuttingOperations}
                    onRemove={this.handleCuttingRemove}
                  />
                </TabPane>
                <TabPane tab="Швейный" key="2">
                  <h4 style={styles.headline}>Швейные операции</h4>
                  <Row>
                    <Col sm={24}>
                      <label className="mt-label" htmlFor="operation">
                        {" "}
                        Операция:&nbsp;
                      </label>
                      <Input
                        style={{ width: 200 }}
                        placeholder="Операция"
                        value={this.state.operation}
                        onChange={this.handleOperationChange}
                        type="text"
                        id="operation"
                      />
                      &nbsp;&nbsp;
                      <label className="mt-label" htmlFor="price">
                        {" "}
                        Цена:&nbsp;
                      </label>
                      <InputNumber
                        placeholder="Цена"
                        value={this.state.price}
                        onChange={this.handlePriceChange}
                        type="text"
                        id="price"
                      />
                      &nbsp;&nbsp;
                      <Button
                        disabled={
                          this.state.operation.length <= 0 ||
                          this.state.price <= 0 ||
                          this.state.price == undefined ||
                          disabled
                        }
                        onClick={this.handleSewingAdd}
                      >
                        Добавить
                      </Button>
                    </Col>
                  </Row>
                  <p />

                  <OperationTable
                    disabled={disabled}
                    operations={this.state.sewingOperations}
                    onRemove={this.handleSewingRemove}
                  />
                </TabPane>
                <TabPane tab="Упаковка" key="3">
                  <h4 style={styles.headline}>Упаковочные операции</h4>
                  <Row>
                    <Col sm={24}>
                      <label className="mt-label" htmlFor="operation">
                        {" "}
                        Операция:&nbsp;
                      </label>
                      <Input
                        style={{ width: 200 }}
                        placeholder="Операция"
                        value={this.state.operation}
                        onChange={this.handleOperationChange}
                        type="text"
                        id="operation"
                      />
                      &nbsp;&nbsp;
                      <label className="mt-label" htmlFor="price">
                        {" "}
                        Цена:&nbsp;
                      </label>
                      <InputNumber
                        placeholder="Цена"
                        value={this.state.price}
                        onChange={this.handlePriceChange}
                        type="text"
                        id="price"
                      />
                      &nbsp;&nbsp;
                      <Button
                        disabled={
                          this.state.operation.length <= 0 ||
                          this.state.price <= 0 ||
                          this.state.price == undefined ||
                          disabled
                        }
                        onClick={this.handlePackingAdd}
                      >
                        Добавить
                      </Button>
                    </Col>
                  </Row>
                  <p />

                  <OperationTable
                    disabled={disabled}
                    operations={this.state.packingOperations}
                    onRemove={this.handlePackingRemove}
                  />
                </TabPane>
              </Tabs>
            </DialogContent>
            <DialogActions>
              {openedBom == undefined && (
                <Button
                  type="primary"
                  disabled={this.state.name.length <= 0 || disabled}
                  onClick={this.handleCreate}
                >
                  Создать
                </Button>
              )}
              {openedBom != undefined && (
                <Button
                  type="primary"
                  disabled={this.state.name.length <= 0 || disabled}
                  onClick={this.handleUpdate}
                >
                  Сохранить
                </Button>
              )}
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

BomDetail.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  //console.log(state);
  return {
    // You can now say this.props.books

    openedBom: getOpenedBom(state),
    //product: getProduct(state, state.scenes.product.openedProduct),
    showModal: state.scenes.forms.specform.showBomModal,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowBomModal: showModal => dispatch(setShowBomModal(showModal)),
    addBom: bom => dispatch(addBom(bom)),
    saveBom: bom => dispatch(saveBom(bom)),
    setOpenedBom: spreadId => dispatch(setOpenedBom(spreadId)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BomDetail),
);
