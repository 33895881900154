// import { createSelector } from 'reselect'
// import  * as orders  from "../../data/orders/selectors"

// export const getOpenedContractor=(state)=>state.scenes.contractor.openedServiceOrder

import { createSelector } from "reselect";

import * as employeesPayroll from "../../data/employeesPayroll/selectors";
import * as payrolls from "../../data/payrolls/selectors";

export const getOpenedPayrollId = state => state.scenes.payroll.openedPayrollId;

export const getPayrollRowsForSelectedPayroll = createSelector(
  [
    employeesPayroll.getEmployeesPayroll,
    payrolls.getPayrolls,
    payrolls.getPayrollEntriesByEmployeeId,
    payrolls.getPayrollEntriesById,
    payrolls.getPayrollAttendanceEntriesByEmployeeId,
    payrolls.getPayrollAttendanceEntriesById,
    getOpenedPayrollId,
  ],
  (
    employees,
    payrolls,
    entriesByEmployeeId,
    entriesById,
    attendanceEntriesByEmployeeId,
    attendanceEntriesById,
    payrollId,
  ) => {
    let rows = [];

    //console.log(employees.allIds);

    let rowNum = 1;

    employees.allIds.forEach(employeeId => {
      let rowObject = { rowNum: rowNum };

      let entryForEmployee = entriesByEmployeeId[employeeId];

      //rowObject['team_name']=employee.id;
      // console.log("entry", entry);

      if (entryForEmployee) {
        let employee = employees.byId[employeeId];

        rowObject["id"] = employee.id;
        rowObject["first_name"] = employee.first_name;
        rowObject["last_name"] = employee.last_name;
        rowObject["salary"] = employee.salary;
        rowObject["team_name"] = employee.team.name;
        rowObject["isActive"] = employee.isActive;

        //console.log("entryForEmployee",entryForEmployee)
        //console.log("entryForEmployee2",entriesById)

        let columnsObj = {};

        entryForEmployee.forEach(id => {
          let entry = entriesById[id];
          columnsObj[entry.name] = { sum: entry.sum, id: id };
        });

        rowObject["wage"] = columnsObj.wage ? columnsObj.wage.sum : null;

        rowObject["plastic"] = columnsObj.plastic
          ? columnsObj.plastic.sum
          : null;
        rowObject["tax"] = columnsObj.tax ? columnsObj.tax.sum : null;
        rowObject["electricity"] = columnsObj.electricity
          ? columnsObj.electricity.sum
          : null;
        rowObject["gas"] = columnsObj.gas ? columnsObj.gas.sum : null;
        rowObject["goods"] = columnsObj.goods ? columnsObj.goods.sum : null;
        rowObject["advance"] = columnsObj.advance
          ? columnsObj.advance.sum
          : null;
        rowObject["cash"] = columnsObj.cash ? columnsObj.cash.sum : null;

        rowObject["wageId"] = columnsObj.wage ? columnsObj.wage.id : null;
        rowObject["bonusId"] = columnsObj.bonus ? columnsObj.bonus.id : null;
        rowObject["bonus"] = columnsObj.bonus ? columnsObj.bonus.sum : null;

        rowObject["plasticId"] = columnsObj.plastic
          ? columnsObj.plastic.id
          : null;
        rowObject["taxId"] = columnsObj.tax ? columnsObj.tax.id : null;
        rowObject["electricityId"] = columnsObj.electricity
          ? columnsObj.electricity.id
          : null;
        rowObject["gasId"] = columnsObj.gas ? columnsObj.gas.id : null;
        rowObject["goodsId"] = columnsObj.goods ? columnsObj.goods.id : null;
        rowObject["advanceId"] = columnsObj.advance
          ? columnsObj.advance.id
          : null;
        rowObject["cashId"] = columnsObj.cash ? columnsObj.cash.id : null;

        const attendanceEntryId = attendanceEntriesByEmployeeId[employeeId];
        rowObject["attendance"] = attendanceEntryId
          ? attendanceEntriesById[attendanceEntryId].quantity
          : null;

        rows.push(rowObject);
        rowNum++;
      }

      //console.log("row",rowObject)
    });

    //console.log("row",rows)
    return rows;
  },
);
