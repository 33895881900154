import { Button, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { deleteFabricReturn, fetchFabricReturns } from "../../data/fabricReturns/actions";
import isMobile from "../../utils/mobileCheck";
import FabricReturnTable from "./components/FabricReturnTable";

function FabricReturnMain(props) {
  const { fetchFabricReturns, history, fabricReturns, deleteFabricReturn } = props;

  const [isFetchingFabricReturns, setIsFetchingFabricReturns] = useState(false);

  useEffect(() => {
    setIsFetchingFabricReturns(true);
    fetchFabricReturns().then(
      () => setIsFetchingFabricReturns(false),
      () => setIsFetchingFabricReturns(false),
    );
  }, []);

  const onCreateClick = () => {
    history.push("/fabricReturn/new");
  };

  const onDeleteClick = fabricReturnId => {
    deleteFabricReturn(fabricReturnId);
  };

  return (
    <div>
      <h2>Возврат материалов</h2>
      <div>
        <Button
          style={isMobile() ?  { width: "100%" } : {}}
          type={"primary"}
          onClick={onCreateClick}
        >
          Создать
        </Button>
      </div>
      <br />
      <FabricReturnTable
        isFetchingContractors={isFetchingFabricReturns}
        fabricReturns={fabricReturns}
        history={history}
        onDelete={onDeleteClick}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  fabricReturns: state.data.fabricReturns.all,
});

const mapDispatchToProps = dispatch => ({
  fetchFabricReturns: () => dispatch(fetchFabricReturns()),
  deleteFabricReturn: fabricReturnId => dispatch(deleteFabricReturn(fabricReturnId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabricReturnMain),
);
