import { Breadcrumb, Button, Dropdown, Layout, Menu, Select } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchDepotLocations } from "../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../data/depotLocations/reducer";
import {
  deleteDirectFabricConsumption,
  fetchFabricConsumptions,
} from "../../data/fabricConsumptions/actions";
import FabricConsumptionTable from "./components/FabricConsumptionTable";

function FabricConsumptionMain(props) {
  const {
    history,
    fabricConsumptions,
    fetchFabricConsumptions,
    fetchDepotLocations,
    deleteFabDepotEntry,
    pagesCount,
  } = props;

  const [loading, setLoading] = useState(true);
  const [depotLocationOptions, setDepotLocationOptions] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [fabTransactionTypeId, setFabTransactionTypeId] = useState(4);

  const onFabricConsumptionCreate = () => {
    history.push(`/fabricConsumption/new`);
  };

  const handleEditClick = fabricConsumption => {
    history.push(`/fabricConsumption/` + fabricConsumption.id);
  };

  const onRemove = value => {
    deleteFabDepotEntry(value.id);
  };

  const handleDeleteDirectFabricConsumption = id => {
    props.deleteDirectFabricConsumption(id).then(() => {
      setLoading(true);
      fetchFabricConsumptions(
        pageSize,
        pageIndex + 1,
        fabTransactionTypeId,
      ).then(() => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    fetchDepotLocations().then(options => {
      setDepotLocationOptions(options);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchFabricConsumptions(pageSize, pageIndex + 1, fabTransactionTypeId).then(
      () => {
        setLoading(false);
      },
    );
  }, [pageIndex, pageSize, fabTransactionTypeId]);

  return (
    <div>
      <div>
        <h2>Расходы материала</h2>
        <Button type="primary" onClick={onFabricConsumptionCreate}>
          Создать
        </Button>
        <Select
          style={{ float: "right" }}
          value={fabTransactionTypeId}
          onChange={e => {
            setFabTransactionTypeId(e);
            setPageIndex(0);
          }}
        >
          <Select.Option value={6}>Прямой расход</Select.Option>
          <Select.Option value={4}>Расход производства</Select.Option>
        </Select>
      </div>
      <br />
      <FabricConsumptionTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        fabricConsumptions={fabricConsumptions}
        depotLocationOptions={depotLocationOptions}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pagesCount={pagesCount}
        fabTransactionTypeId={fabTransactionTypeId}
        deleteDirectFabricConsumption={handleDeleteDirectFabricConsumption}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    fabricConsumptions: state.data.fabricConsumptions.data,
    depotLocations: getAllDepotLocations(state),
    pagesCount: state.data.fabricConsumptions.pagesCount,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFabricConsumptions: (pageSize, pageIndex, fabTransactionTypeId) =>
      dispatch(
        fetchFabricConsumptions(pageSize, pageIndex, fabTransactionTypeId),
      ),
    deleteDirectFabricConsumption: id =>
      dispatch(deleteDirectFabricConsumption(id)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabricConsumptionMain),
);
