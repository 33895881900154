export const SET_OPENED_PAYROLL_ID = "SET_OPENED_PAYROLL_ID";

export const SET_SHOW_PAYROLLCREATE_MODAL = "SET_SHOW_PAYROLLCREATE_MODAL";

export const setOpenedPayrollId = payrollId => ({
  type: SET_OPENED_PAYROLL_ID,
  payrollId,
});

export const setShowPayrollCreateModal = showPayrollCreateModal => ({
  type: SET_SHOW_PAYROLLCREATE_MODAL,
  showPayrollCreateModal,
});
