import { combineReducers } from "redux";

import { boms } from "./boms/reducer";
import { patterns } from "./patterns/reducer";
import { spreads } from "./spreads/reducer";

export const getPatterns = state => state.scenes.forms.specform.data.patterns;
export const getSpreads = state => state.scenes.forms.specform.data.spreads;
export const getBoms = state => state.scenes.forms.specform.data.boms;

export const reducer = combineReducers({
  patterns,
  boms,
  spreads,
});
