import { Breadcrumb, Button, Dropdown, Layout, Menu } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchDepotLocations } from "../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../data/depotLocations/reducer";
import {
  deleteFabStockMovementRequest,
  fetchFabStockMovementRequests,
} from "../../data/fabStockMovementRequest/actions";
import { getAllFabStockMovementRequests } from "../../data/fabStockMovementRequest/reducer";
import { deleteFabStock, fetchFabStocks } from "../../data/fabStocks/actions";
import { getAllFabStocks } from "../../data/fabStocks/reducer";
import { fetchTeams } from "../../data/teams/actions";
import FabStockMovementRequestTable from "./components/FabStockMovementRequestTable";
import FabStockTable from "./components/FabStockTable";

function FabStockMain(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    fabStocks,
    fabStockMovementRequests,
    fetchFabStockMovementRequests,
    deleteFabStockMovementRequest,
    fetchFabStocks,
    fetchDepotLocations,
    deleteFabStock,
  } = props;

  const [fabStockLoading, setFabStockLoading] = useState(() => {
    if (fabStocks.length > 0) {
      return false;
    } else return true;
  });

  const [
    fabStockMovementRequestLoading,
    setFabStockMovementRequestLoading,
  ] = useState(() => {
    if (fabStockMovementRequests.length > 0) {
      return false;
    } else return true;
  });

  const [searchEnabled, setSearchEnabled] = useState(false);
  //const [fabDepotEntries, setFabDepotEntries] = useState(0);
  const [count, setCount] = useState(0);
  const [depotLocationOptions, setDepotLocationOptions] = useState([]);

  const onFabStockCreate = () => {
    history.push(`/fabstock/new`);
  };

  const onMovementRequestCreate = () => {
    history.push(`/fabstock/movementrequest/new`);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/fabstock/` + contractor.id);
  };

  const onRemove = value => {
    // const {deleteEmployee} =props;
    deleteFabStock(value.id);
  };

  const onFabStockMovementRequestEditClick = id => {
    history.push("/fabstock/movementrequest/" + id);
  };

  useEffect(() => {
    fetchFabStocks().then(depot => {
      setFabStockLoading(false);
    });

    fetchFabStockMovementRequests().then(() => {
      setFabStockMovementRequestLoading(false);
    });

    fetchDepotLocations().then(options => {
      setDepotLocationOptions(options);
    });
  }, []);

  return (
    <div>
      <div>
        {fabStockMovementRequests.length > 0 && (
          <h3>Запросы на передвижение материалов</h3>
        )}
      </div>
      {fabStockMovementRequests.length > 0 && (
        <div>
          <FabStockMovementRequestTable
            loading={fabStockMovementRequestLoading}
            fabStockMovementRequests={fabStockMovementRequests}
            onEditClick={onFabStockMovementRequestEditClick}
          />
          <br />
        </div>
      )}
      <h2>Движение материалов</h2>
      <Button type={"primary"} onClick={onMovementRequestCreate}>
        Создать запрос
      </Button>
      <Dropdown
        overlay={
          <Menu onClick={() => history.push("/logs/fabStockMovement")}>
            <Menu.Item>Посмотреть в журнале</Menu.Item>
          </Menu>
        }
      >
        <Button style={{ marginLeft: "0.5em" }}>...</Button>
      </Dropdown>
      <br />
      <br />
      <FabStockTable
        loading={fabStockLoading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        fabStocks={fabStocks}
        depotLocationOptions={depotLocationOptions}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    depotLocations: getAllDepotLocations(state),
    fabStocks: getAllFabStocks(state),
    fabStockMovementRequests: getAllFabStockMovementRequests(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchFabStocks: () => dispatch(fetchFabStocks()),
    fetchFabStockMovementRequests: () =>
      dispatch(fetchFabStockMovementRequests()),
    deleteFabStockMovementRequest: id =>
      dispatch(deleteFabStockMovementRequest(id)),
    deleteFabStock: id => dispatch(deleteFabStock(id)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabStockMain),
);

//export default ContractorMain
