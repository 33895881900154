import "./index.css";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Card, Col, Input, InputNumber, Modal, Row, Tabs } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { createSpec } from "../../../../../../data/specs/actions";
import SpecForm from "../../../../../forms/SpecForm";

const TabPane = Tabs.TabPane;

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the styleSheet
      paperWidthSm: {
        // Name of the rule
        maxWidth: "900px",
        minWidth: "900px",
      },
      root: {
        alignItems: "initial",
        zIndex: 300,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class CreateSpecDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };

    this.formRef = React.createRef();
  }

  close = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleCreate = () => {
    const { onClose, createSpec, onSaveSuccess } = this.props;

    this.formRef.current.validate((err, spec) => {
      if (err) {
        return;
      }

      this.setState({ loading: true });

      console.log("yes yes inside else");
      return createSpec({ name: spec.name, boms: spec.boms }).then(
        json => {
          this.setState({ loading: false });
          onSaveSuccess(json);
        },
        error => {
          this.setState({ loading: false });
          if (error.name) {
            this.formRef.current.setNameExists(true);
          }
          // let subError=this.mapErrorsToObjectMap(error);
          // throw new SubmissionError(subError)
        },
      );
    });
  };
  handleUpdate = () => {};

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    //coming from HOC
    const { fullScreen, classes } = this.props;

    const { showModal, openedBom } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
            //classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle id="responsive-dialog-title">
              Создать спецификацию
            </DialogTitle>
            <DialogContent>
              <SpecForm ref={this.formRef} />
            </DialogContent>
            <DialogActions>
              <Button
                type="primary"
                onClick={this.handleCreate}
                loading={this.state.loading}
              >
                Создать
              </Button>
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

CreateSpecDetail.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  //console.log(state);
  return {};
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    createSpec: employee => dispatch(createSpec(employee)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateSpecDetail),
);
