import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const PRODSTOCKS_FETCH_SUCCESS = "PRODSTOCKS_FETCH_SUCCESS";
export const PRODSTOCK_FETCH_SUCCESS = "PRODSTOCK_FETCH_SUCCESS";
export const PRODSTOCK_CREATE_SUCCESS = "PRODSTOCK_CREATE_SUCCESS";
export const PRODSTOCK_UPDATE_SUCCESS = "PRODSTOCK_UPDATE_SUCCESS";
export const PRODSTOCK_DELETE_SUCCESS = "PRODSTOCK_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchProdStocks = () => {
  return dispatch => {
    return axios
      .get(`/api/prodstocks/`)
      .then(({ data }) => {
        dispatch({
          type: PRODSTOCKS_FETCH_SUCCESS,
          response: normalize(data, schema.arrayOfProdStocks),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchProdStock = stockId => {
  return dispatch => {
    return axios
      .get(`/api/prodstocks/` + stockId)
      .then(({ data }) => {
        dispatch({
          type: PRODSTOCK_FETCH_SUCCESS,
          response: normalize(data, schema.prodStock),
          id: stockId,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createProdStock = prodStock => {
  return dispatch => {
    return axios
      .post("/api/prodstocks/", prodStock)
      .then(({ data }) => {
        // Status looks good
        dispatch({
          type: PRODSTOCK_CREATE_SUCCESS,
          response: normalize(data, schema.prodStock),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateProdStock = (entryId, entry) => {
  return dispatch => {
    return axios
      .put("/api/prodstocks/" + entryId, entry)
      .then(({ data }) => {
        dispatch({
          type: PRODSTOCK_UPDATE_SUCCESS,
          response: normalize(data, schema.prodStock),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteProdStock = prodStockId => {
  return dispatch => {
    return axios
      .delete("/api/prodstocks/" + prodStockId)
      .then(({ data }) => {
        dispatch({ type: PRODSTOCK_DELETE_SUCCESS, prodStockId });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данные этой записи используются в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          //console.log("Parsing eror: Most likely server crashed");

          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
