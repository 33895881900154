import { createSelector } from "reselect";

export const getWages = state => state.data.wages;
export const getEmployees = state => state.data.employees;

export const getWagesForSelectedEmployee = state => {
  let wages = getWages(state);
  let employees = getEmployees(state);

  if (
    state.scenes.wagescan.selectedEmployeeId &&
    employees.byId[state.scenes.wagescan.selectedEmployeeId]
  ) {
    let employee = employees.byId[state.scenes.wagescan.selectedEmployeeId];
    if (employee.wages) {
      return employee.wages.map(wageId => wages.byId[wageId]);
    }
  }

  return [];
};

export const getTotalForSelectedEmployee = state => {
  let wages = getWages(state);
  let employees = getEmployees(state);

  if (
    state.scenes.wagescan.selectedEmployeeId &&
    employees.byId[state.scenes.wagescan.selectedEmployeeId]
  ) {
    let employee = employees.byId[state.scenes.wagescan.selectedEmployeeId];

    if (employee.wages)
      return employee.wages
        .map(wageId => wages.byId[wageId])
        .reduce(function(acc, val) {
          return acc + val.sum;
        }, 0);
  }

  return 0;
};

export const getEmployeesByPage = (state, pageNum, numberPerPage) => {
  //pageNum 1
  //Number per page 50

  let beginIndex = pageNum * numberPerPage;

  if (beginIndex > state.data.employees.allIds.length) {
    return [];
  }
  let res = [];

  for (let i = beginIndex; i < state.data.employees.allIds.length; i++) {
    let currentId = state.data.employees.allIds[i];
    res.push(state.data.employees.byId[currentId]);
  }

  return res;
};

//export const getOpenedProdEntry=(state)=>orders.getProdEntries(state).byId[getOpenedProdEntryId(state)]
