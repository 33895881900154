import { formatAttendanceReportType } from "@app/shared/utils/AttendanceReportUtils";
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";

import { useCallbackProxy } from "../../../../utils/useCallbackProxy";

export function AttendanceReportTable({
  loading,
  onDelete,
  attendanceReports,
  onFetchDataForPage,
  onSetCurrentDataPage,
  onSetPageSize,
  defaultPageSize,
}) {
  const handleDelete = useCallbackProxy(onDelete);
  const [pages, setPages] = useState(-1);

  const columns = useMemo(
    () => [
      {
        id: "id",
        maxWidth: 200,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <Link to={`/attendance-report/${original.type}/${original.id}`}>
            REP-{original.id}
          </Link>
        ),
      },

      {
        id: "type",
        sortable: false,
        filterable: false,
        Header: "Тип",
        Cell: ({ original }) => formatAttendanceReportType(original.type),
      },

      {
        id: "name",
        sortable: false,
        filterable: false,
        Header: "Название",
        Cell: ({ original }) => original.name,
      },

      {
        id: "status",
        sortable: false,
        filterable: false,
        Header: "Статус",
        Cell: ({ original }) =>
          original.failed_at != null
            ? "Ошибка генерации"
            : original.result != null
            ? "Сгенерирован"
            : "Генерируется",
      },

      {
        id: "actions",
        maxWidth: 44,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <Popconfirm
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            title="Уверены что хотите удалить эту запись?"
            onConfirm={() => {
              handleDelete(original);
            }}
          >
            <Button type="danger" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      },
    ],
    [],
  );

  return (
    <ReactTable
      defaultFilterMethod={(filter, row) =>
        String(row[filter.id]) === filter.value
      }
      className="-striped -highlight"
      data={attendanceReports || []}
      getTdProps={() => (!loading ? {} : { className: "pt-skeleton" })}
      columns={columns}
      defaultPageSize={defaultPageSize}
      previousText="Пред"
      nextText="След"
      loadingText="Загрузка..."
      noDataText={loading ? "" : "Нет записей"}
      pageText="Страница"
      ofText="из"
      rowsText="строк"
      manual
      pages={pages}
      onFetchData={state => {
        onSetPageSize(state.pageSize);
        onSetCurrentDataPage(state.page);
        onFetchDataForPage(state.page, state.pageSize).then(res => {
          if (res) {
            setPages(Math.ceil(res.total / state.pageSize));
          }
        });
      }}
      freezeWhenExpanded={true}
    />
  );
}
