//import "react-table/react-table.css";

import { Button } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

//import { Panel } from 'react-bootstrap';

class ProdSummaryTable extends Component {
  constructor(props) {
    super(props);
  }

  makeTransferedRow(entries) {
    const { lineItems } = this.props;
    let entriesBySum = {};

    lineItems.forEach((lineItem, index) => {
      let sum = entries
        .filter(entry => entry.size.id == lineItem.size.id)
        .reduce(function(acc, val) {
          return acc + val.quantity;
        }, 0);
      entriesBySum[index] = sum;
    });

    //----------

    let row = {
      header_description: "Передано в цех",
    };

    lineItems.forEach((item, index) => {
      row[index] = entriesBySum[index];
    });
    return row;
  }

  makeProducedRow(entries) {
    const { lineItems } = this.props;
    let entriesBySum = {};

    lineItems.forEach((lineItem, index) => {
      let sum = entries
        .filter(entry => {
          let areAllClaimed = true;
          entry.wages.forEach(wage => {
            if (wage.isClaimed === 0) {
              areAllClaimed = false;
            }
          });

          return (
            entry.size.id == lineItem.size.id &&
            areAllClaimed &&
            entry.wages.length > 0
          );
        })
        .reduce(function(acc, val) {
          return acc + val.quantity;
        }, 0);

      entriesBySum[index] = sum;
    });

    //----------

    let row = {
      header_description: "Сшито",
    };

    lineItems.forEach((item, index) => {
      row[index] = entriesBySum[index];
    });
    return row;
  }

  makeEmptryRow(pattern) {
    const { lineItems } = this.props;
    let row = {
      header_description: " - ",
    };

    lineItems.forEach((item, index) => {
      row[index] = "";
    });
    return row;
  }

  makeAlreadyCutRow(pattern) {
    const { lineItems, cuttingEntries } = this.props;

    let cuttingItemsByProdPatternId = this.makeCuttingItemsByPatternId(
      cuttingEntries,
    );

    let row = {
      pattern_name: pattern.name + " (Раскроено)",
    };

    lineItems.forEach((item, index) => {
      let cuttingItems = cuttingItemsByProdPatternId[pattern.id];
      if (cuttingItems) {
        let itemsForThisSize = cuttingItems.filter(
          cuttingItem => cuttingItem.size.name == item.size.name,
        );
        let sum = itemsForThisSize
          .map(item => item.quantity)
          .reduce((acc, curr) => {
            return acc + curr;
          }, 0);

        row[index] = sum;
      } else {
        row[index] = 0;
      }
    });

    return row;
  }

  makeCuttingItemsByPatternId(cuttingEntries) {
    let cuttingItemsByProdPatternId = {};

    if (cuttingEntries.length > 0) {
      cuttingEntries.forEach(cuttingEntry => {
        cuttingEntry.cuttingItems.forEach(cuttingItem => {
          if (cuttingItemsByProdPatternId[cuttingItem.prodPattern_id]) {
            cuttingItemsByProdPatternId[cuttingItem.prodPattern_id] = [
              ...cuttingItemsByProdPatternId[cuttingItem.prodPattern_id],
              cuttingItem,
            ];
          } else {
            cuttingItemsByProdPatternId[cuttingItem.prodPattern_id] = [
              cuttingItem,
            ];
          }
        });
      });
    }
    return cuttingItemsByProdPatternId;
  }

  makeData() {
    let data = [];
    const {
      disabled,
      onPatternDoubleClick,
      prodEntries,
      lineItems,
    } = this.props;

    // prodEntries.forEach((entry, index) => {

    let transferedRow = this.makeTransferedRow(prodEntries);
    data.push(transferedRow);

    let producedRow = this.makeProducedRow(prodEntries);
    data.push(producedRow);

    // let alreadyCutRow=this.makeAlreadyCutRow(entry);
    // data.push(alreadyCutRow);

    // if (index < patterns.length-1) {
    //     let emptyRow = this.makeEmptryRow(entry);
    //     data.push(emptyRow);
    // }
    //})
    return data;
  }

  calculateTotalForRow(row) {
    const { lineItems } = this.props;

    //console.log("ROW ROW ", row);
    if (row.pattern_name == " - ") {
      return "";
    }
    let total = 0;
    lineItems.forEach((item, index) => {
      total += row[index];
    });
    return total;
  }

  render() {
    const {
      disabled,
      onPatternDoubleClick,
      lineItems,
      onViewRecordsClick,
      numberOfRows,
    } = this.props;

    // const data = [{
    //     name: 'Tanner Linsley',
    //     age: 26,
    //     friend: {
    //         name: 'Jason Maurer',
    //         age: 23,
    //     }
    // }]
    let data = this.makeData();

    const columns = [
      {
        Header: "",
        accessor: "header_description", // String-based value accessors!
        minWidth: 200,
        // Footer: (
        //     <span>
        //         <Button type="secondary" onClick={onViewRecordsClick}>Просмотреть записи</Button>

        //     </span>
        //   )
        // Cell: props => <span>{new moment(props.value).format('L')}</span> // Custom cell components!
      },
    ];

    lineItems.forEach((item, index) => {
      let col = {
        Header: item.size.name,
        accessor: index + "",
        // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
      };
      columns.push(col);
    });

    let totalCol = {
      Header: "Итого",
      // accessor: "name",
      Cell: row => (
        <span className="number">
          {this.calculateTotalForRow(row.original)}
        </span>
      ), // Custom cell components!
    };
    columns.push(totalCol);

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        showPagination={false}
        sortable={false}
        //defaultPageSize={data.length}
        defaultPageSize={numberOfRows != undefined ? numberOfRows : 20}
      />
    );
  }
}

export default ProdSummaryTable;
