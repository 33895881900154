import { normalize, schema } from "normalizr";

// Define a users schema

export const pattern = new schema.Entity("patterns", {}, { idAttribute: "id" });

export const spread = new schema.Entity(
  "spreads",
  {
    patterns: [pattern],
  },
  { idAttribute: "id" },
);

export const bom = new schema.Entity(
  "boms",
  {
    spreads: [spread],
  },
  { idAttribute: "id" },
);

export const arrayOfSpreads = [spread];

export const product = new schema.Entity(
  "products",
  {
    boms: [bom],
  },
  { idAttribute: "id" },
);

export const arrayOfProducts = [product];
