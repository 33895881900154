import { Card, Input } from "antd";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function TablePropertiesForm(props) {
  const {
    properties,
    isInEditMode,
    handleChangeTableConfig,
    values,
    table,
    keyOfTableName,
    title,
    style,
  } = props;

  return (
    <Card
      style={style}
      title={
        <span>
          <span>Название: </span>{" "}
          <Input
            disabled={!isInEditMode}
            style={{ marginLeft: "10px", width: "300px" }}
            value={values["tables"][table][keyOfTableName]}
            onChange={e =>
              handleChangeTableConfig(table, keyOfTableName, e.target.value)
            }
          />
          <span style={{ float: "right" }}>{title}</span>
        </span>
      }
    >
      {properties.map((property, index) => (
        <div key={index} style={{ marginBottom: "20px", height: "35px" }}>
          <span style={{ display: "inline-block", verticalAlign: "middle" }}>
            {property.label}
          </span>
          <span
            style={{
              width: "300px",
              float: "right",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            <Input
              disabled={!isInEditMode}
              value={values["tables"][table][property.propertyName]}
              onChange={e =>
                handleChangeTableConfig(
                  table,
                  property.propertyName,
                  e.target.value,
                )
              }
            />
          </span>
        </div>
      ))}
    </Card>
  );
}

export default TablePropertiesForm;
