import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm } from "antd";
import React, { Component } from "react";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import SizeTable from "../../../../../components/SizeTable";

class PivotComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: [],
    };
  }

  cancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  componentDidMount() {
    console.log("component mounted");

    const { cuttingEntry, row } = this.props;
    this.setInitialSizeValues(cuttingEntry);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cuttingEntry != this.props.cuttingEntry) {
      this.setInitialSizeValues(nextProps.cuttingEntry);
    }
  }

  handleClick = () => {
    this.props.onHeaderClick(this.props.value);
  };

  handleEditCuttingEntry = () => {
    const { cuttingEntries, row, onEditClicked } = this.props;
    onEditClicked(row.original);
  };

  handleDeleteCuttingEntry = () => {
    const { cuttingEntries, row, onDelete } = this.props;
    onDelete(row.original);
  };

  setInitialSizeValues = cuttingEntry => {
    console.log("SET SIZE VALUES", cuttingEntry);

    let lineItems = [];
    if (cuttingEntry.lineItems) {
      cuttingEntry.lineItems.forEach(item => {
        lineItems.push({
          size: item.size.name,
          quantity: item.quantity,
          size_id: item.size_id,
        });
      });
      this.setState(
        {
          initialValues: lineItems,
        },
        () => {
          console.log("line items", this.state.initialValues);
        },
      );
    } else {
      this.setState(
        {
          initialValues: [],
        },
        () => {
          console.log("line items", this.state.initialValues);
        },
      );
    }
  };

  handleSizeTableChange = lineItems => {
    //this.setState({enteredValues: lineItems})
  };

  // getInitialCuttingItems = () => {

  //     const { cuttingEntries, row } = this.props;
  //     let cuttingEntryId=row.original.id;

  //     let foundEntry=cuttingEntries.find(entry=>entry.id==cuttingEntryId);
  //     let cuttingItems= foundEntry.cuttingItems;

  //     let initialVals = [];

  //     let mappedItems = cuttingItems.map(item => {
  //         return {
  //             size:  item.size.name,
  //             quantity: item.quantity,
  //             prodPattern_id: item.prodPattern_id,
  //             size_id: item.size.id
  //         }
  //     });
  //     initialVals = initialVals.concat(mappedItems)
  //     return initialVals;
  // }

  render() {
    //const { cuttingEntries, prodPatterns } = this.props;
    const { disabled } = this.props;
    return (
      <div>
        <Card title="Количество по размерам" bordered={true}>
          <SizeTable
            enableReinitialize
            onChange={this.handleSizeTableChange}
            initialValues={this.state.initialValues}
            disabled={true}
          />
        </Card>
        <br />
        <Button
          disabled={disabled}
          type="primary"
          onClick={this.handleEditCuttingEntry}
        >
          Редактировать
        </Button>
        &nbsp;
        {!disabled ? (
          <Popconfirm
            title="Уверены что хотите удалить эту запись?"
            onConfirm={this.handleDeleteCuttingEntry}
            onCancel={this.cancel}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        ) : (
          <Button type="danger" icon={<DeleteOutlined />} disabled>
            Удалить
          </Button>
        )}
      </div>
    );
  }
}

export default PivotComponent;
