import { Popconfirm } from "antd";
import matchSorter from "match-sorter";
import React from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

function ProdDepotEntryTable(props) {
  const handleDeleteConfirm = (row, e) => {
    const { onRemove } = props;
    onRemove(row);
  };

  const { prodDepotLocations, onEditClick, loading } = props;
  const columns = [
    {
      Header: "",
      accessor: "id",
      maxWidth: 100,
      filterable: false,
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            onEditClick(row.original, e);
          }}
          href={"/prodDepotLocation/" + row.original.id}
        >
          {"PDEP-" + row.value}
        </a>
      ),
    },
    {
      Header: "Имя",
      accessor: "name",
      id: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Удалить",
      filterable: false,
      maxWidth: 100,
      Cell: row => (
        <div>
          <Popconfirm
            title="Уверены что хотите удалить эту запись?"
            onConfirm={e => handleDeleteConfirm(row.original, e)}
            okText="Да"
            cancelText="Нет"
          >
            <a href="javascript:;">Удалить</a>
          </Popconfirm>
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <ReactTable
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        className="-striped -highlight"
        data={loading ? [] : prodDepotLocations}
        getTdProps={(state, rowInfo, column) => {
          if (loading) {
            return {
              className: "pt-skeleton",
            };
          } else {
            return {};
          }
        }}
        columns={columns}
        defaultPageSize={50}
        previousText="Пред"
        nextText="След"
        loadingText="Загрузка..."
        noDataText={loading ? "" : "Нет записей"}
        pageText="Страница"
        ofText="из"
        rowsText="строк"
      />
    </div>
  );
}

export default withRouter(ProdDepotEntryTable);
