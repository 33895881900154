//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Input } from "antd";
import Select from "components/Select";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { createPayroll } from "../../../../data/payrolls/actions";
import { fetchPeriods } from "../../../../data/periods/actions";
import FormValidator from "../../../FormValidator";
import { setShowPayrollCreateModal } from "../../actions";

//import './index.css';
const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "400px",
      },
      root: {
        //alignItems: "initial"
        zIndex: 500,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class PayrollCreateModal extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите название",
      },
      {
        field: "periodField",
        method: () => {
          if (this.state.selectedPeriod) {
            return false;
          } else {
            return true;
          }
          // return true
        },
        validWhen: false,
        message: "Выберите период",
      },
    ]);

    this.state = {
      name: "",
      activeTab: 1,
      periodOptions: [],
      periodField: "",
      isSaving: false,
      validation: this.validator.valid(),
    };
    this.submitted = false;
  }

  componentDidUpdate(prevProps) {
    //console.log("yes received new props", this.props);
    //let this.props=this.props;
    //You don't have to do this check first, but it can help prevent an unneeded render
    //fetchTeams();
    //    if (this.props.showModal!=prevProps.showModal) {
    //         this.setState({
    //             selectedPeriod: null,
    //             name: '',
    //         })
    //         this.submitted=false;
    //     }
  }

  close = () => {
    ///this.props.setOpenedPattern("")
    this.props.setShowPayrollCreateModal(false);
  };

  creatPayrollFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var payroll = {
      name: this.state.name,
      period: this.state.selectedPeriod,
    };
    return payroll;
  };

  create = () => {
    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
      this.setState({ isSaving: true });
      let payroll = this.creatPayrollFromValues();
      this.props.createPayroll(payroll).then(
        json => {
          this.setState({ isSaving: false });
          this.props.onCreated();
          this.close();
        },
        error => {
          this.setState({ isSaving: false });
        },
      );
    }
    // const {addPattern, selectedSpreadId} =this.props;
    // //this.props.onNastilDetailCreate({ name: this.state.name, material: this.state.selectedMaterial });
    // let pattern = {
    //     name: this.state.name,
    // }

    // console.log("SELEDDFDF", selectedSpreadId)
    // addPattern( pattern, selectedSpreadId)
    //     this.props.setShowPatternModal(false);
  };

  handlePeriodChange = period => {
    this.setState({ selectedPeriod: period });
  };

  componentDidMount() {
    this.props.fetchPeriods().then(periods => {
      this.setState({
        periodOptions: periods
          .filter(period => {
            return period.payroll === null ? 1 : !period.payroll.isClosed;
          })
          .map(period => {
            return { label: period.name, value: period.id, ...period };
          }),
      });
    });
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    const { showModal, openedPattern } = this.props;
    //coming from HOC
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Ведомость</DialogTitle>
            <DialogContent>
              {/* <div className="form-inline">
                                <div className="form-group">
                                    <label htmlFor="name"> Название детали:</label>&nbsp;&nbsp;
                                    <input placeholder="Введите название" value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="name" />
                                </div>
                            </div> */}

              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="article">
                  Название ведомости
                </label>
                <Input
                  name="article"
                  placeholder="Название ведомости"
                  //onChange={this.handleInputChange}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  //disabled={!this.state.isInEditState}
                />
                <span className="help-block">{validation.name.message}</span>
              </div>

              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="period">
                  Период
                </label>
                <Select
                  id="period"
                  options={this.state.periodOptions}
                  //clearable={this.state.clearable}
                  //name="selected-state"
                  //disabled={this.state.disabled}
                  //value={this.state.selectValue}
                  //onChange={this.updateValue}
                  //rtl={this.state.rtl}
                  searchable={true}
                  value={this.state.selectedPeriod}
                  onChange={this.handlePeriodChange}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                />
                <span className="help-block">
                  {validation.periodField.message}
                </span>
              </div>

              <p />

              <p />
            </DialogContent>
            <DialogActions>
              <Button
                type="primary"
                onClick={this.create}
                loading={this.state.isSaving}
              >
                Создать
              </Button>
              }<Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

PayrollCreateModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

//export default withMobileDialog()(SpreadDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //selectedSpreadId: state.scenes.product.selectedSpread,
    //selectedSpreadId: state.scenes.payroll.specform.selectedSpread,
    showModal: state.scenes.payroll.showPayrollCreateModal,
    //openedPattern: getOpenedPattern(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowPayrollCreateModal: showModal =>
      dispatch(setShowPayrollCreateModal(showModal)),

    fetchPeriods: () => dispatch(fetchPeriods()),
    createPayroll: payroll => dispatch(createPayroll(payroll)),
    //addPattern:  (pattern, spreadId) => dispatch(addPattern(pattern, spreadId)),
    //savePattern: ( pattern) => dispatch(savePattern( pattern)),
    //setOpenedPattern: (patternId) => dispatch(setOpenedPattern(patternId))
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PayrollCreateModal),
);
