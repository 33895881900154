import { nanoid } from "nanoid";

export default (passportEntry, prodBoms, openedOrder) => {
  if (passportEntry) {
    return {
      period: {
        ...passportEntry.period,
        label: passportEntry.period.name,
        value: passportEntry.period.id,
      },
      team: passportEntry.team
        ? {
            ...passportEntry.team,
            label: passportEntry.team.name,
            value: passportEntry.team.id,
          }
        : null,
      prodBoms: prodBoms.map(prodBom =>
        prodBom.prodSpreads
          .filter(prodSpread => prodSpread.printOperations)
          .map(prodSpread => {
            const operations = [];
            for (const prodOperation of prodBom.prodOperations.filter(
              operation => operation.operationType_id === 1,
            )) {
              const operationsToPush = passportEntry.wages.filter(
                wage =>
                  wage.prodBom_id === prodBom.id &&
                  wage.prodSpread_id === prodSpread.id &&
                  wage.prodOperation.id === prodOperation.id,
              );

              if (operationsToPush.length > 0) {
                operations.push(
                  ...operationsToPush.map(wage => ({
                    key: wage.id,
                    prodBomId: wage.prodBom_id,
                    prodSpreadId: wage.prodSpread_id,
                    operation: wage.prodOperation,
                    prodOperation: wage.prodOperation.operation,
                    employee: wage.employee,
                    quantity: wage.quantity,
                  })),
                );
              } else {
                operations.push({
                  key: nanoid(5),
                  prodBomId: prodBom.id,
                  prodSpreadId: prodSpread.id,
                  operation: prodOperation,
                  prodOperation: prodOperation.operation,
                  employee: null,
                  quantity: 0,
                });
              }
            }
            return operations;
          }),
      ),
      fabricConsumptions: prodBoms.map(prodBom =>
        prodBom.prodSpreads
          .filter(prodSpread => prodSpread.printOperations)
          .map(prodSpread =>
            passportEntry.fabricConsumptions
              .filter(
                fabricConsumption =>
                  fabricConsumption.prodBom_id === prodBom.id &&
                  fabricConsumption.prodSpread_id === prodSpread.id,
              )
              .map(fabricConsumption => ({
                id: fabricConsumption.id,
                key: fabricConsumption.id,
                prodSpreadId: fabricConsumption.prodSpread_id,
                prodBomId: fabricConsumption.prodBom_id,
                fabric: fabricConsumption.fabric,
                depotLocation: fabricConsumption.depotLocation,
                weight: fabricConsumption.weight,
                lineItems: fabricConsumption.lineItems.map(lineItem => ({
                  sizeId: lineItem.size.id,
                  sizeName: lineItem.size.name,
                  quantity: lineItem.quantity,
                })),
              })),
          ),
      ),
    };
  } else {
    return {
      period: null,
      team: null,
      prodBoms: prodBoms.map(prodBom =>
        prodBom.prodSpreads
          .filter(prodSpread => prodSpread.printOperations)
          .map(prodSpread =>
            prodBom.prodOperations
              .filter(operation => operation.operationType_id === 1)
              .map((operation, key) => ({
                key: nanoid(5),
                prodBomId: prodBom.id,
                prodSpreadId: prodSpread.id,
                operation: operation,
                prodOperation: operation.operation,
                employee: null,
                quantity: 0,
              })),
          ),
      ),
      fabricConsumptions: prodBoms.map(prodBom =>
        prodBom.prodSpreads
          .filter(prodSpread => prodSpread.printOperations)
          .map(prodSpread => [
            {
              key: nanoid(5),
              prodSpreadId: prodSpread.id,
              prodBomId: prodBom.id,
              fabric: null,
              depotLocation: null,
              weight: 0,
              lineItems: openedOrder.lineItems.map(lineItem => ({
                sizeId: lineItem.size.id,
                sizeName: lineItem.size.name,
                quantity: 0,
              })),
            },
          ]),
      ),
    };
  }
};
