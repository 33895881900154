import NotificationContainer from "./NotificationContainer";
import AttendanceRoute from "./scenes/Attendance/route";
import AttendanceReportRoute from "./scenes/AttendanceReport/route";
import ClientRoute from "./scenes/Client/route";
import ContractorRoute from "./scenes/Contractor/route";
import CuttingRoute from "./scenes/Cutting/route";
import DepotLocationRoute from "./scenes/DepotLocation/route";
import DomainSettingsRoute from "./scenes/DomainSettings/route";
import EmployeeRoute from "./scenes/Employee/route";
import FabDepotRoute from "./scenes/FabDepot/route";
import FabricReturnRoute from "./scenes/FabricReturn/route";
import FabricRoute from "./scenes/Fabric/route";
import FabricConsumptionRoute from "./scenes/FabricConsumption/route";
import FabStockRoute from "./scenes/FabStock/route";
import FabStockBalanceRoute from "./scenes/FabStockBalance/route";
import FabStockRevisionRoute from "./scenes/FabStockRevision/route";
import Home from "./scenes/Home/Home";
import LogRoute from "./scenes/Log/route";
import PaymentRoute from "./scenes/Payment/route";
import ContractorPaymentRoute from "./scenes/ContractorPayment/route";
import PayrollRoute from "./scenes/Payroll/route";
import PeriodRoute from "./scenes/Period/route";
import ProdDepotEntryRoute from "./scenes/ProdDepotEntry/route";
import ProdDepotLocationRoute from "./scenes/ProdDepotLocation/route";
import ProdStockRoute from "./scenes/ProdStock/route";
import ProdStockBalanceRoute from "./scenes/ProdStockBalance/route";
import ProdStockRevisionRoute from "./scenes/ProdStockRevision/route";
import ProductRoute from "./scenes/Product/route";
import ProductionRoute from "./scenes/Production/route";
import ProductionStats from "./scenes/ProductionStats/route";
import ShipmentRoute from "./scenes/Shipment/route";
import ShipmentReport from "./scenes/ShipmentReport";
import SizeRoute from "./scenes/Size/route";
import SpecificationRoute from "./scenes/Specification/route";
import SplitRoute from "./scenes/Split/route";
import TeamRoute from "./scenes/Team/route";
import UserRoute from "./scenes/User/route";
import WageRoute from "./scenes/WageScan/route";
import WorkingScheduleRoute from "./scenes/WorkingSchedule/route";
import isMobile from "./utils/mobileCheck";

var dashRoutes = [
  {
    collapse: true,
    name: "Производство",
    roles: [1, 2, 3],
    icon: "right-circle",
    state: "prodCollapse",
    views: [
      {
        path: "/production",
        roles: [1, 2, 3],
        name: "Производство",
        mini: "ПР",
        component: ProductionRoute,
        layout: "",
      },
      {
        path: "/cutting",
        roles: [1, 2, 3],
        name: "Карта производства",
        mini: "КП",
        component: CuttingRoute,
        layout: "",
      },
      // {
      //   path: "/production-report",
      //   roles: [1, 2, 3],
      //   name: "Статистика",
      //   mini: "СТ",
      //   component: StatisticsRoute,
      //   layout: "",
      // },
      {
        path: "/productionstats",
        roles: [1, 2, 3],
        name: "Статистика",
        mini: "СТ",
        component: ProductionStats,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Модели",
    roles: [1, 2, 3, 5],
    icon: "tag",
    state: "productCollapse",
    views: [
      {
        path: "/product-admin",
        roles: [1, 2, 3, 5],
        name: "Все модели",
        mini: "ПР",
        component: ProductRoute,
        layout: "",
      },
      {
        path: "/specification",
        name: "Спецификации",
        roles: [1, 2, 3],
        mini: "СП",
        component: SpecificationRoute,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Сотрудники",
    roles: [1, 2, 3],
    icon: "team",
    state: "formsCollapse",
    views: [
      {
        path: "/employee",
        name: "Все сотрудники",
        roles: [1, 2, 3],
        mini: "СО",
        component: EmployeeRoute,
        layout: "",
      },
      {
        path: "/wagescan",
        name: "Сканировать сотрудника",
        roles: [1, 2, 3],
        mini: "СК",
        component: WageRoute,
        layout: "",
      },
      {
        path: "/split",
        name: "Разделить штрих код",
        disabledForProductionProcessTypes: ["passport"],
        roles: [1, 2, 3],
        mini: "РА",
        component: SplitRoute,
        layout: "",
      },
      {
        path: "/team",
        name: "Бригады",
        roles: [1, 2, 3],
        mini: "БР",
        component: TeamRoute,
        layout: "",
      },
      {
        path: "/payroll",
        name: "Ведомости",
        roles: [1, 2, 3],
        mini: "ВЕ",
        component: PayrollRoute,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Учёт материалов",
    icon: "database",
    roles: [1, 2, 3, 7],
    state: "tablesCollapse",
    views: [
      {
        path: "/fabricConsumption",
        name: "Расходы",
        roles: [1, 2, 3],
        mini: "РА",
        component: FabricConsumptionRoute,
        layout: "",
      },
      {
        path: "/fabdepot",
        name: "Приходы",
        roles: [1, 2, 3],
        mini: "ПР",
        component: FabDepotRoute,
        layout: "",
      },
      {
        path: "/fabricReturn",
        roles: [1, 2, 3, 7],
        name: "Возвраты",
        mini: "ВЗВРТ",
        component: FabricReturnRoute,
        layout: "",
      },
      {
        path: "/fabstock",
        name: "Запасы",
        roles: [1, 2, 3],
        mini: "ЗА",
        component: FabStockRoute,
        layout: "",
      },
      {
        path: "/depotlocation",
        roles: [1, 2, 3],
        name: "Складские помещения",
        mini: "СП",
        component: DepotLocationRoute,
        layout: "",
      },
      {
        path: "/fabstockrevision",
        roles: [1, 2, 3],
        name: "Ревизии материалов",
        mini: "МА",
        component: FabStockRevisionRoute,
        layout: "",
      },
      {
        path: "/fabric-admin",
        roles: [1, 2, 3],
        name: "Все материалы",
        mini: "МА",
        component: FabricRoute,
        layout: "",
      },
      {
        path: "/fabstockbalance",
        roles: [1, 2, 3],
        name: "Остатки материалов",
        mini: "МА",
        component: FabStockBalanceRoute,
        layout: "",
      },
      {
        path: "/contractorPayment",
        roles: [7],
        name: "Платежи",
        mini: "CPYM",
        component: ContractorPaymentRoute,
        layout: "",
      },
      {
        path: "/contractor",
        name: "Подрядчики",
        roles: [1, 2, 3, 7],
        mini: "ПО",
        component: ContractorRoute,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Продажи",
    icon: "tag",
    roles: [5, 6],
    state: "salesCollapse",
    views: [
      {
        path: "/shipment",
        roles: [5, 6],
        name: "Отгрузки",
        mini: "МА",
        component: ShipmentRoute,
        layout: "",
      },
      {
        path: "/payment",
        roles: [6],
        name: "Платежи",
        mini: "PYM",
        component: PaymentRoute,
        layout: "",
      },
      {
        path: "/client",
        roles: [5, 6],
        name: "Клиенты",
        mini: "CL",
        component: ClientRoute,
        layout: "",
      },
      {
        path: "/shipmentReport",
        roles: [5, 6],
        name: "Отчет о продажах",
        mini: "ОП",
        component: ShipmentReport,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Склад Продукции",
    icon: "database",
    roles: [1, 2, 3, 5],
    state: "prodDepotCollapse",
    views: [
      {
        path: "/proddepotentry",
        name: "Приходы",
        roles: [1, 2, 3, 5],
        mini: "ПР",
        component: ProdDepotEntryRoute,
        layout: "",
      },
      {
        path: "/prodstock",
        name: "Запасы",
        roles: [1, 2, 3, 5],
        mini: "ЗА",
        component: ProdStockRoute,
        layout: "",
      },
      {
        path: "/prodDepotLocation",
        roles: [1, 2, 3, 5],
        name: "Складские помещения",
        mini: "СП",
        component: ProdDepotLocationRoute,
        layout: "",
      },
      {
        path: "/prodstockrevision",
        roles: [1, 2, 3],
        name: "Ревизии продукции",
        mini: "МА",
        component: ProdStockRevisionRoute,
        layout: "",
      },
      {
        path: "/prodstockbalance",
        roles: [1, 2, 3, 5],
        name: "Остатки продукции",
        mini: "МА",
        component: ProdStockBalanceRoute,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Охрана",
    roles: [1, 2, 3, 4],
    icon: "appstore",
    state: "securityCollapse",
    views: [
      {
        path: "/attendance",
        roles: [1, 2, 3, 4],
        name: "Приход уход",
        mini: "ПР",
        component: AttendanceRoute,
        layout: "",
      },
      {
        path: "/attendance-report",
        name: "Отчет посещаемости",
        roles: [1, 2, 3],
        mini: "ОППР",
        component: AttendanceReportRoute,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Настройки",
    roles: [1, 2, 3],
    icon: "setting",
    state: "settingsCollapse",
    views: [
      {
        path: "/user",
        name: "Пользователи",
        roles: [1],
        mini: "ПО",
        component: UserRoute,
        layout: "",
      },
      {
        path: "/workingschedule",
        name: "График Работы",
        roles: [1, 2, 3],
        mini: "ГР",
        component: WorkingScheduleRoute,
        layout: "",
      },
      {
        path: "/size",
        name: "Размеры",
        roles: [1, 2, 3],
        mini: "РА",
        component: SizeRoute,
        layout: "",
      },
      {
        path: "/period",
        name: "Периоды",
        roles: [1, 2, 3],
        mini: "ПЕ",
        rtlMini: "زم",
        component: PeriodRoute,
        layout: "",
      },
      {
        path: "/domainSettings",
        name: "Общие настройки",
        roles: [1],
        mini: "ОН",
        component: DomainSettingsRoute,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Журнал",
    roles: [1, 2, 3],
    icon: "file",
    state: "logsCollapse",
    views: [
      {
        path: "/logs",
        name: "Журнал",
        roles: [1, 2, 3],
        mini: "ЖУ",
        component: LogRoute,
        layout: "",
      },
    ],
  },
  {
    path: "/",
    hidden: true,
    roles: [1, 2, 3],
    name: "Home",
    icon: "",
    component: Home,
    layout: "",
  },
];
export default dashRoutes;
