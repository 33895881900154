import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { addNotification } from "./actions";

class NotificationContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem;
  }

  componentWillReceiveProps(newProps) {
    const { message, level, title } = newProps.notification;
    this.notificationSystem.addNotification({
      title,
      message,
      level,
    });
  }

  render() {
    return <NotificationSystem ref="notificationSystem" />;
  }
}

function mapStateToProps(state) {
  return {
    notification: state.notification,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addNotification,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer);
