import { combineReducers } from "redux";

import commonComponentsReducer from "./Common/reducer";
import { reducer as contractorReducer } from "./Contractor/reducer";
import { reducer as cuttingReducer } from "./Cutting/reducer";
import { reducer as depotLocationReducer } from "./DepotLocation/reducer";
import { reducer as employeeReducer } from "./Employee/reducer";
import { reducer as employeeReport } from "./EmployeeReport/reducer";
import { reducer as fabDepotReducer } from "./FabDepot/reducer";
import { reducer as fabricReducer } from "./Fabric/reducer";
import fabricConsumptionReducer from "./FabricConsumption/reducer";
import { reducer as fabStockReducer } from "./FabStock/reducer";
import { reducer as revisionReducer } from "./FabStockRevision/reducer";
import { reducer as formsReducer } from "./forms/reducer";
import { reducer as payrollReducer } from "./Payroll/reducer";
import { reducer as periodReduer } from "./Period/reducer";
import { reducer as prodDepotEntryReducer } from "./ProdDepotEntry/reducer";
import { reducer as prodDepotLocationReducer } from "./ProdDepotLocation/reducer";
import { reducer as prodStockReducer } from "./ProdStock/reducer";
import { reducer as prodStockBalance } from "./ProdStockBalance/reducer";
import { reducer as prodStockRevision } from "./ProdStockRevision/reducer";
import { reducer as productReducer } from "./Product/reducer";
import { reducer as productionReducer } from "./Production/reducer";
import { reducer as productionReportReducer } from "./ProductionReport/reducer";
import { reducer as productionReportByPeriodsReducer } from "./ProductionReportByPeriods/reducer";
import { reducer as shipmentReducer } from "./Shipment/reducer";
import { reducer as sizeReducer } from "./Size/reducer";
import { reducer as specReducer } from "./Specification/reducer";
import { reducer as splitReducer } from "./Split/reducer";
import { reducer as teamReducer } from "./Team/reducer";
import { reducer as userReducer } from "./User/reducer";
import { reducer as wageReducer } from "./WageScan/reducer";

export const reducer = combineReducers({
  fabricConsumption: fabricConsumptionReducer,
  common: commonComponentsReducer,
  production: productionReducer,
  size: sizeReducer,
  product: productReducer,
  cutting: cuttingReducer,
  fabric: fabricReducer,
  contractor: contractorReducer,
  employee: employeeReducer,
  team: teamReducer,
  wagescan: wageReducer,
  split: splitReducer,
  specification: specReducer,
  productionReport: productionReportReducer,
  productionReportByPeriods: productionReportByPeriodsReducer,
  employeeReport: employeeReport,
  user: userReducer,
  forms: formsReducer,
  period: periodReduer,
  payroll: payrollReducer,
  fabDepot: fabDepotReducer,
  depotLocations: depotLocationReducer,
  prodDepotLocations: prodDepotLocationReducer,
  fabStock: fabStockReducer,
  fabStockRevision: revisionReducer,
  prodDepotEntry: prodDepotEntryReducer,
  prodStock: prodStockReducer,
  prodStockBalance: prodStockBalance,
  prodStockRevision: prodStockRevision,
  shipment: shipmentReducer,
});
