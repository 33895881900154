import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, DatePicker } from "antd";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

const { RangePicker } = DatePicker

class ShipmentTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedDateRange: null
    }
  }

  handleDateRangeChange = () => {
    this.setState({});
  }

  handleDeleteConfirm = id => {
    const { onRemove } = this.props;
    onRemove(id);
  };

  handleDeleteCancel = () => {};

  render() {
    const {
      shipments,
      onEditClick,
      onRemove,
      mobile,
      loading,
      setShowShipmentStatusModal,
    } = this.props;

    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/shipments/" + row.original.id}
          >
            {"SHP-" + row.value}
          </a>
        ),
      },
      {
        Header: "Клиент",
        accessor: "client",
        Cell: row => (
          <span className="number">{row.value ? row.value.name : null}</span>
        ),
        filterable: false
      },
      {
        Header: "Итоговая стоимость",
        id: "totalShipmentValue",
        accessor: data =>
          data.lineItems.reduce(
            (acc, i) =>
              i.price && i.quantity ? acc + i.price * i.quantity : acc + 0,
            0,
          ),
        Cell: props => (
          <span className="text">
            {`${props.value}`
              .replace(/(^\d+\.?)(\d{0,2})?\d*/, (...args) =>
                args[1].concat(args[2] || ""),
              )
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </span>
        ),
        Filter: () => Number(shipments.reduce((acc, row) =>
            acc + row.lineItems.reduce((lineItemsAcc, item) =>
              item.price && item.quantity ? lineItemsAcc + item.price * item.quantity : lineItemsAcc
              , 0)
            , 0)).toFixed(2),
        filterAll: true,
        filterable: true,
      },
      {
        Header: "Количество мешков",
        id: "totalNumberOfBags",
        accessor: data =>
          data.lineItems.reduce((acc, elem) => acc + elem.numberOfBags, 0),
        Filter: () => shipments.reduce((acc, row) =>
            acc + row.lineItems.reduce((lineItemAcc, item) => lineItemAcc + item.numberOfBags, 0), 0),
        filterAll: false,
        filterable: true,
      },
      {
        Header: "Дата",
        id: "shipped_at",
        accessor: "shipped_at",
        Cell: row =>
          row.value && (
            <span className="number">
              {new moment(row.value).format("DD/MM/YYYY")}
            </span>
          ),
        filterable: false,
      },
      {
        Header: "Статус",
        id: "shipmentStatusType_id",
        accessor: "shipmentStatusType_id",
        Cell: row => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>
                {row.value === 1 ? "Отправлено" : "В процессе подготовки"}
              </span>
              <LegacyIcon
                type={"edit"}
                style={{ justifySelf: "flex-end", display: "flex" }}
                onClick={() =>
                  setShowShipmentStatusModal(true, row.original.id)
                }
              />
            </div>
          );
        },
        filterable: false,
      },
      {
        Header: "Удалить",
        filterable: false,
        maxWidth: 100,
        Cell: row => (
          <div>
            <Popconfirm
              title="Уверены что хотите удалить эту запись?"
              onConfirm={() => this.handleDeleteConfirm(row.original.id)}
              onCancel={this.handleDeleteCancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="danger" size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
        sortable: false,
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={loading ? [] : shipments}
          getTdProps={(state, rowInfo, column) => {
            if (loading) {
              return {
                className: "pt-skeleton",
              };
            } else {
              return {};
            }
          }}
          columns={columns}
          defaultPageSize={50}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText={loading ? "" : "Нет записей"}
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

ShipmentTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(ShipmentTable);
