import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { meFromToken, resetToken } from "../../data/users/actions";
import routes from "../../routes";
import { getFirstAccessibleRoute } from "../../utils";
import logo from "./logo.svg";

class Home extends Component {
  componentDidMount() {
    let route = getFirstAccessibleRoute(this.props.user.user);

    if (this.props.user.user != null) {
      this.props.history.push(route);
    }
  }

  componentDidUpdate(prevProps) {
    const { location, user } = this.props;

    if (prevProps.user.user != user.user && prevProps.user.user == null) {
      let route = getFirstAccessibleRoute(this.props.user.user);
      this.props.history.push(route);
    }
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <h2>Добро пожаловать в Prodex</h2>
        </div>
        <p className="App-intro">Система контроля для трикотажных фабрик</p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.data.users.user,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(Home),
);
