import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import FabStockDetail from "./components/FabStockDetail";
import FabStockMovementRequestDetail from "./components/FabStockMovementRequestDetail";
import FabStockMain from "./index.js";

///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class FabStockRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/fabstock" component={FabStockMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={FabStockDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={FabStockDetail}
          />
          <Route
            exact
            path="/fabstock/movementrequest/new"
            component={FabStockMovementRequestDetail}
          />
          <Route
            exact
            path="/fabstock/movementrequest/:id"
            component={FabStockMovementRequestDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default FabStockRoute;
