import { combineReducers } from "redux";

const openedEmployeeId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_EMPLOYEE_ID":
      return action.employeeId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedEmployeeId,
});
