import { Button, DatePicker, Empty, InputNumber, Select, Spin } from "antd";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  createFabricReturn,
  fetchFabricReturn,
  updateFabricReturn,
} from "../../../data/fabricReturns/actions";
import isMobile from "../../../utils/mobileCheck";
import {fetchFabDepotEntries} from "../../../data/fabDepotEntries/actions";

const isInteger = str => {
  return /^\+?(0|[1-9]\d*)$/.test(str);
};

const fabricReturnDetailsStatuses = {
  CREATE_NEW_FABRIC_RETURN: 1,
  FETCHING_FABRIC_RETURN: 2,
  NO_SUCH_FABRIC_RETURN: 3,
  SHOW_FABRIC_RETURN: 4,
  EDIT_FABRIC_RETURN: 5,
  WRONG_PATH: 6,
};

const FabricReturnSchema = Yup.object().shape({
  fabDepotEntry: Yup.object()
    .required("Выберите приход")
    .nullable(),
  price: Yup.number()
    .max(99999999, "Значение слишком большое")
    .min(0, "Значение должно быть неотрицательным")
    .required("Обязательное поле")
    .nullable(),
  weight: Yup.number()
    .max(99999999, "Значение слишком большое")
    .min(0, "Значение должно быть неотрицательным")
    .required("Обязательное поле")
    .nullable(),
  transferredAt: Yup.date()
    .required("Обязательное поле")
    .nullable(),
});

function FabricReturnDetails(props) {
  const {
    match,
    fetchFabricReturn,
    createFabricReturn,
    history,
    updateFabricReturn,
    fetchFabDepotEntries
  } = props;

  const [status, setStatus] = useState(() => {
    if (match.params.id === "new") {
      return fabricReturnDetailsStatuses.CREATE_NEW_FABRIC_RETURN;
    }
    if (isInteger(match.params.id)) {
      return fabricReturnDetailsStatuses.FETCHING_FABRIC_RETURN;
    }
    return fabricReturnDetailsStatuses.WRONG_PATH;
  });

  const formRef = createRef();
  const [fabricReturn, setFabricReturn] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [fabDepotEntries, setFabDepotEntries] = useState([]);
  const [formData, setFormData] = useState({
    fabDepotEntry: null,
    price: 0,
    weight: 0,
    transferredAt: null,
  });

  useEffect(() => {
      fetchFabDepotEntries().then(fabDepotEntries => {
          setFabDepotEntries(fabDepotEntries);
      })
  }, [])

  useEffect(() => {
    if (fabricReturn) {
      setFormData({
        fabDepotEntry: fabricReturn.fabDepotEntry,
        price: fabricReturn.price,
        weight: fabricReturn.weight,
        transferredAt: moment(fabricReturn.transferred_at),
      });
      setStatus(fabricReturnDetailsStatuses.SHOW_FABRIC_RETURN);
    }
  }, [fabricReturn]);

  useEffect(() => {
    if (match.params.id && isInteger(match.params.id)) {
      fetchFabricReturn(match.params.id).then(
        fabricReturn => {
          setFabricReturn(fabricReturn);
        },
        () => {
          setStatus(fabricReturnDetailsStatuses.NO_SUCH_FABRIC_RETURN);
        },
      );
    }
  }, [match.params.id]);

  if (status === fabricReturnDetailsStatuses.NO_SUCH_FABRIC_RETURN) {
    return (
      <Empty
        style={{ marginTop: "100px" }}
        description={"Такого платежа не существует"}
      />
    );
  }

  if (status === fabricReturnDetailsStatuses.WRONG_PATH) {
    props.history.push("/fabricReturn");
  }

  const showNull =
    status === fabricReturnDetailsStatuses.NO_SUCH_FABRIC_RETURN ||
    status === fabricReturnDetailsStatuses.FETCHING_FABRIC_RETURN;

  const showFormData =
    status === fabricReturnDetailsStatuses.CREATE_NEW_FABRIC_RETURN ||
    status === fabricReturnDetailsStatuses.EDIT_FABRIC_RETURN ||
    status === fabricReturnDetailsStatuses.SHOW_FABRIC_RETURN;

  const disabled = !(
    status === fabricReturnDetailsStatuses.CREATE_NEW_FABRIC_RETURN ||
    status === fabricReturnDetailsStatuses.EDIT_FABRIC_RETURN
  );

  const showValue = value => {
    return showNull ? null : showFormData ? value : null;
  };

  const onSubmit = values => {
    setIsSaving(true);
    if (status === fabricReturnDetailsStatuses.EDIT_FABRIC_RETURN) {
      updateFabricReturn(fabricReturn.id, values).then(
        () => {
          setIsSaving(false);
          setStatus(fabricReturnDetailsStatuses.SHOW_FABRIC_RETURN);
        },
        () => {
          setIsSaving(false);
        },
      );
    } else if (status === fabricReturnDetailsStatuses.CREATE_NEW_FABRIC_RETURN) {
      createFabricReturn(values).then(
        newFabricReturn => {
          setIsSaving(false);
          history.push(`/fabricReturn/${newFabricReturn.id}`);
        },
        () => {
          setIsSaving(false);
        },
      );
    }
  };

  const handleSubmit = () => {
    formRef.current.submitForm();
  };

  const makeFabDepotLabel = (fabDepotEntry) => {
      return `${
        fabDepotEntry.batchNumber === "" ?
            "Нет номера партии" :
            fabDepotEntry.batchNumber
    } - ${fabDepotEntry.fabric.name} [${fabDepotEntry.weight} кг].`
  }

  return (
      <Spin spinning={status === fabricReturnDetailsStatuses.FETCHING_FABRIC_RETURN}>
        <div className={"mt-paper"} style={isMobile() ? { padding: "10px" } : {}}>
        <h2>Возврат материала</h2>
        {(status === fabricReturnDetailsStatuses.CREATE_NEW_FABRIC_RETURN ||
          status === fabricReturnDetailsStatuses.EDIT_FABRIC_RETURN) && (
          <Button
            loading={isSaving}
            onClick={handleSubmit}
            style={isMobile() ? { width: "100%" } : {}}
          >
            Сохранить
          </Button>
        )}
        {status === fabricReturnDetailsStatuses.SHOW_FABRIC_RETURN && (
          <Button
            style={isMobile() ? { width: "100%" } : {}}
            onClick={() => setStatus(fabricReturnDetailsStatuses.EDIT_FABRIC_RETURN)}
          >
            Изменить
          </Button>
        )}
        <br />
        <br />
        <Formik
          ref={formRef}
          initialValues={formData}
          validationSchema={FabricReturnSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <>
                <div
                  className={errors.fabDepotEntry && touched.fabDepotEntry ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="fabDepotEntry">
                    Приход
                  </label>
                  <br />
                  <Select
                    id="fabDepotEntry"
                    style={{ width: isMobile() ? "100%" : 500 }}
                    placeholder="Приход"
                    disabled={disabled}
                    value={showValue(
                      values.fabDepotEntry
                          ? makeFabDepotLabel(values.fabDepotEntry)
                          : undefined
                    )}
                    onChange={fabDepotEntry_id => {
                        const fabDepotEntry =
                            fabDepotEntries.find(fabDepotEntry => fabDepotEntry.id === fabDepotEntry_id);

                        setFieldValue("fabDepotEntry", fabDepotEntry);
                        setFieldValue("price", fabDepotEntry.price);
                    }}
                  >
                    {fabDepotEntries.map(fabDepotEntry => (
                        <Select.Option value={fabDepotEntry.id} key={fabDepotEntry.id}>
                          {makeFabDepotLabel(fabDepotEntry)}
                        </Select.Option>
                    ))}
                  </Select>
                  <div className="help-block">
                    {errors.fabDepotEntry && touched.fabDepotEntry && errors.fabDepotEntry}
                  </div>
                </div>
                <div
                    style={{ marginTop: "15px" }}
                    className={errors.price && touched.price ? "has-error" : ""}
                >
                  <label className={"mt-label"} htmlFor={"price"}>
                    Цена
                  </label>
                  <br />
                  <InputNumber
                      id={"price"}
                      style={{ width: isMobile() ? "100%" : 500 }}
                      placeholder={"Введите цену"}
                      precision={2}
                      formatter={value => `$ ${value}`}
                      value={showValue(values.price)}
                      onChange={e => setFieldValue("price", e)}
                      disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.price && touched.price && errors.price}
                  </div>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className={errors.weight && touched.weight ? "has-error" : ""}
                >
                  <label className={"mt-label"} htmlFor={"weight"}>
                    Вес
                  </label>
                  <br />
                  <InputNumber
                    id={"weight"}
                    style={{ width: isMobile() ? "100%" : 500 }}
                    placeholder={"Введите вес"}
                    precision={2}
                    formatter={value => `${value} кг`}
                    value={showValue(values.weight)}
                    onChange={e => setFieldValue("weight", Math.min(e, values.fabDepotEntry.weight))}
                    disabled={disabled || !values.fabDepotEntry}
                  />
                  <div className="help-block">
                    {errors.weight && touched.weight && errors.weight}
                  </div>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className={
                    errors.transferredAt && touched.transferredAt
                      ? "has-error"
                      : ""
                  }
                >
                  <label className={"mt-label"} htmlFor={"transferredAt"}>
                    Дата возврата
                  </label>
                  <br />
                  <DatePicker
                    id={"transferredAt"}
                    style={{ width: isMobile() ? "100%" : 500 }}
                    placeholder={"Выберите дату"}
                    value={showValue(values.transferredAt)}
                    onChange={e => setFieldValue("transferredAt", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.transferredAt &&
                      touched.transferredAt &&
                      errors.transferredAt}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Spin>
  );
}

const mapStateToProps = (state, ownProps) => {

};

const mapDispatchToProps = dispatch => ({
  fetchFabDepotEntries: () => dispatch(fetchFabDepotEntries()),
  fetchFabricReturn: id => dispatch(fetchFabricReturn(id)),
  createFabricReturn: fabricReturn => dispatch(createFabricReturn(fabricReturn)),
  updateFabricReturn: (id, fabricReturn) => dispatch(updateFabricReturn(id, fabricReturn)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FabricReturnDetails),
);
