import React from "react";

export function FormGroup({ name, label, hasError, children }) {
  return (
    <div className={!hasError ? "" : "has-error"}>
      <label className="mt-label" htmlFor={name}>
        {label}
      </label>

      {children}
    </div>
  );
}
