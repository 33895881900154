import { Button, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import {
  createTeam,
  fetchTeam,
  updateTeam,
} from "../../../../data/teams/actions";
import { getTeam } from "../../../../data/teams/reducer";
import { setOpenedTeamId } from "../../actions";
import FormValidator from "./FormValidator";

class TeamDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите имя",
      },
    ]);

    this.state = {
      isInEditState: false,
      name: "",

      validation: this.validator.valid(),
    };

    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  mapErrorsToObjectMap = error => {
    let subError = new Object();
    for (var key in error.errors) {
      subError[key] = error.errors[key].message;
    }
    return subError;
  };

  createTeamFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var team = {
      name: this.state.name,
    };
    return team;
  };

  handleSubmit = values => {
    const { createTeam, match, updateTeam } = this.props;

    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
      var team = this.createTeamFromValues();
      //Handle update
      if (match.params.id) {
        return updateTeam(match.params.id, team).then(
          json => {
            this.setState({ isInEditState: false });
            this.props.history.push(`/team/` + json.id);
          },
          error => {},
        );
      }
      //Handle create
      else {
        return createTeam(team).then(
          json => {
            this.setState({ isInEditState: false });
            this.props.history.push(`/team/` + json.id);
          },
          error => {},
        );
      }
    }
  };

  componentDidMount() {
    this.fetchTeamOrResetForm();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.fetchTeamOrResetForm();
    }
  }

  fetchTeamOrResetForm = () => {
    const { fetchTeam, match, setOpenedTeamId } = this.props;
    setOpenedTeamId("");
    if (match.params.id) {
      fetchTeam(match.params.id).then(team => {
        setOpenedTeamId(team.id);
        this.setState({
          isInEditState: false,
          name: team.name,
        });
      });
    } else {
      this.setState({ isInEditState: true });
    }
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  render() {
    const { history, openedTeam, match } = this.props;

    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    return (
      <div className="mt-paper">
        <div>
          <h2>Бригада</h2>
        </div>
        {match.params.id && this.state.isInEditState && (
          <Button type="primary" onClick={this.handleSubmit}>
            Сохранить
          </Button>
        )}
        {match.params.id == undefined && (
          <Button type="primary" onClick={this.handleSubmit}>
            Создать
          </Button>
        )}
        {match.params.id && !this.state.isInEditState && (
          <Button onClick={this.toggleEditState}>Изменить</Button>
        )}
        <br />
        &nbsp;
        {/* <TeamForm enableReinitialize initialValues={openedTeam} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
        <div
          style={{ marginBottom: 15 }}
          className={validation.name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="name">
            Название бригады
          </label>
          <Input
            name="name"
            placeholder="Название"
            //onChange={this.handleInputChange}
            value={this.state.name}
            onChange={this.handleNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.name.message}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    openedTeam: getTeam(state, state.scenes.team.openedTeamId),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchTeam: id => dispatch(fetchTeam(id)),
    setOpenedTeamId: id => dispatch(setOpenedTeamId(id)),
    createTeam: team => dispatch(createTeam(team)),
    updateTeam: (id, team) => dispatch(updateTeam(id, team)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TeamDetail),
);
