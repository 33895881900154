import moment from "moment";

export const createChangeLog = propertiesToCheck =>
  propertiesToCheck
    .filter(property => {
      if (property.comparator) {
        return property.comparator(
          property.valuesToCompare[0],
          property.valuesToCompare[1],
        );
      }
      if (property.comparisonType === "float") {
        return (
          Math.abs(property.valuesToCompare[0] - property.valuesToCompare[1]) >
          0.00001
        );
      } else {
        return property.valuesToCompare[0] !== property.valuesToCompare[1];
      }
    })
    .map(
      (property, index) =>
        `\n${index + 1}. ${property.name}: ${
          property.valuesToDisplay[0]
        } \u279E ${property.valuesToDisplay[1]} ${
          property.unit ? property.unit : ""
        }`,
    )
    .reduce((acc, changeLogRecord) => acc + changeLogRecord, "");

const constructLineItemLogText = (item, index) =>
  `${index ? `${index}. ` : ""}Продукт: ${item.product.name} [PR-${
    item.product.id
  }]. Мешков: ${item.numberOfBags}. Количество: ${
    item.quantity
  } шт. Цена за штуку: ${item.price}$.`;

const lineItemPropertyListToCompare = [
  ["product_id", "int"],
  ["numberOfBags", "int"],
  ["price", "float"],
  ["quantity", "int"],
];

const areLineItemsDifferent = (firstItem, secondItem) =>
  lineItemPropertyListToCompare
    .map(([key, comparisonType]) => {
      if (comparisonType === "float") {
        return Math.abs(firstItem[key] - secondItem[key]) > 0.0001;
      } else {
        return firstItem[key] !== secondItem[key];
      }
    })
    .reduce((acc, value) => acc || value, false);

const constructComparisonLineItemLogText = (firstItem, secondItem, index) => {
  return (
    constructLineItemLogText(secondItem) +
    createChangeLog([
      {
        name: "Продукт",
        valuesToCompare: [firstItem.product_id, secondItem.product_id],
        valuesToDisplay: [firstItem.product.name, secondItem.product.name],
      },
      {
        name: "Цена за штуку",
        valuesToCompare: [firstItem.price, secondItem.price],
        valuesToDisplay: [firstItem.price, secondItem.price],
        comparisonType: "float",
        unit: "$",
      },
      {
        name: "Количество мешков",
        valuesToCompare: [firstItem.numberOfBags, secondItem.numberOfBags],
        valuesToDisplay: [firstItem.numberOfBags, secondItem.numberOfBags],
      },
      {
        name: "Количество",
        valuesToCompare: [firstItem.quantity, secondItem.quantity],
        valuesToDisplay: [firstItem.quantity, secondItem.quantity],
        unit: "шт",
      },
    ]) +
    "\n"
  );
};

export const createShipmentChangeLog = logObject => {
  const { oldShipment, newShipment } = logObject;

  const prevLineItems = oldShipment.lineItems;
  const currentLineItems = newShipment.lineItems;

  const deletedLineItems = prevLineItems.filter(
    lineItem => !currentLineItems.map(item => item.id).includes(lineItem.id),
  );
  const newLineItems = currentLineItems.filter(
    lineItem => !prevLineItems.map(item => item.id).includes(lineItem.id),
  );
  const differingIntersectingLineItems = prevLineItems
    .filter(
      prevLineItem =>
        !!currentLineItems.find(
          currentLineItem => currentLineItem.id === prevLineItem.id,
        ),
    )
    .map(prevLineItem => [
      prevLineItem,
      currentLineItems.find(item => item.id === prevLineItem.id),
    ])
    .filter(items => areLineItemsDifferent(...items));

  const deletedLineItemsText = `Удалённые записи продаж: 
    ${deletedLineItems
      .map((item, index) => constructLineItemLogText(item, index + 1))
      .join("\n")}
    `;

  const newLineItemsText = `Добавленные записи продаж:
    ${newLineItems
      .map((item, index) => constructLineItemLogText(item, index + 1))
      .join("\n")} `;

  const intersectingLineItemsText = `
  Изменённые записи продаж:
  ${differingIntersectingLineItems
    .map((items, index) =>
      constructComparisonLineItemLogText(items[0], items[1], index + 1),
    )
    .join("\n")}`;

  let result = "";
  result += createChangeLog([
    {
      name: "Клиент",
      valuesToCompare: [oldShipment.client.id, newShipment.client.id],
      valuesToDisplay: [oldShipment.client.name, newShipment.client.name],
    },
    {
      name: "Дата отгрузки",
      valuesToCompare: [oldShipment.shipped_at, newShipment.shipped_at],
      // comparator: (a, b) => {
      //     moment(a).isSame(b, 'day')
      // },
      valuesToDisplay: [
        moment(oldShipment.shipped_at).format("LL"),
        moment(newShipment.shipped_at).format("LL"),
      ],
    },
  ]);
  if (deletedLineItems.length > 0) {
    result += "\n" + deletedLineItemsText;
  }
  if (newLineItems.length > 0) {
    result += "\n" + newLineItemsText;
  }
  if (differingIntersectingLineItems.length > 0) {
    result += "\n" + intersectingLineItemsText;
  }

  return result;
};

export const createFabStockMovementRequestChangeLog = logObject => {
  const {
    oldFabStockMovementRequest,
    oldFabric,
    oldSourceDepotLocation,
    oldEndDepotLocation,
    newFabStockMovementRequest,
    newFabric,
    newSourceDepotLocation,
    newEndDepotLocation,
  } = logObject;

  return createChangeLog([
    {
      name: "Дата перемещения",
      valuesToCompare: [
        oldFabStockMovementRequest.entryDate,
        newFabStockMovementRequest.entryDate,
      ],
      valuesToDisplay: [
        moment(oldFabStockMovementRequest.entryDate).format("LL"),
        moment(newFabStockMovementRequest.entryDate).format("LL"),
      ],
    },
    {
      name: "Материал",
      valuesToCompare: [oldFabric.id, newFabric.id],
      valuesToDisplay: [oldFabric.name, newFabric.name],
    },
    {
      name: "Из склада",
      valuesToCompare: [oldSourceDepotLocation.id, newSourceDepotLocation.id],
      valuesToDisplay: [
        oldSourceDepotLocation.name,
        newSourceDepotLocation.name,
      ],
    },
    {
      name: "В склад",
      valuesToCompare: [oldEndDepotLocation.id, newEndDepotLocation.id],
      valuesToDisplay: [oldEndDepotLocation.name, newEndDepotLocation.name],
    },
    {
      name: "Вес",
      valuesToCompare: [
        oldFabStockMovementRequest.weight,
        newFabStockMovementRequest.weight,
      ],
      valuesToDisplay: [
        oldFabStockMovementRequest.weight,
        newFabStockMovementRequest.weight,
      ],
      unit: "кг",
    },
    {
      name: "Количество рулонов",
      valuesToCompare: [
        oldFabStockMovementRequest.rolls,
        newFabStockMovementRequest.rolls,
      ],
      valuesToDisplay: [
        oldFabStockMovementRequest.rolls,
        newFabStockMovementRequest.rolls,
      ],
    },
  ]);
};

export const createProdDepotEntryChangeLog = logObject => {
  const {
    oldProdDepotEntry,
    oldProduct,
    oldProdDepotLocation,
    newProdDepotEntry,
    newProduct,
    newProdDepotLocation,
  } = logObject;

  return createChangeLog([
    {
      name: "Цена",
      valuesToCompare: [oldProdDepotEntry.price, newProdDepotEntry.price],
      valuesToDisplay: [oldProdDepotEntry.price, newProdDepotEntry.price],
    },
    {
      name: "Продукт",
      valuesToCompare: [oldProduct.id, newProduct.id],
      valuesToDisplay: [oldProduct.name, newProduct.name],
    },
    {
      name: "Склад",
      valuesToCompare: [oldProdDepotLocation.id, newProdDepotLocation.id],
      valuesToDisplay: [oldProdDepotLocation.name, newProdDepotLocation.name],
    },
    {
      name: "Количество",
      valuesToCompare: [oldProdDepotEntry.quantity, newProdDepotEntry.quantity],
      valuesToDisplay: [oldProdDepotEntry.quantity, newProdDepotEntry.quantity],
    },
    {
      name: "Дата прихода",
      valuesToCompare: [
        oldProdDepotEntry.entry_date,
        newProdDepotEntry.entry_date,
      ],
      valuesToDisplay: [
        moment(oldProdDepotEntry.entry_date).format("LL"),
        moment(newProdDepotEntry.entry_date).format("LL"),
      ],
    },
  ]);
};

export const createFabDepotEntryChangeLog = logObject => {
  const {
    oldFabDepotEntry,
    oldFabric,
    oldDepotLocation,
    newFabDepotEntry,
    newFabric,
    newDepotLocation,
  } = logObject;

  return createChangeLog([
    {
      name: "Цена",
      valuesToCompare: [oldFabDepotEntry.price, newFabDepotEntry.price],
      valuesToDisplay: [oldFabDepotEntry.price, newFabDepotEntry.price],
    },
    {
      name: "Материал",
      valuesToCompare: [oldFabric.id, newFabric.id],
      valuesToDisplay: [oldFabric.name, newFabric.name],
    },
    {
      name: "Склад",
      valuesToCompare: [oldDepotLocation.id, newDepotLocation.id],
      valuesToDisplay: [oldDepotLocation.name, newDepotLocation.name],
    },
    {
      name: "Вес",
      valuesToCompare: [oldFabDepotEntry.weight, newFabDepotEntry.weight],
      valuesToDisplay: [oldFabDepotEntry.weight, newFabDepotEntry.weight],
    },
    {
      name: "Количество рулонов",
      valuesToCompare: [oldFabDepotEntry.rolls, newFabDepotEntry.rolls],
      valuesToDisplay: [oldFabDepotEntry.rolls, newFabDepotEntry.rolls],
    },
    {
      name: "Дата прихода",
      valuesToCompare: [
        oldFabDepotEntry.entry_date,
        newFabDepotEntry.entry_date,
      ],
      valuesToDisplay: [
        moment(oldFabDepotEntry.entry_date).format("LL"),
        moment(newFabDepotEntry.entry_date).format("LL"),
      ],
    },
  ]);
};

export const createProductChangeLog = logObject => {
  const { oldProduct, newProduct } = logObject;
  const oldSizes = oldProduct.sizes.map(size => size.name)
  const newSizes = newProduct.sizes.map(size => size.name)

  return createChangeLog([
    {
      name: "Рассчётная цена",
      valuesToCompare: [oldProduct.estimatedPrice, newProduct.estimatedPrice],
      valuesToDisplay: [oldProduct.estimatedPrice, newProduct.estimatedPrice],
      comparisonType: "float",
      unit: "$"
    },
    {
      name: "Название",
      valuesToCompare: [oldProduct.name, newProduct.name],
      valuesToDisplay: [oldProduct.name, newProduct.name],
    },
    {
      name: "id спецификации",
      valuesToCompare: [oldProduct.spec_id, newProduct.spec_id],
      valuesToDisplay: [oldProduct.spec_id, newProduct.spec_id],
    },
    {
      name: "Описание",
      valuesToCompare: [oldProduct.description, newProduct.description],
      valuesToDisplay: [oldProduct.description, newProduct.description],
    },
    {
      name: "Цена",
      valuesToCompare: [oldProduct.price, newProduct.price],
      valuesToDisplay: [oldProduct.price, newProduct.price],
      comparisonType: "float",
      unit: "$"
    },
    {
      name: "Артикул",
      valuesToCompare: [oldProduct.article, newProduct.article],
      valuesToDisplay: [oldProduct.article, newProduct.article],
    },
    {
      name: "Размеры",
      valuesToCompare: [oldSizes, newSizes],
      valuesToDisplay: [oldSizes.toString(), newSizes.toString()],
      comparator: (a, b) => {
        return a.toString() !== b.toString()
      }
    }
  ])
}

export const createContractorPaymentChangeLog = logObject => {
  const { oldContractorPaymentEntry, newContractorPaymentEntry, oldContractor, newContractor } = logObject;

  return createChangeLog([
    {
      name: "Сумма платежа",
      valuesToCompare: [oldContractorPaymentEntry.amount, newContractorPaymentEntry.amount],
      valuesToDisplay: [oldContractorPaymentEntry.amount, newContractorPaymentEntry.amount],
    },
    {
      name: "Дата платежа",
      valuesToCompare: [
        oldContractorPaymentEntry.transferred_at,
        newContractorPaymentEntry.transferred_at,
      ],
      valuesToDisplay: [
        moment(oldContractorPaymentEntry.transferred_at).format("LL"),
        moment(newContractorPaymentEntry.tranfserred_at).format("LL"),
      ],
    },
    {
      name: "Подрядчик",
      valuesToCompare: [oldContractor.id, newContractor.id],
      valuesToDisplay: [oldContractor.name, newContractor.name],
    },
  ]);
};

export const createPaymentChangeLog = logObject => {
  const { oldPaymentEntry, newPaymentEntry, oldClient, newClient } = logObject;

  return createChangeLog([
    {
      name: "Сумма платежа",
      valuesToCompare: [oldPaymentEntry.amount, newPaymentEntry.amount],
      valuesToDisplay: [oldPaymentEntry.amount, newPaymentEntry.amount],
    },
    {
      name: "Дата платежа",
      valuesToCompare: [
        oldPaymentEntry.transferred_at,
        newPaymentEntry.transferred_at,
      ],
      valuesToDisplay: [
        moment(oldPaymentEntry.transferred_at).format("LL"),
        moment(newPaymentEntry.tranfserred_at).format("LL"),
      ],
    },
    {
      name: "Клиент",
      valuesToCompare: [oldClient.id, newClient.id],
      valuesToDisplay: [oldClient.name, newClient.name],
    },
  ]);
};
