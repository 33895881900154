import { Popconfirm } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

function ContractorPaymentTable(props) {
  const { isFetchingContractorPayments, contractorPayments, history, onDelete } = props;

  const columns = [
    {
      Header: "",
      accessor: "id",
      maxWidth: 60,
      filterable: false,
      key: "id",
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            history.push("/contractorPayment/" + row.value);
          }}
        >
          {"PM-" + row.value}
        </a>
      ),
    },
    {
      Header: "Подрядчик",
      accessor: "contractor_id",
      width: 200,
      key: "contractor_id",
      Cell: row => (
        <span>
          [
          <a
            onClick={e => {
              e.preventDefault();
              history.push("/contractor/" + row.value);
            }}
          >
            {"CNTR-" + row.value}
          </a>
          ] {row.original.contractor.name}
        </span>
      ),
      filterable: true,
      filterAll: true,
      filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["_original.contractor.name"] })
    },
    {
      Header: "Сумма",
      accessor: "amount",
      Cell: row => {
        return `${row.value}$`;
      },
      key: "amount",
      filterable: false,
    },
    {
      Header: "Дата перевода",
      accessor: "transferred_at",
      key: "transferred_at",
      Cell: row => moment(row.value).format("DD/MM/YYYY"),
      filterable: false,
    },
    {
      Header: "",
      accessor: "id",
      key: "delete",
      filterable: false,
      Cell: row => (
        <Popconfirm
          title={"Вы уверены, что хотите удалить эту запись?"}
          onConfirm={() => onDelete(row.value)}
          okText={"Да"}
          cancelText={"Отменить"}
        >
          <a>Удалить</a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <ReactTable
        columns={columns}
        dataSource={contractorPayments}
        filterable={true}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        className="-striped -highlight"
        data={contractorPayments}
        getTdProps={() => {
          if (isFetchingContractorPayments) {
            return {
              className: "pt-skeleton",
            };
          } else {
            return {};
          }
        }}
        defaultPageSize={50}
        previousText="Пред"
        nextText="След"
        loadingText="Загрузка..."
        noDataText={isFetchingContractorPayments ? "" : "Нет записей"}
        pageText="Страница"
        ofText="из"
        rowsText="строк"
      />
    </div>
  );
}

export default ContractorPaymentTable;
