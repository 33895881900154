import { Button, notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import { serialize } from "../../helpers/utils";

export const FABRIC_CONSUMPTIONS_FETCH_SUCCESS =
  "FABRIC_CONSUMPTIONS_FETCH_SUCCESS";
export const FABRIC_CONSUMPTION_FETCH_SUCCESS =
  "FABRIC_CONSUMPTION_FETCH_SUCCESS";
export const FABRIC_CONSUMPTION_CREATE_SUCCESS =
  "FABRIC_CONSUMPTION_CREATE_SUCCESS";
export const FABRIC_CONSUMPTION_UPDATE_SUCCESS =
  "FABRIC_CONSUMPTION_UPDATE_SUCCESS";
export const FABRIC_CONSUMPTION_DELETE_SUCCESS =
  "FABRIC_CONSUMPTION_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const createFabricConsumption = fabricConsumption => dispatch =>
  axios
    .post("/api/fabricConsumption", fabricConsumption)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_CONSUMPTION_CREATE_SUCCESS,
        response: data,
      });
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const updateFabricConsumption = (id, fabricConsumption) => dispatch =>
  axios
    .put("/api/fabricConsumption/" + id, fabricConsumption)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_CONSUMPTION_UPDATE_SUCCESS,
        response: data,
      });
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const deleteDirectFabricConsumption = id => dispatch =>
  axios
    .delete(`/api/fabricConsumption/` + id)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_CONSUMPTION_DELETE_SUCCESS,
        response: data,
      });
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });
export const fetchFabricConsumption = id => dispatch =>
  axios
    .get(`/api/fabricConsumption/` + id)
    .then(({ data }) => {
      dispatch({
        type: FABRIC_CONSUMPTION_FETCH_SUCCESS,
        response: data,
      });
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const fetchFabricConsumptions = (
  pageSize,
  pageIndex,
  fabTransactionTypeId,
) => {
  return dispatch => {
    return axios
      .get(
        `/api/fabricConsumptions/?${serialize({
          page: pageIndex,
          pageSize,
          fabTransactionTypeId,
        })}`,
      )
      .then(({ data }) => {
        dispatch({
          type: FABRIC_CONSUMPTIONS_FETCH_SUCCESS,
          response: data,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
