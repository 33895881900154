import AttendanceReportType from "@app/shared/constants/AttendanceReportType";
import { attendanceReportByDateRangeInputValidator } from "@app/shared/validators/attendanceReportValidators";
import { Button, Col, Row } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useMemo } from "react";

import { FormDateRange } from "../../../../components/FormDateRange";
import { FormInput } from "../../../../components/FormInput";
import WorkingSchedulesField from "../WorkingSchedulesField";

export function AttendanceReportByDateRangeForm({ onSubmit, isSaving }) {
  const initialValues = useMemo(
    () => ({
      start_date: null,
      end_date: null,
      working_schedule: [],
      type: AttendanceReportType.AttendanceReportByDateRange,
    }),
    [],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={attendanceReportByDateRangeInputValidator}
      render={({ handleSubmit }) => (
        <>
          <Button type="primary" onClick={handleSubmit} loading={isSaving}>
            Сгенерировать отчет
          </Button>

          <br />
          <br />

          <Row gutter={16}>
            <Col span={12}>
              <FormInput name="name" label="Название" />
            </Col>

            <Col span={12}>
              <FormDateRange
                label="За Период"
                startDateName="start_date"
                defaultStartDatePickerValue={moment().startOf("month")}
                endDateName="end_date"
                defaultEndDatePickerValue={moment().endOf("month")}
              />
            </Col>
          </Row>

          <br />

          <WorkingSchedulesField name="working_schedule" />
        </>
      )}
    />
  );
}
