import {
  formatMonthWithDate,
  formatTime,
  formatWeekDay,
} from "@app/shared/utils/DateUtils";
import { workingScheduleValidator } from "@app/shared/validators/workingScheduleValidators";
import { isEqual } from "lodash";
import moment from "moment";
import { reach } from "yup";

export const WorkingScheduleType = {
  CommonWorkingHours: "common_working_hours",
  Weekend: "weekend",
  Holiday: "holiday",
  WeekDayWorkingHours: "week_day_working_hours",
  MonthDayWorkingHours: "month_day_working_hours",
  Other: "other",
};

export const workingScheduleTypes = [
  WorkingScheduleType.CommonWorkingHours,
  WorkingScheduleType.Weekend,
  WorkingScheduleType.Holiday,
  WorkingScheduleType.WeekDayWorkingHours,
  WorkingScheduleType.MonthDayWorkingHours,
  WorkingScheduleType.Other,
];

const workingScheduleKeys = new Set([
  "month",
  "day_of_week",
  "day_of_month",
  "start_time",
  "end_time",
]);

const workingScheduleTypeKeys = {
  [WorkingScheduleType.CommonWorkingHours]: new Set(["start_time", "end_time"]),
  [WorkingScheduleType.Weekend]: new Set(["day_of_week"]),
  [WorkingScheduleType.Holiday]: new Set(["month", "day_of_month"]),
  [WorkingScheduleType.WeekDayWorkingHours]: new Set([
    "day_of_week",
    "start_time",
    "end_time",
  ]),
  [WorkingScheduleType.MonthDayWorkingHours]: new Set([
    "month",
    "day_of_month",
    "start_time",
    "end_time",
  ]),
};

export function getWorkingScheduleTypeKeys(type) {
  return workingScheduleTypeKeys[type] || workingScheduleKeys;
}

export function getWorkingScheduleValidatorByType(type) {
  const keys = workingScheduleTypeKeys[type];

  if (!keys) {
    return workingScheduleValidator;
  }

  const overrides = {};

  keys.forEach(key => {
    overrides[key] = reach(workingScheduleValidator, key)
      .nullable(false)
      .required("Обязательное поле")
      .typeError("Обязательное поле");
  });

  return workingScheduleValidator.shape(overrides);
}

export function isValidWorkingScheduleType(type) {
  return workingScheduleTypes.includes(type);
}

export function formatWorkingScheduleType(type) {
  switch (type) {
    case WorkingScheduleType.CommonWorkingHours:
      return "Общее рабочее время";

    case WorkingScheduleType.Weekend:
      return "Выходной день недели";

    case WorkingScheduleType.Holiday:
      return "Определенный выходной день";

    case WorkingScheduleType.WeekDayWorkingHours:
      return "Рабочий день недели";

    case WorkingScheduleType.MonthDayWorkingHours:
      return "Определенный рабочий день";

    default:
      return "Прочий";
  }
}

export function getWorkingScheduleType(workingSchedule) {
  const definedKeys = new Set(
    Object.keys(workingSchedule).filter(
      key => workingScheduleKeys.has(key) && workingSchedule[key] != null,
    ),
  );

  const type = Object.keys(workingScheduleTypeKeys).find(key =>
    isEqual(definedKeys, workingScheduleTypeKeys[key]),
  );

  return type || WorkingScheduleType.Other;
}

export function formatWorkingSchedule(schedule) {
  const type = getWorkingScheduleType(schedule);

  switch (type) {
    case WorkingScheduleType.CommonWorkingHours:
      return `${formatTime(schedule.start_time)} - ${formatTime(
        schedule.end_time,
      )}`;

    case WorkingScheduleType.Weekend:
      return formatWeekDay(schedule.day_of_week);

    case WorkingScheduleType.Holiday:
      return formatMonthWithDate(schedule.month, schedule.day_of_month);

    case WorkingScheduleType.WeekDayWorkingHours:
      return `${formatWeekDay(schedule.day_of_week)}: ${formatTime(
        schedule.start_time,
      )} - ${formatTime(schedule.end_time)}`;

    case WorkingScheduleType.MonthDayWorkingHours:
      return `${formatMonthWithDate(
        schedule.month,
        schedule.day_of_month,
      )}: ${formatTime(schedule.start_time)} - ${formatTime(
        schedule.end_time,
      )}`;

    default:
      return "Смешанный";
  }
}

export function formatWorkingSchedulePeriod({
  start_date: startDate,
  end_date: endDate,
}) {
  const start = startDate && moment(startDate).format("DD/MM/YYYY");
  const end = endDate && moment(endDate).format("DD/MM/YYYY");

  if (start != null && end != null) {
    return `${start} - ${end}`;
  }

  if (start != null) {
    return `Начиная с ${start}`;
  }

  if (end != null) {
    return `До ${end}`;
  }

  return "Без истечения";
}
