import "./tableStyles.css";

import ShipmentStatusTypes from "@app/shared/constants/shipmentStatusTypes";
import {Button, Empty, Input, notification, Spin, Table} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  createClient,
  fetchClient,
  updateClient,
} from "../../../data/clients/actions";
import XLSX from "xlsx";
import moment from "moment"

const isInteger = str => {
  return /^\+?(0|[1-9]\d*)$/.test(str);
};

const clientDetailsStatuses = {
  CREATE_NEW_CLIENT: 1,
  FETCHING_CLIENT: 2,
  NO_SUCH_CLIENT: 3,
  SHOW_CLIENT: 4,
  EDIT_CLIENT: 5,
  WRONG_PATH: 6,
};

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

function ClientDetails(props) {
  const {
    match,
    fetchClient,
    createClient,
    history,
    updateClient,
    roles,
  } = props;

  const [status, setStatus] = useState(() => {
    if (match.params.id === "new") {
      return clientDetailsStatuses.CREATE_NEW_CLIENT;
    }
    if (isInteger(match.params.id)) {
      return clientDetailsStatuses.FETCHING_CLIENT;
    }
    return clientDetailsStatuses.WRONG_PATH;
  });

  const [client, setClient] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phoneNumber: "",
    nameOfRepresentative: "",
  });

  useEffect(() => {
    if (client) {
      setFormData({
        name: client.name,
        address: client.address,
        phoneNumber: client.phoneNumber,
        nameOfRepresentative: client.nameOfRepresentative,
      });
      setStatus(clientDetailsStatuses.SHOW_CLIENT);
    }
  }, [client]);

  useEffect(() => {
    if (match.params.id && isInteger(match.params.id)) {
      fetchClient(match.params.id).then(
        client => {
          setClient(client);
        },
        () => {
          setStatus(clientDetailsStatuses.NO_SUCH_CLIENT);
        },
      );
    }
  }, [match.params.id]);

  if (status === clientDetailsStatuses.NO_SUCH_CLIENT) {
    return (
      <Empty
        style={{ marginTop: "100px" }}
        description={"Такого клиента не существует"}
      />
    );
  }

  if (status === clientDetailsStatuses.WRONG_PATH) {
    props.history.push("/client");
  }

  const showNull =
    status === clientDetailsStatuses.NO_SUCH_CLIENT ||
    status === clientDetailsStatuses.FETCHING_CLIENT;

  const showFormData =
    status === clientDetailsStatuses.CREATE_NEW_CLIENT ||
    status === clientDetailsStatuses.EDIT_CLIENT ||
    status === clientDetailsStatuses.SHOW_CLIENT;

  const disabled = !(
    status === clientDetailsStatuses.CREATE_NEW_CLIENT ||
    status === clientDetailsStatuses.EDIT_CLIENT
  );

  const getValueOfElement = formDataProperty => {
    return showNull ? null : showFormData ? formData[formDataProperty] : null;
  };

  const handleRedirectToShipment = (e, id) => {
    e.preventDefault();
    history.push(`/shipment/${id}`);
  };

  const handleOnChange = formDataProperty => event => {
    setFormData({
      ...formData,
      [formDataProperty]: event.target.value,
    });
  };

  const onCreateClient = () => {
    setIsSaving(true);
    createClient(formData).then(
      newClient => {
        setIsSaving(false);
        history.push(`/client/${newClient.id}`);
      },
      () => {
        setIsSaving(false);
      },
    );
  };

  const onUpdateClient = () => {
    setIsSaving(true);
    updateClient(client.id, formData).then(
      () => {
        setIsSaving(false);
        setStatus(clientDetailsStatuses.SHOW_CLIENT);
      },
      () => {
        setIsSaving(false);
      },
    );
  };

  const hasAccessToBalance = roles.find(role => role.id === 6 || role.id === 1);
  const generateReconciliationAct = () => {
    try {
      const headers = ["Дата отгрузки", "Номер отгрузки", "Мешков", "Стоимость поставки", "Приход"];

      let data = [
          ...client.shipments.map(shipment => [
            moment(shipment.shipped_at),
            shipment.id,
            shipment.totalNumberOfBags,
            {v: shipment.totalPrice, z: "$0.00", w: undefined},
            ""
          ]),
          ...client.payments.map(payment => [
            moment(payment.transferred_at),
            "Приход",
            "",
            "",
            payment.amount
          ])
      ]

      data.sort((a, b) => {
        if(a[0] < b[0]) {
          return -1;
        } else return 1;
      })

      const combinedTotalPrice = client.shipments.reduce((acc, item) => acc + item.totalPrice, 0);
      const totalPaymentAmount = client.payments.reduce((acc, item) => acc + item.amount, 0);
      const diff = combinedTotalPrice - totalPaymentAmount;

      data = data.map(([date, ...rest]) => [date.format("DD/MM/YYYY"), ...rest])
      data.push(["", "", "", "", ""])
      data.push(["", "", "",
          combinedTotalPrice,
          totalPaymentAmount
      ])
      data.push(["", "", "", "", ""])
      data.push([`${diff > 0 ? "Долг" : "Предоплата"} на ${moment().format("DD/MM/YYYY")}`, "", "",
        diff > 0 ? diff : -diff,
        ""])
      data.unshift(headers);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      worksheet['!cols'] = [
        {wch:17},
        {wch:10},
        {wch:10},
        {wch:10},
        {wch:15}
      ]

      XLSX.utils.book_append_sheet(workbook, worksheet, "Акт сверки");

      XLSX.writeFile(workbook, `Акт сверки ${client.name}.xlsx`);
    } catch (error) {
      openNotificationWithIcon("Ошибка", error.message, "error");
    }
  }

  return (
    <Spin spinning={status === clientDetailsStatuses.FETCHING_CLIENT}>
      <div className={"mt-paper"}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Клиент</h2>
          {hasAccessToBalance && (
            <span>
              <strong>Текущий баланс:</strong>{" "}
              <span
                style={{
                  color: client
                    ? client.currentBalance >= -0.0001
                      ? "green"
                      : "red"
                    : "green",
                }}
              >
                {client ? Number(client.currentBalance).toFixed(2) : 0}$
              </span>
            </span>
          )}
        </div>
        {status === clientDetailsStatuses.CREATE_NEW_CLIENT && (
          <Button loading={isSaving} onClick={onCreateClient}>
            Сохранить
          </Button>
        )}
        {status === clientDetailsStatuses.EDIT_CLIENT && (
          <Button loading={isSaving} onClick={onUpdateClient}>
            Сохранить
          </Button>
        )}
        {status === clientDetailsStatuses.SHOW_CLIENT && (
          <Button onClick={() => setStatus(clientDetailsStatuses.EDIT_CLIENT)}>
            Изменить
          </Button>
        )}
        <Button
            style={{float: "right"}}
            onClick={generateReconciliationAct}
        >
          Сгенерировать акт сверки
        </Button>
        <br />
        <div style={{ marginTop: "15px" }}>
          <label className={"mt-label"}>Название</label>
          <br />
          <Input
            placeholder={"Введите название"}
            value={getValueOfElement("name")}
            onChange={handleOnChange("name")}
            disabled={disabled}
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <label className={"mt-label"}>Имя представителя</label>
          <br />
          <Input
            placeholder={"Введите имя"}
            value={getValueOfElement("nameOfRepresentative")}
            onChange={handleOnChange("nameOfRepresentative")}
            disabled={disabled}
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <label className={"mt-label"}>Номер телефона</label>
          <br />
          <Input
            placeholder={"Введите номер"}
            value={getValueOfElement("phoneNumber")}
            onChange={handleOnChange("phoneNumber")}
            disabled={disabled}
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <label className={"mt-label"}>Адрес</label>
          <br />
          <Input
            placeholder={"Введите адрес"}
            value={getValueOfElement("address")}
            onChange={handleOnChange("address")}
            disabled={disabled}
          />
        </div>

        <br />
        <br />
        {client && client.shipments && (
          <Table
            dataSource={client.shipments.map(shipment => ({
              id: shipment.id,
              totalPrice: shipment.totalPrice,
              totalNumberOfBags: shipment.totalNumberOfBags,
              shipmentStatusType_id: shipment.shipmentStatusType_id,
            }))}
            size={"small"}
            bordered
            pagination={{ pageSize: 8 }}
            columns={[
              {
                title: "Отгрузка",
                dataIndex: "id",
                key: "id",
                render(text, record) {
                  return (
                    <a onClick={e => handleRedirectToShipment(e, record.id)}>
                      SHP-{record.id}
                    </a>
                  );
                },
              },
              {
                title: "Общая стоимость",
                dataIndex: "totalPrice",
                key: "totalPrice",
                render(text) {
                  return `${text}$`;
                },
              },
              {
                title: "Количество мешков",
                dataIndex: "totalNumberOfBags",
                key: "totalNumberOfBags",
              },
              {
                title: "Статус отгрузки",
                dataIndex: "shipmentStatusType_id",
                key: "shipmentStatusType_id",
                render(id, record) {
                  return (
                    <>
                      {id === ShipmentStatusTypes.SHIPPED && "Отправлено"}
                      {id === ShipmentStatusTypes.BEING_PREPARED &&
                        "В процессе подготовки"}
                    </>
                  );
                },
              },
            ]}
          />
        )}
      </div>
    </Spin>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.data.users.user.user.roles,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchClient: id => dispatch(fetchClient(id)),
  createClient: client => dispatch(createClient(client)),
  updateClient: (id, client) => dispatch(updateClient(id, client)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ClientDetails),
);
