import { combineReducers } from "redux";

import { SET_OPENED_PERIOD_ID } from "./actions";

const openedPeriodId = (state = "", action) => {
  switch (action.type) {
    case SET_OPENED_PERIOD_ID:
      return action.periodId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedPeriodId,
});
