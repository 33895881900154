import { createSelector } from "reselect";

import * as dataReducer from "./data/reducer";

//export const getSpecs=(state)=>state.scenes.specification.data.specs;

export const openedSpread = state => state.scenes.forms.specform.openedSpread;
export const openedBom = state => state.scenes.forms.specform.openedBom;
export const openedPattern = state => state.scenes.forms.specform.openedPattern;
export const specName = state => state.scenes.forms.specform.specName;

export const getSelectedSpreadTab = state =>
  state.scenes.forms.specform.selectedSpreadTab;

//state.scenes.forms.specform
//export const getOpenedSpecId=(state)=>state.scenes.product.openedProduct

export const getSpecModelFromState = state => {
  let boms = dataReducer.getBoms(state);
  let spreads = dataReducer.getSpreads(state);
  let patterns = dataReducer.getPatterns(state);
  //return foundSpread;

  let allBoms = boms.allIds.map(id => {
    let bom = boms.byId[id];

    let result = [];
    let spreadsForBom = [];
    if (bom.spreads.length > 0)
      spreadsForBom = bom.spreads.map(id => spreads.byId[id]);

    if (spreadsForBom.length > 0) {
      spreadsForBom.forEach(spread => {
        let patternsForSpread = spread.patterns.map(id => patterns.byId[id]);
        let newSpread = {
          ...spread,
          patterns: patternsForSpread,
        };
        result.push(newSpread);
      });
    }

    return { ...bom, spreads: result };

    //newBom
  });

  return { name: specName(state), boms: allBoms };
};

export const getOpenedSpread = state => {
  let spreads = dataReducer.getSpreads(state);
  let foundSpread = spreads.byId[openedSpread(state)];
  return foundSpread;
};

export const getOpenedBom = state => {
  let boms = dataReducer.getBoms(state);
  let foundBom = boms.byId[openedBom(state)];
  return foundBom;
};

export const getOpenedPattern = state => {
  let patterns = dataReducer.getPatterns(state);
  let foundBom = patterns.byId[openedPattern(state)];
  return foundBom;
};

export const getSelectedSpreadTabByProp = (state, props) => {
  let tabs = getSelectedSpreadTab(state);
  if (tabs) {
    return tabs[props.bomId];
  } else return undefined;
};

export const getSpreadsWithPatternsByProp = (state, props) => {
  let bom = dataReducer.getBoms(state).byId[props.bomId];
  let result = [];
  if (props.bomId) {
    let patterns = dataReducer.getPatterns(state);

    let spreads = dataReducer.getSpreads(state);

    let spreadsForBom = [];
    if (bom.spreads.length > 0)
      spreadsForBom = bom.spreads.map(id => spreads.byId[id]);

    if (spreadsForBom.length > 0) {
      spreadsForBom.forEach(spread => {
        let patternsForSpread = spread.patterns.map(id => patterns.byId[id]);
        let newSpread = {
          ...spread,
          patterns: patternsForSpread,
        };
        result.push(newSpread);
      });
    }
  }
  return result;
};

// export const getBomsForOpenedSpec = createSelector(
//     [getSpecs, dataReducer.getBoms, getOpenedSpecId],
//     (specs, boms, productId) => {

//         var result = [];
//         //let boms = getBoms(state);
//         var spec = specs.byId[productId];

//         if (spec && spec.boms) {
//             result = product.boms.map(id => boms.byId[id]);
//         }

//         return result;
//     }
// )

export const getFormBoms = state => {
  return dataReducer
    .getBoms(state)
    .allIds.map(id => dataReducer.getBoms(state).byId[id]);
};

export const makeGetSelectedSpreadTabByBomId = () => {
  return createSelector(
    [getSelectedSpreadTabByProp],
    spreadId => {
      //let products = getProducts(state);
      return spreadId;
    },
  );
};

export const makeGetSpreadsWithPatternsByBomId = () => {
  return createSelector(
    [getSpreadsWithPatternsByProp],
    spreads => {
      //console.log("recalculated recalculated recalculated recalculated")

      //let products = getProducts(state);
      return spreads;
    },
  );
};
