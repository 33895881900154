import "./index.css";

import { Breadcrumb, Button, Col, Layout, Menu, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import {
  deleteEmployee,
  fetchEmployeesWithSummary,
} from "../../data/employees/actions";
import { getAllEmployeesWithRowNum } from "../../data/employees/reducer";
import EmployeeTable from "./components/EmployeeTable";
import { getEmployeesByPage } from "./selectors";

class WageSummaryMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxPages: 2,
      currentPage: 0,
    };
  }

  componentDidMount() {
    const { fetchEmployeesWithSummary, match } = this.props;
    fetchEmployeesWithSummary(employees => {
      let pageCount = Math.ceil(employees.length / 92);
      this.setState({ maxPages: pageCount });
    });

    this.interval = setInterval(() => {
      fetchEmployeesWithSummary();

      this.setState((prevState, props) => {
        if (prevState.currentPage + 1 == prevState.maxPages) {
          return { currentPage: 0 };
        } else {
          return { currentPage: prevState.currentPage + 1 };
        }
      });
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    const { allEmployees } = this.props;
    if (prevProps.allEmployees != allEmployees) {
      let pageCount = Math.ceil(allEmployees.length / 92);
      this.setState({ maxPages: pageCount });
    }
  }

  getEmployeesByPage = (allEmployees, pageNum, numberPerPage) => {
    //pageNum 1
    //Number per page 5

    let beginIndex = pageNum * numberPerPage;
    //0

    if (beginIndex > allEmployees.length) {
      return [];
    }
    let res = [];

    let currentIndex = 0;

    for (let i = beginIndex; i < allEmployees.length; i++) {
      if (currentIndex >= numberPerPage) {
        break;
      }

      let employee = allEmployees[i];
      res.push(employee);
      currentIndex++;
    }

    return res;
  };

  render() {
    const {
      history,
      employees1,
      employees2,
      employees3,
      employees4,
      allEmployees,
    } = this.props;

    console.log("ALL EMPLOYEES", allEmployees);

    const onEmployeeCreate = () => {
      history.push(`/employee/new`);
    };

    const handleEditClick = contractor => {
      //console.log(id);
      history.push(`/employee/` + contractor.id);
    };

    const onRemove = value => {
      const { deleteEmployee } = this.props;
      deleteEmployee(value.id);
      //history.push(`/product-admin/`)
    };

    let currentCount = 0;
    let currentEmployees = [];
    //  allEmployees.forEach((employee,index)=>{
    //      console.log(index);

    //      if(currentCount==20){

    //         currentEmployees=[];
    //         currentCount=0;
    //      }

    //      currentEmployees.push(employee);
    //      currentCount++;

    //  })

    //maxPage 2
    //currentPage 1
    let currentStartPageIndex = this.state.currentPage * 4;

    return (
      <div className="mt-wagesummary">
        <Row>
          <Col span={6}>
            <EmployeeTable
              onEditClick={handleEditClick}
              onRemove={onRemove}
              employees={this.getEmployeesByPage(
                allEmployees,
                currentStartPageIndex,
                23,
              )}
            />
          </Col>
          <Col span={6}>
            <EmployeeTable
              onEditClick={handleEditClick}
              onRemove={onRemove}
              employees={this.getEmployeesByPage(
                allEmployees,
                currentStartPageIndex + 1,
                23,
              )}
            />
          </Col>
          <Col span={6}>
            <EmployeeTable
              onEditClick={handleEditClick}
              onRemove={onRemove}
              employees={this.getEmployeesByPage(
                allEmployees,
                currentStartPageIndex + 2,
                23,
              )}
            />
          </Col>
          <Col span={6}>
            <EmployeeTable
              onEditClick={handleEditClick}
              onRemove={onRemove}
              employees={this.getEmployeesByPage(
                allEmployees,
                currentStartPageIndex + 3,
                23,
              )}
            />
          </Col>
        </Row>
        <div style={{ paddingTop: 10 }}>
          <label>
            <b>
              Страница {this.state.currentPage + 1} из {this.state.maxPages}
            </b>
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    allEmployees: getAllEmployeesWithRowNum(state),
    employees1: getEmployeesByPage(state, 0, 50),
    employees2: getEmployeesByPage(state, 1, 50),
    employees3: getEmployeesByPage(state, 2, 50),
    employees4: getEmployeesByPage(state, 3, 50),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchEmployeesWithSummary: () => dispatch(fetchEmployeesWithSummary()),
    deleteEmployee: id => dispatch(deleteEmployee(id)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WageSummaryMain),
);

//export default ContractorMain
