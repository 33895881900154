import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Dropdown, Menu, Modal, Select } from "antd";
import axios from "axios";
import { fetchProdDepotLocations } from "data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "data/prodDepotLocations/reducer";
import {
  createShipment,
  fetchShipment,
  updateShipment,
} from "data/shipments/actions";
import { getShipment } from "data/shipments/selectors";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setOpenedShipmentId } from "scenes/Shipment/actions";

import { shipment } from "../../../../data/shipments/schema";
import isMobile from "../../../../utils/mobileCheck";
import {
  checkAvailable,
  decodeStatus,
  getDefaultPrinter,
  readPrinterStatus,
  sendToPrinter,
} from "../../../printHelper";
import ShipmentForm from "../../forms/ShipmentForm";
import ShipmentInvoice from "../ShipmentInvoice";

const { Option } = Select;
const confirm = Modal.confirm;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function ShipmentDetail(props) {
  const {
    history,
    match,
    fetchShipment,
    createShipment,
    updateShipment,
    setOpenedShipmentId,
    fetchProdDepotLocations,
    openedShipment,
    classes,
    user,
    domainSettings,
  } = props;

  const [isInEditState, toggleEditState] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isInvoiceEmpty, setIsInvoiceEmpty] = useState(null);
  const [formData, setFormData] = useState({
    client: "",
    shipped_at: null,
    statusTypeId: 1,
    lineItems: [
      {
        id: nanoid(5),
        product: null,
        price: null,
        numberOfBags: null,
        quantity: "",
        stocks: {},
      },
    ],
  });

  const form = useRef();

  const startSubmit = e => {
    e.preventDefault();
    form.current.submitForm();
  };

  const addLabelFieldsToProduct = product => {
    if (product) {
      return {
        ...product,
      };
    }
    return null;
  };

  useEffect(() => {
    setOpenedShipmentId("");
    if (match.params.id) {
      fetchShipment(match.params.id).then(entry => {
        setOpenedShipmentId(entry.id);
        toggleEditState(false);
        fetchProdDepotLocations();
        setFormData(prev => {
          return {
            ...prev,
            client: entry.client,
            shipped_at: moment(entry.shipped_at),
            statusTypeId: entry.shipmentStatusType_id,
            totalShipmentValue:
              entry.lineItems.reduce(
                (acc, i) =>
                  i.price && i.quantity ? acc + i.price * i.quantity : acc + 0,
                0,
              ) || "",
            lineItems: entry.lineItems.map(lineItem => ({
              id: lineItem.id,
              database_id: lineItem.id,
              ...lineItem,
              product: addLabelFieldsToProduct(lineItem.product),
            })),
          };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  const onProdDepotEntryClick = id => {
    history.push(`/proddepotentry/` + id);
  };

  const createShipmentFromValues = values => ({
    client: values.client,
    shipped_at: values.shipped_at.startOf("day").format(),
    statusType_id: values.statusTypeId,
    lineItems: values.lineItems.map(lineItem => ({
      ...lineItem,
      id: lineItem.database_id,
      product_id: lineItem.product.id,
    })),
  });

  const generateShipmentLineItemReceiptCode = shipmentLineItem => `
^XA
^MNN
^MMT
^PW631
^LL0060
^LS0
^FT599,21^A0I,23,19^FH^FD${shipmentLineItem.product.name.slice(0, 25)}^FS
^FT279,21^A0I,23,24^FH^FD${shipmentLineItem.quantity.toString().slice(0, 9)}^FS
^FT135,21^A0I,23,24^FH^FD${shipmentLineItem.price.toString().slice(0, 9)}^FS
^PQ1,0,1,Y^XZ
`;

  const generateReceiptCode = shipment => {
    let receiptBody = "";
    for (const shipmentLineItem of shipment.lineItems) {
      receiptBody += generateShipmentLineItemReceiptCode(shipmentLineItem);
    }
    const sum =
      shipment.lineItems
        .reduce(
          (acc, i) =>
            i.price && i.quantity ? acc + i.price * i.quantity : acc + 0,
          0,
        )
        .toString() || "";
    const quantity =
      shipment.lineItems.reduce((acc, i) => acc + i.quantity, 0).toString() ||
      "";
    return `
^XA
^MNN
^MMT
^CI28
^PW631
^LL0160
^LS0
^FO224,96^GFA,00768,00768,00012,:Z64:
eJzl0D0OwjAMBWAHS2SDHgAJJC7BxM+NGonBo6vunMld2hFuQCQGNhg6AKrU0IS2YmBghpfl05PjwQA/mUGvCIa9FzDqPWkeIJ+4xt3U1QA6uwnpo5ao6W1lCa8pzwAUGyFVIK/9t0oI9ymzt+FY5VrCuhIIL/jqt0DqgIHw4K5XYCRWhc7A7y8tzc+Yeoth2uSYePNy7OQe3CbMtwnznZPPfp9fOfvduf43T0haOcU=:FF15
^FO160,0^GFA,01280,01280,00020,:Z64:
eJztkTFOxDAQRScYkQKUYTsKK0HiAiktiAId1+AIKbf0anu4AIeJlYJrjGUhCppZ0VgK2mW8KTcFHRLaP93T07fHBjjmmH8eRRgrG3Ed1Rgi3+LLFvLLpsPsu3KcbRzXb7ljKJplxJP2hggC0V1YE8OF7jqEMmMC7/zCrYSVpYkIBfDeU8PEpA8Qug94d14NPTO0LcbEONAnJRYZtNbi5ffse+nLXL+Uc6c+Q57G5NlGmDZyv7P6afIGW6X7iXf+bCikUcHWLHtI3+L1ym1kPDwC8H7fXZR9x68x7KyBa555lRk2450+HLLCHjJczXj59lf/84f5ARJbgbk=:409C
^FO32,0^GFA,00768,00768,00012,:Z64:
eJxjYBgFgwwIILENcIjLQKnCvx9q5P//B7ErP3x48I+94QeIbVNQ+FGe/UABmF1R8IC/GSIuU1Dwgb0dIm7xoOABM1Tc9kA9XNyGoeDhf7j6wo/HoeZYFBQ8bGZGmA8T51H48OAYVJz9INA9UHEwgIqDATHi7Eji8p//EwwrigEA3MpA8Q==:3704
^FO448,0^GFA,01280,01280,00020,:Z64:
eJztzTFuAjEQBdDxWsKdp6SxLCSKXCEF2g0KN8kFXKbDqSi5QbiKE+UYKWaXho7QjRTEBguy2IEuUpSCX0zx9fQH4Jpr/jYUz9SnlfiI1+TuNh6bd+7cFQUMAMrcCbhLHRJzIVti+Vytd4dOBud6gu6NNnj4H7tH1kAzbUv0dJwKxqEgpY1RJ2d5D1CPbOdkQNcXNarMId/4BnGU7oGTTzVi5oCHofmxN3Hjl/zv8HXFD9QoXSZ7b8GNN7Uw+N65aku8/Gw9y8X82yXpXBL8hcMLrmp3Z93/yxej+FUS:A581
^FO16,80^GB599,0,8^FS
^FT615,110^A0I,34,33^FH^FD${user.factoryName.slice(0, 15)}^FS
^FT239,116^A0I,28,28^FH^FD${moment().format("DD.MM.YYYY HH:mm")}^FS
^PQ1,0,1,Y^XZ
${receiptBody}

^XA
^MNN
^MMT
^PW631
^LL0120
^LS0
^FO416,0^GFA,01536,01536,00024,:Z64:
eJztkb9KA0EQh+dY2LUI9wct9mBR8AHkAhZzJAQrfY0EX2DtLBJyWoiF3BPY+QKWFiITTnIvEWSSK64LWgpi3Gh7jY2N+ZqB4ePHjxmADRv+gq2f0bd78ze2Q/UyHMxLOjTBilkdgV2mvDhHFI94h1WWH/hp9YAMyXNEpE3omXBbn4FUMip0YsHLU2L01/6l7mS+yNNCWwSvjIlNqy26iTIxKCHbpelrENOAuetj9YmyF2RS5J16l3vfPsUf4WQWKuPBaiyj0rAB78rlxDdY3Lp8kU0rl2+4C/1pRPOolRQmUeEEjk9lu9hxvuu/qO+vsXpC4fov99f9GSHRqnwnO6pn4ws6odAEIyKrm0/k/EasbdqKV3efBjxS2a/fs+Ff8gWFs2mb:7BE0
^FO448,32^GFA,01280,01280,00020,:Z64:
eJxjYBgFo4BKwAKLmAwWMQ4izWtAVsx4XOBD8cEDjA/Y7yTz/3/wgN2BgYH58Y4k5RMNTRXMO5L7LB/vsHgAVNcsU8h4huGABLNEIZvgYQmDAqBm5hmPF/Y1PrBg3vG4z/KwRAHIpcwC5Yx3GA/IMBuUsRkel0mQAIktSF78rumBDfOPPD7r53IP7EAWNxQ2HmMokGCUKGATPC7zQAZs3suFyxoMJJjnAO09LPPABqjssEThweMshgLMMsVshodFQOqYH3d8ePy4zXoD845iPqD7HgDtZWy88OHj4TO1B9g/yPDXHjhQIIEZPg+whGRBAboI8weQf1EB4wH2BiJDdxQAAQCyRldo:164A
^FO19,96^GB588,0,8^FS
^FT407,25^A0I,23,21^FH^FD${quantity.slice(0, 15)}^FS
^FT455,65^A0I,23,21^FH^FD${sum.slice(0, 15)}^FS
^PQ1,0,1,Y^XZ
`;
  };

  const showConfirm = (shipment, message) => {
    return new Promise(resolve => {
      confirm({
        title: "Попробовать напечатать заново?",
        content: message,
        onOk() {
          printCode(shipment).then(() => resolve());
        },
        onCancel() {
          resolve();
        },
      });
    });
  };

  const printCode = shipment =>
    getDefaultPrinter()
      .then(json => {
        //check if the default printer is available for printing
        return checkAvailable().then(available => {
          let foundPrinter = undefined;
          if (available.printer && available.printer.length > 0) {
            //return json
            available.printer.forEach(availablePrinter => {
              if (availablePrinter.name == json.name) {
                foundPrinter = availablePrinter;
              }
            });
          }
          if (foundPrinter == undefined) {
            throw "Принтер не подключен";
          } else {
            //send read printer status request
            return sendToPrinter(json, "~HQES").then(data => {
              return json;
            });
          }
        });
      })
      .then(printer => {
        //check if the printer is ready to print
        return readPrinterStatus(printer)
          .then(text => {
            let status = decodeStatus(text);
            if (status == "Ready to Print") {
              return sendToPrinter(
                printer,
                generateReceiptCode(shipment),
              ).then(() => showConfirm(shipment).then(() => null));
            } else {
              throw status;
            }
          })
          .catch(error => {
            return showConfirm(shipment, error);
          });
      })
      .catch(error => {
        return showConfirm(shipment, error);
      });

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    const shipment = createShipmentFromValues(values);
    let hasError = false;

    if (hasError) {
      return;
    }

    setSaving(true);
    if (!match.params.id) {
      createShipment(shipment).then(
        json => {
          setSubmitting(false);
          setSaving(false);
          toggleEditState(false);
          history.push(`/shipment/` + json.id);
        },
        error => {
          setSubmitting(false);
          setSaving(false);
        },
      );
    } else {
      updateShipment(match.params.id, shipment).then(
        json => {
          setSubmitting(false);
          setSaving(false);
          toggleEditState(false);
        },
        error => {
          setSubmitting(false);
          setSaving(false);
        },
      );
    }
  };

  const createRows = () => {
    let rows = [];
    if (
      openedShipment &&
      openedShipment.lineItems &&
      openedShipment.lineItems.length
    ) {
      for (const lineItem of openedShipment.lineItems) {
        if (lineItem.prodStocks && lineItem.prodStocks.length) {
          for (const prodStock of lineItem.prodStocks) {
            rows.push(prodStock);
          }
        }
      }
    }
    return rows;
  };

  const rows = createRows();

  const printReceiptHandler = () => {
    setIsPrinting(true);
    printCode(openedShipment).finally(() => setIsPrinting(false));
  };

  const menu = (
    <Menu>
      <Menu.Item disabled={isPrinting} onClick={printReceiptHandler}>
        Штрих код
      </Menu.Item>
      <Menu.Item onClick={() => setIsInvoiceEmpty(false)}>
        Счёт-фактура
      </Menu.Item>
      <Menu.Item onClick={() => setIsInvoiceEmpty(true)}>
        Счёт-фактура без цены
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (isInvoiceEmpty !== null) {
      window.print();
      setIsInvoiceEmpty(null);
    }
  }, [isInvoiceEmpty]);

  const renderMobile = () => (
    <div style={{ margin: "10px 10px 10px 10px" }}>
      <h2>Запись отгрузки</h2>
      {match.params.id !== undefined && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {!isInEditState && (
              <Button
                style={{ marginRight: "10px", flexGrow: "1" }}
                type={"primary"}
                onClick={() => toggleEditState(true)}
              >
                Изменить
              </Button>
            )}
            {isInEditState && (
              <Button
                style={{ marginRight: "10px", flexGrow: "1" }}
                type={"primary"}
                onClick={startSubmit}
                loading={saving}
              >
                Сохранить
              </Button>
            )}
          </div>
          <br />
          <br />
        </div>
      )}
      {match.params.id == undefined && (
        <div>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={startSubmit}
            loading={saving}
          >
            Сохранить
          </Button>
          <br />
          &nbsp;
        </div>
      )}
      <ShipmentForm
        user={user}
        disabled={!isInEditState}
        ref={form}
        mobile
        onSubmit={handleSubmit}
        //shipmentId={openedShipment ? openedShipment.id : null}
        initialValues={formData}
      />

      {/*
      don't delete this span. It fixes Chrome bug
      For more information see: https://stackoverflow.com/questions/39364259/chrome-print-preview-doesnt-load-media-only-print-font-face
      */}
      <span style={{ fontFamily: "proxima-nova", visibility: "hidden" }}>
        .
      </span>
    </div>
  );

  const renderPC = () => (
    <div className="mt-content">
      <div>
        <h2>Запись отгрузки</h2>
      </div>
      {match.params.id !== undefined && (
        <div>
          <div>
            {!isInEditState && (
              <Button
                style={{ marginRight: "10px" }}
                type={"primary"}
                onClick={() => toggleEditState(true)}
              >
                Изменить
              </Button>
            )}
            {isInEditState && (
              <Button
                style={{ marginRight: "10px" }}
                type={"primary"}
                onClick={startSubmit}
                loading={saving}
              >
                Сохранить
              </Button>
            )}
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button> Распечатать</Button>
            </Dropdown>
          </div>
          <br />
          <br />
        </div>
      )}
      {match.params.id == undefined && (
        <div>
          <Button type="primary" onClick={startSubmit} loading={saving}>
            Сохранить
          </Button>
          <br />
          &nbsp;
        </div>
      )}
      <ShipmentForm
        user={user}
        disabled={!isInEditState}
        ref={form}
        onSubmit={handleSubmit}
        //shipmentId={openedShipment ? openedShipment.id : null}
        initialValues={formData}
      />

      {/*
      don't delete this span. It fixes Chrome bug
      For more information see: https://stackoverflow.com/questions/39364259/chrome-print-preview-doesnt-load-media-only-print-font-face
      */}
      <span style={{ fontFamily: "proxima-nova", visibility: "hidden" }}>
        .
      </span>

      <ShipmentInvoice
        empty={isInvoiceEmpty}
        logoImgPath={domainSettings.find(setting => setting.id === 3).value}
        shipment={openedShipment}
      />
    </div>
  );

  return isMobile() ? renderMobile() : renderPC();
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.data.users.user.user,
    openedShipment: getShipment(state, state.scenes.shipment.openedShipmentId),
    prodDepotLocations: getAllProdDepotLocations(state),
    domainSettings: state.domainSettings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchShipment: id => dispatch(fetchShipment(id)),
    setOpenedShipmentId: id => dispatch(setOpenedShipmentId(id)),
    createShipment: shipment => dispatch(createShipment(shipment)),
    updateShipment: (id, shipment) => dispatch(updateShipment(id, shipment)),
    // updateShipment: (id, entry) => dispatch(updateShipment(id, entry)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

ShipmentDetail = withStyles(styles)(ShipmentDetail);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShipmentDetail),
);
