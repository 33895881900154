//import Button from 'material-ui/Button';

import "../print.css";

import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Col, Divider, InputNumber, Row, Table } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { setShowSewingPassportModal } from "../../../actions";
import {
  getOpenedCuttingOrder,
  getProdBomsByOpenedOrderWithSpreadsPatternsEntries,
} from "../../../selectors";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: "800px",
      },
      root: {
        alignItems: "initial",
        zIndex: 1000,
        height: "100%",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class SewingPassportModal extends Component {
  constructor(props) {
    super(props);
  }

  close = () => {
    this.props.setShowSewingPassportModal(false);
  };

  render() {
    const {
      showModal,
      fullScreen,
      openedOrder,
      prodBoms,
      productionProcessType_id,
    } = this.props;
    const passportComponent = (
      <>
        <Row>
          <Col span={8}>Заказ: PR-{openedOrder.id}</Col>
        </Row>
        <Row>Модель: {openedOrder.product.name}</Row>
        <Row>
          Список частей:{" "}
          {prodBoms.map(
            (prodBom, index) =>
              prodBom.name + (index === prodBoms.length - 1 ? "" : ", "),
          )}
        </Row>
        <Divider />
        <Table
          bordered={true}
          rowClassName={"noHighlight"}
          size={"small"}
          pagination={false}
          columns={[
            {
              dataIndex: "label",
              key: "label",
              align: "center",
              width: 80,
              render(value) {
                return {
                  props: {
                    style: { height: "25px", padding: 0 },
                  },
                  children: value,
                };
              },
            },
            ...openedOrder.lineItems.map((lineItem, lineItemIndex) => ({
              title: lineItem.size.name,
              dataIndex: "lineItems",
              key: "sizeName" + lineItemIndex,
              align: "center",
              width: 80,
              render(value, record, index) {
                return {
                  props: {
                    style: { height: "25px", padding: 0 },
                  },
                  children: value
                    ? value.find(item => item.sizeId === lineItem.size.id)
                        .quantity
                    : "",
                };
              },
            })),
            {
              title: "Всего",
              key: "lineItemsSum",
              align: "center",
              width: 70,
              render(value, record, index) {
                return {
                  props: {
                    style: { height: "25px", padding: 0 },
                  },
                  children: value.lineItems
                    ? value.lineItems.reduce(
                        (acc, curr) => acc + curr.quantity,
                        0,
                      )
                    : "",
                };
              },
            },
          ]}
          dataSource={[
            ...prodBoms.map((prodBom, key) => {
              return {
                key,
                label: prodBom.name,
                lineItems: openedOrder.lineItems.map(lineItem => ({
                  sizeId: lineItem.size.id,
                  sizeName: lineItem.size.name,
                  quantity: Math.min(
                    ...prodBom.prodSpreads.map(prodSpread => {
                      return openedOrder.cuttingPassportEntry.fabricConsumptions
                        .filter(
                          fabricConsumption =>
                            fabricConsumption.prodBom_id === prodBom.id &&
                            fabricConsumption.prodSpread_id === prodSpread.id,
                        )
                        .reduce(
                          (acc, curr) =>
                            acc +
                            curr.lineItems.find(
                              item => item.size.id === lineItem.size.id,
                            ).quantity,
                          0,
                        );
                    }),
                  ),
                })),
              };
            }),
            { label: "Cшито:", key: 2 },
          ]}
        />
        <br />
        {productionProcessType_id === ProductionProcessTypes.id.PASSPORT &&
          prodBoms.map((prodBom, prodBomIndex) => (
            <div key={prodBomIndex}>
              <Row>
                <h3>Часть: {prodBom.name}</h3>
              </Row>
              <Table
                size={"small"}
                pagination={false}
                rowClassName={"noHighlight"}
                columns={[
                  {
                    title: "Операция",
                    dataIndex: "prodOperation",
                    key: "prodOperation",
                    width: 300,
                    render(value, record, index) {
                      return {
                        props: {
                          style: { height: "10px", padding: 0 },
                        },
                        children: `${value}`,
                      };
                    },
                  },
                  {
                    title: "Сотрудник",
                    dataIndex: "employee",
                    key: "",
                    render(value, record, index) {
                      return {
                        props: {
                          style: { height: "10px" },
                        },
                      };
                    },
                  },
                  {
                    title: "Количество",
                    dataIndex: "quantity",
                    key: "",
                    width: 150,
                    render(value, record, index) {
                      return {
                        props: {
                          style: { height: "10px" },
                        },
                      };
                    },
                  },
                ]}
                dataSource={prodBom.prodOperations
                  .filter(operation => operation.operationType_id !== 1)
                  .map(operation => ({
                    prodOperation: operation.operation,
                    quantity: "",
                    employee: "",
                  }))}
              />
              {prodBomIndex !== prodBoms.length - 1 && <Divider />}
            </div>
          ))}
      </>
    );

    return (
      <div>
        {showModal && (
          <div id="toPrint">
            <h2>Швейный паспорт</h2>
            {passportComponent}
          </div>
        )}
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            scroll={"paper"}
            open={showModal}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Паспорт пошива
            </DialogTitle>
            <DialogContent>
              <div>{passportComponent}</div>
            </DialogContent>
            <DialogActions>
              <Button type={"primary"} onClick={window.print}>
                Распечатать
              </Button>
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

// CuttingPassportModal.propTypes = {
//     fullScreen: PropTypes.bool.isRequired,
// };

const mapStateToProps = (state, ownProps) => {
  return {
    showModal: state.scenes.cutting.showSewingPassportModal,
    prodBoms: getProdBomsByOpenedOrderWithSpreadsPatternsEntries(state),
    openedOrder: getOpenedCuttingOrder(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowSewingPassportModal: showModal =>
      dispatch(setShowSewingPassportModal(showModal)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SewingPassportModal),
);
