import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import CuttingEntryList from "./CuttingEntryList";
import Cutting from "./index.js";

///import MaterialDetailContainer from './MaterialDetailContainer'

class CuttingRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path={`${this.props.match.url}/:id/spreads/:prodSpreadId?`}
            component={CuttingEntryList}
          />
          <Route path="/cutting" component={Cutting} />
        </Switch>
      </div>
    );
  }
}

export default CuttingRoute;
