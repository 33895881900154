import { Button, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { deleteClient, fetchClients } from "../../data/clients/actions";
import ClientTable from "./components/ClientTable";

function ClientMain(props) {
  const { fetchClients, history, clients, deleteClient, roles } = props;

  const [isFetchingClients, setIsFetchingClients] = useState(false);

  useEffect(() => {
    setIsFetchingClients(true);
    fetchClients().then(
      () => setIsFetchingClients(false),
      () => setIsFetchingClients(false),
    );
  }, []);

  const onCreateClick = () => {
    history.push("/client/new");
  };

  const onDeleteClick = clientId => {
    deleteClient(clientId);
  };

  return (
    <div>
      <h2>Клиенты</h2>
      <div>
        <Button type={"primary"} onClick={onCreateClick}>
          Создать
        </Button>
      </div>
      <br />
      <ClientTable
        isFetchingClients={isFetchingClients}
        clients={clients}
        history={history}
        showBalance={!!roles.find(role => role.id === 6 || role.id === 1)}
        onDelete={onDeleteClick}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  clients: state.data.clients.all,
  roles: state.data.users.user.user.roles,
});

const mapDispatchToProps = dispatch => ({
  fetchClients: () => dispatch(fetchClients()),
  deleteClient: clientId => dispatch(deleteClient(clientId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ClientMain),
);
