import { Button, Popconfirm, Select } from "antd";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

const Option = Select.Option;

class FabDepotEntryTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  render() {
    const {
      fabDepotEntries,
      onEditClick,
      onRemove,
      classes,
      loading,
      depotLocationOptions,
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          // <div>
          //     <Button type="primary" size="small" icon="edit" onClick={(e => onEditClick(row.original, e))}  >
          //         {/* <OpenInNew classes={{ root: classes.iconRoot }} />
          //         <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
          //     </Button>
          // </div>

          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/fabdepot/" + row.original.id}
          >
            {"FDE-" + row.value}
          </a>
        ),
      },
      {
        Header: "Тип",
        maxWidth: 45,
        filterable: false,
        Cell: row =>
          row.original.fabStocks.length > 0 &&
          row.original.fabStocks[0].weight > 0
            ? " Р"
            : "",
      },
      {
        Header: "Дата",
        accessor: "entry_date", // String-based value accessors!,
        Cell: row =>
          row.value && (
            <span className="number">
              {new moment(row.value).format("DD/MM/YYYY")}
            </span>
          ),
        filterable: false,
        sortable: false,
      },
      {
        Header: "Материал",
        accessor: "fabric.name",
        id: "fabricName",
        Cell: row => <span className="number">{row.value}</span>,
        filterMethod: (filter, rows) => {
          //console.log("filter",rows)
          return matchSorter(rows, filter.value, { keys: ["fabricName"] });
        },
        filterAll: true,
        // Custom cell components!
      },
      {
        Header: "Номер партии",
        accessor: "batchNumber",
        id: "batchNumber",
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, { keys: ["batchNumber"] });
        },
        filterAll: true,
      },
      {
        Header: "Склад",
        accessor: "depotLocation.name",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
        //id:"team.name",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <Select
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={value => onChange(value)}
          >
            <Option key={"all"} value="all">
              Все
            </Option>
            {depotLocationOptions &&
              depotLocationOptions.map(depot => (
                <Option key={depot.name} value={depot.name}>
                  {depot.name}
                </Option>
              ))}
          </Select>
        ),
        sortable: false,
      },
      {
        Header: "Поставщик",
        accessor: "contractor",
        Cell: row => `${row.value ? row.value.company_name : ''}`, // Custom cell components!
        id: "contractor",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["contractor.company_name"] }),
        filterAll: true,
        filterable: true,
      },
      {
        Header: "Вес",
        accessor: "weight",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
        id: "weight",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["weight"] }),
        filterAll: true,
        filterable: false,
      },
      {
        Header: "Цена",
        accessor: "price", // String-based value accessors!
        id: "price",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["price"] }),
        filterAll: true,
        filterable: false,
      },
      {
        Header: "Рулоны",
        accessor: "rolls",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
        id: "rolls",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["rolls"] }),
        filterAll: true,
        filterable: false,
      },
      {
        Header: "Удалить",
        filterable: false,
        //accessor: 'product.name',
        maxWidth: 100,
        Cell: row => (
          // <div>
          //     {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >

          //     </Button> */}
          //     <Popconfirm placement="topRight" title="Уверены что хотите удалить эту запись?"
          //         onConfirm={(e) => this.handleDeleteConfirm(row.original, e)}
          //         onCancel={this.handleDeleteCancel} okText="Да" cancelText="Нет">
          //     <Button type="danger" size="small" icon="delete">
          //     </Button>
          //     </Popconfirm>
          // </div>

          <div>
            <Popconfirm
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => this.handleDeleteConfirm(row.original, e)}
              onCancel={this.handleDeleteCancel}
              okText="Да"
              cancelText="Нет"
            >
              <a href="javascript:;">Удалить</a>
            </Popconfirm>
          </div>
        ),
        sortable: false,
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={loading ? [] : fabDepotEntries}
          getTdProps={(state, rowInfo, column) => {
            //console.log(column);
            if (loading) {
              return {
                className: "pt-skeleton",
              };
            } else {
              return {};
            }
          }}
          columns={columns}
          defaultPageSize={50}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText={loading ? "" : "Нет записей"}
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

FabDepotEntryTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(FabDepotEntryTable);
