import { Col, Row } from "antd";
import { toFinite } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";

import {
  fetchWorkingSchedule,
  updateWorkingSchedule,
} from "../../../../data/workingSchedules/actions";
import { getWorkingSchedule } from "../../../../data/workingSchedules/reducer";
import {
  WorkingScheduleType,
  getWorkingScheduleType,
} from "../../helpers/WorkingScheduleTypeHelpers";
import { WorkingScheduleForm } from "../WorkingScheduleForm";

const enhancer = compose(
  connect((state, { match }) => {
    const id = toFinite(match.params.id);

    return { id, data: getWorkingSchedule(state, id) };
  }),
  withRouter,
);

function WorkingScheduleEdit({ id, data, history, dispatch }) {
  const initialType = useMemo(
    () =>
      !data
        ? WorkingScheduleType.CommonWorkingHours
        : getWorkingScheduleType(data),
    [data],
  );

  const [isSaving, setSaving] = useState(false);
  const [type, setType] = useState(initialType);

  useEffect(() => {
    setType(initialType);
  }, [initialType]);

  useEffect(() => {
    dispatch(fetchWorkingSchedule(id));
  }, [id, dispatch]);

  return (
    <div className="mt-paper">
      <div>
        <h2>График работ</h2>
      </div>

      <Row gutter={16}>
        <Col span={12}>
          <WorkingScheduleForm
            id={id}
            type={type}
            key={`${id}-${type}`}
            onTypeChange={setType}
            initialValues={data}
            isSaving={isSaving}
            onSubmit={(values, { setSubmitting }) => {
              setSaving(true);
              Promise.resolve(dispatch(updateWorkingSchedule(id, values)))
                .then(() => {
                  setSaving(false);
                  setSubmitting(false);
                  history.push("/workingschedule");
                })
                .catch(() => {
                  setSaving(false);
                  setSubmitting(false);
                });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default enhancer(WorkingScheduleEdit);
