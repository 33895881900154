import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";

const openedProduct = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_PRODUCT":
      return action.productId;
    default:
      return state;
  }
};

const showPrintCodeModal = (state = false, action) => {
  switch (action.type) {
    case "SET_SHOW_PRINT_CODE_MODAL":
      return action.show;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedProduct,
  showPrintCodeModal,
  data: dataReducer,
});
