import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import React, { useMemo } from "react";
import ReactTable from "react-table";

import { useCallbackProxy } from "../../../../utils/useCallbackProxy";
import {
  formatWorkingSchedule,
  formatWorkingSchedulePeriod,
  formatWorkingScheduleType,
  getWorkingScheduleType,
} from "../../helpers/WorkingScheduleTypeHelpers";

export function WorkingScheduleTable({
  loading,
  onEdit,
  onDelete,
  onWeightChange,
  workingSchedules,

  showID = true,
  showWeight = true,
  showActions = true,
  showPagination = true,
}) {
  const handleEdit = useCallbackProxy(onEdit);
  const handleDelete = useCallbackProxy(onDelete);
  const handleWeightChange = useCallbackProxy(onWeightChange);

  const columns = useMemo(
    () => [
      {
        id: "id",
        show: showID,
        maxWidth: 44,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <Button
            icon={<EditOutlined />}
            size="small"
            type="primary"
            onClick={() => {
              handleEdit(original);
            }}
          />
        ),
      },

      {
        maxWidth: 100,
        show: showWeight,
        sortable: false,
        accessor: "weight",
        Header: "Приоритет",
        Cell: ({ original }) => (
          <>
            <Button
              icon={<CaretUpOutlined />}
              size="small"
              type="default"
              onClick={() => {
                handleWeightChange(original, -1);
              }}
            />{" "}
            <Button
              icon={<CaretDownOutlined />}
              size="small"
              type="danger"
              onClick={() => {
                handleWeightChange(original, +1);
              }}
            />
          </>
        ),
      },

      {
        id: "type",
        sortable: false,
        filterable: false,
        Header: "Тип Записи",
        Cell: ({ original }) =>
          formatWorkingScheduleType(getWorkingScheduleType(original)),
      },

      {
        id: "details",
        sortable: false,
        filterable: false,
        Header: "Детали",
        Cell: ({ original }) => formatWorkingSchedule(original),
      },

      {
        id: "period",
        sortable: false,
        filterable: false,
        Header: "Период",
        Cell: ({ original }) => formatWorkingSchedulePeriod(original),
      },

      {
        id: "actions",
        maxWidth: 44,
        show: showActions,
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <Popconfirm
            okText="Да"
            cancelText="Нет"
            placement="topRight"
            title="Уверены что хотите удалить эту запись?"
            onConfirm={() => {
              handleDelete(original);
            }}
          >
            <Button type="danger" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      },
    ],
    [showID, showWeight, showActions],
  );

  return (
    <ReactTable
      showPagination={showPagination}
      defaultFilterMethod={(filter, row) =>
        String(row[filter.id]) === filter.value
      }
      className="-striped -highlight"
      data={workingSchedules || []}
      getTdProps={() => (!loading ? {} : { className: "pt-skeleton" })}
      columns={columns}
      defaultPageSize={10}
      previousText="Пред"
      nextText="След"
      loadingText="Загрузка..."
      noDataText={loading ? "" : "Нет записей"}
      pageText="Страница"
      ofText="из"
      rowsText="строк"
    />
  );
}
