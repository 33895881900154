import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class EmployeeReportTable extends Component {
  render() {
    const { data, onEditClick } = this.props;
    console.log("DATA: ", data);

    const columns = [
      {
        Header: "",
        accessor: "PR",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/cutting/" + row.original.PR}
          >
            {"PR-" + row.value}
          </a>
        ),
      },
      {
        Header: "Название модели",
        accessor: "name",
      },
      {
        Header: "Операция",
        accessor: "operation",
      },
      {
        Header: "Спецификация",
        accessor: "specification",
      },
      {
        Header: "Количество",
        accessor: "quantity",
        Footer: (
          <span>
            <strong>Всего:</strong>{" "}
            {data.map(elem => elem.quantity).reduce((a, b) => a + b, 0)}
          </span>
        ),
      },
    ];

    return (
      <div>
        <ReactTable
          className="-striped -highlight"
          data={data}
          columns={columns}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText="Нет записей"
          pageText="Страница"
          ofText="из"
          rowsText="строк"
          defaultPageSize={25}
        />
      </div>
    );
  }
}

export default withRouter(EmployeeReportTable);
