import { Button, DatePicker, Input } from "antd";
import { Checkbox, InputNumber, Select } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import {
  createEmployee,
  fetchEmployee,
  updateEmployee,
} from "../../../../data/employees/actions";
import { getEmployee } from "../../../../data/employees/reducer";
import { fetchTeams } from "../../../../data/teams/actions";
import { getAllTeams } from "../../../../data/teams/reducer";
import { setOpenedEmployeeId } from "../../actions";
import FormValidator from "./FormValidator";

const Option = Select.Option;

class EmployeeDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "first_name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите имя",
      },
      {
        field: "hire_date",
        method: "isEmpty",
        validWhen: false,
        message: "Введите дату начала работы",
      },
      {
        field: "last_name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите фамилию",
      },
      {
        field: "middle_name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите отчество",
      },
      {
        field: "salary",
        method: "isEmpty",
        validWhen: false,
        message: "Введите оклад",
      },
      {
        field: "rank",
        method: "isEmpty",
        validWhen: false,
        message: "Разряд",
      },
      {
        field: "team",
        method: () => {
          if (Number.isInteger(this.state.team)) {
            return false;
          } else return true;
        },
        validWhen: false,
        message: "Выберите бригаду",
      },
    ]);

    this.state = {
      isInEditState: false,

      first_name: "",
      last_name: "",
      middle_name: "",
      job_title: "",
      hire_date: "",
      team: "",
      salary: "",
      rank: "",
      isActive: true,
      isSaving: false,
      validation: this.validator.valid(),
    };

    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  mapErrorsToObjectMap = error => {
    let subError = new Object();
    for (var key in error.errors) {
      subError[key] = error.errors[key].message;
    }
    return subError;
  };

  createEmployeeFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var contractor = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      middle_name: this.state.middle_name,
      job_title: this.state.job_title,
      hire_date: this.state.hire_date.format(),
      team_id: this.state.team,
      isActive: this.state.isActive,
      rank: this.state.rank,
      salary: this.state.salary,
    };
    return contractor;
  };

  handleSubmit = () => {
    const { createEmployee, match, updateEmployee } = this.props;

    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
      this.setState({ isSaving: true });
      var employee = this.createEmployeeFromValues();
      //Handle update
      if (match.params.id) {
        return updateEmployee(match.params.id, employee).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/employee/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });
            // let subError=this.mapErrorsToObjectMap(error);
            // throw new SubmissionError(subError)
          },
        );
      }
      //Handle create
      else {
        console.log("yes yes inside else");
        return createEmployee(employee).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/employee/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });
            // let subError=this.mapErrorsToObjectMap(error);
            // throw new SubmissionError(subError)
          },
        );
      }
    }
  };

  componentDidMount() {
    this.fetchEmployeeOrResetForm();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.fetchEmployeeOrResetForm();
    }
  }

  fetchEmployeeOrResetForm = () => {
    const {
      fetchEmployee,
      match,
      setOpenedEmployeeId,
      fetchTeams,
    } = this.props;
    setOpenedEmployeeId("");
    if (match.params.id) {
      fetchEmployee(match.params.id).then(employee => {
        setOpenedEmployeeId(employee.id);
        this.setState({ isInEditState: false });

        this.setState({
          first_name: employee.first_name,
          last_name: employee.last_name,
          hire_date: moment(employee.hire_date),
          middle_name: employee.middle_name,
          job_title: employee.job_title,
          team: employee.team_id,
          rank: employee.rank ? employee.rank.toString() : "",
          salary: employee.salary ? employee.salary.toString() : "",
          isActive: employee.isActive,
        });
      });

      fetchTeams();
    } else {
      this.setState({ isInEditState: true });
      fetchTeams();
    }
  };

  handleFirstNameChange = e => {
    this.setState({ first_name: e.target.value });
  };
  handleLastNameChange = e => {
    this.setState({ last_name: e.target.value });
  };
  handleMiddleNameChange = e => {
    this.setState({ middle_name: e.target.value });
  };
  handleJobTitleChange = e => {
    this.setState({ job_title: e.target.value });
  };

  handleTeamChange = team => {
    this.setState({ team: team });
  };

  handleSalaryChange = salary => {
    if (!salary) {
      this.setState({ salary: "" });
    } else this.setState({ salary });
  };

  handleRankChange = rank => {
    if (!rank) {
      this.setState({ rank: "" });
    } else this.setState({ rank });
  };

  handleHireDateChange = date => {
    this.setState({ hire_date: date });
  };

  onActiveChange = e => {
    this.setState({ isActive: e.target.checked });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    const { history, openedEmployee, match, teams } = this.props;
    let teamOptions = teams.map(team => {
      return (
        <Option key={team.id} value={team.id}>
          {team.name}
        </Option>
      );
    });

    //console.log("OPENED EMPLOYEE", openedEmployee)
    let teamId = "";
    if (openedEmployee) teamId = openedEmployee.team_id;

    return (
      <div className="mt-paper">
        <div>
          <h2>Сотрудник</h2>
        </div>
        {match.params.id && this.state.isInEditState && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Сохранить
          </Button>
        )}
        {match.params.id == undefined && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Создать
          </Button>
        )}
        {match.params.id && !this.state.isInEditState && (
          <Button onClick={this.toggleEditState}>Изменить</Button>
        )}
        <br />
        &nbsp;
        {/* <EmployeeForm teamOptions={teamOptions} enableReinitialize initialValues={{...openedEmployee, team:teamId }} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
        <div
          style={{ marginBottom: 15 }}
          className={validation.last_name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="last_name">
            Фамилия
          </label>
          <Input
            name="last_name"
            placeholder="Фамилия"
            //onChange={this.handleInputChange}
            value={this.state.last_name}
            onChange={this.handleLastNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.last_name.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.first_name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="first_name">
            Имя
          </label>
          <Input
            name="first_name"
            placeholder="Имя"
            //onChange={this.handleInputChange}
            value={this.state.first_name}
            onChange={this.handleFirstNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.first_name.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.middle_name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="middle_name">
            Отчество
          </label>
          <Input
            name="middle_name"
            placeholder="Отчество"
            //onChange={this.handleInputChange}
            value={this.state.middle_name}
            onChange={this.handleMiddleNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.middle_name.message}</span>
        </div>
        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="job_title">
            Должность
          </label>
          <Input
            name="job_title"
            placeholder="Должность"
            //onChange={this.handleInputChange}
            value={this.state.job_title}
            onChange={this.handleJobTitleChange}
            disabled={!this.state.isInEditState}
          />
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.team.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="job_title">
            Бригада
          </label>
          <br />
          <Select
            disabled={!this.state.isInEditState}
            value={this.state.team}
            style={{ width: 300 }}
            onChange={this.handleTeamChange}
          >
            {teamOptions}
          </Select>
          <br />
          <span className="help-block">{validation.team.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.salary.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="salary">
            Оклад
          </label>
          <br />
          <InputNumber
            id={"salary"}
            disabled={!this.state.isInEditState}
            value={this.state.salary}
            style={{ width: 300 }}
            precision={2}
            formatter={value => {
              if (!value) return "";
              let newValue = value;
              if (typeof value === "number") {
                newValue = value.toString();
              }
              return newValue + " сум";
            }}
            onChange={this.handleSalaryChange}
          />
          <br />
          <span className="help-block">{validation.salary.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.rank.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="rank">
            Разряд
          </label>
          <br />
          <InputNumber
            id={"rank"}
            disabled={!this.state.isInEditState}
            value={this.state.rank}
            style={{ width: 300 }}
            precision={0}
            onChange={this.handleRankChange}
          />
          <br />
          <span className="help-block">{validation.rank.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.hire_date.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="job_title">
            Дата начала работы
          </label>
          <br />
          <DatePicker
            locale={locale}
            name="hire_date"
            allowClear={false}
            placeholder="Дата начала работы"
            value={this.state.hire_date}
            onChange={this.handleHireDateChange}
            disabled={!this.state.isInEditState}
          />
          <br />
          <span className={"help-block"}>{validation.hire_date.message}</span>
        </div>
        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="isActive" />
          <Checkbox
            disabled={!this.state.isInEditState}
            checked={this.state.isActive}
            // disabled={this.state.disabled}
            onChange={this.onActiveChange}
          >
            Действующий
          </Checkbox>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    openedEmployee: getEmployee(state, state.scenes.employee.openedEmployeeId),
    teams: getAllTeams(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchEmployee: id => dispatch(fetchEmployee(id)),
    fetchTeams: () => dispatch(fetchTeams()),
    setOpenedEmployeeId: id => dispatch(setOpenedEmployeeId(id)),
    createEmployee: employee => dispatch(createEmployee(employee)),
    updateEmployee: (id, employee) => dispatch(updateEmployee(id, employee)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EmployeeDetail),
);
