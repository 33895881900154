import { Button, Layout, Menu, Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";

import { deleteEmployee, fetchEmployees } from "../../data/employees/actions";
import { getAllEmployees } from "../../data/employees/reducer";
import { fetchTeams } from "../../data/teams/actions";
import EmployeeTable from "./components/EmployeeTable";

class EmployeeMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      searchEnabled: false,
      teamOptions: [],
      showOnly: "active",
    };

    if (this.props.employees.length > 0) {
      this.state = { loading: false };
    }
  }

  componentDidMount() {
    const { fetchEmployees, match, fetchTeams } = this.props;
    this.setState({ showOnly: "active" });
    fetchTeams().then(teams => {
      this.setState({ teamOptions: teams });
    });
    fetchEmployees().then(productions => {
      this.setState({ loading: false });

      // this.interval = setInterval(() =>{

      //     this.toggleLoading();

      // }, 1000);
    });
  }

  render() {
    const { history, employees } = this.props;

    const onEmployeeCreate = () => {
      history.push(`/employee/new`);
    };

    const handleEditClick = contractor => {
      //console.log(id);
      history.push(`/employee/` + contractor.id);
    };

    const onRemove = value => {
      const { deleteEmployee } = this.props;
      deleteEmployee(value.id);
      //history.push(`/product-admin/`)
    };

    return (
      <div>
        <div>
          <h2>Сотрудники</h2>
          <span>
            <Button type="primary" onClick={onEmployeeCreate}>
              Создать
            </Button>
            <span style={{ float: "right" }}>
              <Select
                value={this.state.showOnly}
                style={{ width: "150px" }}
                onChange={value => this.setState({ showOnly: value })}
              >
                <Select.Option value={"active"} key={1}>
                  Активные
                </Select.Option>
                <Select.Option value={"non-active"} key={2}>
                  Неактивные
                </Select.Option>
              </Select>
            </span>
          </span>
        </div>
        <br />
        <EmployeeTable
          teamOptions={this.state.teamOptions}
          loading={this.state.loading}
          domainSettings={this.props.domainSettings}
          onEditClick={handleEditClick}
          onRemove={onRemove}
          employees={employees.filter(employee => {
            if (this.state.showOnly === "active") {
              return employee.isActive;
            } else if (this.state.showOnly === "non-active") {
              return !employee.isActive;
            }
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    domainSettings: state.domainSettings,
    employees: getAllEmployees(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchEmployees: () => dispatch(fetchEmployees()),
    fetchTeams: () => dispatch(fetchTeams()),
    deleteEmployee: id => dispatch(deleteEmployee(id)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EmployeeMain),
);

//export default ContractorMain
