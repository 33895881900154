import { DatePicker, Select } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchPeriods } from "../../data/periods/actions";
import { getAllPeriods } from "../../data/periods/reducer";
import {
  selectPartition,
  selectPeriod,
  selectRange,
  setMinMax,
} from "./actions";
import BarComponent from "./components/BarComponent";
import { fetchEntries, fetchReports } from "./data/actions";
import { getProductionReport } from "./data/selectors";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class StatisticsMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchPeriods().then(data => {
      let n = data.length - 1;
      if (n >= 0) {
        let tmp = data[n].id;
        this.props.fetchEntries(data[n].id).then(result => {
          const moments = result.cuttingEntries
            .map(elem => moment(elem.cut_date))
            .concat(result.prodEntries.map(elem => moment(elem.entryDate)));

          let minDate = null;
          let maxDate = null;

          if (moments.length === 0) {
            minDate = moment().startOf("day");
            maxDate = moment().startOf("day");
          } else {
            minDate = moment.min(moments).startOf("day");
            maxDate = moment.max(moments).startOf("day");
          }

          let from = maxDate.clone().subtract(6, "days");
          from = moment.max(from, minDate);

          const range = [from, maxDate];
          this.props.setMinMaxDates([minDate, maxDate]);
          this.props.selectRange(range);
          this.props.selectPeriod(tmp);
          this.props.fetchReports(
            tmp,
            [range[0].format("YYYY-MM-DD"), range[1].format("YYYY-MM-DD")],
            this.props.partition,
          );
        });
      } else {
        const range = [moment().startOf("day"), moment().startOf("day")];
        this.props.selectRange(range);
        this.props.setMinMaxDates(range);
      }
    });
  }

  render() {
    const x = this.props.periods
      .filter(x => x.id === this.props.periodId)
      .map(x => x.id);
    let dv = Object.values(x);
    return (
      <div>
        <Select
          notFoundContent={"Нет периодов"}
          value={dv.length === 0 ? "Нет периодов" : dv[0]}
          style={{ width: 150 }}
          onSelect={period_id => {
            this.props.fetchEntries(period_id).then(result => {
              const moments = result.cuttingEntries
                .map(elem => moment(elem.cut_date))
                .concat(result.prodEntries.map(elem => moment(elem.entryDate)));

              let minDate = null;
              let maxDate = null;

              if (moments.length === 0) {
                minDate = moment().startOf("day");
                maxDate = moment().startOf("day");
              } else {
                minDate = moment.min(moments).startOf("day");
                maxDate = moment.max(moments).startOf("day");
              }

              let from = maxDate.clone().subtract(6, "days");
              from = moment.max(from, minDate);

              const range = [from, maxDate];
              this.props.setMinMaxDates([minDate, maxDate]);
              this.props.selectRange(range);
              this.props.selectPeriod(period_id);
              this.props.fetchReports(
                period_id,
                [range[0].format("YYYY-MM-DD"), range[1].format("YYYY-MM-DD")],
                this.props.partition,
              );
            });
          }}
        >
          {this.props.periods.map(period => (
            <Option key={period.id} value={period.id}>
              {period.name}
            </Option>
          ))}
        </Select>
        <RangePicker
          allowClear={false}
          disabledDate={current =>
            this.props.minMaxDates[0] > current ||
            current > this.props.minMaxDates[1]
          }
          value={this.props.range}
          style={{ marginLeft: "2em" }}
          onChange={range => {
            if (dv.length !== 0) {
              if (dv.length !== 0) {
                this.props.selectRange([range[0], range[1]]);
                this.props.fetchReports(
                  this.props.periodId,
                  [
                    range[0].format("YYYY-MM-DD"),
                    range[1].format("YYYY-MM-DD"),
                  ],
                  this.props.partition,
                );
              }
            }
          }}
        />
        <Select
          value={this.props.partition}
          style={{ width: 150, marginLeft: "2em" }}
          onSelect={partition => {
            if (dv.length !== 0) {
              const range = [
                this.props.range[0].format("YYYY-MM-DD"),
                this.props.range[1].format("YYYY-MM-DD"),
              ];
              this.props.selectPartition(partition);
              this.props.fetchReports(this.props.periodId, range, partition);
            }
          }}
        >
          <Option value="days">По дням</Option>
          <Option value="weeks">По неделям</Option>
        </Select>
        <br /> <br /> <br />
        <BarComponent data={this.props.data} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getProductionReport(state),
    range: state.scenes.productionReport.range,
    periodId: state.scenes.productionReport.period_id,
    partition: state.scenes.productionReport.partition,
    periods: getAllPeriods(state),
    minMaxDates: state.scenes.productionReport.minMaxDates,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchReports: (period_id, range, partition) =>
      dispatch(fetchReports(period_id, range, partition)),
    fetchPeriods: () => dispatch(fetchPeriods()),
    fetchEntries: period_id => dispatch(fetchEntries(period_id)),
    selectPartition: partition => dispatch(selectPartition(partition)),
    selectPeriod: period_id => dispatch(selectPeriod(period_id)),
    selectRange: range => dispatch(selectRange(range)),
    setMinMaxDates: dates => dispatch(setMinMax(dates)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StatisticsMain),
);
