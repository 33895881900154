import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import WageScan from "./index.js";

///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class WageRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/wagescan" component={WageScan} />
          {/* <Route exact path={`${this.props.match.url}/new`} component={TeamDetail} />
          <Route exact path={`${this.props.match.url}/:id`} component={TeamDetail} /> */}
        </Switch>
      </div>
    );
  }
}

export default WageRoute;
