import { notification } from "antd";
import axios from "axios";
import { serialize } from "helpers/utils";

export const EMPLOYEE_REPORT_FETCH_SUCCESS = "EMPLOYEE_REPORT_FETCH_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchEmployeeReport = (period_id, employee_id) => {
  return dispatch => {
    return axios
      .get(
        `/api/production_statistics/employee_report/report?${serialize({
          period_id,
          employee_id,
        })}`,
      )
      .then(({ data }) => {
        dispatch({
          type: EMPLOYEE_REPORT_FETCH_SUCCESS,
          response: data,
        });
        return data;
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
