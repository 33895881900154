import produce from "immer";
import { toFinite } from "lodash";
import { combineReducers } from "redux";

import {
  ATTENDANCE_REPORTS_FETCH_SUCCESS,
  ATTENDANCE_REPORT_CREATE_SUCCESS,
  ATTENDANCE_REPORT_DELETE_SUCCESS,
  ATTENDANCE_REPORT_FETCH_SUCCESS,
  ATTENDANCE_REPORT_SET_CURRENT_PAGE,
  ATTENDANCE_REPORT_SET_PAGE_SIZE,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case ATTENDANCE_REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.attendanceReports,
      };
    case ATTENDANCE_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.attendanceReports,
      };
    case ATTENDANCE_REPORT_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.attendanceReports,
      };
    case ATTENDANCE_REPORT_DELETE_SUCCESS:
      return Object.keys(state).reduce(
        (acc, key) => {
          if (action.id === toFinite(key)) {
            delete acc[key];
          }

          return acc;
        },
        { ...state },
      );
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_REPORTS_FETCH_SUCCESS:
      return action.response.result;
    case ATTENDANCE_REPORT_CREATE_SUCCESS:
      return [...state, action.response.result];
    case ATTENDANCE_REPORT_DELETE_SUCCESS:
      return state.filter(x => x !== action.id);
    default:
      return state;
  }
};

const page = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case ATTENDANCE_REPORTS_FETCH_SUCCESS:
        draft[action.page] = action.response.result;
        return;

      case ATTENDANCE_REPORT_DELETE_SUCCESS:
        for (let key of draft) {
          // check if the property/key is defined in the object itself, not in parent
          if (draft.hasOwnProperty(key)) {
            let foundId = draft[key].find(id => id == action.orderId);
            if (foundId) draft[key].splice(draft[key].indexOf(foundId), 1);
          }
        }
        return;

      default:
        return;
    }
  });

const pageSize = (state = 50, action) => {
  switch (action.type) {
    case ATTENDANCE_REPORT_SET_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
};

const currentPage = (state = 0, action) => {
  switch (action.type) {
    case ATTENDANCE_REPORT_SET_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
  page,
  pageSize,
  currentPage,
});

export const getAllAttendanceReports = state => {
  return state.data.attendanceReports.allIds
    .map(id => state.data.attendanceReports.byId[id])
    .sort((a, b) => a.weight - b.weight);
};

export const getAttendanceReport = (state, id) => {
  return state.data.attendanceReports.byId[id];
};
