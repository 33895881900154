import logTypesByGroup from "@app/shared/constants/logTypesByGroup";
import { DatePicker, Select, Tag, notification } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchLogs } from "../../data/logs/actions";
import LogTable from "./components/LogTable";

const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

function Log(props) {
  const { fetchLogs, logs, match, history, pagesCount } = props;
  const logGroups = [
    {
      label: "Все",
      value: "all",
    },
    {
      label: "Перемещение материала",
      value: "fabStockMovement",
    },
    {
      label: "Приход материалов",
      value: "fabDepotEntry",
    },
    {
      label: "Ревизия материалов",
      value: "fabStockRevision",
    },
    {
      label: "Перемещение продукции",
      value: "prodStockMovement",
    },
    {
      label: "Приход продукции",
      value: "prodDepotEntry",
    },
    {
      label: "Ревизия продукции",
      value: "prodStockRevision",
    },
    {
      label: "Платежи [Продажи]",
      value: "payment",
    },
    {
      label: "Отгрузки",
      value: "shipment",
    },
    {
      label: "Модели",
      value: "product",
    },
    {
      label: "Платежи [Подрядчики]",
      value: "contractorPayment",
    },
    {
      label: "Заказы",
      value: "order",
    },
  ];

  const filter = match.params.filter
    ? logGroups.map(logGroup => logGroup.value).includes(match.params.filter)
      ? match.params.filter
      : false
    : false;

  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState([moment().subtract(7, "days"), moment()]);
  const [selectedLogGroup, setSelectedLogGroup] = useState(
    filter ? filter : "all",
  );
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setLoading(true);
    fetchLogs(
      range && range.length !== 0 ? range[0].format("YYYY-MM-DD HH:mm ZZ") : undefined,
      range && range.length !== 0 ? range[1].format("YYYY-MM-DD HH:mm ZZ") : undefined,
      pageIndex + 1,
      selectedLogGroup,
    ).then(
      () => {
        setLoading(false);
      },
      () => setLoading(false),
    );
  }, [range, pageIndex, selectedLogGroup]);

  return (
    <div>
      <div>
        <h2>Журнал изменений</h2>
        <span>
          <RangePicker
            value={range}
            onChange={e => {
              setPageIndex(0);
              setRange(e);
            }}
            locale={locale}
          />
          &nbsp; &nbsp;
          <Select
            showArrow
            style={{
              width: "500px",
            }}
            value={selectedLogGroup}
            onChange={value => {
              setSelectedLogGroup(value);
              setPageIndex(0);
              history.push("/logs/" + value);
            }}
          >
            {logGroups.map((logGroup, id) => (
              <Option key={id} value={logGroup.value}>
                {logGroup.label}
              </Option>
            ))}
          </Select>
        </span>
      </div>
      <br />
      <LogTable
        loading={loading}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pagesCount={pagesCount}
        logs={logs.filter(log => {
          if (selectedLogGroup === "all") return true;
          else
            return logTypesByGroup[selectedLogGroup].includes(log.logType_id);
        })}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    logs: state.data.logs.rows,
    pagesCount: state.data.logs.pagesCount,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLogs: (fromDate, toDate, pageIndex, logTypeGroup) =>
      dispatch(fetchLogs(fromDate, toDate, pageIndex, logTypeGroup)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Log),
);
