import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class ContractorTable extends Component {
  confirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  cancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  render() {
    const { contractors, onEditClick, showBalance, classes } = this.props;
    let columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 60,
        Cell: row => (
          <div>
            <Button
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={e => onEditClick(row.original, e)}
            >
              {/* <OpenInNew classes={{ root: classes.iconRoot }} />
                        <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
            </Button>
          </div>
        ),
      },
      {
        Header: "Название компании",
        accessor: "company_name", // String-based value accessors!
      },
      {
        Header: "Адрес",
        accessor: "address",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Контактное лицо",
        accessor: "contact_name",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Удалить",
        //accessor: 'product.name',
        maxWidth: 100,
        Cell: row => (
          <div>
            {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >
                      
                    </Button> */}
            <Popconfirm
              placement="topRight"
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => this.confirm(row.original, e)}
              onCancel={this.cancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="danger" size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
      },
    ];

    if (showBalance) {
      columns.splice(3, 0, {
        Header: "Текущий долг",
        accessor: "currentDebt",
        key: "balance",
        filterable: true,
        Cell: row => {
          return (
              <div
                  style={{
                    color: row.value >= -0.001 ? "green" : "red",
                    textAlign: "center",
                    width: "100%",
                  }}
              >
                {Number(row.value).toFixed(2)}$
              </div>
          );
        },
        Filter: () => {
          const totalBalance = contractors.reduce(
              (acc, curr) => acc + curr.currentDebt,
              0,
          );
          return (
              <span style={{ color: totalBalance >= -0.001 ? "green" : "red" }}>
            {Number(totalBalance).toFixed(2)}
          </span>
          );
        },
      });
    }

    return (
      <div>
        <ReactTable
          className="-striped -highlight"
          data={contractors}
          columns={columns}
        />
      </div>
    );
  }
}

ContractorTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(ContractorTable);
