import React from "react";
import { Route, Switch } from "react-router-dom";

import WorkingScheduleAdd from "./components/WorkingScheduleAdd";
import WorkingScheduleEdit from "./components/WorkingScheduleEdit";
import Main from "./index";

export default function WorkingScheduleRoute({ match: { url } }) {
  return (
    <Switch>
      <Route exact path={url} component={Main} />
      <Route exact path={`${url}/add/:type?`} component={WorkingScheduleAdd} />
      <Route exact path={`${url}/:id`} component={WorkingScheduleEdit} />
    </Switch>
  );
}
