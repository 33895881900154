//import {getOpenedSpec} from '../../selectors'

import { PlusOutlined } from '@ant-design/icons';

import { Alert, Button, Input } from "antd";
import { Select, message } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import {
  setOpenedBom,
  setOpenedPattern,
  setSelectedSpreadTab,
  setShowBomModal,
  setShowPatternModal,
  setShowSpreadModal,
  setSpecName,
} from "./actions";
import BomCard from "./components/BomCard";
import BomDetail from "./components/BomDetail";
import PatternDetail from "./components/PatternDetail";
import SpreadDetail from "./components/SpreadDetail";
import { addBom, deleteBom } from "./data/boms/actions";
import { addPattern } from "./data/patterns/actions";
import { addSpread } from "./data/spreads/actions";
import FormValidator from "./FormValidator";
import { getFormBoms, getSpecModelFromState } from "./selectors";

const Option = Select.Option;

class SpecDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: () => {
          if (this.props.specName.length == 0) {
            return true;
          } else return false;
        },
        validWhen: false,
        message: "Введите имя",
      },
      {
        field: "name",
        method: () => {
          return this.state.nameExists;
          // return true
        },
        validWhen: false,
        message: "Такое имя уже есть",
      },
      {
        field: "partsField",
        method: () => {
          if (this.props.boms.length == 0) {
            return true;
          } else return false;
        },
        validWhen: false,
        message: "Добавьте хотябы одну часть",
      },
    ]);

    this.state = {
      isInEditState: false,

      partsField: "",
      isPastingBom: false,
      name: "",
      nameExists: false,
      validation: this.validator.valid(),
    };

    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  clearForm = () => {
    const { setSpecName, addPattern, boms, deleteBom } = this.props;
    boms.forEach(bom => {
      deleteBom(bom.id);
    });
    setSpecName("");
  };

  createSpecFromValues = () => {
    const { specModel } = this.props;
    return specModel;
  };

  initializeSpecForm = spec => {
    const { setSpecName, addBom, addSpread, addPattern } = this.props;

    setSpecName(spec.name);

    spec.boms.forEach(bom => {
      let bomAction = addBom(bom);

      bom.spreads.forEach(spread => {
        let spreadAction = addSpread(spread, bomAction.bomId);
        //setSelectedSpreadTab(bomAction.bomId, spreadAction.id)
        spread.patterns.forEach(pattern => {
          addPattern(pattern, spreadAction.spreadId);
        });
      });
    });
  };

  setNameExists = nameExists => {
    this.setState({ nameExists: nameExists });
  };

  validate = callback => {
    const { createSpec, match, updateSpec } = this.props;

    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    var spec = this.createSpecFromValues();
    if (validation.isValid) {
      //Handle update
      callback(null, spec);
    } else {
      callback("error validating form", spec);
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  handleNameChange = e => {
    const { setSpecName } = this.props;
    //this.setState({ name: e.target.value });
    this.setState({ nameExists: false });
    setSpecName(e.target.value);
  };

  handleAddBom = () => {
    this.props.setOpenedBom("");
    this.props.setShowBomModal(true);
  };

  handlePasteBom = () => {
    const { addBom, addSpread, addPattern } = this.props;

    this.setState({ isPastingBom: true });
    setTimeout(() => this.setState({ isPastingBom: false }), 1000);
    const bomBuffer = this.props.bomBuffer;
    let bomAction = addBom(bomBuffer.bom);

    bomBuffer.bom.spreads.forEach(spreadId => {
      const spread = bomBuffer.spreads[spreadId];
      let spreadAction = addSpread(spread, bomAction.bomId);
      spread.patterns.forEach(patternId => {
        const pattern = bomBuffer.patterns[patternId];
        addPattern(pattern, spreadAction.spreadId);
      });
    });

    message.success("Часть вставлена");
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    const {
      history,
      openedEmployee,
      match,
      boms,
      specName,
      disabled,
    } = this.props;

    boms.sort((bomA, bomB) => (bomA.sortOrderId > bomB.sortOrderId ? 1 : -1));
    console.log(boms);

    return (
      <div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="name">
            Имя
          </label>
          <Input
            name="name"
            placeholder="Имя"
            //onChange={this.handleInputChange}
            value={specName}
            onChange={this.handleNameChange}
            disabled={disabled}
          />
          <span className="help-block">{validation.name.message}</span>
        </div>
        <Button disabled={disabled} icon={<PlusOutlined />} onClick={this.handleAddBom}>
          Добавить часть
        </Button>
        &nbsp; &nbsp;
        {this.props.bomBuffer && (
          <Button
            onClick={this.handlePasteBom}
            loading={this.state.isPastingBom}
            disabled={disabled}
          >
            Вставить часть
          </Button>
        )}
        <br /> <br />
        {boms.map(bom => (
          <BomCard
            disabled={disabled}
            key={bom.id}
            bom={bom}
            numberOfBoms={boms.length}
          />
        ))}
        {validation.partsField.isInvalid && (
          <Alert
            message="Ошибка"
            description={validation.partsField.message}
            type="error"
            closable
          />
        )}
        <BomDetail disabled={disabled} />
        <PatternDetail />
        <SpreadDetail />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //formBoms: state.scenes.specification.formBoms
    boms: getFormBoms(state),
    specName: state.scenes.forms.specform.specName,
    bomBuffer: state.data.tempData.bomBuffer,
    specModel: getSpecModelFromState(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setSpecName: name => dispatch(setSpecName(name)),

    addBom: bom => dispatch(addBom(bom)),
    deleteBom: bomId => dispatch(deleteBom(bomId)),
    addSpread: (spread, bomId) => dispatch(addSpread(spread, bomId)),
    addPattern: (pattern, spreadId) => dispatch(addPattern(pattern, spreadId)),

    setSelectedSpreadTab: (bomId, spreadId) =>
      dispatch(setSelectedSpreadTab(bomId, spreadId)),

    setOpenedBom: bomId => dispatch(setOpenedBom(bomId)),
    setOpenedPattern: spreadId => dispatch(setOpenedPattern(spreadId)),
    setShowSpreadModal: showModal => dispatch(setShowSpreadModal(showModal)),
    setShowBomModal: showModal => dispatch(setShowBomModal(showModal)),
    setShowPatternModal: showModal => dispatch(setShowPatternModal(showModal)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(SpecDetail);
