import { Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";

function ClientTable(props) {
  const { isFetchingClients, clients, history, onDelete, showBalance } = props;

  const columns = [
    {
      Header: "",
      accessor: "id",
      maxWidth: 100,
      filterable: false,
      key: "id",
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            history.push("/client/" + row.value);
          }}
        >
          {"CL-" + row.value}
        </a>
      ),
    },
    {
      Header: "Название",
      accessor: "name",
      key: "name",
      filterable: false,
    },
    {
      Header: "Имя контакта",
      accessor: "nameOfRepresentative",
      key: "nameOfRepresentative",
      filterable: false,
    },
    {
      Header: "Адрес",
      accessor: "address",
      key: "address",
      filterable: false,
    },
    {
      Header: "Номер телефона",
      accessor: "phoneNumber",
      key: "phoneNumber",
    },
    {
      Header: "",
      accessor: "id",
      key: "delete",
      Cell: row => (
        <Popconfirm
          title={"Вы уверены, что хотите удалить эту запись?"}
          onConfirm={() => onDelete(row.value)}
          okText={"Да"}
          cancelText={"Отменить"}
        >
          <a>Удалить</a>
        </Popconfirm>
      ),
    },
  ];

  if (showBalance) {
    columns.splice(3, 0, {
      Header: "Текущий баланс",
      accessor: "currentBalance",
      key: "balance",
      filterable: true,
      Cell: row => {
        return (
          <div
            style={{
              color: row.value >= -0.001 ? "green" : "red",
              textAlign: "center",
              width: "100%",
            }}
          >
            {Number(row.value).toFixed(2)}$
          </div>
        );
      },
      Filter: () => {
        const totalBalance = clients.reduce(
          (acc, curr) => acc + curr.currentBalance,
          0,
        );
        return (
          <span style={{ color: totalBalance >= -0.001 ? "green" : "red" }}>
            {Number(totalBalance).toFixed(2)}
          </span>
        );
      },
    });
  }

  return (
    <div>
      <ReactTable
        columns={columns}
        dataSource={clients}
        filterable={false}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        className="-striped -highlight"
        data={clients}
        getTdProps={() => {
          if (isFetchingClients) {
            return {
              className: "pt-skeleton",
            };
          } else {
            return {};
          }
        }}
        defaultPageSize={50}
        previousText="Пред"
        nextText="След"
        loadingText="Загрузка..."
        noDataText={isFetchingClients ? "" : "Нет записей"}
        pageText="Страница"
        ofText="из"
        rowsText="строк"
      />
    </div>
  );
}

export default ClientTable;
