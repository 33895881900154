import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import matchSorter from "match-sorter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class SpecTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  render() {
    const { specs, onEditClick, onRemove, classes } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 60,
        filterable: false,
        Cell: row => (
          <div>
            <Button
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={e => onEditClick(row.original, e)}
            >
              {/* <OpenInNew classes={{ root: classes.iconRoot }} />
                        <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
            </Button>
          </div>
        ),
      },
      {
        Header: "Имя",
        accessor: "name", // String-based value accessors!
        id: "name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
      },
      {
        Header: "Части",
        accessor: "boms",
        filterable: false,
        Cell: props => (
          <span className="text">
            {props.original.boms.map(bom => bom.name).join()}
          </span>
        ), // Custom cell components!
        id: "boms",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["boms"] }),
        filterAll: true,
      },
      {
        Header: "Удалить",
        filterable: false,
        //accessor: 'product.name',
        maxWidth: 100,
        Cell: row => (
          <div>
            {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >
                      
                    </Button> */}
            <Popconfirm
              placement="topRight"
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => this.handleDeleteConfirm(row.original, e)}
              onCancel={this.handleDeleteCancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="danger" size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={specs}
          columns={columns}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText="Нет записей"
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

SpecTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(SpecTable);
