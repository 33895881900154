import { Button, Popconfirm, Select } from "antd";
import ReactTable from "components/ReactTable";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";

const Option = Select.Option;

class FabStockRevisionTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  render() {
    const {
      fabStockRevisions,
      onEditClick,
      onRemove,
      classes,
      loading,
      depotLocationOptions,
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          // <div>
          //     <Button type="primary" size="small" icon="edit" onClick={(e => onEditClick(row.original, e))}  >
          //         {/* <OpenInNew classes={{ root: classes.iconRoot }} />
          //         <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
          //     </Button>
          // </div>

          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/fabstock/" + row.original.id}
          >
            {"FSR-" + row.value}
          </a>
        ),
      },
      {
        Header: "Дата",
        accessor: "entry_date", // String-based value accessors!,
        Cell: row =>
          row.value && (
            <span className="number">
              {new moment(row.value).format("DD/MM/YYYY")}
            </span>
          ),
        filterable: false,
        sortable: false,
      },
      {
        Header: "Материал",
        accessor: "fabric.name",
        id: "fabricName",
        Cell: row => <span className="number">{row.value}</span>,
        filterMethod: (filter, rows) => {
          //console.log("filter",rows)
          return matchSorter(rows, filter.value, { keys: ["fabricName"] });
        },
        filterAll: true,
        // Custom cell components!
      },
      {
        Header: "Склад",
        accessor: "depotLocation.name",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
        //id:"team.name",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <Select
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={value => onChange(value)}
          >
            <Option key={"all"} value="all">
              Все
            </Option>
            {depotLocationOptions &&
              depotLocationOptions.map(depot => (
                <Option key={depot.name} value={depot.name}>
                  {depot.name}
                </Option>
              ))}
          </Select>
        ),
        sortable: false,
      },

      {
        Header: "Вес",
        accessor: "weight",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
        id: "weight",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["weight"] }),
        filterAll: true,
        filterable: false,
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={fabStockRevisions}
          isLoading={loading}
          columns={columns}
          defaultPageSize={50}
        />
      </div>
    );
  }
}

FabStockRevisionTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(FabStockRevisionTable);
