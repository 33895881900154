import { TimePicker } from "antd";
import moment from "moment";
import React, { useMemo } from "react";

import { FormField } from "./FormField";

function FormTimePickerInternal({
  name,
  value,
  onChange,
  defaultOpenValue,
  placeholder = "Время",
  ...props
}) {
  const handleChange = useMemo(() => onChange(name), [name, onChange]);
  const valueMoment = useMemo(
    () => (!value ? null : moment(value, "HH:mm:ss")),
    [value],
  );
  const defaultOpenValueMoment = useMemo(
    () => (!defaultOpenValue ? null : moment(defaultOpenValue, "HH:mm")),
    [defaultOpenValue],
  );

  return (
    <TimePicker
      {...props}
      name={name}
      format="HH:mm"
      value={valueMoment}
      placeholder={placeholder}
      defaultOpenValue={defaultOpenValueMoment}
      onChange={(time, timeString) => handleChange(timeString || null)}
    />
  );
}

export function FormTimePicker({ name, label, disabled, ...props }) {
  return (
    <FormField
      name={name}
      label={label}
      render={({ field, form: { isSubmitting } }) => (
        <>
          <br />

          <FormTimePickerInternal
            {...props}
            {...field}
            disabled={disabled || isSubmitting}
          />

          <br />
        </>
      )}
    />
  );
}
