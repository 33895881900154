import { Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class EmployeeTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  render() {
    const { employees, onEditClick, onRemove, classes } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "row", // String-based value accessors!
        maxWidth: 40,
        Cell: props => <span className="text">{props.value}</span>,
      },
      {
        Header: "Имя",
        accessor: "first_name", // String-based value accessors!
        Cell: props => (
          <span className="text">
            {props.original.first_name + " " + props.original.last_name}
          </span>
        ),
      },
      {
        Header: "Сумма",
        accessor: "wageSum",
        maxWidth: 120,
        Cell: props =>
          props.original.wageSum == null ? (
            <span className="text">0</span>
          ) : (
            <span className="text">{props.value}</span>
          ),
      },
    ];

    return (
      <div>
        <ReactTable
          className="-striped -highlight"
          data={employees}
          columns={columns}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText=""
          pageText="Страница"
          ofText="из"
          rowsText="строк"
          defaultPageSize={23}
          showPageSizeOptions={false}
          showPaginationBottom={false}
        />
      </div>
    );
  }
}

EmployeeTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(EmployeeTable);
