import AttendanceReportType from "@app/shared/constants/AttendanceReportType";
import { isValidAttendanceReportType } from "@app/shared/utils/AttendanceReportUtils";
import React, { useState } from "react";
import { connect } from "react-redux";

import { createAttendanceReport } from "../../../../data/attendanceReports/actions";
import { AttendanceReportByDateRangeForm } from "../AttendanceReportByDateRangeForm";

const enhancer = connect();

function AttendanceReportByDateRangeAdd({
  history,
  dispatch,
  match: { params },
}) {
  const type = isValidAttendanceReportType(params.type)
    ? params.type
    : AttendanceReportType.AttendanceReportByDateRange;

  const [isSaving, setSaving] = useState(false);

  return (
    <div className="mt-paper">
      <div>
        <h2>Отчет по посещаемости</h2>
      </div>

      <AttendanceReportByDateRangeForm
        key={type}
        type={type}
        onTypeChange={nextType =>
          history.push(`/attendance-report/add/${nextType}`)
        }
        isSaving={isSaving}
        onSubmit={(values, { setSubmitting }) => {
          setSaving(true);
          Promise.resolve(
            dispatch(
              createAttendanceReport({
                ...values,
                working_schedule: values.working_schedule.map((x, weight) => ({
                  ...x,
                  weight,
                })),
              }),
            ),
          )
            .then(() => {
              setSubmitting(false);
              setSaving(false);
              history.push("/attendance-report");
            })
            .catch(() => {
              setSaving(false);
              setSubmitting(false);
            });
        }}
      />
    </div>
  );
}

export default enhancer(AttendanceReportByDateRangeAdd);
