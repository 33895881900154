import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import FabricDetail from "./components/FabricDetail";
import FabricMain from "./index";

class FabricRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/fabric-admin" component={FabricMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={FabricDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={FabricDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default FabricRoute;
