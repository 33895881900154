import { combineReducers } from "redux";

import {
  FABRICS_FETCH_SUCCESS,
  FABRIC_CREATE_SUCCESS,
  FABRIC_DELETE_SUCCESS,
  FABRIC_FETCH_SUCCESS,
  FABRIC_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case FABRICS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabrics,
      };
    case FABRIC_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabrics,
      };
    case FABRIC_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabrics,
      };
    case FABRIC_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabrics,
      };
    case FABRIC_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.fabricId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FABRICS_FETCH_SUCCESS:
      return action.response.result;
    case FABRIC_CREATE_SUCCESS:
      return [...state, action.response.result];
    case FABRIC_DELETE_SUCCESS:
      return [...state.filter(id => id != action.fabricId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllFabrics = state => {
  return state.scenes.fabric.data.fabrics.allIds.map(
    id => state.scenes.fabric.data.fabrics.byId[id],
  );
};

export const getFabric = (state, id) => {
  return state.scenes.fabric.data.fabrics.byId[id];
};
