import { combineReducers } from "redux";

import { EMPLOYEES_PAYROLL_FETCH_SUCCESS } from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEES_PAYROLL_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.employees,
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case EMPLOYEES_PAYROLL_FETCH_SUCCESS:
      return action.response.result;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllEmployees = state => {
  return state.data.employeesPayroll.allIds.map(
    id => state.data.employeesPayroll.byId[id],
  );
};

export const getAllEmployeesWithRowNum = state => {
  //let employees=[];

  return state.data.employeesPayroll.allIds.map((id, index) => {
    return { row: index + 1, ...state.data.employeesPayroll.byId[id] };
  });
};

export const getEmployee = (state, id) => {
  return state.data.employeesPayroll.byId[id];
};
