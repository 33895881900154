import { combineReducers } from "redux";

const openedOrder = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_ORDER":
      return action.orderId;
    default:
      return state;
  }
};

const showCompletionModal = (state = false, action) => {
  switch (action.type) {
    case "SHOW_COMPLETION_MODAL":
      return action.showCompletionModal;
    default:
      return state;
  }
};

const orderIdForCompletionModal = (state = null, action) => {
  switch (action.type) {
    case "SET_ORDER_ID_FOR_COMPLETION_MODAL":
      return action.orderId;
    default:
      return state;
  }
};

export const getOrderForCompletionModal = state => {
  if (state.scenes.production.orderIdForCompletionModal === null) return null;
  else {
    const currentOrder =
      state.data.orders.byId[state.scenes.production.orderIdForCompletionModal];
    return {
      ...currentOrder,
      prodBoms: currentOrder.prodBoms.map(
        prodBom => state.data.prodBoms.byId[prodBom],
      ),
    };
  }
};

export const getOpenedOrder = state => {
  return state.scenes.production.openedOrder;
};

export const reducer = combineReducers({
  openedOrder,
  showCompletionModal,
  orderIdForCompletionModal,
});
