"use strict";

const {
  APPROVED_FABSTOCK_MOVEMENT_REQUEST,
  DECLINED_FABSTOCK_MOVEMENT_REQUEST,
  DELETED_FAB_DEPOT_ENTRY,
  DELETED_PROD_DEPOT_ENTRY,
  NEW_FAB_DEPOT_ENTRY,
  NEW_FABSTOCK_MOVEMENT_REQUEST,
  NEW_FABSTOCK_REVISION,
  NEW_PROD_DEPOT_ENTRY,
  NEW_PRODSTOCK_MOVEMENT,
  NEW_PRODSTOCK_REVISION,
  UPDATED_FAB_DEPOT_ENTRY,
  UPDATED_PROD_DEPOT_ENTRY,
  UPDATED_FABSTOCK_MOVEMENT_REQUEST,
  NEW_PAYMENT_ENTRY,
  NEW_SHIPMENT_ENTRY,
  UPDATED_PAYMENT_ENTRY,
  DELETED_SHIPMENT_ENTRY,
  DELETED_PAYMENT_ENTRY,
  UPDATED_SHIPMENT_ENTRY,
  UPDATED_PRODUCT,
  DELETED_PRODUCT,
  NEW_PRODUCT,
  DELETED_CONTRACTOR_PAYMENT_ENTRY,
  UPDATED_CONTRACTOR_PAYMENT_ENTRY,
  NEW_CONTRACTOR_PAYMENT_ENTRY,
  DELETED_ORDER_ENTRY,
  NEW_ORDER_ENTRY,
  UPDATED_ORDER_STATUS
} = require("./logType");

module.exports = {
  fabStockMovement: [
    APPROVED_FABSTOCK_MOVEMENT_REQUEST,
    DECLINED_FABSTOCK_MOVEMENT_REQUEST,
    NEW_FABSTOCK_MOVEMENT_REQUEST,
    UPDATED_FABSTOCK_MOVEMENT_REQUEST,
  ],
  fabDepotEntry: [
    NEW_FAB_DEPOT_ENTRY,
    UPDATED_FAB_DEPOT_ENTRY,
    DELETED_FAB_DEPOT_ENTRY,
  ],
  fabStockRevision: [NEW_FABSTOCK_REVISION],
  prodStockMovement: [NEW_PRODSTOCK_MOVEMENT],
  prodDepotEntry: [
    NEW_PROD_DEPOT_ENTRY,
    UPDATED_PROD_DEPOT_ENTRY,
    DELETED_PROD_DEPOT_ENTRY,
  ],
  prodStockRevision: [NEW_PRODSTOCK_REVISION],
  shipment: [
    NEW_SHIPMENT_ENTRY,
    DELETED_SHIPMENT_ENTRY,
    UPDATED_SHIPMENT_ENTRY,
  ],
  payment: [DELETED_PAYMENT_ENTRY, UPDATED_PAYMENT_ENTRY, NEW_PAYMENT_ENTRY],
  contractorPayment: [
    DELETED_CONTRACTOR_PAYMENT_ENTRY,
    UPDATED_CONTRACTOR_PAYMENT_ENTRY,
    NEW_CONTRACTOR_PAYMENT_ENTRY
  ],
  product: [DELETED_PRODUCT, UPDATED_PRODUCT, NEW_PRODUCT],
  order: [NEW_ORDER_ENTRY, UPDATED_ORDER_STATUS, DELETED_ORDER_ENTRY],
};
