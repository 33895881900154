import { Breadcrumb, Button, DatePicker, Layout, Menu, Radio } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchDepotLocations } from "../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../data/depotLocations/reducer";
import FabricTable from "./components/FabricTable";

function FabStockMain(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    fabrics,
    fetchFabStocks,
    fetchDepotLocations,
    depotLocations,
    deleteFabStock,
  } = props;

  const [loading, setLoading] = useState(true);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [fabricsWithStock, setFabricsWithStock] = useState([]);
  //const [fabDepotEntries, setFabDepotEntries] = useState(0);
  const [count, setCount] = useState(0);
  const [depotLocationOptions, setDepotLocationOptions] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new moment(new Date()));

  const [selectedDepot, setSelectedDepot] = useState("all");

  const [dataToDownload, setDataToDownload] = useState([]);

  const [filename, setFilename] = useState("Остатки материалов");

  const selectTable = useRef();
  const csvLink = useRef();

  const onFabStockCreatte = () => {
    history.push(`/fabstock/new`);
  };

  const onFabricDoubleClick = value => {
    history.push(`/fabric-admin/` + value.id);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/fabstock/` + contractor.id);
  };

  const onRemove = value => {
    // const {deleteEmployee} =props;
    deleteFabStock(value.id);
  };

  const handleGenerateClick = value => {
    // const {deleteEmployee} =props;
    axios
      .post(`/api/fabstockbalance/`, {
        selectedDate: new moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedDepot: selectedDepot,
      })
      .then(({ data }) => {
        setLoading(false);
        setFabricsWithStock(data);
        return data;
      });
    //
  };

  const handleDateChange = value => {
    setSelectedDate(value);
  };

  useEffect(() => {
    const { getFabrics, match, fetchDepotLocations } = props;

    fetchDepotLocations();

    setLoading(true);
    axios
      .post(`/api/fabstockbalance/`, {
        selectedDate: new moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedDepot: selectedDepot,
      })
      .then(({ data }) => {
        setLoading(false);
        setFabricsWithStock(data);
        return data;
      });
  }, []);

  const handleDepotChange = e => {
    //console.log(depot);
    //console.log(id);
    setSelectedDepot(e.target.value);

    axios
      .post(`/api/fabstockbalance/`, {
        selectedDate: new moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedDepot: e.target.value,
      })
      .then(({ data }) => {
        setLoading(false);
        setFabricsWithStock(data);
        return data;
      });
  };

  let options = depotLocations.map(depotLocation => {
    return (
      <Radio.Button key={depotLocation.id} value={depotLocation.id}>
        {depotLocation.name}
      </Radio.Button>
    );
  });

  const handleCsvDownload = () => {
    const headers = [
      { label: "Имя", key: "name" },
      { label: "Описание", key: "description" },
      { label: "Производитель", key: "manufacturer" },
      { label: "Остаток (кг)", key: "totalWeight" },
    ];
    let currentRecords = [];
    if (selectTable.current) {
      currentRecords = selectTable.current.getResolvedState().sortedData;
    }
    const data_to_download = [];
    for (let index = 0; index < currentRecords.length; index++) {
      const record_to_download = {};
      for (let colIndex = 0; colIndex < headers.length; colIndex++) {
        record_to_download[headers[colIndex].label] =
          currentRecords[index][headers[colIndex].key] || 0;
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
    const dateString = ` на ${selectedDate.format("DD.MM.YYYY")}`;
    const depot = depotLocations.find(el => el.id === selectedDepot);
    const depotString = depot && depot.name ? ` (${depot.name})` : "";
    setFilename(filename.concat(dateString, depotString));
  };

  useEffect(() => {
    if (dataToDownload.length) {
      csvLink.current.link.click();
      setFilename("Остатки материалов");
    }
  }, [dataToDownload]);

  return (
    <div>
      <div>
        <h2>Остаток материалов</h2>
        {/*/!* {console.log(fabDepotEntries)} *!/*/}
        {/*<Button type="primary" onClick={onFabStockCreatte}>*/}
        {/*  Создать*/}
        {/*</Button>*/}
      </div>
      <br />
      <Radio.Group
        defaultValue={selectedDepot}
        buttonStyle="solid"
        value={selectedDepot}
        onChange={handleDepotChange}
      >
        <Radio.Button key={"all"} value="all">
          Все склады
        </Radio.Button>
        {options}
      </Radio.Group>
      <br />
      <br />
      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="prodDate">
          Остаток на
        </label>
        &nbsp; &nbsp;
        {/*<br />*/}
        <DatePicker
          id="entry_date"
          name="date"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          value={selectedDate}
          style={{ zIndex: 9999 }}
          //disabled={!isInEditState}
        />
        &nbsp; &nbsp;
        <Button type="primary" onClick={handleGenerateClick}>
          Сгенерировать
        </Button>
        &nbsp; &nbsp;
        <Button type="primary" onClick={handleCsvDownload}>
          Скачать
        </Button>
        <CSVLink
          style={{ display: "none" }}
          data={dataToDownload}
          filename={`${filename}.csv`}
          ref={csvLink}
        >
          Скачать
        </CSVLink>
        {/*<span className="help-block">{validation.entry_dateField.message}</span>*/}
      </div>

      {/*<FabStockTable*/}
      {/*  loading={loading}*/}
      {/*  onEditClick={handleEditClick}*/}
      {/*  onRemove={onRemove}*/}
      {/*  fabStocks={fabStocks}*/}
      {/*  depotLocationOptions={depotLocationOptions}*/}
      {/*/>*/}
      <FabricTable
        onEditClick={onFabricDoubleClick}
        onRemove={onRemove}
        fabricWithStock={fabricsWithStock}
        loading={loading}
        ref={selectTable}
      />
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    //fabrics: getAllFabrics(state),
    depotLocations: getAllDepotLocations(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabStockMain),
);

//export default ContractorMain
