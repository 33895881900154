import { nanoid } from "nanoid";

export default (passportEntry, prodBoms, openedOrder) => {
  if (passportEntry) {
    return {
      period: {
        ...passportEntry.period,
        label: passportEntry.period.name,
        value: passportEntry.period.id,
      },
      team: passportEntry.team
        ? {
            ...passportEntry.team,
            label: passportEntry.team.name,
            value: passportEntry.team.id,
          }
        : null,
      prodBoms: prodBoms.map(prodBom => {
        const operations = [];
        for (const prodOperation of prodBom.prodOperations.filter(
          operation => operation.operationType_id !== 1,
        )) {
          const operationsToPush = passportEntry.wages.filter(
            wage =>
              wage.prodBom_id === prodBom.id &&
              wage.prodOperation.id === prodOperation.id,
          );

          if (operationsToPush.length > 0) {
            operations.push(
              ...operationsToPush.map(wage => ({
                ...wage,
                key: wage.id,
                period:
                  wage.period.id === passportEntry.period.id
                    ? undefined
                    : wage.period,
                prodBomId: wage.prodBom_id,
                operation: wage.prodOperation,
                prodOperation: wage.prodOperation.operation,
                employee: wage.employee,
                quantity: wage.quantity,
              })),
            );
          } else {
            operations.push({
              key: nanoid(5),
              prodBomId: prodBom.id,
              operation: prodOperation,
              prodOperation: prodOperation.operation,
              employee: null,
              quantity: 0,
            });
          }
        }
        return operations;
      }),
      lineItems: passportEntry.lineItems.map(lineItem => ({
        sizeId: lineItem.size.id,
        sizeName: lineItem.size.name,
        quantity: lineItem.quantity,
      })),
    };
  } else {
    return {
      period: null,
      team: null,
      prodBoms: prodBoms.map(prodBom =>
        prodBom.prodOperations
          .filter(operation => operation.operationType_id !== 1)
          .map((operation, key) => ({
            key: nanoid(5),
            prodBomId: prodBom.id,
            operation: operation,
            prodOperation: operation.operation,
            employee: null,
            quantity: 0,
          })),
      ),
      lineItems: openedOrder.lineItems.map(lineItem => ({
        sizeId: lineItem.size.id,
        sizeName: lineItem.size.name,
        quantity: 0,
      })),
    };
  }
};
