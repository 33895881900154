"use strict";

module.exports = {
  NEW_FABSTOCK_MOVEMENT_REQUEST: 1,
  APPROVED_FABSTOCK_MOVEMENT_REQUEST: 2,
  DECLINED_FABSTOCK_MOVEMENT_REQUEST: 3,
  NEW_FAB_DEPOT_ENTRY: 4,
  UPDATED_FAB_DEPOT_ENTRY: 5,
  DELETED_FAB_DEPOT_ENTRY: 6,
  NEW_FABSTOCK_REVISION: 7,
  NEW_PRODSTOCK_MOVEMENT: 8,
  NEW_PROD_DEPOT_ENTRY: 9,
  UPDATED_PROD_DEPOT_ENTRY: 10,
  DELETED_PROD_DEPOT_ENTRY: 11,
  NEW_PRODSTOCK_REVISION: 12,
  UPDATED_FABSTOCK_MOVEMENT_REQUEST: 13,
  NEW_SHIPMENT_ENTRY: 14,
  DELETED_SHIPMENT_ENTRY: 15,
  NEW_PAYMENT_ENTRY: 16,
  UPDATED_PAYMENT_ENTRY: 17,
  DELETED_PAYMENT_ENTRY: 18,
  UPDATED_SHIPMENT_ENTRY: 19,
  NEW_PRODUCT: 20,
  UPDATED_PRODUCT: 21,
  DELETED_PRODUCT: 22,
  NEW_CONTRACTOR_PAYMENT_ENTRY: 23,
  UPDATED_CONTRACTOR_PAYMENT_ENTRY: 24,
  DELETED_CONTRACTOR_PAYMENT_ENTRY: 25,
  NEW_ORDER_ENTRY: 26,
  UPDATED_ORDER_STATUS: 27,
  DELETED_ORDER_ENTRY: 28
};
