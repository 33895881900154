import { Card, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";

class WageEntry extends Component {
  render() {
    const { wage } = this.props;

    return (
      <div>
        <label>Количество: {wage.quantity}</label>
        <br />
        <label>Сумма: {wage.sum}</label>
        <br />
        <label>Операция: {wage.prodOperation.operation}</label>
        <br />
        <label />
      </div>
    );
  }
}

export default WageEntry;
