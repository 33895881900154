import Icon, { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import matchSorter from "match-sorter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";

class ProductTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  airTableSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 65 65"
    >
      <path
        d="M28.578 5.906L4.717 15.78c-1.327.55-1.313 2.434.022 2.963l23.96 9.502a8.89 8.89 0 0 0 6.555 0l23.96-9.502c1.335-.53 1.35-2.414.022-2.963l-23.86-9.873a8.89 8.89 0 0 0-6.799 0"
        fill="#fc0"
      />
      <path
        d="M34.103 33.433V57.17a1.6 1.6 0 0 0 2.188 1.486l26.7-10.364A1.6 1.6 0 0 0 64 46.806V23.07a1.6 1.6 0 0 0-2.188-1.486l-26.7 10.364a1.6 1.6 0 0 0-1.009 1.486"
        fill="#31c2f2"
      />
      <path
        d="M27.87 34.658l-8.728 4.215-16.727 8.015c-1.06.512-2.414-.26-2.414-1.44V23.17c0-.426.218-.794.512-1.07a1.82 1.82 0 0 1 .405-.304c.4-.24.97-.304 1.455-.112l25.365 10.05c1.3.512 1.4 2.318.133 2.925"
        fill="#ed3049"
      />
      <path
        d="M27.87 34.658l-7.924 3.826L.512 22.098a1.82 1.82 0 0 1 .405-.304c.4-.24.97-.304 1.455-.112l25.365 10.05c1.3.512 1.4 2.318.133 2.925"
        fill="#c62842"
      />
    </svg>
  );

  render() {
    const {
      products,
      onEditClick,
      onRemove,
      classes,
      domainSettings,
      hasAccessToModify,
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 60,
        filterable: false,
        Cell: row => (
          <div>
            <Button
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={e => onEditClick(row.original, e)}
            >
              {/* <OpenInNew classes={{ root: classes.iconRoot }} />
                        <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
            </Button>
          </div>
        ),
      },
      {
        Header: "Артикул",
        accessor: "article", // String-based value accessors!
        id: "article",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["article"] }),
        filterAll: true,
      },
      {
        Header: "Название",
        accessor: "name",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
        id: "name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
      },
      {
        Header: "Описание",
        accessor: "description",
        Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
        id: "description",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["description"] }),
        filterAll: true,
      },
    ];

    if (hasAccessToModify) {
      columns.push({
        Header: "Удалить",
        id: "delete",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <div>
            {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >

                    </Button> */}
            <Popconfirm
              placement="topRight"
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => this.handleDeleteConfirm(row.original, e)}
              onCancel={this.handleDeleteCancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="danger" size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
      });
    }

    if (domainSettings.find(setting => setting.id === 2).value === "true") {
      columns.push({
        Header: "AirTable",
        accessor: "airTable",
        maxWidth: 70,
        Cell: row => {
          const icon = row.original.airTable ? (
            <Icon component={this.airTableSvg} />
          ) : (
            <Icon
              style={{ filter: "grayscale(100%)" }}
              component={this.airTableSvg}
            />
          );

          return <div style={{ textAlign: "center" }}>{icon}</div>;
        },
        id: "airTableSynchronization",
        filterable: false,
      });
    }

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={products}
          columns={columns}
          getTrProps={(state, rowInfo, instance) => {
            if(domainSettings.find(setting => setting.id === 1).value !== ProductionProcessTypes.name.BARCODE) {
              if (rowInfo && rowInfo.original.estimatedPrice < 0.0001) {
                return {
                  style: {
                    background: "#ffffed"
                  }
                }
              }
            }

            return {}
          }}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText="Нет записей"
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

ProductTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(ProductTable);

// import React, { Component } from 'react';
// import { withRouter } from 'react-router'

// const ProductTable = ({ products, onProductDoubleClick, onRemove }) => {

//     var rows = [];
//     if (products) {
//         products.forEach(function (product) {
//             rows.push(<ProductRow key={product.id} product={product} onProductDoubleClick={onProductDoubleClick} onRemove={onRemove} />);
//         });
//     }
//     return (

//         <table className="table table-hover">
//             <thead>
//                 <tr>
//                     <th className="col-xs-3">Артикул</th>
//                     <th className="col-xs-3">Название</th>
//                     <th className="col-xs-4">Описание</th>
//                     <th className="col-xs-2"></th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {rows}
//             </tbody>
//         </table>
//     );

// }

// export default withRouter(ProductTable);

// class ProductRow extends Component {

//     onRemove=(e)=>{
//         e.preventDefault();
//         e.stopPropagation();
//         this.props.onRemove(this.props.product.id)
//     }

//     handleDoubleClickRemove = (e) => {
//         e.preventDefault();
//         e.stopPropagation();
//     }

//     onProductDoubleClick=()=>{
//         this.props.onProductDoubleClick(this.props.product.id)
//     }

//     render() {
//         return (
//             <tr key={this.props.product.id} onDoubleClick={this.onProductDoubleClick}>
//                 <td>{this.props.product.article}</td>
//                 <td>{this.props.product.name}</td>
//                 <td>{this.props.product.description}</td>
//                 <td>  <button onClick={this.onRemove} onDoubleClick={this.handleDoubleClickRemove} type="button" className="btn btn-danger btn-sm">
//                     <span className="glyphicon glyphicon-trash"></span>
//                 </button></td>
//             </tr>
//         );

//     }

// }
