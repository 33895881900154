import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Input, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";

import { setShowPasswordConfirmationModal } from "../../scenes/Common/actions";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "400px",
        minHeight: "200px",
      },
      root: {
        //alignItems: "initial"
        zIndex: 1000,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class PasswordConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      isChecking: false,
    };
  }
  close = () => {
    this.props.setShowPasswordConfirmationModal(false);
  };

  checkPassword = password =>
    axios
      .post("/api/users/confirmPassword", {
        password,
      })
      .catch(() => {
        message.error("Неправильный пароль");
        return Promise.reject();
      });

  componentDidUpdate(prevProps) {
    if (this.props.showModal !== prevProps.showModal) {
      this.setState({
        password: "",
        isChecking: false,
      });
    }
  }

  handleSubmit = () => {
    this.setState({ isChecking: true });
    this.checkPassword(this.state.password)
      .then(() => {
        this.setState({ isChecking: false });
        this.close();
        this.props.onSuccess();
      })
      .catch(() => {
        this.setState({ isChecking: false });
      });
  };

  render() {
    const { showModal } = this.props;
    const { fullScreen } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Введите пароль
            </DialogTitle>
            <DialogContent>
              <Input.Password
                visibilityToggle={true}
                value={this.state.password}
                onChange={e => {
                  this.setState({ password: e.target.value });
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!this.state.password}
                type="primary"
                onClick={this.handleSubmit}
                loading={this.state.isChecking}
              >
                Отправить
              </Button>

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showModal: state.scenes.common.showPasswordConfirmationModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowPasswordConfirmationModal: showModal =>
      dispatch(setShowPasswordConfirmationModal(showModal)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PasswordConfirmationModal),
);
