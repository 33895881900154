import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, InputNumber, Table } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import Select from "components/Select";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllDepotLocations } from "../../../../../data/depotLocations/reducer";
import { setShowRealPriceCalculationModal } from "../../../actions";
import { getOpenedCuttingOrder } from "../../../selectors";
import { calculateRealPrice } from "../../../../../data/passportEntries/actions";
import "../cleanInputNumber.css"

const theme = createMuiTheme({
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                maxWidth: "800px",
                minWidth: "800px",
                minHeight: "200px",
            },
            root: {
                //alignItems: "initial"
                zIndex: 1000,
            },
        },
    },
    typography: {
        useNextVariants: true,
    },
});

class RealPriceCalculationModal extends Component {
    constructor(props) {
        super(props);

        const value = {}
        if(props.passportFabricDetails) {
            props.passportFabricDetails.forEach(fabricDetails => {
                value[fabricDetails.fabric_id] = fabricDetails.price
            })
        }

        this.state = {
            priceByFabricId: value,
            isSaving: false
        };
    }

    close = () => {
        this.props.setShowRealPriceCalculationModal(false);
    };

    handleCalculateRealPrice = () => {
        this.setState({isSaving: true});
        this.props.calculateRealPrice(this.props.openedOrder.id,
            this.props.openedOrder.cuttingPassportEntry.id,
            this.props.fabrics.map(fabric => ({
                id: fabric.id,
                price: this.state.priceByFabricId[fabric.id]
            }))
        ).then(() => {
            this.setState({isSaving: false});
            this.close();
        })
        .catch(() => {
            this.setState({isSaving: false});
        })
    }

    handleChangePrice = (id, newValue) => {
        this.setState({
            priceByFabricId: {
                ...this.state.priceByFabricId,
                [id]: newValue
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.showModal !== prevProps.showModal) {
            this.setState({
            });
        }
    }

    render() {
        const { showModal, fabrics } = this.props;
        const { fullScreen } = this.props;

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Dialog
                        fullScreen={fullScreen}
                        open={showModal}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            Стоимость материала
                        </DialogTitle>
                        <DialogContent>
                            <Table
                                rowKey={"id"}
                                pagination={false}
                                size={"small"}
                                dataSource={ fabrics }
                                columns={[
                                    {
                                        title: '',
                                        dataIndex: 'id',
                                        key: 'id',
                                        render: (text, record) => {
                                            return `FAB-${text}`
                                        }
                                    },
                                    {
                                        title: "Название",
                                        dataIndex: "name",
                                        key: "name"
                                    },
                                    {
                                        title: "Цена (кг)",
                                        render: (text, record) => {
                                            return <InputNumber
                                                className={"cleanInputNumber"}
                                                precision={2}
                                                value={this.state.priceByFabricId[record.id]}
                                                onChange={(val) => this.handleChangePrice(record.id, val)}
                                                formatter={val => val ? `$ ${val}` : ''}
                                                size={"small"}
                                                bordered
                                            />
                                        }
                                    }
                                ]}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={fabrics.some((fabric) => !this.state.priceByFabricId[fabric.id])}
                                type="primary"
                                onClick={this.handleCalculateRealPrice}
                                loading={this.state.isSaving}
                            >
                                Рассчитать
                            </Button>

                            <Button onClick={this.close}>Закрыть</Button>
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const getUniqueFabrics = (fabricConsumptions) => {
        const usedFabricId = {};
        const result = [];

        fabricConsumptions.forEach(fabricConsumption => {
            if(!usedFabricId[fabricConsumption.fabric_id]) {
                usedFabricId[fabricConsumption.fabric_id] = true;
                result.push({
                    id: fabricConsumption.fabric_id,
                    name: fabricConsumption.fabric.name,
                })
            }
        })

        return result;
    }

    const openedOrder = getOpenedCuttingOrder(state);
    return {
        depotLocations: getAllDepotLocations(state),
        showModal: state.scenes.cutting.showRealPriceCalculationModal,
        fabrics: openedOrder ? getUniqueFabrics(openedOrder.cuttingPassportEntry.fabricConsumptions) : [],
        passportFabricDetails: openedOrder ? openedOrder.cuttingPassportEntry.passportFabricDetails : null,
        openedOrder
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShowRealPriceCalculationModal: showModal =>
            dispatch(setShowRealPriceCalculationModal(showModal)),
        calculateRealPrice: (orderId, passportId, fabricPrices) =>
            dispatch(calculateRealPrice(orderId, passportId, fabricPrices))
    };
};

export default withMobileDialog()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RealPriceCalculationModal),
);
