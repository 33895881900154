import { combineReducers } from "redux";

const openedUserId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_USER_ID":
      return action.userId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedUserId,
});
