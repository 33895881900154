import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import WageSummaryMain from "./index.js";

///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class WageSummaryRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/wagesummary" component={WageSummaryMain} />
        </Switch>
      </div>
    );
  }
}

export default WageSummaryRoute;
