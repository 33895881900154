import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

const SizeTable = ({ sizes, onSizeDoubleClick, onRemove }) => {
  var rows = [];
  if (sizes) {
    sizes.forEach(function(size) {
      rows.push(
        <SizeRow
          key={size.id}
          size={size}
          onRemove={onRemove}
          onSizeDoubleClick={onSizeDoubleClick}
        />,
      );
    });
  }

  function handleConfirm(row, e) {
    onRemove(row.original);
    //const { spread, onPatternRemove, onPatternEdit } = this.props;
    //onPatternRemove(row, e);
  }

  const columns = [
    {
      Header: "Размер",
      accessor: "name",
      Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
    },
    {
      Header: "Удалить",
      maxWidth: 100,
      Cell: row => (
        <div>
          <Popconfirm
            placement="topRight"
            title="Уверены что хотите удалить этот размер?"
            onConfirm={e => handleConfirm(row, e)}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="danger" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <ReactTable
      data={sizes}
      columns={columns}
      className=" -highlight"
      minRows={10}
      showPagination={false}
    />
  );
};

export default withRouter(SizeTable);

class SizeRow extends Component {
  onRemove = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onRemove(this.props.size);
  };

  handleDeleteCancel = e => {};

  handleDoubleClickRemove = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  onSizeDoubleClick = () => {
    this.props.onSizeDoubleClick(this.props.size.id);
  };

  render() {
    return (
      <tr
        data-id={this.props.size.id}
        key={this.props.size.id}
        onDoubleClick={this.onSizeDoubleClick}
      >
        <td>{this.props.size.name}</td>
        <td>
          <Popconfirm
            placement="topRight"
            title="Уверены что хотите удалить этот размер?"
            onConfirm={this.onRemove}
            onCancel={this.handleDeleteCancel}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="danger" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
          {/* <button onClick={this.onRemove} onDoubleClick={this.handleDoubleClickRemove} type="button" className="btn btn-danger btn-sm">
                        <span className="glyphicon glyphicon-trash"></span>
                    </button> */}
        </td>
      </tr>
    );
  }
}
