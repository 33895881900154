import { combineReducers } from "redux";

import {
  SPECS_FETCH_SUCCESS,
  SPEC_CREATE_SUCCESS,
  SPEC_DELETE_SUCCESS,
  SPEC_FETCH_SUCCESS,
  SPEC_UPDATE_SUCCESS,
} from "./actions";

const initialState = { name: "", article: "", description: "", sizes: [] };

const byId = (state = {}, action) => {
  switch (action.type) {
    case SPECS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.specs,
      };
    case SPEC_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.specs,
      };
    case SPEC_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.specs,
      };
    case SPEC_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.specs,
      };
    case SPEC_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.specId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case SPECS_FETCH_SUCCESS:
      return action.response.result;
    case SPEC_CREATE_SUCCESS:
      return [...state, action.response.result];
    case SPEC_DELETE_SUCCESS:
      return [...state.filter(id => id != action.specId)];
    default:
      return state;
  }
};

export const specs = combineReducers({
  allIds,
  byId,
});

//SELECTORS
