import { Breadcrumb, Button, Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { deletePeriod, fetchPeriods } from "../../data/periods/actions";
import { getAllPeriods } from "../../data/periods/reducer";
import PeriodTable from "./components/PeriodTable";

class PeriodMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchPeriods, match } = this.props;
    fetchPeriods();
  }

  render() {
    const { history, periods, deletePeriod } = this.props;

    const onPeriodCreate = () => {
      history.push(`/period/new`);
    };

    const handleEditClick = contractor => {
      //console.log(id);
      history.push(`/period/` + contractor.id);
    };

    const onRemove = value => {
      deletePeriod(value.id);
      //history.push(`/product-admin/`)
    };

    return (
      <div>
        <div>
          <h2>Периоды</h2>
          <Button type="primary" onClick={onPeriodCreate}>
            Создать
          </Button>
        </div>
        <br />
        <PeriodTable
          onEditClick={handleEditClick}
          onRemove={onRemove}
          periods={periods}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    periods: getAllPeriods(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchPeriods: () => dispatch(fetchPeriods()),
    deletePeriod: id => dispatch(deletePeriod(id)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PeriodMain),
);

//export default ContractorMain
