import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import SizeMain from "./index";

class SizeRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/size" component={SizeMain} />
          {/* <Route exact path={`${this.props.match.url}/new`} component={ProductDetailContainer} />
                    <Route exact path={`${this.props.match.url}/:id`} component={ProductDetailContainer} /> */}
        </Switch>
      </div>
    );
  }
}

export default SizeRoute;
