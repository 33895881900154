export const setOpenedOrder = orderId => ({
  type: "SET_OPENED_ORDER",
  orderId,
});

export const setShowCompletionModal = showCompletionModal => ({
  type: "SHOW_COMPLETION_MODAL",
  showCompletionModal,
});

export const setOrderIdForCompletionModal = orderId => ({
  type: "SET_ORDER_ID_FOR_COMPLETION_MODAL",
  orderId,
});
