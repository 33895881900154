export const getFirstLevelFromPath = path => {
  var a = path.split("/");
  var res = "";
  if (a.length > 1) {
    res = "/" + a[1];
  }
  return res;
};

export const getStateNameForRoute = (routes, pathname) => {
  let res = getFirstLevelFromPath(pathname);

  let stateName = ";";
  for (let route of routes) {
    if (route.views) {
      for (let view of route.views) {
        if (view.path == res) {
          return route.state;
        }
      }
    }
  }
  return stateName;
};
