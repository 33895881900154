import { createSelector } from "reselect";

import * as orders from "../../data/orders/selectors";

export const getOpenedCuttingOrderId = state =>
  state.scenes.cutting.openedCuttingOrder;

export const getOpenedCuttingEntryId = state =>
  state.scenes.cutting.openedCuttingEntryId;

export const getOpenedProdEntryId = state =>
  state.scenes.cutting.openedProdEntryId;

export const getOpenedCuttingEntry = state =>
  orders.getCuttingEntries(state).byId[getOpenedCuttingEntryId(state)];

export const getModalSelectedSpreadId = state =>
  state.scenes.cutting.modalSelectedSpread;

export const getModalSelectedSpread = state =>
  orders.getProdSpreads(state).byId[getModalSelectedSpreadId(state)];

export const getSelectedProdBomId = state =>
  state.scenes.cutting.selectedProdBomId;

export const getWages = state => state.data.wages;

export const getOpenedWageId = state => state.scenes.cutting.openedWageId;

export const getProdPatternsBySelectedProdSpread = createSelector(
  [orders.getProdSpreads, orders.getProdPatterns, getModalSelectedSpreadId],
  (prodSpreads, prodPatterns, prodSpreadId) => {
    let patterns = [];
    let prodSpread = prodSpreads.byId[prodSpreadId];
    if (prodSpread) {
      let sps = prodSpread.prodPatterns.map(id => prodPatterns.byId[id]);
      patterns = [...patterns, ...sps];
    }
    return patterns;
  },
);

// export const getProdPatternsByOpenedOrder = createSelector(
//     [orders.getOrders, orders.getProdSpreads, orders.getProdPatterns, getOpenedCuttingOrderId],
//     (orders, prodSpreads, prodPatterns, orderId) => {
//         let patterns=[];
//         let order=orders.byId[orderId];
//         if (order && order.prodSpreads) {

//             let spreads = order.prodSpreads.map(id => prodSpreads.byId[id]);

//             spreads.forEach(spread=>{
//                 if (spread.prodPatterns) {
//                     let sps = spread.prodPatterns.map(id => prodPatterns.byId[id])
//                     patterns = [...patterns, ...sps]
//                 }
//             })
//         }
//         return patterns
//     }
// )

// export const getProdSpreadsByOpenedOrder = createSelector(
//     [orders.getOrders, orders.getProdSpreads, getOpenedCuttingOrder],
//     (orders, prodSpreads, orderId) => {
//         let spreads=[];
//         let order=orders.byId[orderId];
//         if (order && order.prodSpreads) {

//             spreads = order.prodSpreads.map(id => {
//                 let spread=prodSpreads.byId[id]
//                 return {...spread, label: spread.name, value: spread.id}
//             });

//         }
//         return spreads
//     }
// )

export const getProdBomsByOpenedOrderWithSpreadsPatternsEntries = createSelector(
  [
    orders.getOrders,
    orders.getProdBoms,
    orders.getProdSpreads,
    orders.getCuttingEntries,
    orders.getProdOperations,
    orders.getProdPatterns,
    getOpenedCuttingOrderId,
  ],
  (
    orders,
    prodBoms,
    prodSpreads,
    cuttingEntries,
    prodOperations,
    prodPatterns,
    orderId,
  ) => {
    let boms = [];
    let order = orders.byId[orderId];
    if (order && order.prodBoms) {
      boms = order.prodBoms.map(prodBomId => {
        let bom = prodBoms.byId[prodBomId];

        let operations = [];
        if (bom.prodOperations && bom.prodOperations.length > 0) {
          operations = bom.prodOperations.map(
            operation => prodOperations.byId[operation],
          );
        }
        let spreads = [];
        if (bom.prodSpreads && bom.prodSpreads.length > 0) {
          spreads = bom.prodSpreads.map(prodSpreadId => {
            let spread = prodSpreads.byId[prodSpreadId];
            let patterns = [];
            if (spread.prodPatterns.length > 0) {
              patterns = spread.prodPatterns.map(
                prodPatternId => prodPatterns.byId[prodPatternId],
              );
            }

            //get cuttingEntries
            let resCuttingEntries = [];
            if (spread.cuttingEntries.length > 0) {
              resCuttingEntries = spread.cuttingEntries.map(
                id => cuttingEntries.byId[id],
              );
            }

            return {
              ...spread,
              prodPatterns: patterns,
              cuttingEntries: resCuttingEntries,
            };
          });
        }

        return { ...bom, prodSpreads: spreads, prodOperations: operations };
      });
    }

    return boms;
  },
);

export const getProdSpreadsBySelectedBomWithPatterns = createSelector(
  [
    orders.getProdBoms,
    orders.getProdSpreads,
    orders.getProdPatterns,
    getSelectedProdBomId,
  ],
  (prodBoms, prodSpreads, prodPatterns, prodBomId) => {
    let spreads = [];
    let bom = prodBoms.byId[prodBomId];

    if (bom && bom.prodSpreads.length > 0) {
      spreads = bom.prodSpreads.map(prodSpreadId => {
        let spread = prodSpreads.byId[prodSpreadId];
        let patterns = [];
        if (spread.prodPatterns.length > 0) {
          patterns = spread.prodPatterns.map(
            prodPatternId => prodPatterns.byId[prodPatternId],
          );
        }
        return { ...spread, prodPatterns: patterns };
      });
    }
    return spreads;
  },
);

export const getSelectedProdBom = createSelector(
  [
    orders.getProdBoms,
    orders.getProdSpreads,
    orders.getProdPatterns,
    getSelectedProdBomId,
  ],
  (prodBoms, prodSpreads, prodPatterns, prodBomId) => {
    let spreads = [];
    let bom = undefined;
    bom = prodBoms.byId[prodBomId];

    if (bom) {
      if (bom.prodSpreads.length > 0) {
        spreads = bom.prodSpreads.map(prodSpreadId => {
          let spread = prodSpreads.byId[prodSpreadId];
          let patterns = [];
          if (spread.prodPatterns.length > 0) {
            patterns = spread.prodPatterns.map(
              prodPatternId => prodPatterns.byId[prodPatternId],
            );
          }
          return { ...spread, prodPatterns: patterns };
        });
      }
      return { ...bom, prodSpreads: spreads };
    } else return bom;
  },
);

export const getCuttingWagesForSelectedProdBom = createSelector(
  [
    orders.getProdBoms,
    orders.getProdSpreads,
    orders.getCuttingEntries,
    orders.getWages,
    getSelectedProdBomId,
  ],
  (prodBoms, prodSpreads, cuttingEntries, wages, prodBomId) => {
    let resCuttingWages = [];
    let bom = undefined;
    bom = prodBoms.byId[prodBomId];

    if (bom) {
      if (bom.prodSpreads.length > 0) {
        bom.prodSpreads.forEach(prodSpreadId => {
          let spread = prodSpreads.byId[prodSpreadId];
          if (spread.cuttingEntries.length > 0) {
            let entries = spread.cuttingEntries.map(
              id => cuttingEntries.byId[id],
            );
            //console.log("SSSSPREAD",entries);

            entries.forEach(entry => {
              //make sure entry is defined;
              if (entry) {
                let periodName = "";
                if (entry.period) {
                  periodName = entry.period.name;
                }

                entry.wages.forEach(wageId => {
                  let wage = wages.byId[wageId];
                  resCuttingWages.push({ periodName, ...wage });
                });
              }
            });
          }
        });
      }
    }

    return resCuttingWages;
  },
);

export const getProdWagesForSelectedProdBom = createSelector(
  [
    orders.getProdBoms,
    orders.getProdEntries,
    orders.getWages,
    getSelectedProdBomId,
  ],
  (prodBoms, prodEntries, wages, prodBomId) => {
    let resProdWages = [];
    let bom = undefined;
    bom = prodBoms.byId[prodBomId];

    if (bom) {
      if (bom.prodEntries.length > 0) {
        bom.prodEntries.forEach(prodEntryId => {
          let prodEntry = prodEntries.byId[prodEntryId];
          //console.log("PROD ENTRY", prodEntry)

          if (prodEntry && prodEntry.wages) {
            let periodName = "";
            if (prodEntry.period) {
              periodName = prodEntry.period.name;
            }

            prodEntry.wages.forEach(id => {
              let newEntry = wages.byId[id];
              resProdWages.push({
                ...newEntry,
                size: prodEntry.size.name,
                periodName,
              });
            });
          }
          // resProdWages.push(...prodEntry.wages.map(id=>{
          //     return { ...wages.byId[id], size: prodEntry.size.name }
          // }));
        });
      }
    }
    //console.log("PROD ENTRY res", resProdWages)
    return resProdWages;
  },
);

export const getCuttingEntriesForSelectedProdSpread = createSelector(
  [orders.getProdSpreads, orders.getCuttingEntries, getModalSelectedSpreadId],
  (prodSpreads, cuttingEntries, prodSpreadId) => {
    let resEntries = [];
    let prodSpread = prodSpreads.byId[prodSpreadId];

    if (prodSpread && prodSpread.cuttingEntries) {
      resEntries = prodSpread.cuttingEntries.map(id => cuttingEntries.byId[id]);
    }

    return resEntries;
  },
);

// export const getCuttingEntriesByOpenedOrder = createSelector(
//     [orders.getOrders, orders.getCuttingEntries, getOpenedCuttingOrderId],
//     (orders, entries, orderId) => {
//         let cuttingEntries=[];
//         let order=orders.byId[orderId];
//         if(order && order.cuttingEntries){

//             cuttingEntries=order.cuttingEntries.map(id=>entries.byId[id])
//         }

//         return cuttingEntries;
//     }
// )

export const getProdEntriesForSelectedProdBom = createSelector(
  [orders.getProdBoms, orders.getProdEntries, getSelectedProdBomId, getWages],
  (prodBoms, entries, prodBomId, wages) => {
    let prodEntries = [];
    let prodBom = prodBoms.byId[prodBomId];
    if (prodBom && prodBom.prodEntries) {
      prodEntries = prodBom.prodEntries
        .map(id => entries.byId[id])
        .map(entry => ({
          ...entry,
          wages: entry.wages
            .filter(wageId => wages.byId[wageId])
            .map(wageId => wages.byId[wageId]),
        }));
    }

    return prodEntries;
  },
);

export const getOpenedCuttingOrder = createSelector(
  [orders.getOrders, getOpenedCuttingOrderId],
  (orders, orderId) => {
    let order = orders.byId[orderId];

    return order;
  },
);

export const getOpenedProdEntry = createSelector(
  [orders.getProdEntries, getOpenedProdEntryId],
  (entries, entryId) => {
    let entry = entries.byId[entryId];

    return entry;
  },
);

export const getOpenedWage = createSelector(
  [orders.getWages, getOpenedWageId],
  (wages, wageId) => {
    let wage = wages.byId[wageId];

    return wage;
  },
);

export const getProdOperationsForSelectedProdBom = createSelector(
  [orders.getProdBoms, orders.getProdOperations, getSelectedProdBomId],
  (prodBoms, prodOperations, prodBomId) => {
    let resProdOperations = [];
    let prodBom = prodBoms.byId[prodBomId];
    if (prodBom && prodBom.prodOperations) {
      resProdOperations = prodBom.prodOperations.map(
        id => prodOperations.byId[id],
      );
    }

    return resProdOperations;
  },
);

//export const getOpenedProdEntry=(state)=>orders.getProdEntries(state).byId[getOpenedProdEntryId(state)]
