import { Breadcrumb, Button, Card, Layout, Menu, Radio } from "antd";
import { Input, notification } from "antd";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { fetchEmployee } from "../../data/employees/actions";
import { claimWageEntry, unClaimWageEntry } from "../../data/wages/actions";
import { setSelectedEmployeeId } from "./actions";
import { createAttendanceRecord } from "./api";
import WageTable from "./components/WageTable";
import {
  getTotalForSelectedEmployee,
  getWagesForSelectedEmployee,
} from "./selectors";

//import TeamTable from './components/TeamTable'
//import { getAllTeams } from '../../data/teams/reducer';

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

class AttendanceMain extends Component {
  constructor(props) {
    super(props);

    this.interval = null;

    this.textInput = React.createRef();

    this.state = {
      selectedEmployee: undefined,
      barCode: "",

      attendanceType: 1,

      employee: undefined,
      record: undefined,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.textInput && this.textInput.current) {
        this.textInput.current.focus();
      }
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getEmployeeOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return axios(`/api/employees/search?q=${input}`).then(({ data }) => {
      return {
        options: data.items.map(item => {
          return {
            value: item.id,
            label:
              item.first_name + " " + item.last_name + " " + item.middle_name,
            id: item.id,
            ...item,
          };
        }),
      };
    });
  };

  handleEmployeeSelect = employee => {
    const { fetchEmployee, setSelectedEmployeeId } = this.props;

    if (employee) {
      fetchEmployee(employee.id).then(employee => {
        setSelectedEmployeeId(employee.id);
      });
    } else {
      setSelectedEmployeeId(null);
    }

    this.setState({ selectedEmployee: employee });
  };

  handlBarCodeChange = value => {
    this.setState({ barCode: value.target.value });
  };

  handleAttendanceTypeChange = e => {
    this.setState({ attendanceType: e.target.value });
    this.focusTextInput();
  };

  handleKeyPress = e => {
    const { createWageClaim } = this.props;
    if (e.key === "Enter") {
      //console.log('do validate');
      //console.log("blahblahblah",this.state.barCode)
      let barCode = this.state.barCode;
      let employeeId = undefined;
      let badFormat = 0;

      if (barCode.length < 2) {
        badFormat = 1;
      } else {
        if (barCode[0] === "0") {
          if (barCode[1] === "2") employeeId = barCode.slice(2);
          else badFormat = 1;
        } else {
          badFormat = 1;
        }
      }

      if (!badFormat) {
        if (Number.isInteger(parseInt(employeeId, 10))) {
          let entry = {
            attendanceType: this.state.attendanceType,
            where: "Qorovul Xona",
          };

          let isTheSameEmployee = this.state.employee
            ? this.state.employee.id == employeeId
            : false;

          if (isTheSameEmployee) {
            let nowDate = moment(new Date());

            var duration = moment.duration(
              nowDate.diff(this.state.record.scanned_at),
            );
            var seconds = duration.asSeconds();

            if (seconds > 60) {
              createAttendanceRecord(employeeId, entry)
                .then(record => {
                  let employee = record.employee;
                  this.setState({ employee: employee });
                  this.setState({ record: record });
                  // console.log("yes successful");
                })
                .catch(error => {
                  console.log("rejected");
                  this.setState({ employee: undefined });
                  this.setState({ record: undefined });
                });
            }
          } else {
            createAttendanceRecord(employeeId, entry)
              .then(record => {
                let employee = record.employee;
                this.setState({ employee: employee });
                this.setState({ record: record });
                // console.log("yes successful");
              })
              .catch(error => {
                console.log("rejected");
                this.setState({ employee: undefined });
                this.setState({ record: undefined });
              });
          }
        } else {
          openNotificationWithIcon("Ошибка", "Неправильный штрих код", "error");
        }
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Неверный формат штрих кода",
          "error",
        );
      }

      setTimeout(e => {
        this.setState({ barCode: "" });
      }, 200);
    }
  };
  handleWageRemove = value => {
    const { deleteWageEntry } = this.props;
    console.log(value);
    deleteWageEntry(this.state.selectedEmployee.id, value);
  };
  handleEditClick = value => {};

  focusTextInput = () => {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.textInput.current.focus();
  };

  render() {
    const { wages, total } = this.props;

    let sortedWages = [...wages];

    sortedWages.reverse();

    return (
      <div className="mt-content">
        <h2>Сканируйте работника</h2>
        <br />
        <Radio.Group
          value={this.state.attendanceType}
          buttonStyle="solid"
          onChange={this.handleAttendanceTypeChange}
        >
          <Radio.Button value={1}>Приход</Radio.Button>
          <Radio.Button value={2}>Уход</Radio.Button>
        </Radio.Group>
        <br />
        <br />
        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="article">
            Штрих код
          </label>
          <Input
            autoFocus
            ref={this.textInput}
            value={this.state.barCode}
            onChange={this.handlBarCodeChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>

        {this.state.employee != undefined && this.state.record != undefined && (
          <Card>
            <div style={{ marginBottom: 15 }}>
              <label className="mt-label" htmlFor="first_name">
                {" "}
                {this.state.employee.first_name}
              </label>
            </div>

            <div style={{ marginBottom: 15 }}>
              <label className="mt-label" htmlFor="last_name">
                {this.state.employee.last_name}
              </label>
            </div>

            <div style={{ marginBottom: 15 }}>
              <label className="mt-label" htmlFor="team">
                {this.state.employee.team.name}
              </label>
            </div>

            <div style={{ marginBottom: 15 }}>
              <label className="mt-label" htmlFor="team">
                {moment(this.state.record.scanned_at).format(
                  "DD/MM/YYYY, HH:mm:ss",
                )}
              </label>
            </div>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    wages: getWagesForSelectedEmployee(state),
    total: getTotalForSelectedEmployee(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    createWageClaim: (employeeId, wage) =>
      dispatch(claimWageEntry(employeeId, wage)),
    deleteWageEntry: (employeeId, wage) =>
      dispatch(unClaimWageEntry(employeeId, wage)),
    fetchEmployee: employeeId => dispatch(fetchEmployee(employeeId)),
    //fetchEmployee: (employeeId)=>dispatch(fetchEmployee(employeeId)),
    setSelectedEmployeeId: employeeId =>
      dispatch(setSelectedEmployeeId(employeeId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AttendanceMain),
);

//export default ContractorMain
