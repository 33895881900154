import { combineReducers } from "redux";

import { FABRIC_CONSUMPTIONS_FETCH_SUCCESS } from "./actions";

export const data = (state = [], action) => {
  switch (action.type) {
    case FABRIC_CONSUMPTIONS_FETCH_SUCCESS:
      return action.response.result;

    default:
      return state;
  }
};

const pagesCount = (state = 0, action) => {
  switch (action.type) {
    case FABRIC_CONSUMPTIONS_FETCH_SUCCESS:
      return action.response.pagesCount;
    default:
      return state;
  }
};

export default combineReducers({
  data,
  pagesCount,
});
