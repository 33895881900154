import { combineReducers } from "redux";

import { ORDERS_FETCH_SUCCESS, ORDER_FETCH_SUCCESS } from "../orders/actions";
import {
  PROD_ENTRY_CREATE_SUCCESS,
  PROD_ENTRY_DELETE_SUCCESS,
} from "../prodEntries/actions";
import {
  ADD_PRODOPERATION_SUCCESS,
  DELETE_PRODOPERATION_SUCCESS,
  PRODOPERATIONS_SAVE_SUCCESS,
} from "../prodOperations/actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case ORDER_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodBoms,
      };
    case ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodBoms,
      };
    case PROD_ENTRY_CREATE_SUCCESS: {
      const prodBomId = action.prodBomId;
      const prodEntryId = action.response.result;
      const prodBom = state[prodBomId];

      return {
        ...state,
        [prodBomId]: {
          ...prodBom,
          prodEntries: prodBom.prodEntries.concat(prodEntryId),
        },
      };
    }
    case DELETE_PRODOPERATION_SUCCESS: {
      const prodBomId = action.prodBomId;
      const prodOperationId = action.prodOperationId;
      const prodBom = state[prodBomId];

      return {
        ...state,
        [prodBomId]: {
          ...prodBom,
          prodOperations: prodBom.prodOperations.filter(
            op => op !== prodOperationId,
          ),
        },
      };
    }
    case ADD_PRODOPERATION_SUCCESS: {
      const prodBomId = action.prodBomId;
      const prodBom = state[prodBomId];

      return {
        ...state,
        [prodBomId]: {
          ...prodBom,
          prodOperations: [
            ...prodBom.prodOperations,
            ...action.response.result,
          ],
        },
      };
    }
    case PRODOPERATIONS_SAVE_SUCCESS: {
      const prodBomId = action.prodBomId;
      const prodOperationIds = action.response.result;
      const prodBom = state[prodBomId];

      let A = prodBom.prodOperations;
      let B = action.oldProdOperationIds;
      let diff = A.filter(x => B.indexOf(x) < 0);

      //Need to replace all prodOperationIds for this prodbom with new Ids
      return {
        ...state,
        [prodBomId]: {
          ...prodBom,
          prodOperations: diff.concat(prodOperationIds),
        },
      };
    }
    case PROD_ENTRY_DELETE_SUCCESS:
      return deleteProdEntry(state, action);
    default:
      return state;
  }
};

function deleteProdEntry(state, action) {
  let prodBomId = action.prodBomId;
  let prodEntryId = action.prodEntryId;
  let prodBom = state[prodBomId];
  return {
    ...state,
    [prodBomId]: {
      ...prodBom,
      prodEntries: prodBom.prodEntries.filter(id => id != prodEntryId),
    },
  };
}

export const reducer = combineReducers({
  byId,
});
