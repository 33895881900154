import { normalize, schema } from "normalizr";

// Define a users schema

export const prodPattern = new schema.Entity(
  "prodPatterns",
  {},
  { idAttribute: "id" },
);
export const wage = new schema.Entity("wages", {}, { idAttribute: "id" });
export const cuttingEntry = new schema.Entity(
  "cuttingEntries",
  { wages: [wage] },
  { idAttribute: "id" },
);
export const prodEntry = new schema.Entity(
  "prodEntries",
  { wages: [wage] },
  { idAttribute: "id" },
);

export const prodOperation = new schema.Entity(
  "prodOperations",
  {},
  { idAttribute: "id" },
);
export const arrayOfProdOperations = [prodOperation];
export const prodSpread = new schema.Entity(
  "prodSpreads",
  { cuttingEntries: [cuttingEntry], prodPatterns: [prodPattern] },
  { idAttribute: "id" },
);
export const prodBom = new schema.Entity(
  "prodBoms",
  {
    prodSpreads: [prodSpread],
    prodOperations: [prodOperation],
    prodEntries: [prodEntry],
  },
  { idAttribute: "id" },
);

//export const cuttingItem = new schema.Entity('cuttingItems',{}, { idAttribute: 'id' });

export const arrayOfCuttingEntries = [cuttingEntry];

export const order = new schema.Entity(
  "orders",
  { prodBoms: [prodBom] },
  { idAttribute: "id" },
);

//export const order = new schema.Entity('orders',{}, { idAttribute: 'id' });

export const arrayOfOrders = [order];

export const arrayOfWages = [wage];

// Define a users schema
export const employee = new schema.Entity(
  "employees",
  { wages: [wage] },
  { idAttribute: "id" },
);

export const arrayOfEmployees = [employee];

export const splitEntry = new schema.Entity(
  "splitEntries",
  {},
  { idAttribute: "id" },
);
export const arrayOfSplitEntries = [splitEntry];

export const depotLocation = new schema.Entity(
  "depotLocations",
  {},
  { idAttribute: "id" },
);

export const arrayOfDepotLocations = [depotLocation];

export const prodDepotLocation = new schema.Entity(
  "prodDepotLocations",
  {},
  { idAttribute: "id" },
);

export const arrayOfProdDepotLocations = [prodDepotLocation];
