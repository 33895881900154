import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ProdDepotEntryDetail from "./components/ProdDepotEntryDetail";
import ProdDepotLocationMain from "./index.js";

class prodDepotLocationRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/prodDepotLocation"
            component={ProdDepotLocationMain}
          />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={ProdDepotEntryDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={ProdDepotEntryDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default prodDepotLocationRoute;
