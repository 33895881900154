import { combineReducers } from "redux";

const openedShipmentId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_SHIPMENT_ID":
      return action.shipmentId;
    default:
      return state;
  }
};

const showShipmentsReportModal = (state = false, action) => {
  switch (action.type) {
    case "SET_SHOW_SHIPMENTS_REPORT_MODAL":
      return action.show;
    default:
      return state;
  }
};

const showShipmentStatusModal = (state = false, action) => {
  switch (action.type) {
    case "SET_SHOW_SHIPMENT_STATUS_MODAL":
      return action.show;
    default:
      return state;
  }
};

const shipmentStatusModalData = (state = {}, action) => {
  switch (action.type) {
    case "SET_SHOW_SHIPMENT_STATUS_MODAL": {
      return { ...state, shipmentId: action.shipmentId };
    }
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedShipmentId,
  showShipmentsReportModal,
  showShipmentStatusModal,
  shipmentStatusModalData,
});
