export const getPayrolls = state => state.data.payrolls;

export const getPayrollEntriesById = state => state.data.payrollEntries.byId;

export const getPayrollAttendanceEntriesById = state =>
  state.data.payrollAttendanceEntries.byId;

export const getPayrollEntriesByEmployeeId = state =>
  state.data.payrollEntries.byEmployeeId;

export const getPayrollAttendanceEntriesByEmployeeId = state =>
  state.data.payrollAttendanceEntries.byEmployeeId;
