import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Row, Upload, message, notification } from "antd";
import axios from "axios";
import AsyncCreatableSelect from "components/AsyncCreatableSelect";
import Select from "components/Select";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import thumbnail from "scenes/placeholder.png";

import { fetchSizes } from "../../../../data/sizes/actions";
import { getAllSizes } from "../../../../data/sizes/reducer";
import { resetSpecForm, setSpecName } from "../../../forms/SpecForm/actions";
import { setOpenedProduct, setShowPrintCodeModal } from "../../actions";
import {
  createProduct,
  fetchProduct,
  updateProduct,
  uploadPicture,
  upsertProductCode,
} from "../../data/products/actions";
import { getOpenedProduct } from "../../selectors";
import CreateSpecDetail from "./components/CreateSpecDetail";
import PrintCodeModal from "./components/PrintCodeModal";
import FormValidator from "./FormValidator";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "article",
        method: "isEmpty",
        validWhen: false,
        message: "Введите артикул",
      },
      {
        field: "article",
        method: () => {
          return this.state.articleExists;
          // return true
        },
        validWhen: false,
        message: "Такой артикул уже есть",
      },
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите название",
      },
      {
        field: "name",
        method: () => {
          return this.state.nameExists;
          // return true
        },
        validWhen: false,
        message: "Такое название уже есть",
      },
      {
        field: "price",
        method: "isEmpty",
        validWhen: false,
        message: "Введите цену",
      },
      {
        field: "estimatedPrice",
        method: "isEmpty",
        validWhen: false,
        message: "Введите рассчётную цену",
      },
      {
        field: "specField",
        method: () => {
          if (this.state.selectedSpec) {
            return false;
          } else {
            return true;
          }
          // return true
        },
        validWhen: false,
        message: "Выберите спецификацию",
      },
      {
        field: "sizeField",
        method: () => {
          if (this.state.selectedSizes.length > 0) {
            return false;
          } else {
            return true;
          }
          // return true
        },
        validWhen: false,
        message: "Выберите хотябы один размер",
      },
    ]);

    this.state = {
      selectedSizes: [],
      isInEditState: true,
      options: [],
      imagePreviewUrl: thumbnail,
      smallImageUrl: thumbnail,
      isCodeInEditState: false,

      printModalKey:1,
      specField: "",
      sizeField: "",
      estimatedPrice: "",

      article: "",
      name: "",
      description: "",
      price: "",
      codes:[],
      articleExists: false,
      nameExists: false,
      isSavingCode: false,

      codeExists: false,
      showSpecModal: false,

      selectedSpec: null,
      loading: false,

      validation: this.validator.valid(),
    };

    this.submitted = false;
    this.codeSubmitted = false;
  }

  fetchProductOrResetForm = () => {
    const { fetchProduct, match, setOpenedProduct } = this.props;

    setOpenedProduct("");

    if (match.params.id) {
      fetchProduct(match.params.id).then(product => {
        setOpenedProduct(match.params.id);
        let specValue = null;
        if (product.spec_id) {
          specValue = {
            value: product.spec.id,
            label: product.spec.name,
            ...product.spec,
          };
        }

        this.setState({
          selectedSizes: product.sizes.map(size => {
            return { value: size.id, label: size.name };
          }),
          //selectedSizes: sizes,
          isInEditState: false,
          imagePreviewUrl: product.img_path,
          smallImageUrl: product.img_small_path,
          article: product.article,
          name: product.name,
          description: product.description != null ? product.description : "",
          price: product.price,
          estimatedPrice: product.estimatedPrice,
          selectedSpec: specValue,
          codes: product.eanCodes,
        });
      });
      this.fetchAndSetSizeOptions();
    } else {
      this.fetchAndSetSizeOptions();
      this.setState({ isInEditState: true });
    }
  };

  fetchAndSetSizeOptions = () => {
    this.props.fetchSizes().then(sizes => {
      let sizeOptions = sizes.map(size => {
        return { value: size.id, label: size.name };
      });
      this.setState({
        options: sizeOptions,
      });
    });
  };

  componentDidMount() {
    this.fetchProductOrResetForm();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.fetchProductOrResetForm();
    }
  }

  handleSizeChange = val => {
    this.setState({ selectedSizes: val });
  };

  handleEstimatedPriceChange = val => {
    this.setState({ estimatedPrice: val });
  };

  onEditStateChange = e => {
    this.setState({ isInEditState: true });
  };

  isThumbnail(img) {
    if (img && img.indexOf("data:image") === 0) {
      return true;
    } else return false;
  }

  createProductFromValues = () => {
    const { imagePreviewUrl, smallImageUrl } = this.state;

    let imgPath = imagePreviewUrl;
    let imgPathSmall = smallImageUrl;

    // Check if thumbnail, if thumbnail we don't need it in the database
    if (this.isThumbnail(imagePreviewUrl)) {
      imgPath = null;
    }

    if (this.isThumbnail(smallImageUrl)) {
      imgPathSmall = null;
    }

    var sizesModel = this.state.selectedSizes.map(size => {
      return { value: size.value, label: size.label };
    });
    var product = {
      article: this.state.article,
      name: this.state.name,
      description: this.state.description,
      sizes: sizesModel,
      img_path: imgPath,
      img_small_path: imgPathSmall,
      price: this.state.price,
      estimatedPrice: this.state.estimatedPrice,
      spec_id: this.state.selectedSpec ? this.state.selectedSpec.value : null,
      codes: this.state.codes,
    };

    return product;
  };

  _handleImageChange = e => {
    e.preventDefault();

    const { uploadPicture } = this.props;
    let file = e.target.files[0];

    if (file) {
      uploadPicture(file, "picture_name").then(json => {
        //let newUrl=json.path.replace("build/", "");
        //let newUrl = json.path
        let newUrl = "/" + json.path;
        let smallUrl = "/" + json.small_path;

        this.setState({
          imagePreviewUrl: newUrl,
          smallImageUrl: smallUrl,
        });
      });
      //https://github.com/mosch/react-avatar-editor/issues/123
    }
  };

  handleArticleChange = event => {
    this.setState({ article: event.target.value });
    this.setState({ articleExists: false });
  };

  // handleCodeChange = event => {
  //   this.setState({ code: event.target.value });
  //   this.setState({ codeExists: false });
  // };

  handleSaveCode = () => {
    // if (this.state.code === "") {
    //   this.setState({ isCodeInEditState: false });
    //   return;
    // }
    const { upsertProductCode, match } = this.props;
    this.setState({ isSavingCode: true });
    this.codeSubmitted = true;
    //const codeValidation = this.codeValidator.validate(this.state);

    //this.setState({ codeValidation });
    try {
      upsertProductCode(match.params.id, this.state.codes).then(
        (data) =>{
          this.setState({ isSavingCode: false, isCodeInEditState: false, codes:data })
          console.log("data", data)
        },
        () => this.setState({ isSavingCode: false }),
      );
    } catch(e) {
      openNotificationWithIcon("Ошибка", e, "error");
      this.setState({ isSavingCode: false });
    }
  };

  handleNameChange = event => {
    this.setState({ name: event.target.value });
    this.setState({ nameExists: false });
  };

  handleDescriptionChange = event => {
    this.setState({ description: event.target.value });
  };

  handlePriceChange = value => {
    if (value == undefined) {
      this.setState({ price: "" });
    } else {
      this.setState({ price: value });
    }
  };

  handleSubmitDispatch = () => {
    const { createProduct, updateProduct, match } = this.props;
    this.submitted = true;
    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
      // handle actual form submission here

      var product = this.createProductFromValues();

      this.setState({ loading: true });

      //Handle update
      if (match.params.id) {
        return updateProduct(match.params.id, product)
          .then(json => {
            this.setState({ loading: false });
            this.setState({ isInEditState: false });
            this.props.history.push(`/product-admin/` + json.id);
          })
          .catch(({ response }) => {
            this.setState({ loading: false });
            let error = response.data;

            if (error.article) {
              this.setState({ articleExists: true });
            }
            if (error.code) {
              this.setState({ codeExists: true });
            }
            if (error.name) {
              this.setState({ nameExists: true });
            }
          });
      }
      //Handle create
      else {
        return createProduct(product)
          .then(json => {
            this.setState({ loading: false });
            this.setState({ isInEditState: false });
            this.props.history.push(`/product-admin/` + json.id);
          })
          .catch(({ response }) => {
            this.setState({ loading: false });
            let error = response.data;

            if (error.article) {
              this.setState({ articleExists: true });
            }
            if (error.code) {
              this.setState({ codeExists: true });
            }
            if (error.name) {
              this.setState({ nameExists: true });
            }
          });
      }
    }
  };

  getSpecOptions = function(input, callback) {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/specs/search?q=${input}`).then(({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  handleNewOptionClick = value => {
    const { setSpecName, resetSpecForm } = this.props;
    resetSpecForm();
    setSpecName(value);
    this.setState({ showSpecModal: true });
  };

  handleCreateOptionSuccess = spec => {
    let specValue = { label: spec.name, value: spec.id, ...spec };
    this.setState({ selectedSpec: specValue });
    this.setState({ showSpecModal: false });
  };

  handleClose = () => {
    this.setState({ showSpecModal: false });
  };

  handleSpecChange = (value, actionMeta) => {
    this.setState({ selectedSpec: value });
  };

  addDefaultSrc(ev) {
    ev.target.src = thumbnail;
  }

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state


    const hasAccessToEditModel = this.props.user
      ? this.props.user.roles
          .map(role => role.id)
          .filter(roleId => [1, 2, 3].includes(roleId)).length > 0
      : false;

    const { imagePreviewUrl } = this.state;

    const imgStyle = {
      // width: '20vw',
      // height: '20vw',
      width: "200px",
      height: "200px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "grey",
      // display:'flex',
      // justifyContent:'space-between',
    };

    const imgStyle2 = {
      width: "auto",
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      display: "block",
      margin: "0 auto",
      //position: 'absolute',
      //top: 0; bottom: 0; left: 0; right: 0;
    };

    const { product, boms, match, dispatch, form } = this.props;

    let self = this;

    const props = {
      name: "file",
      action: "/api/products/files",
      showUploadList: false,
      headers: {
        authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          //console.log(info.file, info.fileList);
          // let newUrl = '/' + json.path
          // let smallUrl = '/' + json.small_path
          // this.setState({
          //     imagePreviewUrl: newUrl,
          //     smallImageUrl: smallUrl
          // });
        }
        if (info.file.status === "done") {
          let newUrl = "/" + info.file.response.path;
          let smallUrl = "/" + info.file.response.small_path;

          self.setState({
            imagePreviewUrl: newUrl,
            smallImageUrl: smallUrl,
          });
          message.success(`${info.file.name} Файл успешно загружен`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} Не удалось загрузить файл`);
        }
      },
    };

    let ean13Pack=null
    if(this.state.codes.find(e=>e.single==false)){
      ean13Pack=this.state.codes.find(e=>e.single==false).ean13
    }
    let ean13Single=null
    if(this.state.codes.find(e=>e.single==true)){
      ean13Single=this.state.codes.find(e=>e.single==true).ean13
    }
    //let eanCodePack=this.state.codes.find(e=>e.single==false)
   // console.log("ean code", eanCodePack)

    return (
      <div className="mt-paper">
        <div className="row">
          <div className="col-xs-12">
            <PrintCodeModal
              codes={this.state.codes}
              article={this.props.product ? this.props.product.article : null}
              sizes={this.state.selectedSizes}
              key={this.state.printModalKey}
            />
            <h2>Модель</h2>
            <p />
            <span>
              {match.params.id && this.state.isInEditState && (
                <Button
                  type="primary"
                  onClick={this.handleSubmitDispatch}
                  loading={this.state.loading}
                  style={{ marginRight: "15px" }}
                >
                  Сохранить
                </Button>
              )}
              {match.params.id == undefined && (
                <Button
                  type="primary"
                  onClick={this.handleSubmitDispatch}
                  loading={this.state.loading}
                >
                  Создать
                </Button>
              )}
              {match.params.id &&
                !this.state.isInEditState &&
                hasAccessToEditModel && (
                  <Button
                    onClick={this.onEditStateChange}
                    style={{ marginRight: "15px" }}
                  >
                    Изменить
                  </Button>
                )}
              {match.params.id != undefined && (

                <div
                  style={{ marginRight: 15, display: "inline" }}

                >
                  <Input
                    name="code"
                    placeholder="Штрих код"
                    value={ean13Pack}
                    disabled={!this.state.isCodeInEditState}
                    style={{ width: "140px" }}
                  />
                  &nbsp;
                  <Input
                      name="code"
                      placeholder="Штрих код"
                      value={ean13Single}
                      disabled={!this.state.isCodeInEditState}
                      style={{ width: "140px" }}
                  />
                </div>

              )}
              {match.params.id != undefined && !ean13Pack && (
                <span>
                  <Button
                    onClick={this.handleSaveCode}
                    type={"primary"}
                    loading={this.state.isSavingCode}
                  >
                    Сгенерировать EAN
                  </Button>
                  {/*<Button*/}
                  {/*  onClick={() =>*/}
                  {/*    this.handleCodeChange({*/}
                  {/*      target: {*/}
                  {/*        value:*/}
                  {/*          "55" +*/}
                  {/*          Math.floor(Math.random() * 1000000000000)*/}
                  {/*            .toString()*/}
                  {/*            .padStart(12, "0"),*/}
                  {/*      },*/}
                  {/*    })*/}
                  {/*  }*/}
                  {/*  style={{ marginLeft: "15px" }}*/}
                  {/*>*/}
                  {/*  Сгенерировать штрих-код*/}
                  {/*</Button>*/}
                </span>
              )}

              {match.params.id != undefined && !this.state.isCodeInEditState && (
                <span>

                  {ean13Pack && (
                    <Button
                      onClick={() =>{
                        this.props.setShowPrintCodeModal(true)
                        this.setState((prevState, props) => ({
                          printModalKey: prevState.printModalKey + 1
                        }));
                      }}
                    >
                      Распечатать
                    </Button>
                  )}
                </span>
              )}

            </span>
            <br />
            &nbsp;
            <Row gutter={16}>
              <Col xs={24} sm={16}>
                <div
                  style={{ marginBottom: 15 }}
                  className={validation.article.isInvalid ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="article">
                    Артикул
                  </label>
                  <Input
                    name="article"
                    placeholder="Артикул"
                    value={this.state.article}
                    onChange={this.handleArticleChange}
                    disabled={!this.state.isInEditState}
                  />
                  <span className="help-block">
                    {validation.article.message}
                  </span>
                </div>

                <div
                  style={{ marginBottom: 15 }}
                  className={validation.name.isInvalid ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="name">
                    Название
                  </label>
                  <Input
                    name="name"
                    placeholder="Название"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    disabled={!this.state.isInEditState}
                  />
                  <span className="help-block">{validation.name.message}</span>
                </div>

                <div style={{ marginBottom: 15 }}>
                  <label className="mt-label" htmlFor="description">
                    Описание
                  </label>
                  <Input
                    name="description"
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                    disabled={!this.state.isInEditState}
                    placeholder="Описание"
                  />
                </div>

                <div
                  style={{ marginBottom: 15 }}
                  className={validation.price.isInvalid ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="price">
                    Цена
                  </label>
                  <InputNumber
                    name="price"
                    placeholder="Цена"
                    style={{ width: "100%" }}
                    value={this.state.price}
                    onChange={this.handlePriceChange}
                    disabled={!this.state.isInEditState}
                  />
                  <span className="help-block">{validation.price.message}</span>
                </div>

                <div style={{ marginBottom: 15 }}>
                  <label className="mt-label" htmlFor="sizes">
                    Размеры
                  </label>
                  <Select
                    isDisabled={!this.state.isInEditState}
                    name="sizes"
                    isMulti
                    value={this.state.selectedSizes}
                    options={this.state.options}
                    onChange={this.handleSizeChange}
                    placeholder={"Выберите размеры"}
                    noOptionsMessage={() => {
                      return "Печатайте для поиска...";
                    }}
                  />
                  <span className="help-block">
                    {validation.sizeField.message}
                  </span>
                </div>
                <div style={{ marginBottom: 15 }}>
                  <label className="mt-label" htmlFor="sizes">
                    Спецификация
                  </label>
                  <AsyncCreatableSelect
                    isDisabled={!this.state.isInEditState}
                    onChange={this.handleSpecChange}
                    loadOptions={this.getSpecOptions}
                    onCreateOption={this.handleNewOptionClick}
                    value={this.state.selectedSpec}
                    formatCreateLabel={label => {
                      return "Создать спецификацию: " + label;
                    }}
                    noOptionsMessage={() => {
                      return "Печатайте для поиска...";
                    }}
                  />
                  <span className="help-block">
                    {validation.specField.message}
                  </span>
                </div>
                <div style={{ marginBottom: 15 }}>
                  <label className="mt-label" htmlFor="estimatedPrice">
                    Рассчётная цена
                  </label>
                  <InputNumber
                      name="estimatedPrice"
                      placeholder="Рассчётная цена"
                      style={{ width: "100%" }}
                      value={this.state.estimatedPrice}
                      onChange={this.handleEstimatedPriceChange}
                      precision={2}
                      disabled={!this.state.isInEditState}
                  />
                  <span className="help-block">{validation.estimatedPrice.message}</span>
                </div>
                <div />
              </Col>
              <Col xs={24} sm={8}>
                <div style={imgStyle}>
                  {/*<Image  style={imgStyle2} src={imagePreviewUrl} thumbnail />*/}

                  <img
                    onError={this.addDefaultSrc}
                    alt="фото"
                    style={imgStyle2}
                    src={imagePreviewUrl ? imagePreviewUrl : thumbnail}
                  />
                </div>
                &nbsp;
                {this.state.isInEditState && (
                  <div>
                    {/* <label className="btn" htmlFor="files" >Выберите фото</label>
                                        <input id="files" style={{ visibility: "hidden" }} type="file" onChange={this._handleImageChange} /> */}

                    {/* <input
                                            accept="image/*"
                                            className={classes.input}
                                            id="raised-button-file"
                                            multiple
                                            type="file"
                                            onChange={this._handleImageChange}
                                        />
                                        <label htmlFor="raised-button-file">
                                            <Button variant="raised" component="span" className={classes.button}>
                                                Upload
                                        </Button>
                                        </label> */}
                    <Upload {...props}>
                      <Button>
                        <UploadOutlined /> Загрузить
                      </Button>
                    </Upload>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
        <br />

        <CreateSpecDetail
          onSaveSuccess={this.handleCreateOptionSuccess}
          onClose={this.handleClose}
          showModal={this.state.showSpecModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.data.users.user.user,
    product: getOpenedProduct(state),
    availableSizes: getAllSizes(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchProduct: (id, callback) => dispatch(fetchProduct(id, callback)),
    upsertProductCode: (productId, code) =>
      dispatch(upsertProductCode(productId, code)),
    createProduct: product => dispatch(createProduct(product)),
    setOpenedProduct: id => dispatch(setOpenedProduct(id)),
    updateProduct: (productId, product) =>
      dispatch(updateProduct(productId, product)),
    fetchSizes: () => dispatch(fetchSizes()),
    uploadPicture: (file, name) => dispatch(uploadPicture(file, name)),
    resetSpecForm: () => dispatch(resetSpecForm()),
    setSpecName: name => dispatch(setSpecName(name)),
    setShowPrintCodeModal: show => dispatch(setShowPrintCodeModal(show)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProductDetail),
);
