import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Log from "./index";

class LogRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/logs" component={Log} />
          <Route path={"/logs/:filter"} component={Log} />
        </Switch>
      </div>
    );
  }
}

export default LogRoute;
