import { InputNumber, Table } from "antd";
import React from "react";

export function TableOfSewedProducts(props) {
  const { openedOrder, values, disabled, setFieldValue, data } = props;

  return (
    <Table
      bordered={true}
      size={"small"}
      pagination={false}
      columns={[
        ...openedOrder.lineItems.map((lineItem, lineItemIndex) => ({
          title: lineItem.size.name,
          key: "sizeName" + lineItemIndex,
          align: "center",
          width: 70,
          render: (value, record, index) => {
            return (
              <InputNumber
                precision={0}
                className={"cleanInputNumber"}
                value={
                  values.lineItems.find(
                    item => item.sizeId === lineItem.size.id,
                  ).quantity
                }
                min={0}
                disabled={disabled}
                onChange={e => {
                  setFieldValue(
                    "lineItems",
                    values.lineItems.map(newLineItem => {
                      if (newLineItem.sizeId === lineItem.size.id) {
                        return {
                          ...newLineItem,
                          quantity: e,
                        };
                      } else return newLineItem;
                    }),
                  );
                }}
              />
            );
          },
        })),
        {
          title: "Всего сшито",
          key: "lineItemsSum",
          align: "center",
          width: 70,
          render(value, record, index) {
            return record.lineItems.reduce(
              (acc, curr) => acc + curr.quantity,
              0,
            );
          },
        },
      ]}
      dataSource={data}
    />
  );
}
