import Icon from '@ant-design/icons';
import { Popconfirm, Table } from "antd";
import { nanoid } from "nanoid";
import React from "react";

const warningSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="warning"
    width="1em"
    height="1em"
    fill="currentColor"
    color={"red"}
    aria-hidden="true"
  >
    <path d="M464 720a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z" />
  </svg>
);

const { NEW, EDIT, NORMAL } = {
  NEW: "NEW",
  EDIT: "EDIT",
  NORMAL: "NORMAL",
};

export function SewingOperationsTable(props) {
  const {
    setShowAddOperationModal,
    prodBomIndex,
    setIsDeletingOperation,
    isDeletingOperation,
    deleteOperation,
    setShowSearchEmployeeModal,
    form: { prodBom, errors, touched, values, setFieldValue, status },
    data,
  } = props;

  return (
    <Table
      size={"small"}
      pagination={false}
      bordered
      rowKey={"key"}
      locale={{ emptyText: "Список операций пуст" }}
      rowClassName={(record, index) =>
        errors &&
        errors.prodBoms &&
        errors.prodBoms[prodBomIndex] &&
        errors.prodBoms[prodBomIndex] &&
        errors.prodBoms[prodBomIndex][index] &&
        touched &&
        touched.prodBoms &&
        touched.prodBoms[prodBomIndex] &&
        touched.prodBoms[prodBomIndex] &&
        touched.prodBoms[prodBomIndex][index]
          ? "errorBackground"
          : ""
      }
      columns={[
        {
          title:
            status === NORMAL ? (
              "Операция"
            ) : (
              <div>
                <span>Операция</span>
                <span
                  style={{
                    float: "right",
                  }}
                  onClick={() =>
                    setShowAddOperationModal(true, prodBomIndex, prodBom.id)
                  }
                >
                  <a style={{ color: "green" }}>+</a>
                </span>
              </div>
            ),
          dataIndex: "prodOperation",
          key: "prodOperation",
          width: 300,
          render: (value, record, index) => {
            if (status === NORMAL) return value;
            if (status === NEW || status === EDIT) {
              const reducer = (acc, currOperation) =>
                acc + (currOperation.operation.id === record.operation.id);

              const copyRow = () => {
                const operationsWithExtraRow = [
                  ...values.prodBoms[prodBomIndex],
                ];
                operationsWithExtraRow.splice(index, 0, {
                  ...values.prodBoms[prodBomIndex][index],
                  key: nanoid(5),
                  employee: null,
                  quantity: 0,
                });
                setFieldValue(
                  `prodBoms[${prodBomIndex}]`,
                  operationsWithExtraRow,
                );
              };

              const deleteRow = () => {
                const operationsWithExtraRow = [
                  ...values.prodBoms[prodBomIndex],
                ];
                operationsWithExtraRow.splice(index, 1);
                setFieldValue(
                  `prodBoms[${prodBomIndex}]`,
                  operationsWithExtraRow,
                );
              };

              const handleDeleteOperation = prodOperationId => {
                setIsDeletingOperation(true);
                deleteOperation(prodBom.id, prodOperationId)
                  .then(() => {
                    setFieldValue(`prodBoms[${prodBomIndex}]`, [
                      ...values.prodBoms[prodBomIndex].filter(
                        op => op.operation.id !== prodOperationId,
                      ),
                    ]);
                    setIsDeletingOperation(false);
                  })
                  .catch(() => setIsDeletingOperation(false));
              };

              return {
                children: (
                  <span>
                    <span>{value}</span>
                    <span style={{ float: "right" }}>
                      <span>
                        <a onClick={copyRow} style={{ color: "green" }}>
                          +
                        </a>
                      </span>
                      &nbsp;
                      {values.prodBoms[prodBomIndex].reduce(reducer, 0) > 1 ? (
                        <span>
                          <a onClick={deleteRow} style={{ color: "red" }}>
                            -
                          </a>
                        </span>
                      ) : (
                        <span>
                          <Popconfirm
                            icon={<Icon component={warningSvg} />}
                            title={
                              <span>
                                Это последняя операция данного типа и её
                                удаление необратимо. Эта операция удалится из
                                всех настилов этой части.
                                <br />
                                Вы уверены, что хотите удалить?
                              </span>
                            }
                            onConfirm={() =>
                              handleDeleteOperation(record.operation.id)
                            }
                            okButtonProps={{
                              loading: isDeletingOperation,
                            }}
                            okText={"Да"}
                            cancelText={"Отмена"}
                          >
                            <a style={{ color: "red" }}>-</a>
                          </Popconfirm>
                        </span>
                      )}
                    </span>
                  </span>
                ),
              };
            }
          },
        },
        {
          title: "Сотрудник",
          dataIndex: "employee",
          align: "center",
          key: "employee",
          render: (value, record, index) => {
            let periodMark = "";
            if (
              record.period &&
              values.period &&
              record.period.id !== values.period.id
            ) {
              periodMark = `[П: ${record.period.name}]`;
            }
            if (status === NORMAL) {
              return value ? (
                `${value.first_name} ${value.middle_name} ${
                  value.last_name
                } ${periodMark}`
              ) : (
                <span style={{ color: "red" }}>
                  <Icon component={warningSvg} />
                  &nbsp; Необходимо указать сотрудника
                </span>
              );
            }
            if (status === NEW || status === EDIT) {
              return value === null ? (
                <div
                  onClick={() =>
                    setShowSearchEmployeeModal(true, prodBomIndex, index)
                  }
                >
                  <a>Указать</a>
                </div>
              ) : (
                <span>
                  {`${value.first_name} ${value.middle_name} ${
                    value.last_name
                  } ${periodMark}`}
                  <span
                    onClick={() => {
                      setFieldValue(
                        `prodBoms[${prodBomIndex}][${index}].employee`,
                        null,
                      );
                      setFieldValue(
                        `prodBoms[${prodBomIndex}][${index}].quantity`,
                        0,
                      );
                    }}
                    style={{
                      float: "right",
                    }}
                  >
                    <a style={{ color: "red" }}>X</a>
                  </span>
                </span>
              );
            }
          },
        },
        {
          title: "Количество",
          dataIndex: "quantity",
          key: "quantity",
          align: "center",
          width: 150,
          render: (value, record, index) => {
            return values.prodBoms[prodBomIndex][index].quantity;
          },
        },
      ]}
      dataSource={data}
    />
  );
}
