import "./index.css";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Input } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { setOpenedPattern, setShowPatternModal } from "../../actions";
import { addPattern, savePattern } from "../../data/patterns/actions";
import { getOpenedPattern } from "../../selectors";

//import Button from 'material-ui/Button';

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "400px",
      },
      root: {
        //alignItems: "initial"
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class PatternDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      activeTab: 1,
    };
  }
  close = () => {
    this.props.setOpenedPattern("");
    this.props.setShowPatternModal(false);
  };

  create = () => {
    const { addPattern, selectedSpreadId } = this.props;
    //this.props.onNastilDetailCreate({ name: this.state.name, material: this.state.selectedMaterial });
    let pattern = {
      name: this.state.name,
    };

    addPattern(pattern, selectedSpreadId);
    this.props.setShowPatternModal(false);
  };

  handleUpdate = () => {
    const { savePattern, selectedSpreadId, openedPattern } = this.props;
    let pattern = {
      id: openedPattern.id,
      name: this.state.name,
    };

    savePattern(pattern);
    this.props.setShowPatternModal(false);
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    //You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.openedPattern) {
      if (nextProps.openedPattern !== this.props.openedPattern) {
        this.setState({
          name: nextProps.openedPattern.name,
        });
      }
    } else {
      this.setState({
        name: "",
      });
    }
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  render() {
    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    const { showModal, openedPattern } = this.props;
    //coming from HOC
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Деталь</DialogTitle>
            <DialogContent>
              {/* <div className="form-inline">
                                <div className="form-group">
                                    <label htmlFor="name"> Название детали:</label>&nbsp;&nbsp;
                                    <input placeholder="Введите название" value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="name" />
                                </div>
                            </div> */}

              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="article">
                  Название детали
                </label>
                <Input
                  name="article"
                  placeholder="Деталь"
                  //onChange={this.handleInputChange}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  //disabled={!this.state.isInEditState}
                />
              </div>

              <p />

              <p />
            </DialogContent>
            <DialogActions>
              {openedPattern == undefined && (
                <Button
                  type="primary"
                  disabled={this.state.name.length <= 0}
                  onClick={this.create}
                >
                  Создать
                </Button>
              )}
              {openedPattern != undefined && (
                <Button
                  type="primary"
                  disabled={this.state.name.length <= 0}
                  onClick={this.handleUpdate}
                >
                  Сохранить
                </Button>
              )}

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

PatternDetail.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

//export default withMobileDialog()(SpreadDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //selectedSpreadId: state.scenes.product.selectedSpread,
    selectedSpreadId: state.scenes.forms.specform.selectedSpread,
    showModal: state.scenes.forms.specform.showPatternModal,
    openedPattern: getOpenedPattern(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowPatternModal: showModal => dispatch(setShowPatternModal(showModal)),
    addPattern: (pattern, spreadId) => dispatch(addPattern(pattern, spreadId)),
    savePattern: pattern => dispatch(savePattern(pattern)),
    setOpenedPattern: patternId => dispatch(setOpenedPattern(patternId)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PatternDetail),
);
