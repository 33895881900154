import { normalize, schema } from "normalizr";

// Define a users schema

export const prodDepotEntry = new schema.Entity(
  "prodDepotEntries",
  {},
  { idAttribute: "id" },
);

export const arrayOfProdDepotEntries = [prodDepotEntry];
