import { Breadcrumb, Button, Dropdown, Layout, Menu } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchDepotLocations } from "../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../data/depotLocations/reducer";
import {
  deleteFabDepotEntry,
  fetchFabDepotEntries,
} from "../../data/fabDepotEntries/actions";
import { getAllFabDepotEntries } from "../../data/fabDepotEntries/reducer";
import { fetchTeams } from "../../data/teams/actions";
import FabDepotEntryTable from "./components/FabDepotEntryTable";

function FabDepotMain(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    fabDepotEntries,
    fetchFabDepotEntries,
    fetchDepotLocations,
    deleteFabDepotEntry,
  } = props;

  const [loading, setLoading] = useState(() => {
    if (fabDepotEntries.length > 0) {
      return false;
    } else return true;
  });
  const [searchEnabled, setSearchEnabled] = useState(false);
  //const [fabDepotEntries, setFabDepotEntries] = useState(0);
  const [count, setCount] = useState(0);
  const [depotLocationOptions, setDepotLocationOptions] = useState([]);

  const onFabDepotEntryCreate = () => {
    history.push(`/fabdepot/new`);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/fabdepot/` + contractor.id);
  };

  const onRemove = value => {
    // const {deleteEmployee} =props;
    deleteFabDepotEntry(value.id);
  };

  useEffect(() => {
    fetchFabDepotEntries().then(depot => {
      setLoading(false);
    });

    fetchDepotLocations().then(options => {
      setDepotLocationOptions(options);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Приходы</h2>
        {/* {console.log(fabDepotEntries)} */}
        <Button type="primary" onClick={onFabDepotEntryCreate}>
          Создать
        </Button>
        &nbsp;
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => history.push("/logs/fabDepotEntry")}>
                Посмотреть в журнале
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={{ marginLeft: "0.5em" }}>...</Button>
        </Dropdown>
      </div>
      {console.log(fabDepotEntries)}
      <br />
      <FabDepotEntryTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        fabDepotEntries={fabDepotEntries}
        depotLocationOptions={depotLocationOptions}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    fabDepotEntries: getAllFabDepotEntries(state),
    depotLocations: getAllDepotLocations(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchFabDepotEntries: () => dispatch(fetchFabDepotEntries()),
    // fetchTeams: ()=>dispatch(fetchTeams()),
    deleteFabDepotEntry: id => dispatch(deleteFabDepotEntry(id)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabDepotMain),
);

//export default ContractorMain
