import * as Sentry from "@sentry/browser";
import React, { Component } from "react";

export class SentryErrorBoundary extends Component {
  state = { error: null, eventId: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);

      this.setState({ eventId: Sentry.captureException(error) });
    });
  }

  render() {
    const { children } = this.props;
    const { error, eventId } = this.state;

    if (!error) {
      return children;
    }

    // TODO: Implement error page with feedback button.
    return (
      <button
        type="button"
        onClick={() => {
          Sentry.showReportDialog({ eventId });
        }}
      >
        Report feedback
      </button>
    );
  }
}
