import Icon from '@ant-design/icons';
import { Button, Popconfirm, Select } from "antd";
import matchSorter from "match-sorter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

const Option = Select.Option;

class EmployeeTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  airTableSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 65 65"
    >
      <path
        d="M28.578 5.906L4.717 15.78c-1.327.55-1.313 2.434.022 2.963l23.96 9.502a8.89 8.89 0 0 0 6.555 0l23.96-9.502c1.335-.53 1.35-2.414.022-2.963l-23.86-9.873a8.89 8.89 0 0 0-6.799 0"
        fill="#fc0"
      />
      <path
        d="M34.103 33.433V57.17a1.6 1.6 0 0 0 2.188 1.486l26.7-10.364A1.6 1.6 0 0 0 64 46.806V23.07a1.6 1.6 0 0 0-2.188-1.486l-26.7 10.364a1.6 1.6 0 0 0-1.009 1.486"
        fill="#31c2f2"
      />
      <path
        d="M27.87 34.658l-8.728 4.215-16.727 8.015c-1.06.512-2.414-.26-2.414-1.44V23.17c0-.426.218-.794.512-1.07a1.82 1.82 0 0 1 .405-.304c.4-.24.97-.304 1.455-.112l25.365 10.05c1.3.512 1.4 2.318.133 2.925"
        fill="#ed3049"
      />
      <path
        d="M27.87 34.658l-7.924 3.826L.512 22.098a1.82 1.82 0 0 1 .405-.304c.4-.24.97-.304 1.455-.112l25.365 10.05c1.3.512 1.4 2.318.133 2.925"
        fill="#c62842"
      />
    </svg>
  );

  render() {
    const {
      employees,
      onEditClick,
      onRemove,
      classes,
      loading,
      teamOptions,
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          // <div>
          //     <Button type="primary" size="small" icon="edit" onClick={(e => onEditClick(row.original, e))}  >
          //         {/* <OpenInNew classes={{ root: classes.iconRoot }} />
          //         <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
          //     </Button>
          // </div>

          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/employee/" + row.original.id}
          >
            {"EMP-" + row.value}
          </a>
        ),
      },
      {
        Header: "Фамилия",
        accessor: "last_name",
        id: "last_name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["last_name"] }),
        filterAll: true,
        Cell: row =>
          row.original.isActive == 1 ? (
            <div>{row.value}</div>
          ) : (
            <strike>{row.value}</strike>
          ),
      },
      {
        Header: "Имя",
        accessor: "first_name", // String-based value accessors!
        id: "first_name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["first_name"] }),
        filterAll: true,
        Cell: row =>
          row.original.isActive == 1 ? (
            <div>{row.value}</div>
          ) : (
            <strike>{row.value}</strike>
          ),
      },
      {
        Header: "Отчество",
        accessor: "middle_name",
        id: "middle_name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["middle_name"] }),
        filterAll: true,
        Cell: row =>
          row.original.isActive == 1 ? (
            <div>{row.value}</div>
          ) : (
            <strike>{row.value}</strike>
          ),
      },
      {
        Header: "Оклад",
        id: "salary",
        accessor: "salary",
        filterable: false,
        sortable: false,
      },
      {
        Header: "Бригада",
        accessor: "team.name",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <Select
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={value => onChange(value)}
          >
            <Option key={"all"} value="all">
              Все
            </Option>
            {teamOptions &&
              teamOptions.map(team => (
                <Option key={team.name} value={team.name}>
                  {team.name}
                </Option>
              ))}
          </Select>
        ),
        sortable: false,
      },
      {
        Header: "Удалить",
        filterable: false,
        //accessor: 'product.name',
        maxWidth: 100,
        Cell: row => (
          // <div>
          //     {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >

          //     </Button> */}
          //     <Popconfirm placement="topRight" title="Уверены что хотите удалить эту запись?"
          //         onConfirm={(e) => this.handleDeleteConfirm(row.original, e)}
          //         onCancel={this.handleDeleteCancel} okText="Да" cancelText="Нет">
          //     <Button type="danger" size="small" icon="delete">
          //     </Button>
          //     </Popconfirm>
          // </div>

          <div>
            <Popconfirm
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => this.handleDeleteConfirm(row.original, e)}
              onCancel={this.handleDeleteCancel}
              okText="Да"
              cancelText="Нет"
            >
              <a href="javascript:;">Удалить</a>
            </Popconfirm>
          </div>
        ),
        sortable: false,
      },
    ];

    if (
      this.props.domainSettings.find(setting => setting.id === 2).value ===
      "true"
    ) {
      columns.push({
        Header: "AirTable",
        accessor: "airTable",
        maxWidth: 70,
        Cell: row => {
          const icon = row.original.airTable ? (
            <Icon component={this.airTableSvg} />
          ) : (
            <Icon
              style={{ filter: "grayscale(100%)" }}
              component={this.airTableSvg}
            />
          );

          return <div style={{ textAlign: "center" }}>{icon}</div>;
        },
        id: "airTableSynchronization",
        filterable: false,
      });
    }

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={loading ? [] : employees}
          getTdProps={(state, rowInfo, column) => {
            //console.log(column);
            if (loading) {
              return {
                className: "pt-skeleton",
              };
            } else {
              return {};
            }
          }}
          columns={columns}
          defaultPageSize={50}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText={loading ? "" : "Нет записей"}
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

EmployeeTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(EmployeeTable);
