//import Button from 'material-ui/Button';

import "../print.css";

import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Col, Divider, InputNumber, Modal, Row, Table } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { setShowCuttingPassportModal } from "../../../actions";
import {
  getOpenedCuttingOrder,
  getProdBomsByOpenedOrderWithSpreadsPatternsEntries,
} from "../../../selectors";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: "800px",
      },
      root: {
        alignItems: "initial",
        zIndex: 1000,
        height: "100%",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class CuttingPassportModal extends Component {
  constructor(props) {
    super(props);
  }

  close = () => {
    this.props.setShowCuttingPassportModal(false);
  };

  render() {
    const {
      showModal,
      fullScreen,
      openedOrder,
      prodBoms,
      productionProcessType_id,
    } = this.props;
    const passportComponent = (
      <>
        <Row>
          <Col span={8}>Заказ: PR-{openedOrder.id}</Col>
        </Row>
        <Row>Модель: {openedOrder.product.name}</Row>
        <Row>
          Список частей:{" "}
          {prodBoms.map(
            (prodBom, index) =>
              prodBom.name + (index === prodBoms.length - 1 ? "" : ", "),
          )}
        </Row>
        <h4>Заказано</h4>
        <Table
          bordered={true}
          size={"small"}
          pagination={false}
          rowClassName={"noHighlight"}
          columns={[
            ...openedOrder.lineItems.map((lineItem, lineItemIndex) => ({
              title: lineItem.size.name,
              key: "sizeName" + lineItemIndex,
              align: "center",
              width: 70,
              render: (value, record, index) => {
                return {
                  props: {
                    style: {
                      height: "25px",
                      padding: 0,
                    },
                  },
                  children: (
                    <InputNumber
                      precision={0}
                      className={"cleanInputNumber"}
                      value={
                        record.lineItems.find(
                          item => item.size.id === lineItem.size.id,
                        ).quantity
                      }
                      min={0}
                      disabled={true}
                    />
                  ),
                };
              },
            })),
          ]}
          dataSource={[{ key: 1, lineItems: openedOrder.lineItems }]}
        />
        <Divider />
        {prodBoms.map((prodBom, prodBomIndex) => (
          <div key={prodBomIndex}>
            <Row>
              <h3>Часть: {prodBom.name}</h3>
            </Row>
            {prodBom.prodSpreads
              .filter(prodSpread => prodSpread.printOperations)
              .map((prodSpread, prodSpreadIndex) => (
                <div key={prodSpreadIndex}>
                  <h4>Расход материала:</h4>
                  <Table
                    size={"small"}
                    pagination={false}
                    bordered
                    rowClassName={"noHighlight"}
                    columns={[
                      {
                        title: "Материал",
                        dataIndex: "fabric",
                        key: "fabric",
                        width: 450,
                        render(text, record) {
                          return {
                            props: {
                              style: { height: "25px" },
                            },
                          };
                        },
                      },
                      ...openedOrder.lineItems.map(
                        (lineItem, lineItemIndex) => ({
                          title: lineItem.size.name,
                          dataIndex: "lineItems",
                          key: "sizeName" + lineItemIndex,
                          align: "center",
                          width: 80,
                          render(text, record) {
                            return {
                              props: {
                                style: { height: "25px" },
                              },
                            };
                          },
                        }),
                      ),
                      {
                        title: "Всего скроено",
                        key: "lineItemsSum",
                        align: "center",
                        width: 90,
                        render(text, record) {
                          return {
                            props: {
                              style: { height: "25px" },
                            },
                          };
                        },
                      },
                      {
                        title: "Вес",
                        dataIndex: "weight",
                        key: "weight",
                        width: 200,
                        render(text, record) {
                          return {
                            props: {
                              style: { height: "25px" },
                            },
                          };
                        },
                      },
                      {
                        title: "Склад",
                        dataIndex: "depotLocation",
                        key: "depotLocation",
                        width: 250,
                        render(text, record) {
                          return {
                            props: {
                              style: { height: "25px" },
                            },
                          };
                        },
                      },
                    ]}
                    dataSource={[{}, {}]}
                  />
                  {productionProcessType_id ===
                    ProductionProcessTypes.id.PASSPORT && (
                    <>
                      <Row>
                        <h4>Настил: {prodSpread.name}</h4>
                      </Row>
                      <Table
                        size={"small"}
                        pagination={false}
                        // bordered
                        rowClassName={"noHighlight"}
                        columns={[
                          {
                            title: "Операция",
                            dataIndex: "prodOperation",
                            key: "prodOperation",
                            width: 300,
                            render(value, record, index) {
                              return {
                                props: {
                                  style: { height: "10px", padding: 0 },
                                },
                                children: `${value}`,
                              };
                            },
                          },
                          {
                            title: "Сотрудник",
                            dataIndex: "employee",
                            key: "",
                            render(value, record, index) {
                              return {
                                props: {
                                  style: { height: "10px", padding: 0 },
                                },
                              };
                            },
                          },
                          {
                            title: "Количество",
                            dataIndex: "quantity",
                            key: "",
                            width: 150,
                            render(value, record, index) {
                              return {
                                props: {
                                  style: { height: "10px", padding: 0 },
                                },
                              };
                            },
                          },
                        ]}
                        dataSource={prodBom.prodOperations
                          .filter(operation => operation.operationType_id === 1)
                          .map(operation => ({
                            prodOperation: operation.operation,
                            quantity: "",
                            employee: "",
                          }))}
                      />
                    </>
                  )}
                </div>
              ))}
            {prodBomIndex !== prodBoms.length - 1 && <Divider />}
          </div>
        ))}
      </>
    );
    return (
      <div>
        {showModal && (
          <div id="toPrint">
            <h2>Раскройный паспорт</h2>
            {passportComponent}
          </div>
        )}
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            scroll={"paper"}
            open={showModal}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Паспорт раскроя
            </DialogTitle>
            <DialogContent>
              <div>{passportComponent}</div>
            </DialogContent>
            <DialogActions>
              <Button type={"primary"} onClick={window.print}>
                Распечатать
              </Button>
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

// CuttingPassportModal.propTypes = {
//     fullScreen: PropTypes.bool.isRequired,
// };

const mapStateToProps = (state, ownProps) => {
  return {
    showModal: state.scenes.cutting.showCuttingPassportModal,
    prodBoms: getProdBomsByOpenedOrderWithSpreadsPatternsEntries(state),
    openedOrder: getOpenedCuttingOrder(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowCuttingPassportModal: showModal =>
      dispatch(setShowCuttingPassportModal(showModal)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CuttingPassportModal),
);
