import { combineReducers } from "redux";

import {
  FABRIC_RETURNS_FETCH_SUCCESS,
  FABRIC_RETURN_CREATE_SUCCESS,
  FABRIC_RETURN_DELETE_SUCCESS,
  FABRIC_RETURN_FETCH_SUCCESS,
  FABRIC_RETURN_UPDATE_SUCCESS,
} from "./actions";

export const all = (state = [], action) => {
  switch (action.type) {
    case FABRIC_RETURN_CREATE_SUCCESS: {
      return [...state, action.response];
    }
    case FABRIC_RETURN_FETCH_SUCCESS: {
      return state.map(fabricReturn =>
        fabricReturn.id === action.fabricReturnId ? action.response : fabricReturn,
      );
    }
    case FABRIC_RETURN_UPDATE_SUCCESS: {
      return state.map(fabricReturn =>
        fabricReturn.id === action.fabricReturnId ? action.response : fabricReturn,
      );
    }
    case FABRIC_RETURNS_FETCH_SUCCESS: {
      return action.response;
    }
    case FABRIC_RETURN_DELETE_SUCCESS: {
      return state.filter(fabricReturn => fabricReturn.id !== action.fabricReturnId);
    }
    default:
      return state;
  }
};

export default combineReducers({
  all,
});
