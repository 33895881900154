import { combineReducers } from "redux";

import { reducer as attendanceReports } from "./attendanceReports/reducer";
import clientReducer from "./clients/reducer";
import { cuttingEntries } from "./cuttingEntries/reducer";
import { reducer as depotLocations } from "./depotLocations/reducer";
import { reducer as employeeReducer } from "./employees/reducer";
import { reducer as employeesPayrollReducer } from "./employeesPayroll/reducer";
import { reducer as fabDepotEntries } from "./fabDepotEntries/reducer";
import fabricConsumptionReducer from "./fabricConsumptions/reducer";
import { reducer as fabStockMovementRequests } from "./fabStockMovementRequest/reducer";
import { reducer as fabStocks } from "./fabStocks/reducer";
import logsReducer from "./logs/reducer";
import { reducer as orderReducer } from "./orders/reducer";
import paymentReducer from "./payments/reducer";
import contractorPaymentReducer from "./contractorPayments/reducer";
import fabricReturnReducer from "./fabricReturns/reducer";
import { reducer as payrollAttendanceEntryReducer } from "./payrollAttendanceEntry/reducer";
import { reducer as payrollEntryReducer } from "./payrollEntries/reducer";
import { reducer as payrollReducer } from "./payrolls/reducer";
import { reducer as periodsReducer } from "./periods/reducer";
import { reducer as prodBomReducer } from "./prodBoms/reducer";
import { reducer as prodDepotEntries } from "./prodDepotEntries/reducer";
import { reducer as prodDepotLocations } from "./prodDepotLocations/reducer";
import { prodEntries } from "./prodEntries/reducer";
import { reducer as prodOperations } from "./prodOperations/reducer";
import { reducer as prodPatternReducer } from "./prodPatterns/reducer";
import { reducer as prodSpreadReducer } from "./prodSpreads/reducer";
import { reducer as prodStocks } from "./prodStocks/reducer";
import { reducer as roleReducer } from "./roles/reducer";
import { reducer as shipmentReportsReducer } from "./shipmentReports/reducer";
import { reducer as shipments } from "./shipments/reducer";
import { sizes } from "./sizes/reducer";
import { specs } from "./specs/reducer";
import { reducer as splitEntries } from "./splitEntries/reducer";
import { reducer as teamReducer } from "./teams/reducer";
import { reducer as tempData } from "./tempData/reducer";
import { reducer as userReducer } from "./users/reducer";
import { reducer as wageReducer } from "./wages/reducer";
import { reducer as scheduleReducer } from "./workingSchedules/reducer";

export const reducer = combineReducers({
  tempData: tempData,
  orders: orderReducer,
  prodBoms: prodBomReducer,
  prodOperations,
  prodSpreads: prodSpreadReducer,
  prodPatterns: prodPatternReducer,
  sizes,
  teams: teamReducer,
  cuttingEntries,
  splitEntries,
  prodEntries,
  employees: employeeReducer,
  employeesPayroll: employeesPayrollReducer,
  wages: wageReducer,
  specs: specs,
  users: userReducer,
  roles: roleReducer,
  periods: periodsReducer,
  payrolls: payrollReducer,
  payrollEntries: payrollEntryReducer,
  payrollAttendanceEntries: payrollAttendanceEntryReducer,
  depotLocations: depotLocations,
  fabDepotEntries: fabDepotEntries,
  fabStocks: fabStocks,
  workingSchedules: scheduleReducer,
  prodDepotLocations: prodDepotLocations,
  prodDepotEntries: prodDepotEntries,
  prodStocks: prodStocks,
  attendanceReports,
  shipments,
  fabStockMovementRequests: fabStockMovementRequests,
  shipmentsReport: shipmentReportsReducer,
  logs: logsReducer,
  fabricConsumptions: fabricConsumptionReducer,
  clients: clientReducer,
  payments: paymentReducer,
  contractorPayments: contractorPaymentReducer,
  fabricReturns: fabricReturnReducer
});

//selectors
