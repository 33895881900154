import { Button, notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const FABSTOCK_MOVEMENT_REQUESTS_FETCH_SUCCESS =
  "FABSTOCK_MOVEMENT_REQUESTS_FETCH_SUCCESS";
export const FABSTOCK_MOVEMENT_REQUEST_FETCH_SUCCESS =
  "FABSTOCK_MOVEMENT_REQUEST_FETCH_SUCCESS";
export const FABSTOCK_MOVEMENT_REQUEST_CREATE_SUCCESS =
  "FABSTOCK_MOVEMENT_REQUEST_CREATE_SUCCESS";
export const FABSTOCK_MOVEMENT_REQUEST_UPDATE_SUCCESS =
  "FABSTOCK_MOVEMENT_REQUEST_UPDATE_SUCCESS";
export const FABSTOCK_MOVEMENT_REQUEST_DELETE_SUCCESS =
  "FABSTOCK_MOVEMENT_REQUEST_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchFabStockMovementRequests = () => {
  return dispatch => {
    return axios
      .get(`/api/fabstockrequests/`)
      .then(({ data }) => {
        let fabStockMovementRequests = data;
        dispatch({
          type: FABSTOCK_MOVEMENT_REQUESTS_FETCH_SUCCESS,
          response: normalize(
            fabStockMovementRequests,
            schema.arrayOfFabStocks,
          ),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchFabStockMovementRequest = stockId => {
  return dispatch => {
    return axios
      .get(`/api/fabstockrequest/` + stockId)
      .then(({ data }) => {
        let fabStockMovementRequest = data;
        let id = stockId;
        dispatch({
          type: FABSTOCK_MOVEMENT_REQUEST_FETCH_SUCCESS,
          response: normalize(
            fabStockMovementRequest,
            schema.fabStockMovementRequest,
          ),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          if (data.errorCode !== 132) {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            return null;
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateFabStockMovementRequest = (
  fabStockMovementRequestEntry,
  id,
) => dispatch =>
  axios
    .put("/api/fabstockrequest/" + id, fabStockMovementRequestEntry)
    .then(({ data }) => {
      // Status looks good
      dispatch({
        type: FABSTOCK_MOVEMENT_REQUEST_UPDATE_SUCCESS,
        response: normalize(data, schema.fabStockMovementRequest),
      });
      openNotificationWithIcon(
        "Успешно",
        "Запись успешно добавлена",
        "success",
      );
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const createFabStockMovementRequest = fabStockMovementRequestEntry => {
  return dispatch => {
    return axios
      .post("/api/fabstockrequest/", fabStockMovementRequestEntry)
      .then(({ data }) => {
        // Status looks good
        dispatch({
          type: FABSTOCK_MOVEMENT_REQUEST_CREATE_SUCCESS,
          response: normalize(data, schema.fabStockMovementRequest),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteFabStockMovementRequest = fabStockMovementRequestId => {
  return dispatch => {
    return axios
      .delete("/api/fabstocks/" + fabStockMovementRequestId)
      .then(({ data }) => {
        dispatch({
          type: FABSTOCK_MOVEMENT_REQUEST_DELETE_SUCCESS,
          fabStockMovementRequestId: fabStockMovementRequestId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данные этой записи используются в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          //console.log("Parsing eror: Most likely server crashed");

          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
