import { Button, DatePicker } from "antd";
import { Card, Input } from "antd";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import AsyncSelect from "../../../../components/AsyncSelect";
import Select from "../../../../components/Select";
import SizeTable from "../../../../components/SizeTable";
import {
  createOrder,
  fetchOrder,
  fetchOrders,
  updateOrder,
} from "../../../../data/orders/actions";
import { getOrder } from "../../../../data/orders/reducer";
import { fetchSpecs } from "../../../../data/specs/actions";
import { setOpenedOrder } from "../../actions";
import { getOpenedOrder } from "../../reducer";
import FormValidator from "./FormValidator";

//import { fetchPeriods} from '../../../../data/periods/actions';

class ProductionDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "specField",
        method: () => {
          if (this.state.selectedSpec) {
            return false;
          } else {
            return true;
          }
          // return true
        },
        validWhen: false,
        message: "Выберите спецификацию",
      },
      // {
      //     field: 'periodField',
      //     method: ()=>{
      //         if(this.state.selectedPeriod){
      //             return false
      //         }
      //         else{
      //             return true;
      //         }
      //        // return true
      //     },
      //     validWhen: false,
      //     message: 'Выберите период'
      // },
      {
        field: "selectedDateField",
        method: () => {
          if (this.state.selectedDate) {
            return false;
          } else {
            return true;
          }
          // return true
        },
        validWhen: false,
        message: "Укажите дату",
      },
      {
        field: "productField",
        method: () => {
          if (this.state.selectedProduct) {
            return false;
          } else {
            return true;
          }
          // return true
        },
        validWhen: false,
        message: "Выберите продукт",
      },
    ]);

    this.state = {
      initialValues: [],
      enteredValues: [],
      name: "",
      isInEditState: false,
      specOptions: [],
      //periodOptions:[],

      selectedDate: null,
      selectedDateField: "",

      selectedProduct: null,
      productField: "",

      selectedSpec: null,
      specField: "",

      //selectedPeriod: null,
      //periodField:'',
      loading: false,

      validation: this.validator.valid(),
    };

    this.submitted = false;
  }

  componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    this.fetchOrderOrResetForm();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.fetchOrderOrResetForm();
    }
  }

  fetchOrderOrResetForm = () => {
    const {
      fetchOrder,
      match,
      setOpenedOrder,
      openedOrder,
      fetchSpecs,
      fetchPeriods,
    } = this.props;
    setOpenedOrder("");

    if (match.params.id) {
      fetchOrder(match.params.id).then(production => {
        setOpenedOrder(match.params.id);
        //this.handleInitialValues(production);
        this.setInitialSizeValues(production);
        this.setState({ isInEditState: false });

        this.setState({ name: production.name });

        this.setState({
          selectedProduct: this.addLabelFieldsToProduct(production.product),
        });

        this.setState({
          selectedDate: production.start_date
            ? new moment(production.start_date)
            : null,
        });

        let specValue = null;
        if (production.spec) {
          specValue = {
            label: production.spec.name,
            value: production.spec.id,
            ...production.spec,
          };
        }
        //let specValue= production.spec;
        this.setState({ selectedSpec: specValue });

        let periodValue = null;
        if (production.period) {
          periodValue = {
            label: production.period.name,
            value: production.period.id,
            ...production.period,
          };
        }
        //let specValue= production.spec;
        this.setState({ selectedPeriod: periodValue });
        //console.log("production",production)
      });

      fetchSpecs().then(specs => {
        this.setState({
          specOptions: specs.map(spec => {
            return { label: spec.name, value: spec.id, ...spec };
          }),
        });
      });

      // fetchPeriods().then(periods=>{
      //     console.log(periods);
      //     this.setState({
      //         periodOptions: periods.map(period=>{ return {label: period.name, value: period.id, ...period}})
      //     })
      // })
    }
    //Reset form
    else {
      fetchSpecs().then(specs => {
        this.setState({
          specOptions: specs.map(spec => {
            return { label: spec.name, value: spec.id, ...spec };
          }),
        });
      });

      // fetchPeriods().then(periods=>{
      //     console.log(periods);
      //     this.setState({
      //         periodOptions: periods.map(period=>{ return {label: period.name, value: period.id, ...period}})
      //     })
      // })

      //this.setState({  });
      this.setState({
        name: "",
        isInEditState: true,
        initialValues: [],
        enteredValues: [],
        selectedDate: null,
      });
    }
  };

  addLabelFieldsToProduct = product => {
    if (product) {
      //this.setState({ initialValues: openedProduction.lineItems })
      let selectedProduct = {
        value: product.id,
        label: product.name,
        ...product,
      };
      return selectedProduct;
    }
    return undefined;
  };

  setInitialSizeValues = production => {
    let lineItems = [];
    if (production.lineItems) {
      production.lineItems.forEach(item => {
        lineItems.push({
          size: item.size.name,
          quantity: item.quantity,
          size_id: item.size_id,
        });
      });
      this.setState({
        enteredValues: lineItems,
        initialValues: lineItems,
      });
    } else {
      this.setState({
        enteredValues: [],
        initialValues: [],
      });
    }
  };

  handleProductSelect = product => {
    let lineItems = [];

    if (product) {
      product.sizes.forEach(size => {
        lineItems.push({ size: size.name, quantity: 0, size_id: size.id });
      });

      let specValue = {
        label: product.spec.name,
        value: product.spec.id,
        ...product.spec,
      };
      this.setState({
        enteredValues: lineItems,
        initialValues: lineItems,
        selectedSpec: specValue,
      });
    } else {
      this.setState({
        enteredValues: [],
        initialValues: [],
        selectedSpec: null,
      });
    }

    this.setState({ selectedProduct: product });
  };

  handleSizeTableChange = lineItems => {
    this.setState({ enteredValues: lineItems });
  };

  createOrderFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var order = {
      lineItems: this.state.enteredValues,
      product: this.state.selectedProduct,
      name: this.state.name,
      spec: this.state.selectedSpec,
      period: this.state.selectedPeriod,
      start_date: new moment(this.state.selectedDate).format(
        "YYYY-MM-DD HH:mm:ss",
      ),
    };
    return order;
  };

  mapErrorsToObjectMap = error => {
    let subError = new Object();
    for (var key in error.errors) {
      subError[key] = error.errors[key].message;
    }
    return subError;
  };

  handleSubmit = values => {
    const { createOrder, match, updateOrder } = this.props;

    var order = this.createOrderFromValues();

    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    console.log("VALIDATION", validation);

    if (validation.isValid) {
      this.setState({ loading: true });
      // handle actual form submission here

      //Handle update
      if (match.params.id) {
        return updateOrder(match.params.id, order).then(
          json => {
            this.setState({ loading: false });
            this.setState({ isInEditState: false });
            this.props.history.push(`/production/` + json.id);
          },
          error => {
            this.setState({ loading: false });
            // let subError=this.mapErrorsToObjectMap(error);
            // throw new SubmissionError(subError)
          },
        );
      }
      //Handle create
      else {
        return createOrder(order).then(
          json => {
            this.setState({ loading: false });
            this.setState({ isInEditState: false });
            this.props.history.push(`/production/` + json.id);
          },
          error => {
            this.setState({ loading: false });
            // let subError=this.mapErrorsToObjectMap(error);
            // throw new SubmissionError(subError)
          },
        );
      }
    }
  };

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  getProductOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return axios(`/api/products/search?q=${input}`).then(({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  handleSpecChange = spec => {
    console.log("SPEC", spec);
    this.setState({ selectedSpec: spec });
  };

  handlePeriodChange = period => {
    console.log("period", period);
    this.setState({ selectedPeriod: period });
  };

  handleDateChange = day => {
    console.log(day);
    this.setState({ selectedDate: day });
  };

  render() {
    const { history, openedOrder, match } = this.props;

    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    //console.log("INITIAL FORM IS ",initialFormValues)
    const isInEditState = this.state.isInEditState;
    //console.log("INITIAL VALUES IS ",this.state.initialValues.length)

    function disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf("day");
    }

    return (
      <div className="mt-paper">
        <div>
          <h2>Заказ на производство</h2>
        </div>
        {match.params.id && isInEditState && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
          >
            Сохранить
          </Button>
        )}
        {match.params.id == undefined && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
          >
            Создать
          </Button>
        )}
        {match.params.id && !isInEditState && (
          <Button onClick={this.toggleEditState}>Изменить</Button>
        )}
        <br />
        &nbsp;
        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="article">
            Название
          </label>
          <Input
            name="name"
            placeholder="Название заказа"
            //onChange={this.handleInputChange}
            value={this.state.name}
            onChange={this.handleNameChange}
            disabled={!this.state.isInEditState}
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="prodDate">
            Дата
          </label>
          <br />
          <DatePicker
            disabledDate={disabledDate}
            id="prodDate"
            name="date"
            placeholder="DD/MM/YYYY"
            format="DD/MM/YYYY"
            onChange={this.handleDateChange}
            value={this.state.selectedDate}
            style={{ zIndex: 9999 }}
            disabled={!this.state.isInEditState}
          />
          <br />
          <span className="help-block">
            {validation.selectedDateField.message}
          </span>
        </div>
        {/* <div style={{marginBottom: 15}}>
                    <label className="mt-label" htmlFor="period">Период</label>
                    <Select
                        id="period"
                       
                        options={this.state.periodOptions}
                        disabled={!this.state.isInEditState}
                        //clearable={this.state.clearable}
                        //name="selected-state"
                        //disabled={this.state.disabled}
                        //value={this.state.selectValue}
                        //onChange={this.updateValue}
                        //rtl={this.state.rtl}
                        searchable={true}
                        value={this.state.selectedPeriod}
                        onChange={this.handlePeriodChange}
                    />
                     <span className="help-block">{validation.periodField.message}</span>
                                     
                </div> */}
        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="product">
            Продукт
          </label>
          <AsyncSelect
            cacheOptions
            classNamePrefix="product"
            name="product"
            value={this.state.selectedProduct}
            loadOptions={this.getProductOptions}
            onChange={this.handleProductSelect}
            isDisabled={match.params.id != undefined}
          />
          <span className="help-block">{validation.productField.message}</span>
        </div>
        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="spec">
            Спецификация
          </label>
          <Select
            id="spec"
            options={this.state.specOptions}
            isDisabled={match.params.id != undefined}
            //clearable={this.state.clearable}
            //name="selected-state"
            //disabled={this.state.disabled}
            //value={this.state.selectValue}
            //onChange={this.updateValue}
            //rtl={this.state.rtl}
            searchable={true}
            value={this.state.selectedSpec}
            onChange={this.handleSpecChange}
          />
          <span className="help-block">{validation.specField.message}</span>
        </div>
        &nbsp;
        {this.state.initialValues.length > 0 && (
          <div>
            <Card title="Количество по размерам" bordered={true}>
              {/* <Panel header={"Количество по размерам"} bsStyle="primary"> */}

              {/* <h4>Количество</h4> */}
              <SizeTable
                onChange={this.handleSizeTableChange}
                enableReinitialize
                initialValues={this.state.initialValues}
                disabled={!isInEditState}
              />
              {/* </Panel> */}
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    //materials: getAllMaterials(state.materials.byId, state.materials.allIds)
    openedOrder: getOrder(state, getOpenedOrder(state)),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: id => dispatch(fetchOrder(id)),
    setOpenedOrder: id => dispatch(setOpenedOrder(id)),
    createOrder: order => dispatch(createOrder(order)),
    updateOrder: (orderId, order) => dispatch(updateOrder(orderId, order)),

    fetchSpecs: () => dispatch(fetchSpecs()),
    //fetchPeriods: () => dispatch(fetchPeriods()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProductionDetail),
);
