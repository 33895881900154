"use strict";

const { get } = require("lodash");
const moment = require("moment");
const AttendanceReportType = require("../constants/AttendanceReportType");

const attendanceReportTypes = [
  AttendanceReportType.AttendanceReportByDateRange,
];

function formatAttendanceReportType(type) {
  switch (type) {
    case AttendanceReportType.AttendanceReportByDateRange:
      return "Отчет по периоду";

    default:
      return type;
  }
}

function isValidAttendanceReportType(type) {
  return attendanceReportTypes.includes(type);
}

function tryParseJSON(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}

function getAttendanceReportName({ id, name }) {
  return name || `REP-${id}`;
}

function formatAttendanceReportName(report) {
  const title = getAttendanceReportName(report);

  if (report.type === AttendanceReportType.AttendanceReportByDateRange) {
    const values = tryParseJSON(report.input);

    if (values) {
      const startDate = moment(get(values, "start_date"));
      const endDate = moment(get(values, "end_date"));

      if (startDate.isValid() && endDate.isValid()) {
        return `${title}: ${startDate.format("DD/MM/YYYY")} - ${endDate.format(
          "DD/MM/YYYY",
        )}`;
      }
    }
  }

  return title;
}

module.exports = {
  attendanceReportTypes,
  getAttendanceReportName,
  formatAttendanceReportName,
  formatAttendanceReportType,
  isValidAttendanceReportType,
};
