import { DatePicker } from "antd";
import { Field, getIn } from "formik";
import moment from "moment";
import React, { useMemo } from "react";

import { FormGroup } from "./FormGroup";

function FormDatePickerInternal({
  startDateName,
  startDateValue,
  onStartDateChange,
  defaultStartDatePickerValue,

  endDateName,
  endDateValue,
  onEndDateChange,
  defaultEndDatePickerValue,

  placeholder = ["Начиная с", "По"],
  ...props
}) {
  const value = useMemo(
    () => [
      !startDateValue ? null : moment(startDateValue),
      !endDateValue ? null : moment(endDateValue),
    ],
    [startDateValue, endDateValue],
  );
  const defaultPickerValue = useMemo(
    () => [
      !defaultStartDatePickerValue ? null : moment(defaultStartDatePickerValue),
      !defaultEndDatePickerValue ? null : moment(defaultEndDatePickerValue),
    ],
    [defaultStartDatePickerValue, defaultEndDatePickerValue],
  );
  const handleChange = useMemo(() => {
    const handleStartDateChange = onStartDateChange(startDateName);
    const handleEndDateChange = onEndDateChange(endDateName);

    return ([startDate, endDate]) => {
      handleStartDateChange(startDate && startDate.toDate());
      handleEndDateChange(endDate && endDate.toDate());
    };
  }, [endDateName, startDateName, onEndDateChange, onStartDateChange]);

  return (
    <DatePicker.RangePicker
      {...props}
      format="DD/MM/YYYY"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      defaultPickerValue={defaultPickerValue}
    />
  );
}

export function FormDateRange({
  label,
  disabled,
  startDateName,
  defaultStartDatePickerValue,
  endDateName,
  defaultEndDatePickerValue,

  ...props
}) {
  return (
    <Field
      name={startDateName}
      render={({
        field: startDateField,
        form: { touched, errors, isSubmitting },
      }) => (
        <Field
          name={endDateName}
          render={({ field: endDateField }) => {
            const errorMessage =
              (getIn(touched, startDateName) && getIn(errors, startDateName)) ||
              (getIn(touched, endDateName) && getIn(errors, endDateName));

            return (
              <FormGroup
                label={label}
                hasError={Boolean(errorMessage)}
                name={`${startDateName}-${endDateName}`}
              >
                <br />
                <FormDatePickerInternal
                  {...props}
                  disabled={disabled || isSubmitting}
                  startDateName={startDateField.name}
                  startDateValue={startDateField.value}
                  onStartDateChange={startDateField.onChange}
                  defaultStartDatePickerValue={defaultStartDatePickerValue}
                  endDateName={endDateField.name}
                  endDateValue={endDateField.value}
                  onEndDateChange={endDateField.onChange}
                  defaultEndDatePickerValue={defaultEndDatePickerValue}
                  onBlur={() => {
                    startDateField.onBlur();
                    endDateField.onBlur();
                  }}
                />
                <br />

                {errorMessage && (
                  <span className="help-block">{errorMessage}</span>
                )}
              </FormGroup>
            );
          }}
        />
      )}
    />
  );
}
