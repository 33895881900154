import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, DatePicker, Input } from "antd";
import { InputNumber, Select as SelectAntD, notification } from "antd";
import axios from "axios";
import { fetchDepotLocations } from "data/depotLocations/actions";
import { getAllDepotLocations } from "data/depotLocations/reducer";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { setOpenedRevisionId } from "scenes/FabStockRevision/actions";
import {
  createRevision,
  fetchRevision,
  updateRevision,
} from "scenes/FabStockRevision/data/fabStockRevisions/actions";
import { getFabStockRevision } from "scenes/FabStockRevision/data/fabStockRevisions/reducer";

import FabStockRevisionForm from "../../forms/FabStockRevisionForm";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const Option = SelectAntD.Option;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function FabStockRevisionDetail(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    match,
    fetchRevision,
    setOpeenedRevisionId,
    createRevision,
    openedFabStockRevision,
    updateRevision,
    fetchDepotLocations,
    fetchFabStock,
    setOpenedRevisionId,
    updateFabStock,
    classes,
    openedFabStock,
  } = props;

  const [isInEditState, toggleEditState] = useState(false);
  const [formData, setFormData] = useState({
    depotLocation: null,
    entryDate: new moment(new Date()),
    fabric: null,
    weight: null,
  });

  const form = useRef();

  const [depotLocations, setDepotLocations] = useState([]);

  const [saving, setSaving] = useState(false);

  const handleEditClick = contractor => {
    history.push(`/fabdepot/` + contractor.id);
  };

  const startSubmit = e => {
    e.preventDefault();
    form.current.submitForm();
  };

  const addLabelFieldsToFabric = fabric => {
    if (fabric) {
      let selectedFabric = {
        value: fabric.id,
        label: fabric.name,
        ...fabric,
      };
      return selectedFabric;
    }
    return null;
  };

  const fetchDepotLocationsWithStock = () => {
    let fabricId = "";
    if (formData.fabric) {
      fabricId = formData.fabric.id;
    }
    axios(`/api/depotlocations/searchwithstock?q=${fabricId}`).then(
      ({ data }) => {
        setDepotLocations(data);
      },
    );
  };

  useEffect(() => {
    fetchDepotLocationsWithStock();
  }, [formData.fabric]);

  useEffect(() => {
    setOpenedRevisionId("");
    if (match.params.id) {
      fetchRevision(match.params.id).then(entry => {
        setOpenedRevisionId(entry.id);
        toggleEditState(false);
        fetchDepotLocations();
        setFormData(prev => {
          return {
            ...prev,
            weight: entry.weight,
            entryDate: entry.entry_date ? new moment(entry.entry_date) : null,
            //contractor: addLabelFieldsToContractor(entry.contractor),
            fabric: addLabelFieldsToFabric(entry.fabric),
            depotLocation: entry.depotLocation_id,
          };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  let depotLocationOptions = depotLocations.map(depot => {
    let weight = 0;
    if (depot.totalWeight) {
      weight = depot.totalWeight;
    }
    if (match.params.id) {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}
        </Option>
      );
    } else {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}
        </Option>
      );
    }
  });

  const onFabDepotEntryClick = id => {
    //console.log(id);
    history.push(`/fabdepot/` + id);
  };

  const createFabStockRevisionFromValues = values => {
    var revision = {
      weight: values.weight,
      entry_date: new moment(values.entryDate).format("YYYY-MM-DD HH:mm:ss"),
      fabric: values.fabric,
      depotLocation_id: values.depotLocation,
    };
    return revision;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    var revision = createFabStockRevisionFromValues(values);
    //Handle update
    if (match.params.id) {
      // return updateFabDepotEntry(match.params.id, depotLocation).then(
      //   json => {
      //     toggleEditState(false);
      //     history.push(`/fabdepot/` + json.id);
      //     printLabelsForEntry(json);
      //   },
      //   error => {},
      // );
    }
    //Handle create
    else {
      setSaving(true);
      return createRevision(revision).then(
        json => {
          setSubmitting(false);
          setSaving(false);
          toggleEditState(false);
          history.push(`/fabstockrevision/` + json.id);
          //printLabelsForEntry(json);
        },
        error => {
          setSubmitting(false);
          setSaving(false);
        },
      );
    }
  };

  const createRows = () => {
    let rows = [];
    console.log("opened", openedFabStockRevision);
    if (openedFabStockRevision && openedFabStockRevision.fabStocks) {
      openedFabStockRevision.fabStocks.forEach(stock => {
        rows.push(stock);
        //rows.push({name})
      });
    }
    return rows;
  };

  const rows = createRows();

  return (
    <div className="mt-paper">
      <div>
        <h2>Запись ревизии</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={startSubmit} loading={saving}>
          Сохранить
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={startSubmit} loading={saving}>
          Создать
        </Button>
      )}
      {/*{match.params.id && !isInEditState && (*/}
      {/*  <Button onClick={() => toggleEditState(true)}>Изменить</Button>*/}
      {/*)}*/}
      <br />
      &nbsp;
      <FabStockRevisionForm
        depotLocationOptions={depotLocationOptions}
        disabled={!isInEditState}
        ref={form}
        onSubmit={handleSubmit}
        //fabDepotEntryId={openedFabDepotEntry ? openedFabDepotEntry.id : null}
        initialValues={{
          weight: formData.weight,
          entryDate: formData.entryDate,
          fabric: formData.fabric,
          depotLocation: formData.depotLocation,
        }}
      />
      {match.params.id && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Из прихода</TableCell>
                <TableCell align="right">Тип</TableCell>
                <TableCell align="right">Склад </TableCell>
                <TableCell align="right">Вес исправления (kg)</TableCell>
                <TableCell align="right">Цена </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <a
                      onClick={e => {
                        e.preventDefault();
                        onFabDepotEntryClick(row.fabDepotEntry_id);
                      }}
                      href={"/fabdepot/" + row.fabDepotEntry_id}
                    >
                      {"FDE-" + row.fabDepotEntry_id}
                    </a>
                    {/*{row.fabDepotEntry.entry_date}*/}
                  </TableCell>
                  <TableCell align="right">
                    {row.fabTransactionType.name}
                  </TableCell>
                  <TableCell align="right">{row.depotLocation.name}</TableCell>
                  <TableCell align="right">{row.weight}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedFabStockRevision: getFabStockRevision(
      state,
      state.scenes.fabStockRevision.openedRevisionId,
    ),
    depotLocations: getAllDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRevision: id => dispatch(fetchRevision(id)),
    setOpenedRevisionId: id => dispatch(setOpenedRevisionId(id)),
    createRevision: revision => dispatch(createRevision(revision)),
    updateRevision: (id, entry) => dispatch(updateRevision(id, entry)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};

FabStockRevisionDetail = withStyles(styles)(FabStockRevisionDetail);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabStockRevisionDetail),
);
