import { schema } from "normalizr";

// Define a users schema
export const attendanceReport = new schema.Entity(
  "attendanceReports",
  {},
  { idAttribute: "id" },
);

export const arrayOfAttendanceReports = [attendanceReport];
