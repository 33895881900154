import { createSelector } from "reselect";

export const getAttendanceReports = state => state.data.attendanceReports;

export const getCurrentPage = state => state.data.attendanceReports.currentPage;

export const getPage = state => state.data.attendanceReports.page;

export const isPageInCache = state => {
  if (getPage(state)[getCurrentPage(state)]) return true;
  else return false;
};

export const getAttendanceReportsForCurrentPage = createSelector(
  [getAttendanceReports, getCurrentPage, getPage],
  (attendanceReports, currentPage, page) => {
    let myPage = page[currentPage];

    let res = [];

    if (myPage) {
      res = myPage.map(id => {
        return { ...attendanceReports.byId[id], currentPage: currentPage };
      });
    }

    return res;
  },
);
