import React from "react";
import ReactSelect from "react-select";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: base => ({
    ...base,
    paddingTop: 1,
    paddingBottom: 1,
  }),
};

function Select({ ...props }) {
  let combinedStyles = { ...customStyles, ...props.styles };

  return (
    <ReactSelect
      noOptionsMessage={() => {
        return "Нет данных";
      }}
      placeholder={"Выберите"}
      {...props}
      styles={combinedStyles}
    />
  );
}

export default Select;
