import { combineReducers } from "redux";

import {
  TEAMS_FETCH_SUCCESS,
  TEAM_CREATE_SUCCESS,
  TEAM_DELETE_SUCCESS,
  TEAM_FETCH_SUCCESS,
  TEAM_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case TEAMS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.teams,
      };
    case TEAM_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.teams,
      };
    case TEAM_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.teams,
      };
    case TEAM_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.teams,
      };
    case TEAM_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.teamId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case TEAMS_FETCH_SUCCESS:
      return action.response.result;
    case TEAM_CREATE_SUCCESS:
      return [...state, action.response.result];
    case TEAM_DELETE_SUCCESS:
      return [...state.filter(id => id != action.teamId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllTeams = state => {
  return state.data.teams.allIds.map(id => state.data.teams.byId[id]);
};

export const getTeam = (state, id) => {
  return state.data.teams.byId[id];
};
