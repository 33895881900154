import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, message } from "antd";
import React, { useState } from "react";

function LogoUpload(props) {
  const { filePath, setLogoPath, disabled } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <Upload
          disabled={disabled}
          name="file"
          action="/api/products/files"
          showUploadList={false}
          headers={{
            authorization: "Bearer " + localStorage.getItem("jwtToken"),
          }}
          onChange={info => {
            if (info.file.status === "done") {
              let newUrl = "/" + info.file.response.path;
              let smallUrl = "/" + info.file.response.small_path;

              setLogoPath(newUrl);
              message.success(`${info.file.name} Файл успешно загружен`);
            } else if (info.file.status === "error") {
              message.error(`${info.file.name} Не удалось загрузить файл`);
            }
          }}
        >
          {!filePath && (
            <Button disabled={disabled}>
              <UploadOutlined /> Загрузить логотип
            </Button>
          )}
        </Upload>
        {filePath && (
          <Button
            type={"danger"}
            size={"small"}
            disabled={disabled}
            onClick={() => setLogoPath("")}
          >
            Удалить
          </Button>
        )}
      </div>
      {filePath && (
        <div>
          <img
            // onError={this.addDefaultSrc}
            alt="фото"
            style={{
              border: "1px solid black",
              width: "200px",
              // height: "200px"
            }}
            src={filePath}
          />
        </div>
      )}
    </div>
  );
}

export default LogoUpload;
