import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const PERIODS_FETCH_SUCCESS = "PERIODS_FETCH_SUCCESS";
export const PERIOD_FETCH_SUCCESS = "PERIOD_FETCH_SUCCESS";
export const PERIOD_CREATE_SUCCESS = "PERIOD_CREATE_SUCCESS";
export const PERIOD_UPDATE_SUCCESS = "PERIOD_UPDATE_SUCCESS";
export const PERIOD_DELETE_SUCCESS = "PERIOD_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchPeriods = () => {
  return dispatch => {
    return axios
      .get(`/api/periods/`)
      .then(({ data }) => {
        let periods = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: PERIODS_FETCH_SUCCESS,
          response: normalize(periods, schema.arrayOfPeriods),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchPeriod = periodId => {
  return dispatch => {
    return axios
      .get(`/api/periods/` + periodId)
      .then(({ data }) => {
        let period = data;
        let id = periodId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: PERIOD_FETCH_SUCCESS,
          response: normalize(period, schema.period),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createPeriod = period => {
  return dispatch => {
    return axios
      .post("/api/periods/", period)
      .then(({ data }) => {
        // Status looks good
        let period = data;
        dispatch({
          type: PERIOD_CREATE_SUCCESS,
          response: normalize(period, schema.period),
        });
        openNotificationWithIcon(
          "Успешно",
          "Период успешно добавлен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Период с таким названием уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updatePeriod = (periodId, period) => {
  return dispatch => {
    return axios
      .put("/api/periods/" + periodId, period)
      .then(({ data }) => {
        let period = data;
        dispatch({
          type: PERIOD_UPDATE_SUCCESS,
          response: normalize(period, schema.period),
        });
        openNotificationWithIcon(
          "Успешно",
          "Период успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Период с таким названием уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deletePeriod = periodId => {
  return dispatch => {
    return axios
      .delete("/api/periods/" + periodId)
      .then(({ data }) => {
        let period = data;
        dispatch({ type: PERIOD_DELETE_SUCCESS, periodId });
        openNotificationWithIcon("Успешно", "Период успешно удален", "success");
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данный период уже используется в записях производства";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
