import {Button, Dropdown, Menu, DatePicker, Select, Spin} from "antd";
import moment from "moment";
import { deleteShipment, fetchShipments } from "data/shipments/actions";
import { getAllShipments } from "data/shipments/selectors";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import locale from "antd/es/date-picker/locale/ru_RU";
import isMobile from "../../utils/mobileCheck";
import {
  setShowShipmentStatusModal,
  setShowShipmentsReportModal,
} from "./actions";
import ShipmentStatusModal from "./components/ShipmentStatusModal";
import ShipmentTable from "./components/ShipmentTable";
import axios from "axios";
import "./mobileFriendlyDateRange.css"

const { RangePicker } = DatePicker

function ShipmentMain(props) {
  const {
    history,
    fetchShipments,
    deleteShipment,
    setShowShipmentStatusModal,
  } = props;

  let { shipments } = props;

  const [loading, setLoading] = useState(() => {
    if (shipments.length > 0) {
      return false;
    } else return true;
  });
  const [clientId, setClientId] = useState(undefined)
  const [isSearchingClients, setIsSearchingClients] = useState(false)
  const [foundClients, setFoundClients] = useState([]);
  const [dateRange, setDateRange] = useState(undefined)

  const onShipmentCreate = () => {
    history.push(`/shipment/new`);
  };

  const handleEditClick = ({ id }) => {
    history.push(`/shipment/` + id);
  };

  const searchClient = term => {
      setIsSearchingClients(true)
      axios.get(`/api/clients?q=${term}`).then(
          ({ data }) => {
              setIsSearchingClients(false)
              setFoundClients(data)
          },
          () => {
              setIsSearchingClients(false)
          },
      );
};

  const onRemove = id => {
    deleteShipment(id);
  };

  useEffect(() => {
    fetchShipments().then(() => {
      setLoading(false);
    });
  }, []);

  const isInRange = (date) => dateRange[0] <= date && date <= dateRange[1];

  shipments = shipments
      .filter((shipment) => clientId ? shipment.client_id === clientId : true)
      .filter((shipment) => dateRange ? isInRange(moment(shipment.shipped_at)) : true)

  const renderMobile = () => (
    <div>
      <h2>Отгрузки</h2>
      <Button style={{ width: "100%" }} onClick={onShipmentCreate}>
        Новая отгрузка
      </Button>
      <br />
      <RangePicker
          locale={locale}
          style={{width: "100%", marginTop: "10px"}}
          allowClear={true}
          onChange={(range) => range ?
              setDateRange([range[0].startOf('day'), range[1].endOf('day')])
              : setDateRange(undefined)
          }
      />
      <br />
      <Select
          style={{width: "100%", marginTop: "10px"}}
          allowClear={true}
          showSearch
          placeholder="Клиент"
          filterOption={false}
          notFoundContent={
              isSearchingClients ? (
                  <Spin size="small" />
              ) : null
          }
          value={clientId}
          onSearch={e => searchClient(e)}
          onChange={id => setClientId(id)}
      >
          {foundClients.map(client => (
                  <Select.Option key={client.id} value={client.id}>
                      {client.name}
                  </Select.Option>
              )
          )}
      </Select>
      <br />
      <br />
      <ShipmentTable
        mobile
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        shipments={shipments}
        setShowShipmentStatusModal={setShowShipmentStatusModal}
      />
      <br />
    </div>
  );

  const renderPC = () => (
    <div>
      <div>
        <h2>Отгрузки</h2>
        <span>
          <Button type="primary" onClick={onShipmentCreate}>
            Новая отгрузка
          </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => history.push("/logs/shipment")}>
                  Посмотреть в журнале
                </Menu.Item>
              </Menu>
            }
          >
            <Button style={{ marginLeft: "0.5em" }}>...</Button>
          </Dropdown>
          <RangePicker
              locale={locale}
              style={{marginLeft: "10px", float: "right", width: "300px"}}
              allowClear={true}
              onChange={(range) => range ?
                  setDateRange([range[0].startOf('day'), range[1].endOf('day')])
                  : setDateRange(undefined)
              }
          />
          <Select
              style={{float: "right", width: "400px"}}
              allowClear={true}
              showSearch
              placeholder="Клиент"
              filterOption={false}
              notFoundContent={
                  isSearchingClients ? (
                      <Spin size="small" />
                  ) : null
              }
              value={clientId}
              onSearch={e => searchClient(e)}
              onChange={id => setClientId(id)}
          >
            {foundClients.map(client => (
                    <Select.Option key={client.id} value={client.id}>
                        {client.name}
                    </Select.Option>
                )
            )}
          </Select>
        </span>
      </div>
      <br />
      <ShipmentTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        shipments={shipments}
        setShowShipmentStatusModal={setShowShipmentStatusModal}
      />
      <ShipmentStatusModal />
    </div>
  );

  return isMobile() ? renderMobile() : renderPC();
}

const mapStateToProps = (state, ownProps) => {
  return {
    shipments: getAllShipments(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchShipments: () => dispatch(fetchShipments()),
    deleteShipment: id => dispatch(deleteShipment(id)),
    setShowShipmentsReportModal: show =>
      dispatch(setShowShipmentsReportModal(show)),
    setShowShipmentStatusModal: (show, shipmentId) =>
      dispatch(setShowShipmentStatusModal(show, shipmentId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShipmentMain),
);
