import { Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { deleteSpec, fetchSpecs } from "../../data/specs/actions";
import SpecTable from "./components/SpecTable";
import { getAllSpecs } from "./selectors";

class SpecMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchSpecs();
  }

  handleProductDoubleClick = value => {
    this.props.history.push(`/specification/` + value.id);
  };

  handleSpecRemove = value => {
    this.props.deleteSpec(value.id);
  };

  handleCreate = () => {
    this.props.history.push(`/specification/new`);
  };

  render() {
    return (
      <div>
        <div>
          <h2>Спецификации</h2>
          <Button type="primary" onClick={this.handleCreate}>
            Создать
          </Button>
        </div>
        <br />
        <SpecTable
          onEditClick={this.handleProductDoubleClick}
          onRemove={this.handleSpecRemove}
          specs={this.props.specs}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    specs: getAllSpecs(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchSpecs: () => dispatch(fetchSpecs()),
    deleteSpec: id => dispatch(deleteSpec(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpecMain);
