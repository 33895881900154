import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import SpecDetail from "./components//SpecDetail";
import SpecMain from "./index.js";

class SpecificationRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/specification" component={SpecMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={SpecDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:specId`}
            component={SpecDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:specId/:saved`}
            component={SpecDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default SpecificationRoute;
