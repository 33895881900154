import Icon, {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Popconfirm, Table, Tabs, message } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { copyBom } from "../../../../../data/tempData/actions";
import {
  setOpenedBom,
  setOpenedSpread,
  setSelectedBom,
  setSelectedSpreadTab,
  setShowBomModal,
  setShowSpreadModal,
} from "../../actions";
import { deleteBom, moveBom } from "../../data/boms/actions";
import SpreadTabs from "../SpreadTabs";

const copyIcon = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="copy"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z" />
  </svg>
);

class BomCard extends Component {
  handleDeleteBom = (e, bom) => {
    const { deleteBom } = this.props;
    console.log("YES HANDLE DELETE ", bom);
    deleteBom(bom.id);
    // this.props.setOpenedBom('');
    //this.props.setShowBomModal(true)
  };

  handleBomEdit = (e, bom) => {
    this.props.setOpenedBom(bom.id);
    this.props.setShowBomModal(true);
  };

  handleCopyBom = bomId => {
    const specform = this.props.specform;
    this.props.copyBom({
      bom: { ...specform.boms.byId[bomId], sortOrderId: undefined },
      spreads: specform.spreads.byId,
      patterns: specform.patterns.byId,
    });
    message.success("Часть скопирована");
  };

  handleAddSpread = () => {
    const { bom } = this.props;
    this.props.setOpenedSpread("");
    this.props.setSelectedBom(bom.id);
    this.props.setShowSpreadModal(true);
  };

  componentDidMount() {
    const { bom, setSelectedSpreadTab } = this.props;
    let selectedSpreadId = "";
    if (bom.spreads.length > 0) {
      selectedSpreadId = bom.spreads[0];
    }

    console.log("MOUNT MOUNT MOUNT", bom);
    setSelectedSpreadTab(bom.id, selectedSpreadId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bom != this.props.bom) {
      const { bom, setSelectedSpreadTab } = this.props;
      let selectedSpreadId = "";
      if (bom.spreads.length == 1) {
        selectedSpreadId = bom.spreads[0];
        console.log("MOUNT MOUNT MOUNT", bom);
        setSelectedSpreadTab(bom.id, selectedSpreadId);
      }
    }
  }

  render() {
    const { bom, disabled, numberOfBoms, moveBom } = this.props;

    const actions = [
      <Button
        disabled={disabled}
        icon={<EditOutlined />}
        onClick={e => this.handleBomEdit(e, bom)}
      />,
      <Popconfirm
        placement="topRight"
        title="Уверены что хотите удалить эту спецификацию?"
        onConfirm={e => this.handleDeleteBom(e, bom)}
        onCancel={this.handleCancel}
        okText="Да"
        cancelText="Нет"
      >
        <Button disabled={disabled} icon={<DeleteOutlined />} />
      </Popconfirm>,
      <Button disabled={disabled} onClick={() => this.handleCopyBom(bom.id)}>
        <Icon component={copyIcon} />
      </Button>,
    ];
    if (numberOfBoms > 1) {
      actions.push(
        <div>
          {bom.sortOrderId !== 1 && (
            <Button
              disabled={disabled}
              onClick={e => {
                e.preventDefault();
                moveBom(bom.id, "up");
              }}
            >
              <CaretUpOutlined />
            </Button>
          )}
          &nbsp;
          {bom.sortOrderId !== this.props.numberOfBoms && (
            <Button
              disabled={disabled}
              onClick={e => {
                e.preventDefault();
                moveBom(bom.id, "down");
              }}
            >
              <CaretDownOutlined />
            </Button>
          )}
        </div>,
      );
    }
    return (
      <div>
        <Card
          key={bom.id}
          style={{ marginTop: 10, marginBottom: 10 }}
          actions={actions}
        >
          <h3>{bom.name} </h3>

          <div>
            <Button
              disabled={disabled}
              icon={<PlusOutlined />}
              onClick={this.handleAddSpread}
            >
              Добавить настил
            </Button>
            <br /> <br />
            <SpreadTabs disabled={disabled} bomId={bom.id} />
          </div>
        </Card>

        <br />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //console.log(state);
  return {
    // You can now say this.props.books
    //openedSpread : getOpenedSpreadById(state, state.scenes.product.openedSpread),
    //product: getProduct(state, state.scenes.product.openedProduct),
    //showModal: state.scenes.product.showBomModal
    specform: state.scenes.forms.specform.data,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowBomModal: showModal => dispatch(setShowBomModal(showModal)),
    setShowSpreadModal: showModal => dispatch(setShowSpreadModal(showModal)),
    setOpenedBom: bomId => dispatch(setOpenedBom(bomId)),
    setSelectedBom: bomId => dispatch(setSelectedBom(bomId)),
    setOpenedSpread: spreadId => dispatch(setOpenedSpread(spreadId)),
    deleteBom: bom => dispatch(deleteBom(bom)),
    copyBom: bomData => dispatch(copyBom(bomData)),
    moveBom: (bomId, direction) => dispatch(moveBom(bomId, direction)),
    setSelectedSpreadTab: (bomId, spreadId) =>
      dispatch(setSelectedSpreadTab(bomId, spreadId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BomCard);
