import { DatePicker, InputNumber, Select as SelectAntD } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import { Formik, withFormik } from "formik";
import moment from "moment";
import React, { Component, useRef } from "react";
import * as Yup from "yup";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const EntrySchema = Yup.object().shape({
  price: Yup.number()
    .min(0, "Значение слишком маленькое")
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  quantity: Yup.number()
    .min(1, "Значение слишком маленькое")
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  entry_date: Yup.object()
    .required("Выберите дату")
    .nullable(),
  product: Yup.object()
    .required("Выберите продукт")
    .nullable(),
  prodDepotLocation: Yup.number()
    .required("Выберите склад")
    .nullable(),
});

class ProdDepotEntryForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {}

  submitForm = () => {
    this.form.current.submitForm();
  };

  getProductOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return axios(`/api/products/search?q=${input}`).then(({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  render() {
    const { disabled } = this.props;

    function disabledDate(current) {
      return current > moment().endOf("day");
    }

    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={EntrySchema}
          onSubmit={this.props.onSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div
                  style={{ marginBottom: 15 }}
                  className={errors.price && touched.price ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="price">
                    Цена
                  </label>
                  <br />
                  <InputNumber
                    style={{ width: 300 }}
                    name="price"
                    placeholder="Цена"
                    value={values["price"]}
                    onChange={e => setFieldValue("price", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.price && touched.price && errors.price}
                  </div>
                </div>
                <div
                  style={{ marginBottom: 15 }}
                  className={
                    errors.quantity && touched.quantity ? "has-error" : ""
                  }
                >
                  <label className="mt-label" htmlFor="quantity">
                    Количество
                  </label>
                  <br />
                  <InputNumber
                    style={{ width: 300 }}
                    name="Количество"
                    placeholder="Количество"
                    value={values["quantity"]}
                    onChange={e => setFieldValue("quantity", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.quantity && touched.quantity && errors.quantity}
                  </div>
                </div>
                <div
                  style={{ marginBottom: 15 }}
                  className={
                    errors.entry_date && touched.entry_date ? "has-error" : ""
                  }
                >
                  <label className="mt-label" htmlFor="prodDate">
                    Дата прихода
                  </label>
                  <br />
                  <DatePicker
                    id="entry_date"
                    name="date"
                    disabledDate={disabledDate}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    value={values["entry_date"]}
                    onChange={e => setFieldValue("entry_date", e)}
                    //onBlur={e => setFieldTouched("entryDate", true)}
                    disabled={disabled}
                  />
                  <br />
                  <div className="help-block">
                    {errors.entry_date &&
                      touched.entry_date &&
                      errors.entry_date}
                  </div>
                </div>
                <div
                  style={{ marginBottom: 15 }}
                  className={
                    errors.product && touched.product ? "has-error" : ""
                  }
                >
                  <label className="mt-label" htmlFor="product">
                    Продукт
                  </label>
                  <AsyncSelect
                    name="product"
                    loadOptions={this.getProductOptions}
                    isDisabled={disabled}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    value={values["product"]}
                    onChange={e => setFieldValue("product", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.product && touched.product && errors.product}
                  </div>
                </div>
                <div
                  style={{ marginBottom: 15 }}
                  className={
                    errors.prodDepotLocation && touched.prodDepotLocation
                      ? "has-error"
                      : ""
                  }
                >
                  <label className="mt-label" htmlFor="job_title">
                    Склад
                  </label>
                  <br />
                  <SelectAntD
                    disabled={disabled}
                    value={values["prodDepotLocation"]}
                    style={{ width: 300 }}
                    onChange={e => setFieldValue("prodDepotLocation", e)}
                  >
                    {this.props.prodDepotLocationOptions}
                  </SelectAntD>
                  <br />
                  <div className="help-block">
                    {errors.prodDepotLocation &&
                      touched.prodDepotLocation &&
                      errors.prodDepotLocation}
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default ProdDepotEntryForm;
