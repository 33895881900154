import React from "react";
import { Route, Switch } from "react-router-dom";

import FabricReturnDetails from "./components/FabricReturnDetails";
import FabricReturnMain from "./index.js";

export default props => (
  <div>
    <Switch>
      <Route exact path="/fabricReturn" component={FabricReturnMain} />
      <Route exact path="/fabricReturn/:id" component={FabricReturnDetails} />
    </Switch>
  </div>
);
