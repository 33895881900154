import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import SplitMain from "./index.js";

class SplitRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/split" component={SplitMain} />
          {/* <Route exact path={`${this.props.match.url}/new`} component={TeamDetail} />
          <Route exact path={`${this.props.match.url}/:id`} component={TeamDetail} /> */}
        </Switch>
      </div>
    );
  }
}

export default SplitRoute;
