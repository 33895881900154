import { Button, Popconfirm, Select } from "antd";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class ShipmentReportTable extends Component {
  render() {
    const { shipmentsReport, loading, history } = this.props;

    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <div onClick={() => history.push("/product-admin/" + row.value)}>
            <a>{"PR-" + row.value}</a>
          </div>
        ),
        style: { textAlign: "center" },
      },
      {
        Header: "Продукт",
        accessor: "name",
        style: { textAlign: "center" },
        filterable: true,
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true
      },
      {
        Header: "Количество",
        accessor: "quantity",
        style: { textAlign: "center" },
      },
      {
        Header: "Сумма",
        accessor: "sum",
        style: { textAlign: "center" },
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={shipmentsReport}
          getTdProps={(state, rowInfo, column) => {
            if (loading) {
              return {
                className: "pt-skeleton",
              };
            } else {
              return {};
            }
          }}
          columns={columns}
          defaultPageSize={50}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText={loading ? "" : "Нет записей"}
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

export default withRouter(ShipmentReportTable);
