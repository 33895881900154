import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

//import {addNotification} from '../../../../actions'

export const SPEC_FETCH_SUCCESS = "SPEC_FETCH_SUCCESS";
export const SPECS_FETCH_SUCCESS = "SPECS_FETCH_SUCCESS";
export const SPEC_CREATE_SUCCESS = "SPEC_CREATE_SUCCESS";
export const SPEC_UPDATE_SUCCESS = "SPEC_UPDATE_SUCCESS";
export const SPEC_DELETE_SUCCESS = "SPEC_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchSpec = (specId, callback) => {
  return dispatch => {
    return axios
      .get(`/api/specs/` + specId)
      .then(({ data }) => {
        let product = data;
        let normalized = normalize(product, schema.spec);
        dispatch({ type: SPEC_FETCH_SUCCESS, response: normalized });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchSpecs = () => {
  return dispatch => {
    return axios
      .get(`/api/specs/`)
      .then(({ data }) => {
        let specs = data;
        dispatch({
          type: SPECS_FETCH_SUCCESS,
          response: normalize(specs, schema.arrayOfSpecs),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createSpec = spec => {
  return dispatch => {
    return axios
      .post("/api/specs/", { spec })
      .then(({ data }) => {
        // Status looks good
        let spec = data;
        //dispatch({type: 'PRODUCT_CREATE_SUCCESS', product})
        dispatch({
          type: SPEC_CREATE_SUCCESS,
          response: normalize(spec, schema.spec),
        });
        openNotificationWithIcon(
          "Успешно",
          "Спецификация успешно создана",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data, status } = error.response;
          if (status == 409) {
            throw error;
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            throw error;
          }
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateSpec = (specId, spec) => {
  return dispatch => {
    return axios
      .put("/api/specs/" + specId, { spec })
      .then(({ data }) => {
        let spec = data;
        dispatch({
          type: SPEC_UPDATE_SUCCESS,
          response: normalize(spec, schema.spec),
        });
        openNotificationWithIcon(
          "Успешно",
          "Спецификация успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data, status } = error.response;
          if (status == 409) {
            throw error;
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            throw error;
          }
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteSpec = specId => {
  return dispatch => {
    return axios
      .delete("/api/specs/" + specId)
      .then(({ data }) => {
        let spec = data;
        dispatch({ type: SPEC_DELETE_SUCCESS, specId });
        openNotificationWithIcon(
          "Успешно",
          "Спецификация успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данная спецификация используется в продукте";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
