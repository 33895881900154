import { Card, Col, DatePicker, InputNumber, Row } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import Select from "components/Select";
import SizeTable from "components/SizeTable";
import { Formik, withFormik } from "formik";
import moment from "moment";
import React, { Component, useRef } from "react";
import * as Yup from "yup";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

//https://github.com/jquense/yup/issues/97#issuecomment-306547261

function lessThan(ref, msg) {
  return this.test({
    name: "lessThan",
    exclusive: false,
    message: msg || "В выбранном складе недостаточно материала",
    params: {
      reference: ref.path,
    },
    test: function(value) {
      let res = this.resolve(ref);

      if (!value || !res) {
        return true;
      } else if (res && res.weight !== undefined) {
        console.log(value <= res.weight);
        return value <= res.weight;
      }

      return false;
    },
  });
}

Yup.addMethod(Yup.number, "lessThan", lessThan);

function atLeastOneQuantityIsGreaterThan(num, msg) {
  return this.test({
    name: "atLeastOneQuantityIsGreaterThan",
    exclusive: false,
    message: msg || "Необходимо указать количество",
    test: function(arr) {
      return arr.some(el => el.quantity > num);
    },
  });
}

Yup.addMethod(
  Yup.array,
  "atLeastOneQuantityIsGreaterThan",
  atLeastOneQuantityIsGreaterThan,
);

const EntrySchema = Yup.object().shape({
  cutDate: Yup.object()
    .required("Выберите дату")
    .nullable(),
  period: Yup.object()
    .required("Выберите период")
    .nullable(),
  fabric: Yup.object()
    .required("Выберите материал")
    .nullable(),
  depotLocation: Yup.object()
    .required("Выберите Склад")
    .nullable(),
  weight: Yup.number()
    .lessThan(Yup.ref("depotLocation"))
    .min(0, "Значение слишком маленькое")
    .max(999999, "Значение слишком большой")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  sizeValues: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.string().required(),
        quantity: Yup.number()
          .min(0, "Значение слишком маленькое")
          .required("Обязательное значение")
          .typeError("Неправильное значение"),
      }),
    )
    .atLeastOneQuantityIsGreaterThan(0),
});

class CuttingEntryForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.state = {
      depotLocations: [],
    };
  }

  componentDidMount() {
    const { depotLocation, fabric } = this.props.initialValues;
    if (fabric) {
      //let fabric = this.props.initialValues.fabric
      this.fetchDepotLocationsWithStock(fabric).then(data => {
        if (depotLocation) {
          let found = data.find(option => option.id === depotLocation.id);
          let weight = 0;
          if (found.totalWeight) {
            weight = found.totalWeight;
          }
          let val = {
            label: found.name + ", остаток: " + weight,
            value: found.id,
            ...found,
            weight: weight,
          };

          console.log("vals are ", this.form.current.values);
          this.form.current.setFieldValue("depotLocation", val);
          //setFieldValue("depotLocation", val)
        }
      });
      // setFieldValue("fabric", e)
    }
  }

  getFabricOptions = function(input, callback) {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  submitForm = () => {
    this.form.current.submitForm();
  };

  fetchDepotLocationsWithStock = value => {
    let fabricId = "";
    if (value) {
      fabricId = value.id;
    }

    let queryString = "";

    if (this.props.cuttingEntryId) {
      queryString = `/api/depotlocations/searchwithstock?q=${fabricId}&cuttingEntryId=${
        this.props.cuttingEntryId
      }`;
    } else {
      queryString = `/api/depotlocations/searchwithstock?q=${fabricId}`;
    }
    return axios(queryString).then(({ data }) => {
      this.setState({ depotLocations: data });
      //setDepotLocations(data);
      return data;
    });
  };

  render() {
    let depotLocationOptions = this.state.depotLocations.map(depot => {
      let weight = 0;
      if (depot.totalWeight) {
        weight = depot.totalWeight;
      }
      return {
        label: depot.name + ", остаток: " + weight,
        value: depot.id,
        ...depot,
        weight: weight,
      };
    });

    function disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf("day");
    }

    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={EntrySchema}
          onSubmit={this.props.onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <div style={{ marginBottom: 15 }}>
                      <label className="mt-label" htmlFor="cutDate">
                        {" "}
                        Дата раскроя:&nbsp;
                      </label>
                      <br />
                      <DatePicker
                        disabledDate={disabledDate}
                        id="cutDate"
                        name="cutDate"
                        value={values["cutDate"]}
                        placeholder="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        onChange={e => setFieldValue("cutDate", e)}
                        style={{ zIndex: 9999 }}
                      />
                      <div className="help-block">
                        {" "}
                        {errors.cutDate && touched.cutDate && errors.cutDate}
                      </div>
                    </div>
                  </Col>

                  <Col span={12}>
                    <div style={{ marginBottom: 15, paddingLeft: 10 }}>
                      <label className="mt-label" htmlFor="period">
                        Период: &nbsp;
                      </label>
                      <Select
                        id="period"
                        options={this.props.periodOptions}
                        value={values["period"]}
                        searchable={true}
                        onChange={e => setFieldValue("period", e)}
                        styles={customStyles}
                      />
                      <div className="help-block">
                        {" "}
                        {errors.period && touched.period && errors.period}
                      </div>
                      {/* <span className="help-block">{validation.periodField.message}</span> */}
                    </div>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <div style={{ marginBottom: 15, paddingRight: 10 }}>
                      <label className="mt-label" htmlFor="sizes">
                        Материал
                      </label>
                      <AsyncSelect
                        id="fabric"
                        name="fabric"
                        value={values["fabric"]}
                        loadOptions={this.getFabricOptions}
                        onChange={e => {
                          this.fetchDepotLocationsWithStock(e).then(data => {
                            if (values["depotLocation"]) {
                              let found = data.find(
                                option =>
                                  option.id === values["depotLocation"].id,
                              );
                              let weight = 0;
                              if (found.totalWeight) {
                                weight = found.totalWeight;
                              }
                              let val = {
                                label: found.name + ", остаток: " + weight,
                                value: found.id,
                                ...found,
                                weight: weight,
                              };
                              setFieldValue("depotLocation", val);
                            }
                          });
                          setFieldValue("fabric", e);
                        }}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                      />
                      <div className="help-block">
                        {errors.fabric && touched.fabric && errors.fabric}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ marginBottom: 15, paddingLeft: 10 }}>
                      <label className="mt-label" htmlFor="depotLocation">
                        Склад
                      </label>
                      <Select
                        id="depotLocation"
                        name="depotLocation"
                        value={values["depotLocation"]}
                        options={depotLocationOptions}
                        onChange={e => setFieldValue("depotLocation", e)}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        noOptionsMessage={() => {
                          return "Сначала выберите материал";
                        }}
                      />
                      <div className="help-block">
                        {errors.depotLocation &&
                          touched.depotLocation &&
                          errors.depotLocation}
                      </div>
                    </div>
                  </Col>
                </Row>

                <label htmlFor="weight">Вес (кг):&nbsp;</label>
                <InputNumber
                  value={values["weight"]}
                  onChange={e => setFieldValue("weight", e)}
                  onBlur={e => setFieldTouched("weight", true)}
                  id="weight"
                />
                <div className="help-block">
                  {" "}
                  {errors.weight && touched.weight && errors.weight}
                </div>
                <div />
              </div>
              <br />
              <Card title="Количество по размерам" bordered={true}>
                <SizeTable
                  onChange={e => {
                    //console.log("touched");
                    setFieldValue("sizeValues", e);
                  }}
                  initialValues={this.props.initialSizeValues}
                />
                {!!errors.sizeValues &&
                  errors.sizeValues &&
                  touched.sizeValues && (
                    <div style={{ color: "red", marginTop: ".5rem" }}>
                      {Array.isArray(errors.sizeValues) ? (
                        errors.sizeValues.map((error, index) => {
                          if (error != null)
                            if (error.quantity) {
                              if (this.props.initialSizeValues[index]) {
                                return (
                                  <div key={index}>
                                    {this.props.initialSizeValues[index].size} :{" "}
                                    {error.quantity}
                                  </div>
                                );
                              }
                            }
                        })
                      ) : (
                        <div>{errors.sizeValues}</div>
                      )}
                    </div>
                  )}
              </Card>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CuttingEntryForm;
