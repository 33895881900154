import { BarcodeOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class SplitTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    //message.error('Click on No');
  };

  render() {
    const { splitEntries, onEditClick, onRemove, classes } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 60,
        Cell: row => (
          <div>
            <Button
              size="small"
              icon={<BarcodeOutlined />}
              onClick={e => onEditClick(row.original, e)}
            >
              {/* <OpenInNew classes={{ root: classes.iconRoot }} />
                        <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
            </Button>
          </div>
        ),
      },
      {
        Header: "Количество",
        accessor: "quantity", // String-based value accessors!
      },
      {
        Header: "Сумма",
        accessor: "sum", // String-based value accessors!
      },
    ];

    return (
      <div>
        <ReactTable
          className="-striped -highlight"
          data={splitEntries}
          columns={columns}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText="Нет записей"
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

SplitTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};

export default withRouter(SplitTable);
