import { Button, DatePicker, Empty, InputNumber, Select, Spin } from "antd";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  createPayment,
  fetchPayment,
  updatePayment,
} from "../../../data/payments/actions";
import isMobile from "../../../utils/mobileCheck";
import ShipmentSchema from "../../Shipment/forms/ShipmentSchema";

const isInteger = str => {
  return /^\+?(0|[1-9]\d*)$/.test(str);
};

const paymentDetailsStatuses = {
  CREATE_NEW_PAYMENT: 1,
  FETCHING_PAYMENT: 2,
  NO_SUCH_PAYMENT: 3,
  SHOW_PAYMENT: 4,
  EDIT_PAYMENT: 5,
  WRONG_PATH: 6,
};

const PaymentSchema = Yup.object().shape({
  client: Yup.object()
    .required("Выберите клиента")
    .nullable(),
  amount: Yup.number()
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .nullable(),
  transferredAt: Yup.date()
    .required("Обязательное поле")
    .nullable(),
});

function PaymentDetails(props) {
  const { match, fetchPayment, createPayment, history, updatePayment } = props;

  const [status, setStatus] = useState(() => {
    if (match.params.id === "new") {
      return paymentDetailsStatuses.CREATE_NEW_PAYMENT;
    }
    if (isInteger(match.params.id)) {
      return paymentDetailsStatuses.FETCHING_PAYMENT;
    }
    return paymentDetailsStatuses.WRONG_PATH;
  });

  const formRef = createRef();
  const [payment, setPayment] = useState(null);
  const [isSearchingClients, setIsSearchingClients] = useState(false);
  const [foundClientsByTerm, setFoundClientsByTerm] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    client: null,
    amount: 0,
    transferredAt: null,
  });

  useEffect(() => {
    if (payment) {
      setFormData({
        client: payment.client,
        amount: payment.amount,
        transferredAt: moment(payment.transferred_at),
      });
      setStatus(paymentDetailsStatuses.SHOW_PAYMENT);
    }
  }, [payment]);

  useEffect(() => {
    if (match.params.id && isInteger(match.params.id)) {
      fetchPayment(match.params.id).then(
        payment => {
          setPayment(payment);
        },
        () => {
          setStatus(paymentDetailsStatuses.NO_SUCH_PAYMENT);
        },
      );
    }
  }, [match.params.id]);

  if (status === paymentDetailsStatuses.NO_SUCH_PAYMENT) {
    return (
      <Empty
        style={{ marginTop: "100px" }}
        description={"Такого платежа не существует"}
      />
    );
  }

  if (status === paymentDetailsStatuses.WRONG_PATH) {
    props.history.push("/payment");
  }

  const showNull =
    status === paymentDetailsStatuses.NO_SUCH_PAYMENT ||
    status === paymentDetailsStatuses.FETCHING_PAYMENT;

  const showFormData =
    status === paymentDetailsStatuses.CREATE_NEW_PAYMENT ||
    status === paymentDetailsStatuses.EDIT_PAYMENT ||
    status === paymentDetailsStatuses.SHOW_PAYMENT;

  const disabled = !(
    status === paymentDetailsStatuses.CREATE_NEW_PAYMENT ||
    status === paymentDetailsStatuses.EDIT_PAYMENT
  );

  const showValue = value => {
    return showNull ? null : showFormData ? value : null;
  };

  const onSubmit = values => {
    setIsSaving(true);
    if (status === paymentDetailsStatuses.EDIT_PAYMENT) {
      updatePayment(payment.id, values).then(
        () => {
          setIsSaving(false);
          setStatus(paymentDetailsStatuses.SHOW_PAYMENT);
        },
        () => {
          setIsSaving(false);
        },
      );
    } else if (status === paymentDetailsStatuses.CREATE_NEW_PAYMENT) {
      createPayment(values).then(
        newPayment => {
          setIsSaving(false);
          history.push(`/payment/${newPayment.id}`);
        },
        () => {
          setIsSaving(false);
        },
      );
    }
  };

  const handleSubmit = () => {
    formRef.current.submitForm();
  };

  const searchClient = term => {
    setIsSearchingClients(true);
    axios.get(`/api/clients?q=${term}`).then(
      ({ data }) => {
        setIsSearchingClients(false);
        setFoundClientsByTerm(data);
      },
      () => {
        setIsSearchingClients(false);
      },
    );
  };

  return (
    <Spin spinning={status === paymentDetailsStatuses.FETCHING_PAYMENT}>
      <div className={"mt-paper"} style={isMobile() ? { padding: "10px" } : {}}>
        <h2>Платежи</h2>
        {(status === paymentDetailsStatuses.CREATE_NEW_PAYMENT ||
          status === paymentDetailsStatuses.EDIT_PAYMENT) && (
          <Button
            loading={isSaving}
            onClick={handleSubmit}
            style={isMobile() ? { width: "100%" } : {}}
          >
            Сохранить
          </Button>
        )}
        {status === paymentDetailsStatuses.SHOW_PAYMENT && (
          <Button
            style={isMobile() ? { width: "100%" } : {}}
            onClick={() => setStatus(paymentDetailsStatuses.EDIT_PAYMENT)}
          >
            Изменить
          </Button>
        )}
        <br />
        <br />
        <Formik
          ref={formRef}
          initialValues={formData}
          validationSchema={PaymentSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <>
                <div
                  className={errors.client && touched.client ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="client">
                    Клиент
                  </label>
                  <br />
                  <Select
                    id="client"
                    style={{ width: isMobile() ? "100%" : 300 }}
                    showSearch
                    placeholder="Клиент"
                    disabled={disabled}
                    filterOption={false}
                    notFoundContent={
                      isSearchingClients ? <Spin size="small" /> : null
                    }
                    value={showValue(
                      values.client ? values.client.id : undefined,
                    )}
                    onSearch={e => searchClient(e)}
                    onChange={clientId => {
                      const newClient = foundClientsByTerm.find(
                        client => client.id === clientId,
                      );
                      if (newClient) {
                        setFieldValue("client", newClient);
                      }
                    }}
                  >
                    {foundClientsByTerm.length !== 0 &&
                      foundClientsByTerm.map(client => {
                        return (
                          <Select.Option key={client.id} value={client.id}>
                            {client.name}
                          </Select.Option>
                        );
                      })}
                    {foundClientsByTerm.length === 0 && values.client && (
                      <Select.Option value={values.client.id}>
                        {values.client.name}
                      </Select.Option>
                    )}
                  </Select>
                  <div className="help-block">
                    {errors.client && touched.client && errors.client}
                  </div>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className={errors.amount && touched.amount ? "has-error" : ""}
                >
                  <label className={"mt-label"} htmlFor={"amount"}>
                    Сумма платежа
                  </label>
                  <br />
                  <InputNumber
                    id={"amount"}
                    style={{ width: isMobile() ? "100%" : 300 }}
                    placeholder={"Введите сумму"}
                    precision={2}
                    formatter={value => `$ ${value}`}
                    value={showValue(values.amount)}
                    onChange={e => setFieldValue("amount", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.amount && touched.amount && errors.amount}
                  </div>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className={
                    errors.transferredAt && touched.transferredAt
                      ? "has-error"
                      : ""
                  }
                >
                  <label className={"mt-label"} htmlFor={"transferredAt"}>
                    Дата платежа
                  </label>
                  <br />
                  <DatePicker
                    id={"transferredAt"}
                    style={{ width: isMobile() ? "100%" : 300 }}
                    placeholder={"Выберите дату"}
                    value={showValue(values.transferredAt)}
                    onChange={e => setFieldValue("transferredAt", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.transferredAt &&
                      touched.transferredAt &&
                      errors.transferredAt}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Spin>
  );
}

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = dispatch => ({
  fetchPayment: id => dispatch(fetchPayment(id)),
  createPayment: payment => dispatch(createPayment(payment)),
  updatePayment: (id, payment) => dispatch(updatePayment(id, payment)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentDetails),
);
