import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";
import "react-table/react-table.css";

import "./index.css";
import "./header.css";

import notificationType from "@app/shared/constants/notificationType";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import Icon, { UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Divider, Dropdown, Layout, Menu, Popover, notification } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import { deleteEmployee, fetchEmployees } from "../data/employees/actions";
import { getAllEmployees } from "../data/employees/reducer";
import {
  deleteNotification,
  fetchNotifications,
  markNotificationAsRead,
} from "../data/notification/actions";
import { fetchTeams } from "../data/teams/actions";
import routes from "../routes";
import isMobile from "../utils/mobileCheck";
import CustomSider from "./Sider";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

const bellSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="bell"
    width="1.5em"
    height="1.5em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M816 768h-24V428c0-141.1-104.3-257.7-240-277.1V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.9c-135.7 19.4-240 136-240 277.1v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48zM304 768V428c0-55.6 21.6-107.8 60.9-147.1S456.4 220 512 220c55.6 0 107.8 21.6 147.1 60.9S720 372.4 720 428v340H304z" />
  </svg>
);

const linkSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="export"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M888.3 757.4h-53.8c-4.2 0-7.7 3.5-7.7 7.7v61.8H197.1V197.1h629.8v61.8c0 4.2 3.5 7.7 7.7 7.7h53.8c4.2 0 7.7-3.4 7.7-7.7V158.7c0-17-13.7-30.7-30.7-30.7H158.7c-17 0-30.7 13.7-30.7 30.7v706.6c0 17 13.7 30.7 30.7 30.7h706.6c17 0 30.7-13.7 30.7-30.7V765.1c0-4.3-3.5-7.7-7.7-7.7zm18.6-251.7L765 393.7c-5.3-4.2-13-.4-13 6.3v76H438c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z" />
  </svg>
);

class SiderLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      //openKeys: [],
      lastNumberOfNotifications: 0,
    };
  }

  componentDidMount() {
    this.props.fetchNotifications(0).then(() => {
      this.inverval = setInterval(() => {
        this.props
          .fetchNotifications(
            this.props.notifications.length > 0
              ? this.props.notifications[this.props.notifications.length - 1].id
              : 0,
          )
          .then(newNotifications => {
            for (const notificationToShow of newNotifications) {
              notification.info({
                message: notificationToShow.title,
                description: notificationToShow.text,
                duration: null,
              });
            }
          });
      }, 8000);
    });
  }

  componentWillUnmount() {
    clearInterval(this.inverval);
  }

  handleCollapseClicked = value => {
    this.setState({
      collapsed: value,
    });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      //openKeys: [],
    });
  };

  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    const productionProcessType = this.props.domainSettings
      ? this.props.domainSettings.find(setting => setting.id === 1).value
      : null;
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "" && productionProcessType) {
        if (!prop.disabledForProductionProcessTypes) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          if (
            !productionProcessType ||
            prop.disabledForProductionProcessTypes.includes(
              productionProcessType,
            )
          ) {
            return null;
          } else
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
        }
      } else {
        return null;
      }
    });
  };

  handleLogOutClick = () => {
    this.props.onLogoutClick();
  };

  render() {
    const {
      user,
      notifications,
      deleteNotification,
      markNotificationAsRead,
      history,
      me
    } = this.props;

    let menu = (
      <Menu>
        <Menu.Item onClick={() => history.push(`/user/${me.id}`)}>
          <span>Учётная запись</span>
        </Menu.Item>
        <Menu.Item onClick={this.handleLogOutClick}>
          <span>Выйти</span>
        </Menu.Item>
      </Menu>
    );

    const createLink = notificationItem => {
      switch (notificationItem.notificationType_id) {
        case notificationType.FABSTOCK_MOVEMENT_REQUEST_CREATE: {
          return (
            "/fabstock/movementrequest/" +
            notificationItem.notificationData[0].value
          );
        }
        case notificationType.FABSTOCK_MOVEMENT_REQUEST_APPROVE: {
          return "/fabstock/" + notificationItem.notificationData[0].value;
        }
        case notificationType.FABSTOCK_MOVEMENT_REQUEST_DECLINE: {
          return "/fabstock";
        }
      }
    };

    const notificationsContent = notifications ? (
      <div
        style={{
          width: "300px",
          maxHeight: "500px",
          overflow: "scroll",
          marginRight: "-10px",
          paddingRight: "10px",
        }}
      >
        {notifications.map((notificationItem, notificationId) => (
          <div>
            <div style={{ width: "100%" }} key={notificationId}>
              <div
                style={{
                  display: "inline",
                  float: "left",
                  width: "5%",
                  textAlign: "center",
                }}
              >
                {!notificationItem.isSeen && (
                  <Badge
                    count={1}
                    dot={true}
                    showZero={true}
                    offset={[2, 1]}
                    color="green"
                  />
                )}
              </div>
              <div style={{ display: "inline", float: "left", width: "85%" }}>
                <label style={{ fontWeight: "bold" }}>
                  {notificationItem.title.slice(0, 30)}
                </label>
                <br />
                <text>{notificationItem.text}</text>
              </div>
              <div
                style={{
                  display: "inline",
                  float: "right",
                  width: "9%",
                  textAlign: "center",
                }}
              >
                <a
                  onClick={() => {
                    this.setState({
                      lastNotificationNumber:
                        this.state.lastNotificationNumber - 1,
                    });
                    deleteNotification(notificationItem.id);
                  }}
                  style={{
                    color: "red",
                  }}
                >
                  X
                </a>
                <br />
                {!notificationItem.isSeen && (
                  <a
                    onClick={() => markNotificationAsRead(notificationItem.id)}
                  >
                    <LegacyIcon type={"eye"} style={{ color: "#52c41a" }} />
                  </a>
                )}
                <a
                  onClick={() => {
                    markNotificationAsRead(notificationItem.id);
                    this.props.history.push(createLink(notificationItem));
                  }}
                >
                  <Icon component={linkSvg} />
                </a>
              </div>
            </div>
            {notificationId !== notifications.length - 1 && (
              <Divider style={{ top: "1em" }} />
            )}
          </div>
        ))}
      </div>
    ) : null;

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <CustomSider
          //openKeys={this.state.openKeys}
          // onOpenChange={this.onOpenChange}
          user={user}
          domainSettings={this.props.domainSettings}
          collapseClicked={this.handleCollapseClicked}
          collapsed={this.state.collapsed}
          routes={routes}
        />
        <Layout
          style={{
            marginLeft: this.state.collapsed ? (isMobile() ? 0 : 80) : 256,
          }}
        >
          <Header className="myHeader">
            <LegacyIcon
              className="trigger headerTrigger"
              type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
              onClick={this.toggle}
            />

            <span style={{ float: "right", paddingRight: 20 }}>
              <Dropdown className="userInfo" overlay={menu}>
                <span>
                  <Avatar size="small" icon={<UserOutlined />} />
                  &nbsp;&nbsp;
                  {user ? user.first_name + " " + user.last_name : ""}
                </span>
              </Dropdown>
            </span>

            <Popover
              placement={"bottom"}
              content={
                notifications.length > 0
                  ? notificationsContent
                  : "Новых уведомлений нет"
              }
              title={null}
              trigger={"hover"}
            >
              <span
                style={{ float: "right", marginRight: "20px" }}
                className="notification"
              >
                <Badge
                  count={this.props.notifications.reduce(
                    (acc, notificationItem) => acc + !notificationItem.isSeen,
                    0,
                  )}
                >
                  <Icon component={bellSvg} />
                </Badge>
              </span>
            </Popover>
          </Header>

          <Content
            style={{
              margin: isMobile() ? null : "24px 16px 0",
              overflow: "initial",
            }}
          >
            <div
              style={
                isMobile()
                  ? { padding: "10px 10px 10px 10px" }
                  : { paddingLeft: 24, paddingRight: 24 }
              }
            >
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }} />
        </Layout>
      </Layout>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    me: state.data.users.user.user,
    notifications: state.notifications,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    deleteNotification: notificationId =>
      dispatch(deleteNotification(notificationId)),
    markNotificationAsRead: notificationId =>
      dispatch(markNotificationAsRead(notificationId)),
    fetchNotifications: id => dispatch(fetchNotifications(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiderLayout),
);
