import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ProductionDetail from "./components/ProductionDetail";
import ProductionMain from "./index";

class ProductionRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/production" component={ProductionMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={ProductionDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={ProductionDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default ProductionRoute;
