import "./index.css";

import axios from "axios";
import { css } from "emotion";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import AsyncSelect from "react-select/lib/Async";
import thumbnail from "scenes/placeholder.png";

import OrderValue from "../../components/OrderValue";
import { fetchOrder } from "../../data/orders/actions";
import { getOrder } from "../../data/orders/reducer";
import {
  setOpenedCuttingOrder,
  setSelectedOrderValue,
  setSelectedProdBomId,
} from "./actions";
import CuttingDetail from "./CuttingDetail";
import { getOpenedCuttingOrder } from "./selectors";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

class CuttingMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  handleOrderSelect = order => {
    const { setOpenedOrder, setSelectedOrderValue, history } = this.props;

    if (order) {
      setSelectedOrderValue(order);
      //this.props.history.push(`/cutting/` + order.id)

      let prodBomId = undefined;
      //console.log("value",value);
      if (order.prodBoms && order.prodBoms.length > 0) {
        prodBomId = order.prodBoms[0].id;
      }

      history.push({
        pathname: "/cutting/" + order.id,
        state: { prodBomId: prodBomId },
      });
    } else {
      setSelectedOrderValue(null);
      this.props.history.push(`/cutting`);
    }
  };

  getOrderOptions = function(input, callback) {
    if (!input) {
      return Promise.resolve([]);
    }
    return (
      axios(`/api/production_orders/search?q=${input}`)
        //.then((response) => response.json())
        .then(({ data }) => {
          return data.map(item => {
            return {
              value: item.id,
              label: item.orderNumberStr,
              ...item,
            };
          });
        })
    );
  };

  handleFilterOption = (option, filterString) => {
    return true;
  };

  render() {
    const { selectedOrderValue } = this.props;
    //console.log("YES ROUTED OPENED ", openedOrder)

    const isCompleted =
      selectedOrderValue !== null && selectedOrderValue.is_completed === 1;
    return (
      <div>
        <div>
          {isCompleted === true && (
            <h2> Карта производства [Заказ завершён]</h2>
          )}
          {isCompleted === false && <h2> Карта производства </h2>}
          <AsyncSelect
            placeholder={"Печатайте для поиска..."}
            onChange={this.handleOrderSelect}
            components={{ Option }}
            loadOptions={this.getOrderOptions}
            value={selectedOrderValue}
            classNamePrefix="cutting"
            name="form-field-name"
            valueComponent={OrderValue}
            filterOption={this.handleFilterOption}
            cache={false}
            styles={customStyles}
            noOptionsMessage={() => {
              return "Нет денных";
            }}
          />
        </div>
        <br />

        <Route
          exact
          path={`${this.props.match.url}/:orderId?`}
          component={CuttingDetail}
        />
      </div>
    );
  }
}

const Option = props => {
  const {
    innerProps,
    innerRef,
    isDisabled,
    isFocused,
    isSelected,
    data,
    className,
    cx,
    getStyles,
  } = props;

  // const handleMouseDown = event => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   innerProps.onClick(this.props.data, event);
  // };
  // const handleMouseEnter = event => {
  //   innerRef.onFocus(this.props.data, event);
  // };
  // const handleMouseMove = event => {
  //   if (innerRef.isFocused) return;
  //   innerRef.onFocus(this.props.data, event);
  //
  // };

  const sumLineItems = lineItems => {
    let total = 0;
    lineItems.forEach(item => (total += item.quantity));
    return total;
  };

  const addDefaultSrc = ev => {
    ev.target.src = thumbnail;
  };

  return (
    <div
      className={cx(
        css(getStyles("option", props)),
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className,
      )}
      {...innerProps}
    >
      {/*<Gravatar email={this.props.option.email} size={GRAVATAR_SIZE} style={gravatarStyle} />*/}

      <div className="select2-result-repository clearfix">
        <div className="select2-result-repository__avatar">
          <img
            onError={addDefaultSrc}
            src={props.data.product.img_small_path}
          />
        </div>
        <div className="select2-result-repository__meta">
          <div className="select2-result-repository__title">
            Заказ: {props.children}{" "}
          </div>

          {props.data.product.name && (
            <div className="select2-result-repository__description">
              {props.data.product.article}: {props.data.product.name}
            </div>
          )}

          <div className="select2-result-repository__statistics">
            <div className="select2-result-repository__forks">
              <i className="fa fa-flash" /> {sumLineItems(props.data.lineItems)}{" "}
              Штук
            </div>
            {/* <div className='select2-result-repository__stargazers'><i className='fa fa-calendar'></i> Дата: {this.getFormattedDate(this.props.option.created_at)}</div> */}
            {/* <div className='select2-result-repository__watchers'><i className='fa fa-eye'></i>  5 Watchers</div> */}
          </div>
        </div>
      </div>

      {/*{this.props.option.productDoc[0].name}*/}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedOrderValue: state.scenes.cutting.selectedOrderValue,
  };
};
// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setOpenedCuttingOrder: order => dispatch(setOpenedCuttingOrder(order)),
    fetchOrder: id => dispatch(fetchOrder(id)),
    setSelectedProdBomId: id => dispatch(setSelectedProdBomId(id)),
    setSelectedOrderValue: order => dispatch(setSelectedOrderValue(order)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CuttingMain);
