import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";

export const SPLIT_ENTRIES_FETCH_SUCCESS = "SPLIT_ENTRIES_FETCH_SUCCESS";
export const SPLIT_ENTRY_FETCH_SUCCESS = "SPLIT_ENTRY_FETCH_SUCCESS";
export const SPLIT_ENTRY_CREATE_SUCCESS = "SPLIT_ENTRY_CREATE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchSplitEntries = () => {
  return dispatch => {
    return axios
      .get(`/api/split_entry/`)
      .then(({ data }) => {
        let entries = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: SPLIT_ENTRIES_FETCH_SUCCESS,
          response: normalize(entries, schema.arrayOfSplitEntries),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchSplitEntry = entryId => {
  return dispatch => {
    return axios
      .get(`/api/split_entry/` + entryId)
      .then(({ data }) => {
        let entry = data;
        let id = entryId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: SPLIT_ENTRY_FETCH_SUCCESS,
          response: normalize(entry, schema.splitEntry),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createSplitEntry = entry => {
  return dispatch => {
    return axios
      .post("/api/split_entry/", entry)
      .then(({ data }) => {
        let entry = data;
        dispatch({
          type: SPLIT_ENTRY_CREATE_SUCCESS,
          response: normalize(entry, schema.splitEntry),
        });
        openNotificationWithIcon(
          "Успешно",
          "Штрих код успешно разбит",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
