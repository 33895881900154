import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";

export const CUTTING_ENTRIES_FETCH_SUCCESS = "CUTTING_ENTRIES_FETCH_SUCCESS";
export const CUTTING_ENTRY_CREATE_SUCCESS = "CUTTING_ENTRY_CREATE_SUCCESS";
export const CUTTING_ENTRY_UPDATE_SUCCESS = "CUTTING_ENTRY_UPDATE_SUCCESS";
export const CUTTING_ENTRY_DELETE_SUCCESS = "CUTTING_ENTRY_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchCuttingEntriesForOrder = orderId => {
  return dispatch => {
    return axios
      .get(`/api/cutting_entry/` + orderId)
      .then(({ data }) => {
        let cuttingEntries = data;
        dispatch({
          type: CUTTING_ENTRIES_FETCH_SUCCESS,
          response: normalize(cuttingEntries, schema.arrayOfCuttingEntries),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createCuttingEntry = (orderId, cuttingEntry) => {
  return dispatch => {
    return axios
      .post("/api/cutting_entry/" + orderId, cuttingEntry)
      .then(({ data }) => {
        let addedEntry = data;
        let prodSpreadId = addedEntry.prodSpread_id;
        dispatch({
          type: CUTTING_ENTRY_CREATE_SUCCESS,
          response: normalize(addedEntry, schema.cuttingEntry),
          prodSpreadId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateCuttingEntry = (orderId, cuttingEntry) => {
  return dispatch => {
    return axios
      .put("/api/cutting_entry/" + orderId, cuttingEntry)
      .then(({ data }) => {
        let updatedEntry = data;
        dispatch({
          type: CUTTING_ENTRY_UPDATE_SUCCESS,
          response: normalize(updatedEntry, schema.cuttingEntry),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно обновлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteCuttingEntry = entry => {
  return dispatch => {
    return axios
      .delete("/api/cutting_entry/" + entry.id, { idToDelete: entry.id })
      .then(({ data }) => {
        //let spreads=json;
        let cuttingEntryId = entry.id;
        let prodSpreadId = entry.prodSpread_id;
        dispatch({
          type: CUTTING_ENTRY_DELETE_SUCCESS,
          cuttingEntryId,
          prodSpreadId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
