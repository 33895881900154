import "./index.css";

import { id as ProductionProcessTypeId } from "@app/shared/constants/productionProcessTypes";
import Icon, {CheckOutlined, DeleteOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import {Tabs, Button, Dropdown, Menu, Popconfirm, Tag, notification, Select} from "antd";
import ReactTableAsync from "components/ReactTableAsync";
import {
  completeOrder,
  deleteOrder,
  discardOrderCompletion,
  fetchOrder,
  fetchOrders,
  setCurrentOrderPage,
  setOrderPageSize, updateOrderStatus,
} from "data/orders/actions";
import { getOrdersForCurrentPage, isPageInCache } from "data/orders/selectors";
import _ from "lodash";
import matchSorter from "match-sorter";
import moment from "moment";
import React, {Component, createRef} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import orderStatusLabels from "@app/shared/constants/orderStatusLabels";
import { COMPLETED, NOT_STARTED, CUT, SEWED, BEING_SEWED } from "@app/shared/constants/orderStatuses";
import { fetchProdDepotLocations } from "../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../data/prodDepotLocations/reducer";
import {
  setOrderIdForCompletionModal,
  setShowCompletionModal,
} from "./actions";
import CompletionOrderModal from "./components/CompletionOrderModal/CompletionOrderModal";
import PivotComponent from "./components/PivotComponent";
const { TabPane } = Tabs;

const warningSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="warning"
    width="1em"
    height="1em"
    fill="currentColor"
    color={"orange"}
    aria-hidden="true"
  >
    <path d="M464 720a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z" />
  </svg>
);

class ProductionMain extends Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
      searchEnabled: false,
      currentTab: props.activeTabKey ? props.activeTabKey : NOT_STARTED,
      orderIdForWhichStatusIsBeingChanged: null
    };
  }

  componentDidMount() {
    const { fetchProductions, match, fetchProdDepotLocations } = this.props;
    fetchProdDepotLocations();
  }

  render() {
    const {
      history,
      ordersForCurrentPage,
      fetchOrders,
      setCurrentOrderPage,
      deleteProduction,
      pageSize,
      isPageInCache,
      currentPage,
      setOrderPageSize,
      fetchOrder,
      quantityByOrderStatus,
      setOrderIdForCompletionModal,
      setShowCompletionModal,
      prodDepotLocations,
      completeOrder,
      discardCompletion,
    } = this.props;

    const onProductionCreate = () => {
      history.push(`/production/new`);
    };
    const handleToggleSearch = () => {
      this.setState({ searchEnabled: !this.state.searchEnabled });
    };

    const handleCompletion = order => {
      setOrderIdForCompletionModal(order.id);
      setShowCompletionModal(true);
    };

    const handleDiscardCompletion = order => {
      discardCompletion(order.id);
    };

    const onEditClick = row => {
      history.push(`/production/` + row.id);
    };
    const handleProductionView = (value, e) => {
      history.push(`/cutting/` + value.id);

      let prodBomId = undefined;
      if (value.prodBoms && value.prodBoms.length > 0) {
        prodBomId = value.prodBoms[0].id;
      }

      history.push({
        pathname: "/cutting/" + value.id,
        state: { prodBomId: prodBomId },
      });
    };

    const confirm = (row, e) => {
      deleteProduction(row.id).then(data => {
        fetchOrders(currentPage, pageSize, undefined, {
          orderStatusFilter: this.state.currentTab
        });
      });
    }

    const isContainUnassignedOperation = sewingPassportEntry => {
      for (const prodBom of sewingPassportEntry.order.prodBoms) {
        for (const prodOperation of prodBom.prodOperations.filter(
          op => op.operationType_id !== 1,
        )) {
          if (
            !sewingPassportEntry.wages.find(
              wage => wage.prodOperation_id === prodOperation.id,
            )
          ) {
            return true;
          }
        }
      }
      return false;
    };

    function cancel(e) {}

    const columns = [
      {
        id: 10,
        Header: "",
        accessor: "product.name",
        width: 25,
        Cell: row => (
          <div>{row.original.is_completed === 1 && <CheckOutlined />}</div>
        ),
        filterable: false,
        sortable: false,
      },
      {
        id: 1,
        Header: "№",
        accessor: "id", // String-based value accessors!,
        width: 90,
        Cell: row => (
          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/production/" + row.original.id}
          >
            {"PR-" + row.value}
          </a>
        ),
        filterable: false,
      },
      {
        id: 2,
        Header: "Название",
        accessor: "name", // String-based value accessors!,
        width: 200,
        Cell: row => <span className="number">{row.original.name}</span>,
        filterable: false,
        sortable: false,
      },
      {
        id: 3,
        Header: "Дата",
        accessor: "start_date", // String-based value accessors!,
        width: 100,
        Cell: row =>
          row.value && (
            <span className="number">
              {new moment(row.value).format("DD/MM/YYYY")}
            </span>
          ),
        filterable: false,
        sortable: false,
      },
      {
        id: "productName",
        Header: "Модель",
        accessor: "product.name",
        width: 240,
        Cell: row => <span className="number">{row.value}</span>,
        filterMethod: (filter, rows) => {
          //console.log("filter",rows)
          return matchSorter(rows, filter.value, { keys: ["productName"] });
        },
        filterAll: true,
        // Custom cell components!
      },
      {
        id: 5,
        Header: "Количество",
        accessor: "total",
        width: 90,
        Cell: row => <span className="number">{row.value}</span>,
        filterable: false,
        sortable: false,
        // Custom cell components!
      },
      {
        id: 11,
        Header: "Статус",
        accessor: "orderStatus_id",
        width: 180,
        Cell: row => {
          if(row.original.id === this.state.orderIdForWhichStatusIsBeingChanged) {
              return ( <Select
                placeholder={"Статус"}
                size={"small"}
                value={row.original.orderStatus_id}
                onChange={(id) => {
                  this.props.updateOrderStatus(row.original.id, id).then(() => {
                    this.setState({
                      orderIdForWhichStatusIsBeingChanged: null
                    }, () => {
                      this.tableRef.current.fireFetchData()
                    })
                  })
                }}
                style={{
                  width: "100%"
                }}
            >
              {Object.keys(orderStatusLabels).map(status_id => (
                  <Select.Option value={Number(status_id)}>
                    {orderStatusLabels[status_id]}
                  </Select.Option>
              ))}
            </Select>)
          } else {
            return (
                <div style={
                  {
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }
                }
                >
                  <span className="number">{row.value ? orderStatusLabels[row.value] : ""}</span>
                  <div>
                    <EditOutlined style={{float: "right"}} onClick={() => {
                      this.setState({
                        orderIdForWhichStatusIsBeingChanged: row.original.id
                      })
                    }}
                    />
                  </div>
                </div>
            )
          }
        },
        filterable: false,
        sortable: false,
      },
      {
        id: 6,
        Header: "Прогресс",
        accessor: "product.prodBoms",
        minWidth: 250,
        Cell: row => (
          <span>
            {row.original.productionProcessType_id === 1 &&
              row.original.prodBoms.map((value, index) => (
                <Tag key={index}>
                  {value.countOfCuttings === null ? (
                    <span className="dotOff" />
                  ) : (
                    <span className="dotOn" />
                  )}
                  {value.countOfEntries === null ? (
                    <span className="dotOff" />
                  ) : (
                    <span className="dotOn" />
                  )}
                  {" " + value.name.slice(0, 3)}
                </Tag>
              ))}
            {row.original.productionProcessType_id ===
              ProductionProcessTypeId.PASSPORT && (
              <span>
                <Tag color={row.original.cuttingPassportEntry ? "green" : null}>
                  Крой. паспорт
                </Tag>
                <Tag
                  color={
                    row.original.sewingPassportEntry
                      ? isContainUnassignedOperation(
                          row.original.sewingPassportEntry,
                        )
                        ? "orange"
                        : "green"
                      : null
                  }
                >
                  {row.original.sewingPassportEntry &&
                    isContainUnassignedOperation(
                      row.original.sewingPassportEntry,
                    ) && (
                      <Icon
                        style={{ marginRight: "5px" }}
                        component={warningSvg}
                      />
                    )}
                  Швей. паспорт
                </Tag>
              </span>
            )}
            {row.original.productionProcessType_id ===
              ProductionProcessTypeId.PASSPORT_WITH_FIXED_SALARY && (
              <span>
                <Tag color={row.original.cuttingPassportEntry ? "green" : null}>
                  Крой. паспорт
                </Tag>
                <Tag color={row.original.sewingPassportEntry ? "green" : null}>
                  Швей. паспорт
                </Tag>
              </span>
            )}
          </span>
        ),
        filterable: false,
        sortable: false,
      },
      {
        id: 7,
        Header: "",
        accessor: "id",
        maxWidth: 100,
        Cell: row => (
          <div>
            <Dropdown
              overlay={
                <Menu>
                  {!row.original.is_completed && (
                    <Menu.Item onClick={() => handleCompletion(row.original)}>
                      Завершить заказ
                    </Menu.Item>
                  )}
                  {row.original.is_completed && (
                    <Menu.Item onClick={null}>
                      <Popconfirm
                        title={
                          "Вы действительно хотите отменить завершение заказа?"
                        }
                        onConfirm={() => handleDiscardCompletion(row.original)}
                        onCancel={null}
                      >
                        Отменить завершение заказа
                      </Popconfirm>
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomCenter"
            >
              <Tag>...</Tag>
            </Dropdown>
          </div>
        ),
        sortable: false,
        filterable: false,
        width: 35,
      },
      {
        id: 8,
        Header: "Карта",
        accessor: "id",
        width: 60,
        Cell: row => (
          <div>
            <a
              onClick={e => {
                e.preventDefault();
                handleProductionView(row.original, e);
              }}
              href={"/cutting/" + row.value}
            >
              Карта
            </a>
          </div>
        ),
        sortable: false,
        filterable: false,
      },
      {
        id: 9,
        Header: "",
        accessor: "product.name",
        width: 40,
        Cell: row => (
          <div>
            <Popconfirm
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => confirm(row.original, e)}
              onCancel={cancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="danger" size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
        filterable: false,
        sortable: false,
      },
    ];

    return (
      <div>
        <h2>Заказы на производство</h2>
        <Button type="primary" onClick={onProductionCreate}>
          Создать
        </Button>
        &nbsp;&nbsp;
        <Button type="secondary" icon={<SearchOutlined />} onClick={handleToggleSearch} />
        &nbsp;&nbsp;
        <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => history.push("/logs/order")}>
                  Посмотреть в журнале
                </Menu.Item>
              </Menu>
            }
        >
          <Button>...</Button>
        </Dropdown>
        <br />
        &nbsp;
        <Tabs activeKey={this.state.currentTab} onChange={(tab) => {
          this.setState({ currentTab: tab }, () => {
            this.tableRef.current.fireFetchData()
          })
        }}>
          {Object.keys(orderStatusLabels).map(key => {
              const quantity = quantityByOrderStatus[key];
              return (<TabPane key={key} tab={quantity ?
                  `${orderStatusLabels[key]} (${quantity})`
                  : orderStatusLabels[key]
              }/>)
            })}
        </Tabs>
        <ReactTableAsync
          ref={this.tableRef}
          columns={columns}
          fetchOptions={{
              orderStatusFilter: this.state.currentTab
          }}
          isLoading={!isPageInCache}
          searchEnabled={this.state.searchEnabled}
          dataForCurrentPage={ordersForCurrentPage}
          onFetchDataForPage={fetchOrders}
          onSetCurrentDataPage={setCurrentOrderPage}
          onDelete={deleteProduction}
          getTrProps={(state, rowInfo, instance) => {
            if (rowInfo && rowInfo.original.cuttingPassportEntry) {
              const realPrice = rowInfo.original.cuttingPassportEntry.realPrice
              return {
                style: {
                  background: realPrice
                      ? realPrice > rowInfo.original.product.estimatedPrice
                          ? "#ffe8e7" : undefined
                      : "#ffffed",
                }
              }
            }
            return {};
          }}
          onSetPageSize={setOrderPageSize}
          SubComponent={row => {
            return (
              <div style={{ padding: "20px" }}>
                <em>
                  <PivotComponent orderId={row.original.id} />
                </em>
              </div>
            );
          }}
        />
        <CompletionOrderModal
          prodDepotLocationOptions={prodDepotLocations}
          completeOrder={completeOrder}
        />
        <br />
        &nbsp;
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let activeProdTab = null;
  if(state.userUIConfigs) {
      const tabConfig = state.userUIConfigs.find(config => config.key === "activeProdTab");
      if(tabConfig) {
        activeProdTab = tabConfig.value;
      }
  }

  return {
    activeTabKey: activeProdTab,
    quantityByOrderStatus: state.data.orders.quantityByOrderStatus,
    isPageInCache: isPageInCache(state),
    pageSize: state.data.orders.pageSize,
    currentPage: state.data.orders.currentPage,
    ordersForCurrentPage: getOrdersForCurrentPage(state),
    showCompletionModal: state.scenes.production.showCompletionModal,
    prodDepotLocations: getAllProdDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrders: (page, pageSize, filtered, fetchOptions) =>
      dispatch(fetchOrders(page, pageSize, filtered, fetchOptions)),
    fetchOrder: orderId => dispatch(fetchOrder(orderId)),
    setCurrentOrderPage: page => dispatch(setCurrentOrderPage(page)),
    deleteProduction: productionId => dispatch(deleteOrder(productionId)),
    setOrderPageSize: productionId => dispatch(setOrderPageSize(productionId)),
    setShowCompletionModal: showCompletionModal =>
      dispatch(setShowCompletionModal(showCompletionModal)),
    setOrderIdForCompletionModal: orderId =>
      dispatch(setOrderIdForCompletionModal(orderId)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
    completeOrder: formData => dispatch(completeOrder(formData)),
    discardCompletion: orderId => dispatch(discardOrderCompletion(orderId)),
    updateOrderStatus: (id, statusId) => dispatch(updateOrderStatus(id, statusId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProductionMain),
);
