"use strict";

module.exports.makeBaseValidator = (
  baseSchema,
  { message, required = false, default: defaultValue = null } = {},
) => {
  let schema = baseSchema
    .typeError(message)
    .nullable(!required)
    .default(defaultValue);

  if (required) {
    schema = schema.required(message);
  }

  return schema;
};
