import { Breadcrumb, Button, Layout, Menu } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import ContractorTable from "./components/ContractorTable";
import { deleteContractor, fetchContractors } from "./data/contractors/actions";
import { getAllContractors } from "./data/contractors/reducer";
import ClientTable from "../Client/components/ClientTable";

//import ProductsTable from './ProductsTable'

//import {  setOpenedContractorId } from '../actions';

function ContractorMain(props) {
  // Declare a new state variable, which we'll call "count"
  const { history, contractors, fetchContractors, deleteContractor } = props;

  const [loading, setLoading] = useState(() => {
    if (contractors.length > 0) {
      return false;
    } else return true;
  });
  const [searchEnabled, setSearchEnabled] = useState(false);
  //const [fabDepotEntries, setFabDepotEntries] = useState(0);
  const [count, setCount] = useState(0);

  const onContractorCreate = () => {
    history.push(`/contractor/new`);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/contractor/` + contractor.id);
  };

  const onRemove = value => {
    // const {deleteDepotLocation} =props;
    deleteContractor(value.id);
    //history.push(`/product-admin/`)
  };

  useEffect(() => {
    fetchContractors().then(depot => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Подрядчики</h2>
        <Button type="primary" onClick={onContractorCreate}>
          Создать
        </Button>
      </div>
      <br />

      <ContractorTable
        onEditClick={handleEditClick}
        onRemove={onRemove}
        showBalance={!!props.roles.find(role => role.id === 6 || role.id === 1)}
        contractors={contractors}
      />
      {/*<DepotEntryTable loading={loading} onEditClick={handleEditClick} onRemove={onRemove} depotLocations={depotLocations} />*/}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    roles: state.data.users.user.user.roles,
    contractors: getAllContractors(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchContractors: () => dispatch(fetchContractors()),
    deleteContractor: id => dispatch(deleteContractor(id)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractorMain),
);

//export default ContractorMain
