import { combineReducers } from "redux";

import {
  CUTTING_ENTRIES_FETCH_SUCCESS,
  CUTTING_ENTRY_CREATE_SUCCESS,
  CUTTING_ENTRY_DELETE_SUCCESS,
  CUTTING_ENTRY_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case CUTTING_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.cuttingEntries,
      };
    //   case 'CUTTING_ENTRY_FETCH_DATA_SUCCESS':
    //        return {
    //           ...state, ...action.response.entities.cuttingEntries
    //       }
    case CUTTING_ENTRY_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.cuttingEntries,
      };
    case CUTTING_ENTRY_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.cuttingEntries,
      };
    case CUTTING_ENTRY_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.cuttingEntryId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

export const cuttingEntries = combineReducers({
  byId,
});

//SELECTORS
