import { combineReducers } from "redux";

import {
  MODAL_SELECTED_SPREAD_SET,
  OPENED_CUTTING_ENTRY_ID_SET,
  OPENED_CUTTING_ORDER_SET,
  OPENED_PROD_ENTRY_ID_SET,
  OPENED_WAGE_ID_SET,
  RESET_CUTTING_OPERATIONS_MODAL_KEY,
  RESET_CUTTING_PASSPORT_KEY,
  RESET_CUTTING_SUBMIT_PASSPORT_KEY,
  RESET_PROD_ENTRY_DETAIL_KEY,
  RESET_SEWING_OPERATIONS_MODAL_KEY,
  RESET_SEWING_PASSPORT_KEY,
  RESET_SEWING_SUBMIT_PASSPORT_KEY,
  SELECTED_PRODBOM_SET,
  SET_SELECTED_ORDER_VALUE,
  SHOW_ADD_OPERATION_MODAL_SET,
  SHOW_CUTTING_ENTRY_MODAL_SET,
  SHOW_CUTTING_OPERATIONS_MODAL_SET,
  SHOW_CUTTING_PASSPORT_MODAL,
  SHOW_CUTTING_SUBMIT_PASSPORT_MODAL,
  SHOW_CUTTING_WAGE_MODAL_SET,
  SHOW_PROD_ENTRY_MODAL_SET,
  SHOW_PROD_WAGE_MODAL_SET,
  SHOW_SEARCH_EMPLOYEE_MODAL_SET,
  SHOW_SEARCH_FABRIC_MODAL_SET,
  SHOW_SEWING_OPERATIONS_MODAL_SET,
  SHOW_SEWING_PASSPORT_MODAL,
  SHOW_SEWING_SUBMIT_PASSPORT_MODAL,
  SHOW_WAGE_CLAIM_MODAL_SET,
    SHOW_REAL_PRICE_CALCULATION_MODAL,
    RESET_REAL_PRICE_CALCULATION_MODAL_KEY
} from "./actions";

const showCuttingEntryModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_CUTTING_ENTRY_MODAL_SET:
      return action.showCuttingEntryModal;

    default:
      return state;
  }
};

const showRealPriceCalculationModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_REAL_PRICE_CALCULATION_MODAL:
      return action.showRealPriceCalculationModal;

    default:
      return state;
  }
};

const showProdEntryModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_PROD_ENTRY_MODAL_SET:
      return action.showProdEntryModal;

    default:
      return state;
  }
};

const openedCuttingEntryId = (state = null, action) => {
  switch (action.type) {
    case OPENED_CUTTING_ENTRY_ID_SET:
      return action.cuttingEntryId;

    default:
      return state;
  }
};

const openedProdEntryId = (state = null, action) => {
  switch (action.type) {
    case OPENED_PROD_ENTRY_ID_SET:
      return action.prodEntryId;

    default:
      return state;
  }
};

const openedCuttingOrder = (state = null, action) => {
  switch (action.type) {
    case OPENED_CUTTING_ORDER_SET:
      return action.orderId;
    default:
      return state;
  }
};

const prodEntryDetailKey = (state = "prod_0", action) => {
  switch (action.type) {
    case RESET_PROD_ENTRY_DETAIL_KEY:
      return action.prodEntryDetailKey;
    default:
      return state;
  }
};

const cuttingSubmitPassportModalKey = (
  state = "cuttingSubmitPassport_0",
  action,
) => {
  switch (action.type) {
    case RESET_CUTTING_SUBMIT_PASSPORT_KEY:
      return action.cuttingSubmitPassportKey;

    default:
      return state;
  }
};

const sewingSubmitPassportModalKey = (
  state = "sewingSubmitPassport_0",
  action,
) => {
  switch (action.type) {
    case RESET_SEWING_SUBMIT_PASSPORT_KEY:
      return action.sewingSubmitPassportKey;

    default:
      return state;
  }
};

const cuttingPassportModalKey = (state = "cuttingPassport_0", action) => {
  switch (action.type) {
    case RESET_CUTTING_PASSPORT_KEY:
      return action.cuttingPassportKey;

    default:
      return state;
  }
};

const sewingPassportModalKey = (state = "sewingPassport_0", action) => {
  switch (action.type) {
    case RESET_SEWING_PASSPORT_KEY:
      return action.sewingPassportKey;

    default:
      return state;
  }
};

const realPriceCalculationModalKey = (state = "realPrice_0", action) => {
  switch (action.type) {
    case RESET_REAL_PRICE_CALCULATION_MODAL_KEY:
      return action.realPriceCalculationModalKey;

    default:
      return state;
  }
};

const cuttingOperationsModalKey = (state = "cut_0", action) => {
  switch (action.type) {
    case RESET_CUTTING_OPERATIONS_MODAL_KEY:
      return action.cuttingOperationsModalKey;
    default:
      return state;
  }
};

const sewingOperationsModalKey = (state = "sew_0", action) => {
  switch (action.type) {
    case RESET_SEWING_OPERATIONS_MODAL_KEY:
      return action.sewingOperationsModalKey;
    default:
      return state;
  }
};

const openedWageId = (state = null, action) => {
  switch (action.type) {
    case OPENED_WAGE_ID_SET:
      return action.wageId;
    default:
      return state;
  }
};

const modalSelectedSpread = (state = null, action) => {
  switch (action.type) {
    case MODAL_SELECTED_SPREAD_SET:
      return action.spreadId;
    default:
      return state;
  }
};

const selectedProdBomId = (state = null, action) => {
  switch (action.type) {
    case SELECTED_PRODBOM_SET:
      return action.prodBomId;
    default:
      return state;
  }
};

const searchFabricIndices = (state = null, action) => {
  switch (action.type) {
    case SHOW_SEARCH_FABRIC_MODAL_SET:
      return {
        prodBomIndex: action.prodBomIndex,
        prodSpreadIndex: action.prodSpreadIndex,
        consumptionIndex: action.consumptionIndex,
      };

    default:
      return state;
  }
};

const addOperationIndices = (state = null, action) => {
  switch (action.type) {
    case SHOW_ADD_OPERATION_MODAL_SET:
      return {
        prodBomIndex: action.prodBomIndex,
        prodBomId: action.prodBomId,
      };

    default:
      return state;
  }
};

const searchEmployeeIndices = (state = null, action) => {
  switch (action.type) {
    case SHOW_SEARCH_EMPLOYEE_MODAL_SET:
      return {
        prodBomIndex: action.prodBomIndex,
        prodSpreadIndex: action.prodSpreadIndex,
        operationIndex: action.operationIndex,
      };

    default:
      return state;
  }
};

const showSearchFabricModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_SEARCH_FABRIC_MODAL_SET:
      return action.showSearchFabricModal;

    default:
      return state;
  }
};

const showSearchEmployeeModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_SEARCH_EMPLOYEE_MODAL_SET:
      return action.showSearchEmployeeModal;

    default:
      return state;
  }
};

const showCuttingSubmitPassportModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_CUTTING_SUBMIT_PASSPORT_MODAL:
      return action.showCuttingSubmitPassportModal;

    default:
      return state;
  }
};

const showSewingSubmitPassportModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_SEWING_SUBMIT_PASSPORT_MODAL:
      return action.showSewingSubmitPassportModal;

    default:
      return state;
  }
};

const showCuttingPassportModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_CUTTING_PASSPORT_MODAL:
      return action.showCuttingPassportModal;

    default:
      return state;
  }
};

const showSewingPassportModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_SEWING_PASSPORT_MODAL:
      return action.showSewingPassportModal;

    default:
      return state;
  }
};

const showAddOperationModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_ADD_OPERATION_MODAL_SET:
      return action.showAddOperationModal;

    default:
      return state;
  }
};

const showCuttingWageModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_CUTTING_WAGE_MODAL_SET:
      return action.showCuttingWageModal;

    default:
      return state;
  }
};

const showProdWageModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_PROD_WAGE_MODAL_SET:
      return action.showProdWageModal;

    default:
      return state;
  }
};

const showWageClaimModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_WAGE_CLAIM_MODAL_SET:
      return action.showWageClaimModal;

    default:
      return state;
  }
};

const showCuttingOperationsModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_CUTTING_OPERATIONS_MODAL_SET:
      return action.showCuttingOperationsModal;

    default:
      return state;
  }
};

const showSewingOperationsModal = (state = false, action) => {
  switch (action.type) {
    case SHOW_SEWING_OPERATIONS_MODAL_SET:
      return action.showSewingOperationsModal;

    default:
      return state;
  }
};

const selectedOrderValue = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_ORDER_VALUE:
      return action.selectedOrderValue;

    default:
      return state;
  }
};

const showCompletionModalForCutting = (state = false, action) => {
  switch (action.type) {
    case "SHOW_COMPLETION_MODAL_FOR_CUTTING":
      return action.showCompletionModal;
    default:
      return state;
  }
};

const orderIdForCompletionModalForCutting = (state = null, action) => {
  switch (action.type) {
    case "SET_ORDER_ID_FOR_COMPLETION_MODAL_FOR_CUTTING":
      return action.orderId;
    default:
      return state;
  }
};

export const getOrderForCompletionModalForCutting = state => {
  if (state.scenes.cutting.orderIdForCompletionModalForCutting === null)
    return null;
  else {
    const currentOrder =
      state.data.orders.byId[
        state.scenes.cutting.orderIdForCompletionModalForCutting
      ];
    return {
      ...currentOrder,
      prodBoms: currentOrder.prodBoms.map(
        prodBom => state.data.prodBoms.byId[prodBom],
      ),
    };
  }
};

export const reducer = combineReducers({
  addOperationIndices,
  showAddOperationModal,
  sewingPassportModalKey,
  showSewingPassportModal,
  showSewingSubmitPassportModal,
  sewingSubmitPassportModalKey,
  showSearchFabricModal,
  searchFabricIndices,
  searchEmployeeIndices,
  showSearchEmployeeModal,
  showCuttingSubmitPassportModal,
  cuttingSubmitPassportModalKey,
  cuttingPassportModalKey,
  showCuttingPassportModal,
  openedCuttingOrder,
  modalSelectedSpread,
  showWageClaimModal,
  openedWageId,
  showCuttingWageModal,
  showCuttingEntryModal,
  showProdEntryModal,
  openedCuttingEntryId,
  openedProdEntryId,
  selectedProdBomId,
  showProdWageModal,
  prodEntryDetailKey,
  selectedOrderValue,
  showCuttingOperationsModal,
  showSewingOperationsModal,
  cuttingOperationsModalKey,
  sewingOperationsModalKey,
  orderIdForCompletionModalForCutting,
  showCompletionModalForCutting,
  showRealPriceCalculationModal,
  realPriceCalculationModalKey
});
