"use strict";

const { makeBaseValidator } = require("../utils/ValidatorUtils");
const { date } = require("yup");

module.exports = ({
  max,
  min,
  message,
  required,
  default: defaultValue,
} = {}) => {
  let schema = makeBaseValidator(date(), {
    message,
    required,
    default: defaultValue,
  });

  if (min != null) {
    schema = schema.min(min, message);
  }

  if (max != null) {
    schema = schema.max(max, message);
  }

  return schema;
};
