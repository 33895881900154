"use strict";

const moment = require("moment");

moment.locale("ru");
moment.updateLocale("ru", require("moment/locale/ru"));

module.exports.formatMonth = month =>
  moment()
    .month(month)
    .format("MMMM");

module.exports.formatWeekDay = weekDay =>
  moment()
    .day(weekDay)
    .format("dddd");

module.exports.formatTime = time => moment(time, "HH:mm:ss").format("HH:mm");

module.exports.formatMonthWithDate = (month, date) =>
  moment()
    .month(month)
    .date(date)
    .format("Do MMMM");
