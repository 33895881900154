import { combineReducers } from "redux";

import {
  CONTRACTORS_FETCH_SUCCESS,
  CONTRACTOR_CREATE_SUCCESS,
  CONTRACTOR_DELETE_SUCCESS,
  CONTRACTOR_FETCH_SUCCESS,
  CONTRACTOR_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case CONTRACTORS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.contractors,
      };
    case CONTRACTOR_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.contractors,
      };
    case CONTRACTOR_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.contractors,
      };
    case CONTRACTOR_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.contractorId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case CONTRACTORS_FETCH_SUCCESS:
      return action.response.result;
    case CONTRACTOR_CREATE_SUCCESS:
      return [...state, action.response.result];
    case CONTRACTOR_DELETE_SUCCESS:
      return [...state.filter(id => id != action.contractorId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllContractors = state => {
  return state.scenes.contractor.data.contractors.allIds.map(
    id => state.scenes.contractor.data.contractors.byId[id],
  );
};

export const getContractor = (state, id) => {
  return state.scenes.contractor.data.contractors.byId[id];
};
