import { EllipsisOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Radio,
  Row,
  Table,
  Tabs,
} from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import ReactTable from "react-table";

import ProdEntriesTable from "../../../../../components/ProdEntriesTable";
import ProdSummaryTable from "../../../../../components/ProdSummaryTable";
import { deleteProdEntry } from "../../../../../data/prodEntries/actions";
import {
  resetCuttingOperationsModalKey,
  resetProdEntryDetailKey,
  resetSewingOperationsModalKey,
  setModalSelectedSpread,
  setOpenedProdEntryId,
  setShowCuttingOperationsModal,
  setShowCuttingWageModal,
  setShowProdEntryModal,
  setShowProdWageModal,
  setShowSewingOperationsModal,
} from "../../../actions";
import {
  getOpenedCuttingOrder,
  getProdEntriesForSelectedProdBom,
} from "../../../selectors";
import CuttingSummaryTable from "../CuttingSummaryTable";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;

function ProdBomTabPane(props) {
  const {
    setModalSelectedSpread,
    history,
    match,
    setShowProdEntryModal,
    setOpenedProdEntryId,
    resetProdEntryDetailKey,
    deleteProdEntry,
    openedOrder,
    prodBom,
    setShowCuttingWageModal,
    setShowCuttingOperationsModal,
    resetCuttingOperationsModalKey,
    setShowSewingOperationsModal,
    resetSewingOperationsModalKey,
    setShowProdWageModal,
    prodEntries,
  } = props;

  const disabled = !!openedOrder.is_completed;

  const [viewProdByValue, setViewProdByValue] = useState("prodSummary");

  const onRadioChange = value => {
    setViewProdByValue(value.target.value);
  };

  const handleViewRecordsClick = (value, e) => {
    setModalSelectedSpread(value.id);
    history.push(match.url + "/spreads/" + value.id);
  };

  const handleAddCuttingEntry = event => {
    setModalSelectedSpread("");
    history.push(match.url + "/spreads/");
  };

  const handleAddProdEntry = event => {
    setOpenedProdEntryId("");
    resetProdEntryDetailKey();
    setShowProdEntryModal(true);
  };

  const handleProdEntryEditClick = value => {
    resetProdEntryDetailKey();
    setOpenedProdEntryId(value.id);
    setShowProdEntryModal(true);
  };
  const handleProdEntryRemoveClick = value => {
    deleteProdEntry(value);
  };

  function generateYieldForBom(prodBom) {
    let yieldForBom = [];
    let prodSpreadToTotalWeight = {};
    let prodSpreadToTotalSum = {};
    prodBom.prodSpreads.forEach(prodSpread => {
      let entriesForProdSpread = prodSpread.cuttingEntries;

      let sum = 0;
      entriesForProdSpread.forEach(item => {
        item.lineItems.forEach(lineItem => {
          sum += lineItem.quantity;
        });
      });

      let weight = 0;
      entriesForProdSpread.forEach(item => {
        weight += item.weight;
      });

      prodSpreadToTotalWeight[prodSpread.id] = weight;
      prodSpreadToTotalSum[prodSpread.id] = sum;
    });

    prodBom.prodSpreads.forEach((prodSpread, index) => {
      let yildVal =
        prodSpreadToTotalSum[prodSpread.id] /
        prodSpreadToTotalWeight[prodSpread.id];
      if (prodSpreadToTotalWeight[prodSpread.id] == 0) {
        yildVal = 0;
      }
      let yildForSpread = Math.round(yildVal * 100) / 100;

      yieldForBom.push(
        // <div key={index}>
        //     <b>Выход: {prodSpread.name} {yildForSpread} </b>
        // </div>
        {
          name: prodSpread.name,
          yield: yildForSpread,
        },
      );
    });

    return yieldForBom;
  }

  const handleViewCuttingWages = () => {
    setShowCuttingWageModal(true);
  };

  const handleViewCuttingOperations = () => {
    resetCuttingOperationsModalKey();
    setShowCuttingOperationsModal(true);
  };

  const handleViewSewingOperations = () => {
    resetSewingOperationsModalKey();
    setShowSewingOperationsModal(true);
  };

  const handleViewProdWages = () => {
    setShowProdWageModal(true);
  };

  const handleCuttingMenuClick = e => {
    if (e.key == "1") {
      handleViewCuttingWages();
    } else if (e.key == "2") {
      handleViewCuttingOperations();
    }
  };

  const handleSewingMenuClick = e => {
    if (e.key == "1") {
      handleViewProdWages();
    } else if (e.key == "2") {
      handleViewSewingOperations();
    }
  };

  let lineItems = [];
  if (openedOrder && openedOrder.lineItems) {
    lineItems = openedOrder.lineItems;
  }

  let yieldForBom = [];

  let summaryBySpread = [];
  if (prodBom) {
    prodBom.prodSpreads.map((prodSpread, index) => {
      summaryBySpread.push(
        <SummaryRow
          key={index}
          patterns={prodSpread.prodPatterns}
          prodSpread={prodSpread}
          cuttingEntries={prodSpread.cuttingEntries}
          lineItems={lineItems}
          onViewRecordsClick={e => handleViewRecordsClick(prodSpread, e)}
        />,
      );
    });
    yieldForBom = generateYieldForBom(prodBom);
  }

  let filteredProdEntries = prodEntries;

  let prodBomName = "";
  if (prodBom) {
    prodBomName = prodBom.name;
  }

  const yieldColumns = [
    {
      Header: "Настил",
      accessor: "name", // String-based value accessors!
    },
    {
      Header: "Выход",
      accessor: "yield",
      Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
    },
  ];

  const cuttingBarcodeMenu = (
    <Menu onClick={handleCuttingMenuClick}>
      <Menu.Item key="1">
        <UserOutlined />
        Посмотреть коды
      </Menu.Item>
      <Menu.Item key="2">
        <UserOutlined />
        Операции
      </Menu.Item>
      {/* <Menu.Item key="3"><Icon type="user" />3rd item</Menu.Item> */}
    </Menu>
  );

  const sewingMenu = (
    <Menu onClick={handleSewingMenuClick}>
      <Menu.Item key="1">
        <UserOutlined />
        Посмотреть коды
      </Menu.Item>
      <Menu.Item key="2">
        <UserOutlined />
        Операции
      </Menu.Item>
      {/* <Menu.Item key="3"><Icon type="user" />3rd item</Menu.Item> */}
    </Menu>
  );

  const getSewingExtra = productionProcessType => {
    if (!productionProcessType) {
      return null;
    }
    return disabled ? (
      <Dropdown overlay={sewingMenu}>
        <Button style={{ width: 32, padding: 0 }} type="primary">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    ) : (
      <Dropdown.Button
        type="primary"
        onClick={handleAddProdEntry}
        overlay={sewingMenu}
      >
        Отдать в цех
      </Dropdown.Button>
    );
  };

  const getCuttingExtra = productionProcessType => {
    if (!productionProcessType) {
      return null;
    }

    return disabled ? (
      <Dropdown overlay={cuttingBarcodeMenu}>
        <Button style={{ width: 32, padding: 0 }} type="primary">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    ) : (
      <Dropdown.Button
        type="primary"
        onClick={handleAddCuttingEntry}
        overlay={cuttingBarcodeMenu}
      >
        Добавить запись
      </Dropdown.Button>
    );
  };

  return (
    <div>
      <br />
      <Card
        title={"Закройный: " + prodBomName}
        // extra={<Button type="primary" onClick={this.handleAddCuttingEntry}>Добавить запись</Button>}
        extra={getCuttingExtra(props.productionProcessType)}
      >
        {summaryBySpread}
        <Divider orientation="left">Выход</Divider>
        <ReactTable
          data={yieldForBom}
          columns={yieldColumns}
          className="-striped -highlight"
          showPagination={false}
          sortable={false}
          defaultPageSize={yieldForBom.length}
        />
      </Card>
      <br />
      <Card
        title={"Швейный: " + prodBomName}
        extra={getSewingExtra(props.productionProcessType)}
      >
        <RadioGroup
          value={viewProdByValue}
          onChange={onRadioChange}
          defaultValue="prodSummary"
        >
          <RadioButton value="prodSummary">По размерам</RadioButton>
          <RadioButton value="prodEntries">По записям</RadioButton>
        </RadioGroup>
        <br />
        &nbsp;
        {viewProdByValue == "prodEntries" && (
          <ProdEntriesTable
            showEditControls={!disabled}
            prodEntries={filteredProdEntries}
            onEditClick={handleProdEntryEditClick}
            onRemove={handleProdEntryRemoveClick}
          />
        )}
        {viewProdByValue == "prodSummary" && (
          <ProdSummaryTable
            prodEntries={filteredProdEntries}
            lineItems={lineItems}
          />
        )}
      </Card>
    </div>
  );
}

const SummaryRow = ({
  prodSpread,
  patterns,
  lineItems,
  onViewRecordsClick,
}) => {
  let patternVals = [];
  if (patterns && patterns.length > 0) {
    patternVals = patterns.map(pattern => pattern.name).join(" ,");
  }

  return (
    <div>
      <Divider orientation="left">Настил: {prodSpread.name}</Divider>
      {/* <div><h4></h4></div> */}
      Детали: {patternVals}
      <CuttingSummaryTable
        patterns={patterns}
        cuttingEntries={prodSpread.cuttingEntries}
        lineItems={lineItems}
        prodSpreadId={prodSpread.id}
        onViewRecordsClick={onViewRecordsClick}
      />
      <div>
        <br />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const domainSettings = state.domainSettings;
  return {
    productionProcessType: domainSettings
      ? domainSettings.find(setting => setting.id === 1).value
      : null,
    openedOrder: getOpenedCuttingOrder(state),
    prodEntries: getProdEntriesForSelectedProdBom(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setModalSelectedSpread: spreadId =>
      dispatch(setModalSelectedSpread(spreadId)),
    setOpenedProdEntryId: entryId => dispatch(setOpenedProdEntryId(entryId)),
    setShowProdEntryModal: show => dispatch(setShowProdEntryModal(show)),
    setShowCuttingWageModal: show => dispatch(setShowCuttingWageModal(show)),
    setShowProdWageModal: show => dispatch(setShowProdWageModal(show)),
    deleteProdEntry: prodEntry => dispatch(deleteProdEntry(prodEntry)),
    resetProdEntryDetailKey: id => dispatch(resetProdEntryDetailKey()),
    resetCuttingOperationsModalKey: id =>
      dispatch(resetCuttingOperationsModalKey()),
    resetSewingOperationsModalKey: id =>
      dispatch(resetSewingOperationsModalKey()),
    setShowCuttingOperationsModal: show =>
      dispatch(setShowCuttingOperationsModal(show)),
    setShowSewingOperationsModal: show =>
      dispatch(setShowSewingOperationsModal(show)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdBomTabPane),
);
