import shipmentStatusTypes from "@app/shared/constants/shipmentStatusTypes";
import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { updateShipmentStatus } from "../../../../data/shipments/actions";
import { setShowShipmentStatusModal } from "../../actions";

function ShipmentStatusModal(props) {
  const {
    showModal,
    setShowShipmentStatusModal,
    shipmentId,
    updateStatus,
    currentStatusId,
  } = props;

  const closeModal = () => setShowShipmentStatusModal(false);
  const [statusTypeId, setStatusTypeId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const handleChangeStatus = id => {
    setStatusTypeId(id);
  };

  useEffect(() => setStatusTypeId(currentStatusId), [currentStatusId]);

  const handleSetStatus = () => {
    setIsSaving(true);
    updateStatus(shipmentId, statusTypeId).then(
      () => {
        setIsSaving(false);
        closeModal();
      },
      () => setIsSaving(false),
    );
  };

  return (
    <div>
      <Modal
        visible={showModal}
        onCancel={closeModal}
        cancelText={"Закрыть"}
        confirmLoading={isSaving}
        onOk={handleSetStatus}
        width={"350px"}
        style={{
          marginTop: "100px",
        }}
        okText={"Сохранить"}
      >
        <h2>Статус отгрузки</h2>
        <br />
        <Select
          style={{ width: "100%" }}
          value={statusTypeId}
          onChange={handleChangeStatus}
        >
          <Select.Option value={shipmentStatusTypes.SHIPPED}>
            Отправлено
          </Select.Option>
          <Select.Option value={shipmentStatusTypes.BEING_PREPARED}>
            В процессе подготовки
          </Select.Option>
        </Select>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  const shipmentId = state.scenes.shipment.shipmentStatusModalData.shipmentId;
  const currentStatusId = shipmentId
    ? state.data.shipments.byId[shipmentId].shipmentStatusType_id
    : null;

  return {
    showModal: state.scenes.shipment.showShipmentStatusModal,
    currentStatusId,
    shipmentId,
  };
};

const mapDispatchToProps = dispatch => ({
  setShowShipmentStatusModal: show =>
    dispatch(setShowShipmentStatusModal(show)),
  updateStatus: (shipmentId, statusTypeId) =>
    dispatch(updateShipmentStatus(shipmentId, statusTypeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShipmentStatusModal);
