import { combineReducers } from "redux";

import {
  SIZES_FETCH_SUCCESS,
  SIZE_CREATE_SUCCESS,
  SIZE_DELETE_SUCCESS,
  SIZE_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case SIZES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.sizes,
      };
    case SIZE_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.sizes,
      };
    case SIZE_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.sizes,
      };
    case SIZE_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.sizeId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case SIZES_FETCH_SUCCESS:
      return action.response.result;
    case SIZE_CREATE_SUCCESS:
      return [...state, action.response.result];
    case SIZE_DELETE_SUCCESS:
      return [...state.filter(id => id != action.sizeId)];
    default:
      return state;
  }
};

export const sizes = combineReducers({
  allIds,
  byId,
});

//Selectors here
export const getAllSizes = state => {
  let sizes = state.data.sizes;
  return sizes.allIds.map(id => sizes.byId[id]);
};

export const getSize = (state, id) => {
  let sizes = state.data.sizes;
  return sizes.byId[id];
};
