//import "react-table/react-table.css";

import { Button, Card, Col, Dropdown, Row, Table, Tabs } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTable from "react-table";

import {
  deleteCuttingEntry,
  fetchCuttingEntriesForOrder,
} from "../../../data/cuttingEntries/actions";
import { fetchOrder } from "../../../data/orders/actions";
import {
  setModalSelectedSpread,
  setOpenedCuttingEntryId,
  setOpenedCuttingOrder,
  setSelectedProdBomId,
  setShowCuttingEntryModal,
} from "../actions";
import {
  getCuttingEntriesForSelectedProdSpread,
  getModalSelectedSpreadId,
  getOpenedCuttingOrder,
  getOpenedCuttingOrderId,
  getProdSpreadsBySelectedBomWithPatterns,
  getSelectedProdBom,
  getSelectedProdBomId,
} from "../selectors";
import CuttingEntryModal from "./components/CuttingEntryModal";
import PivotComponent from "./components/PivotComponent";

const TabPane = Tabs.TabPane;

class CuttingEntryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: {},
      cuttingEntryModalKey: 0,
    };

    //console.log("constructeasdfsasdfd")
  }

  close = () => {
    const { history, match, openedOrderId, selectedProdBomId } = this.props;

    //let fromEntryList={orderId:openedOrderId,prodBomId: selectedProdBomId }
    //console.log("selectedprodbomid", selectedProdBomId)

    history.push({
      pathname: "/cutting/" + match.params.id,
      state: { prodBomId: selectedProdBomId, isFromCuttingEntryList: true },
    });
  };

  initializeOrderIfNull = () => {
    const {
      match,
      setModalSelectedSpread,
      openedOrderId,
      fetchOrder,
      setOpenedCuttingOrder,
      setSelectedProdBomId,
    } = this.props;
    if (openedOrderId == null || !openedOrderId) {
      if (match.params.id) {
        fetchOrder(match.params.id).then(order => {
          setOpenedCuttingOrder(parseInt(match.params.id));

          if (order && order.prodBoms && order.prodBoms.length > 0) {
            if (!match.params.prodSpreadId) {
              setSelectedProdBomId(order.prodBoms[0].id);
              if (order.prodBoms[0].prodSpreads.length > 0)
                setModalSelectedSpread(order.prodBoms[0].prodSpreads[0].id);
            }

            order.prodBoms.forEach(prodBom => {
              if (prodBom.prodSpreads) {
                let foundSpread = prodBom.prodSpreads.find(
                  prodSpread => prodSpread.id == match.params.prodSpreadId,
                );
                if (foundSpread) {
                  setSelectedProdBomId(prodBom.id);
                }
              }
            });
          }
        });
      }
    }
  };

  componentDidMount() {
    const {
      prodSpreads,
      match,
      setModalSelectedSpread,
      openedOrderId,
      fetchOrder,
      setOpenedCuttingOrder,
      setSelectedProdBomId,
    } = this.props;
    //console.log("PARAMS IS HERE", match.params.id);
    this.initializeModalSelectedSpreadFromParams();

    this.initializeOrderIfNull();
    //Means that the page was just refreshed
  }

  componentDidUpdate(prevProps) {
    const { match, setModalSelectedSpread } = this.props;
    if (prevProps.match.params.prodSpreadId !== match.params.prodSpreadId) {
      this.initializeModalSelectedSpreadFromParams();
    }
  }

  initializeModalSelectedSpreadFromParams = () => {
    const { match, setModalSelectedSpread, prodSpreads } = this.props;
    if (match.params.prodSpreadId) {
      setModalSelectedSpread(parseInt(match.params.prodSpreadId));
    } else {
      if (prodSpreads.length > 0) {
        setModalSelectedSpread(prodSpreads[0].id);
      }
    }
  };

  handleProdSpreadSelection = key => {
    const {
      openedOrderId,
      fetchCuttingEntriesForOrder,
      setModalSelectedSpread,
      prodSpreads,
      match,
      history,
    } = this.props;

    //console.log("MMM", history)

    let prodSpread = prodSpreads.find(spread => spread.id == key);
    if (prodSpread) {
      setModalSelectedSpread(prodSpread.id);
      fetchCuttingEntriesForOrder(openedOrderId);
    } else {
      setModalSelectedSpread(prodSpread);
    }

    history.push("/cutting/" + openedOrderId + "/spreads/" + key);
  };

  handleAddCuttingEntry = () => {
    const { setShowCuttingEntryModal, setOpenedCuttingEntryId } = this.props;
    //this.setState({ cuttingEntry: undefined })
    this.resetCuttingEntryModalKey();
    setOpenedCuttingEntryId(null);
    setShowCuttingEntryModal(true);
  };

  handleEditClicked = cuttingEntry => {
    const { setShowCuttingEntryModal, setOpenedCuttingEntryId } = this.props;

    this.resetCuttingEntryModalKey();
    setOpenedCuttingEntryId(cuttingEntry.id);
    //this.setState({ cuttingEntry: cuttingEntry })
    setShowCuttingEntryModal(true);
  };

  handleDeleteClicked = (cuttingEntry, row) => {
    let expandedRows = { ...this.state.expandedRows };
    delete expandedRows[row.index];
    this.setState({ expandedRows: expandedRows });

    const { deleteCuttingEntry } = this.props;
    deleteCuttingEntry(cuttingEntry);
  };

  handleExpandedChange = (newExpanded, index, event) => {
    this.setState({ expandedRows: newExpanded });
  };

  resetCuttingEntryModalKey = () => {
    this.setState((prevState, props) => {
      return { cuttingEntryModalKey: prevState.cuttingEntryModalKey + 1 };
    });
  };

  render() {
    const {
      prodSpreads,
      selectedProdSpreadId,
      openedOrderId,
      cuttingEntriesForSelectedProdSpread,
      selectedProdBom,
      openedOrder,
    } = this.props;

    let disabled = !!openedOrder.is_completed;

    let prodBomName = "";
    //console.log("SELECTED PROOOOOODBOM", selectedProdBom)
    if (selectedProdBom) {
      prodBomName = selectedProdBom.name;
    }

    //console.log("ENTRIES FOR SELECTED PROD SPREAD", cuttingEntriesForSelectedProdSpread)
    const columns = [
      {
        Header: "Дата раскроя",
        accessor: "cut_date", // String-based value accessors!
        Cell: props => <span>{new moment(props.value).format("L")}</span>, // Custom cell components!
      },
      {
        Header: "Период",
        accessor: "period", // String-based value accessors!
        Cell: props => (
          <span className="text">{props.value && props.value.name}</span>
        ), // Custom cell components!
      },
      {
        Header: "Вес",
        accessor: "weight",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
      },
    ];

    let tabPanes = prodSpreads.map((spread, index) => {
      return (
        <TabPane key={spread.id + ""} tab={spread.name}>
          <br />
          <Button
            disabled={
              disabled || selectedProdSpreadId < 0 || !selectedProdSpreadId
            }
            onClick={this.handleAddCuttingEntry}
          >
            Добавить Запись
          </Button>
          <br />
          &nbsp;
          <ReactTable
            data={cuttingEntriesForSelectedProdSpread}
            columns={columns}
            expanded={this.state.expandedRows}
            onExpandedChange={this.handleExpandedChange}
            className="-striped -highlight"
            SubComponent={row => {
              return (
                <div style={{ padding: "20px" }}>
                  <em>
                    <PivotComponent
                      row={row}
                      cuttingEntry={row.original}
                      onEditClicked={this.handleEditClicked}
                      prodPatterns={spread.prodPatterns}
                      onDelete={e => this.handleDeleteClicked(e, row)}
                      disabled={disabled}
                    />
                  </em>
                </div>
              );
            }}
          />
          {/* <PaneContent spread={spread} onPatternRemove={this.handlePatternRemove} onPatternEdit={this.handlePatternEditClick}/> */}
        </TabPane>
      );
    });

    return (
      <div className="mt-paper">
        <Row type="flex">
          <Col span={12}>
            <h3>Раскройные записи</h3>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">
              <Button onClick={this.close}>Закрыть</Button>
            </Row>
          </Col>
        </Row>
        <br />
        <label>Спецификация: {prodBomName}</label>
        {/* <label>Артикул: {openedOrder.product.article}</label> */}
        <br />
        &nbsp;
        <Tabs
          activeKey={selectedProdSpreadId + ""}
          type="card"
          animated={false}
          onTabClick={this.handleProdSpreadSelection}
        >
          {tabPanes}
        </Tabs>
        {/* <Select options={prodSpreads} name="selected-state" value={selectedProdSpreadId}
                    onChange={this.handleProdSpreadSelection} /> */}
        <CuttingEntryModal key={this.state.cuttingEntryModalKey} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    prodSpreads: getProdSpreadsBySelectedBomWithPatterns(state),
    openedOrderId: getOpenedCuttingOrderId(state),
    //prodPatterns: getProdPatternsBySelectedProdSpread(state),
    cuttingEntriesForSelectedProdSpread: getCuttingEntriesForSelectedProdSpread(
      state,
    ),
    selectedProdSpreadId: getModalSelectedSpreadId(state),
    selectedProdBom: getSelectedProdBom(state),
    selectedProdBomId: getSelectedProdBomId(state),
    openedOrder: getOpenedCuttingOrder(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowCuttingEntryModal: showModal =>
      dispatch(setShowCuttingEntryModal(showModal)),
    setModalSelectedSpread: spreadId =>
      dispatch(setModalSelectedSpread(spreadId)),
    fetchCuttingEntriesForOrder: orderId =>
      dispatch(fetchCuttingEntriesForOrder(orderId)),
    fetchOrder: id => dispatch(fetchOrder(id)),
    deleteCuttingEntry: entry => dispatch(deleteCuttingEntry(entry)),
    setOpenedCuttingOrder: order => dispatch(setOpenedCuttingOrder(order)),
    setOpenedCuttingEntryId: id => dispatch(setOpenedCuttingEntryId(id)),

    setSelectedProdBomId: id => dispatch(setSelectedProdBomId(id)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CuttingEntryList),
);
