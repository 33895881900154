import { Breadcrumb, Button, Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {Redirect, withRouter} from "react-router";
import { Route, Switch } from "react-router-dom";

import { deleteUser, fetchUsers } from "../../data/users/actions";
import { getAllUsers } from "../../data/users/reducer";
import UserTable from "./components/UserTable";

class UserMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchUsers, match } = this.props;
    fetchUsers();
  }

  render() {
    const { history, users, me, deleteUser } = this.props;

    const onUserCreate = () => {
      history.push(`/user/new`);
    };

    const handleEditClick = contractor => {
      //console.log(id);
      history.push(`/user/` + contractor.id);
    };

    const onRemove = value => {
      deleteUser(value.id);
      //history.push(`/product-admin/`)
    };
    
    if(me && !me.roles.map(role => role.id).includes(1)) {
      return <Redirect to={`user/${me.id}`} />
    }

    return (
      <div>
        <div>
          <h2>Пользователи</h2>
          <Button type="primary" onClick={onUserCreate}>
            Создать
          </Button>
        </div>
        <br />
        <UserTable
          onEditClick={handleEditClick}
          onRemove={onRemove}
          users={users}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    users: getAllUsers(state),
    me: state.data.users.user.user,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    deleteUser: id => dispatch(deleteUser(id)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserMain),
);

//export default ContractorMain
