import { combineReducers } from "redux";

const openedFabStockId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_FABSTOCK_ID":
      return action.fabStockId;
    default:
      return state;
  }
};

const openedFabStockMovementId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_FABSTOCK_MOVEMENT_REQUEST_ID":
      return action.fabStockMovementRequestId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedFabStockId,
  openedFabStockMovementId,
});
