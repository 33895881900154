import "font-awesome/css/font-awesome.min.css";

import "./index.css";

import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import configureStore from "./configureStore";
import { unregister } from "./registerServiceWorker";
import { SentryErrorBoundary } from "./SentryErrorBoundary";

const {
  REACT_APP_ENV: APP_ENV,
  REACT_APP_SENTRY_DSN: APP_SENTRY_DSN,
} = process.env;

if (APP_SENTRY_DSN) {
  Sentry.init({ dsn: APP_SENTRY_DSN, environment: APP_ENV });
}

const store = configureStore();

ReactDOM.render(
  <SentryErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </SentryErrorBoundary>,

  document.getElementById("root"),
  () => {
    document.getElementById("spinner").style.display = "none";
  },
);

unregister();
