import { BarcodeOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class WageTable extends Component {
  render() {
    const { wages, onEditClick, onRemove, classes } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 60,
        Cell: row => (
          <div>
            <BarcodeOutlined />
            {/* <OpenInNew classes={{ root: classes.iconRoot }} />
                        <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
          </div>
        ),
      },
      {
        Header: "Количество",
        accessor: "quantity", // String-based value accessors!
      },
      {
        Header: "Операция",
        accessor: "prodOperation.operation", // String-based value accessors!
      },
      {
        Header: "Сумма",
        accessor: "sum", // String-based value accessors!
      },
    ];

    return (
      <div>
        <ReactTable
          className="-striped -highlight"
          data={wages}
          columns={columns}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText="Нет записей"
          pageText="Страница"
          ofText="из"
          rowsText="строк"
          defaultPageSize={10}
          showPageSizeOptions={false}
        />
      </div>
    );
  }
}

export default withRouter(WageTable);
