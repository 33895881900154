import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import FabStockBalanceMain from "./index.js";

// import FabStockDetail from "./components/FabStockDetail";
///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class FabStockBalanceRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/fabstockbalance"
            component={FabStockBalanceMain}
          />
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={`${this.props.match.url}/new`}*/}
          {/*  component={FabStockDetail}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={`${this.props.match.url}/:id`}*/}
          {/*  component={FabStockDetail}*/}
          {/*/>*/}
        </Switch>
      </div>
    );
  }
}

export default FabStockBalanceRoute;
