import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import FabStockRevisionDetail from "./components/FabStockRevisionDetail";
import FabStockRevisionMain from "./index.js";

///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class FabStockRevisionRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/fabstockrevision"
            component={FabStockRevisionMain}
          />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={FabStockRevisionDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={FabStockRevisionDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default FabStockRevisionRoute;
