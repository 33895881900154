import "../print.css";
import "../cleanInputNumber.css";
import "../validation.css";

import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Col, Divider, Row } from "antd";
import { Formik } from "formik";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import Select from "../../../../../components/Select";
import {
  createSewingPassportEntry,
  updateSewingPassportEntry,
} from "../../../../../data/passportEntries/actions";
import { fetchPeriods } from "../../../../../data/periods/actions";
import { deleteProdOperation } from "../../../../../data/prodOperations/actions";
import { fetchTeams } from "../../../../../data/teams/actions";
import { getAllTeams } from "../../../../../data/teams/reducer";
import {
  setShowAddOperationModal,
  setShowSearchEmployeeModal,
  setShowSewingSubmitPassportModal,
} from "../../../actions";
import {
  getOpenedCuttingOrder,
  getProdBomsByOpenedOrderWithSpreadsPatternsEntries,
} from "../../../selectors";
import AddOperationModal from "../AddOperationModal";
import { TableOfCutFabrics } from "../common/TableOfCutFabrics";
import { TableOfSewedProducts } from "../common/TableOfSewedProducts";
import SearchEmployeeModal from "../SearchEmployeeModal";
import getInitialValues from "./initialValues";
import { SewingOperationsTable } from "./SewingOperationsTable";
import getValidationSchema from "./validationSchema";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: "1000px",
      },
      root: {
        alignItems: "initial",
        zIndex: 1000,
        height: "100%",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const { NEW, EDIT, NORMAL } = {
  NEW: "NEW",
  EDIT: "EDIT",
  NORMAL: "NORMAL",
};

function SewingSubmitPassportModal(props) {
  const form = useRef();
  const close = () => {
    props.setShowSewingSubmitPassportModal(false);
  };

  const handleSubmit = () => {
    form.current.submitForm();
  };

  const {
    showModal,
    fullScreen,
    openedOrder,
    prodBoms,
    passportEntry,
    setShowSearchEmployeeModal,
    setShowAddOperationModal,
    fetchPeriods,
    fetchTeams,
    productionProcessType_id,
  } = props;
  const [status, setStatus] = useState(passportEntry === null ? NEW : NORMAL);
  const [isDeletingOperation, setIsDeletingOperation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams().then(teams => {
      setTeams(
        teams.map(team => ({
          ...team,
          label: team.name,
          value: team.id,
        })),
      );
    });
  }, []);

  useEffect(() => {
    fetchPeriods().then(periods => {
      let filteredPeriods = periods.filter(period => {
        if (!period.payroll) {
          return true;
        } else if (period.payroll.isClosed == 0) {
          return true;
        } else return false;
      });

      setPeriods(
        filteredPeriods.map(period => {
          return { label: period.name, value: period.id, ...period };
        }),
      );
    });
  }, []);

  const onSubmit = values => {
    const { createSewingPassportEntry, updateSewingPassportEntry } = props;
    const passportData = {
      period: values.period,
      team: values.team,
      lineItems: values.lineItems,
      prodBoms: values.prodBoms,
    };

    if (
      productionProcessType_id ===
      ProductionProcessTypes.id.PASSPORT_WITH_FIXED_SALARY
    ) {
      delete passportData.prodBoms;
    }

    setIsSaving(true);
    if (status === NEW) {
      createSewingPassportEntry(openedOrder.id, passportData).then(
        () => {
          setStatus(NORMAL);
          setIsSaving(false);
        },
        () => {
          setIsSaving(false);
        },
      );
    } else if (status === EDIT) {
      updateSewingPassportEntry(
        openedOrder.id,
        passportEntry.id,
        passportData,
      ).then(
        () => {
          setStatus(NORMAL);
          setIsSaving(false);
        },
        () => {
          setIsSaving(false);
        },
      );
    }
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          fullScreen={fullScreen}
          scroll={"body"}
          open={showModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Паспорт пошива</DialogTitle>
          <DialogContent>
            <div>
              <Formik
                ref={form}
                initialValues={getInitialValues(
                  passportEntry,
                  prodBoms,
                  openedOrder,
                )}
                validationSchema={getValidationSchema()}
                onSubmit={onSubmit}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <div>
                    <AddOperationModal
                      modalType={"sewing"}
                      openedOrder={openedOrder}
                      addNewOperationToForm={(
                        prodBomIndex,
                        prodBomId,
                        newOperation,
                      ) => {
                        setFieldValue(`prodBoms[${prodBomIndex}]`, [
                          ...values.prodBoms[prodBomIndex],
                          {
                            key: nanoid(5),
                            prodBomId: prodBomId,
                            operation: newOperation,
                            prodOperation: newOperation.operation,
                            employee: null,
                            quantity: 0,
                          },
                        ]);
                      }}
                    />
                    <SearchEmployeeModal
                      passportType={"sewing"}
                      selectEmployee={(
                        employee,
                        prodBomIndex,
                        operationIndex,
                      ) => {
                        setFieldValue(
                          `prodBoms[${prodBomIndex}][${operationIndex}].employee`,
                          employee,
                        );
                      }}
                      setPeriod={(period, prodBomIndex, operationIndex) => {
                        setFieldValue(
                          `prodBoms[${prodBomIndex}][${operationIndex}].period`,
                          period,
                        );
                      }}
                      setQuantity={(value, prodBomIndex, operationIndex) => {
                        setFieldValue(
                          `prodBoms[${prodBomIndex}][${operationIndex}].quantity`,
                          value,
                        );
                      }}
                    />
                    <Row>
                      <Col span={8}>Заказ: PR-{openedOrder.id}</Col>
                    </Row>
                    <Row>Модель: {openedOrder.product.name}</Row>
                    <Row>
                      Список частей:{" "}
                      {prodBoms.map(
                        (prodBom, index) =>
                          prodBom.name +
                          (index === prodBoms.length - 1 ? "" : ", "),
                      )}
                    </Row>
                    <br />
                    <div style={{ marginBottom: 15 }}>
                      <label className="mt-label" htmlFor="period">
                        Период:
                      </label>
                      <Select
                        id="period"
                        options={periods}
                        value={values["period"]}
                        onChange={e => setFieldValue("period", e)}
                        isDisabled={status === NORMAL}
                        className={
                          errors.period && touched.period ? "inputError" : ""
                        }
                        style={{ width: "100%" }}
                      />
                      <div className="help-block">
                        {errors.period && touched.period && errors.period}
                      </div>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                      <label className="mt-label" htmlFor="teams">
                        Бригада:
                      </label>
                      <Select
                        id="team"
                        options={teams}
                        value={values["team"]}
                        onChange={e => setFieldValue("team", e)}
                        isDisabled={status === NORMAL}
                        className={
                          errors.team && touched.team ? "inputError" : ""
                        }
                        style={{ width: "100%" }}
                      />
                      <div className="help-block">
                        {errors.team && touched.team && errors.team}
                      </div>
                    </div>
                    <Divider />
                    <h4>Скроено:</h4>
                    <TableOfCutFabrics
                      openedOrder={openedOrder}
                      data={prodBoms.map((prodBom, key) => {
                        return {
                          key,
                          prodBomName: prodBom.name,
                          lineItems: openedOrder.lineItems.map(lineItem => ({
                            sizeId: lineItem.size.id,
                            sizeName: lineItem.size.name,
                            quantity: Math.min(
                              ...prodBom.prodSpreads.map(prodSpread => {
                                return props.cuttingPassportEntry.fabricConsumptions
                                  .filter(
                                    fabricConsumption =>
                                      fabricConsumption.prodBom_id ===
                                        prodBom.id &&
                                      fabricConsumption.prodSpread_id ===
                                        prodSpread.id,
                                  )
                                  .reduce(
                                    (acc, curr) =>
                                      acc +
                                      curr.lineItems.find(
                                        item =>
                                          item.size.id === lineItem.size.id,
                                      ).quantity,
                                    0,
                                  );
                              }),
                            ),
                          })),
                        };
                      })}
                    />
                    <br />
                    <h4>Сшито:</h4>
                    <TableOfSewedProducts
                      disabled={status === NORMAL}
                      openedOrder={openedOrder}
                      values={values}
                      setFieldValue={setFieldValue}
                      data={[{ key: 1, lineItems: values.lineItems }]}
                    />
                    <br />
                    {productionProcessType_id ===
                      ProductionProcessTypes.id.PASSPORT &&
                      prodBoms.map((prodBom, prodBomIndex) => (
                        <div key={prodBomIndex}>
                          <Row>
                            <h3>Часть: {prodBom.name}</h3>
                          </Row>
                          <div key={prodBomIndex}>
                            <SewingOperationsTable
                              setShowAddOperationModal={
                                setShowAddOperationModal
                              }
                              prodBomIndex={prodBomIndex}
                              setIsDeletingOperation={setIsDeletingOperation()}
                              isDeletingOperation={isDeletingOperation}
                              deleteOperation={props.deleteOperation}
                              setShowSearchEmployeeModal={
                                setShowSearchEmployeeModal
                              }
                              form={{
                                prodBom,
                                errors,
                                touched,
                                values,
                                setFieldValue,
                                status,
                              }}
                              data={values.prodBoms[prodBomIndex]}
                            />
                            <br />
                          </div>
                          {prodBomIndex !== prodBoms.length - 1 && <Divider />}
                        </div>
                      ))}
                  </div>
                )}
              </Formik>
            </div>
          </DialogContent>
          <DialogActions>
            {(status === NEW || status === EDIT) && (
              <Button
                type={"primary"}
                onClick={handleSubmit}
                loading={isSaving}
              >
                Сохранить
              </Button>
            )}
            {status === NORMAL && (
              <Button type={"primary"} onClick={() => setStatus(EDIT)}>
                Редактировать
              </Button>
            )}
            <Button onClick={close}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

const mapStateToProps = state => {
  const openedOrder = getOpenedCuttingOrder(state);
  return {
    showModal: state.scenes.cutting.showSewingSubmitPassportModal,
    prodBoms: getProdBomsByOpenedOrderWithSpreadsPatternsEntries(state),
    openedOrder: openedOrder,
    passportEntry: openedOrder.sewingPassportEntry,
    cuttingPassportEntry: openedOrder.cuttingPassportEntry,
    teams: getAllTeams(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTeams: () => dispatch(fetchTeams()),
    createSewingPassportEntry: (orderId, passport) =>
      dispatch(createSewingPassportEntry(orderId, passport)),
    fetchPeriods: () => dispatch(fetchPeriods()),
    updateSewingPassportEntry: (orderId, passportEntryId, passport) =>
      dispatch(updateSewingPassportEntry(orderId, passportEntryId, passport)),
    setShowSewingSubmitPassportModal: showModal =>
      dispatch(setShowSewingSubmitPassportModal(showModal)),
    deleteOperation: (prodBomId, operationId) =>
      dispatch(deleteProdOperation(prodBomId, operationId)),
    setShowAddOperationModal: (showModal, prodBomIndex, prodBomId) =>
      dispatch(setShowAddOperationModal(showModal, prodBomIndex, prodBomId)),
    setShowSearchEmployeeModal: (
      showModal,
      prodBomIndex,
      prodSpreadIndex,
      operationIndex,
    ) =>
      dispatch(
        setShowSearchEmployeeModal(
          showModal,
          prodBomIndex,
          prodSpreadIndex,
          operationIndex,
        ),
      ),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SewingSubmitPassportModal),
);
