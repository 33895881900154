import { Button, notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";

export const EMPLOYEES_PAYROLL_FETCH_SUCCESS =
  "EMPLOYEES_PAYROLL_FETCH_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchEmployeesForPayroll = () => {
  return dispatch => {
    return axios
      .get(`/api/employees_payroll/`)
      .then(({ data }) => {
        let employees = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: EMPLOYEES_PAYROLL_FETCH_SUCCESS,
          response: normalize(employees, schema.arrayOfEmployees),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
