import { combineReducers } from "redux";

import {
  PRODSTOCKS_FETCH_SUCCESS,
  PRODSTOCK_CREATE_SUCCESS,
  PRODSTOCK_DELETE_SUCCESS,
  PRODSTOCK_FETCH_SUCCESS,
  PRODSTOCK_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case PRODSTOCKS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStocks,
      };
    case PRODSTOCK_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStocks,
      };
    case PRODSTOCK_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStocks,
      };
    case PRODSTOCK_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStocks,
      };
    case PRODSTOCK_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.prodStockId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case PRODSTOCKS_FETCH_SUCCESS:
      return action.response.result;
    case PRODSTOCK_CREATE_SUCCESS:
      return [...state, action.response.result];
    case PRODSTOCK_DELETE_SUCCESS:
      return [...state.filter(id => id != action.prodStockId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllProdStocks = state => {
  return state.data.prodStocks.allIds.map(id => state.data.prodStocks.byId[id]);
};

export const getProdStock = (state, id) => {
  return state.data.prodStocks.byId[id];
};
