// import React, { Component } from 'react';
// import PropTypes from 'prop-types'
// import { withRouter } from 'react-router'
// import ReactTable from 'react-table'

import { Button, Popconfirm } from "antd";
import ReactTable from "components/ReactTable";
import matchSorter from "match-sorter";
import React, { forwardRef } from "react";

const FabricTable = forwardRef(function(props, ref) {
  const confirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  const cancel = e => {
    console.log(e);
  };

  const { fabricWithStock, onEditClick, onRemove, classes, loading } = props;
  const columns = [
    {
      Header: "",
      accessor: "id",
      maxWidth: 100,
      filterable: false,
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            onEditClick(row.original, e);
          }}
          href={"/fabric-admin/" + row.original.id}
        >
          {"FAB-" + row.value}
        </a>
      ),
    },
    {
      Header: "Имя",
      accessor: "name", // String-based value accessors!
      id: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Описание",
      accessor: "description", // String-based value accessors!
      id: "description",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["description"] }),
      filterAll: true,
    },
    {
      Header: "Производитель",
      accessor: "manufacturer",
      Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
      filterable: false,
    },
    {
      Header: "Остаток (кг)",
      accessor: "totalWeight",
      Cell: props => (
        <span className="text"> {props.value ? props.value : 0}</span>
      ), // Custom cell components!
      filterable: false,
    },
  ];

  return (
    <div>
      <ReactTable
        data={fabricWithStock}
        columns={columns}
        isLoading={loading}
        ref={ref}
      />
    </div>
  );
});

export default FabricTable;
