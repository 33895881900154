import { combineReducers } from "redux";

const openedDepotLocationId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_DEPOT_LOCATION_ID":
      return action.depotLocationId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedDepotLocationId: openedDepotLocationId,
});
