import { Button, notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import { WAGE_ENTRIES_FETCH_SUCCESS } from "../wages/actions";
import * as schema from "./schema";

export const FABDEPOT_ENTRIES_FETCH_SUCCESS = "FABDEPOT_ENTRIES_FETCH_SUCCESS";
export const FABDEPOT_ENTRY_FETCH_SUCCESS = "FABDEPOT_ENTRY_FETCH_SUCCESS";
export const FABDEPOT_ENTRY_CREATE_SUCCESS = "FABDEPOT_ENTRY_CREATE_SUCCESS";
export const FABDEPOT_ENTRY_UPDATE_SUCCESS = "FABDEPOT_ENTRY_UPDATE_SUCCESS";
export const FABDEPOT_ENTRY_DELETE_SUCCESS = "FABDEPOT_ENTRY_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchFabDepotEntries = () => {
  return dispatch => {
    return axios
      .get(`/api/fabdepot_entries/`)
      .then(({ data }) => {
        let fabDepotEntries = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: FABDEPOT_ENTRIES_FETCH_SUCCESS,
          response: normalize(fabDepotEntries, schema.arrayOfFabDepotEntries),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchFabDepotEntry = entryId => {
  return dispatch => {
    return axios
      .get(`/api/fabdepot_entries/` + entryId)
      .then(({ data }) => {
        let fabDepotEntry = data;
        let id = entryId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: FABDEPOT_ENTRY_FETCH_SUCCESS,
          response: normalize(fabDepotEntry, schema.fabDepotEntry),
          id,
        });
        //dispatch({ type: WAGE_ENTRIES_FETCH_SUCCESS, response: normalize(fabDepotEntry, schema.fabDepotEntry) })
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createFabDepotEntry = entry => {
  return dispatch => {
    return axios
      .post("/api/fabdepot_entries/", entry)
      .then(({ data }) => {
        // Status looks good
        let entry = data;
        dispatch({
          type: FABDEPOT_ENTRY_CREATE_SUCCESS,
          response: normalize(entry, schema.fabDepotEntry),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateFabDepotEntry = (entryId, entry) => {
  return dispatch => {
    return axios
      .put("/api/fabdepot_entries/" + entryId, entry)
      .then(({ data }) => {
        // Status looks good
        let fabDepotEntry = data;
        dispatch({
          type: FABDEPOT_ENTRY_UPDATE_SUCCESS,
          response: normalize(fabDepotEntry, schema.fabDepotEntry),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteFabDepotEntry = entryId => {
  return dispatch => {
    return axios
      .delete("/api/fabdepot_entries/" + entryId)
      .then(({ data }) => {
        let product = data;
        dispatch({ type: FABDEPOT_ENTRY_DELETE_SUCCESS, entryId });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данные из этой записи используются в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          //console.log("Parsing eror: Most likely server crashed");

          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
