import AttendanceReportType from "@app/shared/constants/AttendanceReportType";
import { isValidAttendanceReportType } from "@app/shared/utils/AttendanceReportUtils";
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Popover } from "antd";
import ReactTable from "components/ReactTable";
import {
  difference,
  forEach,
  get,
  keys,
  map,
  size,
  slice,
  toFinite,
  trim,
} from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";

import { fetchAttendanceReport } from "../../../../data/attendanceReports/actions";
import { getAttendanceReport } from "../../../../data/attendanceReports/reducer";

const {
  formatAttendanceReportName,
} = require("@app/shared/utils/AttendanceReportUtils");

const enhancer = compose(
  connect((state, { match: { params } }) => {
    const id = toFinite(params.id);
    const type = isValidAttendanceReportType(params.type)
      ? params.type
      : AttendanceReportType.AttendanceReportByDateRange;

    return { id, type, data: getAttendanceReport(state, id) };
  }),
  withRouter,
);

function AttendanceReportByDateRange({ id, type, data, dispatch }) {
  const reportName = useMemo(() => data && formatAttendanceReportName(data), [
    data,
  ]);

  const [dataToDownload, setDataToDownload] = useState([]);

  const csvLink = useRef();
  const isDownloadStarted = useRef(false);

  const attendanceTable = useRef();

  const [columns, dataSource] = useMemo(() => {
    if (!data || !data.result) {
      return [];
    }

    const result = JSON.parse(data.result);
    // Using lodash helpers here to avoid errors if result was corrupt.
    const days = get(result, "days");
    const employeeEntries = get(result, "employeeEntries");

    const workingHours = {};
    const nextDataSource = [];
    const nextColumns = [
      {
        minWidth: 160,
        accessor: "name",
        filterable: false,
      },

      {
        minWidth: 160,
        accessor: "workingDays",
        Header: "Число рабочих дней",
        filterable: false,
      },

      {
        minWidth: 160,
        accessor: "attendedDays",
        Header: "Число прогулов",
        filterable: false,
      },
    ];

    forEach(days, row => {
      const day = get(row, "day");
      const startTime = trim(get(row, ["workingHours", "start_time"])).slice(
        0,
        5,
      );
      const endTime = trim(get(row, ["workingHours", "end_time"])).slice(0, 5);

      if (startTime && endTime) {
        workingHours[day] = { startTime, endTime };
      }

      nextColumns.push({
        Header: slice(day, 0, 5),

        columns: [
          {
            accessor: day,
            id: `day_${day}`,
            Header:
              !startTime || !endTime ? "Выходной" : `${startTime}-${endTime}`,

            maxWidth: 105,
            sortable: false,
            filterable: false,

            Cell: ({ value }) => {
              if (!value) {
                return "-";
              }

              const { entries, timeWorked } = value;
              const validEntries = size(entries) % 2 === 0;

              return (
                <Popover
                  trigger="hover"
                  content={
                    <div>
                      {map(entries, (entry, idx) => (
                        <p key={idx}>
                          {get(entry, "time")}:{" "}
                          {get(entry, "attendanceType_id") === 1
                            ? "Приход"
                            : "Уход"}
                        </p>
                      ))}
                    </div>
                  }
                >
                  <div>{validEntries ? timeWorked : "~08:00"}</div>
                </Popover>
              );
            },
          },
        ],
      });
    });

    const workingDays = keys(workingHours);

    forEach(employeeEntries, entry => {
      const attendedDays = difference(
        workingDays,
        keys(get(entry, "attendance")),
      );

      const dataSourceItem = {
        name: [
          get(entry, ["employee", "first_name"]),
          get(entry, ["employee", "last_name"]),
        ]
          .filter(Boolean)
          .join(" "),

        workingDays: workingDays.length,
        attendedDays: attendedDays.length,
      };

      forEach(get(entry, "attendance"), (attendance, day) => {
        const entries = get(attendance, ["entries"]);
        const timeWorked = trim(get(attendance, "timeWorked"));

        if (timeWorked && Array.isArray(entries) && entries.length > 0) {
          const startTime = moment(
            get(workingHours, [day, "startTime"]),
            "HH:mm",
          );
          const firstEntryTime = moment(get(entries, [0, "time"]), "HH:mm");

          dataSourceItem[day] = {
            entries,
            timeWorked,
            minutesLate: firstEntryTime.diff(startTime, "minutes"),
          };
        }
      });

      nextDataSource.push(dataSourceItem);
    });

    return [nextColumns, nextDataSource];
  }, [data]);

  useEffect(() => {
    dispatch(fetchAttendanceReport(id, type));
  }, [id, dispatch]);

  useEffect(() => {
    if (data && dataSource) {
      return;
    }

    const interval = setInterval(() => {
      dispatch(fetchAttendanceReport(id, type));
    }, 5 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [id, dispatch, data, dataSource]);

  const download = event => {
    const headers = [
      { label: "Имя", key: "name" },
      { label: "Число рабочих дней", key: "workingDays" },
      { label: "Число прогулов", key: "attendedDays" },
    ];

    let currentRecords = [];
    if (attendanceTable.current) {
      currentRecords = attendanceTable.current.getResolvedState().sortedData;
    }

    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < headers.length; colIndex++) {
        record_to_download[headers[colIndex].label] =
          currentRecords[index][headers[colIndex].key];
      }
      data_to_download.push(record_to_download);
    }

    setDataToDownload(data_to_download);
    isDownloadStarted.current = true;
  };

  useEffect(() => {
    if (csvLink.current && isDownloadStarted.current == true) {
      csvLink.current.link.click();
      isDownloadStarted.current = false;
    }
  }, [dataToDownload]);

  console.log("data source is ", dataSource);

  return (
    <div className="mt-paper">
      <div>
        {!reportName ? (
          <h2>Отчет по посещаемости</h2>
        ) : (
          <h2>Отчет: {reportName}</h2>
        )}
      </div>

      {columns == null || dataSource == null ? (
        <div>
          <LoadingOutlined />
        </div>
      ) : (
        <div>
          <Button onClick={download}>Скачать</Button>
          <br />
          &nbsp;
          <span>
            <CSVLink
              style={{ display: "none" }}
              data={dataToDownload}
              filename={
                reportName ? `Отчет: ${reportName}.csv` : "Посещаемость.csv"
              }
              ref={csvLink}
            >
              Скачать
            </CSVLink>
          </span>
          <br />
          <span>
            Всего число рабочих дней:{" "}
            {dataSource.reduce((acc, cur) => acc + cur.workingDays, 0)}
          </span>
          <br />
          <span>
            Всего число прогулов:{" "}
            {dataSource.reduce((acc, cur) => acc + cur.attendedDays, 0)}
          </span>
          <br /> <br />
          <ReactTable
            data={dataSource}
            ref={attendanceTable}
            columns={columns}
            showPagination={true}
            getTdProps={(_state, rowInfo, column) => {
              const id = trim(get(column, "id"));

              if (id.startsWith("day_")) {
                const minutesLate = get(rowInfo, ["row", id, "minutesLate"]);

                if (minutesLate != null && minutesLate > 0) {
                  return { style: { backgroundColor: "rgba(255, 0, 0, 0.3)" } };
                }
              }

              return {};
            }}
          />
        </div>
      )}
    </div>
  );
}

export default enhancer(AttendanceReportByDateRange);
