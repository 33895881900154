"use strict";

const { makeBaseValidator } = require("../utils/ValidatorUtils");
const { number } = require("yup");

module.exports = ({
  max,
  min,
  message,
  integer,
  required,
  default: defaultValue,
} = {}) => {
  let schema = makeBaseValidator(number(), {
    message,
    required,
    default: defaultValue,
  });

  if (integer) {
    schema = schema.integer(message);
  }

  if (min != null) {
    schema = schema.min(min, message);
  }

  if (max != null) {
    schema = schema.max(max, message);
  }

  return schema;
};
