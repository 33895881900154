import { Button, Card, Popconfirm, Radio, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import ProdEntriesTable from "../../../../components/ProdEntriesTable";
import ProdSummaryTable from "../../../../components/ProdSummaryTable";
import { fetchOrder } from "../../../../data/orders/actions";

const TabPane = Tabs.TabPane;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class PivotComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prodBoms: [],
      viewProdByValue: "prodSummary",
      openedOrder: null,
      selectedBomId: null,
      loading: true,
    };
  }

  cancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  componentDidMount() {
    const { prodBoms, orderId, fetchOrder } = this.props;
    console.log("yes component mounted");
    fetchOrder(orderId).then(order => {
      console.log(order);
      this.setState({
        openedOrder: order,
        loading: false,
      });

      if (order.prodBoms.length > 0) {
        this.setState({ selectedBomId: order.prodBoms[0].id + "" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {}

  handleSizeTableChange = lineItems => {
    //this.setState({enteredValues: lineItems})
  };

  // getInitialCuttingItems = () => {

  //     const { cuttingEntries, row } = this.props;
  //     let cuttingEntryId=row.original.id;

  //     let foundEntry=cuttingEntries.find(entry=>entry.id==cuttingEntryId);
  //     let cuttingItems= foundEntry.cuttingItems;

  //     let initialVals = [];

  //     let mappedItems = cuttingItems.map(item => {
  //         return {
  //             size:  item.size.name,
  //             quantity: item.quantity,
  //             prodPattern_id: item.prodPattern_id,
  //             size_id: item.size.id
  //         }
  //     });
  //     initialVals = initialVals.concat(mappedItems)
  //     return initialVals;
  // }

  handleBomSelection = value => {
    console.log("bom selection ", value);
    this.setState({ selectedBomId: value });
  };

  onRadioChange = value => {
    //console.log("value", value);
    this.setState({ viewProdByValue: value.target.value });
  };

  render() {
    const { openedOrder } = this.state;

    let panes = [];
    let filteredProdEntries = [];
    let lineItems = [];
    if (openedOrder && openedOrder.prodBoms) {
      console.log("openedOrder", openedOrder);

      lineItems = openedOrder.lineItems;
      // filteredProdEntries=openedOrder.prodEntries.filter(entry=>{
      //     return entry.prodBom_id==this.state.selectedBomId
      // })

      openedOrder.prodBoms.map(prodBom => {
        panes.push(
          <TabPane key={prodBom.id + ""} tab={prodBom.name}>
            <div>
              <h4>В Швейном Цеху</h4>
            </div>
            <br />
            &nbsp;
            <RadioGroup
              value={this.state.viewProdByValue}
              onChange={this.onRadioChange}
              defaultValue="prodSummary"
            >
              <RadioButton value="prodSummary">По размерам</RadioButton>
              <RadioButton value="prodEntries">По записям</RadioButton>
            </RadioGroup>
            <br />
            &nbsp;
            {this.state.viewProdByValue == "prodEntries" && (
              <ProdEntriesTable
                numberOfRows={4}
                prodEntries={prodBom.prodEntries}
              />
            )}
            {this.state.viewProdByValue == "prodSummary" && (
              <ProdSummaryTable
                numberOfRows={5}
                prodEntries={prodBom.prodEntries}
                lineItems={lineItems}
              />
            )}
          </TabPane>,
        );
      });
    }

    const { loading } = this.state;

    function getCardStyle() {
      const style = {
        height: 420,
      };

      if (loading) return style;
      else return {};
    }

    //const { cuttingEntries, prodPatterns } = this.props;
    return (
      <div>
        {/* <CuttingEntryForm disabled={true}
                    prodPatterns={prodPatterns} initialCuttingItems={this.getInitialCuttingItems()} /> */}
        <Card style={getCardStyle()} loading={this.state.loading}>
          <Tabs
            activeKey={this.state.selectedBomId}
            onTabClick={this.handleBomSelection}
          >
            {panes}
          </Tabs>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    //materials: getAllMaterials(state.materials.byId, state.materials.allIds)
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: id => dispatch(fetchOrder(id)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PivotComponent),
);
