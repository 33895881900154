import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ProdStockBalanceMain from "./index.js";

class ProdStockBalanceRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/prodstockbalance"
            component={ProdStockBalanceMain}
          />
        </Switch>
      </div>
    );
  }
}

export default ProdStockBalanceRoute;
