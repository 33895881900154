import { DatePicker } from "antd";
import moment from "moment";
import React, { useMemo } from "react";

import { FormField } from "./FormField";

function FormDatePickerInternal({
  name,
  value,
  onChange,
  defaultPickerValue,
  placeholder = "Дата",
  ...props
}) {
  const handleChange = useMemo(() => onChange(name), [name, onChange]);
  const valueMoment = useMemo(() => (!value ? null : moment(value)), [value]);

  const defaultPickerValueMoment = useMemo(
    () => (!defaultPickerValue ? null : moment(defaultPickerValue, "HH:mm")),
    [defaultPickerValue],
  );

  return (
    <DatePicker
      {...props}
      name={name}
      format="DD/MM/YYYY"
      value={valueMoment}
      placeholder={placeholder}
      defaultPickerValue={defaultPickerValueMoment}
      onChange={date => handleChange(date && date.toDate())}
    />
  );
}

export function FormDatePicker({ name, label, disabled, ...props }) {
  return (
    <FormField
      name={name}
      label={label}
      render={({ field, form: { isSubmitting } }) => (
        <>
          <br />

          <FormDatePickerInternal
            {...props}
            {...field}
            disabled={disabled || isSubmitting}
          />

          <br />
        </>
      )}
    />
  );
}
