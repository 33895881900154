//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Input } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import {
  setOpenedSpread,
  setSelectedSpreadTab,
  setShowSpreadModal,
} from "../../actions";
import { addSpread, saveSpread } from "../../data/spreads/actions";
import { getOpenedSpread } from "../../selectors";

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "400px",
      },
      root: {
        //alignItems: "initial"
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class SpreadDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      selectedFabric: undefined,
    };
  }

  close = () => {
    this.props.setShowSpreadModal(false);
    this.props.setOpenedSpread("");
  };

  handleCreate = () => {
    const { addSpread, selectedBomId, setSelectedSpreadTab } = this.props;
    let action = addSpread(
      {
        name: this.state.name,
        fabric: this.state.selectedFabric,
        patterns: [],
        printOperations: 1,
      },
      selectedBomId,
    );

    this.props.setShowSpreadModal(false);
    setSelectedSpreadTab(selectedBomId, action.spreadId);
  };
  handleUpdate = () => {
    const { saveSpread, openedSpread } = this.props;

    saveSpread({
      id: openedSpread.id,
      name: this.state.name,
      fabric: this.state.selectedFabric,
      printOperations: openedSpread.printOperations,
    });

    this.props.setShowSpreadModal(false);
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleFabricSelect = fabric => {
    this.setState({ selectedFabric: fabric });
  };

  getFabricOptions = function(input, callback) {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    //You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.openedSpread) {
      if (nextProps.openedSpread !== this.props.openedSpread) {
        //console.log("Will receive props for spread");
        this.setState({
          name: nextProps.openedSpread.name,
          selectedFabric: {
            ...nextProps.openedSpread.fabric,
            value: nextProps.openedSpread.fabric.id,
            label: nextProps.openedSpread.fabric.name,
          },
        });
      }
    } else {
      this.setState({
        name: "",
        selectedFabric: undefined,
      });
    }
  }

  render() {
    //coming from HOC
    const { fullScreen, classes } = this.props;

    const { showModal, openedSpread } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
            //classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle id="responsive-dialog-title">
              {openedSpread != undefined
                ? "Настил: " + openedSpread.name
                : "Добавить настил"}
            </DialogTitle>
            <DialogContent>
              {/* <div className="form-group">
                             <label htmlFor="description">Название</label>
                             <input value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="description" />
                         </div> */}

              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="article">
                  Название
                </label>
                <Input
                  name="article"
                  placeholder="Название"
                  //onChange={this.handleInputChange}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  //disabled={!this.state.isInEditState}
                />
              </div>

              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="sizes">
                  Материал
                </label>
                <AsyncSelect
                  name="sizes"
                  value={this.state.selectedFabric}
                  loadOptions={this.getFabricOptions}
                  onChange={this.handleFabricSelect}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                />
              </div>
            </DialogContent>
            <DialogActions>
              {openedSpread == undefined && (
                <Button
                  type="primary"
                  disabled={
                    this.state.name.length <= 0 ||
                    this.state.selectedFabric == undefined
                  }
                  onClick={this.handleCreate}
                >
                  Создать
                </Button>
              )}
              {openedSpread != undefined && (
                <Button
                  type="primary"
                  disabled={
                    this.state.name.length <= 0 ||
                    this.state.selectedFabric == undefined
                  }
                  onClick={this.handleUpdate}
                >
                  Сохранить
                </Button>
              )}
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

SpreadDetail.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  //console.log(state);
  return {
    // You can now say this.props.books

    openedSpread: getOpenedSpread(state),
    //selectedSpreadId: state.scenes.product.selectedSpreadId,
    selectedBomId: state.scenes.forms.specform.selectedBom,
    //spec: getOpenedSpec(state),
    showModal: state.scenes.forms.specform.showSpreadModal,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowSpreadModal: showModal => dispatch(setShowSpreadModal(showModal)),
    addSpread: (bomId, spread) => dispatch(addSpread(bomId, spread)),
    saveSpread: spread => dispatch(saveSpread(spread)),
    setOpenedSpread: spreadId => dispatch(setOpenedSpread(spreadId)),
    setSelectedSpreadTab: (bomId, spreadId) =>
      dispatch(setSelectedSpreadTab(bomId, spreadId)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SpreadDetail),
);
