import ReactTable from "components/ReactTable";
import matchSorter from "match-sorter";
import React, { forwardRef } from "react";

const ProductTable = forwardRef(function(props, ref) {
  const confirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    onRemove(row, e);
  };

  const cancel = e => {
    console.log(e);
  };

  const { productWithStock, onEditClick, onRemove, classes, loading } = props;
  const columns = [
    {
      Header: "",
      accessor: "id",
      maxWidth: 100,
      filterable: false,
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            onEditClick(row.original, e);
          }}
          href={"/product-admin/" + row.original.id}
        >
          {"PR-" + row.value}
        </a>
      ),
    },
    {
      Header: "Имя",
      accessor: "name",
      id: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Описание",
      accessor: "description",
      id: "description",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["description"] }),
      filterAll: true,
    },
    {
      Header: "Остаток (шт)",
      accessor: "totalQuantity",
      Cell: props => (
        <span className="text"> {props.value ? props.value : 0}</span>
      ), // Custom cell components!
      filterable: false,
    },
  ];

  return (
    <div>
      <ReactTable
        data={productWithStock}
        columns={columns}
        isLoading={loading}
        ref={ref}
      />
    </div>
  );
});

export default ProductTable;
