import { Card, Col, DatePicker, InputNumber, Row } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import Select from "components/Select";
import RSelect from "components/Select";
import { Formik, withFormik } from "formik";
import moment from "moment";
import React, { Component, useRef } from "react";
import * as Yup from "yup";

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const EntrySchema = Yup.object().shape({
  entryDate: Yup.object()
    .required("Выберите дату")
    .nullable(),
  period: Yup.object()
    .required("Выберите период")
    .nullable(),
  size: Yup.object()
    .required("Выберите размер")
    .nullable(),
  employee: Yup.object()
    .required("Выберите работника")
    .nullable(),
  team: Yup.object()
    .required("Выберите бригаду")
    .nullable(),
  // weight: Yup.number()
  //   .min(0, "Значение слишком маленькое")
  //   .max(999999, "Значение слишком большой")
  //   .required("Обязательное поле")
  //   .typeError("Неправильное значение"),
  quantity: Yup.number()
    .min(0, "Значение слишком маленькое")
    .max(99999999, "Значение слишком большой")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
});

class ProdEntryForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.state = {};
  }

  componentDidMount() {
    const { depotLocation, fabric } = this.props.initialValues;
  }

  getEmployeeOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/employees/search?q=${input}`).then(({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label:
            item.last_name +
            " " +
            item.first_name +
            " " +
            item.middle_name +
            " [" +
            item.team.name +
            "]",
          id: item.id,
          ...item,
        };
      });
    });
  };

  submitForm = () => {
    this.form.current.submitForm();
  };

  render() {
    let sizeOptions = [];
    if (this.props.sizeOptions) {
      sizeOptions = this.props.sizeOptions.map(item => {
        return { label: item.size.name, value: item.size.id, ...item.size };
      });
    }

    let teamOptions = [];
    if (this.props.teamOptions) {
      teamOptions = this.props.teamOptions.map(item => {
        return { label: item.name, value: item.id, ...item };
      });
    }

    function disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf("day");
    }

    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={EntrySchema}
          onSubmit={this.props.onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={8}>
                  <label>Дата</label>
                </Col>
                <Col span={16}>
                  <DatePicker
                    disabledDate={disabledDate}
                    id="entryDate"
                    name="entryDate"
                    value={values["entryDate"]}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    onChange={e => setFieldValue("entryDate", e)}
                    style={{ zIndex: 9999 }}
                  />
                  <div className="help-block">
                    {errors.entryDate && touched.entryDate && errors.entryDate}
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={8}>
                  <label>Период</label>
                </Col>
                <Col span={16}>
                  <RSelect
                    id="period"
                    options={this.props.periodOptions}
                    searchable={true}
                    value={values["period"]}
                    onChange={e => setFieldValue("period", e)}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    //menuPlacement={portalPlacement}
                  />
                  <div className="help-block">
                    {errors.period && touched.period && errors.period}
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={8}>
                  <label htmlFor="sizes">Размер</label>&nbsp;&nbsp;
                </Col>
                <Col span={16}>
                  <Select
                    value={values["size"]}
                    styles={customStyles}
                    options={sizeOptions}
                    onChange={e => setFieldValue("size", e)}
                    menuPortalTarget={document.body}
                  />
                  <div className="help-block">
                    {errors.size && touched.size && errors.size}
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={8}>
                  <label htmlFor="description">Количество</label>&nbsp;&nbsp;
                </Col>
                {/* <input value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="description" /> */}
                <Col span={16}>
                  <InputNumber
                    value={values["quantity"]}
                    style={{ width: 120 }}
                    onChange={e => setFieldValue("quantity", e)}
                  />
                  <div className="help-block">
                    {errors.quantity && touched.quantity && errors.quantity}
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={8}>
                  <label>Работник</label>
                </Col>
                <Col span={16}>
                  <AsyncSelect
                    name="form-field-name"
                    value={values["employee"]}
                    loadOptions={this.getEmployeeOptions}
                    onChange={e => setFieldValue("employee", e)}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                  />
                  <div className="help-block">
                    {errors.employee && touched.employee && errors.employee}
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={8}>
                  <label htmlFor="sizes">Бригада</label>&nbsp;&nbsp;
                </Col>
                <Col span={16}>
                  <Select
                    value={values["team"]}
                    styles={customStyles}
                    onChange={e => setFieldValue("team", e)}
                    options={teamOptions}
                    menuPortalTarget={document.body}
                  />
                  <div className="help-block">
                    {errors.team && touched.team && errors.team}
                  </div>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ProdEntryForm;
