import * as Yup from "yup";

const ShipmentLineItemSchema = Yup.object().shape({
  product: Yup.object()
    .required("Выберите продукт")
    .nullable(),
  // prodDepotLocation_id: Yup.number()
  //   .required("Выберите Склад")
  //   .nullable(),
  quantity: Yup.number()
    .integer("Значение должно быть целым числом")
    .min(1, "Значение слишком маленькое")
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Обязательное поле"),
  numberOfBags: Yup.number()
    .integer("Значение должно быть целым числом")
    .min(0, "Значение не должно быть отрицательным")
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Обязательное поле"),
  price: Yup.number()
    .min(0, "Значение слишком маленькое")
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Обязательное поле"),
});
const ShipmentSchema = Yup.object().shape({
  client: Yup.object()
    .required("Выберите клиента")
    .typeError("Выберите клиента")
    .nullable(),
  lineItems: Yup.array()
    .required("Вы должны добавить хотя бы один продукт")
    .of(ShipmentLineItemSchema),
  shipped_at: Yup.date()
    .required("Выберите дату отгрузки")
    .nullable(),
});
export default ShipmentSchema;
