import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const PAYROLLS_FETCH_SUCCESS = "PAYROLLS_FETCH_SUCCESS";
export const PAYROLL_FETCH_SUCCESS = "PAYROLL_FETCH_SUCCESS";
export const PAYROLL_TOGGLE_SUCCESS = "PAYROLL_TOGGLE_SUCCESS";
export const PAYROLL_CREATE_SUCCESS = "PAYROLL_CREATE_SUCCESS";
export const PAYROLL_UPDATE_SUCCESS = "PAYROLL_UPDATE_SUCCESS";
export const PAYROLL_ENTRIES_UPDATE_SUCCESS = "PAYROLL_ENTRIES_UPDATE_SUCCESS";
export const PAYROLL_WAGE_REFRESH_SUCCESS = "PAYROLL_WAGE_REFRESH_SUCCESS";
export const PAYROLL_DELETE_SUCCESS = "PAYROLL_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchPayrolls = () => {
  return dispatch => {
    return axios
      .get(`/api/payrolls/`)
      .then(({ data }) => {
        let payrolls = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: PAYROLLS_FETCH_SUCCESS,
          response: normalize(payrolls, schema.arrayOfPayrolls),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchPayroll = payrollId => {
  return dispatch => {
    return axios
      .get(`/api/payrolls/` + payrollId)
      .then(({ data }) => {
        let payroll = data;
        let id = payrollId;
        dispatch({
          type: PAYROLL_FETCH_SUCCESS,
          response: normalize(payroll, schema.payroll),
          original: payroll,
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createPayroll = payroll => {
  return dispatch => {
    return axios
      .post("/api/payrolls/", payroll)
      .then(({ data }) => {
        // Status looks good
        let payroll = data;
        dispatch({
          type: PAYROLL_CREATE_SUCCESS,
          response: normalize(payroll, schema.payroll),
        });
        openNotificationWithIcon(
          "Успешно",
          "Ведомость успешно создана",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такая ведомость уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateEntries = entries => {
  return dispatch => {
    return axios
      .put("/api/payroll_entries/", entries)
      .then(({ data }) => {
        let entries = data;
        dispatch({
          type: PAYROLL_ENTRIES_UPDATE_SUCCESS,
          response: normalize(entries, {
            payrollEntries: schema.arrayOfPayrollEntries,
            payrollAttendanceEntry: schema.payrollAttendanceEntry,
          }),
        });
        openNotificationWithIcon(
          "Успешно",
          "Ведомость успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такая ведомость уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const refreshWage = payrollId => {
  return dispatch => {
    return axios
      .put("/api/payroll_refresh/" + payrollId)
      .then(({ data }) => {
        let payroll = data;
        dispatch({
          type: PAYROLL_WAGE_REFRESH_SUCCESS,
          response: normalize(payroll, schema.payroll),
          original: payroll,
        });
        openNotificationWithIcon(
          "Успешно",
          "Зарплата успешно обновлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такая ведомость уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const togglePayroll = (payrollId, toggle) => {
  return dispatch => {
    return axios
      .put("/api/payroll_toggle/" + payrollId, toggle)
      .then(({ data }) => {
        let payroll = data;
        dispatch({
          type: PAYROLL_TOGGLE_SUCCESS,
          response: normalize(payroll, schema.payroll),
        });
        openNotificationWithIcon(
          "Успешно",
          "Ведомость успешно открыта/закрыта",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такая ведомость уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updatePayroll = (payrollId, payroll) => {
  return dispatch => {
    return axios
      .put("/api/payrolls/" + payrollId, payroll)
      .then(({ data }) => {
        let period = data;
        dispatch({
          type: PAYROLL_UPDATE_SUCCESS,
          response: normalize(period, schema.payroll),
        });
        openNotificationWithIcon(
          "Успешно",
          "Ведомость успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такая ведомость уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deletePayroll = payrollId => {
  return dispatch => {
    return axios
      .delete("/api/payrolls/" + payrollId)
      .then(({ data }) => {
        let period = data;
        dispatch({ type: PAYROLL_DELETE_SUCCESS, payrollId });
        openNotificationWithIcon(
          "Успешно",
          "Ведомость успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данная ведомость используется в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
