import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";

const initialState = { name: "", article: "", description: "", sizes: [] };

const openedFabric = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_FABRIC":
      return action.fabricId;
    default:
      return state;
  }
};

export const getOpenedFabric = state => {
  return state.scenes.fabric.openedFabric;
};

export const reducer = combineReducers({
  openedFabric,
  data: dataReducer,
});
