import { Button, Popconfirm } from "antd";
import { FieldArray, getIn } from "formik";
import React, { useMemo, useState } from "react";

import { WorkingScheduleTable } from "../../../WorkingSchedule/components/WorkingScheduleTable";
import WorkingScheduleChooseDialog from "../WorkingScheduleChooseDialog";
import WorkingScheduleFormDialog from "../WorkingScheduleFormDialog";

function WorkingSchedulesFiledInternal({
  name,
  form: { values, errors, touched, handleChange },
  ...field
}) {
  const value = getIn(values, name);
  const errorList = getIn(touched, name) && getIn(errors, name);
  const onChange = useMemo(() => handleChange(name), [name, handleChange]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openChooseDialog, setOpenChooseDialog] = useState(false);
  const errorMessage = useMemo(() => {
    if (!errorList) {
      return;
    }

    if (!Array.isArray(errorList)) {
      return String(errorList);
    }

    const invalidIndexes = errorList
      .map((x, idx) => (x ? idx + 1 : undefined))
      .filter(Boolean);

    return invalidIndexes.length === 0
      ? undefined
      : invalidIndexes.length === 1
      ? `Неверный график на строке: ${invalidIndexes[0]}.`
      : `Неверные графики на строках: ${invalidIndexes.join(", ")}.`;
  }, [errorList]);

  return (
    <>
      <WorkingScheduleFormDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onSubmit={values => {
          field.push(values);
          setOpenAddDialog(false);
        }}
      />

      <WorkingScheduleChooseDialog
        open={openChooseDialog}
        onClose={() => setOpenChooseDialog(false)}
        onSelect={list => {
          onChange(list);
          setOpenChooseDialog(false);
        }}
      />

      {value.length === 0 ? (
        <Button type="default" onClick={() => setOpenChooseDialog(true)}>
          Выбрать из шаблонов
        </Button>
      ) : (
        <Popconfirm
          okText="Принять"
          cancelText="Отменить"
          placement="topRight"
          title="Текущие данные будут удалены"
          onConfirm={() => setOpenChooseDialog(true)}
        >
          <Button type="default">Выбрать из шаблонов</Button>
        </Popconfirm>
      )}

      <br />
      <br />

      <Button type="primary" onClick={() => setOpenAddDialog(true)}>
        Добавить правило
      </Button>

      <br />
      <br />

      {errorMessage && (
        <>
          <div className="has-error">
            <span className="help-block">{errorMessage}</span>
          </div>
          <br />
        </>
      )}

      <WorkingScheduleTable
        showID={false}
        showPagination={false}
        workingSchedules={value}
        onWeightChange={(entry, addition) => {
          const indexA = value.indexOf(entry);
          const indexB = indexA + addition;

          if (indexB >= 0 && indexB <= value.length - 1) {
            field.swap(indexA, indexB);
          }
        }}
        onDelete={entry => {
          field.remove(value.indexOf(entry));
        }}
      />
    </>
  );
}

export default function WorkingSchedulesField({ name }) {
  return <FieldArray name={name} component={WorkingSchedulesFiledInternal} />;
}
