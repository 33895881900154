import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    period: Yup.object()
      .nullable()
      .required("Выберите период"),
    team: Yup.object()
      .nullable()
      .required("Выберите бригаду"),
  });
};
