import { combineReducers } from "redux";

import { SET_SHOW_PASSWORD_CONFIRMATION_MODAL } from "./actions";

const showPasswordConfirmationModal = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_PASSWORD_CONFIRMATION_MODAL:
      return action.show;

    default:
      return state;
  }
};

export default combineReducers({
  showPasswordConfirmationModal,
});
