import React, { PureComponent } from "react";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default class BarComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      //loading: true,
      searchEnabled: false,
    };
  }

  render() {
    const length = this.props.data.length;
    return (
      <BarChart
        width={950}
        height={500}
        data={this.props.data}
        layout="vertical"
        margin={{
          top: 5,
          right: 30,
          left: -30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" tick={false} />
        <Tooltip
          labelFormatter={name => {
            const arrayOfLabels = name.split("\n");
            return (
              <span>
                {arrayOfLabels.map((label, index) => (
                  <span key={index}>
                    {label}
                    <br />
                  </span>
                ))}
              </span>
            );
          }}
        />

        <Tooltip />
        <Legend />
        <Bar dataKey="Количество" fill="#8884d8" />
      </BarChart>
    );
  }
}
