//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, InputNumber, Switch } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import Select from "../../../../../components/Select";
import { fetchPeriods } from "../../../../../data/periods/actions";
import { setShowSearchEmployeeModal } from "../../../actions";

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "400px",
        minHeight: "300px",
      },
      root: {
        //alignItems: "initial"
        zIndex: 1000,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class SearchEmployeeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployee: null,
      quantity: 0,
      selectedPeriod: null,
      isAnotherPeriod: false,
      periods: [],
    };
  }
  close = () => {
    this.props.setShowSearchEmployeeModal(false);
  };

  handleUpdate = () => {
    const {
      selectEmployee,
      searchEmployeeIndices,
      setQuantity,
      setPeriod,
    } = this.props;
    selectEmployee(
      this.state.selectedEmployee,
      searchEmployeeIndices.prodBomIndex,
      searchEmployeeIndices.prodSpreadIndex,
      searchEmployeeIndices.operationIndex,
    );
    setQuantity(
      this.state.quantity,
      searchEmployeeIndices.prodBomIndex,
      searchEmployeeIndices.prodSpreadIndex,
      searchEmployeeIndices.operationIndex,
    );
    if (this.props.passportType === "sewing") {
      setPeriod(
        this.state.isAnotherPeriod ? this.state.selectedPeriod : undefined,
        searchEmployeeIndices.prodBomIndex,
        searchEmployeeIndices.prodSpreadIndex,
        searchEmployeeIndices.operationIndex,
      );
    }
    this.close();
  };

  componentDidMount() {
    this.props.fetchPeriods().then(periods => {
      let filteredPeriods = periods.filter(period => {
        if (!period.payroll) {
          return true;
        } else if (period.payroll.isClosed == 0) {
          return true;
        } else return false;
      });

      this.setState({
        periods: filteredPeriods.map(period => {
          return { label: period.name, value: period.id, ...period };
        }),
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal !== prevProps.showModal) {
      this.setState({
        selectedEmployee: null,
        quantity: 0,
        selectedPeriod: null,
        isAnotherPeriod: false,
      });
    }
  }

  componentWillReceiveProps(nextProps) {}

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  getEmployeeOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return axios(`/api/employees/search?q=${input}`).then(({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label:
            item.last_name +
            " " +
            item.first_name +
            " " +
            item.middle_name +
            " [" +
            item.team.name +
            "]",
          id: item.id,
          ...item,
        };
      });
    });
  };

  handleEmployeeSelect = employee => {
    this.setState({ selectedEmployee: employee });
  };

  render() {
    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    const { showModal, openedPattern } = this.props;
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            scroll={"paper"}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Указать работника
            </DialogTitle>
            <DialogContent>
              {this.state.isAnotherPeriod && (
                <div style={{ marginBottom: 15 }}>
                  <label className="mt-label" htmlFor="period">
                    Период:
                  </label>
                  <Select
                    id="period"
                    options={this.state.periods}
                    value={this.state.selectedPeriod}
                    onChange={e => this.setState({ selectedPeriod: e })}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="employee">
                  Работник
                </label>
                <AsyncSelect
                  menuContainerStyle={{ zIndex: 99999 }}
                  name="form-field-name"
                  value={this.state.selectedEmployee}
                  loadOptions={this.getEmployeeOptions}
                  onChange={this.handleEmployeeSelect}
                  styles={customStyles}
                  noOptionsMessage={() => {
                    return "Печатайте для поиска...";
                  }}
                  isClearable
                  menuPortalTarget={document.body}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="quantity">
                  Количество
                </label>
                <InputNumber
                  value={this.state.quantity}
                  min={0}
                  onChange={e => this.setState({ quantity: e })}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
              {this.props.passportType === "sewing" && (
                <div>
                  <Switch
                    checked={this.state.isAnotherPeriod}
                    onClick={() =>
                      this.setState({
                        isAnotherPeriod: !this.state.isAnotherPeriod,
                      })
                    }
                  />
                  &nbsp;
                  <span>Выбрать другой период</span>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={
                  !this.state.selectedEmployee ||
                  !this.state.quantity ||
                  (this.state.isAnotherPeriod && !this.state.selectedPeriod)
                }
                type="primary"
                onClick={this.handleUpdate}
              >
                Сохранить
              </Button>

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

SearchEmployeeModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    showModal: state.scenes.cutting.showSearchEmployeeModal,
    searchEmployeeIndices: state.scenes.cutting.searchEmployeeIndices,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowSearchEmployeeModal: showModal =>
      dispatch(setShowSearchEmployeeModal(showModal)),
    fetchPeriods: () => dispatch(fetchPeriods()),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchEmployeeModal),
);
