import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ClientDetail from "./components/ClientDetails";
import ClientMain from "./index.js";

export default props => (
  <div>
    <Switch>
      <Route exact path="/client" component={ClientMain} />
      <Route exact path="/client/:id" component={ClientDetail} />
    </Switch>
  </div>
);
