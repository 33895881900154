import { Breadcrumb, Button, DatePicker, Layout, Menu, Radio, Select } from "antd";
import axios from "axios";
import { fetchPeriods } from "data/periods/actions";
import { getAllPeriods } from "data/periods/reducer";
import { fetchTeams } from "data/teams/actions";
import { getAllTeams } from "data/teams/reducer";
import { serialize } from "helpers/utils";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import EmployeeReport from "scenes/EmployeeReport";
import ProductionReport from "scenes/ProductionReport";

import { fetchDepotLocations } from "../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../data/depotLocations/reducer";
import StatisticsByPeriods from "../ProductionReportByPeriods";
import StatsByTeam from "./components/StatsByTeam";
import StatsByTeamTable from "./components/StatsByTeamTable";

const Option = Select.Option;

function FabStockMain(props) {
  // Declare a new state variable, which we'll call "count"
  const { history, fetchPeriods, fetchTeams, teams, match, periods } = props;

  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [statisticType, setStatisticType] = useState(null);

  const [statsByTeam, setStatsByTeam] = useState([]);

  const onPRDoubleClick = value => {
    history.push(`/cutting/` + value.PR);
  };

  const productionProcessType = props.domainSettings.find(
    setting => setting.id === 1,
  ).value;

  useEffect(() => {
    setLoading(true);
    fetchPeriods().then(data => {});

    fetchTeams().then(data => {});

    if (!match.params.statisticType) {
      props.history.push(`/productionstats/byteam`);
    }

    // //setStatisticType("byteam")
    // if(!props.statisticType){
    //   console.log("uyes undefined", statisticType);
    //   setStatisticType("production")
    //   props.history.push(`/productionstats/production`)

    // }else{
    //   setStatisticType(props.statisticType)
    //   //props.history.push(`/productionstats/production`)
    // }
  }, []);

  useEffect(() => {
    //setStatisticType(props.statisticType);
    // props.history.push(`/productionstats/${props.statisticType}`)
    // console.log("yes effect", props.statisticType)

    setStatisticType(match.params.statisticType);
  }, [match.params.statisticType]);

  const handleStatTypeChange = e => {
    //console.log(depot);
    //console.log(id);
    //setStatisticType(e.target.value);
    props.history.push(`/productionstats/${e.target.value}`);
  };

  return (
    <div>
      <div>
        <h2>Статистика Производства</h2>
      </div>
      <br />

      <Radio.Group
        defaultValue={statisticType}
        buttonStyle="solid"
        value={statisticType}
        onChange={handleStatTypeChange}
      >
        {productionProcessType === "barcode" && (
          <Radio.Button key={"production"} value="production">
            Все производство
          </Radio.Button>
        )}
        {productionProcessType === "passport" && (
          <Radio.Button key={"byperiods"} value="byperiods">
            Все производство
          </Radio.Button>
        )}
        <Radio.Button key={"byteam"} value="byteam">
          По бригаде
        </Radio.Button>
        <Radio.Button key={"byemployee"} value="byemployee">
          По работнику
        </Radio.Button>
      </Radio.Group>

      <br />
      <br />
      <Switch>
        <Route exact path={`/productionstats/byteam`} component={StatsByTeam} />
        {productionProcessType === "barcode" && (
          <Route
            exact
            path={`/productionstats/production`}
            component={ProductionReport}
          />
        )}
        <Route
          exact
          path={`/productionstats/byemployee`}
          component={EmployeeReport}
        />
        {productionProcessType === "passport" && (
          <Route
            exact
            path={`/productionstats/byperiods`}
            component={StatisticsByPeriods}
          />
        )}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path={`${this.props.match.url}/new`}*/}
        {/*  component={FabStockDetail}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path={`${this.props.match.url}/:id`}*/}
        {/*  component={FabStockDetail}*/}
        {/*/>*/}
      </Switch>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    domainSettings: state.domainSettings,
    depotLocations: getAllDepotLocations(state),
    teams: getAllTeams(state),
    periods: getAllPeriods(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
    fetchTeams: () => dispatch(fetchTeams()),
    fetchPeriods: () => dispatch(fetchPeriods()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabStockMain),
);

//export default ContractorMain
