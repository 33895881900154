import { createSelector } from "reselect";

export const getAllSplitEntries = state => {
  return state.data.splitEntries.allIds
    .slice()
    .reverse()
    .map(id => {
      return state.data.splitEntries.byId[id];
    });
};
//export const getEmployees=(state)=>state.data.employees

export const getOpenedSplitEntry = state => {
  return state.data.splitEntries.byId[state.scenes.split.openedSplitEntryId];
};

//export const getOpenedProdEntry=(state)=>orders.getProdEntries(state).byId[getOpenedProdEntryId(state)]
