import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, DatePicker, Input } from "antd";
import { InputNumber, Select as SelectAntD, notification } from "antd";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

import { fetchDepotLocations } from "../../../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../../../data/depotLocations/reducer";
import {
  createFabStock,
  fetchFabStock,
  updateFabStock,
} from "../../../../data/fabStocks/actions";
import { getFabStock } from "../../../../data/fabStocks/reducer";
import { setOpenedFabStockId } from "../../actions";
import FormValidator from "./FormValidator";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const Option = SelectAntD.Option;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

let validator = new FormValidator([
  {
    field: "weight",
    method: "isEmpty",
    validWhen: false,
    message: "Введите вес",
  },
  {
    field: "weight",
    method: (value, formData) => {
      let val = parseInt(value);
      if (Number.isInteger(val) && val <= 0) {
        return true;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Вес должен быть больше 0",
  },
  {
    field: "rolls",
    method: "isEmpty",
    validWhen: false,
    message: "Введите количество рулонов",
  },
  {
    field: "rolls",
    method: (value, formData) => {
      let val = parseInt(value);
      if (Number.isInteger(val) && val < 0) {
        return true;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Значение не может быть меньше 0",
  },
  {
    field: "entry_dateField",
    method: (value, formData) => {
      if (formData.entry_date) {
        return false;
      } else {
        return true;
      }
    },
    validWhen: false,
    message: "Укажите дату",
  },
  {
    field: "fabricField",
    method: (args, formData) => {
      if (formData.fabric) {
        return false;
      } else {
        return true;
      }
    },
    validWhen: false,
    message: "Выберите материал",
  },
  {
    field: "fromDepotLocationField",
    method: (args, formData) => {
      if (Number.isInteger(formData.fromDepotLocation)) {
        return false;
      } else return true;
    },
    validWhen: false,
    message: "Выберите склад",
  },
  {
    field: "toDepotLocationField",
    method: (args, formData) => {
      if (Number.isInteger(formData.toDepotLocation)) {
        return false;
      } else return true;
    },
    validWhen: false,
    message: "Выберите склад",
  },
]);

function FabStockDetail(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    match,
    fetchFabDepotEntry,
    setOpenedFabStockId,
    createFabDepotEntry,
    updateFabDepotEntry,
    fetchDepotLocations,
    fetchFabStock,
    updateFabStock,
    classes,
    openedFabStock,
  } = props;
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [saving, setSaving] = useState(false);
  const [isInEditState, toggleEditState] = useState(false);
  const [formData, setFormData] = useState({
    weight: "",
    rolls: "",
    entry_date: null,
    entry_dateField: "",
    // contractor: '',
    // contractorField: '',
    fabric: null,
    fabricField: "",

    fromDepotLocation: null,
    fromDepotLocationField: "",

    toDepotLocation: null,
    toDepotLocationField: "",
  });

  const [depotLocations, setDepotLocations] = useState([]);

  const onDepotCreate = () => {
    history.push(`/fabdepot/new`);
  };

  const handleEditClick = contractor => {
    history.push(`/fabdepot/` + contractor.id);
  };

  const onRemove = value => {
    const { deleteEmployee } = this.props;
    deleteEmployee(value.id);
  };

  const createFabStockFromValues = () => {
    var fabStock = {
      weight: formData.weight,
      rolls: formData.rolls,
      entry_date: new moment(formData.entry_date).format("YYYY-MM-DD HH:mm:ss"),

      fabric: formData.fabric,
      fromDepotLocation: formData.fromDepotLocation,
      toDepotLocation: formData.toDepotLocation,
    };
    return fabStock;
  };

  const handleSubmit = () => {
    setSubmitted(true);

    const validated = validator.validate(formData);
    setValidation(validated);

    console.log("validated", validated);

    if (validated.isValid) {
      var fabStock = createFabStockFromValues();

      console.log("values are ", fabStock);
      setSaving(true);
      //Handle update
      if (match.params.id) {
        return updateFabStock(match.params.id, fabStock).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            fetchDepotLocationsWithStock();
            history.push(`/fabstock/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
      //Handle create
      else {
        // return createFabDepotEntry(fabStock)
        // .then(
        //     (json) => {
        //         toggleEditState(false);
        //         history.push(`/fabdepot/` + json.id)
        //     },
        //     (error) => {

        //     }
        // )

        return axios
          .post("/api/fabstocks/", fabStock)
          .then(({ data }) => {
            setSaving(false);
            // // Status looks good
            // let depotLocation = data;
            // dispatch({ type: DEPOT_CREATE_SUCCESS, response: normalize(depotLocation, schema.depotLocation) })
            openNotificationWithIcon(
              "Успешно",
              "Перемещение добавлено",
              "success",
            );
            return data;
          })
          .catch(error => {
            if (error.handledGlobally) {
              return Promise.reject();
            }
            setSaving(false);
            if (error.response) {
              let { data } = error.response;

              if (data.errno && (data.errno == 1062 || data.errno == 19)) {
                let message = "Такое перемещение уже существует";
                openNotificationWithIcon("Ошибка", message, "error");
              } else {
                openNotificationWithIcon(
                  "Ошибка",
                  data.errorMessage || "Произошла неизвестная ошибка",
                  "error",
                );
              }
              throw error;
            } else {
              openNotificationWithIcon(
                "Ошибка",
                "Невозможно подключится к серверу",
                "error",
              );
              throw error;
            }
          })
          .then(data => {
            history.push("/fabstock");
          });
      }
    }
  };

  const handleInputChange = e => {
    let value = e.target.value;
    const name = e.target.name;

    setFormData(prevData => {
      return { ...prevData, [name]: value };
    });
  };

  const handleRollsChange = value => {
    if (value == undefined) {
      setFormData(prevData => {
        return { ...prevData, rolls: "" };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, rolls: value };
      });
    }
  };
  const handleWeightChange = value => {
    if (value == undefined) {
      setFormData(prevData => {
        return { ...prevData, weight: "" };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, weight: value };
      });
    }
  };

  const handleDateChange = day => {
    let value = day;
    setFormData(prevData => {
      return { ...prevData, entry_date: value };
    });
  };

  const getFabricOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/searchwithstock?q=${input}`).then(({ data }) => {
      return data.map(item => {
        let totalWeight = 0;
        if (item.totalWeight) {
          totalWeight = item.totalWeight;
        }
        return {
          value: item.id,
          label: item.name + " { остаток: " + totalWeight + " }",
          id: item.id,
          ...item,
        };
      });
    });
  };

  const handleFabricSelect = fabric => {
    if (fabric) {
      setFormData(prevData => {
        return { ...prevData, fabric: fabric };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, fabric: null };
      });
    }
  };

  const addLabelFieldsToContractor = contractor => {
    if (contractor) {
      let selectedContractor = {
        value: contractor.id,
        label: contractor.company_name,
        ...contractor,
      };
      return selectedContractor;
    }
    return null;
  };

  const addLabelFieldsToFabric = fabric => {
    if (fabric) {
      let selectedFabric = {
        value: fabric.id,
        label: fabric.name,
        ...fabric,
      };
      return selectedFabric;
    }
    return null;
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(formData));
    }
  }, [formData]);

  const fetchDepotLocationsWithStock = () => {
    let fabricId = "";
    if (formData.fabric) {
      fabricId = formData.fabric.id;
    }
    axios(`/api/depotlocations/searchwithstock?q=${fabricId}`).then(
      ({ data }) => {
        setDepotLocations(data);
      },
    );
  };

  useEffect(() => {
    fetchDepotLocationsWithStock();
  }, [formData.fabric]);

  useEffect(() => {
    console.log("yes match effect is called");

    setOpenedFabStockId("");
    if (match.params.id) {
      fetchFabStock(match.params.id).then(entry => {
        setOpenedFabStockId(entry.id);
        toggleEditState(false);
        fetchDepotLocations();
        setFormData(prev => {
          return {
            ...prev,
            price: entry.price,
            weight: entry.weight,
            rolls: entry.rolls,
            entry_date: entry.entry_date ? new moment(entry.entry_date) : null,
            //contractor: addLabelFieldsToContractor(entry.contractor),
            fabric: addLabelFieldsToFabric(entry.fabric),
            fromDepotLocation: entry.fromDepotLocation_id,
            toDepotLocation: entry.toDepotLocation_id,
          };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  let validated = validation;

  let depotLocationOptions = depotLocations.map(depot => {
    let weight = 0;
    if (depot.totalWeight) {
      weight = depot.totalWeight;
    }
    if (match.params.id) {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}
        </Option>
      );
    } else {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}, остаток: {weight}
        </Option>
      );
    }
  });

  const handleFromDepotLocationChange = location => {
    setFormData(prevData => {
      return { ...prevData, fromDepotLocation: location };
    });
  };

  const handleToDepotLocationChange = location => {
    setFormData(prevData => {
      return { ...prevData, toDepotLocation: location };
    });
  };

  const createRows = () => {
    let rows = [];
    if (openedFabStock && openedFabStock.fabStocks) {
      openedFabStock.fabStocks.forEach(stock => {
        rows.push(stock);
        //rows.push({name})
      });
    }
    return rows;
  };

  const rows = createRows();

  const onFabDepotEntryClick = id => {
    //console.log(id);
    history.push(`/fabdepot/` + id);
  };

  const disabledDate = current => {
    // Can not select days before today and today
    return current > moment().endOf("day");
  };

  return (
    <div className="mt-paper">
      <div>
        <h2>Запись перемещения</h2>
      </div>
      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="prodDate">
          Дата перемещения
        </label>
        <br />
        <DatePicker
          disabledDate={disabledDate}
          id="entry_date"
          name="date"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          value={formData.entry_date}
          style={{ zIndex: 9999 }}
          disabled={!isInEditState}
        />
        <br />
        <span className="help-block">{validation.entry_dateField.message}</span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validation.fabricField.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="product">
          Материал
        </label>
        <AsyncSelect
          name="fabric"
          value={formData.fabric}
          loadOptions={getFabricOptions}
          onChange={handleFabricSelect}
          isDisabled={!isInEditState}
          styles={customStyles}
          noOptionsMessage={() => {
            return "Печатайте для поиска...";
          }}
          menuPortalTarget={document.body}
        />
        <span className="help-block">{validation.fabricField.message}</span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={
          validation.fromDepotLocationField.isInvalid ? "has-error" : ""
        }
      >
        <label className="mt-label" htmlFor="job_title">
          Откуда
        </label>
        <br />
        <SelectAntD
          disabled={!isInEditState}
          value={formData.fromDepotLocation}
          style={{ width: 300 }}
          onChange={handleFromDepotLocationChange}
        >
          {depotLocationOptions}
        </SelectAntD>
        <br />
        <span className="help-block">
          {validation.fromDepotLocationField.message}
        </span>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validation.toDepotLocationField.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="job_title">
          Куда
        </label>
        <br />
        <SelectAntD
          disabled={!isInEditState}
          value={formData.toDepotLocation}
          style={{ width: 300 }}
          onChange={handleToDepotLocationChange}
        >
          {depotLocationOptions}
        </SelectAntD>
        <br />
        <div className="help-block">
          {validation.toDepotLocationField.message}
        </div>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validated.weight.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="weight">
          Вес
        </label>
        <br />
        <InputNumber
          style={{ width: 300 }}
          name="weight"
          placeholder="Вес"
          //onChange={this.handleInputChange}
          value={formData.weight}
          onChange={handleWeightChange}
          disabled={!isInEditState}
        />
        <div className="help-block">{validated.weight.message}</div>
      </div>
      <div
        style={{ marginBottom: 15 }}
        className={validated.rolls.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="rolls">
          Кол-во рулонов
        </label>
        <br />
        <InputNumber
          style={{ width: 300 }}
          name="rolls"
          placeholder="Кол-во рулонов"
          //onChange={this.handleInputChange}
          value={formData.rolls}
          onChange={handleRollsChange}
          disabled={!isInEditState}
        />
        <div className="help-block">{validated.rolls.message}</div>
      </div>
      {match.params.id && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Из прихода</TableCell>
                <TableCell align="right">Тип</TableCell>
                <TableCell align="right">Склад </TableCell>
                <TableCell align="right">Вес (kg)</TableCell>
                <TableCell align="right">Цена </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <a
                      onClick={e => {
                        e.preventDefault();
                        onFabDepotEntryClick(row.fabDepotEntry_id);
                      }}
                      href={"/fabdepot/" + row.fabDepotEntry_id}
                    >
                      {"FDE-" + row.fabDepotEntry_id}
                    </a>
                    {/*{row.fabDepotEntry.entry_date}*/}
                  </TableCell>
                  <TableCell align="right">
                    {row.fabTransactionType.name}
                  </TableCell>
                  <TableCell align="right">{row.depotLocation.name}</TableCell>
                  <TableCell align="right">{row.weight}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedFabStock: getFabStock(state, state.scenes.fabStock.openedFabStockId),
    depotLocations: getAllDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFabStock: id => dispatch(fetchFabStock(id)),
    setOpenedFabStockId: id => dispatch(setOpenedFabStockId(id)),
    createFabStock: location => dispatch(createFabStock(location)),
    updateFabStock: (id, entry) => dispatch(updateFabStock(id, entry)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};

FabStockDetail = withStyles(styles)(FabStockDetail);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabStockDetail),
);
