import { Breadcrumb, Button, Dropdown, Layout, Menu } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchProdDepotLocations } from "../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../data/prodDepotLocations/reducer";
import {
  deleteProdStock,
  fetchProdStocks,
} from "../../data/prodStocks/actions";
import { getAllProdStocks } from "../../data/prodStocks/reducer";
import ProdStockTable from "./components/ProdStockTable";

function ProdStockMain(props) {
  const {
    history,
    prodStocks,
    fetchProdStocks,
    fetchDepotLocations,
    deleteProdStock,
  } = props;

  const [loading, setLoading] = useState(() => {
    if (prodStocks.length > 0) {
      return false;
    } else return true;
  });
  const [prodDepotLocationOptions, setProdDepotLocationOptions] = useState([]);

  const onProdStockCreatte = () => {
    history.push(`/prodstock/new`);
  };

  const handleEditClick = contractor => {
    history.push(`/prodstock/` + contractor.id);
  };

  const onRemove = value => {
    deleteProdStock(value.id);
  };

  useEffect(() => {
    fetchProdStocks().then(depot => {
      setLoading(false);
    });

    fetchDepotLocations().then(options => {
      setProdDepotLocationOptions(options);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Движение продукции</h2>
        {/* {console.log(prodDepotEntries)} */}
        <Button type="primary" onClick={onProdStockCreatte}>
          Создать
        </Button>
        &nbsp;
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => history.push("/logs/prodStockMovement")}
              >
                Посмотреть в журнале
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={{ marginLeft: "0.5em" }}>...</Button>
        </Dropdown>
      </div>
      <br />
      <ProdStockTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        prodStocks={prodStocks}
        prodDepotLocationOptions={prodDepotLocationOptions}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    prodDepotLocations: getAllProdDepotLocations(state),
    prodStocks: getAllProdStocks(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProdStocks: () => dispatch(fetchProdStocks()),
    deleteProdStock: id => dispatch(deleteProdStock(id)),
    fetchDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdStockMain),
);
