import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  createProdDepotLocation,
  fetchProdDepotLocation,
  updateProdDepotLocation,
} from "../../../../data/prodDepotLocations/actions";
import { getProdDepotLocation } from "../../../../data/prodDepotLocations/reducer";
import { setOpenedProdDepotLocationId } from "../../actions";
import FormValidator from "./FormValidator";

let validator = new FormValidator([
  {
    field: "name",
    method: "isEmpty",
    validWhen: false,
    message: "Введите название склада",
  },
]);

function DepotEntryDetail(props) {
  const {
    history,
    match,
    fetchProdDepotLocation,
    setOpenedProdDepotLocationId,
    createProdDepotLocation,
    updateProdDepotLocation,
  } = props;
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [isInEditState, toggleEditState] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });

  const createProdDepotLocationFromValues = () => {
    return {
      name: formData.name,
    };
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const validated = validator.validate(formData);
    setValidation(validated);

    if (validated.isValid) {
      setSaving(true);
      var prodDepotLocation = createProdDepotLocationFromValues();
      //Handle update
      if (match.params.id) {
        return updateProdDepotLocation(match.params.id, prodDepotLocation).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            history.push(`/prodDepotLocation/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
      //Handle create
      else {
        return createProdDepotLocation(prodDepotLocation).then(
          json => {
            toggleEditState(false);
            setSaving(false);
            history.push(`/prodDepotLocation/` + json.id);
          },
          error => {
            setSaving(false);
          },
        );
      }
    }
  };

  const handleNameChange = e => {
    let value = e.target.value;
    const name = e.target.name;

    setFormData(prevData => {
      return { ...prevData, name: value };
    });
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(formData));
    }
  }, [formData]);

  useEffect(() => {
    setOpenedProdDepotLocationId("");
    if (match.params.id) {
      fetchProdDepotLocation(match.params.id).then(depot => {
        setOpenedProdDepotLocationId(depot.id);
        toggleEditState(false);

        setFormData(prev => {
          return { ...prev, name: depot.name };
        });
      });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  let validated = validation;

  return (
    <div className="mt-paper">
      <div>
        <h2>Складское помещение</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={handleSubmit} loading={isSaving}>
          Сохранить
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={handleSubmit} loading={isSaving}>
          Создать
        </Button>
      )}
      {match.params.id && !isInEditState && (
        <Button onClick={() => toggleEditState(true)}>Изменить</Button>
      )}
      <br />
      &nbsp;
      {/* <EmployeeForm teamOptions={teamOptions} enableReinitialize initialValues={{...openedEmployee, team:teamId }} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
      <div
        style={{ marginBottom: 15 }}
        className={validated.name.isInvalid ? "has-error" : ""}
      >
        <label className="mt-label" htmlFor="price">
          Имя
        </label>
        <Input
          name="first_name"
          placeholder="Имя"
          //onChange={this.handleInputChange}
          value={formData.name}
          onChange={handleNameChange}
          disabled={!isInEditState}
        />
        <span className="help-block">{validated.name.message}</span>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedDepotLocation: getProdDepotLocation(
      state,
      state.scenes.depotLocations.openedProdDepotLocationId,
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProdDepotLocation: id => dispatch(fetchProdDepotLocation(id)),
    setOpenedProdDepotLocationId: id =>
      dispatch(setOpenedProdDepotLocationId(id)),
    createProdDepotLocation: location =>
      dispatch(createProdDepotLocation(location)),
    updateProdDepotLocation: (id, entry) =>
      dispatch(updateProdDepotLocation(id, entry)),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DepotEntryDetail),
);
