import { message } from "antd";
import PropTypes from "prop-types";
import React, { Component, useState } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

const ReactTableAsync = React.forwardRef((props, ref) => {
  const {
    dataForCurrentPage,
    onFetchDataForPage,
    onSetCurrentDataPage,
    searchEnabled,
    onSetPageSize,
    isLoading,
  } = props;

  const [pages, setPages] = useState(-1);

  return (
    <div>
      <ReactTable
        ref={ref}
        data={isLoading ? [] : dataForCurrentPage} // should default to []
        pages={pages} // should default to -1 (which means we don't know how many pages we have)
        manual
        onFetchData={(state, instance) => {
          //setLoading(true);
          onSetPageSize(state.pageSize);
          onSetCurrentDataPage(state.page);
          onFetchDataForPage(state.page, state.pageSize, state.filtered, props.fetchOptions).then(
            res => {
              if (res) {
                //setData(res.results);
                setPages(Math.ceil(res.total / state.pageSize));
              }
            },
          );
        }}
        getTdProps={(state, rowInfo, column) => {
          //console.log(column);
          if (isLoading) {
            return {
              className: "pt-skeleton",
            };
          } else {
            return {};
          }
        }}
        className="-striped -highlight"
        //data={loading?[]: productionOrders}
        //columns={columns}
        previousText="Пред"
        nextText="След"
        loadingText="Загрузка..."
        noDataText="Нет записей"
        pageText="Страница"
        ofText="из"
        filterable={searchEnabled}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        rowsText="строк"
        defaultPageSize={50}
        freezeWhenExpanded={true}
        {...props}
      />
    </div>
  );
})

ReactTableAsync.propTypes = {
  dataForCurrentPage: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFetchDataForPage: PropTypes.func.isRequired,
  onSetPageSize: PropTypes.func.isRequired,
  onSetCurrentDataPage: PropTypes.func.isRequired,
};

export default ReactTableAsync

//export default withRouter(ProductionTable);
