import { Button, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { deletePayment, fetchPayments } from "../../data/payments/actions";
import isMobile from "../../utils/mobileCheck";
import PaymentTable from "./components/PaymentTable";

function ClientMain(props) {
  const { fetchPayments, history, payments, deletePayment } = props;

  const [isFetchingPayments, setIsFetchingPayments] = useState(false);

  useEffect(() => {
    setIsFetchingPayments(true);
    fetchPayments().then(
      () => setIsFetchingPayments(false),
      () => setIsFetchingPayments(false),
    );
  }, []);

  const onCreateClick = () => {
    history.push("/payment/new");
  };

  const onDeleteClick = paymentId => {
    deletePayment(paymentId);
  };

  return (
    <div>
      <h2>Платежи</h2>
      <div>
        <Button
          style={isMobile() ?  { width: "100%" } : {}}
          type={"primary"}
          onClick={onCreateClick}
        >
          Создать
        </Button>

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => history.push("/logs/payment")}>
                Посмотреть в журнале
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={ isMobile() ? { width: "100%", marginTop: "10px" } : {marginLeft: "0.5em"}}>...</Button>
        </Dropdown>
      </div>
      <br />
      <PaymentTable
        isFetchingClients={isFetchingPayments}
        payments={payments}
        history={history}
        onDelete={onDeleteClick}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  payments: state.data.payments.all,
});

const mapDispatchToProps = dispatch => ({
  fetchPayments: () => dispatch(fetchPayments()),
  deletePayment: paymentId => dispatch(deletePayment(paymentId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ClientMain),
);
