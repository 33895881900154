import { createSelector } from "reselect";

export const getWages = state => state.data.wages;
export const getEmployees = state => state.data.employees;

export const getWagesForSelectedEmployee = state => {
  let wages = getWages(state);
  let employees = getEmployees(state);

  if (
    state.scenes.wagescan.selectedEmployeeId &&
    employees.byId[state.scenes.wagescan.selectedEmployeeId]
  ) {
    let employee = employees.byId[state.scenes.wagescan.selectedEmployeeId];
    if (employee.wages) {
      return employee.wages.map(wageId => wages.byId[wageId]);
    }
  }

  return [];
};

export const getTotalForSelectedEmployee = state => {
  let wages = getWages(state);
  let employees = getEmployees(state);

  if (
    state.scenes.wagescan.selectedEmployeeId &&
    employees.byId[state.scenes.wagescan.selectedEmployeeId]
  ) {
    let employee = employees.byId[state.scenes.wagescan.selectedEmployeeId];

    if (employee.wages)
      return employee.wages
        .map(wageId => wages.byId[wageId])
        .reduce(function(acc, val) {
          return acc + val.sum;
        }, 0);
  }

  return 0;
};

//export const getOpenedProdEntry=(state)=>orders.getProdEntries(state).byId[getOpenedProdEntryId(state)]
