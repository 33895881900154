import { Button, Input } from "antd";
import { Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { signInUser } from "../../data/users/actions";
import FormValidator from "../FormValidator";

//import { fetchUser, createUser, updateUser } from '../../../../data/users/actions';
//import {fetchRoles } from '../../../../data/roles/actions';
//import { getAllRoles } from '../../../../data/roles/reducer';
//import { setOpenedUserId } from '../../actions';

const Option = Select.Option;

class Login extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "username",
        method: "isEmpty",
        validWhen: false,
        message: "Введите имя пользователя",
      },

      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Введите пароль",
      },

      // {
      //     field: 'last_name',
      //     method: 'isEmpty',
      //     validWhen: false,
      //     message: 'Введите фамилию'
      //   },
      // {
      //     field: 'middle_name',
      //     method: 'isEmpty',
      //     validWhen: false,
      //     message: 'Введите отчество'
      // },
      // {
      //     field: 'team',
      //     method: ()=>{
      //         if(Number.isInteger(this.state.team) ){
      //            return false;

      //         }
      //         else
      //             return true;
      //     },
      //     validWhen: false,
      //     message: 'Выберите бригаду'
      //   },
    ]);

    this.state = {
      isInEditState: false,

      username: "",
      password: "",

      isLoading: false,

      validation: this.validator.valid(),
    };

    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  createUserFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var user = {
      username: this.state.username,
      password: this.state.password,
    };
    return user;
  };

  handleSubmit = e => {
    e.preventDefault();
    const { match, signInUser } = this.props;

    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    let user = this.createUserFromValues();

    if (validation.isValid) {
      this.setState({ isLoading: true });

      signInUser(user).then(
        data => {
          //Store JWT Token to browser session storage
          //If you use localStorage instead of sessionStorage, then this w/ persisted across tabs and new windows.
          //sessionStorage = persisted only in current tab
          //console.log("DATA", data);
          this.props.history.push(`/`);
        },
        error => {
          this.setState({ isLoading: false });
        },
      );
    }
  };

  componentDidMount() {
    //this.fetchUserOrResetForm();
  }

  componentDidUpdate(prevProps) {
    // const { match } = this.props;
    // if (prevProps.match.params.id !== match.params.id) {
    //     this.fetchUserOrResetForm();
    // }
  }

  // fetchUserOrResetForm=()=>{
  //     const { fetchUser, match, setOpenedUserId, fetchRoles } = this.props;
  //     setOpenedUserId('');
  //     if (match.params.id) {
  //         fetchUser(match.params.id).then(user => {
  //             setOpenedUserId(user.id);
  //             this.setState({ isInEditState: false });

  //             let rolesVal=[]
  //             if(user.roles && user.roles.length>0){
  //                 rolesVal=user.roles.map(role=>role.id)
  //             }

  //             this.setState({
  //                 username: user.username,
  //                 password: user.password,
  //                 first_name: user.first_name,
  //                 last_name: user.last_name,

  //                 selectedRoles:rolesVal,
  //             })
  //         });

  //         fetchRoles();
  //     }
  //     else{
  //         this.setState({ isInEditState: true });
  //         fetchRoles();
  //     }
  // }

  handleUsernameChange = e => {
    this.setState({
      username: e.target.value,
      usernameExists: false,
    });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    const { history, openedUser, match, roles } = this.props;

    //console.log("OPENED EMPLOYEE", openedEmployee)
    // let teamId=""
    // if(openedEmployee)
    //  teamId=openedEmployee.team_id;

    return (
      <div style={{ paddingTop: 200 }}>
        <div className="mt-paper">
          <form onSubmit={this.handleSubmit}>
            <div>
              <h2>Войти</h2>
            </div>
            {/* <EmployeeForm teamOptions={teamOptions} enableReinitialize initialValues={{...openedEmployee, team:teamId }} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}

            <div
              style={{ marginBottom: 15 }}
              className={validation.username.isInvalid ? "has-error" : ""}
            >
              <label className="mt-label" htmlFor="username">
                Имя пользователя
              </label>
              <Input
                name="username"
                placeholder="Имя пользователя"
                //onChange={this.handleInputChange}
                value={this.state.username}
                onChange={this.handleUsernameChange}
                //disabled={!this.state.isInEditState}
              />
              <span className="help-block">{validation.username.message}</span>
            </div>

            <div
              style={{ marginBottom: 15 }}
              className={validation.password.isInvalid ? "has-error" : ""}
            >
              <label className="mt-label" htmlFor="password">
                Пароль
              </label>
              <Input
                name="password"
                placeholder="Пароль"
                type="password"
                //onChange={this.handleInputChange}
                value={this.state.password}
                onChange={this.handlePasswordChange}
                //disabled={!this.state.isInEditState}
              />
              <span className="help-block">{validation.password.message}</span>
            </div>

            <Button
              htmlType="submit"
              loading={this.state.isLoading}
              type="primary"
              onClick={this.handleSubmit}
            >
              Войти
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    //roles: getAllRoles(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    //fetchUser: (id) => dispatch(fetchUser(id)),
    signInUser: user => dispatch(signInUser(user)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login),
);
