import { Button, Popconfirm, Select } from "antd";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

const Option = Select.Option;

class PendingFabStockTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
  };

  render() {
    const {
      fabStockMovementRequests,
      onEditClick,
      onRemove,
      classes,
      loading,
      depotLocationOptions,
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original.id, e);
            }}
            href={"/fabstock/movementrequest/" + row.original.id}
          >
            {"FSMR-" + row.value}
          </a>
        ),
      },
      {
        Header: "Дата",
        accessor: "entryDate",
        Cell: row =>
          row.value && (
            <span className="number">
              {new moment(row.value).format("DD/MM/YYYY")}
            </span>
          ),
        filterable: false,
        sortable: false,
      },
      {
        Header: "Создал запрос",
        Cell: row => {
          const initiator = row.original.initiator;
          return `${initiator.first_name} ${initiator.last_name} [${
            initiator.username
          }]`;
        },
        filterable: false,
        sortable: false,
      },
      {
        Header: "Материал",
        accessor: "fabric.name",
        id: "fabricName",
        Cell: row => <span className="number">{row.value}</span>,
        filterable: false,
      },
      {
        Header: "Перемещение из",
        accessor: "fromDepotLocation.name",
        filterable: false,
        sortable: false,
      },
      {
        Header: "Перемещение в",
        accessor: "toDepotLocation.name",
        filterable: false,
        sortable: false,
      },
      {
        Header: "Вес",
        accessor: "weight",
        id: "weight",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["weight"] }),
        filterAll: true,
        filterable: false,
      },
      {
        Header: "Рулоны",
        accessor: "rolls",
        id: "rolls",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["rolls"] }),
        filterAll: true,
        filterable: false,
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={loading ? [] : fabStockMovementRequests}
          getTdProps={(state, rowInfo, column) => {
            if (loading) {
              return {
                className: "pt-skeleton",
              };
            } else {
              return {};
            }
          }}
          columns={columns}
          showPagination={false}
          defaultPageSize={fabStockMovementRequests.length}
          previousText="Пред"
          nextText="След"
          loadingText="Загрузка..."
          noDataText={loading ? "" : "Нет записей"}
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

PendingFabStockTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(PendingFabStockTable);
