// import { createSelector } from 'reselect'
// import  * as orders  from "../../data/orders/selectors"

import { createSelector } from "reselect";

export const getSpecs = state => {
  return state.data.specs;
};

export const getOpenedSpec = state => {
  return getSpecs(state).byId[state.scenes.specification.openedSpecId];
};

export const getAllSpecs = state => {
  let specs = getSpecs(state);
  return specs.allIds.map(id => specs.byId[id]);
};
