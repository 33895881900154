import { combineReducers } from "redux";

import {
  FABDEPOT_ENTRIES_FETCH_SUCCESS,
  FABDEPOT_ENTRY_CREATE_SUCCESS,
  FABDEPOT_ENTRY_DELETE_SUCCESS,
  FABDEPOT_ENTRY_FETCH_SUCCESS,
  FABDEPOT_ENTRY_UPDATE_SUCCESS,
} from "./actions";

//import { WAGE_ENTRY_CLAIM_SUCCESS, WAGE_ENTRY_UNCLAIM_SUCCESS, WAGE_ENTRY_DELETE_SUCCESS } from '../wages/actions'

const byId = (state = {}, action) => {
  switch (action.type) {
    case FABDEPOT_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabDepotEntries,
      };
    case FABDEPOT_ENTRY_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabDepotEntries,
      };
    case FABDEPOT_ENTRY_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabDepotEntries,
      };
    case FABDEPOT_ENTRY_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabDepotEntries,
      };
    case FABDEPOT_ENTRY_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.entryId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    // case WAGE_ENTRY_CLAIM_SUCCESS:

    //     const employeeId = action.employeeId;
    //     const wageId = action.response.result;
    //     const employee = state[employeeId]

    //     if (employee) {
    //         return {
    //             ...state,
    //             [employeeId]: {
    //                 ...employee,
    //                 wages: employee.wages.concat(wageId)
    //             }
    //         }
    //     } else {
    //         return state;
    //     }

    // case WAGE_ENTRY_UNCLAIM_SUCCESS:
    //     return unclaimWageEntry(state, action);

    // case WAGE_ENTRY_DELETE_SUCCESS:
    //     return deleteWageEntry(state, action);

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FABDEPOT_ENTRIES_FETCH_SUCCESS:
      return action.response.result;
    case FABDEPOT_ENTRY_CREATE_SUCCESS:
      return [...state, action.response.result];
    case FABDEPOT_ENTRY_DELETE_SUCCESS:
      return [...state.filter(id => id != action.entryId)];
    default:
      return state;
  }
};

function deleteWageEntry(state, action) {
  let employeeId = action.employeeId;
  let wageId = action.wageId;
  let employee = state[employeeId];
  return {
    ...state,
    [employeeId]: {
      ...employee,
      wages: employee.wages.filter(id => id != wageId),
    },
  };
}

function unclaimWageEntry(state, action) {
  let employeeId = action.employeeId;
  let wageId = action.wageId;
  let employee = state[employeeId];

  if (employee) {
    return {
      ...state,
      [employeeId]: {
        ...employee,
        wages: employee.wages.filter(id => id != wageId),
      },
    };
  } else {
    return state;
  }
}

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllFabDepotEntries = state => {
  //console.log("entrie",state.data.fabDepotEntries);

  return state.data.fabDepotEntries.allIds.map(
    id => state.data.fabDepotEntries.byId[id],
  );
};

export const getFabDepotEntry = (state, id) => {
  return state.data.fabDepotEntries.byId[id];
};
