import { Button, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { deleteContractorPayment, fetchContractorPayments } from "../../data/contractorPayments/actions";
import isMobile from "../../utils/mobileCheck";
import ContractorPaymentTable from "./components/ContractorPaymentTable";

function ContractorPaymentMain(props) {
  const { fetchContractorPayments, history, contractorPayments, deleteContractorPayment } = props;

  const [isFetchingContractorPayments, setIsFetchingContractorPayments] = useState(false);

  useEffect(() => {
    setIsFetchingContractorPayments(true);
    fetchContractorPayments().then(
      () => setIsFetchingContractorPayments(false),
      () => setIsFetchingContractorPayments(false),
    );
  }, []);

  const onCreateClick = () => {
    history.push("/contractorPayment/new");
  };

  const onDeleteClick = contractorPaymentId => {
    deleteContractorPayment(contractorPaymentId);
  };

  return (
    <div>
      <h2>Платежи</h2>
      <div>
        <Button
          style={isMobile() ?  { width: "100%" } : {}}
          type={"primary"}
          onClick={onCreateClick}
        >
          Создать
        </Button>

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => history.push("/logs/contractorPayment")}>
                Посмотреть в журнале
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={ isMobile() ? { width: "100%", marginTop: "10px" } : {marginLeft: "0.5em"}}>...</Button>
        </Dropdown>
      </div>
      <br />
      <ContractorPaymentTable
        isFetchingContractors={isFetchingContractorPayments}
        contractorPayments={contractorPayments}
        history={history}
        onDelete={onDeleteClick}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  contractorPayments: state.data.contractorPayments.all,
});

const mapDispatchToProps = dispatch => ({
  fetchContractorPayments: () => dispatch(fetchContractorPayments()),
  deleteContractorPayment: contractorPaymentId => dispatch(deleteContractorPayment(contractorPaymentId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractorPaymentMain),
);
