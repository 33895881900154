import { createSelector } from "reselect";

export const getReport = state =>
  state.scenes.employeeReport.data.employeeReport;

export const getEmployeeReport = createSelector(
  [getReport],
  report => {
    return Object.values(report)
      .filter(report => report.PR !== null)
      .sort((a, b) => {
        if (a.quantity > b.quantity) return -1;
        else if (a.quantity < b.quantity) return 1;
        else return 0;
      })
      .map((elem, index) => ({
        id: index + 1,
        Количество: elem.quantity === null ? 0 : elem.quantity,
        name:
          "Название: " +
          elem.name +
          "\nОперация: " +
          elem.operation +
          "\nСпецификация: " +
          elem.specification,
      }));
  },
);

export const getEmployeeReportForTable = createSelector(
  [getReport],
  report => {
    return Object.values(report)
      .filter(report => report.PR !== null)
      .sort((a, b) => {
        if (a.quantity > b.quantity) return -1;
        else if (a.quantity < b.quantity) return 1;
        else return 0;
      })
      .map((elem, index) => ({
        PR: elem.PR,
        id: index + 1,
        name: elem.name,
        operation: elem.operation,
        specification: elem.specification,
        quantity: elem.quantity,
      }));
  },
);
