import { Button, Popconfirm, Select } from "antd";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

const Option = Select.Option;

class FabricConsumptionTable extends Component {
  render() {
    const {
      fabricConsumptions,
      onEditClick,
      loading,
      deleteDirectFabricConsumption,
      pagesCount,
      depotLocationOptions,
      pageIndex,
      setPageIndex,
      pageSize,
      setPageSize,
    } = this.props;

    const columns = [
      {
        Header: "Материал",
        accessor: "fabric.name",
        id: "fabricName",
        Cell: row => <span className="number">{row.value}</span>,
        filterAll: true,
        filterable: false,
      },
      {
        Header: "Вес (кг)",
        accessor: "weight",
        Cell: props => (
          <span className="text">
            {this.props.fabTransactionTypeId === 4 ? -props.value : props.value}
          </span>
        ),
        id: "weight",
        filterAll: true,
        filterable: false,
      },
      {
        Header: "Склад",
        accessor: "depotLocation.name",
        Cell: props => <span className="text">{props.value}</span>,
        Filter: ({ filter, onChange }) => (
          <Select
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={value => onChange(value)}
          >
            <Option key={"all"} value="all">
              Все
            </Option>
            {depotLocationOptions &&
              depotLocationOptions.map(depot => (
                <Option key={depot.name} value={depot.name}>
                  {depot.name}
                </Option>
              ))}
          </Select>
        ),
        sortable: false,
        filterable: false,
      },
      {
        Header: "Дата",
        accessor: "entry_date",
        Cell: row =>
          row.value && (
            <span className="number">
              {new moment(row.value).format("DD/MM/YYYY")}
            </span>
          ),
        filterable: false,
        sortable: false,
      },
    ];

    if (this.props.fabTransactionTypeId === 4) {
      columns.splice(0, 0, {
        Header: "Заказ",
        Cell: ({ original }) => {
          let content = "";
          let order_id = null;
          if (original.cuttingEntry) {
            order_id = original.cuttingEntry.order_id;
          } else if (original.passportEntry) {
            order_id = original.passportEntry.order_id;
          }
          content = (
            <a onClick={() => this.props.history.push(`/cutting/${order_id}`)}>
              PR-{order_id}
            </a>
          );
          return <span className="text">{content}</span>;
        },
        filterable: false,
      });
    } else {
      columns.splice(0, 0, {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/fabricConsumption/" + row.original.id}
          >
            {"FC-" + row.value}
          </a>
        ),
      });
      columns.push({
        Header: "Удалить",
        filterable: false,
        maxWidth: 100,
        Cell: row => (
          <div>
            <Popconfirm
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => deleteDirectFabricConsumption(row.original.id)}
              onCancel={this.handleDeleteCancel}
              okText="Да"
              cancelText="Нет"
            >
              <a href="javascript:;">Удалить</a>
            </Popconfirm>
          </div>
        ),
        sortable: false,
      });
    }

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={loading ? [] : fabricConsumptions}
          getTdProps={() => {
            if (loading) {
              return {
                className: "pt-skeleton",
              };
            } else {
              return {};
            }
          }}
          columns={columns}
          defaultPageSize={pageSize}
          onPageSizeChange={pageSize => setPageSize(pageSize)}
          onPageChange={state => setPageIndex(state)}
          previousText="Пред"
          page={pageIndex}
          manual
          pages={pagesCount}
          nextText="След"
          loadingText="Загрузка..."
          noDataText={loading ? "" : "Нет записей"}
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

FabricConsumptionTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(FabricConsumptionTable);
