import { formatMonth, formatWeekDay } from "@app/shared/utils/DateUtils";
import { Button, Col, Row, Select } from "antd";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";

import { FormDatePicker } from "../../../../components/FormDatePicker";
import { FormGroup } from "../../../../components/FormGroup";
import { FormInput } from "../../../../components/FormInput";
import { FormSelect } from "../../../../components/FormSelect";
import { FormTimePicker } from "../../../../components/FormTimePicker";
import {
  formatWorkingScheduleType,
  getWorkingScheduleTypeKeys,
  getWorkingScheduleValidatorByType,
  workingScheduleTypes,
} from "../../helpers/WorkingScheduleTypeHelpers";

const monthOptions = Array.from({ length: 12 }, (_, x) => x);
const weekDayOptions = Array.from({ length: 7 }, (_, x) => x);
const monthDayOptions = Array.from({ length: 31 }, (_, x) => x + 1);

export function WorkingScheduleForm({
  id,
  type,
  onSubmit,
  onTypeChange,
  initialValues,
  isSaving,
}) {
  const keys = useMemo(() => getWorkingScheduleTypeKeys(type), [type]);
  const validationSchema = useMemo(
    () => getWorkingScheduleValidatorByType(type),
    [type],
  );

  const formInitialValues = useMemo(() => {
    const nextInitialValues = {};

    ["weight", "start_date", "end_date", ...keys].forEach(key => {
      nextInitialValues[key] = initialValues && initialValues[key];
    });

    return nextInitialValues;
  }, [keys, initialValues]);

  return (
    <Formik
      onSubmit={onSubmit}
      shouldUpdate={() => true}
      enableReinitialize={true}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <Button type="primary" htmlType="submit" loading={isSaving}>
          {id == null ? "Создать" : "Сохранить"}
        </Button>

        <br />
        <br />

        <Row gutter={16}>
          <Col span={12}>
            <FormGroup name="working_schedule_type" label="Тип">
              <Select value={type} onChange={onTypeChange}>
                {workingScheduleTypes.map(x => (
                  <Select.Option key={x} value={x}>
                    {formatWorkingScheduleType(x)}
                  </Select.Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          <Col span={12}>
            <FormInput name="weight" type="number" label="Приоритет" />
          </Col>
        </Row>

        <br />

        {keys.has("start_time") && keys.has("end_time") && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <FormTimePicker
                  name="start_time"
                  defaultOpenValue="08:00"
                  label="Начало рабочего дня"
                />
              </Col>

              <Col span={12}>
                <FormTimePicker
                  name="end_time"
                  defaultOpenValue="18:00"
                  label="Конец рабочего дня"
                />
              </Col>
            </Row>

            <br />
          </>
        )}

        {keys.has("day_of_week") && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <FormSelect
                  name="day_of_week"
                  allowClear={true}
                  label="День недели"
                >
                  {weekDayOptions.map(x => (
                    <Select.Option key={x} value={x}>
                      {formatWeekDay(x)}
                    </Select.Option>
                  ))}
                </FormSelect>
              </Col>
            </Row>

            <br />
          </>
        )}

        {keys.has("month") && keys.has("day_of_month") && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <FormSelect name="month" allowClear={true} label="Месяц">
                  {monthOptions.map(x => (
                    <Select.Option key={x} value={x}>
                      {formatMonth(x)}
                    </Select.Option>
                  ))}
                </FormSelect>
              </Col>

              <Col span={12}>
                <FormSelect
                  name="day_of_month"
                  allowClear={true}
                  label="День месяца"
                >
                  {monthDayOptions.map(x => (
                    <Select.Option key={x} value={x}>
                      {x}
                    </Select.Option>
                  ))}
                </FormSelect>
              </Col>
            </Row>

            <br />
          </>
        )}

        <Row gutter={16}>
          <Col span={12}>
            <FormDatePicker name="start_date" label="Активен с" />
          </Col>

          <Col span={12}>
            <FormDatePicker name="end_date" label="Активен до" />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}
