import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const SCHEDULES_FETCH_SUCCESS = "SCHEDULES_FETCH_SUCCESS";
export const SCHEDULE_FETCH_SUCCESS = "SCHEDULE_FETCH_SUCCESS";
export const SCHEDULE_CREATE_SUCCESS = "SCHEDULE_CREATE_SUCCESS";
export const SCHEDULE_UPDATE_SUCCESS = "SCHEDULE_UPDATE_SUCCESS";
export const SCHEDULE_DELETE_SUCCESS = "SCHEDULE_DELETE_SUCCESS";
export const SCHEDULE_WEIGHT_BATCH_UPDATE_PERFORM =
  "SCHEDULE_WEIGHT_BATCH_UPDATE_PERFORM";
export const SCHEDULE_WEIGHT_BATCH_UPDATE_SUCCESS =
  "SCHEDULE_WEIGHT_BATCH_UPDATE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchWorkingSchedules = () => {
  return dispatch => {
    return axios
      .get(`/api/working_schedule/`)
      .then(({ data }) => {
        dispatch({
          type: SCHEDULES_FETCH_SUCCESS,
          response: normalize(data.results, schema.arrayOfWorkingSchedules),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchWorkingSchedule = scheduleId => {
  return dispatch => {
    return axios
      .get(`/api/working_schedule/` + scheduleId)
      .then(({ data }) => {
        let schedule = data;
        let id = scheduleId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: SCHEDULE_FETCH_SUCCESS,
          response: normalize(schedule, schema.workingSchedule),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createWorkingSchedule = schedule => {
  return dispatch => {
    return axios
      .post(`/api/working_schedule/`, schedule)
      .then(({ data }) => {
        // Status looks good
        let schedule = data;
        dispatch({
          type: SCHEDULE_CREATE_SUCCESS,
          response: normalize(schedule, schema.workingSchedule),
        });
        openNotificationWithIcon(
          "Успешно",
          "График успешно добавлен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такой график уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateWorkingSchedule = (scheduleId, schedule) => {
  return dispatch => {
    return axios
      .put(`/api/working_schedule/` + scheduleId, schedule)
      .then(({ data }) => {
        dispatch({
          type: SCHEDULE_UPDATE_SUCCESS,
          response: normalize(data, schema.workingSchedule),
        });
        openNotificationWithIcon(
          "Успешно",
          "График успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такой график уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const batchUpdateWorkingScheduleWeight = values => {
  return dispatch => {
    dispatch({
      type: SCHEDULE_WEIGHT_BATCH_UPDATE_PERFORM,
      payload: values,
    });

    return axios
      .put("/api/working_schedule/weight", values)
      .then(({ data }) => {
        dispatch({
          type: SCHEDULE_WEIGHT_BATCH_UPDATE_SUCCESS,
          response: normalize(data, schema.arrayOfWorkingSchedules),
        });
        openNotificationWithIcon(
          "Успешно",
          "График успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такой график уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteWorkingSchedule = scheduleId => {
  return dispatch => {
    return axios
      .delete(`/api/working_schedule/` + scheduleId)
      .then(({ data }) => {
        dispatch({ type: SCHEDULE_DELETE_SUCCESS, scheduleId });

        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );

        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данный график используется в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
