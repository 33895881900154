import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Checkbox, Col, Input, Modal, notification } from "antd";
import { InputNumber, Select as SelectAntD, Spin } from "antd";
import axios from "axios";
import { Formik } from "formik";
import { serialize } from "helpers/utils";
import { normalize, default as schema } from "normalizr";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const confirm = Modal.confirm;

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "900px",
        minWidth: "900px",
      },
      root: {
        alignItems: "initial",
        zIndex: 301,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const newProductSchema = Yup.object().shape({
  article: Yup.string()
    .required("Введите артикуль")
    .typeError("Введите артикуль")
    .nullable(),
  name: Yup.string()
    .required("Введите название")
    .typeError("Введите название")
    .nullable(),
  price: Yup.number()
    .min(0, "Значение цены слишком маленькое")
    .max(99999999, "Значение цены слишком большое")
    .required("Введите цену")
    .typeError("Введите число для цены"),
});

function CreateNewProductModal(props) {
  const {
    history,
    showModal,
    setShowModal,
    order,
    fullScreen,
    setOrderForCompletionModal,
    originalProduct,
    setFormData,
    formData,
    index,
    setFieldValueForExternalForm,
    type,
  } = props;

  const [saving, setSaving] = useState(false);
  const [newProduct, setNewProduct] = useState({
    ...originalProduct,
    article: "",
    name: "",
    price: "",
  });
  const [serverErrors, setServerErrors] = useState({});

  const onSubmit = values => {
    setSaving(true);
    axios
      .post(`/api/products/uniquenessCheck`, {
        article: values.article,
        name: values.name,
      })
      .then(() => {
        setSaving(false);
        const newProduct = {
          ...originalProduct,
          article: values.article,
          name: values.name,
          price: values.price,
          label: values.name,
          creatingNew: true,
        };

        switch (type) {
          case 1: {
            setFieldValueForExternalForm("faultyProduct", newProduct);
            break;
          }
          case 2: {
            setFieldValueForExternalForm(
              `prodBoms[${index}].faultyProductOfSubProduct`,
              newProduct,
            );
            break;
          }
          case 3: {
            setFieldValueForExternalForm(
              `prodBoms[${index}].subProduct`,
              newProduct,
            );
            break;
          }
        }
        close();
      })
      .catch(error => {
        setSaving(false);
        if (error.response) {
          setServerErrors({
            ...serverErrors,
            article: error.response.data.article,
            name: error.response.data.name,
          });
        }
      });
  };

  const form = createRef();

  useEffect(() => {
    if (showModal) {
      setNewProduct({
        ...originalProduct,
        article: "",
        price: "",
        creatingNew: true,
      });
    } else {
      setNewProduct({
        article: "",
        name: "",
        price: "",
        creatingNew: true,
      });
    }
  }, [showModal]);

  const close = () => {
    setShowModal(false);
    setNewProduct({
      article: "",
      name: "",
      price: "",
      creatingNew: true,
    });
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          fullScreen={fullScreen}
          open={showModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Создать новый продукт:
          </DialogTitle>
          <DialogContent>
            <br />
            <Formik
              ref={form}
              initialValues={newProduct}
              validationSchema={newProductSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                touched,
                isSubmitting,
              }) => (
                <div>
                  <div>
                    <div
                      style={{ marginBottom: 15 }}
                      className={
                        errors.article && touched.article
                          ? "has-error"
                          : serverErrors.article
                          ? "has-error"
                          : ""
                      }
                    >
                      <label className="mt-label" htmlFor="article">
                        Артикул
                      </label>
                      <Input
                        name="article"
                        placeholder="Артикул"
                        value={values["article"]}
                        onChange={e => {
                          setFieldValue("article", e.target.value);
                          setServerErrors({
                            ...serverErrors,
                            article: undefined,
                          });
                        }}
                      />
                      <div className="help-block">
                        {errors.article && touched.article && errors.article}
                      </div>
                      <div className="help-block">
                        {serverErrors.article && serverErrors.article}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{ marginBottom: 15 }}
                      className={
                        errors.name && touched.name
                          ? "has-error"
                          : serverErrors.name
                          ? "has-error"
                          : ""
                      }
                    >
                      <label className="mt-label" htmlFor="name">
                        Название
                      </label>
                      <Input
                        name="name"
                        placeholder="Название"
                        value={values["name"]}
                        onChange={e => {
                          setFieldValue("name", e.target.value);
                          setServerErrors({ ...serverErrors, name: undefined });
                        }}
                      />
                      <div className="help-block">
                        {errors.name && touched.name && errors.name}
                      </div>
                      <div className="help-block">
                        {serverErrors.name && serverErrors.name}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{ marginBottom: 15 }}
                      className={
                        errors.price && touched.price ? "has-error" : ""
                      }
                    >
                      <label className="mt-label" htmlFor="price">
                        Цена
                      </label>
                      <InputNumber
                        name="price"
                        placeholder="Цена"
                        style={{ width: "100%" }}
                        value={values["price"]}
                        onChange={e => setFieldValue("price", e)}
                      />
                      <div className="help-block">
                        {errors.price && touched.price && errors.price}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <Button type="primary" onClick={() => form.current.submitForm()}>
              Сохранить
            </Button>

            <Button onClick={close}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateNewProductModal),
);
