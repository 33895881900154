import { Popconfirm } from "antd";
import matchSorter from "match-sorter";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

function DepotEntryTable(props) {
  const handleDeleteConfirm = (row, e) => {
    const { onRemove } = props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  const handleDeleteCancel = e => {
    console.log(e);
  };

  const { depotLocations, onEditClick, onRemove, classes, loading } = props;
  const columns = [
    {
      Header: "",
      accessor: "id",
      maxWidth: 100,
      filterable: false,
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            onEditClick(row.original, e);
          }}
          href={"/depotlocation/" + row.original.id}
        >
          {"FDEP-" + row.value}
        </a>
      ),
    },
    {
      Header: "Имя",
      accessor: "name", // String-based value accessors!
      id: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Удалить",
      filterable: false,
      //accessor: 'product.name',
      maxWidth: 100,
      Cell: row => (
        <div>
          <Popconfirm
            title="Уверены что хотите удалить эту запись?"
            onConfirm={e => handleDeleteConfirm(row.original, e)}
            onCancel={handleDeleteCancel}
            okText="Да"
            cancelText="Нет"
          >
            <a href="javascript:;">Удалить</a>
          </Popconfirm>
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <ReactTable
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        className="-striped -highlight"
        data={loading ? [] : depotLocations}
        getTdProps={(state, rowInfo, column) => {
          //console.log(column);
          if (loading) {
            return {
              className: "pt-skeleton",
            };
          } else {
            return {};
          }
        }}
        columns={columns}
        defaultPageSize={50}
        previousText="Пред"
        nextText="След"
        loadingText="Загрузка..."
        noDataText={loading ? "" : "Нет записей"}
        pageText="Страница"
        ofText="из"
        rowsText="строк"
      />
    </div>
  );
}

export default withRouter(DepotEntryTable);
