import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";

const period_id = (state = null, action) => {
  switch (action.type) {
    case "SELECT_PERIOD_FOR_STATISTICS":
      return action.period_id;
    default:
      return state;
  }
};

const partition = (state = "days", action) => {
  switch (action.type) {
    case "SELECT_PARTITION_FOR_STATISTICS":
      return action.partition;
    default:
      return state;
  }
};

const range = (state = null, action) => {
  switch (action.type) {
    case "SELECT_RANGE_FOR_STATISTICS":
      return action.range;
    default:
      return state;
  }
};

const entries = (state = {}, action) => {
  switch (action.type) {
    case "REPORT_ENTRIES_FETCH_SUCCESS":
      return action.response;
    default:
      return state;
  }
};

const minMaxDates = (state = {}, action) => {
  switch (action.type) {
    case "SET_MINIMUM_AND_MAXIMUM_DATES":
      return action.minMax;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  data: dataReducer,
  entries,
  period_id,
  partition,
  range,
  minMaxDates,
});
