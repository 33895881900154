import { combineReducers } from "redux";

import { STATISTICS_BY_PERIOD_FETCH_SUCCESS } from "./actions";

export default (state = [], action) => {
  switch (action.type) {
    case STATISTICS_BY_PERIOD_FETCH_SUCCESS:
      return action.response;
    default:
      return state;
  }
};
