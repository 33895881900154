import { useCallback, useEffect, useRef } from "react";

export function useCallbackProxy(callback) {
  const callbackRef = useRef(callback);
  const callbackProxy = useCallback(
    (...args) => callbackRef.current(...args),
    [],
  );
  useEffect(() => {
    callbackRef.current = callback;
  });
  return callbackProxy;
}
