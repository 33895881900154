import { combineReducers } from "redux";

import {
  CUTTING_ENTRY_CREATE_SUCCESS,
  CUTTING_ENTRY_DELETE_SUCCESS,
} from "../cuttingEntries/actions";
import { ORDER_FETCH_SUCCESS } from "../orders/actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case ORDER_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodSpreads,
      };

    case CUTTING_ENTRY_CREATE_SUCCESS:
      const prodSpreadId = action.prodSpreadId;
      const cuttingEntryId = action.response.result;
      const prodSpread = state[prodSpreadId];

      return {
        ...state,
        [prodSpreadId]: {
          ...prodSpread,
          cuttingEntries: prodSpread.cuttingEntries.concat(cuttingEntryId),
        },
      };
    case CUTTING_ENTRY_DELETE_SUCCESS:
      return deleteCuttingEntry(state, action);
    default:
      return state;
  }
};

function deleteCuttingEntry(state, action) {
  let prodSpreadId = action.prodSpreadId;
  let cuttingEntryId = action.cuttingEntryId;
  let prodSpread = state[prodSpreadId];
  return {
    ...state,
    [prodSpreadId]: {
      ...prodSpread,
      cuttingEntries: prodSpread.cuttingEntries.filter(
        id => id != cuttingEntryId,
      ),
    },
  };
}

export const reducer = combineReducers({
  byId,
});
