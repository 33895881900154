import { Button, notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import { WAGE_ENTRIES_FETCH_SUCCESS } from "../wages/actions";
import * as schema from "./schema";

export const FABSTOCKS_FETCH_SUCCESS = "FABSTOCKS_FETCH_SUCCESS";
export const FABSTOCK_FETCH_SUCCESS = "FABSTOCK_FETCH_SUCCESS";
export const FABSTOCK_CREATE_SUCCESS = "FABSTOCK_CREATE_SUCCESS";
export const FABSTOCK_UPDATE_SUCCESS = "FABSTOCK_UPDATE_SUCCESS";
export const FABSTOCK_DELETE_SUCCESS = "FABSTOCK_DELETE_SUCCESS";
export const FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS =
  "FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS";
export const FABSTOCK_MOVEMENT_REQUEST_DECLINE_SUCCESS =
  "FABSTOCK_MOVEMENT_REQUEST_DECLINE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const declineFabStockMovementRequest = id => dispatch =>
  axios.post(`/api/fabstockrequest/${id}/decline`).then(({ data }) => {
    dispatch({
      type: FABSTOCK_MOVEMENT_REQUEST_DECLINE_SUCCESS,
      fabStockMovementRequestId: id,
    });

    return null;
  });

export const approveFabStockMovementRequest = id => dispatch =>
  axios
    .post(`/api/fabstockrequest/${id}/approve`)
    .then(({ data }) => {
      let fabStockMovement = data;
      dispatch({
        type: FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS,
        response: normalize(fabStockMovement, schema.fabStock),
        fabStockMovementRequestId: id,
      });
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const fetchFabStocks = () => {
  return dispatch => {
    return axios
      .get(`/api/fabstocks/`)
      .then(({ data }) => {
        let fabStocks = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: FABSTOCKS_FETCH_SUCCESS,
          response: normalize(fabStocks, schema.arrayOfFabStocks),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchFabStock = stockId => {
  return dispatch => {
    return axios
      .get(`/api/fabstocks/` + stockId)
      .then(({ data }) => {
        let fabStock = data;
        let id = stockId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: FABSTOCK_FETCH_SUCCESS,
          response: normalize(fabStock, schema.fabStock),
          id,
        });
        //dispatch({ type: WAGE_ENTRIES_FETCH_SUCCESS, response: normalize(fabDepotEntry, schema.fabDepotEntry) })
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createFabStock = fabStock => {
  return dispatch => {
    return axios
      .post("/api/fabstocks/", fabStock)
      .then(({ data }) => {
        // Status looks good
        let entry = data;
        dispatch({
          type: FABSTOCK_CREATE_SUCCESS,
          response: normalize(entry, schema.fabStock),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateFabStock = (entryId, entry) => {
  return dispatch => {
    return axios
      .put("/api/fabstocks/" + entryId, entry)
      .then(({ data }) => {
        // Status looks good
        let fabStock = data;
        dispatch({
          type: FABSTOCK_UPDATE_SUCCESS,
          response: normalize(fabStock, schema.fabStock),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteFabStock = fabStockId => {
  return dispatch => {
    return axios
      .delete("/api/fabstocks/" + fabStockId)
      .then(({ data }) => {
        let product = data;
        dispatch({ type: FABSTOCK_DELETE_SUCCESS, fabStockId });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данные этой записи используются в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          //console.log("Parsing eror: Most likely server crashed");

          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
