import { Button, DatePicker, Input } from "antd";
import { Modal, Select as SelectAntD } from "antd";
import moment from "moment";
import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FabDepotEntryForm from "scenes/FabDepot/forms/FabDepotEntryForm";
import {
  checkAvailable,
  decodeStatus,
  generateFabStockEntryData,
  getDefaultPrinter,
  readPrinterStatus,
  sendToPrinter,
} from "scenes/printHelper";

import { fetchDepotLocations } from "../../../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../../../data/depotLocations/reducer";
import {
  createFabDepotEntry,
  fetchFabDepotEntry,
  updateFabDepotEntry,
} from "../../../../data/fabDepotEntries/actions";
import {
  getDepotLocation,
  getFabDepotEntry,
} from "../../../../data/fabDepotEntries/reducer";
import { setOpenedFabDepotEntryId } from "../../actions";

const confirm = Modal.confirm;
const Option = SelectAntD.Option;

function FabDepotEntryDetail(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    match,
    fetchFabDepotEntry,
    setOpenedFabDepotEntryId,
    createFabDepotEntry,
    openedFabDepotEntry,
    updateFabDepotEntry,
    fetchDepotLocations,
    depotLocations,
  } = props;
  const form = useRef();
  const [isInEditState, toggleEditState] = useState(false);
  const [showEdit, setShowEdit] = useState(null);
  const [formData, setFormData] = useState({
    price: null,
    weight: null,
    rolls: 0,
    entry_date: null,
    batchNumber: "",
    contractor: "",
    fabric: null,
    depotLocation: null,
  });
  const [isSaving, setSaving] = useState(false);

  const createDepotLocationFromValues = values => {
    var depot = {
      price: values.price,
      weight: values.weight,
      rolls: values.rolls,
      batchNumber: values.batchNumber,
      entry_date: new moment(values.entryDate).format("YYYY-MM-DD HH:mm:ss"),
      contractor: values.contractor,
      fabric: values.fabric,
      depotLocation_id: values.depotLocation,
    };
    return depot;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    var depotLocation = createDepotLocationFromValues(values);
    //Handle update
    if (match.params.id) {
      setSaving(true);
      return updateFabDepotEntry(match.params.id, depotLocation).then(
        json => {
          toggleEditState(false);
          setSaving(false);
          setSubmitting(false);

          history.push(`/fabdepot/` + json.id);
          printLabelsForEntry(json);
        },
        error => {
          setSaving(false);
          setSubmitting(false);
        },
      );
    }
    //Handle create
    else {
      setSaving(true);
      return createFabDepotEntry(depotLocation).then(
        json => {
          toggleEditState(false);
          setSaving(false);
          setSubmitting(false);
          history.push(`/fabdepot/` + json.id);
          printLabelsForEntry(json);
        },
        error => {
          setSaving(false);
          setSubmitting(false);
        },
      );
    }
  };

  const addLabelFieldsToContractor = contractor => {
    if (contractor) {
      let selectedContractor = {
        value: contractor.id,
        label: contractor.company_name,
        ...contractor,
      };
      return selectedContractor;
    }
    return null;
  };

  const addLabelFieldsToFabric = fabric => {
    if (fabric) {
      let selectedFabric = {
        value: fabric.id,
        label: fabric.name,
        ...fabric,
      };
      return selectedFabric;
    }
    return null;
  };

  const printLabelsForEntry = entry => {
    console.log("this is entry", entry);
    const { user } = props;
    getDefaultPrinter()
      .then(json => {
        //check if the default printer is available for printing
        return checkAvailable().then(available => {
          let foundPrinter = undefined;
          if (available.printer && available.printer.length > 0) {
            //return json
            available.printer.forEach(availablePrinter => {
              if (availablePrinter.name == json.name) {
                foundPrinter = availablePrinter;
              }
            });
          }
          if (foundPrinter == undefined) {
            throw "Принтер не подключен";
          } else {
            //send read printer status request
            return sendToPrinter(json, "~HQES").then(data => {
              return json;
            });
          }
        });
      })
      .then(printer => {
        //check if the printer is ready to print
        return readPrinterStatus(printer)
          .then(text => {
            let status = decodeStatus(text);
            if (status == "Ready to Print") {
              let data = "";
              for (let i = 0; i < entry.rolls; i++) {
                let contractor = entry.contractor
                  ? entry.contractor.company_name
                  : "";
                let fabric = entry.fabric ? entry.fabric.name : "";
                let entryDate = new moment(entry.entry_date).format(
                  "DD.MM.YYYY",
                );
                data += generateFabStockEntryData(
                  "03" + entry.id,
                  entryDate,
                  fabric,
                  contractor,
                  entry.weight,
                  entry.rolls,
                  user ? user.domain : "",
                );
              }
              return sendToPrinter(printer, data).then(res => {
                //this.close();
              });
            } else {
              throw status;
            }
          })
          .catch(error => {
            showConfirm(entry, error);
          });
      })
      .catch(error => {
        showConfirm(entry, error);
      });
  };

  const showConfirm = (entry, message) => {
    //let self = this;
    confirm({
      title: "Попробовать напечатать заного?",
      content: message,
      onOk() {
        printLabelsForEntry(entry);
      },
      onCancel() {
        //close();
      },
    });
  };

  const startSubmit = () => {
    form.current.submitForm();
  };

  useEffect(() => {
    setOpenedFabDepotEntryId("");
    if (match.params.id) {
      fetchFabDepotEntry(match.params.id).then(entry => {
        setShowEdit(false);

        if (entry.fabStocks && entry.fabStocks.length === 1) {
          if (entry.fabStocks[0].fabTransactionType_id === 1) {
            setShowEdit(true);
          }
        }
        setOpenedFabDepotEntryId(entry.id);
        toggleEditState(false);
        fetchDepotLocations();
        setFormData(prev => {
          return {
            ...prev,
            price: entry.price,
            weight: entry.weight,
            rolls: entry.rolls,
            batchNumber: entry.batchNumber,
            entry_date: entry.entry_date ? new moment(entry.entry_date) : null,
            contractor: addLabelFieldsToContractor(entry.contractor),
            fabric: addLabelFieldsToFabric(entry.fabric),
            depotLocation: entry.depotLocation_id,
          };
        });
      });
    } else {
      fetchDepotLocations();
      toggleEditState(true);
    }
  }, [match.params.id]);

  let depotLocationOptions = depotLocations.map(depot => {
    return (
      <Option key={depot.id} value={depot.id}>
        {depot.name}
      </Option>
    );
  });

  return (
    <div className="mt-paper">
      <div>
        <h2>Запись прихода</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={startSubmit} loading={isSaving}>
          Сохранить и распечатать
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={startSubmit} loading={isSaving}>
          Сохранить и распечатать
        </Button>
      )}
      {match.params.id && !isInEditState && showEdit === true && (
        <Button onClick={() => toggleEditState(true)}>Изменить</Button>
      )}

      <br />
      {showEdit === false && match.params.id && (
        <div>
          Эту запись нельзя редактировать так как у нее есть записи перемещения
          или ревизии
        </div>
      )}

      <br />

      <FabDepotEntryForm
        depotLocationOptions={depotLocationOptions}
        disabled={!isInEditState}
        ref={form}
        onSubmit={handleSubmit}
        fabDepotEntryId={openedFabDepotEntry ? openedFabDepotEntry.id : null}
        initialValues={{
          price: formData.price,
          rolls: formData.rolls,
          weight: formData.weight,
          entryDate: formData.entry_date,
          batchNumber: formData.batchNumber,
          contractor: formData.contractor,
          fabric: formData.fabric,
          depotLocation: formData.depotLocation,
        }}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedFabDepotEntry: getFabDepotEntry(
      state,
      state.scenes.fabDepot.openedFabDepotEntryId,
    ),
    depotLocations: getAllDepotLocations(state),
    user: state.data.users.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFabDepotEntry: id => dispatch(fetchFabDepotEntry(id)),
    setOpenedFabDepotEntryId: id => dispatch(setOpenedFabDepotEntryId(id)),
    createFabDepotEntry: location => dispatch(createFabDepotEntry(location)),
    updateFabDepotEntry: (id, entry) =>
      dispatch(updateFabDepotEntry(id, entry)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabDepotEntryDetail),
);
