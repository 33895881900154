import { createSelector } from "reselect";

import * as dataReducer from "./data/reducer";

export const getOpenedProductId = state => state.scenes.product.openedProduct;

export const getOpenedProduct = createSelector(
  [dataReducer.getProducts, getOpenedProductId],
  (products, productId) => {
    //let products = getProducts(state);
    return products.byId[productId];
  },
);
