import AttendanceReportType from "@app/shared/constants/AttendanceReportType";
import { Button } from "antd";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import {
  deleteAttendanceReport,
  fetchAttendanceReports,
  setAttendanceReportPageSize,
  setCurrentAttendanceReportPage,
} from "../../data/attendanceReports/actions";
import {
  getAttendanceReportsForCurrentPage,
  isPageInCache,
} from "../../data/attendanceReports/selectors";
import { AttendanceReportTable } from "./components/AttendanceReportTable";

const enhancer = connect(state => ({
  data: getAttendanceReportsForCurrentPage(state),
  isPageInCache: isPageInCache(state),
}));

function AttendanceReportList({ data, dispatch, history, match: { url } }) {
  const defaultPageSize = 50;

  const hasPending = useMemo(
    () => Boolean(data && data.some(x => x.result == null)),
    [data],
  );

  useEffect(() => {
    if (!hasPending) {
      return;
    }

    const interval = setInterval(() => {
      dispatch(
        fetchAttendanceReports(
          AttendanceReportType.AttendanceReportByDateRange,
          0,
          defaultPageSize,
        ),
      );
    }, 5 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [hasPending]);

  return (
    <div>
      <div>
        <h2>Отчет посещаемости</h2>

        <Button
          type="primary"
          onClick={() =>
            history.push(
              `${url}/add/${AttendanceReportType.AttendanceReportByDateRange}`,
            )
          }
        >
          Создать
        </Button>
      </div>

      <br />

      <AttendanceReportTable
        loading={!isPageInCache}
        attendanceReports={data}
        onEdit={({ id }) => {
          history.push(`${url}/${id}`);
        }}
        onDelete={({ id, type }) => {
          dispatch(deleteAttendanceReport(id, type));
        }}
        onFetchDataForPage={(page, pageSize) =>
          dispatch(
            fetchAttendanceReports(
              AttendanceReportType.AttendanceReportByDateRange,
              page,
              pageSize,
            ),
          )
        }
        onSetCurrentDataPage={currentPage =>
          dispatch(setCurrentAttendanceReportPage(currentPage))
        }
        onSetPageSize={pageSize =>
          dispatch(setAttendanceReportPageSize(pageSize))
        }
        defaultPageSize={defaultPageSize}
      />
    </div>
  );
}

export default enhancer(AttendanceReportList);
