import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const ROLES_FETCH_SUCCESS = "ROLES_FETCH_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchRoles = () => {
  return dispatch => {
    return axios
      .get(`/api/roles/`)
      .then(({ data }) => {
        let roles = data;
        dispatch({
          type: ROLES_FETCH_SUCCESS,
          response: normalize(roles, schema.arrayOfRoles),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
