import { Button, Popconfirm, Select } from "antd";
import ReactTable from "components/ReactTable";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";

const Option = Select.Option;

class ProdStockRevisionTable extends Component {
  handleDeleteConfirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    onRemove(row, e);
  };

  handleDeleteCancel = e => {
    console.log(e);
  };

  render() {
    const {
      prodStockRevisions,
      onEditClick,
      loading,
      prodDepotLocationOptions,
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <a
            onClick={e => {
              e.preventDefault();
              onEditClick(row.original, e);
            }}
            href={"/prodstock/" + row.original.id}
          >
            {"PSR-" + row.value}
          </a>
        ),
      },
      {
        Header: "Дата",
        accessor: "entry_date",
        Cell: row =>
          row.value && (
            <span className="number">
              {new moment(row.value).format("DD/MM/YYYY")}
            </span>
          ),
        filterable: false,
        sortable: false,
      },
      {
        Header: "Продукт",
        accessor: "product.name",
        id: "productName",
        Cell: row => <span className="number">{row.value}</span>,
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, { keys: ["productName"] });
        },
        filterAll: true,
      },
      {
        Header: "Склад",
        accessor: "prodDepotLocation.name",
        Cell: props => <span className="text">{props.value}</span>,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <Select
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={value => onChange(value)}
          >
            <Option key={"all"} value="all">
              Все
            </Option>
            {prodDepotLocationOptions &&
              prodDepotLocationOptions.map(depot => (
                <Option key={depot.name} value={depot.name}>
                  {depot.name}
                </Option>
              ))}
          </Select>
        ),
        sortable: false,
      },
      {
        Header: "Количество",
        accessor: "quantity",
        Cell: props => <span className="text">{props.value}</span>,
        id: "quantity",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["quantity"] }),
        filterAll: true,
        filterable: false,
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={prodStockRevisions}
          isLoading={loading}
          columns={columns}
          defaultPageSize={50}
        />
      </div>
    );
  }
}

ProdStockRevisionTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withRouter(ProdStockRevisionTable);
