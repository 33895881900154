import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class OperationTable extends Component {
  handleConfirm = (row, e) => {
    const { operations, onRemove } = this.props;

    onRemove(row.index);
    //const { spread, onPatternRemove, onPatternEdit } = this.props;
    //onPatternRemove(row, e);
    console.log(row);
  };

  render() {
    // const { operations, onRemove } =this.props;

    // var rows = [];
    // if (operations) {
    //     operations.forEach(function (operation, index) {
    //         rows.push(<OperationRow key={index} index={index} operation={operation} onRemove={onRemove} />);
    //     });
    // }
    // return (
    //     <table>
    //         <thead>
    //             <tr>
    //                 <th className="col-xs-5">Операция</th>
    //                 <th className="col-xs-5">Цена</th>
    //                 <th className="col-xs-2"></th>
    //             </tr>
    //         </thead>
    //         <tbody>
    //             {rows}
    //         </tbody>
    //     </table>
    // );

    const { operations, onRemove, disabled } = this.props;

    const columns = [
      {
        Header: "Операция",
        accessor: "operation",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Цена",
        accessor: "price",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Удалить",
        maxWidth: 100,
        Cell: row => (
          <div>
            {!disabled ? (
              <Popconfirm
                placement="topRight"
                title="Уверены что хотите удалить эту операцию?"
                onConfirm={e => this.handleConfirm(row, e)}
                onCancel={this.handleCancel}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="danger" size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
            ) : (
              <Button type="danger" size="small" icon={<DeleteOutlined />} disabled />
            )}
          </div>
        ),
      },
    ];

    let pageSize = operations.length == 0 ? 10 : operations.length;

    return (
      <ReactTable
        data={operations}
        columns={columns}
        className=" -highlight"
        minRows={10}
        showPagination={false}
        pageSize={pageSize}
      />
    );
  }
}

export default OperationTable;

class OperationRow extends Component {
  constructor(props) {
    super(props);
  }

  onRemove = (e, operation) => {
    const { onRemove, index } = this.props;
    onRemove(index);
  };

  render() {
    const { operation, index } = this.props;

    return (
      <tr key={index}>
        <td>{operation.operation}</td>
        <td>{operation.price}</td>
        <td>
          <Popconfirm
            placement="topRight"
            title="Уверены что хотите удалить эту операцию?"
            onConfirm={e => this.onRemove(e, operation)}
            onCancel={this.handleCancel}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="danger" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </td>
      </tr>
    );
  }
}

OperationRow.propTypes = {
  onRemove: PropTypes.func.isRequired,
};
