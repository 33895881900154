import { combineReducers } from "redux";

import {
  DEPOTS_FETCH_SUCCESS,
  DEPOT_CREATE_SUCCESS,
  DEPOT_DELETE_SUCCESS,
  DEPOT_FETCH_SUCCESS,
  DEPOT_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case DEPOTS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.depotLocations,
      };
    case DEPOT_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.depotLocations,
      };
    case DEPOT_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.depotLocations,
      };
    case DEPOT_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.depotLocations,
      };
    case DEPOT_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.depotLocationId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case DEPOTS_FETCH_SUCCESS:
      return action.response.result;
    case DEPOT_CREATE_SUCCESS:
      return [...state, action.response.result];
    case DEPOT_DELETE_SUCCESS:
      return [...state.filter(id => id != action.depotLocationId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllDepotLocations = state => {
  return state.data.depotLocations.allIds.map(
    id => state.data.depotLocations.byId[id],
  );
};

export const getDepotLocation = (state, id) => {
  return state.data.depotLocations.byId[id];
};
