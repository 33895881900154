import { combineReducers } from "redux";

import {
  CLIENTS_FETCH_SUCCESS,
  CLIENT_CREATE_SUCCESS,
  CLIENT_DELETE_SUCCESS,
  CLIENT_FETCH_SUCCESS,
  CLIENT_UPDATE_SUCCESS,
} from "./actions";

export const all = (state = [], action) => {
  switch (action.type) {
    case CLIENT_CREATE_SUCCESS: {
      return [...state, action.response];
    }
    case CLIENT_FETCH_SUCCESS: {
      return state.map(client =>
        client.id === action.clientId ? action.response : client,
      );
    }
    case CLIENT_UPDATE_SUCCESS: {
      return state.map(client =>
        client.id === action.clientId ? action.response : client,
      );
    }
    case CLIENTS_FETCH_SUCCESS: {
      return action.response;
    }
    case CLIENT_DELETE_SUCCESS: {
      return state.filter(client => client.id !== action.clientId);
    }
    default:
      return state;
  }
};

export default combineReducers({
  all,
});
