import { Breadcrumb, Button, DatePicker, Layout, Menu, Radio, Select } from "antd";
import axios from "axios";
import { fetchDepotLocations } from "data/depotLocations/actions";
import { getAllDepotLocations } from "data/depotLocations/reducer";
import { fetchPeriods } from "data/periods/actions";
import { getAllPeriods } from "data/periods/reducer";
import { fetchTeams } from "data/teams/actions";
import { getAllTeams } from "data/teams/reducer";
import { serialize } from "helpers/utils";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import StatsByTeamTable from "../StatsByTeamTable";

const Option = Select.Option;

function StatsByTeam(props) {
  // Declare a new state variable, which we'll call "count"
  const { history, fetchPeriods, fetchTeams, teams, periods } = props;

  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [statsByTeam, setStatsByTeam] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchPeriods();

    fetchTeams();

    let queryStr = "";
    if (selectedTeam && selectedPeriod) {
      queryStr = serialize({ teamId: selectedTeam, periodId: selectedPeriod });
    }
    axios
      .get(`/api/production_statistics/byteam?${queryStr}`)
      .then(({ data }) => {
        setLoading(false);
        setStatsByTeam(data);
        return data;
      });
  }, []);

  useEffect(() => {
    handleValueChange();
    // do something when value changes
  }, [selectedTeam, selectedPeriod]);

  const onPRDoubleClick = value => {
    history.push(`/cutting/` + value.PR);
  };

  const handleValueChange = e => {
    let queryStr = "";
    if (selectedTeam && selectedPeriod) {
      queryStr = serialize({ teamId: selectedTeam, periodId: selectedPeriod });
    }
    axios
      .get(`/api/production_statistics/byteam?${queryStr}`)
      .then(({ data }) => {
        let filteredData = data.filter(row => row.PR != null);
        setLoading(false);
        setStatsByTeam(filteredData);
        return filteredData;
      });
  };

  const handleTeamChange = value => {
    setSelectedTeam(value);
    //handleValueChange();
  };
  const handlePeriodChange = value => {
    setSelectedPeriod(value);
    //handleValueChange();
  };

  let teamOptions = teams.map(team => {
    return (
      <Option key={team.id} value={team.id}>
        {team.name}
      </Option>
    );
  });

  let periodOptions = periods.map(period => {
    return (
      <Option key={period.id} value={period.id}>
        {period.name}
      </Option>
    );
  });

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="prodDate">
          Период
        </label>
        &nbsp; &nbsp;
        <Select
          value={selectedPeriod}
          style={{ width: 300 }}
          onChange={handlePeriodChange}
        >
          {periodOptions}
        </Select>
      </div>

      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="prodDate">
          Бригада
        </label>
        &nbsp; &nbsp;
        <Select
          value={selectedTeam}
          style={{ width: 300 }}
          onChange={handleTeamChange}
        >
          {teamOptions}
        </Select>
      </div>

      <StatsByTeamTable
        productionProcessType={
          props.domainSettings.find(setting => setting.id === 1).value
        }
        onEditClick={onPRDoubleClick}
        data={statsByTeam}
        loading={loading}
      />
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    domainSettings: state.domainSettings,
    depotLocations: getAllDepotLocations(state),
    teams: getAllTeams(state),
    periods: getAllPeriods(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
    fetchTeams: () => dispatch(fetchTeams()),
    fetchPeriods: () => dispatch(fetchPeriods()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StatsByTeam),
);

//export default ContractorMain
