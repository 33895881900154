import "./SizeTable.css";

import { InputNumber } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";

class SizeTable extends Component {
  derivedIds = ["Итого"];

  constructor(props) {
    super(props);
    this.state = {
      byId: {},
      allIds: [],
      //lineItems: []
    };
  }

  normalizeAndSetState = initialValues => {
    let byId = {};
    let allIds = [];
    initialValues.forEach(el => {
      byId[el.size] = { quantity: el.quantity, size_id: el.size_id };
      allIds.push(el.size);
    });
    //let oldTotal=this.state.byId["Итого"];

    if (initialValues.length > 0) {
      byId["Итого"] = { quantity: 0 };
      allIds.push("Итого");
    }

    const { onChange } = this.props;

    this.setState(
      {
        byId: this.findAndReplaceTotal(byId, allIds),
        allIds: allIds,
      },
      () => {
        onChange(this.convertToLineItems());
      },
    );
  };

  componentDidMount() {
    const { initialValues } = this.props;
    this.normalizeAndSetState(initialValues);
  }

  componentWillReceiveProps(nextProps) {
    const { initialValues } = nextProps;
    if (this.props.enableReinitialize) {
      if (nextProps.initialValues != this.props.initialValues) {
        this.normalizeAndSetState(initialValues);
      }
    }
  }

  findAndReplaceSize(byId, size, value) {
    let newById = { ...byId };
    newById[size].quantity = value;
    return newById;
  }

  handleQuantityChange = (value, size) => {
    //console.log(value);

    const { onChange } = this.props;
    this.setState(
      (prevState, props) => ({
        byId: this.findAndReplaceSize(prevState.byId, size, value),
      }),
      () => {
        onChange(this.convertToLineItems());
        this.handleBlur();
      },
    );
  };

  convertToLineItems = () => {
    let lineItems = [];
    this.state.allIds.map(id => {
      if (!this.derivedIds.includes(id))
        lineItems.push({
          size: id,
          quantity: this.state.byId[id].quantity,
          size_id: this.state.byId[id].size_id,
        });
    });

    //this.setState({lineItems: lineItems});
    return lineItems;
  };

  handleBlur = () => {
    const { onChange } = this.props;
    this.setState((prevState, props) => ({
      byId: this.findAndReplaceTotal(prevState.byId, prevState.allIds),
    }));
  };

  findAndReplaceTotal = (byId, allIds) => {
    let newById = { ...byId };
    let total = 0;
    for (let i = 0; i < allIds.length - 1; i++) {
      if (Number.isInteger(byId[allIds[i]].quantity)) {
        total += parseInt(byId[allIds[i]].quantity);
      }
    }

    if (newById["Итого"]) {
      newById["Итого"].quantity = total;
    }

    return newById;
  };

  render() {
    const { disabled } = this.props;

    //const row = {};
    var headers = [];
    var cells = [];
    if (this.state.allIds) {
      this.state.allIds.map(id => {
        let value = this.state.byId[id].quantity;
        headers.push(<th key={id}>{id}</th>);
        cells.push(
          <SizeCell
            disabled={disabled}
            key={id}
            size={id}
            value={value}
            onCellChange={this.handleQuantityChange}
            onBlur={this.handleBlur}
          />,
        );
      });
    }

    return (
      <div className={"sizeDiv"}>
        <table id="sizes">
          <thead>
            <tr>{headers}</tr>
          </thead>
          <tbody>
            <tr>{cells}</tr>
          </tbody>
        </table>
      </div>
    );
  }
}

SizeTable.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValues: PropTypes.array.isRequired,
};

export default withRouter(SizeTable);

class SizeCell extends Component {
  handleChange = value => {
    const { onCellChange, size } = this.props;
    onCellChange(value, size);
  };

  handleBlur = value => {
    const { onBlur, size } = this.props;
    onBlur(value, size);
  };

  render() {
    const { value, size, disabled } = this.props;
    return (
      <td key={size}>
        <InputNumber
          disabled={disabled}
          onChange={this.handleChange}
          value={value}
          onBlur={this.handleBlur}
        />
      </td>
    );
  }
}
