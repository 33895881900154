import { combineReducers } from "redux";

import {
  WAGE_ENTRY_CLAIM_SUCCESS,
  WAGE_ENTRY_DELETE_SUCCESS,
  WAGE_ENTRY_UNCLAIM_SUCCESS,
} from "../wages/actions";
import {
  EMPLOYEES_FETCH_SUCCESS,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_FETCH_SUCCESS,
  EMPLOYEE_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.employees,
      };
    case EMPLOYEE_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.employees,
      };
    case EMPLOYEE_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.employees,
      };
    case EMPLOYEE_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.employees,
      };
    case EMPLOYEE_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.employeeId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    case WAGE_ENTRY_CLAIM_SUCCESS:
      const employeeId = action.employeeId;
      const wageId = action.response.result;
      const employee = state[employeeId];

      if (employee) {
        return {
          ...state,
          [employeeId]: {
            ...employee,
            wages: employee.wages.concat(wageId),
          },
        };
      } else {
        return state;
      }

    case WAGE_ENTRY_UNCLAIM_SUCCESS:
      return unclaimWageEntry(state, action);

    case WAGE_ENTRY_DELETE_SUCCESS:
      return deleteWageEntry(state, action);

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case EMPLOYEES_FETCH_SUCCESS:
      return action.response.result;
    case EMPLOYEE_CREATE_SUCCESS:
      return [...state, action.response.result];
    case EMPLOYEE_DELETE_SUCCESS:
      return [...state.filter(id => id != action.employeeId)];
    default:
      return state;
  }
};

function deleteWageEntry(state, action) {
  let employeeId = action.employeeId;
  let wageId = action.wageId;
  let employee = state[employeeId];
  return {
    ...state,
    [employeeId]: {
      ...employee,
      wages: employee.wages.filter(id => id != wageId),
    },
  };
}

function unclaimWageEntry(state, action) {
  let employeeId = action.employeeId;
  let wageId = action.wageId;
  let employee = state[employeeId];

  if (employee) {
    return {
      ...state,
      [employeeId]: {
        ...employee,
        wages: employee.wages.filter(id => id != wageId),
      },
    };
  } else {
    return state;
  }
}

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllEmployees = state => {
  return state.data.employees.allIds.map(id => state.data.employees.byId[id]);
};

export const getAllEmployeesWithRowNum = state => {
  //let employees=[];

  return state.data.employees.allIds.map((id, index) => {
    return { row: index + 1, ...state.data.employees.byId[id] };
  });
};

export const getEmployee = (state, id) => {
  return state.data.employees.byId[id];
};
