import { notification } from "antd";
import axios from "axios";
import { serialize } from "helpers/utils";
import { normalize } from "normalizr";

export const SHIPMENT_REPORT_FETCH_SUCCESS = "SHIPMENT_REPORT_FETCH_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchShipments = (fromDate, toDate, clientId) => {
  return dispatch => {
    return axios
      .get(
        `/api/shipmentReport?${serialize({
          fromDate,
          toDate,
          clientId
        })}`,
      )
      .then(({ data }) => {
        dispatch({
          type: SHIPMENT_REPORT_FETCH_SUCCESS,
          response: data,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
