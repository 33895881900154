import { Breadcrumb, Button, Card, Layout, Menu, Modal, Popconfirm } from "antd";
import { InputNumber } from "antd";
import { Input, notification } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import {
  createSplitEntry,
  fetchSplitEntries,
} from "../../data/splitEntries/actions";
import { fetchWage } from "../../data/wages/actions";
import {
  checkAvailable,
  decodeStatus,
  generateCuttingData,
  generateProdEntryData,
  getDefaultPrinter,
  readPrinterStatus,
  sendToPrinter,
} from "../printHelper";
import { setOpenedSplitEntryId, setShowSplitModal } from "./actions";
import SplitDetail from "./components/SplitDetail";
import SplitTable from "./components/SplitTable";
import WageEntry from "./components/WageEntry";
import { getAllSplitEntries } from "./selectors";

//import TeamTable from './components/TeamTable'

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

class SplitMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      barCode: "",
      fetchedWage: undefined,
      splitValue: 2,
      entryValues: {},
      isSaving: false,
    };
  }

  componentDidMount() {
    const { fetchSplitEntries } = this.props;
    //  fetchTeams();

    this.setDefaultEntryValues();

    fetchSplitEntries();
  }

  setDefaultEntryValues = () => {
    let val = {};

    for (let i = 0; i < this.state.splitValue; i++) {
      if (this.state.entryValues[i] == undefined) {
        val[i] = 0;
      }
    }
    this.setState((prevState, props) => {
      let newState = { entryValues: { ...prevState.entryValues, ...val } };
      return newState;
    });
  };

  handlBarCodeChange = value => {
    this.setState({ barCode: value.target.value });
  };

  handleKeyPress = e => {
    const { fetchWage } = this.props;
    if (e.key === "Enter") {
      //console.log('do validate');
      //console.log("blahblahblah",this.state.barCode)
      let barCode = this.state.barCode;
      let wageId = undefined;
      let badFormat = 0;

      if (barCode.length < 2) {
        badFormat = 1;
      } else {
        if (barCode[0] === "0") {
          if (barCode[1] === "1") wageId = barCode.slice(2);
          else badFormat = 1;
        } else {
          badFormat = 1;
        }
      }

      if (!badFormat) {
        if (Number.isInteger(parseInt(wageId, 10))) {
          fetchWage(wageId).then(wage => {
            if (!wage.isClaimed) {
              this.setState({ fetchedWage: wage });
            } else {
              openNotificationWithIcon(
                "Ошибка",
                "Данный штрих код уже просканирован",
                "error",
              );
            }
          });
        } else {
          openNotificationWithIcon("Ошибка", "Неправильный штрих код", "error");
        }
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Неверный формат штрих кода",
          "error",
        );
      }

      setTimeout(e => {
        this.setState({ barCode: "" });
      }, 200);
    }
  };
  handleWageRemove = value => {
    const { deleteWageEntry } = this.props;
    deleteWageEntry(this.state.selectedEmployee.id, value);
  };
  handleEditClick = value => {
    const { setOpenedSplitEntryId, setShowSplitModal } = this.props;

    setOpenedSplitEntryId(value.id);
    setShowSplitModal(true);
  };

  onSplitValueChange = value => {
    let val = {};
    for (let i = 0; i < value; i++) {
      if (this.state.entryValues[i] == undefined) {
        val[i] = 0;
      }
    }

    this.setState({ splitValue: value });
    this.setState((prevState, props) => {
      let newState = { entryValues: { ...prevState.entryValues, ...val } };
      return newState;
    });
  };

  onEntryValueChange = (value, i) => {
    // this.setState({ splitValue: value})

    let val = { ...this.state.entryValues, [i]: value };
    this.setState({ entryValues: val });
  };

  handleSplitClick = () => {
    this.setState({ isSaving: true });
    const { createSplitEntry } = this.props;
    let splitEntry = {
      wage_id: this.state.fetchedWage.id,
      sum: this.state.fetchedWage.sum,
      quantity: this.state.fetchedWage.quantity,
    };

    let splits = [];
    for (let i = 0; i < this.state.splitValue; i++) {
      splits.push({ quantity: this.state.entryValues[i] });
    }

    splitEntry["splits"] = splits;
    createSplitEntry(splitEntry).then(
      entry => {
        this.setState({ isSaving: false });
        this.printLabelsForEntry(entry);
      },
      error => {
        this.setState({ isSaving: false });
      },
    );
  };

  printLabelsForEntry(splitEntry) {
    const { user } = this.props;
    getDefaultPrinter()
      .then(json => {
        //check if the default printer is available for printing
        return checkAvailable().then(available => {
          let foundPrinter = undefined;
          if (available.printer && available.printer.length > 0) {
            //return json
            available.printer.forEach(availablePrinter => {
              if (availablePrinter.name == json.name) {
                foundPrinter = availablePrinter;
              }
            });
          }
          if (foundPrinter == undefined) {
            throw "Принтер не подключен";
          } else {
            //send read printer status request
            return sendToPrinter(json, "~HQES").then(data => {
              return json;
            });
          }
        });
      })
      .then(printer => {
        //check if the printer is ready to print
        return readPrinterStatus(printer)
          .then(text => {
            let status = decodeStatus(text);
            if (status == "Ready to Print") {
              let promises = [];
              splitEntry.wages.forEach(wage => {
                let data = "";
                if (splitEntry.operationType_id == 1) {
                  data = generateCuttingData(
                    "01" + wage.id,
                    wage.prodOperation.operation,
                    wage.quantity,
                    wage.prodOperation.operationType.type,
                    wage.sum,
                    "PR-" + wage.prodOperation.prodBom.order_id.toString(),
                    "Разбит",
                    user ? user.domain : "",
                  );
                } else {
                  let sizeName = "";
                  if (wage.prodEntry && wage.prodEntry.size)
                    sizeName = wage.prodEntry.size.name;
                  data = generateProdEntryData(
                    "01" + wage.id,
                    wage.prodOperation.operation,
                    wage.quantity,
                    wage.prodOperation.operationType.type,
                    wage.sum,
                    sizeName,
                    "PR-" + wage.prodOperation.prodBom.order_id.toString(),
                    "Разбит",
                    user ? user.domain : "",
                  );
                }

                let promise = sendToPrinter(printer, data);
                promises.push(promise);
              });
              return Promise.all(promises).then(res => {
                this.close();
              });
            } else {
              throw status;
            }
          })
          .catch(error => {
            this.showConfirm(splitEntry, error);
          });
      })
      .catch(error => {
        this.showConfirm(splitEntry, error);
      });
  }

  close = () => {
    this.setState({ fetchedWage: undefined });
    this.setState({ entryValues: {} }, () => {
      this.setDefaultEntryValues();
    });
  };

  showConfirm(splitEntry, message) {
    let self = this;
    Modal.confirm({
      title: "Попробовать напечатать заного?",
      content: message,
      onOk() {
        self.printLabelsForEntry(splitEntry);
      },
      onCancel() {
        self.close();
      },
    });
  }

  render() {
    const { splitEntries } = this.props;

    let splitEntryForms = [];
    for (let i = 0; i < this.state.splitValue; i++) {
      //let current=this.
      splitEntryForms.push(
        <div className="form-group" key={i}>
          <label>Количество</label>&nbsp;&nbsp;
          <InputNumber
            defaultValue={0}
            value={this.state.entryValues[i]}
            onChange={e => this.onEntryValueChange(e, i)}
          />
        </div>,
      );
    }

    return (
      <div>
        <h2>Разделить штрих код</h2>

        <div style={{ marginBottom: 15 }}>
          <label className="mt-label" htmlFor="article">
            Штрих код
          </label>

          <Input
            value={this.state.barCode}
            className="form-control"
            onChange={this.handlBarCodeChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>

        {this.state.fetchedWage != undefined && (
          <Card>
            <WageEntry wage={this.state.fetchedWage} />

            <div className="form-group">
              <label>Разбить на </label>&nbsp;&nbsp;
              <InputNumber
                min={1}
                max={10}
                defaultValue={2}
                value={this.state.splitValue}
                onChange={this.onSplitValueChange}
              />
            </div>
            <Card>
              {splitEntryForms}
              <Button
                onClick={this.handleSplitClick}
                type="primary"
                loading={this.state.isSaving}
              >
                Разбить и распечатать
              </Button>
            </Card>
          </Card>
        )}

        <br />

        <Card>
          <SplitTable
            splitEntries={splitEntries}
            onEditClick={this.handleEditClick}
          />
        </Card>

        <SplitDetail
          printLabelsForEntry={this.printLabelsForEntry}
          showConfirm={this.showConfirm}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const domainSettings = state.domainSettings;
  console.log(domainSettings);
  return {
    // You can now say this.props.books
    // wages: getWagesForSelectedEmployee(state),
    // total: getTotalForSelectedEmployee(state)
    splitEntries: getAllSplitEntries(state),
    user: state.data.users.user.user,
    productionProcessType: domainSettings
      ? domainSettings.find(setting => setting.id === 1).value
      : null,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    // createWageClaim: (employeeId,wage)=>dispatch(createWageClaim(employeeId,wage)),
    // deleteWageEntry: (employeeId,wage)=>dispatch(deleteWageEntry(employeeId,wage)),
    fetchSplitEntries: () => dispatch(fetchSplitEntries()),
    fetchWage: id => dispatch(fetchWage(id)),
    createSplitEntry: entry => dispatch(createSplitEntry(entry)),
    setOpenedSplitEntryId: id => dispatch(setOpenedSplitEntryId(id)),
    setShowSplitModal: showModal => dispatch(setShowSplitModal(showModal)),

    // //fetchEmployee: (employeeId)=>dispatch(fetchEmployee(employeeId)),
    // setSelectedEmployeeId: (employeeId)=>dispatch(setSelectedEmployeeId(employeeId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SplitMain),
);

//export default ContractorMain
