import { combineReducers } from "redux";

const openedProdDepotLocationId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_PROD_DEPOT_LOCATION_ID":
      return action.prodDepotLocationId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedProdDepotLocationId: openedProdDepotLocationId,
});
