import { Input } from "antd";
import React from "react";

import { FormField } from "./FormField";

export function FormInput({ name, label, disabled, ...props }) {
  return (
    <FormField
      name={name}
      label={label}
      render={({ field, form: { isSubmitting } }) => (
        <Input {...props} {...field} disabled={disabled || isSubmitting} />
      )}
    />
  );
}
