//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, InputNumber, Table } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import Select from "components/Select";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { getAllDepotLocations } from "../../../../../data/depotLocations/reducer";
import { setShowSearchFabricModal } from "../../../actions";
import { getOpenedCuttingOrder } from "../../../selectors";

const Option = Select.Option;
const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "800px",
        minHeight: "200px",
      },
      root: {
        //alignItems: "initial"
        zIndex: 1000,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class SearchFabricModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFabric: null,
      depotLocations: [],
      selectedDepotLocationId: null,
      weight: 0,
      lineItems: this.props.openedOrder.lineItems.map(lineItem => ({
        sizeId: lineItem.size.id,
        sizeName: lineItem.size.name,
        quantity: 0,
      })),
    };
  }
  close = () => {
    this.props.setShowSearchFabricModal(false);
  };

  handleUpdate = () => {
    const {
      selectFabric,
      selectWeight,
      selectDepotLocation,
      searchFabricIndices,
      selectLineItems,
    } = this.props;
    const {
      prodBomIndex,
      prodSpreadIndex,
      consumptionIndex,
    } = searchFabricIndices;

    selectFabric(
      this.state.selectedFabric,
      prodBomIndex,
      prodSpreadIndex,
      consumptionIndex,
    );
    selectWeight(
      this.state.weight,
      prodBomIndex,
      prodSpreadIndex,
      consumptionIndex,
    );
    selectDepotLocation(
      this.state.selectedDepotLocation,
      prodBomIndex,
      prodSpreadIndex,
      consumptionIndex,
    );
    selectLineItems(
      this.state.lineItems,
      prodBomIndex,
      prodSpreadIndex,
      consumptionIndex,
    );
    this.close();
  };

  componentDidUpdate(prevProps) {
    if (this.props.showModal !== prevProps.showModal) {
      this.setState({
        depotLocations: [],
        selectedFabric: null,
        selectedDepotLocation: null,
        weight: 0,
        lineItems: this.props.openedOrder.lineItems.map(lineItem => ({
          sizeId: lineItem.size.id,
          sizeName: lineItem.size.name,
          quantity: 0,
        })),
      });
    }
  }

  componentWillReceiveProps(nextProps) {}

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  getFabricOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  handleFabricSelect = fabric => {
    this.setState({ selectedFabric: fabric });
    if (fabric) {
      this.fetchDepotLocationsWithStock(fabric.id).then(data =>
        this.setState({ depotLocations: data }),
      );
    }
  };

  handleWeightChange = weight => {
    this.setState({ weight });
  };

  handleDepotLocationChange = depotLocation => {
    this.setState({ selectedDepotLocation: depotLocation });
  };

  fetchDepotLocationsWithStock = fabricId => {
    return axios(`/api/depotlocations/searchwithstock?q=${fabricId}`).then(
      ({ data }) => {
        return data;
      },
    );
  };

  render() {
    const styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
      },
    };

    const { showModal, openedPattern } = this.props;
    const { fullScreen, classes } = this.props;

    let depotLocationOptions = this.state.depotLocations.map(depot => {
      let weight = 0;
      if (depot.totalWeight) {
        weight = depot.totalWeight;
        if (this.state.selectedFabric) {
          weight -= this.props.fabricConsumptions.reduce(
            (prodBomAcc, prodBom, prodBomIndex) => {
              return (
                prodBomAcc +
                prodBom.reduce((prodBomAcc, prodSpread, prodSpreadIndex) => {
                  return (
                    prodBomAcc +
                    prodSpread.reduce(
                      (prodSpreadAcc, fabricConsumption, consumptionIndex) => {
                        if (
                          fabricConsumption.fabric &&
                          fabricConsumption.depotLocation &&
                          fabricConsumption.fabric.id ===
                            this.state.selectedFabric.id &&
                          fabricConsumption.depotLocation.id === depot.id &&
                          !fabricConsumption.id
                        ) {
                          return prodSpreadAcc + fabricConsumption.weight;
                        } else return prodSpreadAcc;
                      },
                      0,
                    )
                  );
                }, 0)
              );
            },
            0,
          );
          if (this.props.fabricConsumptionsFromDatabase) {
            const involvedFabricConsumptionIdsFromDatabase = [];
            for (const prodBom of this.props.fabricConsumptions) {
              for (const prodSpread of prodBom) {
                for (const fabricConsumptionFromForm of prodSpread) {
                  if (fabricConsumptionFromForm.id) {
                    involvedFabricConsumptionIdsFromDatabase.push(
                      fabricConsumptionFromForm.id,
                    );
                  }
                }
              }
            }
            weight += this.props.fabricConsumptionsFromDatabase
              .filter(
                fabricConsumptionFromDatabase =>
                  !involvedFabricConsumptionIdsFromDatabase.includes(
                    fabricConsumptionFromDatabase.id,
                  ),
              )
              .reduce(
                (acc, fabricConsumption) => acc + fabricConsumption.weight,
                0,
              );
          }
        }
      }

      return {
        label: depot.name + ", остаток: " + weight,
        value: depot.id,
        ...depot,
        weight: weight,
      };
    });
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Указать материал
            </DialogTitle>
            <DialogContent>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="employee">
                  Материал
                </label>
                <AsyncSelect
                  menuContainerStyle={{ zIndex: 99999 }}
                  name="form-field-name"
                  value={this.state.selectedFabric}
                  loadOptions={this.getFabricOptions}
                  onChange={this.handleFabricSelect}
                  styles={customStyles}
                  noOptionsMessage={() => {
                    return "Печатайте для поиска...";
                  }}
                  isClearable
                  menuPortalTarget={document.body}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="employee">
                  Вес
                </label>
                <br />
                <InputNumber
                  precision={2}
                  style={{ width: "100%" }}
                  value={this.state.weight}
                  onChange={this.handleWeightChange}
                  bordered={false}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="employee">
                  Склад
                </label>
                <br />
                <Select
                  id="depotLocation"
                  name="depotLocation"
                  value={this.state.selectedDepotLocation}
                  options={depotLocationOptions}
                  onChange={this.handleDepotLocationChange}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  noOptionsMessage={() => {
                    return "Сначала выберите материал";
                  }}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="employee">
                  Количество по размерам:
                </label>
                <Table
                  bordered={true}
                  pagination={false}
                  columns={this.props.openedOrder.lineItems.map(
                    (lineItem, lineItemIndex) => ({
                      title: lineItem.size.name,
                      key: "sizeName" + lineItemIndex,
                      align: "center",
                      width: 70,
                      render: (value, record, index) => {
                        return (
                          <InputNumber
                            precision={0}
                            className={"cleanInputNumber"}
                            value={
                              record.lineItems.find(
                                item => item.sizeId === lineItem.size.id,
                              ).quantity
                            }
                            min={0}
                            onChange={e => {
                              this.setState({
                                lineItems: this.state.lineItems.map(
                                  newLineItem => {
                                    if (
                                      newLineItem.sizeId === lineItem.size.id
                                    ) {
                                      return {
                                        ...newLineItem,
                                        quantity: e,
                                      };
                                    } else return newLineItem;
                                  },
                                ),
                              });
                            }}
                          />
                        );
                      },
                    }),
                  )}
                  dataSource={[{ key: 1, lineItems: this.state.lineItems }]}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={
                  !this.state.selectedFabric ||
                  !(this.state.weight >= 0) ||
                  !this.state.selectedDepotLocation ||
                  !(
                    this.state.lineItems.reduce(
                      (acc, curr) => acc + curr.quantity,
                      0,
                    ) > 0
                  )
                }
                type="primary"
                onClick={this.handleUpdate}
              >
                Сохранить
              </Button>

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

SearchFabricModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    depotLocations: getAllDepotLocations(state),
    showModal: state.scenes.cutting.showSearchFabricModal,
    searchFabricIndices: state.scenes.cutting.searchFabricIndices,
    openedOrder: getOpenedCuttingOrder(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowSearchFabricModal: showModal =>
      dispatch(setShowSearchFabricModal(showModal)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchFabricModal),
);
