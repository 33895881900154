import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";
import { WAGE_ENTRIES_FETCH_SUCCESS } from "../wages/actions";

//import {addNotification} from '../../actions'

export const PROD_ENTRY_CREATE_SUCCESS = "PROD_ENTRY_CREATE_SUCCESS";
export const PROD_ENTRY_UPDATE_SUCCESS = "PROD_ENTRY_UPDATE_SUCCESS";
export const PROD_ENTRY_DELETE_SUCCESS = "PROD_ENTRY_DELETE_SUCCESS";

export const PROD_ENTRIES_FETCH_SUCCESS = "PROD_ENTRIES_FETCH_SUCCESS";
export const PROD_ENTRY_FETCH_SUCCESS = "PROD_ENTRY_FETCH_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const createProdEntry = (orderId, prodEntry) => {
  return dispatch => {
    return axios
      .post("/api/prod_entry/" + orderId, prodEntry)
      .then(({ data }) => {
        let addedEntry = data;
        let prodBomId = addedEntry.prodBom_id;
        //dispatch({type: 'PATTERN_ADD_SUCCESS', spreads});
        dispatch({
          type: PROD_ENTRY_CREATE_SUCCESS,
          response: normalize(addedEntry, schema.prodEntry),
          prodBomId,
        });

        dispatch({
          type: WAGE_ENTRIES_FETCH_SUCCESS,
          response: normalize(addedEntry, schema.prodEntry),
          prodBomId,
        });

        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateProdEntry = (orderId, prodEntry) => {
  return dispatch => {
    return axios
      .put("/api/prod_entry/" + orderId, prodEntry)
      .then(({ data }) => {
        let updatedEntry = data;
        let prodBomId = updatedEntry.prodBom_id;
        //dispatch({type: 'PATTERN_ADD_SUCCESS', spreads});
        dispatch({
          type: PROD_ENTRY_UPDATE_SUCCESS,
          response: normalize(updatedEntry, schema.prodEntry),
        });
        dispatch({
          type: WAGE_ENTRIES_FETCH_SUCCESS,
          response: normalize(updatedEntry, schema.prodEntry),
          prodBomId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно обновлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteProdEntry = entry => {
  return dispatch => {
    return axios
      .delete("/api/prod_entry/" + entry.id, { idToDelete: entry.id })
      .then(({ data }) => {
        //let spreads=json;
        let prodEntryId = entry.id;
        let orderId = entry.order_id;

        let prodBomId = entry.prodBom_id;
        dispatch({ type: PROD_ENTRY_DELETE_SUCCESS, prodEntryId, prodBomId });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
