import AttendanceReportType from "@app/shared/constants/AttendanceReportType";
import React from "react";
import { Route, Switch } from "react-router-dom";

import AttendanceReportByDateRange from "./components/AttendanceReportByDateRange";
import AttendanceReportByDateRangeAdd from "./components/AttendanceReportByDateRangeAdd";
import Main from "./index";

export default function AttendanceReportRoute({ match: { url } }) {
  return (
    <Switch>
      <Route exact path={url} component={Main} />
      <Route
        exact
        path={`${url}/add/:type?`}
        component={AttendanceReportByDateRangeAdd}
      />
      <Route
        exact
        component={AttendanceReportByDateRange}
        path={`${url}/${AttendanceReportType.AttendanceReportByDateRange}/:id`}
      />
    </Switch>
  );
}
