import { SHIPMENT_REPORT_FETCH_SUCCESS } from "./actions";

const shipmentReport = (state = [], action) => {
  switch (action.type) {
    case SHIPMENT_REPORT_FETCH_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

export const reducer = shipmentReport;
