import { combineReducers } from "redux";

import {
  FABSTOCKS_FETCH_SUCCESS,
  FABSTOCK_CREATE_SUCCESS,
  FABSTOCK_DELETE_SUCCESS,
  FABSTOCK_FETCH_SUCCESS,
  FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS,
  FABSTOCK_UPDATE_SUCCESS,
} from "./actions";

//import { WAGE_ENTRY_CLAIM_SUCCESS, WAGE_ENTRY_UNCLAIM_SUCCESS, WAGE_ENTRY_DELETE_SUCCESS } from '../wages/actions'

const byId = (state = {}, action) => {
  switch (action.type) {
    case FABSTOCKS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStocks,
      };
    case FABSTOCK_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStocks,
      };
    case FABSTOCK_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStocks,
      };
    case FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStocks,
      };
    case FABSTOCK_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStocks,
      };
    case FABSTOCK_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.fabStockId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FABSTOCKS_FETCH_SUCCESS:
      return action.response.result;
    case FABSTOCK_CREATE_SUCCESS:
      return [...state, action.response.result];
    case FABSTOCK_MOVEMENT_REQUEST_APPROVE_SUCCESS:
      return [...state, action.response.result];
    case FABSTOCK_DELETE_SUCCESS:
      return [...state.filter(id => id != action.fabStockId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllFabStocks = state => {
  return state.data.fabStocks.allIds.map(id => state.data.fabStocks.byId[id]);
};

export const getFabStock = (state, id) => {
  return state.data.fabStocks.byId[id];
};
