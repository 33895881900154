import LogType from "@app/shared/constants/logType";
import { Button, Popconfirm, Select } from "antd";
import matchSorter from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import orderStatusLabels from "@app/shared/constants/orderStatusLabels";
import * as changeLogConstructor from "./changelogConstructor";

class LogTable extends Component {
  render() {
    const {
      logs,
      loading,
      history,
      setPageIndex,
      pagesCount,
      pageIndex,
    } = this.props;

    moment.locale("ru");

    const createTextOfLog = (notificationType_id, logObject) => {
      switch (notificationType_id) {
        case LogType.NEW_PRODUCT: {
          const { initiator, product } = logObject;

          return `Пользователь ${initiator.first_name} ${
              initiator.last_name
          } [${initiator.username}] добавил новую модель. 
          Название - ${product.name}
          Размеры - ${product.sizes.map(size => size.name)}
          Рассчётная цена - ${product.estimatedPrice} $
          id спецификации - ${product.spec_id}
          `
        }
        case LogType.UPDATED_PRODUCT: {
          const { initiator } = logObject;
          const changeLog = changeLogConstructor.createProductChangeLog(
              logObject,
          );
          return `Пользователь ${initiator.first_name} ${
              initiator.last_name
          } [${initiator.username}] изменил продукт.
          Список изменений:
          ${changeLog}
          `;
        }
        case LogType.DELETED_PRODUCT: {
          const { initiator, product } = logObject

          return `Пользователь ${initiator.first_name} ${
              initiator.last_name
          } [${initiator.username}] удалил модель. 
            Название - ${product.name}
            Размеры - ${product.sizes.map(size => size.name)}
            Рассчётная цена - ${product.estimatedPrice} $
            id спецификации - ${product.spec_id}
            `
        }
        case LogType.UPDATED_SHIPMENT_ENTRY: {
          const { initiator, oldShipment } = logObject;
          const changeLog = changeLogConstructor.createShipmentChangeLog(
            logObject,
          );
          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] изменил запись отгрузки [SHP-${
            oldShipment.id
          }].
          ${changeLog === "" ? `Нет изменений` : `${changeLog}`}
          `;
        }
        case LogType.DELETED_SHIPMENT_ENTRY: {
          const { initiator, deletedShipment, totalPrice, client } = logObject;

          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] удалил запись отгрузки [SHP-${
            deletedShipment.id
          }] на сумму ${totalPrice}$. 
          Клиент - ${client.name} [CL-${client.id}]
          `;
        }
        case LogType.DELETED_PAYMENT_ENTRY: {
          const { initiator, deletedPayment, client } = logObject;

          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] удалил запись платежа клиента "${
            client.name
          }" [CL-${client.id}].
          Сумма платежа - ${deletedPayment.amount}$.
          Дата платежа -  ${moment(deletedPayment.transferred_at).format(
            "DD/MM/YYYY",
          )}.
          `;
        }
        case LogType.DELETED_CONTRACTOR_PAYMENT_ENTRY: {
          const { initiator, deletedContractorPayment, contractor } = logObject;

          return `Пользователь ${initiator.first_name} ${
              initiator.last_name
          } [${initiator.username}] удалил запись платежа подрядчику "${
              contractor.company_name
          }" [CNTR-${contractor.id}].
          Сумма платежа - ${deletedContractorPayment.amount}$.
          Дата платежа -  ${moment(deletedContractorPayment.transferred_at).format(
              "DD/MM/YYYY",
          )}.
          `;
        }
        case LogType.NEW_SHIPMENT_ENTRY: {
          const { initiator, shipment, client, totalPrice } = logObject;
          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] создал новую запись отгрузки [SHP-${
            shipment.id
          }] на сумму ${totalPrice}$. 
          Клиент - ${client.name} [CL-${client.id}]`;
        }
        case LogType.NEW_PAYMENT_ENTRY: {
          const { initiator, payment, client } = logObject;
          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] создал новую запись платежа клиента "${
            client.name
          }".
          Сумма платежа - ${payment.amount}$.
          Дата платежа - ${moment(payment.tranferred_at).format("DD/MM/YYYY")}.
          `;
        }
        case LogType.NEW_ORDER_ENTRY: {
          const { initiator, order, product } = logObject;
          return `Пользователь ${initiator.first_name} ${
              initiator.last_name
          } [${initiator.username}] создал новую запись заказа "${
              order.name
          }".
          Дата заказа - ${moment(order.start_date, "YYYY-MM-DD").format("DD/MM/YYYY")}.
          Продукт - ${product.name} 
          `;
        }
        case LogType.UPDATED_ORDER_STATUS: {
          const {
            initiator,
            order_id,
            oldStatus_id,
            newStatus_id
          } = logObject;

          return (
              `Пользователь ${initiator.first_name} ${initiator.last_name} [${
                  initiator.username
              }] изменил статус заказа [PR-${order_id}].
              "${orderStatusLabels[oldStatus_id]}" \u279E "${orderStatusLabels[newStatus_id]}"`
          );
        }
        case LogType.DELETED_ORDER_ENTRY: {
          const {
            initiator,
            order,
            product,
          } = logObject;

          return `Пользователь ${initiator.first_name} ${
              initiator.last_name
          } [${initiator.username}] удалил запись заказа "${
              order.name
          }" [PR-${order.id}].
          Продукт - ${product.name}`
        }
        case LogType.NEW_CONTRACTOR_PAYMENT_ENTRY: {
          const { initiator, contractorPayment, contractor } = logObject;
          return `Пользователь ${initiator.first_name} ${
              initiator.last_name
          } [${initiator.username}] создал новую запись платежа подрядчику "${
              contractor.company_name
          }".
          Сумма платежа - ${contractorPayment.amount}$.
          Дата платежа - ${moment(contractorPayment.tranferred_at).format("DD/MM/YYYY")}.
          `;
        }
        case LogType.UPDATED_PAYMENT_ENTRY: {
          const { initiator, oldPaymentEntry } = logObject;
          return (
            `Пользователь ${initiator.first_name} ${initiator.last_name} [${
              initiator.username
            }] изменил запись платежа [PM-${oldPaymentEntry.id}].
          ` + changeLogConstructor.createPaymentChangeLog(logObject)
          );
        }
        case LogType.UPDATED_CONTRACTOR_PAYMENT_ENTRY: {
          const { initiator, oldContractorPaymentEntry } = logObject;
          return (
              `Пользователь ${initiator.first_name} ${initiator.last_name} [${
                  initiator.username
              }] изменил запись платежа [CPM-${oldContractorPaymentEntry.id}].
          ` + changeLogConstructor.createContractorPaymentChangeLog(logObject)
          );
        }
        case LogType.NEW_FABSTOCK_MOVEMENT_REQUEST: {
          const {
            initiator,
            fabric,
            fabStockMovementRequest,
            fromDepotLocation,
            toDepotLocation,
          } = logObject;
          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] создал запрос перемещения материала "${
            fabric.name
          }" [${fabStockMovementRequest.weight} кг] из склада "${
            fromDepotLocation.name
          }" в склад "${toDepotLocation.name}"`;
        }
        case LogType.APPROVED_FABSTOCK_MOVEMENT_REQUEST: {
          const {
            toDepotLocation,
            fabric,
            fabStockMovementRequest,
            fromDepotLocation,
          } = logObject;
          return `Заведующий складом "${toDepotLocation.name}" ${
            toDepotLocation.user.first_name
          } ${toDepotLocation.user.last_name} [${
            toDepotLocation.user.username
          }] одобрил перемещение материала "${fabric.name}" [${
            fabStockMovementRequest.weight
          } кг] из склада "${fromDepotLocation.name}"`;
        }
        case LogType.DECLINED_FABSTOCK_MOVEMENT_REQUEST: {
          const {
            toDepotLocation,
            fabric,
            fabStockMovementRequest,
            fromDepotLocation,
          } = logObject;
          return `Заведующий складом "${toDepotLocation.name}" ${
            toDepotLocation.user.first_name
          } ${toDepotLocation.user.last_name} [${
            toDepotLocation.user.username
          }] отклонил перемещение материала "${fabric.name}" [${
            fabStockMovementRequest.weight
          } кг] из склада "${fromDepotLocation.name}"`;
        }
        case LogType.NEW_FAB_DEPOT_ENTRY: {
          const { initiator, fabDepotEntry, depotLocation, fabric } = logObject;
          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] создал запись прихода материала "${
            fabric.name
          }" [${fabDepotEntry.weight} кг, ${
            fabDepotEntry.rolls
          } рул.] в склад "${depotLocation.name}". Дата прихода: ${
            fabDepotEntry.entry_date
          }`;
        }
        case LogType.NEW_PROD_DEPOT_ENTRY: {
          const {
            initiator,
            prodDepotLocation,
            product,
            newProdDepotEntry,
          } = logObject;
          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] создал запись прихода продукции "${
            product.name
          }" [${newProdDepotEntry.quantity} шт] в склад "${
            prodDepotLocation.name
          }". Дата прихода: ${newProdDepotEntry.entry_date}
          `;
        }
        case LogType.NEW_PRODSTOCK_MOVEMENT: {
          const {
            initiator,
            product,
            fromProdDepotLocation,
            toProdDepotLocation,
            newProdStockMovement,
          } = logObject;

          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] добавил запись перемещения продукции "${
            product.name
          }" [${newProdStockMovement.quantity} кг] из склада "${
            fromProdDepotLocation.name
          }" в склад "${toProdDepotLocation.name}"`;
        }
        case LogType.UPDATED_FAB_DEPOT_ENTRY: {
          const { initiator } = logObject;

          return (
            `Пользователь ${initiator.first_name} ${initiator.last_name} [${
              initiator.username
            }] изменил запись прихода материала.
          ` + changeLogConstructor.createFabDepotEntryChangeLog(logObject)
          );
        }
        case LogType.UPDATED_FABSTOCK_MOVEMENT_REQUEST: {
          const { initiator } = logObject;

          return (
            `Пользователь ${initiator.first_name} ${initiator.last_name} [${
              initiator.username
            }] изменил запись запроса перемещения материала.
          ` +
            changeLogConstructor.createFabStockMovementRequestChangeLog(
              logObject,
            )
          );
        }
        case LogType.DELETED_FAB_DEPOT_ENTRY: {
          const {
            initiator,
            deletedFabDepotEntry,
            fabric,
            depotLocation,
          } = logObject;

          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] удалил запись прихода материала "${
            fabric.name
          }" [${deletedFabDepotEntry.weight} кг, ${
            deletedFabDepotEntry.rolls
          } рул.] в склад "${depotLocation.name}". Дата прихода: ${moment(
            deletedFabDepotEntry.entry_date,
          ).format("LL")}`;
        }
        case LogType.UPDATED_PROD_DEPOT_ENTRY: {
          const { initiator } = logObject;

          return (
            `Пользователь ${initiator.first_name} ${initiator.last_name} [${
              initiator.username
            }] изменил запись прихода продукции.
          ` + changeLogConstructor.createProdDepotEntryChangeLog(logObject)
          );
        }
        case LogType.DELETED_PROD_DEPOT_ENTRY: {
          const {
            initiator,
            product,
            deletedProdDepotEntry,
            prodDepotLocation,
          } = logObject;

          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] удалил запись прихода продукции "${
            product.name
          }" [${deletedProdDepotEntry.quantity} шт] в склад "${
            prodDepotLocation.name
          }". Дата прихода: ${moment(deletedProdDepotEntry.entry_date).format(
            "LL",
          )}`;
        }
        case LogType.NEW_FABSTOCK_REVISION: {
          const {
            initiator,
            fabric,
            fabStockRevision,
            depotLocation,
          } = logObject;

          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] добавил запись ревизии материала "${
            fabric.name
          }" [Фактический вес: ${fabStockRevision.weight} кг] в складе "${
            depotLocation.name
          }"`;
        }
        case LogType.NEW_PRODSTOCK_REVISION: {
          const {
            initiator,
            product,
            prodStockRevision,
            prodDepotLocation,
          } = logObject;

          return `Пользователь ${initiator.first_name} ${
            initiator.last_name
          } [${initiator.username}] добавил запись ревизии продукции "${
            product.name
          }" [Фактическое количество: ${
            prodStockRevision.quantity
          } шт] в складе "${prodDepotLocation.name}"`;
        }
        default:
          return ``;
      }
    };

    const columns = [
      {
        accessor: "logObject",
        filterable: false,
        Cell: row => (
          <span style={{ whiteSpace: "pre-line" }}>
            {createTextOfLog(row.original.logType_id, JSON.parse(row.value))}
          </span>
        ),
      },
      {
        accessor: "created_at",
        style: { textAlign: "center" },
        width: 200,
        filterable: false,
        Cell: row => moment.utc(row.value).format("LLL"),
      },
    ];

    return (
      <div>
        <ReactTable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          className="-striped -highlight"
          data={logs}
          getTdProps={(state, rowInfo, column) => {
            if (loading) {
              return {
                className: "pt-skeleton",
              };
            } else {
              return {};
            }
          }}
          sortable={false}
          columns={columns}
          pageSize={50}
          showPageSizeOptions={false}
          previousText="Пред"
          manual
          page={pageIndex}
          onPageChange={state => setPageIndex(state)}
          pages={pagesCount}
          nextText="След"
          loadingText="Загрузка..."
          noDataText={loading ? "" : "Нет записей"}
          pageText="Страница"
          ofText="из"
          rowsText="строк"
        />
      </div>
    );
  }
}

export default withRouter(LogTable);
