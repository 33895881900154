import React from "react";
import { Route, Switch } from "react-router-dom";

import ContractorPaymentDetails from "./components/ContractorPaymentDetails";
import ContractorPaymentMain from "./index.js";

export default props => (
  <div>
    <Switch>
      <Route exact path="/contractorPayment" component={ContractorPaymentMain} />
      <Route exact path="/contractorPayment/:id" component={ContractorPaymentDetails} />
    </Switch>
  </div>
);
