import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";

//import {addNotification} from '../../actions'

export const PRODOPERATIONS_SAVE_SUCCESS = "PRODOPERATIONS_SAVE_SUCCESS";
export const ADD_PRODOPERATION_SUCCESS = "ADD_PRODOPERATION_SUCCESS";
export const DELETE_PRODOPERATION_SUCCESS = "DELETE_PRODOPERATION_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const deleteProdOperation = (prodBomId, prodOperationId) => {
  return dispatch => {
    return axios
      .delete(`/api/prodOperation/${prodOperationId}`)
      .then(({ data }) => {
        dispatch({
          type: DELETE_PRODOPERATION_SUCCESS,
          prodBomId,
          prodOperationId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Операция успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const addProdOperation = (order_id, prodBomId, operation) => {
  return dispatch => {
    return axios
      .post(`/api/order/${order_id}/prodBom/${prodBomId}/operations`, operation)
      .then(({ data }) => {
        let newOperation = data;
        dispatch({
          type: ADD_PRODOPERATION_SUCCESS,
          response: normalize([newOperation], schema.arrayOfProdOperations),
          prodBomId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Операция успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const saveCuttingProdOperations = (
  prodBomId,
  prodOperations,
  oldProdOperations,
) => {
  return dispatch => {
    return axios
      .post(
        "/api/production_orders/cutting_prodoperations/" + prodBomId,
        prodOperations,
      )
      .then(({ data }) => {
        let oldProdOperationIds = oldProdOperations.map(
          operation => operation.id,
        );

        // Status looks good
        let savedOperations = data;
        dispatch({
          type: PRODOPERATIONS_SAVE_SUCCESS,
          response: normalize(savedOperations, schema.arrayOfProdOperations),
          prodBomId,
          oldProdOperationIds,
        });
        openNotificationWithIcon(
          "Успешно",
          "Операции успешно сохранены",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const saveSewingProdOperations = (
  prodBomId,
  prodOperations,
  oldProdOperations,
) => {
  return dispatch => {
    return axios
      .post(
        "/api/production_orders/sewing_prodoperations/" + prodBomId,
        prodOperations,
      )
      .then(({ data }) => {
        let oldProdOperationIds = oldProdOperations.map(
          operation => operation.id,
        );

        // Status looks good
        let savedOperations = data;
        dispatch({
          type: PRODOPERATIONS_SAVE_SUCCESS,
          response: normalize(savedOperations, schema.arrayOfProdOperations),
          prodBomId,
          oldProdOperationIds,
        });
        openNotificationWithIcon(
          "Успешно",
          "Операции успешно сохранены",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
