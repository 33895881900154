import { notification } from "antd";
import axios from "axios";

export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchNotifications = id => {
  return dispatch => {
    return axios
      .get(`/api/notification/` + id)
      .then(({ data }) => {
        let sizes = data;
        //dispatch({ type: 'PRODUCTS_FETCH_DATA_SUCCESS', products })

        //console.log("NORMALIZED", normalize(products, schema.arrayOfProducts));
        dispatch({
          type: FETCH_NOTIFICATIONS_SUCCESS,
          response: data,
        });

        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteNotification = id => {
  return dispatch => {
    return axios
      .delete("/api/notification/" + id)
      .then(({ data }) => {
        // Status looks good
        let size = data;
        dispatch({
          type: DELETE_NOTIFICATION_SUCCESS,
          notificationId: id,
        });
        return data;
      })
      .catch(error => {});
  };
};

export const markNotificationAsRead = id => {
  return dispatch => {
    return axios
      .put("/api/notification/" + id)
      .then(({ data }) => {
        dispatch({
          type: MARK_NOTIFICATION_AS_READ_SUCCESS,
          notificationId: id,
        });
        return data;
      })
      .catch(error => {});
  };
};
