import { Button, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setOpenedFabric } from "../../actions";
import {
  createFabric,
  fetchFabric,
  fetchFabrics,
  updateFabric,
} from "../../data/fabrics/actions";
import { getFabric } from "../../data/fabrics/reducer";
import { getOpenedFabric } from "../../reducer";
import FormValidator from "../FormValidator";

class FabricDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите название",
      },
      {
        field: "name",
        method: () => {
          return this.state.nameExists;
          // return true
        },
        validWhen: false,
        message: "Такое название уже есть",
      },
      {
        field: "description",
        method: "isEmpty",
        validWhen: false,
        message: "Введите описание",
      },
      {
        field: "manufacturer",
        method: "isEmpty",
        validWhen: false,
        message: "Введите производителя",
      },
      {
        field: "colorCode",
        method: "isEmpty",
        validWhen: false,
        message: "Введите код цвета",
      },
    ]);

    this.state = {
      isInEditState: false,

      name: "",
      description: "",
      manufacturer: "",
      colorCode: "",
      nameExists: false,

      validation: this.validator.valid(),
      isSaving: false,
    };

    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  handleSubmitDispatch = () => {
    const { submitForm } = this.props;
    submitForm();
  };

  mapErrorsToObjectMap = error => {
    let subError = new Object();
    for (var key in error.errors) {
      subError[key] = error.errors[key].message;
    }
    return subError;
  };

  createFabricFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var fabric = {
      name: this.state.name,
      description: this.state.description,
      manufacturer: this.state.manufacturer,
      colorCode: this.state.colorCode,
      unit: "asdf",
    };
    return fabric;
  };

  handleSubmit = values => {
    this.submitted = true;
    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    const { createFabric, match, updateFabric } = this.props;

    if (validation.isValid) {
      this.setState({ isSaving: true });
      var fabric = this.createFabricFromValues();
      //Handle update
      if (match.params.id) {
        return updateFabric(match.params.id, fabric).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/fabric-admin/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });

            // let subError = this.mapErrorsToObjectMap(error);
            // throw new SubmissionError(subError)
          },
        );
      }
      //Handle create
      else {
        return createFabric(fabric).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/fabric-admin/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });

            // let subError = this.mapErrorsToObjectMap(error);
            // throw new SubmissionError(subError)
          },
        );
      }
    }
  };

  componentDidMount() {
    this.fetchFabricOrResetForm();
  }

  componentDidUpdate(prevProps) {
    const { fetchFabric, match, setOpenedFabric } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.fetchFabricOrResetForm();
    }
  }

  fetchFabricOrResetForm = () => {
    const { fetchFabric, match, setOpenedFabric } = this.props;
    setOpenedFabric("");
    if (match.params.id) {
      fetchFabric(match.params.id).then(fabric => {
        setOpenedFabric(match.params.id);

        this.setState({
          isInEditState: false,
          name: fabric.name,
          description: fabric.description,
          manufacturer: fabric.manufacturer,
          colorCode: fabric.colorCode
        });
      });
    } else {
      this.setState({ isInEditState: true });
    }
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };
  handleDescriptionChange = e => {
    this.setState({ description: e.target.value });
  };
  handleColorCodeChange = e => {
    this.setState({ colorCode: e.target.value });
  };
  handleManufacturerChange = e => {
    this.setState({ manufacturer: e.target.value });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    const { match, openedFabric } = this.props;
    const { isInEditState } = this.state;
    return (
      // <FabricDetail {...this.props}
      // isInEditState={this.state.isInEditState}
      // toggleEditState={this.toggleEditState}
      // onSubmitDispatch={this.handleSubmitDispatch}
      // onSubmit={this.handleSubmit}/>
      <div className="mt-paper">
        <div>
          <h2>Материал</h2>
        </div>
        {match.params.id && isInEditState && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Сохранить
          </Button>
        )}
        {match.params.id == undefined && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Создать
          </Button>
        )}
        {match.params.id && !isInEditState && (
          <Button type="secondary" onClick={this.toggleEditState}>
            Изменить
          </Button>
        )}
        <br />
        &nbsp;
        {/* <FabricForm enableReinitialize initialValues={openedFabric} onSubmit={this.handleSubmit} disabled={!isInEditState} /> */}
        <div
          style={{ marginBottom: 15 }}
          className={validation.name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="name">
            Название
          </label>
          <Input
            name="name"
            placeholder="Название"
            //onChange={this.handleInputChange}
            value={this.state.name}
            onChange={this.handleNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.name.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.description.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="description">
            Описание
          </label>
          <Input
            name="description"
            placeholder="Описание"
            //onChange={this.handleInputChange}
            value={this.state.description}
            onChange={this.handleDescriptionChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.description.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.manufacturer.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="manufacturer">
            Производитель
          </label>
          <Input
            name="manufacturer"
            placeholder="Производитель"
            //onChange={this.handleInputChange}
            value={this.state.manufacturer}
            onChange={this.handleManufacturerChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.manufacturer.message}</span>
        </div>
        <div
            style={{ marginBottom: 15 }}
            className={validation.colorCode.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="colorCode">
            Код цвета
          </label>
          <Input
              name="colorCode"
              placeholder="Код цвета"
              //onChange={this.handleInputChange}
              value={this.state.colorCode}
              onChange={this.handleColorCodeChange}
              disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.colorCode.message}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    openedFabric: getFabric(state, getOpenedFabric(state)),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchFabric: id => dispatch(fetchFabric(id)),
    setOpenedFabric: id => dispatch(setOpenedFabric(id)),
    createFabric: fabric => dispatch(createFabric(fabric)),
    updateFabric: (fabricId, fabric) =>
      dispatch(updateFabric(fabricId, fabric)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabricDetail),
);
