import { ME_FROM_TOKEN_SUCCESS, SIGNIN_USER_SUCCESS } from "../users/actions";
import {
  DOMAIN_SETTINGS_FETCH_SUCCESS,
  UPDATE_DOMAIN_SETTINGS_SUCCESS,
} from "./actions";

export default (state = null, action) => {
  switch (action.type) {
    case ME_FROM_TOKEN_SUCCESS: {
      return action.data.domainSettings;
    }
    case SIGNIN_USER_SUCCESS: {
      return action.data.domainSettings;
    }
    case UPDATE_DOMAIN_SETTINGS_SUCCESS: {
      return action.response;
    }
    case DOMAIN_SETTINGS_FETCH_SUCCESS: {
      return action.response;
    }

    default:
      return state;
  }
};
