import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Dropdown, Table, Tabs } from "antd";
import { Col, Input, Popconfirm, Row, Switch } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";

import {
  setOpenedPattern,
  setOpenedProduct,
  setOpenedSpread,
  setSelectedSpread,
  setSelectedSpreadTab,
  setShowPatternModal,
  setShowSpreadModal,
} from "../../actions";
import { deletePattern } from "../../data/patterns/actions";
import { deleteSpread, saveSpread } from "../../data/spreads/actions";
import {
  makeGetSelectedSpreadTabByBomId,
  makeGetSpreadsWithPatternsByBomId,
} from "../../selectors";
import { getOpenedSpread } from "../../selectors";
import { getSpreadsWithPatternsByBomId } from "../../selectors";

const TabPane = Tabs.TabPane;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
};

class SpreadTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minHeight: "400px",
      //selectedSpreadId: undefined
    };
  }

  handleAddSemiProduct = () => {
    const { selectedSpreadTab, setSelectedSpread } = this.props;
    //console.log("yes clicked");
    this.props.setOpenedPattern("");
    this.props.setSelectedSpread(selectedSpreadTab);
    this.props.setShowPatternModal(true);
  };

  handlePatternEditClick = value => {
    const { selectedSpreadTab, setSelectedSpread } = this.props;
    this.props.setOpenedPattern(value);
    this.props.setSelectedSpread(selectedSpreadTab);
    this.props.setShowPatternModal(true);
  };

  handlePatternRemove = (pattern, spreadId) => {
    this.props.deletePattern(pattern.id, spreadId);
  };

  handleSpreadRemove = spreadId => {
    const {
      deleteSpread,
      product,
      spreads,
      setSelectedSpreadTab,
      bomId,
    } = this.props;
    //console.log("being remove", value);
    if (spreadId) {
      let foundSpread = spreads.find(spread => spread.id == spreadId);
      if (foundSpread) {
        deleteSpread(foundSpread.id, bomId);

        //Calculate the previous tab id
        let prevSpreadId = "";
        for (let i = 0; i < spreads.length; i++) {
          if (spreads[i].id == spreadId) {
            if (i == 0 && spreads.length > 1) prevSpreadId = spreads[1].id;
            break;
          }
          prevSpreadId = spreads[i].id;
        }

        setSelectedSpreadTab(bomId, prevSpreadId);
        //this.setState({ selectedSpreadId: prevSpreadId })
      } else {
        console.log(spreadId);
      }
    }
  };
  onSpreadDoubleClick = spreadId => {
    this.props.setOpenedSpread(spreadId);
    this.props.setShowSpreadModal(true);
  };

  handleTabChange = key => {
    const { setSelectedSpreadTab, bomId } = this.props;
    //this.setState({ selectedSpreadId: parseInt(key) })
    setSelectedSpreadTab(bomId, parseInt(key));
    // setSelectedSpread();
  };

  componentWillReceiveProps(nextProps) {
    // const { spreads } = this.props;
    // if (this.props.spreads != nextProps.spreads) {
    //     if (spreads && spreads.length >= 1) {
    //         console.log("yes received new spreads here", spreads)
    //        // setSelectedSpread(spreads[0].id);
    //        this.setState({ selectedSpreadId: spreads[0].id })
    //     }
    // }
  }

  componentDidMount() {
    // const { spreads } = this.props;
    // if (spreads && spreads.length >= 1) {
    //     this.setState({ selectedSpreadId: spreads[0].id })
    // }
  }

  onSwitchChange = (value, spread, prevValue) => {
    const { saveSpread } = this.props;
    // setPrintOperations(spread.id, value).then(
    //     (json) => {

    //     },
    //     (error) => {

    //     }
    // )
    saveSpread({
      id: spread.id,
      name: spread.name,
      fabric: spread.fabric,
      printOperations: value,
    });
  };

  render() {
    const { spreads, selectedSpreadTab, disabled } = this.props;
    let selectedSpreadId = selectedSpreadTab;

    let tabPanes = spreads.map((spread, index) => {
      console.log("SPREAD", spread, spread.fabric.name);

      let printChecked = spread.printOperations == 0 ? false : true;
      return (
        <TabPane key={spread.id + ""} tab={spread.name}>
          <div style={{ marginTop: 30, marginBottom: 15 }}>
            <label className="mt-label" htmlFor="fabric">
              Материал:{" "}
            </label>
            <Input
              style={{ maxWidth: "300px", marginLeft: 8 }}
              id="fabric"
              disabled
              name="title"
              value={spread.fabric.name}
            />
          </div>
          <div style={{ marginBottom: 25 }}>
            <label className="mt-label">Печатать операции:</label>&nbsp; &nbsp;
            <Switch
              disabled={disabled}
              defaultChecked={printChecked}
              onChange={e =>
                this.onSwitchChange(e, spread, spread.printChecked)
              }
            />
          </div>

          <div style={{ marginBottom: 25 }}>
            <Button
              disabled={disabled}
              icon={<PlusOutlined />}
              onClick={this.handleAddSemiProduct}
            >
              Добавить деталь
            </Button>
          </div>

          <PaneContent
            disabled={disabled}
            spread={spread}
            onPatternRemove={e => this.handlePatternRemove(e, spread.id)}
            onPatternEdit={this.handlePatternEditClick}
          />
        </TabPane>
      );
    });
    return (
      <Tabs
        disabled={disabled}
        activeKey={selectedSpreadId + ""}
        style={{ minHeight: this.state.minHeight }}
        type="card"
        animated={false}
        onTabClick={this.handleTabChange}
        tabBarExtraContent={
          <Operations
            disabled={disabled}
            selectedSpreadId={selectedSpreadId}
            spreads={spreads}
            onSpreadEdit={this.onSpreadDoubleClick}
            onSpreadRemove={this.handleSpreadRemove}
          />
        }
      >
        {tabPanes}
      </Tabs>
    );
  }
}

const Operations = ({
  spreads,
  onSpreadEdit,
  onSpreadRemove,
  selectedSpreadId,
  disabled,
}) => {
  if (spreads.length > 0) {
    return (
      <Button.Group>
        <Button
          disabled={disabled}
          onClick={e => onSpreadEdit(selectedSpreadId, e)}
          icon={<EditOutlined />}
        >
          Изменить
        </Button>
        <Popconfirm
          placement="topRight"
          title="Уверены что хотите удалить этот настил?"
          onConfirm={e => onSpreadRemove(selectedSpreadId, e)}
          okText="Да"
          cancelText="Нет"
        >
          <Button disabled={disabled} type="danger" icon={<DeleteOutlined />}>
            Удалить
          </Button>
        </Popconfirm>
      </Button.Group>
    );
  } else return null;
};

class PaneContent extends Component {
  handleConfirm = (row, e) => {
    const { spread, onPatternRemove, onPatternEdit } = this.props;
    onPatternRemove(row, e);
  };

  handleCancel = e => {};

  render() {
    const { spread, onPatternRemove, onPatternEdit, disabled } = this.props;

    const columns = [
      {
        Header: "",
        accessor: "id",
        maxWidth: 60,
        Cell: row => (
          <div>
            <Button
              disabled={disabled}
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={e => onPatternEdit(row.original.id, e)}
            />
          </div>
        ),
      },
      {
        Header: "Название",
        accessor: "name",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: "Удалить",
        maxWidth: 100,
        Cell: row => (
          <div>
            <Popconfirm
              placement="topRight"
              title="Уверены что хотите удалить эту деталь?"
              onConfirm={e => this.handleConfirm(row.original, e)}
              onCancel={this.handleCancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button
                disabled={disabled}
                type="danger"
                size="small"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <ReactTable
        data={spread.patterns}
        columns={columns}
        className=" -highlight"
        minRows={5}
        showPagination={false}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getSelectedSpreadTabByBomId = makeGetSelectedSpreadTabByBomId();
  const getSpreadsWithPatternsByBomId = makeGetSpreadsWithPatternsByBomId();
  const mapStateToProps = (state, props) => {
    return {
      spreads: getSpreadsWithPatternsByBomId(state, props),
      openedSpread: getOpenedSpread(state),
      selectedSpreadTab: getSelectedSpreadTabByBomId(state, props),
    };
  };
  return mapStateToProps;
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    //setSelectedSpread: (spreadId) => dispatch(setSelectedSpread(spreadId)),
    setOpenedSpread: spreadId => dispatch(setOpenedSpread(spreadId)),
    setSelectedSpread: spreadId => dispatch(setSelectedSpread(spreadId)),
    setOpenedPattern: spreadId => dispatch(setOpenedPattern(spreadId)),
    setShowSpreadModal: showModal => dispatch(setShowSpreadModal(showModal)),
    setShowPatternModal: showModal => dispatch(setShowPatternModal(showModal)),
    setSelectedSpreadTab: (bomId, spreadId) =>
      dispatch(setSelectedSpreadTab(bomId, spreadId)),
    deleteSpread: (spreadId, bomId) => dispatch(deleteSpread(spreadId, bomId)),
    saveSpread: spread => dispatch(saveSpread(spread)),
    deletePattern: (patternId, spreadId) =>
      dispatch(deletePattern(patternId, spreadId)),
    //setPrintOperations: (id, print)=>dispatch(setPrintOperations(id, print)),
  };
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(SpreadTabs);
