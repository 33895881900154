import { Button, Input } from "antd";
import { Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {Redirect, withRouter} from "react-router";
import { Route, Switch } from "react-router-dom";

import { fetchProdDepotLocations } from "../../../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../../../data/prodDepotLocations/reducer";
import { fetchRoles } from "../../../../data/roles/actions";
import { getAllRoles } from "../../../../data/roles/reducer";
import {
  createUser,
  fetchUser,
  updateUser,
} from "../../../../data/users/actions";
import { getUser } from "../../../../data/users/reducer";
import FormValidator from "../../../FormValidator";
import { setOpenedUserId } from "../../actions";

const Option = Select.Option;

class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "username",
        method: "isEmpty",
        validWhen: false,
        message: "Введите имя пользователя",
      },
      {
        field: "username",
        method: () => {
          return this.state.usernameExists;
          // return true
        },
        validWhen: false,
        message: "Такое имя уже есть",
      },
      {
        field: "username",
        method: value => {
          let arr = value.trim().split("");
          if (
            arr.indexOf("@") >= 0 ||
            arr.indexOf("№") >= 0 ||
            arr.indexOf("%") >= 0 ||
            arr.indexOf("!") >= 0 ||
            arr.indexOf("*") >= 0 ||
            arr.indexOf(" ") >= 0 ||
            arr.indexOf("#") >= 0
          )
            return true;
          else return false;
        },
        validWhen: false,
        message: "Нельзя использовать пробел или символы №, %, !, *, @, #",
      },
      {
        field: "username",
        method: value => {
          if (value.trim().length <= 80) return false;
          else return true;
        },
        validWhen: false,
        message: "Имя пользователя не может быть больше 80 символов",
      },
      {
        field: "first_name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите имя",
      },
      {
        field: "last_name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите фамилию",
      },
      {
        field: "password",
        method: () => {
          if (this.props.match.params.id) {
            return false;
          } else {
            if (this.state.password.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        },
        validWhen: false,
        message: "Введите пароль",
      },
      {
        field: "selectedRolesField",
        method: () => {
          return false;
        },
        validWhen: false,
        message: "Выберите доступ",
      },
      // {
      //     field: 'last_name',
      //     method: 'isEmpty',
      //     validWhen: false,
      //     message: 'Введите фамилию'
      //   },
      // {
      //     field: 'middle_name',
      //     method: 'isEmpty',
      //     validWhen: false,
      //     message: 'Введите отчество'
      // },
      // {
      //     field: 'team',
      //     method: ()=>{
      //         if(Number.isInteger(this.state.team) ){
      //            return false;

      //         }
      //         else
      //             return true;
      //     },
      //     validWhen: false,
      //     message: 'Выберите бригаду'
      //   },
    ]);

    this.state = {
      isInEditState: false,

      username: "",
      password: "",

      selectedRoles: [],
      selectedRolesField: "",
      usernameExists: false,

      first_name: "",
      last_name: "",

      validation: this.validator.valid(),
      isSaving: false,
    };

    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({
      isInEditState: true,
    });
    this.submitted = false;
  };

  mapErrorsToObjectMap = error => {
    let subError = new Object();
    for (var key in error.errors) {
      subError[key] = error.errors[key].message;
    }
    return subError;
  };

  createUserFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    var user = {
      username: this.state.username,
      password: this.state.password,
      first_name: this.state.first_name,
      last_name: this.state.last_name,

      //hire_date: t.hire_date,
      roles: this.state.selectedRoles,
      prodDepotLocation_id: this.state.prodDepotLocation_id
        ? this.state.prodDepotLocation_id
        : null,
    };
    return user;
  };

  handleSubmit = () => {
    const { createUser, match, updateUser } = this.props;

    this.submitted = true;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });

    if (validation.isValid) {
      this.setState({ isSaving: true });
      var user = this.createUserFromValues();
      //Handle update
      if (match.params.id) {
        return updateUser(match.params.id, user).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/user/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });
            if (error.response) {
              let { data } = error.response;
              if (data.username) {
                this.setState({ usernameExists: true });
              }
            }
          },
        );
      }
      //Handle create
      else {
        return createUser(user).then(
          json => {
            this.setState({ isInEditState: false, isSaving: false });
            this.props.history.push(`/user/` + json.id);
          },
          error => {
            this.setState({ isSaving: false });
            if (error.response) {
              let { data } = error.response;
              if (data.username) {
                this.setState({ usernameExists: true });
              }
            }
          },
        );
      }
    }
  };

  componentDidMount() {
    this.props
      .fetchProdDepotLocations()
      .then(() => this.fetchUserOrResetForm());
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.fetchUserOrResetForm();
    }
  }

  fetchUserOrResetForm = () => {
    const { fetchUser, match, setOpenedUserId, fetchRoles } = this.props;
    setOpenedUserId("");
    if (match.params.id) {
      fetchUser(match.params.id).then(user => {
        setOpenedUserId(user.id);
        this.setState({ isInEditState: false });

        let rolesVal = [];
        if (user.roles && user.roles.length > 0) {
          rolesVal = user.roles.map(role => role.id);
        }

        this.setState({
          username: user.username,
          password: user.password,
          first_name: user.first_name,
          last_name: user.last_name,
          prodDepotLocation_id: user.defaultProdDepotLocation_id
            ? user.defaultProdDepotLocation_id
            : undefined,
          selectedRoles: rolesVal,
        });
      });

      fetchRoles();
    } else {
      this.setState({ isInEditState: true });
      fetchRoles();
    }
  };

  handleUsernameChange = e => {
    this.setState({
      username: e.target.value,
      usernameExists: false,
    });
  };

  handleProdDepotLocationChange = prodDepotLocation_id => {
    this.setState({
      prodDepotLocation_id: prodDepotLocation_id
        ? prodDepotLocation_id
        : undefined,
    });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };
  handleFirstNameChange = e => {
    this.setState({ first_name: e.target.value });
  };
  handleLastNameChange = e => {
    this.setState({ last_name: e.target.value });
  };

  handleRoleChange = roles => {
    this.setState({ selectedRoles: roles });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    let prodDepotLocationOptions = this.props.prodDepotLocations.map(depot => {
      return (
        <Option key={depot.id} value={depot.id}>
          {depot.name}
        </Option>
      );
    });

    const { history, openedUser, match, roles, user } = this.props;
    let roleOptions = roles.map(role => {
      return (
        <Option key={role.id} value={role.id}>
          {role.name}
        </Option>
      );
    });

    //console.log("OPENED EMPLOYEE", openedEmployee)
    // let teamId=""
    // if(openedEmployee)
    //  teamId=openedEmployee.team_id;
    const me = user.user;
    const isAdmin = me.roles.map(role => role.id).includes(1);

    if(parseInt(match.params.id) !== me.id && !isAdmin) {
      return <Redirect to={`/user/${me.id}`} />
    }

    return (
      <div className="mt-paper">
        <div>
          <h2>Пользователь</h2>
        </div>
        {match.params.id && this.state.isInEditState && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Сохранить
          </Button>
        )}
        {match.params.id == undefined && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.isSaving}
          >
            Создать
          </Button>
        )}
        {match.params.id && !this.state.isInEditState && (
          <Button onClick={this.toggleEditState}>Изменить</Button>
        )}
        <br />
        &nbsp;
        {/* <EmployeeForm teamOptions={teamOptions} enableReinitialize initialValues={{...openedEmployee, team:teamId }} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
        <div
          style={{ marginBottom: 15 }}
          className={validation.username.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="username">
            Имя пользователя
          </label>
          <Input
            name="username"
            placeholder="Имя пользователя"
            addonAfter={"@" + (user.user ? user.user.domain : "")}
            //onChange={this.handleInputChange}
            value={this.state.username}
            onChange={this.handleUsernameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.username.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.first_name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="first_name">
            Имя
          </label>
          <Input
            name="first_name"
            placeholder="Имя"
            //onChange={this.handleInputChange}
            value={this.state.first_name}
            onChange={this.handleFirstNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.first_name.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.last_name.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="last_name">
            Фамилия
          </label>
          <Input
            name="last_name"
            placeholder="Фамилия"
            //onChange={this.handleInputChange}
            value={this.state.last_name}
            onChange={this.handleLastNameChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.last_name.message}</span>
        </div>
        <div
          style={{ marginBottom: 15 }}
          className={validation.password.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="password">
            Пароль
          </label>
          <Input
            name="password"
            placeholder="Пароль"
            type="password"
            //onChange={this.handleInputChange}
            value={this.state.password}
            onChange={this.handlePasswordChange}
            disabled={!this.state.isInEditState}
          />
          <span className="help-block">{validation.password.message}</span>
        </div>
        { isAdmin &&
          <div
              style={{marginBottom: 15}}
              className={validation.selectedRolesField.isInvalid ? "has-error" : ""}
          >
            <label className="mt-label" htmlFor="access">
              Доступ
            </label>
            <br/>
            {/* <Select disabled={!this.state.isInEditState} value={this.state.team} style={{ width: 120 }} onChange={this.handleRoleChange}>
                        {roleOptions}
                    </Select> */}

            <Select
                disabled={!this.state.isInEditState}
                mode="multiple"
                style={{width: "100%"}}
                placeholder="Выберите доступ"
                //defaultValue={['a10', 'c12']}
                value={this.state.selectedRoles}
                onChange={this.handleRoleChange}
            >
              {roleOptions}
            </Select>

            <br/>
            <span className="help-block">
            {validation.selectedRolesField.message}
          </span>
          </div>
        }
        <div
          style={{ marginBottom: 15 }}
          className={validation.selectedRolesField.isInvalid ? "has-error" : ""}
        >
          <label className="mt-label" htmlFor="access">
            Склад готовой продукции
          </label>
          <br />
          <Select
            allowClear
            disabled={!this.state.isInEditState}
            style={{ width: "100%" }}
            placeholder="Выберите склад"
            value={this.state.prodDepotLocation_id}
            onChange={this.handleProdDepotLocationChange}
          >
            {prodDepotLocationOptions}
          </Select>

          <br />
          <span className="help-block">
            {validation.selectedRolesField.message}
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    prodDepotLocations: getAllProdDepotLocations(state),
    openedUser: getUser(state, state.scenes.user.openeUserId),
    roles: getAllRoles(state),
    user: state.data.users.user,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
    fetchUser: id => dispatch(fetchUser(id)),
    fetchRoles: () => dispatch(fetchRoles()),
    setOpenedUserId: id => dispatch(setOpenedUserId(id)),
    createUser: user => dispatch(createUser(user)),
    updateUser: (id, user) => dispatch(updateUser(id, user)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserDetail),
);
