import { Breadcrumb, Button, Dropdown, Layout, Menu } from "antd";
import {
  deleteProdDepotEntry,
  fetchProdDepotEntries,
} from "data/prodDepotEntries/actions";
import { getAllProdDepotEntries } from "data/prodDepotEntries/reducer";
import { fetchProdDepotLocations } from "data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "data/prodDepotLocations/reducer";
import { fetchTeams } from "data/teams/actions";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import ProdDepotEntryTable from "./components/ProdDepotEntryTable";

function ProdDepotMain(props) {
  const {
    history,
    prodDepotEntries,
    fetchProdDepotEntries,
    fetchProdDepotLocations,
    deleteProdDepotEntry,
  } = props;

  const [loading, setLoading] = useState(() => {
    if (prodDepotEntries.length > 0) {
      return false;
    } else return true;
  });
  const [prodDepotLocationOptions, setProdDepotLocationOptions] = useState([]);

  const onProdDepotEntryCreate = () => {
    history.push(`/proddepotentry/new`);
  };

  const handleEditClick = contractor => {
    history.push(`/proddepotentry/` + contractor.id);
  };

  const onRemove = value => {
    deleteProdDepotEntry(value.id);
  };

  useEffect(() => {
    fetchProdDepotEntries().then(depot => {
      setLoading(false);
    });

    fetchProdDepotLocations().then(options => {
      setProdDepotLocationOptions(options);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Приходы</h2>
        <Button type="primary" onClick={onProdDepotEntryCreate}>
          Создать
        </Button>

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => history.push("/logs/prodDepotEntry")}>
                Посмотреть в журнале
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={{ marginLeft: "0.5em" }}>...</Button>
        </Dropdown>
      </div>
      <br />
      <ProdDepotEntryTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        prodDepotEntries={prodDepotEntries}
        prodDepotLocationOptions={prodDepotLocationOptions}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    prodDepotEntries: getAllProdDepotEntries(state),
    prodDepotLocations: getAllProdDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProdDepotEntries: () => dispatch(fetchProdDepotEntries()),
    deleteProdDepotEntry: id => dispatch(deleteProdDepotEntry(id)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdDepotMain),
);
