import {
  Card,
  Col,
  DatePicker,
  InputNumber,
  Input,
  Row,
  Select as SelectAntD,
} from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import { Formik, withFormik } from "formik";
import moment from "moment";
import React, { Component, useRef } from "react";
import * as Yup from "yup";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const EntrySchema = Yup.object().shape({
  price: Yup.number()
    .min(0, "Значение слишком маленькое")
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  weight: Yup.number()
    .min(1, "Значение слишком маленькое")
    .max(999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  rolls: Yup.number()
    .min(1, "Количество рулонов должно быть больше 0")
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  batchNumber: Yup.string()
    .required("Введите номер партии")
    .typeError("Введите номер партии")
    .nullable(),
  entryDate: Yup.object()
    .required("Выберите дату")
    .nullable(),
  contractor: Yup.object()
    .required("Выберите поставщика")
    .nullable(),
  fabric: Yup.object()
    .required("Выберите материал")
    .nullable(),
  depotLocation: Yup.number()
    .required("Выберите Склад")
    .nullable(),
});

class FabDepotEntryForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {}

  getFabricOptions = function(input, callback) {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  submitForm = () => {
    this.form.current.submitForm();
  };

  getContractorOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/contractors/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.company_name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  getFabricOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/search?q=${input}`).then(({ data }) => {
      return data.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    });
  };

  render() {
    const { disabled } = this.props;

    function disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf("day");
    }

    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={EntrySchema}
          onSubmit={this.props.onSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{ marginBottom: 15 }}
                className={errors.price && touched.price ? "has-error" : ""}
              >
                <label className="mt-label" htmlFor="price">
                  Цена
                </label>
                <br />
                <InputNumber
                  style={{ width: 300 }}
                  name="price"
                  placeholder="Цена"
                  //onChange={this.handleInputChange}
                  value={values["price"]}
                  onChange={e => setFieldValue("price", e)}
                  //onBlur={e => setFieldTouched("price", true)}
                  disabled={disabled}
                />
                <div className="help-block">
                  {errors.price && touched.price && errors.price}
                </div>
              </div>
              <div
                  style={{ marginBottom: 15 }}
                  className={errors.batchNumber && touched.batchNumber ? "has-error" : ""}
              >
                <label className="mt-label" htmlFor="batchNumber">
                  Номер партии
                </label>
                <br />
                <Input
                    style={{ width: 300 }}
                    name="batchNumber"
                    placeholder="Номер партии"
                    value={values["batchNumber"]}
                    onChange={e => setFieldValue("batchNumber", e.target.value)}
                    disabled={disabled}
                />
                <div className="help-block">
                  {errors.batchNumber && touched.batchNumber && errors.batchNumber}
                </div>
              </div>
              <div
                style={{ marginBottom: 15 }}
                className={errors.weight && touched.weight ? "has-error" : ""}
              >
                <label className="mt-label" htmlFor="weight">
                  Вес (кг)
                </label>
                <br />
                <InputNumber
                  style={{ width: 300 }}
                  name="weight"
                  placeholder="Вес"
                  //onChange={this.handleInputChange}
                  value={values["weight"]}
                  onChange={e => setFieldValue("weight", e)}
                  //onBlur={e => setFieldTouched("weight", true)}
                  disabled={disabled}
                />
                <div className="help-block">
                  {errors.weight && touched.weight && errors.weight}
                </div>
              </div>
              <div
                style={{ marginBottom: 15 }}
                className={errors.rolls && touched.rolls ? "has-error" : ""}
              >
                <label className="mt-label" htmlFor="rolls">
                  Кол-во рулонов
                </label>
                <br />
                <InputNumber
                  style={{ width: 300 }}
                  name="rolls"
                  placeholder="Кол-во рулонов"
                  //onChange={this.handleInputChange}
                  value={values["rolls"]}
                  onChange={e => setFieldValue("rolls", e)}
                  //onBlur={e => setFieldTouched("rolls", true)}
                  disabled={disabled}
                />
                <div className="help-block">
                  {errors.rolls && touched.rolls && errors.rolls}
                </div>
              </div>
              <div
                style={{ marginBottom: 15 }}
                className={
                  errors.entryDate && touched.entryDate ? "has-error" : ""
                }
              >
                <label className="mt-label" htmlFor="prodDate">
                  Дата прихода
                </label>
                <br />
                <DatePicker
                  id="entry_date"
                  name="date"
                  disabledDate={disabledDate}
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  value={values["entryDate"]}
                  onChange={e => setFieldValue("entryDate", e)}
                  //onBlur={e => setFieldTouched("entryDate", true)}
                  disabled={disabled}
                />
                <br />
                <div className="help-block">
                  {errors.entryDate && touched.entryDate && errors.entryDate}
                </div>
              </div>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="product">
                  Поставщик
                </label>
                <AsyncSelect
                  name="contractor"
                  loadOptions={this.getContractorOptions}
                  isDisabled={disabled}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  value={values["contractor"]}
                  onChange={e => setFieldValue("contractor", e)}
                  //onBlur={e => setFieldTouched("contractor", true)}
                  disabled={disabled}
                />
                <div className="help-block">
                  {errors.contractor && touched.contractor && errors.contractor}
                </div>
              </div>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="fabric">
                  Материал
                </label>
                <AsyncSelect
                  name="fabric"
                  loadOptions={this.getFabricOptions}
                  isDisabled={disabled}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  value={values["fabric"]}
                  onChange={e => setFieldValue("fabric", e)}
                  //onBlur={e => setFieldTouched("fabric", true)}
                />
                <div className="help-block">
                  {errors.fabric && touched.fabric && errors.fabric}
                </div>
              </div>
              <div
                style={{ marginBottom: 15 }}
                className={
                  errors.depotLocation && touched.depotLocation
                    ? "has-error"
                    : ""
                }
              >
                <label className="mt-label" htmlFor="job_title">
                  Склад
                </label>
                <br />
                <SelectAntD
                  disabled={disabled}
                  value={values["depotLocation"]}
                  style={{ width: 300 }}
                  onChange={e => setFieldValue("depotLocation", e)}
                  //onBlur={e => setFieldTouched("depotLocation", true)}
                >
                  {this.props.depotLocationOptions}
                </SelectAntD>
                <br />
                <div className="help-block">
                  {errors.depotLocation &&
                    touched.depotLocation &&
                    errors.depotLocation}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default FabDepotEntryForm;
