import { Button, Modal, Select as SelectAntD } from "antd";
import {
  createProdDepotEntry,
  fetchProdDepotEntry,
  updateProdDepotEntry,
} from "data/prodDepotEntries/actions";
import { getProdDepotEntry } from "data/prodDepotEntries/reducer";
import { fetchProdDepotLocations } from "data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "data/prodDepotLocations/reducer";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setOpenedProdDepotEntryId } from "../../actions";
import ProdDepotEntryForm from "../../forms/ProdDepotEntryForm";

const confirm = Modal.confirm;
const Option = SelectAntD.Option;

function ProdDepotEntryDetail(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    match,
    fetchProdDepotEntry,
    setOpenedProdDepotEntryId,
    createProdDepotEntry,
    openedProdDepotEntry,
    updateProdDepotEntry,
    fetchProdDepotLocations,
    prodDepotLocations,
  } = props;
  const form = useRef();
  const [isInEditState, toggleEditState] = useState(false);
  const [showEdit, setShowEdit] = useState(null);
  const [formData, setFormData] = useState({
    price: null,
    quantity: null,
    entry_date: null,
    product: null,
    prodDepotLocation: null,
  });
  const [isSaving, setSaving] = useState(false);

  const createProdDepotEntryFromValues = values => {
    var depot = {
      price: values.price,
      quantity: values.quantity,
      entry_date: new moment(values.entry_date).format("YYYY-MM-DD HH:mm:ss"),
      product: values.product,
      prodDepotLocation_id: values.prodDepotLocation,
    };
    return depot;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    var depotLocation = createProdDepotEntryFromValues(values);
    //Handle update
    if (match.params.id) {
      setSaving(true);
      return updateProdDepotEntry(match.params.id, depotLocation).then(
        json => {
          toggleEditState(false);
          setSaving(false);
          setSubmitting(false);

          history.push(`/proddepotentry/` + json.id);
        },
        error => {
          setSaving(false);
          setSubmitting(false);
        },
      );
    }
    //Handle create
    else {
      setSaving(true);
      return createProdDepotEntry(depotLocation).then(
        json => {
          toggleEditState(false);
          setSaving(false);
          setSubmitting(false);
          history.push(`/proddepotentry/` + json.id);
        },
        error => {
          setSaving(false);
          setSubmitting(false);
        },
      );
    }
  };

  const addLabelFieldsToProduct = product => {
    if (product) {
      return {
        value: product.id,
        label: product.name,
        ...product,
      };
    }
    return null;
  };

  const startSubmit = () => {
    form.current.submitForm();
  };

  useEffect(() => {
    setOpenedProdDepotEntryId("");
    if (match.params.id) {
      fetchProdDepotEntry(match.params.id).then(entry => {
        setShowEdit(false);

        if (entry.prodStocks && entry.prodStocks.length === 1) {
          if (entry.prodStocks[0].prodTransactionType_id === 1) {
            setShowEdit(true);
          }
        }
        setOpenedProdDepotEntryId(entry.id);
        toggleEditState(false);
        fetchProdDepotLocations();
        setFormData(prev => {
          return {
            ...prev,
            price: entry.price,
            entry_date: entry.entry_date ? new moment(entry.entry_date) : null,
            quantity: entry.quantity,
            product: addLabelFieldsToProduct(entry.product),
            prodDepotLocation: entry.prodDepotLocation_id,
          };
        });
      });
    } else {
      fetchProdDepotLocations();
      toggleEditState(true);
    }
  }, [match.params.id]);

  let prodDepotLocationOptions = prodDepotLocations.map(depot => {
    return (
      <Option key={depot.id} value={depot.id}>
        {depot.name}
      </Option>
    );
  });

  return (
    <div className="mt-paper">
      <div>
        <h2>Запись прихода</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={startSubmit} loading={isSaving}>
          Сохранить
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={startSubmit} loading={isSaving}>
          Сохранить
        </Button>
      )}
      {match.params.id && !isInEditState && showEdit === true && (
        <Button onClick={() => toggleEditState(true)}>Изменить</Button>
      )}

      <br />
      {showEdit === false && match.params.id && (
        <div>
          Эту запись нельзя редактировать так как у нее есть записи перемещения
          или ревизии
        </div>
      )}

      <br />

      <ProdDepotEntryForm
        prodDepotLocationOptions={prodDepotLocationOptions}
        disabled={!isInEditState}
        ref={form}
        onSubmit={handleSubmit}
        prodDepotEntryId={openedProdDepotEntry ? openedProdDepotEntry.id : null}
        initialValues={{
          price: formData.price,
          quantity: formData.quantity,
          entry_date: formData.entry_date,
          product: formData.product,
          prodDepotLocation: formData.prodDepotLocation,
        }}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    openedProdDepotEntry: getProdDepotEntry(
      state,
      state.scenes.prodDepotEntry.openedProdDepotEntryId,
    ),
    prodDepotLocations: getAllProdDepotLocations(state),
    user: state.data.users.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProdDepotEntry: id => dispatch(fetchProdDepotEntry(id)),
    setOpenedProdDepotEntryId: id => dispatch(setOpenedProdDepotEntryId(id)),
    createProdDepotEntry: location => dispatch(createProdDepotEntry(location)),
    updateProdDepotEntry: (id, entry) =>
      dispatch(updateProdDepotEntry(id, entry)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdDepotEntryDetail),
);
