import { combineReducers } from "redux";

import {
  SCHEDULES_FETCH_SUCCESS,
  SCHEDULE_CREATE_SUCCESS,
  SCHEDULE_DELETE_SUCCESS,
  SCHEDULE_FETCH_SUCCESS,
  SCHEDULE_UPDATE_SUCCESS,
  SCHEDULE_WEIGHT_BATCH_UPDATE_PERFORM,
  SCHEDULE_WEIGHT_BATCH_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case SCHEDULES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.workingSchedules,
      };
    case SCHEDULE_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.workingSchedules,
      };
    case SCHEDULE_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.workingSchedules,
      };
    case SCHEDULE_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.workingSchedules,
      };
    case SCHEDULE_WEIGHT_BATCH_UPDATE_PERFORM:
      return action.payload.reduce(
        (acc, { id, weight }) => {
          if (acc[id]) {
            acc[id] = { ...acc[id], weight };
          }

          return acc;
        },
        { ...state },
      );
    case SCHEDULE_WEIGHT_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.workingSchedules,
      };
    case SCHEDULE_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.scheduleId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case SCHEDULES_FETCH_SUCCESS:
      return action.response.result;
    case SCHEDULE_CREATE_SUCCESS:
      return [...state, action.response.result];
    case SCHEDULE_DELETE_SUCCESS:
      return [...state.filter(id => id != action.scheduleId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllWorkingSchedules = state => {
  return state.data.workingSchedules.allIds
    .map(id => state.data.workingSchedules.byId[id])
    .sort((a, b) => a.weight - b.weight);
};

export const getWorkingSchedule = (state, id) => {
  return state.data.workingSchedules.byId[id];
};
