import { combineReducers } from "redux";

import {
  PRODREVISIONS_FETCH_SUCCESS,
  PRODREVISION_CREATE_SUCCESS,
  PRODREVISION_DELETE_SUCCESS,
  PRODREVISION_FETCH_SUCCESS,
  PRODREVISION_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case PRODREVISIONS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStockRevisions,
      };
    case PRODREVISION_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStockRevisions,
      };
    case PRODREVISION_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStockRevisions,
      };
    case PRODREVISION_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodStockRevisions,
      };
    case PRODREVISION_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.revisionId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case PRODREVISIONS_FETCH_SUCCESS:
      return action.response.result;
    case PRODREVISION_CREATE_SUCCESS:
      return [...state, action.response.result];
    case PRODREVISION_DELETE_SUCCESS:
      return [...state.filter(id => id != action.revisionId)];
    default:
      return state;
  }
};

export const prodStockRevisions = combineReducers({
  allIds,
  byId,
});

export const getAllProdStockRevisions = state => {
  return state.scenes.prodStockRevision.data.prodStockRevisions.allIds.map(
    id => state.scenes.prodStockRevision.data.prodStockRevisions.byId[id],
  );
};

export const getProdStockRevision = (state, id) => {
  return state.scenes.prodStockRevision.data.prodStockRevisions.byId[id];
};
