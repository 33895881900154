import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Breadcrumb, Layout, Menu } from "antd";
import React from "react";
import { Route, withRouter } from "react-router-dom";

import logo from "../prodex.svg";
import {
  doesCurrentUserHaveRoleForRoute,
  isRouteOrViewDisabledForCurrentProductionProcessType,
} from "../utils";
import isMobile from "../utils/mobileCheck";
import { getFirstLevelFromPath, getStateNameForRoute } from "./SiderUtils";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

class CustomSider extends React.Component {
  state = {
    selectedKeyTopBar: [""],
    openKeys: [],
  };

  componentDidMount() {
    const { location, routes } = this.props;

    let res = getFirstLevelFromPath(location.pathname);
    let stateName = getStateNameForRoute(routes, location.pathname);
    this.setState({ selectedKeyTopBar: [res] });
    this.setState({ openKeys: [stateName] });
  }

  static getDerivedStateFromProps(props, state) {
    const { location, routes } = props;
    const { pathname } = state;
    if (props.location.pathname !== pathname) {
      let res = getFirstLevelFromPath(location.pathname);
      let stateName = getStateNameForRoute(routes, location.pathname);

      return {
        pathname: props.location.pathname,
        selectedKeyTopBar: [res],
        openKeys: [stateName],
      };
    }
    return null;
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  handleTopClick = item => {
    const { onLogoutClick, routes } = this.props;

    if (item.key == "logout") {
      onLogoutClick();
    } else {
      const { history } = this.props;

      let stateName = getStateNameForRoute(routes, item.key);
      this.setState({ openKeys: [stateName] });
      this.setState({ selectedKeyTopBar: [item.key] });
      isMobile() && this.props.collapseClicked(true);
      history.push(item.key);
    }
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  onOpenChange = openKeys => {
    this.setState({
      openKeys: openKeys,
    });
  };

  render() {
    let menuItems = this.props.routes.map(route => {
      if (route.hidden) {
        return null;
      }
      if (route.views) {
        if (!doesCurrentUserHaveRoleForRoute(route, this.props.user)) {
          return null;
        }
        if (
          isRouteOrViewDisabledForCurrentProductionProcessType(
            route,
            this.props.domainSettings,
          )
        ) {
          return null;
        }
        return (
          <SubMenu
            key={route.state}
            title={
              <span>
                <LegacyIcon type={route.icon} />
                <span>{route.name}</span>
              </span>
            }
          >
            {route.views.map(view => {
              if (!doesCurrentUserHaveRoleForRoute(view, this.props.user)) {
                return null;
              }
              if (
                isRouteOrViewDisabledForCurrentProductionProcessType(
                  view,
                  this.props.domainSettings,
                )
              ) {
                return null;
              }
              return <Menu.Item key={view.path}>{view.name}</Menu.Item>;
            })}
          </SubMenu>
        );
      } else {
        if (!doesCurrentUserHaveRoleForRoute(route, this.props.user)) {
          return null;
        }
        if (
          isRouteOrViewDisabledForCurrentProductionProcessType(
            route,
            this.props.domainSettings,
          )
        ) {
          return null;
        }
        return (
          <Menu.Item key={route.path}>
            <LegacyIcon type={route.icon} />
            <span>{route.name}</span>
          </Menu.Item>
        );
      }
    });

    const { openKeys } = this.state;
    const { collapsed } = this.props;

    let props = {};

    if (openKeys && !collapsed) {
      props = {
        openKeys: openKeys,
      };
    }

    return (
      <Sider
        className="customSider"
        width={isMobile() ? "100%" : 256}
        collapsible
        collapsedWidth={isMobile() ? 0 : 80}
        trigger={null}
        collapsed={this.props.collapsed}
        onCollapse={this.onCollapse}
      >
        <div className="logo">
          {!this.props.collapsed && (
            <a href={"javascript:;"}>
              <img className="imgDiv" src={logo} />
              {/*<h1 className="prodexCaption">Prodex</h1>*/}
            </a>
          )}
          {isMobile() && (
            <LegacyIcon
              style={{
                height: "64px",
                padding: "calc((64px - 20px) / 2) 24px",
                fontSize: "20px",
                cursor: "pointer",
                float: "right",
                color: "white",
                transition: "all 0.3s, padding 0s",
              }}
              type={this.props.collapsed ? "menu-unfold" : "menu-fold"}
              onClick={() => this.props.collapseClicked(true)}
            />
          )}
        </div>
        <Menu
          style={{ padding: "16px 0px" }}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          selectedKeys={this.state.selectedKeyTopBar}
          //openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          onClick={this.handleTopClick}
          {...props}
        >
          {menuItems}
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(CustomSider);
