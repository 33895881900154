import { notification } from "antd";
import axios from "axios";

export const UPDATE_DOMAIN_SETTINGS_SUCCESS = "UPDATE_DOMAIN_SETTINGS_SUCCESS ";
export const DOMAIN_SETTINGS_FETCH_SUCCESS = "DOMAIN_SETTINGS_FETCH_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchDomainSettings = () => dispatch =>
  axios
    .get(`/api/domainSettings/withConfigs`)
    .then(({ data }) => {
      dispatch({
        type: DOMAIN_SETTINGS_FETCH_SUCCESS,
        response: data,
      });

      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const updateDomainSettings = settings => {
  return dispatch => {
    return axios
      .post(`/api/domainSettings`, settings)
      .then(({ data }) => {
        dispatch({
          type: UPDATE_DOMAIN_SETTINGS_SUCCESS,
          response: data,
        });

        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
