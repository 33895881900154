import { Breadcrumb, Button, Dropdown, Layout, Menu } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  deleteRevision,
  fetchRevisions,
} from "scenes/FabStockRevision/data/fabStockRevisions/actions";
import { getAllFabStockRevisions } from "scenes/FabStockRevision/data/fabStockRevisions/reducer";

import { fetchDepotLocations } from "../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../data/depotLocations/reducer";
import { fetchTeams } from "../../data/teams/actions";
import FabStockRevisionTable from "./components/FabStockRevisionTable";

function FabStockRevisionMain(props) {
  // Declare a new state variable, which we'll call "count"
  const {
    history,
    fetchRevisions,
    fetchDepotLocations,
    deleteFabStock,
    fabStockRevisions,
  } = props;

  const [loading, setLoading] = useState(() => {
    if (fabStockRevisions.length > 0) {
      return false;
    } else return true;
  });
  const [searchEnabled, setSearchEnabled] = useState(false);
  //const [fabDepotEntries, setFabDepotEntries] = useState(0);
  const [count, setCount] = useState(0);
  const [depotLocationOptions, setDepotLocationOptions] = useState([]);

  const onFabStockCreatte = () => {
    history.push(`/fabstockrevision/new`);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/fabstockrevision/` + contractor.id);
  };

  const onRemove = value => {
    // const {deleteEmployee} =props;
    deleteFabStock(value.id);
  };

  useEffect(() => {
    fetchRevisions().then(depot => {
      setLoading(false);
    });

    fetchDepotLocations().then(options => {
      setDepotLocationOptions(options);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Ревизии материалов</h2>
        <Button type="primary" onClick={onFabStockCreatte}>
          Создать
        </Button>
        &nbsp;
        <Dropdown
          overlay={
            <Menu onClick={() => history.push("/logs/fabStockRevision")}>
              <Menu.Item>Посмотреть в журнале</Menu.Item>
            </Menu>
          }
        >
          <Button style={{ marginLeft: "0.5em" }}>...</Button>
        </Dropdown>
      </div>
      <br />
      <FabStockRevisionTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        fabStockRevisions={fabStockRevisions}
        depotLocationOptions={depotLocationOptions}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    //fabDepotEntries: getAllFabDepotEntries(state),
    depotLocations: getAllDepotLocations(state),
    fabStockRevisions: getAllFabStockRevisions(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchRevisions: () => dispatch(fetchRevisions()),
    // fetchTeams: ()=>dispatch(fetchTeams()),
    deleteRevision: id => dispatch(deleteRevision(id)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabStockRevisionMain),
);

//export default ContractorMain
