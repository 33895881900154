import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";

const period_id = (state = null, action) => {
  switch (action.type) {
    case "SELECT_PERIOD_FOR_EMPLOYEE_STATISTICS":
      return action.period_id;
    default:
      return state;
  }
};

const employee_id = (state = null, action) => {
  switch (action.type) {
    case "SELECT_EMPLOYEE_ID_FOR_STATISTICS":
      return action.employee_id;
    default:
      return state;
  }
};

const employee = (state = null, action) => {
  switch (action.type) {
    case "SELECT_EMPLOYEE_FOR_STATISTICS":
      return action.employee;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  data: dataReducer,
  employee_id,
  period_id,
  employee,
});
