"use strict";

const { object, ref, mixed, array } = require("yup");
const AttendanceReportType = require("../constants/AttendanceReportType");
const { workingScheduleValidator } = require("./workingScheduleValidators");
const stringValidator = require("./stringValidator");
const dateValidator = require("./dateValidator");

const attendanceReportTypeValidator = mixed().oneOf(
  Object.values(AttendanceReportType),
);

const attendanceReportByDateRangeInputValidator = object({
  name: stringValidator({ message: "Неверный формат" }),
  start_date: dateValidator({
    required: true,
    message: "Обязательное поле",
  }),
  end_date: dateValidator({
    required: true,
    min: ref("start_date"),
    message: "Обязательное поле",
  }),
  working_schedule: array()
    .min(1, "Необходимо наличие хотя бы одного правила")
    .ensure()
    .of(workingScheduleValidator),
});

module.exports = {
  attendanceReportTypeValidator,
  attendanceReportByDateRangeInputValidator,
};
