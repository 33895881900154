import { combineReducers } from "redux";

const openedSplitEntryId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_SPLIT_ENTRY":
      return action.splitEntryId;
    default:
      return state;
  }
};

const showSplitModal = (state = false, action) => {
  switch (action.type) {
    case "SET_SHOW_SPLIT_MODAL":
      return action.showSplitModal;

    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedSplitEntryId,
  showSplitModal,
});
