import "./tableStyles.css";
import "./print.css";

import ShipmentStatusTypes from "@app/shared/constants/shipmentStatusTypes";
import moment from "moment";
import React from "react";

const appendCheckSum = (code) => {
    const digits = code.split("").map(c => Number(c));
    const weights = [1,3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];

    const sum = digits.reduce((acc, digit, index) => acc + digit * weights[index], 0);
    const remainder = (sum % 10);
    return code + (remainder > 0 ? 10 - remainder : 0).toString(10);
}

function ShipmentInvoice(props) {
  const { shipment, logoImgPath, empty } = props;

  const totalPrice = shipment
    ? shipment.lineItems.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0,
      )
    : 0;

  const currentBalance = shipment
    ? shipment.client.currentBalance +
      (shipment.shipmentStatusType_id === ShipmentStatusTypes.SHIPPED
        ? totalPrice
        : 0)
    : 0;
  const invoice = shipment ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        className={"invoiceHeader"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <span style={{ fontSize: "20pt", fontFamily: "proxima-nova" }}>
            {" "}
            <strong>Заказ:</strong> {shipment.id} {shipment.client.name}{" "}
          </span>
          <br />
          <span style={{ fontSize: "15pt", fontFamily: "proxima-nova" }}>
            {" "}
            Дата отгрузки: {moment(shipment.shipped_at).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className={"invoiceLogo"}>
          {logoImgPath && (
            <div>
              <img
                // onError={this.addDefaultSrc}
                alt="фото"
                style={{
                  width: "200px",
                  // height: "200px"
                }}
                src={logoImgPath}
              />
            </div>
          )}
        </div>
      </div>
      <div className={"invoiceBody"} style={{ minHeight: "1200px" }}>
        <table style={{ width: "100%", marginTop: "100px" }}>
          <tr>
            <th>Название</th>
            <th>EAN (Фасовка)</th>
            <th>Кол-во</th>
            <th>Цена</th>
            <th>Мешков</th>
            <th>Общая цена</th>
          </tr>
          {shipment.lineItems.map((item, index) => {
              const eanCode = item.product
                  ? item.product.eanCodes
                      ? item.product.eanCodes.find(code => !code.single)
                      : null
                  : null

              return (
                  <tr key={index}>
                      <td>{item.product.name}</td>
                      <td>{eanCode ? appendCheckSum(eanCode.ean13) : ""}</td>
                      <td>{item.quantity}</td>
                      <td>{empty ? "" : item.price}</td>
                      <td>{item.numberOfBags}</td>
                      <td>
                          {empty ? "" : Number((item.price * item.quantity)).toFixed(2)}
                      </td>
                  </tr>
              )
          } )
          }
        </table>
      </div>
      <div
        className={"invoiceFooter"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "14pt",
        }}
      >
        <div style={{ fontFamily: "proxima-nova" }}>
          Принял _________________________
        </div>
        <div
          style={{
            whiteSpace: "pre-line",
            marginRight: "50px",
            fontSize: "14pt",
          }}
        >
          <pre style={{ fontFamily: "proxima-nova" }}>
            Количество мешков:{" "}
            {shipment.lineItems.reduce(
              (acc, item) => acc + item.numberOfBags,
              0,
            )}
            <br />
            Стоимость поставки: {empty ? "" : `$${Number(totalPrice).toFixed(2)}`}
            <br /> {"            "}Старый долг:
            {empty
              ? ""
              : " $" + (Number(currentBalance).toFixed(2) > 0 ? 0 : Number(-currentBalance).toFixed(2))}{" "}
            <br />
          </pre>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div>
      <div id={"toPrint"}>{invoice}</div>
    </div>
  );
}

export default ShipmentInvoice;
