export const selectPeriod = period_id => ({
  type: "SELECT_PERIOD_FOR_EMPLOYEE_STATISTICS",
  period_id,
});

export const selectEmployee = employee => ({
  type: "SELECT_EMPLOYEE_FOR_STATISTICS",
  employee,
});

export const selectEmployeeId = employee_id => ({
  type: "SELECT_EMPLOYEE_ID_FOR_STATISTICS",
  employee_id,
});
