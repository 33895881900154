let nextId = 1;

export const addPattern = (pattern, spreadId) => ({
  type: "PATTERN_ADD",
  pattern,
  patternId: nextId++,
  spreadId,
});

export const savePattern = pattern => ({
  type: "PATTERN_UPDATE",
  pattern,
});

export const deletePattern = (patternId, spreadId) => ({
  type: "PATTERN_DELETE",
  patternId,
  spreadId,
});
