import "./index.css";

import SalaryCalculationTypes from "@app/shared/constants/salaryCalculationTypes";
import Icon, { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Select as SelectAnt,
  Spin,
  Switch,
  message,
} from "antd";
import Select from "components/Select";
import matchSorter from "match-sorter";
import React, { Component } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import ReactTable from "react-table";
import XLSX from "xlsx";

import { fetchEmployeesForPayroll } from "../../../data/employeesPayroll/actions";
import {
  createPayroll,
  fetchPayroll,
  refreshWage,
  togglePayroll,
  updateEntries,
  updatePayroll,
} from "../../../data/payrolls/actions";
import { getPayroll } from "../../../data/payrolls/reducer";
import { getPayrollEntriesByEmployeeId } from "../../../data/payrolls/selectors";
import { fetchPeriods } from "../../../data/periods/actions";
import { fetchTeams } from "../../../data/teams/actions";
import { setOpenedPayrollId } from "../actions";
import { getPayrollRowsForSelectedPayroll } from "../selectors";
import FormValidator from "./FormValidator";

const Option = SelectAnt.Option;

const copySvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="save"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M893.3 293.3L730.7 130.7c-7.5-7.5-16.7-13-26.7-16V112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V338.5c0-17-6.7-33.2-18.7-45.2zM384 184h256v104H384V184zm456 656H184V184h136v136c0 17.7 14.3 32 32 32h320c17.7 0 32-14.3 32-32V205.8l136 136V840zM512 442c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144zm0 224c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80z"></path>
  </svg>
);

const cancelSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="stop"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372 0-89 31.3-170.8 83.5-234.8l523.3 523.3C682.8 852.7 601 884 512 884zm288.5-137.2L277.2 223.5C341.2 171.3 423 140 512 140c205.4 0 372 166.6 372 372 0 89-31.3 170.8-83.5 234.8z"></path>
  </svg>
);

class PayrollDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Введите имя",
      },
      {
        field: "periodField",
        method: () => {
          if (this.state.selectedPeriod) {
            return false;
          } else {
            return true;
          }
          // return true
        },
        validWhen: false,
        message: "Выберите период",
      },
    ]);

    this.state = {
      isInEditState: false,
      name: "",
      showOnly: "active",

      allIds: [],
      payrollEntries: {},
      periodOptions: [],
      periodField: "",
      selectedPeriod: null,
      dataToDownload: [],

      validation: this.validator.valid(),

      formRow: {},

      teamOptions: [],
      isClosed: undefined,

      editRowId: undefined,
      isSavingStateOfPeriod: false,
      isPendingPayroll: true,
    };

    this.interval = null;
    this.selectTable = React.createRef();
    this.csvLink = React.createRef();
    this.submitted = false;
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  handlePeriodChange = period => {
    this.setState({ selectedPeriod: period });
  };

  mapErrorsToObjectMap = error => {
    let subError = new Object();
    for (var key in error.errors) {
      subError[key] = error.errors[key].message;
    }
    return subError;
  };

  createPayrollFromValues = () => {
    //var sizesModel = this.state.selectedSizes.map(size => size.value);
    const { allIds, payrollEntries } = this.state;

    let payrollRows = allIds.map(id => payrollEntries[id]);

    let entries = [];
    payrollRows.forEach(row => {
      let entry = { employeeId: row.id };
    });
    var team = {
      name: this.state.name,
      payrollRows,
    };
    return team;
  };

  handleWageRefresh = () => {
    this.setState({ loading: true });
    const {
      createPayroll,
      match,
      refreshWage,
      fetchEmployeesForPayroll,
    } = this.props;

    if (match.params.id) {
      let refreshPromise = refreshWage(match.params.id).then(() =>
        this.setState({ loading: false }),
      );
      let fetchEmployeesPromise = fetchEmployeesForPayroll();
      Promise.all([refreshPromise, fetchEmployeesPromise]).then(() => {
        this.setState({ loading: false });
      });
    }

    // this.submitted = true;

    // const validation = this.validator.validate(this.state);
    // this.setState({ validation });

    // if (validation.isValid) {
    //     var payroll = this.createPayrollFromValues();
    //     //Handle update
    //     if(match.params.id){
    //         return updatePayroll(match.params.id,payroll)
    //         .then(
    //             (json) => {
    //                 this.setState({isInEditState: false});
    //                 this.props.history.push(`/payroll/` + json.id)
    //             },
    //             (error) => {

    //             }
    //         )
    //     }
    //Handle create
    // else{
    //     return createPayroll(team)
    //     .then(
    //         (json) => {
    //             this.setState({isInEditState: false});
    //             this.props.history.push(`/payroll/` + json.id)
    //         },
    //         (error) => {

    //         }
    //     )
    // }
  };

  componentDidMount() {
    const { match, fetchPayroll } = this.props;

    this.props.fetchPeriods().then(periods => {
      this.setState({
        periodOptions: periods.map(period => {
          return { label: period.name, value: period.id, ...period };
        }),
      });
    });

    this.fetchPayrollOrResetForm();
  }

  removeInterval = () => {
    clearInterval(this.interval);
    this.interval = null;
  };

  setupInterval = () => {
    const { match, fetchPayroll } = this.props;
    this.interval = setInterval(() => {
      fetchPayroll(match.params.id).then(payroll => {
        if (payroll.completed_at !== null) {
          this.removeInterval();
          this.fetchPayrollOrResetForm();
          this.setState({ isPendingPayroll: false });
        }
      });
    }, 2 * 1000);
  };

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      if (this.interval) {
        this.removeInterval();
      }

      this.fetchPayrollOrResetForm();
    }
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      this.removeInterval();
    }
  }

  fetchPayrollOrResetForm = () => {
    const { fetchPayroll, match, setOpenedPayrollId } = this.props;
    setOpenedPayrollId("");
    if (match.params.id) {
      fetchPayroll(match.params.id).then(payroll => {
        if (payroll.completed_at !== null) {
          this.removeInterval();
          this.setState({ isPendingPayroll: false });
        } else {
          this.setupInterval();
        }

        let selectedPeriodVal = null;
        if (payroll && payroll.period) {
          selectedPeriodVal = {
            label: payroll.period.name,
            value: payroll.period.id,
            ...payroll.period,
          };
        }

        setOpenedPayrollId(payroll.id);
        this.setState({
          isInEditState: false,
          name: payroll.name,
          isClosed: payroll.isClosed,
          selectedPeriod: selectedPeriodVal,
          loading: false,
        });
      });

      this.initializeEntryModel();
    } else {
      this.initializeEntryModel();
      this.setState({ isInEditState: true });
    }
  };

  initializeEntryModel = () => {
    const {
      fetchPayroll,
      match,
      setOpenedPayrollId,
      fetchEmployeesForPayroll,
      fetchTeams,
    } = this.props;
    fetchEmployeesForPayroll();

    fetchTeams().then(teams => {
      this.setState({ teamOptions: teams });
    });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handlePlasticSumChange = e => {
    //let entryId=props.original.id;
    this.setState((prevState, props) => {
      let newEntry = { ...prevState.formRow, plastic: e };
      return {
        formRow: newEntry,
      };
    });

    // let entryId=props.original.id;
    // this.setState((prevState, props) => {
    //     let newEntry={...prevState.payrollEntries[entryId], plastic: e};
    //     return {
    //         payrollEntries: {...prevState.payrollEntries, [entryId]:newEntry}
    //     };
    // });
  };

  handleTaxChange = e => {
    this.setState((prevState, props) => {
      let newEntry = { ...prevState.formRow, tax: e };
      return {
        formRow: newEntry,
      };
    });
  };

  handleBonusChange = e => {
    this.setState(prevState => {
      let newEntry = { ...prevState.formRow, bonus: e };
      return {
        formRow: newEntry,
      };
    });
  };

  handleAttendanceChange = e => {
    this.setState(prevState => {
      let newEntry = { ...prevState.formRow, attendance: e };
      return {
        formRow: newEntry,
      };
    });
  };

  handlePowerChange = e => {
    this.setState((prevState, props) => {
      let newEntry = { ...prevState.formRow, electricity: e };
      return {
        formRow: newEntry,
      };
    });
  };

  handleGasChange = e => {
    this.setState((prevState, props) => {
      let newEntry = { ...prevState.formRow, gas: e };
      return {
        formRow: newEntry,
      };
    });
  };

  handleGoodsChange = e => {
    this.setState((prevState, props) => {
      let newEntry = { ...prevState.formRow, goods: e };
      return {
        formRow: newEntry,
      };
    });
  };

  handleAdvanceChange = e => {
    this.setState((prevState, props) => {
      let newEntry = { ...prevState.formRow, advance: e };
      return {
        formRow: newEntry,
      };
    });
  };
  handleCashChange = e => {
    this.setState((prevState, props) => {
      let newEntry = { ...prevState.formRow, cash: e };
      return {
        formRow: newEntry,
      };
    });
  };

  setCellWithValue = (entryId, columnId, value) => {
    //let entryId = props.original.id;
    this.setState((prevState, props) => {
      let newEntry = {
        ...prevState.payrollEntries[entryId],
        [columnId]: value,
      };
      return {
        payrollEntries: { ...prevState.payrollEntries, [entryId]: newEntry },
      };
    });
  };

  renderEditable = props => {
    // console.log("rerender")
    return (
      <div
        key={Math.random()}
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          let entryId = props.original.id;
          let columnId = props.column.id;
          let val = parseInt(e.target.innerHTML.trim());

          //Some performance optimization
          //if (val != this.state.payrollEntries[entryId][columnId]) {
          if (isNaN(val)) {
            message.error("Вы ввели ввели буквы вместо цифры");
            this.setCellWithValue(entryId, columnId, "");
          } else {
            this.setCellWithValue(entryId, columnId, val);
          }
          //}
        }}
        onKeyPress={e => {
          //if(e.key==)
          if (e.key == "Enter") e.preventDefault();
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.payrollEntries[props.original.id][props.column.id],
        }}
      />
    );
  };

  handleEditToggle = props => {
    let row = props.original;

    let form = {
      plastic: row.plastic,
      tax: row.tax,
      electricity: row.electricity,
      bonus: row.bonus,
      bonusId: row.bonusId,
      gas: row.gas,
      goods: row.goods,
      advance: row.advance,
      cash: row.cash,
      wageId: row.wageId,
      plasticId: row.plasticId,
      taxId: row.taxId,
      electricityId: row.electricityId,
      gasId: row.gasId,
      goodsId: row.goodsId,
      advanceId: row.advanceId,
      cashId: row.cashId,
      attendance: row.attendance,
    };

    this.setState({ formRow: form });
    let entryId = props.original.id;
    let columnId = "isEditing";
    //let val = parseInt(e.target.innerHTML.trim());

    this.setState({ editRowId: entryId });

    //this.setCellWithValue(entryId, columnId, true)
  };

  handleRowEditCancel = () => {
    this.setState({ editRowId: undefined });
  };

  handleRowSave = () => {
    const { formRow } = this.state;

    let entriesToUpdate = [];

    entriesToUpdate.push({
      id: formRow.plasticId,
      sum: formRow.plastic,
    });
    entriesToUpdate.push({
      id: formRow.taxId,
      sum: formRow.tax,
    });
    entriesToUpdate.push({
      id: formRow.bonusId,
      sum: formRow.bonus,
    });
    entriesToUpdate.push({
      id: formRow.electricityId,
      sum: formRow.electricity,
    });
    entriesToUpdate.push({
      id: formRow.gasId,
      sum: formRow.gas,
    });
    entriesToUpdate.push({
      id: formRow.goodsId,
      sum: formRow.goods,
    });
    entriesToUpdate.push({
      id: formRow.advanceId,
      sum: formRow.advance,
    });
    // entriesToUpdate.push(
    //     {
    //         id: formRow.cashId,
    //         sum: formRow.cash,
    //     }
    // )

    this.props
      .updateEntries({
        wageId: formRow.wageId,
        cashId: formRow.cashId,
        entries: entriesToUpdate,
        attendance: formRow.attendance,
      })
      .then(data => {
        this.setState({ editRowId: undefined });
      });

    //
  };

  handlePeriodClose = () => {
    const { togglePayroll, match } = this.props;

    if (match.params.id) {
      this.setState({ isSavingStateOfPeriod: true });
      togglePayroll(match.params.id, { toggle: true }).then(
        data => {
          this.setState({ isClosed: 1, isSavingStateOfPeriod: false });
        },
        error => {
          this.setState({ isSavingStateOfPeriod: false });
        },
      );
    }
  };
  handlePeriodOpen = () => {
    const { togglePayroll, match } = this.props;

    if (match.params.id) {
      this.setState({ isSavingStateOfPeriod: true });
      togglePayroll(match.params.id, { toggle: false }).then(
        data => {
          this.setState({ isClosed: 0, isSavingStateOfPeriod: false });
        },
        error => {
          this.setState({ isSavingStateOfPeriod: false });
        },
      );
    }
  };

  download = event => {
    const headers = [
      { label: "Имя", key: "first_name" },
      { label: "Фамилия", key: "last_name" },
      { label: "Бригада", key: "team_name" },
      { label: "Зарплата", key: "wage" },
      { label: "Пластик", key: "plastic" },
      { label: "Налог", key: "tax" },
      { label: "Свет", key: "electricity" },
      { label: "Газ", key: "gas" },
      { label: "Товар", key: "goods" },
      { label: "Аванс", key: "advance" },
      { label: "Бонус", key: "bonus" },
      { label: "Наличные", key: "cash" },
    ];

    if (
      this.props.openedPayroll &&
      this.props.openedPayroll.salaryCalculationType_id ===
        SalaryCalculationTypes.PERIOD_BASED
    ) {
      headers.splice(
        3,
        0,
        {
          label: "Оклад",
          key: "salary",
        },
        {
          label: "Посещаемость",
          key: "attendance",
        },
      );
    }

    let currentRecords = [];
    if (this.selectTable.current) {
      currentRecords = this.selectTable.current.getResolvedState().sortedData;
    }

    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < headers.length; colIndex++) {
        record_to_download[headers[colIndex].label] =
          currentRecords[index][headers[colIndex].key];
      }
      data_to_download.push(record_to_download);
    }
    this.setState({ dataToDownload: data_to_download }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.current.link.click();
    });
  };

  render() {
    const { match, payrollRows, openedPayroll } = this.props;

    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    let { editRowId, formRow, isClosed, teamOptions } = this.state;

    const payrollData = payrollRows.filter(employee => {
      if (this.state.showOnly === "active") {
        return employee.isActive;
      } else if (this.state.showOnly === "non-active") {
        return !employee.isActive;
      }
    });

    let columns = [
      {
        Header: "",
        accessor: "id",
        minWidth: 60,
        Cell: row =>
          this.state.isClosed == 0 &&
          (row.value == editRowId ? (
            <div style={{ textAlign: "center" }}>
              <Icon
                component={copySvg}
                onClick={e => this.handleRowSave(row, e)}
                style={{ marginRight: 8 }}
              />
              <Icon
                component={cancelSvg}
                onClick={e => this.handleRowEditCancel(row, e)}
              />
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <EditOutlined onClick={e => this.handleEditToggle(row, e)} />
            </div>
          )),
        filterable: false,
      },
      {
        Header: "№",
        accessor: "rowNum", // String-based value accessors!
        Cell: row => <span>{row.value}</span>,

        filterable: false,
      },
      {
        Header: "Имя",
        accessor: "first_name", // String-based value accessors!
        Cell: row => <span>{row.value}</span>,
        id: "first_name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["first_name"] }),
        filterAll: true,
      },
      {
        Header: "Фамилия",
        accessor: "last_name", // String-based value accessors!
        Cell: row => <span>{row.value}</span>,
        id: "last_name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["last_name"] }),
        filterAll: true,
      },
      {
        Header: "Бригада",
        accessor: "team_name", // String-based value accessors!
        Cell: row => <span>{row.value}</span>,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <SelectAnt
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={value => onChange(value)}
          >
            <Option key={"all"} value="all">
              Все
            </Option>
            {teamOptions &&
              teamOptions.map(team => (
                <Option key={team.name} value={team.name}>
                  {team.name}
                </Option>
              ))}
          </SelectAnt>
        ),
      },
      {
        Header: "Зарплата",
        accessor: "wage", // String-based value accessors!
        filterable: false,
      },
      {
        Header: "Пластик",
        accessor: "plastic", // String-based value accessors!
        minWidth: 101,
        Cell: row =>
          row.original.id == editRowId ? (
            <InputNumber
              size={"small"}
              value={formRow.plastic}
              onChange={e => this.handlePlasticSumChange(e)}
            />
          ) : (
            <span>{row.value}</span>
          ),
        filterable: false,
      },
      {
        Header: "Налог",
        accessor: "tax", // String-based value accessors!
        minWidth: 101,
        Cell: row =>
          row.original.id == editRowId ? (
            <InputNumber
              size={"small"}
              value={formRow.tax}
              onChange={e => this.handleTaxChange(e)}
            />
          ) : (
            <span>{row.value}</span>
          ),
        filterable: false,
      },
      {
        Header: "Свет",
        accessor: "electricity", // String-based value accessors!
        minWidth: 101,
        Cell: row =>
          row.original.id == editRowId ? (
            <InputNumber
              size={"small"}
              value={formRow.electricity}
              onChange={e => this.handlePowerChange(e)}
            />
          ) : (
            <span>{row.value}</span>
          ),
        filterable: false,
      },
      {
        Header: "Газ",
        accessor: "gas", // String-based value accessors!
        minWidth: 101,
        Cell: row =>
          row.original.id == editRowId ? (
            <InputNumber
              size={"small"}
              value={formRow.gas}
              onChange={e => this.handleGasChange(e)}
            />
          ) : (
            <span>{row.value}</span>
          ),
        filterable: false,
      },
      {
        Header: "Махсулот",
        accessor: "goods", // String-based value accessors!
        minWidth: 101,
        Cell: row =>
          row.original.id == editRowId ? (
            <InputNumber
              size={"small"}
              value={formRow.goods}
              onChange={e => this.handleGoodsChange(e)}
            />
          ) : (
            <span>{row.value}</span>
          ),
        filterable: false,
      },
      {
        Header: "Аванс",
        accessor: "advance", // String-based value accessors!
        minWidth: 101,
        Cell: row =>
          row.original.id == editRowId ? (
            <InputNumber
              size={"small"}
              value={formRow.advance}
              onChange={e => this.handleAdvanceChange(e)}
            />
          ) : (
            <span>{row.value}</span>
          ),
        filterable: false,
      },
      {
        Header: "Бонус",
        accessor: "bonus",
        minWidth: 101,
        Cell: row =>
          row.original.id == editRowId ? (
            <InputNumber
              size={"small"}
              value={formRow.bonus}
              onChange={e => this.handleBonusChange(e)}
            />
          ) : (
            <span>{row.value}</span>
          ),
        filterable: false,
      },
      {
        Header: "Наличные",
        accessor: "cash", // String-based value accessors!
        Cell: row => (
          // row => row.original.id==editRowId? (
          //     <span>
          //        <InputNumber value={formRow.cash} onChange={(e)=>this.handleCashChange(e)}/>
          //     </span>
          // ):
          <span>{row.value}</span>
        ),
        filterable: false,
      },
    ];

    if (
      openedPayroll &&
      openedPayroll.salaryCalculationType_id ===
        SalaryCalculationTypes.PERIOD_BASED
    ) {
      columns.splice(
        5,
        0,
        {
          Header: "Оклад",
          accessor: "salary",
          filterable: false,
        },
        {
          Header: "Посещаемость",
          accessor: "attendance",
          minWidth: 101,
          Cell: row =>
            row.original.id === editRowId ? (
              <InputNumber
                size={"small"}
                value={formRow.attendance}
                onChange={e => this.handleAttendanceChange(e)}
                precision={0}
              />
            ) : (
              <span>{row.value}</span>
            ),
          filterable: false,
        },
      );
    }

    const generateExcelTable = async () => {
      const headers = columns
        .filter(column => !!column.Header)
        .map(column => column.Header);

      const data = payrollData.map(row => [
        ...columns
          .filter(column => !!column.Header)
          .map(column => row[column.accessor]),
      ]);

      data.unshift(headers);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Ведомость");

      XLSX.writeFile(workbook, "Ведомость.xlsx");
    };

    return (
      <div className="mt-content">
        <Spin spinning={this.state.isPendingPayroll}>
          <div id="section-to-print">
            <div>
              <h2>Ведомость</h2>
            </div>
            {this.state.isClosed == 0 && (
              <Button
                type="primary"
                onClick={this.handlePeriodClose}
                loading={this.state.isSavingStateOfPeriod}
              >
                Закрыть период
              </Button>
            )}
            {this.state.isClosed == 1 && (
              <Button
                type="secondary"
                onClick={this.handlePeriodOpen}
                loading={this.state.isSavingStateOfPeriod}
              >
                Открыть период
              </Button>
            )}
            &nbsp;
            {this.state.isClosed == 0 && (
              <Button
                type="secondary"
                onClick={this.handleWageRefresh}
                loading={this.state.loading}
              >
                Обновить зарплату
              </Button>
            )}
            {/* {(match.params.id && !this.state.isInEditState) && <Button onClick={this.toggleEditState}>Изменить</Button>} */}
            <br />
            &nbsp;
            {/* <TeamForm enableReinitialize initialValues={openedTeam} onSubmit={this.handleSubmit} disabled={!this.state.isInEditState} /> */}
            <div
              style={{ marginBottom: 15 }}
              className={validation.name.isInvalid ? "has-error" : ""}
            >
              <label className="mt-label" htmlFor="name">
                Название ведомости
              </label>
              <Input
                name="name"
                placeholder="Название"
                //onChange={this.handleInputChange}
                value={this.state.name}
                onChange={this.handleNameChange}
                disabled={!this.state.isInEditState}
              />
              <span className="help-block">{validation.name.message}</span>
            </div>
            <div style={{ marginBottom: 15 }}>
              <label className="mt-label" htmlFor="period">
                Период
              </label>
              <Select
                id="period"
                isDisabled
                options={this.state.periodOptions}
                searchable={true}
                value={this.state.selectedPeriod}
                onChange={this.handlePeriodChange}
              />
              <span className="help-block">
                {validation.periodField.message}
              </span>
            </div>
            <br />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={this.download}>CSV</Menu.Item>
                  <Menu.Item onClick={generateExcelTable}>Excel</Menu.Item>
                </Menu>
              }
            >
              <Button onClick={this.download}>Скачать</Button>
            </Dropdown>
            <CSVLink
              style={{ display: "none" }}
              data={this.state.dataToDownload}
              filename={
                this.state.selectedPeriod
                  ? this.state.selectedPeriod.label + ".csv"
                  : "ведомость.csv"
              }
              ref={this.csvLink}
            >
              Скачать
            </CSVLink>
            <span style={{ float: "right" }}>
              <SelectAnt
                value={this.state.showOnly}
                onChange={value => this.setState({ showOnly: value })}
              >
                <SelectAnt.Option value={"active"} key={1}>
                  Активные
                </SelectAnt.Option>
                <SelectAnt.Option value={"non-active"} key={2}>
                  Неактивные
                </SelectAnt.Option>
              </SelectAnt>
            </span>
            <br />
            &nbsp;
            <Spin spinning={this.state.loading}>
              <ReactTable
                className="-striped -highlight"
                data={payrollData}
                columns={columns}
                previousText="Пред"
                nextText="След"
                loadingText="Загрузка..."
                noDataText="Нет записей"
                pageText="Страница"
                ofText="из"
                ref={this.selectTable}
                //minRows={100}
                //defaultPageSize={50}
                //showPagination={false}
                defaultPageSize={100}
                rowsText="строк"
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                //pageSize={payrollRows.length}
              />
            </Spin>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    openedPayroll: getPayroll(state, state.scenes.payroll.openedPayrollId),
    payrollRows: getPayrollRowsForSelectedPayroll(state),
    payrollEntriesByEmployeeId: getPayrollEntriesByEmployeeId(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchPayroll: id => dispatch(fetchPayroll(id)),
    fetchPeriods: () => dispatch(fetchPeriods()),
    fetchTeams: () => dispatch(fetchTeams()),
    fetchEmployeesForPayroll: () => dispatch(fetchEmployeesForPayroll()),
    setOpenedPayrollId: id => dispatch(setOpenedPayrollId(id)),
    createPayroll: payroll => dispatch(createPayroll(payroll)),
    updateEntries: entries => dispatch(updateEntries(entries)),
    refreshWage: payrollId => dispatch(refreshWage(payrollId)),
    updatePayroll: (id, payroll) => dispatch(updatePayroll(id, payroll)),
    togglePayroll: (id, toggle) => dispatch(togglePayroll(id, toggle)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayrollDetail),
);
