"use strict";

module.exports = [
  {
    tableName: "Product",
    keyOfTableName: "productTableName",
    titleMeta: "Таблица продукции",
    metaAndKeys: [
      { label: "Название модели", propertyName: "name" },
      { label: "Цена", propertyName: "price" },
      { label: "Артикуль", propertyName: "article" },
      { label: "Описание", propertyName: "description" },
      { label: "Изображение", propertyName: "image" },
      { label: "Размеры", propertyName: "sizes" },
      { label: "Удалена", propertyName: "deleted" },
    ],
  },
  {
    tableName: "Employee",
    keyOfTableName: "employeeTableName",
    titleMeta: "Таблица сотрудников",
    metaAndKeys: [
      { label: "Имя", propertyName: "first_name" },
      { label: "Фамилия", propertyName: "last_name" },
      { label: "Отчество", propertyName: "patronymic" },
      { label: "Бригада", propertyName: "team" },
      { label: "Активен", propertyName: "isActive" },
      { label: "Удалена", propertyName: "deleted" },
      { label: "Дата начала работы", propertyName: "hireDate" },
    ],
  },
];
