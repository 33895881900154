import AttendanceReportType from "@app/shared/constants/AttendanceReportType";
import { Breadcrumb, Button, Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { fetchAttendanceReports } from "../../data/attendanceReports/actions";
import { deletePayroll, fetchPayrolls } from "../../data/payrolls/actions";
import { getAllPayrolls } from "../../data/payrolls/reducer";
import { setShowPayrollCreateModal } from "./actions";
import PayrollCreateModal from "./components/PayrollCreateModal";
import PayrollTable from "./components/PayrollTable";

class PayrollMain extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      payrollKey: 0,
    };
  }

  pollingCheck = () => {
    if (this.interval === null) {
      this.interval = setInterval(
        () =>
          this.props.fetchPayrolls().then(payrolls => {
            const hasPending =
              payrolls &&
              payrolls.some(payroll => payroll.completed_at === null);
            if (hasPending === false) {
              clearInterval(this.interval);
              this.interval = null;
            }
          }),
        2 * 1000,
      );
    }
  };

  componentDidMount() {
    const { fetchPayrolls } = this.props;
    fetchPayrolls().then(payrolls => {
      this.pollingCheck();
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onPeriodCreate = () => {
    const {
      history,
      payrolls,
      deletePayroll,
      setShowPayrollCreateModal,
    } = this.props;

    this.setState((prevState, props) => {
      return { payrollKey: prevState.payrollKey + 1 };
    });
    //history.push(`/payroll/new`)
    setShowPayrollCreateModal(true);
  };

  render() {
    const {
      history,
      payrolls,
      deletePayroll,
      setShowPayrollCreateModal,
    } = this.props;

    const handleEditClick = contractor => {
      //console.log(id);
      history.push(`/payroll/` + contractor.id);
    };

    const onRemove = value => {
      deletePayroll(value.id);
      //history.push(`/product-admin/`)
    };

    return (
      <div>
        <div>
          <h2>Ведомости</h2>
          <Button type="primary" onClick={this.onPeriodCreate}>
            Создать
          </Button>
        </div>
        <br />
        <PayrollTable
          onEditClick={handleEditClick}
          onRemove={onRemove}
          payrolls={payrolls}
        />
        <PayrollCreateModal
          onCreated={this.pollingCheck}
          key={this.state.payrollKey}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    payrolls: getAllPayrolls(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchPayrolls: () => dispatch(fetchPayrolls()),
    deletePayroll: id => dispatch(deletePayroll(id)),

    setShowPayrollCreateModal: show =>
      dispatch(setShowPayrollCreateModal(show)),
    //setOpenedContractorId: (contractorId)=>dispatch(setOpenedContractorId(contractorId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PayrollMain),
);

//export default ContractorMain
