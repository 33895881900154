import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const SIZES_FETCH_SUCCESS = "SIZES_FETCH_SUCCESS";
export const SIZE_CREATE_SUCCESS = "SIZE_CREATE_SUCCESS";
export const SIZE_UPDATE_SUCCESS = "SIZE_UPDATE_SUCCESS";
export const SIZE_DELETE_SUCCESS = "SIZE_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchSizes = () => {
  return dispatch => {
    return axios
      .get(`/api/sizes/`)
      .then(({ data }) => {
        let sizes = data;
        //dispatch({ type: 'PRODUCTS_FETCH_DATA_SUCCESS', products })

        //console.log("NORMALIZED", normalize(products, schema.arrayOfProducts));
        dispatch({
          type: SIZES_FETCH_SUCCESS,
          response: normalize(sizes, schema.arrayOfSizes),
        });

        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createSize = size => {
  return dispatch => {
    return axios
      .post("/api/sizes/", size)
      .then(({ data }) => {
        // Status looks good
        let size = data;
        dispatch({
          type: SIZE_CREATE_SUCCESS,
          response: normalize(size, schema.size),
        });
        openNotificationWithIcon(
          "Успешно",
          "Размер успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такой размер уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteSize = sizeId => {
  return dispatch => {
    return axios
      .delete("/api/sizes/" + sizeId)
      .then(({ data }) => {
        let product = data;
        dispatch({ type: SIZE_DELETE_SUCCESS, sizeId });
        openNotificationWithIcon("Успешно", "Размер успешно удален", "success");
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данный размер используется в деталях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
          //console.log("Parsing eror: Most likely server crashed");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
