import produce from "immer";
import { combineReducers } from "redux";

import {
  PASSPORT_ENTRY_CREATE_SUCCESS,
  PASSPORT_ENTRY_UPDATE_SUCCESS,
  SEWING_PASSPORT_ENTRY_CREATE_SUCCESS,
  SEWING_PASSPORT_ENTRY_UPDATE_SUCCESS,
  CALCULATE_REAL_PRICE
} from "../passportEntries/actions";
import {
  ORDERS_FETCH_SUCCESS,
  ORDER_COMPLETE_SUCCESS,
  ORDER_COMPLETE_VALIDATION_ERROR,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_SUCCESS,
  ORDER_DISCARD_SUCCESS,
  ORDER_FETCH_SUCCESS,
  ORDER_SET_CURRENT_PAGE,
  ORDER_SET_PAGE_SIZE,
  ORDER_UPDATE_SUCCESS, ORDER_UPDATE_STATUS_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.orders,
      };
    case SEWING_PASSPORT_ENTRY_CREATE_SUCCESS:
      return {
        ...state,
        [action.passportEntry.order_id]: {
          ...state[action.passportEntry.order_id],
          sewingPassportEntry: action.passportEntry,
        },
      };
    case ORDER_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        [action.orderId]: {
          ...state[action.orderId],
          orderStatus_id: action.statusId
        }
      }
    case SEWING_PASSPORT_ENTRY_UPDATE_SUCCESS:
      return {
        ...state,
        [action.passportEntry.order_id]: {
          ...state[action.passportEntry.order_id],
          sewingPassportEntry: action.passportEntry,
        },
      };
    case PASSPORT_ENTRY_CREATE_SUCCESS:
      return {
        ...state,
        [action.passportEntry.order_id]: {
          ...state[action.passportEntry.order_id],
          cuttingPassportEntry: action.passportEntry,
        },
      };
    case PASSPORT_ENTRY_UPDATE_SUCCESS:
      return {
        ...state,
        [action.passportEntry.order_id]: {
          ...state[action.passportEntry.order_id],
          cuttingPassportEntry: action.passportEntry,
        },
      };
    case CALCULATE_REAL_PRICE:
      return {
        ...state,
        [action.orderId]: {
            ...state[action.orderId],
            cuttingPassportEntry: {
              ...state[action.orderId].cuttingPassportEntry,
              realPrice: action.value
            }
        }
      }
    case ORDER_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.orders,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.orders,
      };
    case ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.orders,
      };
    case ORDER_COMPLETE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.orders,
      };
    case ORDER_DISCARD_SUCCESS:
      return {
        ...state,
        ...action.response.entities.orders,
      };
    default:
      return state;
  }
};

const quantityByOrderStatus = (state = {}, action) => {
    switch (action.type) {
      case ORDERS_FETCH_SUCCESS: {
          return action.quantityByOrderStatus
      }
      default:
        return state
    }
}

const page = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case ORDERS_FETCH_SUCCESS:
        draft[action.page] = action.response.result;
        return;

      case ORDER_DELETE_SUCCESS:
        for (let key of draft) {
          // check if the property/key is defined in the object itself, not in parent
          if (draft.hasOwnProperty(key)) {
            let foundId = draft[key].find(id => id == action.orderId);
            if (foundId) draft[key].splice(draft[key].indexOf(foundId), 1);
          }
        }
        return;

      default:
        return;
    }
  });

const currentPage = (state = 0, action) => {
  switch (action.type) {
    case ORDER_SET_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const pageSize = (state = 50, action) => {
  switch (action.type) {
    case ORDER_SET_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
};

const orderCompletionServerErrors = (state = [], action) => {
  switch (action.type) {
    case ORDER_COMPLETE_VALIDATION_ERROR:
      return action.response.errors;
    case "SHOW_COMPLETION_MODAL":
      return [];
    case "SHOW_COMPLETION_MODAL_FOR_CUTTING":
      return [];
    case ORDER_COMPLETE_SUCCESS:
      return [];
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case ORDERS_FETCH_SUCCESS:
      return action.response.result;
    case ORDER_CREATE_SUCCESS:
      return [...state, action.response.result];
    case ORDER_DELETE_SUCCESS:
      let nextState = [...state];
      let foundId = nextState.find(id => id === action.orderId);
      if (foundId) nextState.splice(nextState.indexOf(foundId), 1);
      return nextState;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
  page,
  pageSize,
  currentPage,
  orderCompletionServerErrors,
  quantityByOrderStatus
});

export const getAllOrders = state => {
  let orders = state.data.orders;
  return orders.allIds.map(id => orders.byId[id]);
};

export const getOrder = (state, id) => {
  let orders = state.data.orders;
  return orders.byId[id];
};
