//import "react-table/react-table.css";

import "./index.css";

import { Button } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

//import { Panel } from 'react-bootstrap';

class CuttingSummaryTable extends Component {
  constructor(props) {
    super(props);
  }

  makeOrderedRow() {
    const { lineItems } = this.props;
    let row = {
      status_name: " (Заказано)",
    };

    lineItems.forEach((item, index) => {
      row[index] = item.quantity;
    });
    return row;
  }

  makeWillCutRow(pattern) {
    const { lineItems } = this.props;
    let row = {
      status_name: pattern.name + " (Будет раскроено)",
    };

    lineItems.forEach((item, index) => {
      row[index] = item.quantity;
    });
    return row;
  }

  makeEmptryRow(pattern) {
    const { lineItems } = this.props;
    let row = {
      status_name: " - ",
    };

    lineItems.forEach((item, index) => {
      row[index] = "";
    });
    return row;
  }

  makeAlreadyCutRow() {
    const { lineItems, cuttingEntries, prodSpreadId } = this.props;
    let summaryWeight = cuttingEntries.reduce((acc, x) => acc + x.weight, 0);

    let row = {
      status_name: " (Раскроено) - " + summaryWeight + " кг",
    };

    let lineItemSumBySizeId = {};

    //set all zeros for all sums first
    lineItems.forEach(lineItem => {
      lineItemSumBySizeId[lineItem.size.id] = 0;
    });

    let filteredEntries = cuttingEntries.filter(
      entry => entry.prodSpread_id == prodSpreadId,
    );
    if (filteredEntries) {
      filteredEntries.forEach(cuttingEntry => {
        cuttingEntry.lineItems.forEach(lineItem => {
          if (lineItemSumBySizeId[lineItem.size.id] != undefined) {
            lineItemSumBySizeId[lineItem.size.id] += lineItem.quantity;
          }
        });
      });
    }

    lineItems.forEach((item, index) => {
      row[index] = lineItemSumBySizeId[item.size.id];
    });

    return row;
  }

  makeCuttingItemsByPatternId(cuttingEntries) {
    let cuttingItemsByProdPatternId = {};

    if (cuttingEntries.length > 0) {
      cuttingEntries.forEach(cuttingEntry => {
        cuttingEntry.cuttingItems.forEach(cuttingItem => {
          if (cuttingItemsByProdPatternId[cuttingItem.prodPattern_id]) {
            cuttingItemsByProdPatternId[cuttingItem.prodPattern_id] = [
              ...cuttingItemsByProdPatternId[cuttingItem.prodPattern_id],
              cuttingItem,
            ];
          } else {
            cuttingItemsByProdPatternId[cuttingItem.prodPattern_id] = [
              cuttingItem,
            ];
          }
        });
      });
    }
    return cuttingItemsByProdPatternId;
  }

  makeData() {
    let data = [];
    const {
      disabled,
      patterns,
      onPatternDoubleClick,
      cuttingEntries,
      lineItems,
    } = this.props;

    patterns.forEach((pattern, index) => {
      // let orderedRow=this.makeOrderedRow(pattern);
      // data.push(orderedRow);
      // let willCutRow=this.makeWillCutRow(pattern);
      // data.push(willCutRow);
      // let alreadyCutRow=this.makeAlreadyCutRow(pattern);
      // data.push(alreadyCutRow);
      // if (index < patterns.length-1) {
      //     let emptyRow = this.makeEmptryRow(pattern);
      //     data.push(emptyRow);
      // }
    });

    let orderedRow = this.makeOrderedRow();
    data.push(orderedRow);

    let alreadyCutRow = this.makeAlreadyCutRow();
    data.push(alreadyCutRow);

    return data;
  }

  calculateTotalForRow(row) {
    const { lineItems } = this.props;

    //console.log("ROW ROW ", row);
    if (row.pattern_name == " - ") {
      return "";
    }
    let total = 0;
    lineItems.forEach((item, index) => {
      total += row[index];
    });
    return total;
  }

  render() {
    const {
      disabled,
      patterns,
      onPatternDoubleClick,
      cuttingEntries,
      lineItems,
      onViewRecordsClick,
    } = this.props;

    // const data = [{
    //     name: 'Tanner Linsley',
    //     age: 26,
    //     friend: {
    //         name: 'Jason Maurer',
    //         age: 23,
    //     }
    // }]
    let data = this.makeData();

    const columns = [
      {
        Header: "",
        accessor: "status_name", // String-based value accessors!
        minWidth: 200,
        Footer: (
          <span>
            <Button type="secondary" onClick={onViewRecordsClick}>
              Просмотреть записи
            </Button>
          </span>
        ),
        // Cell: props => <span>{new moment(props.value).format('L')}</span> // Custom cell components!
      },
    ];

    lineItems.forEach((item, index) => {
      let col = {
        Header: item.size.name,
        accessor: index + "",
        // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
      };
      columns.push(col);
    });

    let totalCol = {
      Header: "Итого",
      // accessor: "name",
      Cell: row => (
        <span className="number">
          {this.calculateTotalForRow(row.original)}
        </span>
      ), // Custom cell components!
    };
    columns.push(totalCol);

    return (
      <div className="summaryTable">
        <ReactTable
          data={data}
          columns={columns}
          className="-striped -highlight"
          showPagination={false}
          sortable={false}
          defaultPageSize={data.length}
        />
      </div>
    );
  }
}

export default CuttingSummaryTable;
