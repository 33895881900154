export const resetSpecForm = () => ({
  type: "RESET_SPEC_FORM",
});

export const setSpecName = name => ({
  type: "SET_SPEC_NAME",
  name,
});

export const setSelectedSpread = spreadId => ({
  type: "SET_SELECTED_SPREAD",
  spreadId,
});

export const setSelectedSpreadTab = (bomId, selectedSpreadId) => ({
  type: "SET_SELECTED_SPREAD_TAB",
  bomId,
  selectedSpreadId,
});

export const setSelectedBom = bomId => ({
  type: "SET_SELECTED_BOM",
  bomId,
});

export const setOpenedBom = bomId => ({
  type: "SET_OPENED_BOM",
  bomId,
});

export const setOpenedSpread = spreadId => ({
  type: "SET_OPENED_SPREAD",
  spreadId,
});

export const setOpenedProduct = productId => ({
  type: "SET_OPENED_PRODUCT",
  productId,
});

export const setOpenedPattern = patternId => ({
  type: "SET_OPENED_PATTERN",
  patternId,
});

export const setShowSpreadModal = showSpreadModal => ({
  type: "SET_SHOW_SPREAD_MODAL",
  showSpreadModal,
});

export const setShowPatternModal = showPatternModal => ({
  type: "SET_SHOW_PATTERN_MODAL",
  showPatternModal,
});

export const setShowBomModal = showBomModal => ({
  type: "SET_SHOW_BOM_MODAL",
  showBomModal,
});
