import { combineReducers } from "redux";

import {
  WAGE_ENTRIES_FETCH_SUCCESS,
  WAGE_ENTRY_CLAIM_MANUALLY_SUCCESS,
  WAGE_ENTRY_CLAIM_SUCCESS,
  WAGE_ENTRY_CREATE_SUCCESS,
  WAGE_ENTRY_DELETE_SUCCESS,
  WAGE_ENTRY_FETCH_SUCCESS,
  WAGE_ENTRY_UNCLAIM_MANUALLY_SUCCESS,
  WAGE_ENTRY_UNCLAIM_SUCCESS,
  WAGE_ENTRY_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case WAGE_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_CLAIM_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_UNCLAIM_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_CLAIM_MANUALLY_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_UNCLAIM_MANUALLY_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.wages,
      };
    case WAGE_ENTRY_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.wageId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

// const allIds = (state = [], action) => {
//     switch (action.type) {
//         case 'CUTTING_ENTRIES_FETCH_DATA_SUCCESS':
//             return action.response.result
//         case 'CUTTING_ENTRY_CREATE_SUCCESS':
//             return [...state, action.response.result]
//         case 'CUTTING_ENTRY_DELETE_SUCCESS':
//             return [...state.filter(id=>id!=action.cuttingEntryId)]
//         default:
//             return state;
//     }
// }

export const reducer = combineReducers({
  byId,
});

//SELECTORS
