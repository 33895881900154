import tables from "@app/shared/constants/airTableSchema";
import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";
import { Button, Card, Col, Divider, Row, Select, Spin, Switch, Input } from "antd";
import {
  fetchDomainSettings,
  updateDomainSettings,
} from "data/domainSettings/actions";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import AirTableConfiguration from "./components/AirTableConfiguration";
import LogoUpload from "./components/LogoUpload";

const Option = Select.Option;

function DepotLocationMain(props) {
  const { updateDomainSettings, domainSettings, fetchDomainSettings } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isFetchingConfigs, setIsFetchingConfigs] = useState(true);
  const [productionProcessTypeValue, setProductionProcessTypeValue] = useState(
    null,
  );
  const [airTableSynchronization, setAirTableSynchronization] = useState({
    enabled: domainSettings.find(setting => setting.id === 2).value === "true",
  });
  const [logoPath, setLogoPath] = useState("");
  const [errorLogs, setErrorLogs] = useState([]);
  const [eanPrefix, setEanPrefix] = useState("");

  const isValidAirTableConfig = () => {
    for (const table of Object.keys(airTableSynchronization.config.tables)) {
      const used = new Map();
      for (const propertyName of Object.keys(
        airTableSynchronization.config.tables[table],
      )) {
        if (!airTableSynchronization.config.tables[table][propertyName]) {
          return false;
        } else {
          const value =
            airTableSynchronization.config.tables[table][propertyName];
          if (used.get(value)) {
            return false;
          } else used.set(value, true);
        }
      }
    }
    return true;
  };

  useEffect(() => {
    if (domainSettings) {
      setProductionProcessTypeValue(
        domainSettings.find(setting => setting.id === 1).value,
      );
    }
  }, [domainSettings]);

  const constructTablesConfig = fetchedAirTableConfig => {
    const result = {};
    for (const table of tables) {
      const properties = {};
      for (const metaAndKey of table.metaAndKeys) {
        properties[metaAndKey.propertyName] = fetchedAirTableConfig.find(
          property => property.key === metaAndKey.propertyName,
        ).value;
      }

      properties[table.keyOfTableName] = fetchedAirTableConfig.find(
        property => property.key === table.keyOfTableName,
      ).value;

      result[table.tableName] = properties;
    }

    return result;
  };

  useEffect(() => {
    setIsFetchingConfigs(true);
    fetchDomainSettings().then(
      data => {
        setIsFetchingConfigs(false);
        const filePath = data.find(setting => setting.id === 3).value;
        setLogoPath(filePath);

        const fetchedEanPrefix = data.find(setting => setting.id === 4).value
        setEanPrefix(fetchedEanPrefix);

        const fetchedAirTableConfig = data.find(setting => setting.id === 2)
          .config;
        setAirTableSynchronization({
          ...airTableSynchronization,
          config: {
            apiKey: fetchedAirTableConfig.find(
              property => property.key === "apiKey",
            ).value,
            base: fetchedAirTableConfig.find(
              property => property.key === "base",
            ).value,
            tables: constructTablesConfig(fetchedAirTableConfig),
          },
        });
        setErrorLogs(data.find(setting => setting.id === 2).errorLogs);
      },
      () => setIsFetchingConfigs(false),
    );
  }, []);

  const getEntityLink = log => {
    let link = "",
      content = "";
    if (log.airTableWorkerEntityType_id === 1) {
      link = `/product-admin/${log.entity_id}`;
      content = `PR-${log.entity_id}`;
    }
    if (log.airTableWorkerEntityType_id === 2) {
      link = `/employee/${log.entity_id}`;
      content = `EMP-${log.entity_id}`;
    }

    return (
      <div onClick={() => props.history.push(link)}>
        <a>{content}</a>
      </div>
    );
  };

  const handleChangeTableConfig = (table, propertyName, value) => {
    setAirTableSynchronization({
      ...airTableSynchronization,
      config: {
        ...airTableSynchronization.config,
        tables: {
          ...airTableSynchronization.config.tables,
          [table]: {
            ...airTableSynchronization.config.tables[table],
            [propertyName]: value.trim(),
          },
        },
      },
    });
  };

  const handleChangeBase = value => {
    setAirTableSynchronization({
      ...airTableSynchronization,
      config: {
        ...airTableSynchronization.config,
        base: value,
      },
    });
  };

  const handleChangeApiKeyConfig = value => {
    setAirTableSynchronization({
      ...airTableSynchronization,
      config: {
        ...airTableSynchronization.config,
        apiKey: value,
      },
    });
  };

  const handleSave = () => {
    setIsSaving(true);
    updateDomainSettings([
      {
        id: 1,
        key: "productionProcessType",
        value: productionProcessTypeValue,
      },
      {
        id: 2,
        key: "enableAirTableSynchronization",
        value: airTableSynchronization.enabled,
        config: airTableSynchronization.config,
      },
      {
        id: 3,
        key: "logoPath",
        value: logoPath,
      },
      {
        id: 4,
        key: "eanPrefix",
        value: eanPrefix,
      },
    ]).then(
      () => {
        setIsSaving(false);
        setIsInEditMode(false);
      },
      () => setIsSaving(false),
    );
  };

  const handleEanPrefixChange=(e)=>{
    //console.log("value is this ", e.target.value)
    setEanPrefix(e.target.value)
  }

  return (
    <Spin spinning={!domainSettings || isFetchingConfigs}>
      <div>
        <Row>
          <Col span={4}>
            <h2>Общие настройки</h2>
          </Col>
          <Col span={20}>
            {!isInEditMode && (
              <Button
                style={{ float: "right" }}
                onClick={() => setIsInEditMode(true)}
              >
                Изменить
              </Button>
            )}
            {isInEditMode && (
              <Button
                style={{ float: "right" }}
                type={"primary"}
                loading={isSaving}
                disabled={
                  airTableSynchronization.enabled && !isValidAirTableConfig()
                }
                onClick={handleSave}
              >
                Сохранить
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        <Row style={{ verticalAlign: "middle" }}>
          <Col span={12}>
            <div
              style={{
                height: "30px",
                verticalAlign: "middle",
                display: "table-cell",
              }}
            >
              Тип процесса производства
            </div>
          </Col>
          <Col span={12}>
            <Select
              style={{
                float: "right",
              }}
              disabled={!isInEditMode}
              value={productionProcessTypeValue}
              onChange={value => {
                setProductionProcessTypeValue(value);
              }}
            >
              <Option value={ProductionProcessTypes.name.BARCODE}>
                По штрих кодам
              </Option>
              <Option value={ProductionProcessTypes.name.PASSPORT}>
                По паспортам
              </Option>
              <Option
                value={ProductionProcessTypes.name.PASSPORT_WITH_FIXED_SALARY}
              >
                По окладам
              </Option>
            </Select>
          </Col>
        </Row>
        <Divider/>

        {/** EAN PREFIX START */}
        <Row style={{ verticalAlign: "middle" }}>
          <Col span={12}>
            <div
              style={{
                height: "30px",
                verticalAlign: "middle",
                display: "table-cell",
              }}
            >
              EAN prefix
            </div>
          </Col>
          <Col span={12}>
          <Input maxLength={7} disabled={!isInEditMode} placeholder="EAN13 Prefix" value={eanPrefix} onChange={handleEanPrefixChange} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <div
              style={{
                height: "30px",
                verticalAlign: "middle",
                display: "table-cell",
              }}
            >
              Включить синхронизацию с AirTable
            </div>
          </Col>
          <Col span={12}>
            <Switch
              disabled={!isInEditMode}
              style={{ float: "right" }}
              checked={airTableSynchronization.enabled}
              onChange={() => {
                if (!airTableSynchronization.enabled) {
                  setAirTableSynchronization({
                    ...airTableSynchronization,
                    enabled: true,
                  });
                } else {
                  setAirTableSynchronization({
                    ...airTableSynchronization,
                    enabled: false,
                  });
                }
              }}
            />
          </Col>
        </Row>
        <br />
        {airTableSynchronization.enabled && airTableSynchronization.config && (
          <div>
            {errorLogs.length > 0 && (
              <div>
                <Card title={"Ошибки"} size={"small"}>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <th>Entity</th>
                      <th>Error</th>
                      <th>Message</th>
                      <th>StatusCode</th>
                    </tr>
                    {errorLogs.map(log => (
                      <tr key={log.id}>
                        <th>{getEntityLink(log)}</th>
                        <th>{log.error}</th>
                        <th>{log.message}</th>
                        <th>{log.statusCode}</th>
                      </tr>
                    ))}
                  </table>
                </Card>
              </div>
            )}
            <AirTableConfiguration
              initialConfig={airTableSynchronization.config}
              handleChangeTableConfig={handleChangeTableConfig}
              isInEditMode={isInEditMode}
              values={airTableSynchronization.config}
              handleChangeApiKeyConfig={handleChangeApiKeyConfig}
              handleChangeBase={handleChangeBase}
            />
          </div>
        )}
        <Divider />
        <div>
          <LogoUpload
            disabled={!isInEditMode}
            filePath={logoPath}
            setLogoPath={setLogoPath}
          />
        </div>
        <Divider />
      </div>
    </Spin>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    domainSettings: state.domainSettings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDomainSettings: settings => dispatch(updateDomainSettings(settings)),
    fetchDomainSettings: () => dispatch(fetchDomainSettings()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DepotLocationMain),
);
