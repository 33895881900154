import { Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { setOpenedFabric } from "./actions";
import FabricTable from "./components/FabricTable";
import { deleteFabric, fetchFabrics } from "./data/fabrics/actions";
import { getAllFabrics } from "./data/fabrics/reducer";

class FabricMain extends Component {
  componentDidMount() {
    const { getFabrics, match } = this.props;
    getFabrics();
  }

  render() {
    const onFabricCreate = () => {
      const { history, fabrics } = this.props;
      history.push(`/fabric-admin/new`);
    };

    const onFabricDoubleClick = value => {
      const { history, fabrics } = this.props;
      history.push(`/fabric-admin/` + value.id);
    };

    const onRemove = value => {
      const { deleteFabric } = this.props;
      //history.push(`/product-admin/`)
      deleteFabric(value.id);
    };

    const { fabrics } = this.props;

    return (
      <div>
        <div>
          <h2>Материал</h2>
          <Button type="primary" onClick={onFabricCreate}>
            Создать
          </Button>
        </div>
        <br />
        <FabricTable
          onEditClick={onFabricDoubleClick}
          onRemove={onRemove}
          fabrics={fabrics}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    fabrics: getAllFabrics(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    getFabrics: () => dispatch(fetchFabrics()),
    setOpenedFabric: fabricId => dispatch(setOpenedFabric(fabricId)),
    deleteFabric: fabricId => dispatch(deleteFabric(fabricId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabricMain),
);
