import { combineReducers } from "redux";

import {
  SET_SHIPMENT_STATUS_SUCCESS,
  SHIPMENTS_FETCH_SUCCESS,
  SHIPMENT_CREATE_SUCCESS,
  SHIPMENT_DELETE_SUCCESS,
  SHIPMENT_FETCH_SUCCESS,
  SHIPMENT_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  console.log(action);
  switch (action.type) {
    case SET_SHIPMENT_STATUS_SUCCESS:
      return {
        ...state,
        [action.shipmentId]: action.shipment,
      };
    case SHIPMENTS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.shipments,
      };
    case SHIPMENT_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.shipments,
      };
    case SHIPMENT_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.shipments,
      };
    case SHIPMENT_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.shipments,
      };
    case SHIPMENT_DELETE_SUCCESS:
      const filtered = Object.keys(state)
        .filter(key => key != action.shipmentId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case SHIPMENTS_FETCH_SUCCESS:
      return action.response.result;
    case SHIPMENT_CREATE_SUCCESS:
      return [...state, action.response.result];
    case SHIPMENT_DELETE_SUCCESS:
      return [...state.filter(id => id != action.shipmentId)];
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});
