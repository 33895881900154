import PropTypes from "prop-types";
import React, { Component } from "react";

class OrderValue extends Component {
  render() {
    var gravatarStyle = {
      borderRadius: 3,
      display: "inline-block",
      marginRight: 10,
      position: "relative",
      top: -2,
      verticalAlign: "middle",
    };
    return (
      <div className="Select-value" title={this.props.value.title}>
        <span className="Select-value-label">
          {/*<Gravatar email={this.props.value.email} size={GRAVATAR_SIZE} style={gravatarStyle} />*/}
          {/* {this.props.value.orderNumberStr} */}
          {this.props.children}
        </span>
      </div>
    );
  }
}

export default OrderValue;

OrderValue.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.string,
  value: PropTypes.object,
};
