"use strict";

module.exports = {
  name: {
    BARCODE: "barcode",
    PASSPORT: "passport",
    PASSPORT_WITH_FIXED_SALARY: "passport-with-fixed-salary",
  },
  id: {
    BARCODE: 1,
    PASSPORT: 2,
    PASSPORT_WITH_FIXED_SALARY: 3,
  },
};
