import { Breadcrumb, Button, Dropdown, Layout, Menu } from "antd";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  deleteRevision,
  fetchRevisions,
} from "scenes/ProdStockRevision/data/prodStockRevisions/actions";
import { getAllProdStockRevisions } from "scenes/ProdStockRevision/data/prodStockRevisions/reducer";

import { fetchProdDepotLocations } from "../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../data/prodDepotLocations/reducer";
import ProdStockRevisionTable from "./components/ProdStockRevisionTable";

function ProdStockRevisionMain(props) {
  const {
    history,
    fetchRevisions,
    fetchProdDepotLocations,
    deleteProdStock,
    prodStockRevisions,
  } = props;

  const [loading, setLoading] = useState(() => {
    if (prodStockRevisions.length > 0) {
      return false;
    } else return true;
  });
  const [searchEnabled, setSearchEnabled] = useState(false);
  //const [prodDepotEntries, setProdDepotEntries] = useState(0);
  const [count, setCount] = useState(0);
  const [prodDepotLocationOptions, setProdDepotLocationOptions] = useState([]);

  const onProdStockCreatte = () => {
    history.push(`/prodstockrevision/new`);
  };

  const handleEditClick = contractor => {
    //console.log(id);
    history.push(`/prodstockrevision/` + contractor.id);
  };

  const onRemove = value => {
    // const {deleteEmployee} =props;
    deleteProdStock(value.id);
  };

  useEffect(() => {
    fetchRevisions().then(depot => {
      setLoading(false);
    });

    fetchProdDepotLocations().then(options => {
      setProdDepotLocationOptions(options);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Ревизии продукции</h2>
        <Button type="primary" onClick={onProdStockCreatte}>
          Создать
        </Button>
        &nbsp;
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => history.push("/logs/prodStockRevision")}
              >
                Посмотреть в журнале
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={{ marginLeft: "0.5em" }}>...</Button>
        </Dropdown>
      </div>
      <br />
      <ProdStockRevisionTable
        loading={loading}
        onEditClick={handleEditClick}
        onRemove={onRemove}
        prodStockRevisions={prodStockRevisions}
        prodDepotLocationOptions={prodDepotLocationOptions}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    prodDepotLocations: getAllProdDepotLocations(state),
    prodStockRevisions: getAllProdStockRevisions(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchRevisions: () => dispatch(fetchRevisions()),
    deleteRevision: id => dispatch(deleteRevision(id)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdStockRevisionMain),
);

//export default ContractorMain
