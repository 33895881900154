import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const TEAMS_FETCH_SUCCESS = "TEAMS_FETCH_SUCCESS";
export const TEAM_FETCH_SUCCESS = "TEAM_FETCH_SUCCESS";
export const TEAM_CREATE_SUCCESS = "TEAM_CREATE_SUCCESS";
export const TEAM_UPDATE_SUCCESS = "TEAM_UPDATE_SUCCESS";
export const TEAM_DELETE_SUCCESS = "TEAM_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchTeams = () => {
  return dispatch => {
    return axios
      .get(`/api/teams/`)
      .then(({ data }) => {
        let teams = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: TEAMS_FETCH_SUCCESS,
          response: normalize(teams, schema.arrayOfTeams),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchTeam = teamId => {
  return dispatch => {
    return axios
      .get(`/api/teams/` + teamId)
      .then(({ data }) => {
        let team = data;
        let id = teamId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: TEAM_FETCH_SUCCESS,
          response: normalize(team, schema.team),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createTeam = team => {
  return dispatch => {
    return axios
      .post("/api/teams/", team)
      .then(({ data }) => {
        // Status looks good
        let team = data;
        dispatch({
          type: TEAM_CREATE_SUCCESS,
          response: normalize(team, schema.team),
        });
        openNotificationWithIcon(
          "Успешно",
          "Бригада успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Бригада с таким названием уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateTeam = (teamId, team) => {
  return dispatch => {
    return axios
      .put("/api/teams/" + teamId, team)
      .then(({ data }) => {
        let team = data;
        dispatch({
          type: TEAM_UPDATE_SUCCESS,
          response: normalize(team, schema.team),
        });
        openNotificationWithIcon(
          "Успешно",
          "Бригада успешно сохранена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Бригада с таким названием уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteTeam = teamId => {
  return dispatch => {
    return axios
      .delete("/api/teams/" + teamId)
      .then(({ data }) => {
        let product = data;
        dispatch({ type: TEAM_DELETE_SUCCESS, teamId });
        openNotificationWithIcon(
          "Успешно",
          "Бригада успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. В данной бригаде есть сотрудники";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
