import { combineReducers } from "redux";

import { SET_OPENED_PAYROLL_ID, SET_SHOW_PAYROLLCREATE_MODAL } from "./actions";

const openedPayrollId = (state = "", action) => {
  switch (action.type) {
    case SET_OPENED_PAYROLL_ID:
      return action.payrollId;
    default:
      return state;
  }
};

const showPayrollCreateModal = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_PAYROLLCREATE_MODAL:
      return action.showPayrollCreateModal;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedPayrollId,
  showPayrollCreateModal,
});
