import "../print.css";
import "../cleanInputNumber.css";
import "../validation.css";

import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Col, Divider, InputNumber, Popconfirm, Row, Table } from "antd";
import { Formik } from "formik";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import Select from "../../../../../components/Select";
import {
  createCuttingPassportEntry,
  updateCuttingPassportEntry,
} from "../../../../../data/passportEntries/actions";
import { fetchPeriods } from "../../../../../data/periods/actions";
import { deleteProdOperation } from "../../../../../data/prodOperations/actions";
import { fetchTeams } from "../../../../../data/teams/actions";
import { getAllTeams } from "../../../../../data/teams/reducer";
import {
  setShowAddOperationModal,
  setShowCuttingSubmitPassportModal,
  setShowSearchEmployeeModal,
  setShowSearchFabricModal,
} from "../../../actions";
import {
  getOpenedCuttingOrder,
  getProdBomsByOpenedOrderWithSpreadsPatternsEntries,
} from "../../../selectors";
import AddOperationModal from "../AddOperationModal";
import SearchEmployeeModal from "../SearchEmployeeModal";
import SearchFabricModal from "../SearchFabricModal";
import { CuttingOperationsFormTable } from "./CuttingOperationsFormTable";
import { FabricConsumptionFormTable } from "./FabricConsumptionFormTable";
import getInitialValues from "./initialValues";
import getValidationSchema from "./validationSchema";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: "1000px",
      },
      root: {
        alignItems: "initial",
        zIndex: 1000,
        height: "100%",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const { NEW, EDIT, NORMAL } = {
  NEW: "NEW",
  EDIT: "EDIT",
  NORMAL: "NORMAL",
};

function CuttingSubmitPassportModal(props) {
  const {
    showModal,
    fullScreen,
    openedOrder,
    prodBoms,
    passportEntry,
    setShowSearchEmployeeModal,
    setShowSearchFabricModal,
    setShowAddOperationModal,
    fetchPeriods,
    fetchTeams,
    productionProcessType_id,
  } = props;

  const form = useRef();
  const close = () => {
    props.setShowCuttingSubmitPassportModal(false);
  };

  useEffect(() => {
    fetchPeriods().then(periods => {
      let filteredPeriods = periods.filter(period => {
        if (!period.payroll) {
          return true;
        } else if (period.payroll.isClosed == 0) {
          return true;
        } else return false;
      });

      setPeriods(
        filteredPeriods.map(period => {
          return { label: period.name, value: period.id, ...period };
        }),
      );
    });
  }, []);

  useEffect(() => {
    fetchTeams().then(teams => {
      setTeams(
        teams.map(team => ({
          ...team,
          label: team.name,
          value: team.id,
        })),
      );
    });
  }, []);

  const handleSubmit = () => {
    form.current.submitForm();
  };

  const [periods, setPeriods] = useState([]);
  const [teams, setTeams] = useState([]);
  const [status, setStatus] = useState(passportEntry === null ? NEW : NORMAL);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeletingOperation, setIsDeletingOperation] = useState(false);

  const onSubmit = values => {
    const { createCuttingPassportEntry, updateCuttingPassportEntry } = props;
    const passportData = {
      prodBoms: values.prodBoms,
      fabricConsumptions: values.fabricConsumptions,
      period: values.period,
      team: values.team,
    };

    if (
      productionProcessType_id ===
      ProductionProcessTypes.id.PASSPORT_WITH_FIXED_SALARY
    ) {
      delete passportData.prodBoms;
    }

    setIsSaving(true);
    if (status === NEW) {
      createCuttingPassportEntry(openedOrder.id, passportData).then(
        () => {
          setStatus(NORMAL);
          setIsSaving(false);
        },
        () => {
          setIsSaving(false);
        },
      );
    } else if (status === EDIT) {
      updateCuttingPassportEntry(
        openedOrder.id,
        passportEntry.id,
        passportData,
      ).then(
        () => {
          setStatus(NORMAL);
          setIsSaving(false);
        },
        () => {
          setIsSaving(false);
        },
      );
    }
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          fullScreen={fullScreen}
          scroll={"body"}
          open={showModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Паспорт раскроя
          </DialogTitle>
          <DialogContent>
            <div>
              <Formik
                ref={form}
                initialValues={getInitialValues(
                  passportEntry,
                  prodBoms,
                  openedOrder,
                )}
                validationSchema={getValidationSchema(productionProcessType_id)}
                onSubmit={onSubmit}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <div>
                    <AddOperationModal
                      modalType={"cutting"}
                      openedOrder={openedOrder}
                      addNewOperationToForm={(
                        prodBomIndex,
                        prodBomId,
                        newOperation,
                      ) => {
                        const prodBomById = prodBoms.find(
                          prodBom => prodBom.id === prodBomId,
                        );
                        setFieldValue(
                          `prodBoms[${prodBomIndex}]`,
                          values.prodBoms[prodBomIndex].map(
                            (prodSpreadOperations, index) => [
                              ...prodSpreadOperations,
                              {
                                key: nanoid(5),
                                prodBomId: prodBomId,
                                prodSpreadId: prodBomById.prodSpreads.filter(
                                  prodSpread => prodSpread.printOperations,
                                )[index].id,
                                operation: newOperation,
                                prodOperation: newOperation.operation,
                                employee: null,
                                quantity: 0,
                              },
                            ],
                          ),
                        );
                      }}
                    />
                    <SearchEmployeeModal
                      selectEmployee={(
                        employee,
                        prodBomIndex,
                        prodSpreadIndex,
                        operationIndex,
                      ) => {
                        setFieldValue(
                          `prodBoms[${prodBomIndex}][${prodSpreadIndex}][${operationIndex}].employee`,
                          employee,
                        );
                      }}
                      setQuantity={(
                        value,
                        prodBomIndex,
                        prodSpreadIndex,
                        operationIndex,
                      ) => {
                        setFieldValue(
                          `prodBoms[${prodBomIndex}][${prodSpreadIndex}][${operationIndex}].quantity`,
                          value,
                        );
                      }}
                    />
                    <SearchFabricModal
                      fabricConsumptionsFromDatabase={
                        passportEntry ? passportEntry.fabricConsumptions : null
                      }
                      fabricConsumptions={values.fabricConsumptions}
                      selectDepotLocation={(
                        depotLocation,
                        prodBomIndex,
                        prodSpreadIndex,
                        fabricConsumptionIndex,
                      ) => {
                        setFieldValue(
                          `fabricConsumptions[${prodBomIndex}][${prodSpreadIndex}][${fabricConsumptionIndex}].depotLocation`,
                          depotLocation,
                        );
                      }}
                      selectWeight={(
                        weight,
                        prodBomIndex,
                        prodSpreadIndex,
                        fabricConsumptionIndex,
                      ) => {
                        setFieldValue(
                          `fabricConsumptions[${prodBomIndex}][${prodSpreadIndex}][${fabricConsumptionIndex}].weight`,
                          weight,
                        );
                      }}
                      selectFabric={(
                        fabric,
                        prodBomIndex,
                        prodSpreadIndex,
                        fabricConsumptionIndex,
                      ) => {
                        setFieldValue(
                          `fabricConsumptions[${prodBomIndex}][${prodSpreadIndex}][${fabricConsumptionIndex}].fabric`,
                          fabric,
                        );
                      }}
                      selectLineItems={(
                        lineItems,
                        prodBomIndex,
                        prodSpreadIndex,
                        fabricConsumptionIndex,
                      ) => {
                        setFieldValue(
                          `fabricConsumptions[${prodBomIndex}][${prodSpreadIndex}][${fabricConsumptionIndex}].lineItems`,
                          lineItems,
                        );
                      }}
                    />
                    <Row>
                      <Col span={8}>Заказ: PR-{openedOrder.id}</Col>
                    </Row>
                    <Row>Модель: {openedOrder.product.name}</Row>
                    <Row>
                      Список частей:{" "}
                      {prodBoms.map(
                        (prodBom, index) =>
                          prodBom.name +
                          (index === prodBoms.length - 1 ? "" : ", "),
                      )}
                    </Row>
                    <br />
                    <div style={{ marginBottom: 15 }}>
                      <label className="mt-label" htmlFor="period">
                        Период:
                      </label>
                      <Select
                        id="period"
                        options={periods}
                        value={values["period"]}
                        onChange={e => setFieldValue("period", e)}
                        isDisabled={status === NORMAL}
                        className={
                          errors.period && touched.period ? "inputError" : ""
                        }
                        style={{ width: "100%" }}
                      />
                      <div className="help-block">
                        {errors.period && touched.period && errors.period}
                      </div>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                      <label className="mt-label" htmlFor="teams">
                        Бригада:
                      </label>
                      <Select
                        id="team"
                        options={teams}
                        value={values["team"]}
                        onChange={e => setFieldValue("team", e)}
                        isDisabled={status === NORMAL}
                        className={
                          errors.team && touched.team ? "inputError" : ""
                        }
                        style={{ width: "100%" }}
                      />
                      <div className="help-block">
                        {errors.team && touched.team && errors.team}
                      </div>
                    </div>
                    <h4>Заказано:</h4>
                    <Table
                      bordered={true}
                      size={"small"}
                      pagination={false}
                      columns={[
                        ...props.openedOrder.lineItems.map(
                          (lineItem, lineItemIndex) => ({
                            title: lineItem.size.name,
                            key: "sizeName" + lineItemIndex,
                            align: "center",
                            width: 70,
                            render: (value, record, index) => {
                              return (
                                <InputNumber
                                  precision={0}
                                  className={"cleanInputNumber"}
                                  value={
                                    record.lineItems.find(
                                      item => item.size.id === lineItem.size.id,
                                    ).quantity
                                  }
                                  min={0}
                                  disabled={true}
                                />
                              );
                            },
                          }),
                        ),
                      ]}
                      dataSource={[
                        { key: 1, lineItems: openedOrder.lineItems },
                      ]}
                    />
                    <Divider />
                    {prodBoms.map((prodBom, prodBomIndex) => (
                      <div key={prodBomIndex}>
                        <h3>Часть: {prodBom.name}</h3>
                        {prodBom.prodSpreads
                          .filter(prodSpread => prodSpread.printOperations)
                          .map((prodSpread, prodSpreadIndex) => (
                            <div key={prodSpreadIndex}>
                              <br />
                              <h4>Расход материала и количество скроенных:</h4>
                              <FabricConsumptionFormTable
                                prodBomIndex={prodBomIndex}
                                prodSpreadIndex={prodSpreadIndex}
                                openedOrder={openedOrder}
                                setShowSearchFabricModal={
                                  setShowSearchFabricModal
                                }
                                form={{
                                  errors,
                                  touched,
                                  values,
                                  setFieldValue,
                                  status,
                                }}
                                data={
                                  values.fabricConsumptions[prodBomIndex][
                                    prodSpreadIndex
                                  ]
                                }
                              />

                              {productionProcessType_id ===
                                ProductionProcessTypes.id.PASSPORT && (
                                <>
                                  <br />
                                  <h4>Настил: {prodSpread.name}</h4>
                                  <CuttingOperationsFormTable
                                    prodBomIndex={prodBomIndex}
                                    prodSpreadIndex={prodSpreadIndex}
                                    status={status}
                                    setShowAddOperationModal={
                                      setShowAddOperationModal
                                    }
                                    prodBom={prodBom}
                                    setIsDeletingOperation={
                                      setIsDeletingOperation
                                    }
                                    setShowSearchEmployeeModal={
                                      setShowSearchEmployeeModal
                                    }
                                    isDeletingOperation={isDeletingOperation}
                                    form={{
                                      errors,
                                      touched,
                                      values,
                                      setFieldValue,
                                    }}
                                    data={
                                      values.prodBoms[prodBomIndex][
                                        prodSpreadIndex
                                      ]
                                    }
                                  />
                                  <br />
                                </>
                              )}
                            </div>
                          ))}
                        {prodBomIndex !== prodBoms.length - 1 && <Divider />}
                      </div>
                    ))}
                  </div>
                )}
              </Formik>
            </div>
          </DialogContent>
          <DialogActions>
            {(status === NEW || status === EDIT) && (
              <Button
                type={"primary"}
                onClick={handleSubmit}
                loading={isSaving}
              >
                Сохранить
              </Button>
            )}
            {status === NORMAL && (
              <Button type={"primary"} onClick={() => setStatus(EDIT)}>
                Редактировать
              </Button>
            )}
            <Button onClick={close}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const openedOrder = getOpenedCuttingOrder(state);
  return {
    showModal: state.scenes.cutting.showCuttingSubmitPassportModal,
    prodBoms: getProdBomsByOpenedOrderWithSpreadsPatternsEntries(state),
    openedOrder: openedOrder,
    passportEntry: openedOrder.cuttingPassportEntry,
    teams: getAllTeams(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTeams: () => dispatch(fetchTeams()),
    createCuttingPassportEntry: (orderId, passport) =>
      dispatch(createCuttingPassportEntry(orderId, passport)),
    fetchPeriods: () => dispatch(fetchPeriods()),
    deleteOperation: (prodBomId, operationId) =>
      dispatch(deleteProdOperation(prodBomId, operationId)),
    updateCuttingPassportEntry: (orderId, passportEntryId, passport) =>
      dispatch(updateCuttingPassportEntry(orderId, passportEntryId, passport)),
    setShowCuttingSubmitPassportModal: showModal =>
      dispatch(setShowCuttingSubmitPassportModal(showModal)),
    setShowSearchFabricModal: (
      showModal,
      prodBomIndex,
      prodSpreadIndex,
      fabricConsumptionIndex,
    ) =>
      dispatch(
        setShowSearchFabricModal(
          showModal,
          prodBomIndex,
          prodSpreadIndex,
          fabricConsumptionIndex,
        ),
      ),
    setShowAddOperationModal: (showModal, prodBomIndex, prodBomId) =>
      dispatch(setShowAddOperationModal(showModal, prodBomIndex, prodBomId)),
    setShowSearchEmployeeModal: (
      showModal,
      prodBomIndex,
      prodSpreadIndex,
      operationIndex,
    ) =>
      dispatch(
        setShowSearchEmployeeModal(
          showModal,
          prodBomIndex,
          prodSpreadIndex,
          operationIndex,
        ),
      ),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CuttingSubmitPassportModal),
);
