//import TeamTable from './components/TeamTable'
//import { getAllTeams } from '../../data/teams/reducer';

import { Breadcrumb, Button, Layout, Menu } from "antd";
import { Input, notification } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { fetchEmployee } from "../../data/employees/actions";
import { claimWageEntry, unClaimWageEntry } from "../../data/wages/actions";
import { setSelectedEmployeeId } from "./actions";
import WageTable from "./components/WageTable";
import {
  getTotalForSelectedEmployee,
  getWagesForSelectedEmployee,
} from "./selectors";

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

class WageMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployee: undefined,
      barCode: "",
    };
  }

  componentDidMount() {
    //  const { fetchTeams, match } = this.props;
    //  fetchTeams();
  }

  getEmployeeOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return (
      axios(`/api/employees/search?q=${input}`)
        //.then((response) => response.json())
        .then(({ data }) => {
          return data.items.map(item => {
            return {
              value: item.id,
              label:
                item.last_name +
                " " +
                item.first_name +
                " " +
                item.middle_name +
                " [" +
                item.team.name +
                "]",
              id: item.id,
              ...item,
            };
          });
        })
    );
  };

  handleEmployeeSelect = employee => {
    const { fetchEmployee, setSelectedEmployeeId } = this.props;

    if (employee) {
      fetchEmployee(employee.id).then(employee => {
        setSelectedEmployeeId(employee.id);
      });
    } else {
      setSelectedEmployeeId(null);
    }

    this.setState({ selectedEmployee: employee });
  };

  handlBarCodeChange = value => {
    this.setState({ barCode: value.target.value });
  };

  handleKeyPress = e => {
    const { createWageClaim } = this.props;
    if (e.key === "Enter") {
      //console.log('do validate');
      //console.log("blahblahblah",this.state.barCode)
      let barCode = this.state.barCode;
      let wageId = undefined;
      let badFormat = 0;

      if (barCode.length < 2) {
        badFormat = 1;
      } else {
        if (barCode[0] === "0") {
          if (barCode[1] === "1") wageId = barCode.slice(2);
          else badFormat = 1;
        } else {
          badFormat = 1;
        }
      }

      if (!badFormat) {
        if (Number.isInteger(parseInt(wageId, 10))) {
          createWageClaim(this.state.selectedEmployee.id, { id: wageId }).then(
            wage => {},
            error => {},
          );
        } else {
          openNotificationWithIcon("Ошибка", "Неправильный штрих код", "error");
        }
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Неверный формат штрих кода",
          "error",
        );
      }

      setTimeout(e => {
        this.setState({ barCode: "" });
      }, 200);
    }
  };

  handleWageRemove = value => {
    const { deleteWageEntry } = this.props;
    //console.log(value);
    deleteWageEntry(this.state.selectedEmployee.id, value);
  };
  handleEditClick = value => {};

  render() {
    const { wages, total } = this.props;

    let sortedWages = [...wages];

    sortedWages.reverse();

    return (
      <div>
        <h2>Выберите работника</h2>
        <AsyncSelect
          menuContainerStyle={{ zIndex: 99999 }}
          name="form-field-name"
          value={this.state.selectedEmployee}
          loadOptions={this.getEmployeeOptions}
          onChange={this.handleEmployeeSelect}
          //disabled={disabled}
          styles={customStyles}
          noOptionsMessage={() => {
            return "Печатайте для поиска...";
          }}
          menuPortalTarget={document.body}
        />
        <br />

        {this.props.productionProcessType === "barcode" &&
          this.state.selectedEmployee != undefined && (
            <div style={{ marginBottom: 15 }}>
              <label className="mt-label" htmlFor="article">
                Штрих код
              </label>

              <Input
                value={this.state.barCode}
                onChange={this.handlBarCodeChange}
                onKeyPress={this.handleKeyPress}
              />
            </div>
          )}

        {this.state.selectedEmployee != undefined && (
          <div>
            <label>Итого Заработал: {total} сум</label>
            <br />
            <br />
            <WageTable
              wages={sortedWages}
              onEditClick={this.handleEditClick}
              onRemove={this.handleWageRemove}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const domainSettings = state.domainSettings;
  return {
    // You can now say this.props.books
    wages: getWagesForSelectedEmployee(state),
    total: getTotalForSelectedEmployee(state),
    productionProcessType: domainSettings
      ? domainSettings.find(setting => setting.id === 1).value
      : null,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    createWageClaim: (employeeId, wage) =>
      dispatch(claimWageEntry(employeeId, wage)),
    deleteWageEntry: (employeeId, wage) =>
      dispatch(unClaimWageEntry(employeeId, wage)),
    fetchEmployee: employeeId => dispatch(fetchEmployee(employeeId)),
    //fetchEmployee: (employeeId)=>dispatch(fetchEmployee(employeeId)),
    setSelectedEmployeeId: employeeId =>
      dispatch(setSelectedEmployeeId(employeeId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WageMain),
);

//export default ContractorMain
