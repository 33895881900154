import { combineReducers } from "redux";

const initialState = [];

const byId = (state = {}, action) => {
  switch (action.type) {
    case "SPREAD_ADD": {
      let spread = { ...action.spread, id: action.spreadId, patterns: [] };
      return {
        ...state,
        [action.spreadId]: spread,
      };
    }
    case "SPREAD_UPDATE": {
      // return {
      //     ...state, ...action.spread
      // }
      let oldPatterns = state[action.spread.id].patterns;
      return {
        ...state,
        [action.spread.id]: { ...action.spread, patterns: oldPatterns },
      };
    }
    case "PATTERN_ADD":
      let spreadId = action.spreadId;
      let patternId = action.patternId;
      let spread = state[spreadId];

      return {
        ...state,
        [spreadId]: {
          ...spread,
          patterns: spread.patterns.concat(patternId),
        },
      };

    case "PATTERN_DELETE":
      return deletePattern(state, action);

    case "SPREAD_DELETE":
      var filtered = Object.keys(state)
        .filter(key => key != action.spreadId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }
    case "RESET_SPEC_FORM":
      return {};

    default:
      return state;
  }
};

function deletePattern(state, action) {
  let spreadId = action.spreadId;
  let patternId = action.patternId;
  let spread = state[spreadId];
  return {
    ...state,
    [spreadId]: {
      ...spread,
      patterns: spread.patterns.filter(id => id != patternId),
    },
  };
}

export const spreads = combineReducers({
  byId,
});
