"use strict";

const moment = require("moment");
const stringValidator = require("./stringValidator");

function parseTime(time) {
  return moment(time, "HH:mm");
}

module.exports = ({
  min,
  max,
  message,
  required,
  default: defaultValue,
} = {}) => {
  let schema = stringValidator({ message, required, default: defaultValue });

  if (min) {
    schema = schema.test({
      message,
      name: "time_min",
      test(value) {
        const minTime = this.resolve(min);

        // Success if `max` value is not defined.
        if (minTime == null) {
          return true;
        }

        // Fail if `max` value is defined but current is not.
        if (value == null) {
          return false;
        }

        // Compare values if both of them are defined.
        return parseTime(minTime).isBefore(parseTime(value));
      },
    });
  }

  if (max) {
    schema = schema.test({
      message,
      name: "time_max",
      test(value) {
        const maxTime = this.resolve(max);

        // Success if `max` value is not defined.
        if (maxTime == null) {
          return true;
        }

        // Fail if `max` value is defined but current is not.
        if (value == null) {
          return false;
        }

        // Compare values if both of them are defined.
        return parseTime(maxTime).isAfter(parseTime(value));
      },
    });
  }

  return schema.test({
    message,
    name: "time",
    test(value) {
      return value == null || parseTime(value).isValid();
    },
  });
};
