import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  batchUpdateWorkingScheduleWeight,
  deleteWorkingSchedule,
  fetchWorkingSchedules,
  updateWorkingSchedule,
} from "../../data/workingSchedules/actions";
import { getAllWorkingSchedules } from "../../data/workingSchedules/reducer";
import { WorkingScheduleTable } from "./components/WorkingScheduleTable";
import {
  formatWorkingScheduleType,
  workingScheduleTypes,
} from "./helpers/WorkingScheduleTypeHelpers";

function mapAndSortWeights(data, fromIndex, toIndex) {
  if (toIndex < 0 || toIndex > data.length - 1) {
    return;
  }

  return data.map(({ id }, index) => {
    if (fromIndex === index) {
    }

    return {
      id,
      weight:
        index === fromIndex ? toIndex : index === toIndex ? fromIndex : index,
    };
  });
}

const enhancer = connect(state => ({
  data: getAllWorkingSchedules(state),
}));

function WorkingScheduleList({ data, dispatch, history, match: { url } }) {
  useEffect(() => {
    dispatch(fetchWorkingSchedules());
  }, []);

  return (
    <div>
      <div>
        <h2>График работы</h2>

        <Dropdown
          overlay={
            <Menu onClick={({ key }) => history.push(`${url}/add/${key}`)}>
              {workingScheduleTypes.map(x => (
                <Menu.Item key={x}>{formatWorkingScheduleType(x)}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button type="primary">
            Создать
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>

      <br />

      <WorkingScheduleTable
        loading={!data}
        workingSchedules={data}
        onEdit={({ id }) => {
          history.push(`${url}/${id}`);
        }}
        onChange={({ id, ...values }) => {
          dispatch(updateWorkingSchedule(id, values));
        }}
        onDelete={({ id }) => {
          dispatch(deleteWorkingSchedule(id));
        }}
        onWeightChange={(entry, addition) => {
          const entryIndex = data.indexOf(entry);
          const payload = mapAndSortWeights(
            data,
            entryIndex,
            entryIndex + addition,
          );

          if (payload) {
            dispatch(batchUpdateWorkingScheduleWeight(payload));
          }
        }}
      />
    </div>
  );
}

export default enhancer(WorkingScheduleList);
