export const selectPeriod = period_id => ({
  type: "SELECT_PERIOD_FOR_STATISTICS",
  period_id,
});

export const selectPartition = partition => ({
  type: "SELECT_PARTITION_FOR_STATISTICS",
  partition,
});

export const selectRange = range => ({
  type: "SELECT_RANGE_FOR_STATISTICS",
  range,
});

export const setMinMax = minMax => ({
  type: "SET_MINIMUM_AND_MAXIMUM_DATES",
  minMax,
});
