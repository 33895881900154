import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";

//import {addNotification} from '../../../../actions'

export const PRODUCT_FETCH_SUCCESS = "PRODUCT_FETCH_SUCCESS";
export const PRODUCTS_FETCH_SUCCESS = "PRODUCTS_FETCH_SUCCESS";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_CODE_UPSERT_SUCCESS = "PRODUCT_CODE_UPSERT_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const upsertProductCode = (productId, code) => {
  return dispatch =>
    axios
      .post(`/api/product/${productId}/code`, {
        code,
      })
      .then(({ data }) => {
        dispatch({ type: PRODUCT_CODE_UPSERT_SUCCESS, response: code });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
};

export const fetchProduct = (productId, callback) => {
  return dispatch => {
    return (
      axios
        .get(`/api/products/` + productId)
        // Try to parse the response
        .then(
          // Both fetching and parsing succeeded!
          ({ data }) => {
            let product = data;
            let normalized = normalize(product, schema.product);
            dispatch({ type: PRODUCT_FETCH_SUCCESS, response: normalized });
            // dispatch({ type: 'SPREADS_FETCH_DATA_SUCCESS', response: normalized })
            // dispatch({ type: 'BOMS_FETCH_DATA_SUCCESS', response: normalized })
            // dispatch({ type: 'PATTERNS_FETCH_DATA_SUCCESS', response: normalized })
            return product;
          },
        )
        .catch(error => {
          if (error.handledGlobally) {
            return Promise.reject();
          }
          if (error.response) {
            let { data } = error.response;
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            throw error;
          } else {
            openNotificationWithIcon(
              "Ошибка",
              "Невозможно подключится к серверу",
              "error",
            );
            throw error;
          }
        })
    );
  };
};

export const fetchProducts = () => {
  return dispatch => {
    return axios
      .get(`/api/products/`)
      .then(({ data }) => {
        let products = data;
        dispatch({
          type: PRODUCTS_FETCH_SUCCESS,
          response: normalize(products, schema.arrayOfProducts),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createProduct = product => {
  return dispatch => {
    return axios
      .post("/api/products/", { product })
      .then(
        // Both fetching and parsing succeeded!
        ({ data }) => {
          // Status looks good
          let product = data;
          //dispatch({type: 'PRODUCT_CREATE_SUCCESS', product})
          dispatch({
            type: PRODUCT_CREATE_SUCCESS,
            response: normalize(product, schema.product),
          });
          openNotificationWithIcon(
            "Успешно",
            "Продукт успешно сохранен",
            "success",
          );
          return data;
        },
      )
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data, status } = error.response;
          if (status == 409) {
            throw error;
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            throw error;
          }
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateProduct = (productId, product) => {
  return dispatch => {
    return axios
      .put("/api/products/" + productId, { product })
      .then(
        // Both fetching and parsing succeeded!
        ({ data }) => {
          let product = data;
          //dispatch({type: 'PRODUCT_UPDATE_SUCCESS', product})

          dispatch({
            type: PRODUCT_UPDATE_SUCCESS,
            response: normalize(product, schema.product),
          });
          openNotificationWithIcon(
            "Успешно",
            "Продукт успешно сохранен",
            "success",
          );
          return data;
        },
      )
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data, status } = error.response;
          if (status == 409) {
            throw error;
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            throw error;
          }
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteProduct = productId => {
  return dispatch => {
    return axios
      .delete("/api/products/" + productId)
      .then(({ data }) => {
        dispatch({ type: PRODUCT_DELETE_SUCCESS, productId });
        openNotificationWithIcon(
          "Успешно",
          "Продукт успешно удален",
          "success",
        );
        return true;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Невозможно удалить так как у этого продукта есть заказы";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

//This is currently not being used. Component has it's own upload method
export const uploadPicture = (file, name) => {
  return dispatch => {
    let data = new FormData();
    data.append("file", file);
    data.append("name", name);

    return axios
      .post("/api/products/files", { data })
      .then(
        // Both fetching and parsing succeeded!
        ({ data }) => {
          // Status looks good
          let product = data;
          //dispatch({type: 'PRODUCT_CREATE_SUCCESS', product})
          //dispatch({type: 'PRODUCT_CREATE_SUCCESS', response: normalize(product, schema.product)})
          openNotificationWithIcon("Успешно", "Файл успешно загружен", "info");
          return product;
        },
      )
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Невозможно загрузить файл",
            "error",
          );
          throw error;
          //let errorMessage
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
    // Either fetching or parsing failed!
  };
};
