import { createSelector } from "reselect";

export const getProdSpreads = state => state.data.prodSpreads;

export const getProdBoms = state => state.data.prodBoms;

export const getProdPatterns = state => state.data.prodPatterns;

export const getCuttingEntries = state => state.data.cuttingEntries;

export const getProdEntries = state => state.data.prodEntries;

export const getProdOperations = state => state.data.prodOperations;

export const getOrders = state => state.data.orders;

export const getWages = state => state.data.wages;

export const getCurrentPage = state => state.data.orders.currentPage;

export const getPage = state => state.data.orders.page;

export const isPageInCache = state => {
  if (getPage(state)[getCurrentPage(state)]) return true;
  else return false;
};

export const getOrdersForCurrentPage = createSelector(
  [getOrders, getCurrentPage, getPage, getProdBoms],
  (orders, currentPage, page, prodBoms) => {
    let myPage = page[currentPage];

    let res = [];

    if (myPage) {
      res = myPage.map(id => {
        return { ...orders.byId[id], currentPage: currentPage };
      });
    }

    res = res.map(order => {
      return {
        ...order,
        prodBoms: order.prodBoms.map(id => {
          return prodBoms.byId[id];
        }),
      };
    });

    return res;
  },
);
