import { combineReducers } from "redux";

import { fabStockRevisions } from "./fabStockRevisions/reducer";

export const reducer = combineReducers({
  fabStockRevisions,
});

// export const getProducts = state => state.scenes.product.data.products;

// export const getAllProducts = state => {
//   let products = getProducts(state);
//   return products.allIds.map(id => products.byId[id]);
// };

// export const getProduct = (state, id) => {
//   let products = getProducts(state);
//   return products.byId[id];
// };
