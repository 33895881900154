import { combineReducers } from "redux";

import { REPORT_FETCH_SUCCESS } from "./actions";

const prodEntriesProductionReport = (state = {}, action) => {
  switch (action.type) {
    case REPORT_FETCH_SUCCESS:
      return action.response.prodEntries;
    default:
      return state;
  }
};

const datesProductionReport = (state = {}, action) => {
  switch (action.type) {
    case REPORT_FETCH_SUCCESS:
      return action.response.dates;
    default:
      return state;
  }
};

const cuttingEntriesProductionReport = (state = {}, action) => {
  switch (action.type) {
    case REPORT_FETCH_SUCCESS:
      return action.response.cuttingEntries;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  datesProductionReport,
  cuttingEntriesProductionReport,
  prodEntriesProductionReport,
});
