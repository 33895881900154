import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import PayrollMain from "./index.js";
import PayrollDetail from "./PayrollDetail";

///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class PayrollRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/payroll" component={PayrollMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={PayrollDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={PayrollDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default PayrollRoute;
