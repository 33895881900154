import { normalize, schema } from "normalizr";

// Define a users schema

export const payrollEntry = new schema.Entity(
  "payrollEntries",
  {},
  { idAttribute: "id" },
);

export const payrollAttendanceEntry = new schema.Entity(
  "payrollAttendanceEntries",
  {},
  { idAttribute: "id" },
);

export const arrayOfPayrollEntries = [payrollEntry];

export const arrayOfPayrollAttendanceEntries = [payrollAttendanceEntry];

export const payroll = new schema.Entity(
  "payrolls",
  {
    payrollEntries: arrayOfPayrollEntries,
    payrollAttendanceEntries: arrayOfPayrollAttendanceEntries,
  },
  { idAttribute: "id" },
);

export const arrayOfPayrolls = [payroll];
