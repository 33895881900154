import AttendanceReportType from "@app/shared/constants/AttendanceReportType";
import { getAttendanceReportName } from "@app/shared/utils/AttendanceReportUtils";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  createMuiTheme,
  withMobileDialog,
} from "@material-ui/core";
import { Button, List } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { fetchAttendanceReports } from "../../../../data/attendanceReports/actions";
import { getAllAttendanceReports } from "../../../../data/attendanceReports/reducer";
import { fetchWorkingSchedules } from "../../../../data/workingSchedules/actions";
import { getAllWorkingSchedules } from "../../../../data/workingSchedules/reducer";
import { WorkingScheduleTable } from "../../../WorkingSchedule/components/WorkingScheduleTable";

const {
  formatAttendanceReportName,
} = require("@app/shared/utils/AttendanceReportUtils");

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      root: { zIndex: 300, alignItems: "initial" },
      paperWidthSm: { maxWidth: "900px", minWidth: "900px" },
    },
  },
  typography: { useNextVariants: true },
});

const enhancer = compose(
  withMobileDialog(),
  connect(state => ({
    workingSchedules: getAllWorkingSchedules(state),
    attendanceReports: getAllAttendanceReports(state),
  })),
);

const LIST_VIEW = "list";
const DEFAULT_SCHEDULES_VIEW = "default-schedules";

function WorkingScheduleChooseDialog({
  open,
  onClose,
  dispatch,
  onSelect,
  workingSchedules,
  attendanceReports,
  ...props
}) {
  const [view, setView] = useState(LIST_VIEW);
  const listDataSource = useMemo(
    () => [
      { value: DEFAULT_SCHEDULES_VIEW, label: "Базовый" },
      ...attendanceReports.map(x => ({
        value: x.id,
        label: getAttendanceReportName(x),
      })),
    ],
    [attendanceReports],
  );

  const [selectedTitle, selectedWorkingSchedules] = useMemo(() => {
    if (view === LIST_VIEW) {
      return [];
    }

    if (view === DEFAULT_SCHEDULES_VIEW) {
      return ["Базовый график работ", workingSchedules];
    }

    const report =
      attendanceReports && attendanceReports.find(x => x.id === view);

    return !report || !report.workingSchedule
      ? []
      : [formatAttendanceReportName(report), report.workingSchedule];
  }, [view, attendanceReports, workingSchedules]);

  useEffect(() => {
    dispatch(fetchWorkingSchedules());
    dispatch(
      fetchAttendanceReports(
        AttendanceReportType.AttendanceReportByDateRange,
        0,
        50,
      ),
    );
  }, []);

  useEffect(() => {
    if (!open) {
      setView(LIST_VIEW);
    }
  }, [open]);

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        {...props}
        open={open}
        onClose={() => {
          if (view === LIST_VIEW) {
            onClose();
          } else {
            setView(LIST_VIEW);
          }
        }}
      >
        {selectedTitle != null && <DialogTitle>{selectedTitle}</DialogTitle>}

        <DialogContent>
          {view === LIST_VIEW && (
            <List
              dataSource={listDataSource}
              renderItem={x => (
                <List.Item
                  actions={[<a onClick={() => setView(x.value)}>Подробнее</a>]}
                >
                  {x.label}
                </List.Item>
              )}
            />
          )}

          {selectedWorkingSchedules != null && (
            <>
              <Button
                type="primary"
                onClick={() => onSelect(selectedWorkingSchedules)}
              >
                Выбрать этот шаблон
              </Button>

              <br />
              <br />

              <WorkingScheduleTable
                showID={false}
                showWeight={false}
                showActions={false}
                workingSchedules={selectedWorkingSchedules}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default enhancer(WorkingScheduleChooseDialog);
