let nextId = 1;

export const addBom = bom => ({
  type: "BOM_ADD",
  bom,
  bomId: nextId++,
});

export const moveBom = (bomId, direction) => ({
  type: "BOM_MOVE",
  bomId,
  direction,
});

export const saveBom = bom => ({
  type: "BOM_UPDATE",
  bom,
});

export const deleteBom = bomId => ({
  type: "BOM_DELETE",
  bomId,
});
