export const OPENED_CUTTING_ORDER_SET = "OPENED_CUTTING_ORDER_SET";
export const SHOW_CUTTING_ENTRY_MODAL_SET = "SHOW_CUTTING_ENTRY_MODAL_SET";
export const MODAL_SELECTED_SPREAD_SET = "MODAL_SELECTED_SPREAD_SET";
export const OPENED_CUTTING_ENTRY_ID_SET = "OPENED_CUTTING_ENTRY_ID_SET";
export const OPENED_PROD_ENTRY_ID_SET = "OPENED_PROD_ENTRY_ID_SET";

export const SELECTED_PRODBOM_SET = "SELECTED_PRODBOM_SET";
export const SHOW_PROD_ENTRY_MODAL_SET = "SHOW_PROD_ENTRY_MODAL_SET";
export const SHOW_CUTTING_WAGE_MODAL_SET = "SHOW_CUTTING_WAGE_MODAL_SET";

export const SHOW_WAGE_CLAIM_MODAL_SET = "SHOW_WAGE_CLAIM_MODAL_SET";
export const SHOW_CUTTING_OPERATIONS_MODAL_SET =
  "SHOW_CUTTING_OPERATIONS_MODAL_SET";
export const SHOW_SEWING_OPERATIONS_MODAL_SET =
  "SHOW_SEWING_OPERATIONS_MODAL_SET";
export const OPENED_WAGE_ID_SET = "OPENED_WAGE_ID_SET";

export const SHOW_PROD_WAGE_MODAL_SET = "SHOW_PROD_WAGE_MODAL_SET";

export const RESET_PROD_ENTRY_DETAIL_KEY = "RESET_PROD_ENTRY_DETAIL_KEY";

export const RESET_CUTTING_OPERATIONS_MODAL_KEY =
  "RESET_CUTTING_OPERATIONS_MODAL_KEY";

export const RESET_SEWING_OPERATIONS_MODAL_KEY =
  "RESET_SEWING_OPERATIONS_MODAL_KEY";

export const SET_SELECTED_ORDER_VALUE = "SET_SELECTED_ORDER_VALUE";
export const RESET_CUTTING_PASSPORT_KEY = "RESET_CUTTING_PASSPORT_KEY";
export const RESET_SEWING_PASSPORT_KEY = "RESET_SEWING_PASSPORT_KEY";
export const RESET_REAL_PRICE_CALCULATION_MODAL_KEY = "RESET_REAL_PRICE_CALCULATION_MODAL_KEY ";
export const RESET_CUTTING_SUBMIT_PASSPORT_KEY =
  "RESET_CUTTING_SUBMIT_PASSPORT_KEY";
export const RESET_SEWING_SUBMIT_PASSPORT_KEY =
  "RESET_SEWING_SUBMIT_PASSPORT_KEY";
export const SHOW_CUTTING_PASSPORT_MODAL = "SHOW_CUTTING_PASSPORT_MODAL";
export const SHOW_SEWING_PASSPORT_MODAL = "SHOW_SEWING_PASSPORT_MODAL";
export const SHOW_REAL_PRICE_CALCULATION_MODAL = "SHOW_REAL_PRICE_CALCULATION_MODAL";
export const SHOW_CUTTING_SUBMIT_PASSPORT_MODAL =
  "SHOW_CUTTING_SUBMIT_PASSPORT_MODAL ";
export const SHOW_SEWING_SUBMIT_PASSPORT_MODAL =
  "SHOW_SEWING_SUBMIT_PASSPORT_MODAL ";
export const SHOW_SEARCH_EMPLOYEE_MODAL_SET = "SHOW_SEARCH_EMPLOYEE_MODAL_SET";
export const SHOW_SEARCH_FABRIC_MODAL_SET = "SHOW_SEARCH_FABRIC_MODAL_SET";
export const SHOW_ADD_OPERATION_MODAL_SET = "SHOW_ADD_OPERATION_MODAL_SET";

let nextKey = 1;

export const setOpenedCuttingOrder = orderId => ({
  type: OPENED_CUTTING_ORDER_SET,
  orderId,
});

export const resetProdEntryDetailKey = () => ({
  type: RESET_PROD_ENTRY_DETAIL_KEY,
  prodEntryDetailKey: "prod_" + nextKey++,
});

export const resetCuttingPassportKey = () => ({
  type: RESET_CUTTING_PASSPORT_KEY,
  cuttingPassportKey: "cuttingPassport_" + nextKey++,
});

export const resetSewingPassportKey = () => ({
  type: RESET_SEWING_PASSPORT_KEY,
  sewingPassportKey: "sewingPassport_" + nextKey++,
});

export const resetRealPriceCalculationModalKey = () => ({
  type: RESET_REAL_PRICE_CALCULATION_MODAL_KEY,
  realPriceCalculationModalKey: "realPrice_" + nextKey++,
});

export const resetCuttingSubmitPassportKey = () => ({
  type: RESET_CUTTING_SUBMIT_PASSPORT_KEY,
  cuttingSubmitPassportKey: "cuttingSubmitPassport_" + nextKey++,
});

export const resetSewingSubmitPassportKey = () => ({
  type: RESET_SEWING_SUBMIT_PASSPORT_KEY,
  sewingSubmitPassportKey: "sewingSubmitPassport_" + nextKey++,
});

export const resetCuttingOperationsModalKey = () => ({
  type: RESET_CUTTING_OPERATIONS_MODAL_KEY,
  cuttingOperationsModalKey: "cut_" + nextKey++,
});

export const resetSewingOperationsModalKey = () => ({
  type: RESET_SEWING_OPERATIONS_MODAL_KEY,
  sewingOperationsModalKey: "sew_" + nextKey++,
});

export const setShowCuttingEntryModal = showCuttingEntryModal => ({
  type: SHOW_CUTTING_ENTRY_MODAL_SET,
  showCuttingEntryModal,
});

export const setShowSearchEmployeeModal = (
  showSearchEmployeeModal,
  prodBomIndex,
  prodSpreadIndex,
  operationIndex,
) => ({
  type: SHOW_SEARCH_EMPLOYEE_MODAL_SET,
  showSearchEmployeeModal,
  prodBomIndex,
  prodSpreadIndex,
  operationIndex,
});

export const setShowSearchFabricModal = (
  showSearchFabricModal,
  prodBomIndex,
  prodSpreadIndex,
  consumptionIndex,
) => ({
  type: SHOW_SEARCH_FABRIC_MODAL_SET,
  showSearchFabricModal,
  prodBomIndex,
  prodSpreadIndex,
  consumptionIndex,
});

export const setShowAddOperationModal = (
  showAddOperationModal,
  prodBomIndex,
  prodBomId,
) => ({
  type: SHOW_ADD_OPERATION_MODAL_SET,
  showAddOperationModal,
  prodBomIndex,
  prodBomId,
});

export const setShowCuttingPassportModal = showCuttingPassportModal => ({
  type: SHOW_CUTTING_PASSPORT_MODAL,
  showCuttingPassportModal,
});

export const setShowSewingPassportModal = showSewingPassportModal => ({
  type: SHOW_SEWING_PASSPORT_MODAL,
  showSewingPassportModal,
});

export const setShowRealPriceCalculationModal = showRealPriceCalculationModal => ({
  type: SHOW_REAL_PRICE_CALCULATION_MODAL,
  showRealPriceCalculationModal,
});

export const setShowCuttingSubmitPassportModal = showCuttingSubmitPassportModal => ({
  type: SHOW_CUTTING_SUBMIT_PASSPORT_MODAL,
  showCuttingSubmitPassportModal,
});

export const setShowSewingSubmitPassportModal = showSewingSubmitPassportModal => ({
  type: SHOW_SEWING_SUBMIT_PASSPORT_MODAL,
  showSewingSubmitPassportModal,
});

export const setModalSelectedSpread = spreadId => ({
  type: MODAL_SELECTED_SPREAD_SET,
  spreadId,
});

export const setOpenedCuttingEntryId = cuttingEntryId => ({
  type: OPENED_CUTTING_ENTRY_ID_SET,
  cuttingEntryId,
});

export const setOpenedProdEntryId = prodEntryId => ({
  type: OPENED_PROD_ENTRY_ID_SET,
  prodEntryId,
});

export const setSelectedProdBomId = prodBomId => ({
  type: SELECTED_PRODBOM_SET,
  prodBomId,
});

export const setShowProdEntryModal = showProdEntryModal => ({
  type: SHOW_PROD_ENTRY_MODAL_SET,
  showProdEntryModal,
});

export const setShowCuttingWageModal = showCuttingWageModal => ({
  type: SHOW_CUTTING_WAGE_MODAL_SET,
  showCuttingWageModal,
});

export const setShowWageClaimModal = showWageClaimModal => ({
  type: SHOW_WAGE_CLAIM_MODAL_SET,
  showWageClaimModal,
});

export const setShowCuttingOperationsModal = showCuttingOperationsModal => ({
  type: SHOW_CUTTING_OPERATIONS_MODAL_SET,
  showCuttingOperationsModal,
});

export const setShowSewingOperationsModal = showSewingOperationsModal => ({
  type: SHOW_SEWING_OPERATIONS_MODAL_SET,
  showSewingOperationsModal,
});

export const setOpenedWageId = wageId => ({
  type: OPENED_WAGE_ID_SET,
  wageId,
});

export const setShowProdWageModal = showProdWageModal => ({
  type: SHOW_PROD_WAGE_MODAL_SET,
  showProdWageModal,
});

export const setSelectedOrderValue = selectedOrderValue => ({
  type: SET_SELECTED_ORDER_VALUE,
  selectedOrderValue,
});

export const setShowCompletionModalForCutting = showCompletionModal => ({
  type: "SHOW_COMPLETION_MODAL_FOR_CUTTING",
  showCompletionModal,
});

export const setOrderIdForCompletionModalForCutting = orderId => ({
  type: "SET_ORDER_ID_FOR_COMPLETION_MODAL_FOR_CUTTING",
  orderId,
});
