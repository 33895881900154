import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ShipmentDetail from "./components/ShipmentDetail";
import ShipmentMain from "./index";

class ShipmentRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/shipment" component={ShipmentMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={ShipmentDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={ShipmentDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default ShipmentRoute;
