import { notification } from "antd";
import axios from "axios";

export const STATISTICS_BY_PERIOD_FETCH_SUCCESS =
  "STATISTICS_BY_PERIOD_FETCH_SUCCESS ";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchReportByPeriods = periods => {
  return dispatch => {
    return axios
      .get(
        `/api/production_statistics/production/byPeriods?periods=${periods.join(
          ",",
        )}`,
      )
      .then(({ data }) => {
        let reports = data;
        dispatch({
          type: STATISTICS_BY_PERIOD_FETCH_SUCCESS,
          response: reports,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключится к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
