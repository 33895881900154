import React, { PureComponent } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default class BarComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      //loading: true,
      searchEnabled: false,
    };
  }

  render() {
    return (
      <BarChart
        width={1000}
        height={400}
        data={this.props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Выкроено" fill="#1890ff" />
        <Bar dataKey="Отдано в цех" fill="#FF5722" />
      </BarChart>
    );
  }
}
