import { combineReducers } from "redux";

import {
  PRODUCTS_FETCH_SUCCESS,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_FETCH_SUCCESS,
  PRODUCT_UPDATE_SUCCESS,
} from "./actions";

const initialState = { name: "", article: "", description: "", sizes: [] };

const byId = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.products,
      };
    case PRODUCT_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.products,
      };
    case PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.products,
      };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.products,
      };
    case PRODUCT_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.productId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }
    //   case 'BOM_ADD_SUCCESS':

    //       const productId=action.productId;
    //       const bomId=action.response.result;
    //       const product=state[productId]

    //       return {
    //          ...state,
    //          [productId] : {
    //             ...product,
    //             boms : product.boms.concat(bomId)
    //          }
    //      }
    //   case 'BOM_DELETE_SUCCESS':
    //         return deleteBom(state,action);

    default:
      return state;
  }
};

// function deleteBom(state, action){
//     let bomId = action.bomId;
//     let productId = action.productId;
//     let product = state[productId]
//     return {
//         ...state,
//         [productId] : {
//             ...product,
//             boms : product.boms.filter(id=>id!=bomId)
//         }
//     }
//   }

const allIds = (state = [], action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_SUCCESS:
      return action.response.result;
    case PRODUCT_CREATE_SUCCESS:
      return [...state, action.response.result];
    case PRODUCT_DELETE_SUCCESS:
      return [...state.filter(id => id != action.productId)];
    default:
      return state;
  }
};

export const products = combineReducers({
  allIds,
  byId,
});

//SELECTORS
