import axios from "axios";

export function addNotification(title, message, level) {
  return {
    type: "ADD_NOTIFICATION",
    message,
    level,
    title,
  };
}
