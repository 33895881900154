import { combineReducers } from "redux";

import { ROLES_FETCH_SUCCESS } from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case ROLES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.roles,
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case ROLES_FETCH_SUCCESS:
      return action.response.result;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  allIds,
  byId,
});

export const getAllRoles = state => {
  return state.data.roles.allIds.map(id => state.data.roles.byId[id]);
};
