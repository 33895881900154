import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ProductionStatsMain from "./index";

// import FabStockDetail from "./components/FabStockDetail";
///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class ProductionStatsRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/productionstats/:statisticType?"
            component={ProductionStatsMain}
          />
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={`${this.props.match.url}/new`}*/}
          {/*  component={FabStockDetail}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={`${this.props.match.url}/:id`}*/}
          {/*  component={FabStockDetail}*/}
          {/*/>*/}
        </Switch>
      </div>
    );
  }
}

export default ProductionStatsRoute;
