import { normalize, schema } from "normalizr";

// Define a users schema

export const fabStockMovementRequest = new schema.Entity(
  "fabStockMovementRequest",
  {},
  { idAttribute: "id" },
);

export const arrayOfFabStocks = [fabStockMovementRequest];
