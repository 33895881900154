let nextId = 1;

export const addSpread = (spread, bomId) => ({
  type: "SPREAD_ADD",
  spread,
  spreadId: nextId++,
  bomId,
});

export const saveSpread = spread => ({
  type: "SPREAD_UPDATE",
  spread,
});

export const deleteSpread = (spreadId, bomId) => ({
  type: "SPREAD_DELETE",
  spreadId,
  bomId,
});
