import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";

class ProdEntriesTable extends Component {
  constructor(props) {
    super(props);
  }

  confirm = (row, e) => {
    const { onRemove } = this.props;
    console.log(e);
    //message.success('Click on Yes');
    onRemove(row, e);
  };

  cancel = e => {
    console.log(e);
    //message.error('Click on No');
  };

  render() {
    const {
      prodEntries,
      onEditClick,
      onRemove,
      classes,
      numberOfRows,
      showEditControls,
    } = this.props;

    //console.log("TABLE PROD ENTREIES",prodEntries);
    const columns = [
      {
        Header: "Дата",
        accessor: "entryDate", // String-based value accessors!
        Cell: props => (
          <span>{props.value ? new moment(props.value).format("L") : ""}</span>
        ), // Custom cell components!
      },
      {
        Header: "Период",
        accessor: "period", // String-based value accessors!
        Cell: props => (
          <span className="text">{props.value && props.value.name}</span>
        ), // Custom cell components!
      },
      {
        Header: "Размер",
        accessor: "size", // String-based value accessors!
        Cell: props => <span className="text">{props.original.size.name}</span>, // Custom cell components!
      },
      {
        Header: "Количество",
        accessor: "quantity",
        Cell: props => <span className="text">{props.original.quantity}</span>, // Custom cell components!
      },
      {
        Header: "Работник",
        accessor: "employee",
        Cell: props => (
          <span className="text">
            {props.original.employee.last_name +
              " " +
              props.original.employee.first_name}
          </span>
        ), // Custom cell components!
      },
      {
        Header: "Бригада",
        accessor: "team",
        Cell: props => <span className="text">{props.original.team.name}</span>, // Custom cell components!
      },
    ];

    if (showEditControls) {
      columns.splice(0, 0, {
        Header: "",
        accessor: "id",
        maxWidth: 60,
        Cell: row => (
          <div>
            <Button
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={e => onEditClick(row.original, e)}
            >
              {/* <OpenInNew classes={{ root: classes.iconRoot }} />
                                <Icon type="edit" style={{ fontSize: 16, color: '#08c' }} /> */}
            </Button>
          </div>
        ),
      });
      columns.push({
        Header: "Удалить",
        //accessor: 'product.name',
        maxWidth: 100,
        Cell: row => (
          <div>
            {/* <Button type="danger" size="small" icon="delete" onClick={(e)=>this.handleClickOpen(row.original,e)} classes={{ root: classes.buttonRoot }} >
                              
                            </Button> */}
            <Popconfirm
              placement="topRight"
              title="Уверены что хотите удалить эту запись?"
              onConfirm={e => this.confirm(row.original, e)}
              onCancel={this.cancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button type="danger" size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
      });
    }

    return (
      <div>
        <ReactTable
          className="-striped -highlight"
          data={prodEntries}
          columns={columns}
          defaultPageSize={numberOfRows != undefined ? numberOfRows : 20}
        />
      </div>
    );
  }
}

// ProdEntriesTable.propTypes = {
//     onEditClick: PropTypes.func.isRequired,
//     onRemove: PropTypes.func.isRequired,

// };

export default withRouter(ProdEntriesTable);
