import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";

//import {addNotification} from '../../actions'

export const WAGE_ENTRY_CLAIM_SUCCESS = "WAGE_ENTRY_CLAIM_SUCCESS";
export const WAGE_ENTRY_UNCLAIM_SUCCESS = "WAGE_ENTRY_UNCLAIM_SUCCESS";

export const WAGE_ENTRY_CLAIM_MANUALLY_SUCCESS =
  "WAGE_ENTRY_CLAIM_MANUALLY_SUCCESS";
export const WAGE_ENTRY_UNCLAIM_MANUALLY_SUCCESS =
  "WAGE_ENTRY_UNCLAIM_MANUALLY_SUCCESS";

export const WAGE_ENTRIES_FETCH_SUCCESS = "WAGE_ENTRIES_FETCH_SUCCESS";
export const WAGE_ENTRY_FETCH_SUCCESS = "WAGE_ENTRY_FETCH_SUCCESS";
export const WAGE_ENTRY_CREATE_SUCCESS = "WAGE_ENTRY_CREATE_SUCCESS";
export const WAGE_ENTRY_UPDATE_SUCCESS = "WAGE_ENTRY_UPDATE_SUCCESS";
export const WAGE_ENTRY_DELETE_SUCCESS = "WAGE_ENTRY_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchWage = wageId => {
  return dispatch => {
    return axios
      .get(`/api/wages/` + wageId)
      .then(({ data }) => {
        let wage = data;
        let id = wageId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: WAGE_ENTRY_FETCH_SUCCESS,
          response: normalize(wage, schema.wage),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        //console.log("err",error.response);
        if (error.response) {
          let { data } = error.response;

          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || data.message || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const claimWageEntry = (employeeId, wageEntry) => {
  return dispatch => {
    return axios
      .post("/api/wage_claim/" + employeeId, wageEntry)
      .then(({ data }) => {
        let addedEntry = data;
        //dispatch({type: 'PATTERN_ADD_SUCCESS', spreads});
        dispatch({
          type: WAGE_ENTRY_CLAIM_SUCCESS,
          response: normalize(addedEntry, schema.wage),
          employeeId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Штрих код просканирован",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || data.message || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const unClaimWageEntry = (employeeId, entry) => {
  return dispatch => {
    return axios
      .delete("/api/wage_claim/" + entry.id, { idToDelete: entry.id })
      .then(({ data }) => {
        //let spreads=json;
        let updatedEntry = data;
        let wageId = entry.id;
        let employeeId = entry.employee_id;
        dispatch({
          type: WAGE_ENTRY_UNCLAIM_SUCCESS,
          response: normalize(updatedEntry, schema.wage),
          wageId,
          employeeId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || data.message || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const claimWageEntryManually = (employeeId, wageEntry) => {
  return dispatch => {
    return axios
      .post("/api/wage_claim/" + employeeId, wageEntry)
      .then(({ data }) => {
        let addedEntry = data;
        //dispatch({type: 'PATTERN_ADD_SUCCESS', spreads});
        dispatch({
          type: WAGE_ENTRY_CLAIM_MANUALLY_SUCCESS,
          response: normalize(addedEntry, schema.wage),
          employeeId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Сотрудник успешно указан",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const unClaimWageEntryManually = (employeeId, entry) => {
  return dispatch => {
    return axios
      .delete("/api/wage_claim/" + entry.id, { idToDelete: entry.id })
      .then(({ data }) => {
        //let spreads=json;
        let updatedEntry = data;
        let wageId = entry.id;
        let employeeId = entry.employee_id;
        dispatch({
          type: WAGE_ENTRY_UNCLAIM_MANUALLY_SUCCESS,
          response: normalize(updatedEntry, schema.wage),
          wageId,
          employeeId,
        });
        openNotificationWithIcon(
          "Успешно",
          "Указанная операция освобождена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
