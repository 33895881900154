import { combineReducers } from "redux";

const initialState = [];

const getAvailableId = bomsById => {
  return Object.keys(bomsById).length + 1;
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case "BOM_ADD": {
      let bom = {
        ...action.bom,
        id: action.bomId,
        sortOrderId: action.bom.sortOrderId
          ? action.bom.sortOrderId
          : getAvailableId(state),
        spreads: [],
      };
      return {
        ...state,
        [action.bomId]: bom,
      };
    }
    case "BOM_MOVE": {
      const { bomId, direction } = action;
      const currentBom = state[bomId];
      const bomToSwapId = Object.keys(state).filter(
        bomId =>
          state[bomId].sortOrderId ===
          currentBom.sortOrderId + (direction === "up" ? -1 : 1),
      );
      const bomToSwap = state[bomToSwapId];
      return {
        ...state,
        [action.bomId]: {
          ...currentBom,
          sortOrderId: bomToSwap.sortOrderId,
        },
        [bomToSwapId]: {
          ...bomToSwap,
          sortOrderId: currentBom.sortOrderId,
        },
      };
    }
    case "BOM_UPDATE":
      let oldSpreads = state[action.bom.id].spreads;
      return {
        ...state,
        [action.bom.id]: { ...action.bom, spreads: oldSpreads },
      };
    case "SPREAD_ADD":
      let bomId = action.bomId;
      let spreadId = action.spreadId;
      let bom = state[bomId];

      return {
        ...state,
        [bomId]: {
          ...bom,
          spreads: bom.spreads.concat(spreadId),
        },
      };

    case "SPREAD_DELETE":
      return deleteSpread(state, action);

    case "BOM_DELETE":
      const sortOrderId = state[action.bomId].sortOrderId;
      var filtered = Object.keys(state)
        .filter(key => key != action.bomId)
        .map(key => {
          const bom = state[key];
          if (bom.sortOrderId > sortOrderId) {
            bom.sortOrderId = bom.sortOrderId - 1;
          }
          return { [key]: bom };
        });
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }
    case "RESET_SPEC_FORM":
      return {};

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "BOM_ADD":
      return [...state, action.bomId];
    case "BOM_DELETE":
      return [...state.filter(id => id != action.bomId)];
    case "RESET_SPEC_FORM":
      return [];
    default:
      return state;
  }
};

function deleteSpread(state, action) {
  let bomId = action.bomId;
  let spreadId = action.spreadId;
  let bom = state[bomId];
  return {
    ...state,
    [bomId]: {
      ...bom,
      spreads: bom.spreads.filter(id => id != spreadId),
    },
  };
}

export const boms = combineReducers({
  byId,
  allIds,
});
