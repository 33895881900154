import { combineReducers } from "redux";

import { reducer as dataReducer } from "./data/reducer";

const openedRevisionId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_REVISION_ID":
      return action.revisionId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedRevisionId,
  data: dataReducer,
});
