import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import UserDetail from "./components/UserDetail";
import UserMain from "./index.js";

///import MaterialDetailContainer from './MaterialDetailContainer'

//import ServiceEntryList from './components/ServiceEntryList'

class UserRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/user" component={UserMain} />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={UserDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={UserDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default UserRoute;
