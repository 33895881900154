//import {getOpenedSpec} from '../../selectors'

import { Button, Input, notification } from "antd";
import { Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import {
  createSpec,
  fetchSpec,
  updateSpec,
} from "../../../../data/specs/actions";
import SpecForm from "../../../forms/SpecForm";
import BomDetail from "../../../forms/SpecForm/components/BomDetail";
import PatternDetail from "../../../forms/SpecForm/components/PatternDetail";
import SpreadDetail from "../../../forms/SpecForm/components/SpreadDetail";
import { setOpenedSpecId } from "../../actions";
import FormValidator from "./FormValidator";

//import { getFormBoms, getSpecModelFromState } from './selectors';
const Option = Select.Option;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

class SpecDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInEditState: false,
      loading: false,
    };
    this.formRef = React.createRef();

    //console.log("ref",this.formRef)
  }

  toggleEditState = () => {
    this.setState({ isInEditState: true });
  };

  createSpecFromValues = () => {
    const { specModel } = this.props;
    console.log("MODEL", specModel);
    return specModel;
  };

  handleSubmit = () => {
    const { createSpec, match, updateSpec } = this.props;

    this.formRef.current.validate((err, spec) => {
      if (err) {
        return;
      }

      this.setState({ loading: true });

      console.log("form valid", spec);
      if (match.params.specId) {
        return updateSpec(match.params.specId, spec).then(
          json => {
            this.setState({ loading: false });
            this.setState({ isInEditState: false });
            this.props.history.push(`/specification/` + json.id);
          },
          error => {
            this.setState({ loading: false });
            if (error.name) {
              this.setState({ nameExists: true });
            }
            // let subError=this.mapErrorsToObjectMap(error);
            // throw new SubmissionError(subError)
          },
        );
      }
      //Handle create
      else {
        console.log("yes yes inside else");
        return createSpec({ name: spec.name, boms: spec.boms })
          .then(json => {
            this.setState({ loading: false });
            this.setState({ isInEditState: false });
            this.props.history.push(`/specification/` + json.id + "/true");
          })
          .catch(({ response }) => {
            this.setState({ loading: false });
            let error = response.data;
            if (error.name) {
              openNotificationWithIcon(
                "Ошибка",
                "Спецификация с таким названием уже существует",
                "error",
              );
              this.setState({ nameExists: true });
            }
          });
      }
    });
  };

  componentDidMount() {
    //console.log("ref",this.formRef.current)

    this.fetchSpecOrResetForm();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.specId !== match.params.specId) {
      this.fetchSpecOrResetForm();
    }
  }

  clearForm = () => {
    //console.log("REF", this.formRef.current);
    this.formRef.current.clearForm();

    // const {  setSpecName, addPattern, boms, deleteBom } = this.props;
    // boms.forEach(bom => {
    //     deleteBom(bom.id);
    // })
    // setSpecName('')
  };

  initializeSpecForm = spec => {
    // const {   setSpecName, addBom, addSpread, addPattern } = this.props;

    // setSpecName(spec.name);

    // spec.boms.forEach(bom => {
    //     let bomAction = addBom(bom);

    //     bom.spreads.forEach(spread => {
    //         let spreadAction = addSpread(spread, bomAction.bomId)
    //         //setSelectedSpreadTab(bomAction.bomId, spreadAction.id)
    //         spread.patterns.forEach(pattern => {
    //             addPattern(pattern, spreadAction.spreadId)
    //         })
    //     })
    // })
    this.formRef.current.initializeSpecForm(spec);
  };

  fetchSpecOrResetForm = () => {
    const {
      fetchSpec,
      match,
      setOpenedSpecId,
      setSpecName,
      addBom,
      addSpread,
      addPattern,
      boms,
      deleteBom,
      setSelectedSpreadTab,
    } = this.props;
    setOpenedSpecId("");
    if (match.params.specId) {
      console.log("SAVED", match.params.saved);

      //only clear and fetch if not just saved
      if (!match.params.saved) {
        this.clearForm();

        fetchSpec(match.params.specId).then(spec => {
          setOpenedSpecId(spec.id);
          this.setState({ isInEditState: false });
          this.initializeSpecForm(spec);
        });
      }
    } else {
      this.clearForm();
      this.setState({ isInEditState: true });
    }
  };

  handleNameChange = e => {
    const { setSpecName } = this.props;
    //this.setState({ name: e.target.value });
    this.setState({ nameExists: false });
    setSpecName(e.target.value);
  };

  handleAddBom = () => {
    this.props.setOpenedBom("");
    this.props.setShowBomModal(true);
  };

  render() {
    const { history, match } = this.props;

    return (
      <div className="mt-paper">
        <div>
          <h2>Спецификация</h2>
        </div>
        {match.params.specId && this.state.isInEditState && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
          >
            Сохранить
          </Button>
        )}
        {match.params.specId == undefined && (
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
          >
            Создать
          </Button>
        )}
        {match.params.specId && !this.state.isInEditState && (
          <Button onClick={this.toggleEditState}>Изменить</Button>
        )}
        <br />
        &nbsp;
        <SpecForm disabled={!this.state.isInEditState} ref={this.formRef} />
        <BomDetail disabled={!this.state.isInEditState} />
        <PatternDetail />
        <SpreadDetail />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books
    //formBoms: state.scenes.specification.formBoms
    //specModel: getSpecModelFromState(state)
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchSpec: id => dispatch(fetchSpec(id)),
    setOpenedSpecId: id => dispatch(setOpenedSpecId(id)),
    createSpec: employee => dispatch(createSpec(employee)),
    updateSpec: (id, employee) => dispatch(updateSpec(id, employee)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SpecDetail),
);
