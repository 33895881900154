import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Input, InputNumber, Select, Switch } from "antd";
import axios from "axios";
import AsyncSelect from "components/AsyncSelect";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { getAllDepotLocations } from "../../../../../data/depotLocations/reducer";
import { addProdOperation } from "../../../../../data/prodOperations/actions";
import { fetchSpec } from "../../../../../data/specs/actions";
import { setShowAddOperationModal } from "../../../actions";
import { getOpenedCuttingOrder } from "../../../selectors";

const Option = Select.Option;
const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "400px",
        minHeight: "200px",
      },
      root: {
        //alignItems: "initial"
        zIndex: 1000,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class AddOperationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      price: 0,
      addToSpec: false,
      operationTypeId: this.props.modalType === "cutting" ? 1 : 2,
      isSaving: false,
      selectedBomId: undefined,
      boms: [],
    };
  }
  close = () => {
    this.props.setShowAddOperationModal(false);
  };

  handleAddOperation = () => {
    const { openedOrder, addOperationIndices } = this.props;
    const { prodBomIndex, prodBomId } = addOperationIndices;
    this.setState({ isSaving: true });
    this.props
      .addNewOperation(openedOrder.id, prodBomId, {
        name: this.state.name,
        price: this.state.price,
        operationTypeId: this.state.operationTypeId,
        selectedBomId: this.state.selectedBomId,
      })
      .then(
        newOperation => {
          this.setState({ isSaving: false });
          this.props.addNewOperationToForm(
            prodBomIndex,
            prodBomId,
            newOperation,
          );
          this.close();
        },
        error => {
          this.setState({ isSaving: false });
        },
      );
  };

  componentDidMount() {
    this.props.fetchSpec(this.props.openedOrder.spec_id).then(spec => {
      this.setState({ boms: spec.boms });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal !== prevProps.showModal) {
      this.setState({
        name: "",
        price: 0,
        addToSpec: false,
        selectedBomId: undefined,
      });
    }
  }

  componentWillReceiveProps(nextProps) {}

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleBomIdChange = e => {
    this.setState({ selectedBomId: e });
  };

  handlePriceChange = e => {
    this.setState({ price: e });
  };

  handleOperationTypeIdChange = e => {
    this.setState({ operationTypeId: e });
  };

  render() {
    const { showModal, openedPattern } = this.props;
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Добавить операцию
            </DialogTitle>
            <DialogContent>
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="name">
                  Название
                </label>
                <Input
                  name="name"
                  placeholder="Название"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
              </div>
              {this.props.modalType === "sewing" && (
                <div style={{ marginBottom: 15 }}>
                  <label className="mt-label" htmlFor="name">
                    Тип операции
                  </label>
                  <br />
                  <Select
                    name="name"
                    style={{ width: "100%" }}
                    placeholder="Тип"
                    value={this.state.operationTypeId}
                    onChange={this.handleOperationTypeIdChange}
                  >
                    <Option value={2}>Швейный</Option>
                    <Option value={3}>Упаковочный</Option>
                  </Select>
                </div>
              )}
              <div style={{ marginBottom: 15 }}>
                <label className="mt-label" htmlFor="name">
                  Цена
                </label>
                <br />
                <InputNumber
                  name="price"
                  style={{ width: "100%" }}
                  placeholder="Цена"
                  precision={0}
                  value={this.state.price}
                  onChange={this.handlePriceChange}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <Switch
                  checked={this.state.addToSpec}
                  onClick={() =>
                    this.setState({ addToSpec: !this.state.addToSpec })
                  }
                />
                &nbsp;
                <span>Добавить в спецификацию</span>
              </div>
              {this.state.addToSpec && (
                <div style={{ marginBottom: 15 }}>
                  <label className="mt-label" htmlFor="name">
                    Часть спецификации
                  </label>
                  <br />
                  <Select
                    name="name"
                    placeholder="Выберите часть"
                    style={{ width: "100%", marginBottom: "20px" }}
                    value={this.state.selectedBomId}
                    onChange={this.handleBomIdChange}
                  >
                    {this.state.boms.map(bom => (
                      <Select.Option key={bom.id} value={bom.id}>
                        {bom.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={
                  !this.state.name ||
                  !(this.state.price > 0) ||
                  (this.state.addToSpec && !this.state.selectedBomId)
                }
                type="primary"
                onClick={this.handleAddOperation}
                loading={this.state.isSaving}
              >
                Сохранить
              </Button>

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

AddOperationModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    depotLocations: getAllDepotLocations(state),
    showModal: state.scenes.cutting.showAddOperationModal,
    addOperationIndices: state.scenes.cutting.addOperationIndices,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowAddOperationModal: showModal =>
      dispatch(setShowAddOperationModal(showModal)),
    addNewOperation: (order_id, prodBomId, newOperation) =>
      dispatch(addProdOperation(order_id, prodBomId, newOperation)),
    fetchSpec: specId => dispatch(fetchSpec(specId)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddOperationModal),
);
