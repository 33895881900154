import { combineReducers } from "redux";

import {
  PROD_ENTRIES_FETCH_SUCCESS,
  PROD_ENTRY_CREATE_SUCCESS,
  PROD_ENTRY_DELETE_SUCCESS,
  PROD_ENTRY_FETCH_SUCCESS,
  PROD_ENTRY_UPDATE_SUCCESS,
} from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case PROD_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodEntries,
      };
    case PROD_ENTRY_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodEntries,
      };
    case PROD_ENTRY_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodEntries,
      };
    case PROD_ENTRY_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.prodEntries,
      };
    case PROD_ENTRY_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.prodEntryId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

// const allIds = (state = [], action) => {
//     switch (action.type) {
//         case 'CUTTING_ENTRIES_FETCH_DATA_SUCCESS':
//             return action.response.result
//         case 'CUTTING_ENTRY_CREATE_SUCCESS':
//             return [...state, action.response.result]
//         case 'CUTTING_ENTRY_DELETE_SUCCESS':
//             return [...state.filter(id=>id!=action.cuttingEntryId)]
//         default:
//             return state;
//     }
// }

export const prodEntries = combineReducers({
  byId,
});

//SELECTORS
