import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "../schema";

export const PASSPORT_ENTRY_CREATE_SUCCESS = "PASSPORT_ENTRY_CREATE_SUCCESS";
export const SEWING_PASSPORT_ENTRY_CREATE_SUCCESS =
  "SEWING_PASSPORT_ENTRY_CREATE_SUCCESS";
export const PASSPORT_ENTRY_UPDATE_SUCCESS = "PASSPORT_ENTRY_UPDATE_SUCCESS";
export const SEWING_PASSPORT_ENTRY_UPDATE_SUCCESS =
  "SEWING_PASSPORT_ENTRY_UPDATE_SUCCESS";
export const CALCULATE_REAL_PRICE = "CALCULATE_REAL_PRICE ";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const createCuttingPassportEntry = (
  orderId,
  passportEntry,
) => dispatch =>
  axios
    .post(`/api/order/${orderId}/passport_entry/cutting/`, passportEntry)
    .then(({ data }) => {
      let passportEntry = data;
      dispatch({
        type: PASSPORT_ENTRY_CREATE_SUCCESS,
        passportEntry,
      });
      openNotificationWithIcon(
        "Успешно",
        "Запись успешно добавлена",
        "success",
      );
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const createSewingPassportEntry = (orderId, passportEntry) => dispatch =>
  axios
    .post(`/api/order/${orderId}/passport_entry/sewing/`, passportEntry)
    .then(({ data }) => {
      let passportEntry = data;
      dispatch({
        type: SEWING_PASSPORT_ENTRY_CREATE_SUCCESS,
        passportEntry,
      });
      openNotificationWithIcon(
        "Успешно",
        "Запись успешно добавлена",
        "success",
      );
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const updateSewingPassportEntry = (
  orderId,
  passportEntryId,
  passportEntry,
) => dispatch =>
  axios
    .post(
      `/api/order/${orderId}/passport_entry/sewing/${passportEntryId}`,
      passportEntry,
    )
    .then(({ data }) => {
      let passportEntry = data;
      dispatch({
        type: SEWING_PASSPORT_ENTRY_UPDATE_SUCCESS,
        passportEntry,
      });
      openNotificationWithIcon(
        "Успешно",
        "Запись успешно добавлена",
        "success",
      );
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const calculateRealPrice = (
    orderId,
    passportEntryId,
    fabricPrices
) => dispatch =>
    axios
        .post(
            `/api/order/${orderId}/passport_entry/cutting/${passportEntryId}/calculate-real-price`,
            {
                fabricPrices
            }
        )
        .then(({ data }) => {
            let value = data;
            dispatch({
                type: CALCULATE_REAL_PRICE,
                orderId,
                passportEntryId,
                value
            });
            openNotificationWithIcon(
                "Успешно",
                "Запись успешно добавлена",
                "success",
            );
            return data;
        })
        .catch(error => {
            if (error.handledGlobally) {
                return Promise.reject();
            }
            if (error.response) {
                let { data } = error.response;
                openNotificationWithIcon(
                    "Ошибка",
                    data.errorMessage || "Произошла неизвестная ошибка",
                    "error",
                );
                throw error;
            } else {
                openNotificationWithIcon(
                    "Ошибка",
                    "Невозможно подключиться к серверу",
                    "error",
                );
                throw error;
            }
        });

export const updateCuttingPassportEntry = (
  orderId,
  passportEntryId,
  passportEntry,
) => dispatch =>
  axios
    .post(
      `/api/order/${orderId}/passport_entry/cutting/${passportEntryId}`,
      passportEntry,
    )
    .then(({ data }) => {
      let passportEntry = data;
      dispatch({
        type: PASSPORT_ENTRY_UPDATE_SUCCESS,
        passportEntry,
      });
      openNotificationWithIcon(
        "Успешно",
        "Запись успешно добавлена",
        "success",
      );
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });
