import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Modal } from "antd";
import { fetchPeriods } from "data/periods/actions";
import { createProdEntry, updateProdEntry } from "data/prodEntries/actions";
import { fetchTeams } from "data/teams/actions";
import { getAllTeams } from "data/teams/reducer";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setOpenedProdEntryId,
  setShowProdEntryModal,
} from "scenes/Cutting/actions";
import ProdEntryForm from "scenes/Cutting/forms/ProdEntryForm";
import {
  getOpenedCuttingOrder,
  getOpenedProdEntry,
  getOpenedProdEntryId,
  getSelectedProdBom,
} from "scenes/Cutting/selectors";
import {
  checkAvailable,
  decodeStatus,
  generateProdEntryData,
  getDefaultPrinter,
  readPrinterStatus,
  sendToPrinter,
} from "scenes/printHelper";

const confirm = Modal.confirm;

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the styleSheet
      paperWidthSm: {
        // Name of the rule
        maxWidth: "800px",
        minWidth: "800px",
      },
      root: {
        alignItems: "initial",
        zIndex: 500,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class ProdEntryDetail extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      size: null,
      entryDate: null,
      selectedEmployee: undefined,
      team: null,
      quantity: undefined,
      isPrinting: false,
      periodOptions: [],
      selectedPeriod: null,
    };

    const { openedProdEntry } = this.props;

    if (this.props.openedProdEntry) {
      let nextEntry = this.props.openedProdEntry;

      //this.state.selectedSizeId = nextEntry.size_id;
      this.state.quantity = nextEntry.quantity;
      this.state.entryDate = nextEntry.entryDate
        ? new moment(nextEntry.entryDate)
        : null;
      this.state.selectedEmployee = {
        ...nextEntry.employee,
        value: nextEntry.employee.id,
        label:
          nextEntry.employee.first_name + " " + nextEntry.employee.last_name,
      };

      //this.state.selectedTeamId = nextEntry.team_id;
      this.state.isPrinting = false;

      if (openedProdEntry.period) {
        let period = openedProdEntry.period;
        this.state.selectedPeriod = {
          label: period.name,
          value: period.id,
          ...period,
        };
      }

      if (openedProdEntry.size) {
        let size = openedProdEntry.size;
        this.state.size = {
          label: size.name,
          value: size.id,
          ...size,
        };
      }

      if (openedProdEntry.team) {
        let team = openedProdEntry.team;
        this.state.team = {
          label: team.name,
          value: team.id,
          ...team,
        };
      }

      //fetchTeams();
    }
  }

  close = () => {
    this.props.setShowProdEntryModal(false);
    //this.props.setOpenedProdEntryId('')
  };

  handleFormSubmit = (values, { setSubmitting }) => {
    console.log("values", values);

    const {
      createProdEntry,
      openedOrder,
      selectedProdBom,
      updateProdEntry,
      user,
      openedProdEntry,
      setOpenedProdEntryId,
    } = this.props;

    this.setState({ isPrinting: true });

    let prodEntryModel = {
      id: openedProdEntry ? openedProdEntry.id : null,

      entryDate: new moment(values.entryDate).format("YYYY-MM-DD HH:mm:ss"),

      employee_id: values.employee.id,
      prodBom_id: selectedProdBom.id,
      size_id: values.size.id,
      order_id: openedOrder.id,
      team_id: values.team.id,
      quantity: values.quantity,
      period: values.period,
    };

    if (openedProdEntry) {
      updateProdEntry(openedOrder.id, prodEntryModel).then(
        prodEntry => {
          //this.props.setShowProdEntryModal(false);
          //this.setState({isPrinting: true})
          this.printLabelsForProdEntry(prodEntry);
          //setSelectedSpreadTab(selectedBomId, spread.id);
        },
        error => {
          this.setState({ isPrinting: false });
        },
      );
    } else {
      createProdEntry(openedOrder.id, prodEntryModel).then(
        prodEntry => {
          this.printLabelsForProdEntry(prodEntry);
        },
        error => {
          this.setState({ isPrinting: false });
        },
      );
    }
  };

  printLabelsForProdEntry = prodEntry => {
    const { openedOrder, user } = this.props;
    getDefaultPrinter()
      .then(json => {
        //check if the default printer is available for printing
        return checkAvailable().then(available => {
          let foundPrinter = undefined;
          if (available.printer && available.printer.length > 0) {
            //return json
            available.printer.forEach(availablePrinter => {
              if (availablePrinter.name == json.name) {
                foundPrinter = availablePrinter;
              }
            });
          }
          if (foundPrinter == undefined) {
            throw "Принтер не подключен";
          } else {
            //send read printer status request
            return sendToPrinter(json, "~HQES").then(data => {
              return json;
            });
          }
        });
      })
      .then(printer => {
        //check if the printer is ready to print
        return readPrinterStatus(printer)
          .then(text => {
            let status = decodeStatus(text);
            if (status == "Ready to Print") {
              let data = "";
              prodEntry.wages.forEach(wage => {
                data += generateProdEntryData(
                  "01" + wage.id,
                  wage.prodOperation.operation,
                  wage.quantity,
                  wage.prodOperation.operationType.type,
                  wage.sum,
                  prodEntry.size.name,
                  openedOrder.orderNumberStr,
                  openedOrder.product.name,
                  user ? user.domain : "",
                );
              });
              return sendToPrinter(printer, data).then(res => {
                this.close();
              });
            } else {
              throw status;
            }
          })
          .catch(error => {
            this.showConfirm(prodEntry, error);
          });
      })
      .catch(error => {
        this.showConfirm(prodEntry, error);
      });
  };

  componentDidMount() {
    const { fetchTeams } = this.props;
    fetchTeams();

    const { fetchPeriods } = this.props;
    fetchPeriods().then(periods => {
      let filteredPeriods = periods.filter(period => {
        if (!period.payroll) {
          return true;
        } else if (period.payroll.isClosed == 0) {
          return true;
        } else return false;
        //this.isEmpty(period.payroll) || period.payroll.isClosed==0
      });
      this.setState({
        periodOptions: filteredPeriods.map(period => {
          return { label: period.name, value: period.id, ...period };
        }),
      });
    });
  }

  showConfirm = (prodEntry, message) => {
    let self = this;
    confirm({
      title: "Попробовать напечатать заново?",
      content: message,
      onOk() {
        self.printLabelsForProdEntry(prodEntry);
      },
      onCancel() {
        self.close();
      },
    });
  };

  handleCreate = () => {
    this.form.current.submitForm();
  };

  render() {
    //coming from HOC
    const { fullScreen, classes } = this.props;
    const { isPrinting } = this.state;

    const { showModal, openedProdEntry, openedOrder, teams } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
            //classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle id="responsive-dialog-title">
              {openedProdEntry != undefined
                ? "Запись: " + openedProdEntry.id
                : "Отдать в швейный цех"}
            </DialogTitle>
            <DialogContent>
              <br />

              <ProdEntryForm
                periodOptions={this.state.periodOptions}
                teamOptions={teams}
                sizeOptions={openedOrder.lineItems}
                ref={this.form}
                onSubmit={this.handleFormSubmit}
                prodEntryId={openedProdEntry ? openedProdEntry.id : null}
                initialValues={{
                  entryDate: this.state.entryDate,
                  period: this.state.selectedPeriod,
                  size: this.state.size,
                  quantity: this.state.quantity,
                  employee: this.state.selectedEmployee,
                  team: this.state.team,
                }}
              />
            </DialogContent>
            <DialogActions>
              {openedProdEntry == undefined && (
                <Button
                  type="primary"
                  loading={isPrinting}
                  onClick={this.handleCreate}
                >
                  Создать и распечатать
                </Button>
              )}
              {openedProdEntry != undefined && (
                <Button
                  type="primary"
                  loading={isPrinting}
                  onClick={this.handleCreate}
                >
                  Сохранить и распечатать
                </Button>
              )}
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

ProdEntryDetail.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  //console.log(state);
  return {
    // You can now say this.props.books

    openedProdEntry: getOpenedProdEntry(state),
    openedProdEntryId: getOpenedProdEntryId(state),
    //selectedSpreadId: state.scenes.product.selectedSpreadId,
    selectedProdBom: getSelectedProdBom(state),
    //product: getProduct(state, state.scenes.product.openedProduct),
    showModal: state.scenes.cutting.showProdEntryModal,
    openedOrder: getOpenedCuttingOrder(state),
    teams: getAllTeams(state),
    user: state.data.users.user.user,
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowProdEntryModal: showModal =>
      dispatch(setShowProdEntryModal(showModal)),

    createProdEntry: (orderId, prodEntry) =>
      dispatch(createProdEntry(orderId, prodEntry)),
    updateProdEntry: (orderId, prodEntry) =>
      dispatch(updateProdEntry(orderId, prodEntry)),

    setOpenedProdEntryId: entryId => dispatch(setOpenedProdEntryId(entryId)),
    fetchTeams: () => dispatch(fetchTeams()),
    fetchPeriods: () => dispatch(fetchPeriods()),
    //setSelectedSpreadTab: (bomId, spreadId)=>dispatch(setSelectedSpreadTab(bomId, spreadId)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdEntryDetail),
);
