import { ME_FROM_TOKEN_SUCCESS, SIGNIN_USER_SUCCESS } from "../users/actions";
import {ORDERS_FETCH_SUCCESS} from "../orders/actions";

export default (state = [], action) => {
    switch (action.type) {
        case ME_FROM_TOKEN_SUCCESS: {
            return action.data.userUIConfigs ? action.data.userUIConfigs : [];
        }
        case SIGNIN_USER_SUCCESS: {
            return action.data.userUIConfigs ? action.data.userUIConfigs : [];
        }
        case ORDERS_FETCH_SUCCESS: {
            if(state.find(config => config.key === "activeProdTab")) {
                return state.map(config => config.key === "activeProdTab" ? {
                    ...config,
                    value: action.activeTabKey
                } : config)
            }

            return [
                {
                    key: "activeProdTab",
                    value: action.activeTabKey
                }
            ]
        }
        default:
            return state;
    }
};
