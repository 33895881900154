import "./datePickerInput.css";

import shipmentStatusTypes from "@app/shared/constants/shipmentStatusTypes";
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import { Formik } from "formik";
import { nanoid } from "nanoid";
import React, { Component } from "react";

import isMobile from "../../../utils/mobileCheck";
import ShipmentFormTable from "./ShipmentFormTable";
import ShipmentSchema from "./ShipmentSchema";

const newShipmentLineItem = () => ({
  id: nanoid(5),
  product: null,
  price: null,
  numberOfBags: null,
  quantity: "",
});

class ShipmentForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.addButton = React.createRef();
    this.state = {
      isSearchingClients: false,
      foundClientsByTerm: [],
    };
  }

  submitForm = () => {
    this.form.current.submitForm();
  };

  searchClient = term => {
    this.setState({ isSearchingClients: true });
    axios.get(`/api/clients?q=${term}`).then(
      ({ data }) => {
        this.setState({ isSearchingClients: false });
        this.setState({ foundClientsByTerm: data });
      },
      () => {
        this.setState({ isSearchingClients: false });
      },
    );
  };

  renderMobile = () => {
    const { disabled } = this.props;
    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={ShipmentSchema}
          onSubmit={this.props.onSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            handleSubmit,
          }) => {
            const addShipmentLineItem = () => {
              setFieldValue("lineItems", [
                ...values.lineItems,
                newShipmentLineItem(),
              ]);
            };

            const deleteShipmentLineItem = index => {
              setFieldValue(
                "lineItems",
                values.lineItems.filter(item => item.id !== index),
              );
            };

            return (
              <form onSubmit={handleSubmit}>
                <div
                  className={errors.client && touched.client ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="quantity">
                    Клиент
                  </label>
                  <br />
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Клиент"
                    disabled={disabled}
                    filterOption={false}
                    notFoundContent={
                      this.state.isSearchingClients ? (
                        <Spin size="small" />
                      ) : null
                    }
                    value={values.client ? values.client.id : undefined}
                    onSearch={e => this.searchClient(e)}
                    onChange={clientId => {
                      const newClient = this.state.foundClientsByTerm.find(
                        client => client.id === clientId,
                      );
                      if (newClient) {
                        setFieldValue("client", newClient);
                      }
                    }}
                  >
                    {this.state.foundClientsByTerm.length !== 0 &&
                      this.state.foundClientsByTerm.map(client => {
                        return (
                          <Select.Option key={client.id} value={client.id}>
                            {client.name}
                          </Select.Option>
                        );
                      })}
                    {this.state.foundClientsByTerm.length === 0 &&
                      values.client &&
                      values.client && (
                        <Select.Option value={values.client.id}>
                          {values.client.name}
                        </Select.Option>
                      )}
                  </Select>
                  <div className="help-block">
                    {errors.client && touched.client && errors.client}
                  </div>
                </div>
                <br />
                <div>
                  <label className="mt-label" htmlFor="quantity">
                    Статус
                  </label>
                  <br />
                  <Select
                    disabled={disabled}
                    style={{ width: "100%" }}
                    value={values.statusTypeId}
                    onChange={e => setFieldValue("statusTypeId", e)}
                  >
                    <Select.Option value={shipmentStatusTypes.SHIPPED}>
                      Отправлено
                    </Select.Option>
                    <Select.Option value={shipmentStatusTypes.BEING_PREPARED}>
                      В процессе подготовки
                    </Select.Option>
                  </Select>
                </div>
                <br />
                <div
                  className={
                    errors.shipped_at && touched.shipped_at ? "has-error" : ""
                  }
                >
                  <label className="mt-label" htmlFor="quantity">
                    Дата отгрузки
                  </label>
                  <br />
                  <DatePicker
                    style={{ width: "100%" }}
                    className={"removeInput"}
                    placeholder="Дата отгрузки"
                    disabled={disabled}
                    filterOption={false}
                    value={values.shipped_at}
                    onChange={newDate => {
                      setFieldValue("shipped_at", newDate);
                    }}
                  />
                  <div className="help-block">
                    {errors.shipped_at &&
                      touched.shipped_at &&
                      errors.shipped_at}
                  </div>
                </div>
                <br />
                <div>
                  <label className="mt-label" htmlFor="price">
                    Итоговая стоимость
                  </label>
                  <br />
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Стоимость"
                    value={Number(values.lineItems.reduce(
                      (acc, i) =>
                        i.price && i.quantity
                          ? acc + i.price * i.quantity
                          : acc,
                      0,
                    )).toFixed(2)}
                    formatter={value =>
                      value
                        .replace(/(^\d+\.?)(\d{0,2})?\d*/, (...args) =>
                          args[1].concat(args[2] || ""),
                        )
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }
                    disabled={true}
                  />
                </div>
                <br />
                <div>
                  <label className="mt-label" htmlFor="totalNumberOfBags">
                    Итоговое количество мешков
                  </label>
                  <br />
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Стоимость"
                    value={
                      values.lineItems.reduce(
                        (acc, elem) =>
                          elem.numberOfBags ? acc + elem.numberOfBags : acc,
                        0,
                      )
                    }
                    disabled={true}
                  />
                </div>
                <Divider />

                <div className="help-block" style={{ marginBottom: 15 }}>
                  {errors.lineItems &&
                    typeof errors.lineItems === "string" &&
                    errors.lineItems}
                </div>

                <ShipmentFormTable
                  user={this.props.user}
                  form={{
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                  }}
                  disabled={disabled}
                  name="lineItems"
                  handleDelete={deleteShipmentLineItem}
                />

                {!disabled && (
                  <div ref={this.addButton}>
                    <Button
                      style={{
                        display: "block",
                        margin: "auto",
                        marginTop: 15,
                        width: "100%",
                      }}
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={addShipmentLineItem}
                    >
                      Добавить продукт
                    </Button>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  renderPC = () => {
    const { disabled } = this.props;
    return (
      <div>
        <Formik
          ref={this.form}
          initialValues={this.props.initialValues}
          validationSchema={ShipmentSchema}
          onSubmit={this.props.onSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            handleSubmit,
          }) => {
            const addShipmentLineItem = () => {
              setFieldValue("lineItems", [
                ...values.lineItems,
                newShipmentLineItem(),
              ]);
            };

            const deleteShipmentLineItem = index => {
              setFieldValue(
                "lineItems",
                values.lineItems.filter(item => item.id !== index),
              );
            };

            return (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className={
                      errors.client && touched.client ? "has-error" : ""
                    }
                  >
                    <label className="mt-label" htmlFor="quantity">
                      Клиент
                    </label>
                    <br />
                    <Select
                      style={{ width: 300 }}
                      showSearch
                      placeholder="Клиент"
                      disabled={disabled}
                      filterOption={false}
                      notFoundContent={
                        this.state.isSearchingClients ? (
                          <Spin size="small" />
                        ) : null
                      }
                      value={values.client ? values.client.id : undefined}
                      onSearch={e => this.searchClient(e)}
                      onChange={clientId => {
                        const newClient = this.state.foundClientsByTerm.find(
                          client => client.id === clientId,
                        );
                        if (newClient) {
                          setFieldValue("client", newClient);
                        }
                      }}
                    >
                      {this.state.foundClientsByTerm.length !== 0 &&
                        this.state.foundClientsByTerm.map(client => {
                          return (
                            <Select.Option key={client.id} value={client.id}>
                              {client.name}
                            </Select.Option>
                          );
                        })}
                      {this.state.foundClientsByTerm.length === 0 &&
                        values.client &&
                        values.client && (
                          <Select.Option value={values.client.id}>
                            {values.client.name}
                          </Select.Option>
                        )}
                    </Select>
                    <div className="help-block">
                      {errors.client && touched.client && errors.client}
                    </div>
                  </div>
                  <div>
                    <label className="mt-label" htmlFor="quantity">
                      Статус
                    </label>
                    <br />
                    <Select
                      disabled={disabled}
                      style={{ width: "220px" }}
                      value={values.statusTypeId}
                      onChange={e => setFieldValue("statusTypeId", e)}
                    >
                      <Select.Option value={shipmentStatusTypes.SHIPPED}>
                        Отправлено
                      </Select.Option>
                      <Select.Option value={shipmentStatusTypes.BEING_PREPARED}>
                        В процессе подготовки
                      </Select.Option>
                    </Select>
                  </div>
                  <div
                    className={
                      errors.shipped_at && touched.shipped_at ? "has-error" : ""
                    }
                  >
                    <label className="mt-label" htmlFor="quantity">
                      Дата отгрузки
                    </label>
                    <br />
                    <DatePicker
                      style={{ width: 200 }}
                      placeholder="Дата отгрузки"
                      disabled={disabled}
                      filterOption={false}
                      value={values.shipped_at}
                      onSearch={e => this.searchClient(e)}
                      onChange={newDate => {
                        setFieldValue("shipped_at", newDate);
                      }}
                    />
                    <div className="help-block">
                      {errors.shipped_at &&
                        touched.shipped_at &&
                        errors.shipped_at}
                    </div>
                  </div>
                  <div style={{ marginRight: 40 }}>
                    <label className="mt-label" htmlFor="price">
                      Итоговая стоимость
                    </label>
                    <br />
                    <InputNumber
                      style={{ width: 180 }}
                      placeholder="Стоимость"
                      value={Number(values.lineItems.reduce(
                        (acc, i) =>
                          i.price && i.quantity
                            ? acc + i.price * i.quantity
                            : acc + 0,
                        0,
                      )).toFixed(2)}
                      formatter={value =>
                        value
                          .replace(/(^\d+\.?)(\d{0,2})?\d*/, (...args) =>
                            args[1].concat(args[2] || ""),
                          )
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      }
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label className="mt-label" htmlFor="totalNumberOfBags">
                      Количество мешков
                    </label>
                    <br />
                    <InputNumber
                      style={{ width: "200px" }}
                      placeholder="Стоимость"
                      value={
                        values.lineItems.reduce(
                          (acc, elem) =>
                            elem.numberOfBags ? acc + elem.numberOfBags : acc,
                          0,
                        )
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <Divider />

                <div className="help-block" style={{ marginBottom: 15 }}>
                  {errors.lineItems &&
                    typeof errors.lineItems === "string" &&
                    errors.lineItems}
                </div>

                <ShipmentFormTable
                  user={this.props.user}
                  form={{
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                  }}
                  disabled={disabled}
                  name="lineItems"
                  handleDelete={deleteShipmentLineItem}
                />

                {!disabled && (
                  <div ref={this.addButton}>
                    <Button
                      style={{
                        display: "block",
                        margin: "auto",
                        marginTop: 15,
                      }}
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={addShipmentLineItem}
                    >
                      Добавить продукт
                    </Button>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  render() {
    return isMobile() ? this.renderMobile() : this.renderPC();
  }
}

export default ShipmentForm;
