import { Button } from "antd";
import { Modal, Select as SelectAntD } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FabricConsumptionForm from "scenes/FabricConsumption/forms/FabricConsumptionForm";

import { fetchDepotLocations } from "../../../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../../../data/depotLocations/reducer";
import {
  createFabricConsumption,
  fetchFabricConsumption,
  updateFabricConsumption,
} from "../../../../data/fabricConsumptions/actions";

const confirm = Modal.confirm;
const Option = SelectAntD.Option;

function FabricConsumptionDetail(props) {
  const {
    history,
    match,
    fetchFabricConsumption,
    createFabricConsumption,
    updateFabricConsumption,
    fetchDepotLocations,
    depotLocations,
  } = props;
  const form = useRef();
  const [isInEditState, toggleEditState] = useState(false);
  const [formData, setFormData] = useState({
    weight: null,
    entryDate: null,
    fabric: null,
    depotLocation: null,
  });
  const [isSaving, setSaving] = useState(false);
  const [depotLocationsWithStock, setDepotLocationsWithStock] = useState([]);

  const createFabricConsumptionFromValues = values => {
    return {
      weight: values.weight,
      entryDate: new moment(values.entryDate).format("YYYY-MM-DD HH:mm:ss"),
      fabric: values.fabric,
      depotLocation: values.depotLocation,
    };
  };

  const fetchDepotLocationsWithStock = fabricId => {
    return axios(`/api/depotlocations/searchwithstock?q=${fabricId}`).then(
      ({ data }) => setDepotLocationsWithStock(data),
    );
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    var fabricConsumption = createFabricConsumptionFromValues(values);
    if (match.params.id) {
      setSaving(true);
      return updateFabricConsumption(match.params.id, fabricConsumption).then(
        json => {
          toggleEditState(false);
          setSaving(false);
          setSubmitting(false);
          fetchDepotLocationsWithStock(json.fabric.id).then(() =>
            setFormData(prev => {
              return {
                weight: json.weight,
                entryDate: json.entry_date ? new moment(json.entry_date) : null,
                fabric: addLabelFieldsToFabric(json.fabric),
                depotLocation: {
                  ...json.depotLocation,
                  label: json.depotLocation.name,
                },
              };
            }),
          );
          history.push(`/fabricConsumption/` + json.id);
        },
        error => {
          setSaving(false);
          setSubmitting(false);
        },
      );
    } else {
      setSaving(true);
      return createFabricConsumption(fabricConsumption).then(
        json => {
          toggleEditState(false);
          setSaving(false);
          setSubmitting(false);
          history.push(`/fabricConsumption/` + json.id);
        },
        error => {
          setSaving(false);
          setSubmitting(false);
        },
      );
    }
  };

  const addLabelFieldsToFabric = fabric => {
    if (fabric) {
      let selectedFabric = {
        value: fabric.id,
        label: fabric.name,
        ...fabric,
      };
      return selectedFabric;
    }
    return null;
  };

  const startSubmit = () => {
    form.current.submitForm();
  };

  useEffect(() => {
    if (match.params.id) {
      fetchFabricConsumption(match.params.id).then(entry => {
        toggleEditState(false);
        fetchDepotLocations();
        fetchDepotLocationsWithStock(entry.fabric.id).then(() =>
          setFormData(prev => {
            return {
              ...prev,
              weight: entry.weight,
              entryDate: entry.entry_date ? new moment(entry.entry_date) : null,
              fabric: addLabelFieldsToFabric(entry.fabric),
              depotLocation: {
                ...entry.depotLocation,
                label: entry.depotLocation.name,
              },
            };
          }),
        );
      });
    } else {
      fetchDepotLocations();
      toggleEditState(true);
    }
  }, [match.params.id]);

  let depotLocationOptions = depotLocations.map(depot => {
    return (
      <Option key={depot.id} value={depot.id}>
        {depot.name}
      </Option>
    );
  });

  return (
    <div className="mt-paper">
      <div>
        <h2>Запись расхода</h2>
      </div>
      {match.params.id && isInEditState && (
        <Button type="primary" onClick={startSubmit} loading={isSaving}>
          Сохранить
        </Button>
      )}
      {match.params.id == undefined && (
        <Button type="primary" onClick={startSubmit} loading={isSaving}>
          Сохранить
        </Button>
      )}
      {match.params.id && !isInEditState && (
        <Button onClick={() => toggleEditState(true)}>Изменить</Button>
      )}

      <br />
      <br />

      <FabricConsumptionForm
        depotLocationOptions={depotLocationOptions}
        depotLocationsWithStock={depotLocationsWithStock}
        match={props.match}
        disabled={!isInEditState}
        ref={form}
        onSubmit={handleSubmit}
        fetchDepotLocationsWithStock={fetchDepotLocationsWithStock}
        initialValues={{
          weight: formData.weight,
          entryDate: formData.entryDate,
          fabric: formData.fabric,
          depotLocation: formData.depotLocation,
        }}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    depotLocations: getAllDepotLocations(state),
    user: state.data.users.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFabricConsumption: id => dispatch(fetchFabricConsumption(id)),
    createFabricConsumption: location =>
      dispatch(createFabricConsumption(location)),
    updateFabricConsumption: (id, entry) =>
      dispatch(updateFabricConsumption(id, entry)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabricConsumptionDetail),
);
