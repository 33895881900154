import { Button, DatePicker, Empty, InputNumber, Select, Spin } from "antd";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  createContractorPayment,
  fetchContractorPayment,
  updateContractorPayment,
} from "../../../data/contractorPayments/actions";
import isMobile from "../../../utils/mobileCheck";

const isInteger = str => {
  return /^\+?(0|[1-9]\d*)$/.test(str);
};

const contractorPaymentDetailsStatuses = {
  CREATE_NEW_CONTRACTOR_PAYMENT: 1,
  FETCHING_CONTRACTOR_PAYMENT: 2,
  NO_SUCH_CONTRACTOR_PAYMENT: 3,
  SHOW_CONTRACTOR_PAYMENT: 4,
  EDIT_CONTRACTOR_PAYMENT: 5,
  WRONG_PATH: 6,
};

const ContractorPaymentSchema = Yup.object().shape({
  contractor: Yup.object()
    .required("Выберите клиента")
    .nullable(),
  amount: Yup.number()
    .max(99999999, "Значение слишком большое")
    .required("Обязательное поле")
    .nullable(),
  transferredAt: Yup.date()
    .required("Обязательное поле")
    .nullable(),
});

function ContractorPaymentDetails(props) {
  const { match, fetchContractorPayment, createContractorPayment, history, updateContractorPayment } = props;

  const [status, setStatus] = useState(() => {
    if (match.params.id === "new") {
      return contractorPaymentDetailsStatuses.CREATE_NEW_CONTRACTOR_PAYMENT;
    }
    if (isInteger(match.params.id)) {
      return contractorPaymentDetailsStatuses.FETCHING_CONTRACTOR_PAYMENT;
    }
    return contractorPaymentDetailsStatuses.WRONG_PATH;
  });

  const formRef = createRef();
  const [contractorPayment, setContractorPayment] = useState(null);
  const [isSearchingContractors, setIsSearchingContractors] = useState(false);
  const [foundContractorsByTerm, setFoundContractorsByTerm] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    contractor: null,
    amount: 0,
    transferredAt: null,
  });

  useEffect(() => {
    if (contractorPayment) {
      setFormData({
        contractor: contractorPayment.contractor,
        amount: contractorPayment.amount,
        transferredAt: moment(contractorPayment.transferred_at),
      });
      setStatus(contractorPaymentDetailsStatuses.SHOW_CONTRACTOR_PAYMENT);
    }
  }, [contractorPayment]);

  useEffect(() => {
    if (match.params.id && isInteger(match.params.id)) {
      fetchContractorPayment(match.params.id).then(
        contractorPayment => {
          setContractorPayment(contractorPayment);
        },
        () => {
          setStatus(contractorPaymentDetailsStatuses.NO_SUCH_CONTRACTOR_PAYMENT);
        },
      );
    }
  }, [match.params.id]);

  if (status === contractorPaymentDetailsStatuses.NO_SUCH_CONTRACTOR_PAYMENT) {
    return (
      <Empty
        style={{ marginTop: "100px" }}
        description={"Такого платежа не существует"}
      />
    );
  }

  if (status === contractorPaymentDetailsStatuses.WRONG_PATH) {
    props.history.push("/contractorPayment");
  }

  const showNull =
    status === contractorPaymentDetailsStatuses.NO_SUCH_CONTRACTOR_PAYMENT ||
    status === contractorPaymentDetailsStatuses.FETCHING_CONTRACTOR_PAYMENT;

  const showFormData =
    status === contractorPaymentDetailsStatuses.CREATE_NEW_CONTRACTOR_PAYMENT ||
    status === contractorPaymentDetailsStatuses.EDIT_CONTRACTOR_PAYMENT ||
    status === contractorPaymentDetailsStatuses.SHOW_CONTRACTOR_PAYMENT;

  const disabled = !(
    status === contractorPaymentDetailsStatuses.CREATE_NEW_CONTRACTOR_PAYMENT ||
    status === contractorPaymentDetailsStatuses.EDIT_CONTRACTOR_PAYMENT
  );

  const showValue = value => {
    return showNull ? null : showFormData ? value : null;
  };

  const onSubmit = values => {
    setIsSaving(true);
    if (status === contractorPaymentDetailsStatuses.EDIT_CONTRACTOR_PAYMENT) {
      updateContractorPayment(contractorPayment.id, values).then(
        () => {
          setIsSaving(false);
          setStatus(contractorPaymentDetailsStatuses.SHOW_CONTRACTOR_PAYMENT);
        },
        () => {
          setIsSaving(false);
        },
      );
    } else if (status === contractorPaymentDetailsStatuses.CREATE_NEW_CONTRACTOR_PAYMENT) {
      createContractorPayment(values).then(
        newContractorPayment => {
          setIsSaving(false);
          history.push(`/contractorPayment/${newContractorPayment.id}`);
        },
        () => {
          setIsSaving(false);
        },
      );
    }
  };

  const handleSubmit = () => {
    formRef.current.submitForm();
  };

  const searchContractor = term => {
      // TODO:
    setIsSearchingContractors(true);
    axios.get(`/api/contractors/search?q=${term}`).then(
      ({ data }) => {
        setIsSearchingContractors(false);
        setFoundContractorsByTerm(data);
      },
      () => {
        setIsSearchingContractors(false);
      },
    );
  };

  return (
    <Spin spinning={status === contractorPaymentDetailsStatuses.FETCHING_CONTRACTOR_PAYMENT}>
      <div className={"mt-paper"} style={isMobile() ? { padding: "10px" } : {}}>
        <h2>Платежи</h2>
        {(status === contractorPaymentDetailsStatuses.CREATE_NEW_CONTRACTOR_PAYMENT ||
          status === contractorPaymentDetailsStatuses.EDIT_CONTRACTOR_PAYMENT) && (
          <Button
            loading={isSaving}
            onClick={handleSubmit}
            style={isMobile() ? { width: "100%" } : {}}
          >
            Сохранить
          </Button>
        )}
        {status === contractorPaymentDetailsStatuses.SHOW_CONTRACTOR_PAYMENT && (
          <Button
            style={isMobile() ? { width: "100%" } : {}}
            onClick={() => setStatus(contractorPaymentDetailsStatuses.EDIT_CONTRACTOR_PAYMENT)}
          >
            Изменить
          </Button>
        )}
        <br />
        <br />
        <Formik
          ref={formRef}
          initialValues={formData}
          validationSchema={ContractorPaymentSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <>
                <div
                  className={errors.contractor && touched.contractor ? "has-error" : ""}
                >
                  <label className="mt-label" htmlFor="contractor">
                    Подрядчик
                  </label>
                  <br />
                  <Select
                    id="contractor"
                    style={{ width: isMobile() ? "100%" : 300 }}
                    showSearch
                    placeholder="Подрядчик"
                    disabled={disabled}
                    filterOption={false}
                    notFoundContent={
                      isSearchingContractors ? <Spin size="small" /> : null
                    }
                    value={showValue(
                      values.contractor ? values.contractor.id : undefined,
                    )}
                    onSearch={e => searchContractor(e)}
                    onChange={contractorId => {
                      const newContractor = foundContractorsByTerm.find(
                        contractor => contractor.id === contractorId,
                      );
                      if (newContractor) {
                        setFieldValue("contractor", newContractor);
                      }
                    }}
                  >
                    {foundContractorsByTerm.length !== 0 &&
                      foundContractorsByTerm.map(contractor => {
                        return (
                          <Select.Option key={contractor.id} value={contractor.id}>
                            {contractor.company_name}
                          </Select.Option>
                        );
                      })}
                    {foundContractorsByTerm.length === 0 && values.contractor && (
                      <Select.Option value={values.contractor.id}>
                        {values.contractor.company_name}
                      </Select.Option>
                    )}
                  </Select>
                  <div className="help-block">
                    {errors.contractor && touched.contractor && errors.contractor}
                  </div>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className={errors.amount && touched.amount ? "has-error" : ""}
                >
                  <label className={"mt-label"} htmlFor={"amount"}>
                    Сумма платежа
                  </label>
                  <br />
                  <InputNumber
                    id={"amount"}
                    style={{ width: isMobile() ? "100%" : 300 }}
                    placeholder={"Введите сумму"}
                    precision={2}
                    formatter={value => `$ ${value}`}
                    value={showValue(values.amount)}
                    onChange={e => setFieldValue("amount", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.amount && touched.amount && errors.amount}
                  </div>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className={
                    errors.transferredAt && touched.transferredAt
                      ? "has-error"
                      : ""
                  }
                >
                  <label className={"mt-label"} htmlFor={"transferredAt"}>
                    Дата платежа
                  </label>
                  <br />
                  <DatePicker
                    id={"transferredAt"}
                    style={{ width: isMobile() ? "100%" : 300 }}
                    placeholder={"Выберите дату"}
                    value={showValue(values.transferredAt)}
                    onChange={e => setFieldValue("transferredAt", e)}
                    disabled={disabled}
                  />
                  <div className="help-block">
                    {errors.transferredAt &&
                      touched.transferredAt &&
                      errors.transferredAt}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Spin>
  );
}

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = dispatch => ({
  fetchContractorPayment: id => dispatch(fetchContractorPayment(id)),
  createContractorPayment: contractorPayment => dispatch(createContractorPayment(contractorPayment)),
  updateContractorPayment: (id, contractorPayment) => dispatch(updateContractorPayment(id, contractorPayment)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractorPaymentDetails),
);
