import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Col, Input, Modal, Switch, notification } from "antd";
import { Card, InputNumber, Row, Select as SelectAntD, Spin } from "antd";
import axios from "axios";
import AsyncCreatableSelect from "components/AsyncCreatableSelect";
import AsyncSelect from "components/AsyncSelect";
import { Formik } from "formik";
import { serialize } from "helpers/utils";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { fetchProdDepotLocations } from "../../../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../../../data/prodDepotLocations/reducer";
import {
  setOrderIdForCompletionModal,
  setShowCompletionModal,
} from "../../actions";
import { getOrderForCompletionModal } from "../../reducer";
import CreateNewProductModal from "./components/CreateNewProductModal";

const Option = SelectAntD.Option;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9998 }),
};

const confirm = Modal.confirm;

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "800px",
      },
      root: {
        alignItems: "initial",
        zIndex: 300,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const OrderCompletionSchema = Yup.object().shape({
  quantityOfProduct: Yup.number()
    .min(0, "Значение должно быть неотрицательным")
    .max(999999, "Значение слишком большое")
    .integer("Число должно быть целым")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  quantityOfFaults: Yup.number()
    .min(0, "Значение должно быть неотрицательным")
    .max(999999, "Значение слишком большое")
    .integer("Число должно быть целым")
    .required("Обязательное поле")
    .typeError("Неправильное значение"),
  faultyProduct: Yup.object().when("quantityOfFaults", {
    is: value => value > 0,
    then: Yup.object()
      .required("Введите продукт")
      .nullable(),
    otherwise: Yup.object().nullable(),
  }),

  prodDepotLocation: Yup.number()
    .required("Выберите склад")
    .nullable(),

  prodBoms: Yup.array().of(
    Yup.object().shape({
      quantityOfSubProduct: Yup.number()
        .min(0, "Значение должно быть неотрицательным")
        .max(999999, "Значение слишком большое")
        .integer("Число должно быть целым")
        .required("Обязательное поле")
        .typeError("Неправильное значение"),

      subProduct: Yup.object().when("quantityOfSubProduct", {
        is: value => value > 0,
        then: Yup.object()
          .required("Введите продукт")
          .nullable(),
        otherwise: Yup.object().nullable(),
      }),

      quantityOfFaultsOfSubProduct: Yup.number()
        .min(0, "Значение должно быть неотрицательным")
        .max(999999, "Значение слишком большое")
        .integer("Число должно быть целым")
        .required("Обязательное поле")
        .typeError("Неправильное значение"),

      faultyProductOfSubProduct: Yup.object().when(
        "quantityOfFaultsOfSubProduct",
        {
          is: value => value > 0,
          then: Yup.object()
            .required("Введите продукт")
            .nullable(),
          otherwise: Yup.object().nullable(),
        },
      ),
    }),
  ),
});

const getFaultyProductOptions = (input, callback) => {
  if (!input) {
    return Promise.resolve({ options: [] });
  }
  return axios(`/api/products/search?q=${input}&isFault=true`).then(
    ({ data }) => {
      return data.items.map(item => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
          ...item,
        };
      });
    },
  );
};

const getProductOptions = (input, callback) => {
  if (!input) {
    return Promise.resolve({ options: [] });
  }
  return axios(`/api/products/search?q=${input}`).then(({ data }) => {
    return data.items.map(item => {
      return {
        value: item.id,
        label: item.name,
        id: item.id,
        ...item,
      };
    });
  });
};

function CompletionOrderModal(props) {
  const {
    history,
    showModal,
    order,
    fullScreen,
    setShowCompletionModal,
    setOrderIdForCompletionModal,
    prodDepotLocations,
    fetchProdDepotLocations,
    completeOrder,
    serverErrors,
  } = props;

  const initialValue = {
    prodDepotLocation: null,
    quantityOfProduct: 0,
    quantityOfFaults: 0,
    faultyProduct: null,
    prodBoms: [],
  };

  const form = createRef();

  const selected = order !== null;

  const [loadingSum, setLoadingSum] = useState(selected);
  const [saving, setSaving] = useState(false);
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [hasRemnants, setHasRemnants] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [originalProduct, setOriginalProduct] = useState({});
  const [type, setType] = useState(1);
  const [indexForNewProductModal, setIndexForNewProductModal] = useState(null);
  const [isOrderCompleted, setIsOrderCompleted] = useState(
    selected ? order.is_completed === 1 : null,
  );

  const onSubmit = values => {
    setSaving(true);
    completeOrder({
      ...values,
      order: order,
      hasRemnants: hasRemnants,
    }).then(
      () => {
        setSaving(false);
        close();
      },
      () => {
        setSaving(false);
      },
    );
  };

  const prodDepotLocationOptions = prodDepotLocations.map(depot => {
    return (
      <Option key={depot.id} value={depot.id}>
        {depot.name}
      </Option>
    );
  });

  const close = () => {
    setShowCompletionModal(false);
    setOrderIdForCompletionModal(null);
    setFormData(initialValue);
    setHasRemnants(false);
    setShowNewProductModal(false);
    setIsOrderCompleted(null);
  };

  useEffect(() => {
    fetchProdDepotLocations();
  }, []);

  useEffect(() => {
    if (selected) {
      setIsOrderCompleted(order.is_completed === 1);
    }
  }, [order]);

  useEffect(() => {
    if (selected) {
      const countOfProdBoms = order.prodBoms.length;
      axios
        .get(
          `/api/production_order/calculate?${serialize({
            orderId: order.id,
          })}`,
        )
        .then(value => {
          setLoadingSum(false);
          setFormData({
            ...formData,
            quantityOfProduct: value.data,
            prodBoms: new Array(countOfProdBoms).fill({
              quantityOfSubProduct: 0,
              subProduct: null,
              quantityOfFaultsOfSubProduct: 0,
              faultyProductOfSubProduct: null,
            }),
          });
        })
        .catch(error => {
          openNotificationWithIcon(
            "Ошибка",
            error.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
        });
    }
  }, [selected]);

  const handleNewFaultyProductClick = value => {
    setType(1);
    setOriginalProduct({ ...order.product, name: value });
    setShowNewProductModal(true);
  };

  const handleNewFaultySubProductClick = (index, value) => {
    setType(2);
    setShowNewProductModal(true);
    setOriginalProduct({
      name: value,
      prodBomName: order.prodBoms[index].name,
    });
    setIndexForNewProductModal(index);
  };

  const handleNewSubProductClick = (index, value) => {
    setType(3);
    setOriginalProduct({
      prodBomName: order.prodBoms[index].name,
      name: value,
    });
    setShowNewProductModal(true);
    setIndexForNewProductModal(index);
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          fullScreen={fullScreen}
          open={showModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Завершить заказ: {selected && order.name} <br />
            Продукт: {selected && order.product.name}
          </DialogTitle>
          <DialogContent>
            <br />
            <Spin spinning={loadingSum}>
              <div>
                {isOrderCompleted === false && (
                  <Formik
                    ref={form}
                    initialValues={formData}
                    validationSchema={OrderCompletionSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      setFieldTouched,
                      handleSubmit,
                      touched,
                      isSubmitting,
                    }) => (
                      <div>
                        {console.log("ERRORS", errors)}
                        <form onSubmit={handleSubmit}>
                          <Card style={{ marginBottom: "1em" }} size="small">
                            <div>
                              <Row>
                                <Col span={12}>
                                  <div
                                    style={{
                                      marginBottom: 15,
                                      marginRight: "4em",
                                    }}
                                    className={
                                      errors.prodDepotLocation &&
                                      touched.prodDepotLocation
                                        ? "has-error"
                                        : ""
                                    }
                                  >
                                    <label
                                      className="mt-label"
                                      htmlFor="job_title"
                                    >
                                      Отправить готовую продукцию в:
                                    </label>
                                    <br />
                                    <SelectAntD
                                      value={values["prodDepotLocation"]}
                                      style={{ width: 300 }}
                                      onChange={e =>
                                        setFieldValue("prodDepotLocation", e)
                                      }
                                    >
                                      {prodDepotLocationOptions}
                                    </SelectAntD>
                                    <br />
                                    <div className="help-block">
                                      {errors.prodDepotLocation &&
                                        touched.prodDepotLocation &&
                                        errors.prodDepotLocation}
                                    </div>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{ marginBottom: 15 }}
                                    className={
                                      errors.quantityOfProduct &&
                                      touched.quantityOfProduct
                                        ? "has-error"
                                        : ""
                                    }
                                  >
                                    <label className="mt-label" htmlFor="price">
                                      Количество готовой продукции (без брака):
                                    </label>
                                    <br />
                                    <InputNumber
                                      style={{ width: 300 }}
                                      name="quantityOfProduct"
                                      placeholder="Количество продукции на отправку:"
                                      value={values["quantityOfProduct"]}
                                      onChange={newValue =>
                                        setFieldValue(
                                          "quantityOfProduct",
                                          newValue,
                                        )
                                      }
                                    />
                                    <div className="help-block">
                                      {errors.quantityOfProduct &&
                                        touched.quantityOfProduct &&
                                        errors.quantityOfProduct}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <div
                                    style={{
                                      marginBottom: 15,
                                      marginRight: "4em",
                                    }}
                                    className={
                                      errors.quantityOfFaults &&
                                      touched.quantityOfFaults
                                        ? "has-error"
                                        : ""
                                    }
                                  >
                                    <label className="mt-label" htmlFor="price">
                                      Количество брака:
                                    </label>
                                    <br />
                                    <InputNumber
                                      style={{ width: 300 }}
                                      name="quantityOfFaults"
                                      placeholder="Введите число"
                                      value={values["quantityOfFaults"]}
                                      onChange={newValue =>
                                        setFieldValue(
                                          "quantityOfFaults",
                                          newValue,
                                        )
                                      }
                                    />
                                    <div className="help-block">
                                      {errors.quantityOfFaults &&
                                        touched.quantityOfFaults &&
                                        errors.quantityOfFaults}
                                    </div>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{ marginBottom: 15 }}
                                    className={
                                      errors.faultyProduct &&
                                      touched.faultyProduct
                                        ? "has-error"
                                        : ""
                                    }
                                  >
                                    <label className="mt-label" htmlFor="price">
                                      Принять брак как:
                                    </label>
                                    <br />
                                    <AsyncCreatableSelect
                                      name="faultyProducts"
                                      loadOptions={getFaultyProductOptions}
                                      styles={customStyles}
                                      menuPortalTarget={document.body}
                                      value={values["faultyProduct"]}
                                      onChange={e =>
                                        setFieldValue("faultyProduct", e)
                                      }
                                      onCreateOption={value =>
                                        handleNewFaultyProductClick(value)
                                      }
                                      formatCreateLabel={label => {
                                        return "Создать продукцию: " + label;
                                      }}
                                      isDisabled={
                                        values["quantityOfFaults"] <= 0
                                      }
                                    />
                                    <div className="help-block">
                                      {serverErrors.map(err => {
                                        if (err.field.faultyProduct) {
                                          return err.errorMessage;
                                        } else {
                                          return "";
                                        }
                                      })}
                                    </div>
                                    {errors.faultyProduct && (
                                      <div className="help-block">
                                        {errors.faultyProduct &&
                                          touched.faultyProduct &&
                                          errors.faultyProduct}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                          {selected && order.prodBoms.length > 1 && (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div style={{ marginRight: "1em" }}>
                                Принять остатки продукции как отдельные продукты
                              </div>
                              <div>
                                <Switch
                                  style={{ marginBottom: "0.5em" }}
                                  value={hasRemnants}
                                  onChange={e => {
                                    setHasRemnants(e);
                                  }}
                                />
                              </div>
                            </span>
                          )}
                          {hasRemnants &&
                            selected &&
                            order.prodBoms.map((prodBom, index) => (
                              <Card
                                key={index}
                                style={{ marginBottom: "1em" }}
                                size="small"
                                title={"Часть: " + prodBom.name}
                              >
                                <div>
                                  <Row>
                                    <Col span={12}>
                                      <div
                                        style={{
                                          marginBottom: 15,
                                          marginRight: "4em",
                                        }}
                                        className={
                                          errors.prodBoms &&
                                          errors.prodBoms[index] &&
                                          errors.prodBoms[index]
                                            .quantityOfSubProduct &&
                                          touched.prodBoms &&
                                          touched.prodBoms[index] &&
                                          touched.prodBoms[index]
                                            .quantityOfSubProduct
                                            ? "has-error"
                                            : ""
                                        }
                                      >
                                        <label
                                          className="mt-label"
                                          htmlFor="price"
                                        >
                                          Количество продукции для отправки:
                                        </label>
                                        <br />
                                        <InputNumber
                                          style={{ width: 300 }}
                                          name="quantityOfSubProduct"
                                          placeholder="Количество продукции на отправку на склад"
                                          value={
                                            values["prodBoms"][index]
                                              .quantityOfSubProduct
                                          }
                                          onChange={newValue =>
                                            setFieldValue(
                                              `prodBoms[${index}].quantityOfSubProduct`,
                                              newValue,
                                            )
                                          }
                                        />
                                        <div className="help-block">
                                          {errors.prodBoms &&
                                            errors.prodBoms[index] &&
                                            errors.prodBoms[index]
                                              .quantityOfSubProduct &&
                                            touched.prodBoms &&
                                            touched.prodBoms[index] &&
                                            touched.prodBoms[index]
                                              .quantityOfSubProduct &&
                                            errors.prodBoms[index]
                                              .quantityOfSubProduct}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col span={12}>
                                      <div
                                        style={{ marginBottom: 15 }}
                                        className={
                                          errors.prodBoms &&
                                          errors.prodBoms[index] &&
                                          errors.prodBoms[index].subProduct &&
                                          touched.prodBoms &&
                                          touched.prodBoms[index] &&
                                          touched.prodBoms[index].subProduct
                                            ? "has-error"
                                            : ""
                                        }
                                      >
                                        <label
                                          className="mt-label"
                                          htmlFor="price"
                                        >
                                          Принять эту часть как:
                                        </label>
                                        <br />
                                        <AsyncCreatableSelect
                                          name="subProduct"
                                          loadOptions={getProductOptions}
                                          styles={customStyles}
                                          menuPortalTarget={document.body}
                                          value={
                                            values["prodBoms"][index].subProduct
                                          }
                                          onChange={e =>
                                            setFieldValue(
                                              `prodBoms[${index}].subProduct`,
                                              e,
                                            )
                                          }
                                          onCreateOption={value => {
                                            handleNewSubProductClick(
                                              index,
                                              value,
                                            );
                                          }}
                                          formatCreateLabel={label => {
                                            return (
                                              "Создать продукцию: " + label
                                            );
                                          }}
                                          isDisabled={
                                            values["prodBoms"][index]
                                              .quantityOfSubProduct <= 0
                                          }
                                        />
                                        <div className="help-block">
                                          {serverErrors.map(err => {
                                            if (
                                              err.field.subProduct &&
                                              err.field.index === index
                                            ) {
                                              return err.errorMessage;
                                            } else {
                                              return "";
                                            }
                                          })}
                                        </div>
                                        <div className="help-block">
                                          {errors.prodBoms &&
                                            errors.prodBoms[index] &&
                                            touched.prodBoms &&
                                            touched.prodBoms[index] &&
                                            errors.prodBoms[index].subProduct}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={12}>
                                      <div
                                        style={{
                                          marginBottom: 15,
                                          marginRight: "4em",
                                        }}
                                        className={
                                          errors.prodBoms &&
                                          errors.prodBoms[index] &&
                                          errors.prodBoms[index]
                                            .quantityOfFaultsOfSubProduct &&
                                          touched.prodBoms &&
                                          touched.prodBoms[index] &&
                                          touched.prodBoms[index]
                                            .quantityOfFaultsOfSubProduct
                                            ? "has-error"
                                            : ""
                                        }
                                      >
                                        <label
                                          className="mt-label"
                                          htmlFor="price"
                                        >
                                          Остаток брака:
                                        </label>
                                        <br />
                                        <InputNumber
                                          style={{ width: 300 }}
                                          name="quantityOfFaultsOfSubProduct"
                                          placeholder="Введите число"
                                          value={
                                            values["prodBoms"][index]
                                              .quantityOfFaultsOfSubProduct
                                          }
                                          onChange={newValue =>
                                            setFieldValue(
                                              `prodBoms[${index}].quantityOfFaultsOfSubProduct`,
                                              newValue,
                                            )
                                          }
                                        />
                                        <div className="help-block">
                                          {errors.prodBoms &&
                                            errors.prodBoms[index] &&
                                            errors.prodBoms[index]
                                              .quantityOfFaultsOfSubProduct &&
                                            touched.prodBoms &&
                                            touched.prodBoms[index] &&
                                            touched.prodBoms[index]
                                              .quantityOfFaultsOfSubProduct &&
                                            errors.prodBoms[index]
                                              .quantityOfFaultsOfSubProduct}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col span={12}>
                                      <div
                                        style={{ marginBottom: 15 }}
                                        className={
                                          serverErrors.faultyProductOfSubProduct &&
                                          serverErrors.index === index
                                            ? "has-error"
                                            : ""
                                        }
                                      >
                                        <label
                                          className="mt-label"
                                          htmlFor="price"
                                        >
                                          Принять брак как:
                                        </label>
                                        <br />
                                        <AsyncCreatableSelect
                                          name="faultyProductOfSubProduct"
                                          loadOptions={getFaultyProductOptions}
                                          styles={customStyles}
                                          menuPortalTarget={document.body}
                                          value={
                                            values["prodBoms"][index]
                                              .faultyProductOfSubProduct
                                          }
                                          onCreateOption={value =>
                                            handleNewFaultySubProductClick(
                                              index,
                                              value,
                                            )
                                          }
                                          onChange={e =>
                                            setFieldValue(
                                              `prodBoms[${index}].faultyProductOfSubProduct`,
                                              e,
                                            )
                                          }
                                          formatCreateLabel={label => {
                                            return (
                                              "Создать продукцию: " + label
                                            );
                                          }}
                                          isDisabled={
                                            values["prodBoms"][index]
                                              .quantityOfFaultsOfSubProduct <= 0
                                          }
                                        />
                                        <div className="help-block">
                                          {serverErrors.map(err => {
                                            if (
                                              err.field
                                                .faultyProductOfSubProduct &&
                                              err.field.index === index
                                            ) {
                                              return err.errorMessage;
                                            } else {
                                              return "";
                                            }
                                          })}
                                        </div>
                                        <div className="help-block">
                                          {errors.prodBoms &&
                                            errors.prodBoms[index] &&
                                            errors.prodBoms[index]
                                              .faultyProductOfSubProduct &&
                                            touched.prodBoms &&
                                            touched.prodBoms[index] &&
                                            touched.prodBoms[index]
                                              .faultyProductOfSubProduct &&
                                            errors.prodBoms[index]
                                              .faultyProductOfSubProduct}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            ))}
                        </form>
                        <CreateNewProductModal
                          type={type}
                          showModal={showNewProductModal}
                          setShowModal={setShowNewProductModal}
                          originalProduct={originalProduct}
                          setFieldValueForExternalForm={setFieldValue}
                          index={indexForNewProductModal}
                        />
                      </div>
                    )}
                  </Formik>
                )}
                {isOrderCompleted === true && <div>Заказ уже завершен</div>}
                {isOrderCompleted === null && <div>Загрузка...</div>}
              </div>
            </Spin>
          </DialogContent>
          <DialogActions>
            {isOrderCompleted === false && (
              <Button
                type="primary"
                loading={saving}
                onClick={() => form.current.submitForm()}
              >
                Завершить
              </Button>
            )}

            <Button onClick={close}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

CompletionOrderModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    showModal: state.scenes.production.showCompletionModal,
    order: getOrderForCompletionModal(state),
    prodDepotLocations: getAllProdDepotLocations(state),
    serverErrors: state.data.orders.orderCompletionServerErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowCompletionModal: showCompletionModal =>
      dispatch(setShowCompletionModal(showCompletionModal)),
    setOrderIdForCompletionModal: order =>
      dispatch(setOrderIdForCompletionModal(order)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CompletionOrderModal),
);
