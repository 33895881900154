import { Breadcrumb, Button, DatePicker, Layout, Menu, Radio } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchProdDepotLocations } from "../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../data/prodDepotLocations/reducer";
import ProductTable from "./components/ProductsTable";

function ProdStockMain(props) {
  const {
    history,
    products,
    fetchProdStocks,
    fetchProdDepotLocations,
    prodDepotLocations,
    deleteProdStock,
  } = props;

  const [loading, setLoading] = useState(true);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [productsWithStock, setProductsWithStock] = useState([]);
  const [prodDepotLocationOptions, setProdDepotLocationOptions] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new moment(new Date()));

  const [selectedProdDepot, setSelectedProdDepot] = useState("all");

  const [dataToDownload, setDataToDownload] = useState([]);

  const [filename, setFilename] = useState("Остатки продукции");

  const selectTable = useRef();
  const csvLink = useRef();

  const onProductDoubleClick = value => {
    history.push(`/product-admin/` + value.id);
  };

  const onRemove = value => {
    deleteProdStock(value.id);
  };

  const handleGenerateClick = value => {
    axios
      .post(`/api/prodstockbalance/`, {
        selectedDate: new moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedProdDepot: selectedProdDepot,
      })
      .then(({ data }) => {
        setLoading(false);
        setProductsWithStock(data);
        return data;
      });
    //
  };

  const handleDateChange = value => {
    setSelectedDate(value);
  };

  useEffect(() => {
    const { getProducts, match, fetchProdDepotLocations } = props;

    fetchProdDepotLocations();

    setLoading(true);
    axios
      .post(`/api/prodstockbalance/`, {
        selectedDate: new moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedProdDepot: selectedProdDepot,
      })
      .then(({ data }) => {
        setLoading(false);
        setProductsWithStock(data);
        return data;
      });
  }, []);

  const handleProdDepotChange = e => {
    setSelectedProdDepot(e.target.value);
    axios
      .post(`/api/prodstockbalance/`, {
        selectedDate: new moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        selectedProdDepot: e.target.value,
      })
      .then(({ data }) => {
        setLoading(false);
        setProductsWithStock(data);
        return data;
      });
  };

  useEffect(() => {
    if (dataToDownload.length) {
      csvLink.current.link.click();
      setFilename("Остатки продукции");
    }
  }, [dataToDownload]);

  const handleCsvDownload = () => {
    const headers = [
      { label: "Имя", key: "name" },
      { label: "Описание", key: "description" },
      { label: "Остаток (шт)", key: "totalQuantity" },
    ];
    let currentRecords = [];
    if (selectTable.current) {
      currentRecords = selectTable.current.getResolvedState().sortedData;
    }
    const data_to_download = [];
    for (let index = 0; index < currentRecords.length; index++) {
      const record_to_download = {};
      for (let colIndex = 0; colIndex < headers.length; colIndex++) {
        record_to_download[headers[colIndex].label] =
          currentRecords[index][headers[colIndex].key] || 0;
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
    const dateString = ` на ${selectedDate.format("DD.MM.YYYY")}`;
    const depot = prodDepotLocations.find(el => el.id === selectedProdDepot);
    const depotString = depot && depot.name ? ` (${depot.name})` : "";
    setFilename(filename.concat(dateString, depotString));
  };

  let options = prodDepotLocations.map(prodDepotLocation => {
    return (
      <Radio.Button key={prodDepotLocation.id} value={prodDepotLocation.id}>
        {prodDepotLocation.name}
      </Radio.Button>
    );
  });

  return (
    <div>
      <div>
        <h2>Остаток продукции</h2>
      </div>
      <br />
      <Radio.Group
        defaultValue={selectedProdDepot}
        buttonStyle="solid"
        value={selectedProdDepot}
        onChange={handleProdDepotChange}
      >
        <Radio.Button key={"all"} value="all">
          Все склады
        </Radio.Button>
        {options}
      </Radio.Group>
      <br />
      <br />
      <div style={{ marginBottom: 15 }}>
        <label className="mt-label" htmlFor="prodDate">
          Остаток на
        </label>
        &nbsp; &nbsp;
        {/*<br />*/}
        <DatePicker
          id="entry_date"
          name="date"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          value={selectedDate}
          style={{ zIndex: 9999 }}
          //disabled={!isInEditState}
        />
        &nbsp; &nbsp;
        <Button type="primary" onClick={handleGenerateClick}>
          Сгенерировать
        </Button>
        &nbsp; &nbsp;
        <Button type="primary" onClick={handleCsvDownload}>
          Скачать
        </Button>
        <CSVLink
          style={{ display: "none" }}
          data={dataToDownload}
          filename={`${filename}.csv`}
          ref={csvLink}
        >
          Скачать
        </CSVLink>
      </div>
      <ProductTable
        onEditClick={onProductDoubleClick}
        onRemove={onRemove}
        productWithStock={productsWithStock}
        loading={loading}
        ref={selectTable}
      />
    </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    prodDepotLocations: getAllProdDepotLocations(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdStockMain),
);

//export default ContractorMain
