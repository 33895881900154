import {
  DELETE_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
} from "./actions";

export default (state = [], action) => {
  switch (action.type) {
    case DELETE_NOTIFICATION_SUCCESS:
      return state.filter(
        notification => notification.id !== action.notificationId,
      );
    case FETCH_NOTIFICATIONS_SUCCESS:
      return [...state, ...action.response];
    case MARK_NOTIFICATION_AS_READ_SUCCESS:
      return state.map(notification => {
        if (notification.id === action.notificationId) {
          return {
            ...notification,
            isSeen: true,
          };
        }
        return notification;
      });
    default:
      return state;
  }
};
