import { notification } from "antd";
import axios from "axios";
import { serialize } from "helpers/utils";
import { normalize } from "normalizr";

import {
  OPENED_CUTTING_ORDER_SET,
  SELECTED_PRODBOM_SET,
} from "../../scenes/Cutting/actions";
import { CUTTING_ENTRIES_FETCH_SUCCESS } from "../cuttingEntries/actions";
import { PROD_ENTRIES_FETCH_SUCCESS } from "../prodEntries/actions";
import * as schema from "../schema";
import { WAGE_ENTRIES_FETCH_SUCCESS } from "../wages/actions";

//import {addNotification} from '../../actions'

export const ORDERS_FETCH_SUCCESS = "ORDERS_FETCH_SUCCESS";
export const ORDER_FETCH_SUCCESS = "ORDER_FETCH_SUCCESS";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_STATUS_SUCCESS = "ORDER_UPDATE_STATUS_SUCCESS ";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_COMPLETE_SUCCESS = "ORDER_COMPLETE_SUCCESS";
export const ORDER_DISCARD_SUCCESS = "ORDER_DISCARD_SUCCESS";
export const ORDER_SET_CURRENT_PAGE = "ORDER_SET_CURRENT_PAGE";
export const ORDER_SET_PAGE_SIZE = "ORDER_SET_PAGE_SIZE";
export const ORDER_COMPLETE_VALIDATION_ERROR =
  "ORDER_COMPLETE_VALIDATION_ERROR";

export const setCurrentOrderPage = currentPage => ({
  type: ORDER_SET_CURRENT_PAGE,
  currentPage,
});

export const setOrderPageSize = pageSize => ({
  type: ORDER_SET_PAGE_SIZE,
  pageSize,
});

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchOrders = (page, pageSize, filtered, fetchOptions) => {
  return dispatch => {
    let str =
      filtered && filtered.length > 0
        ? serialize({ page, pageSize, filtered, fetchOptions })
        : serialize({ page, pageSize, fetchOptions });

    return axios
      .get(`/api/production_orders/withpaging?${str}`)
      .then(({ data }) => {
        let orders = data.results;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: ORDERS_FETCH_SUCCESS,
          activeTabKey: fetchOptions.orderStatusFilter,
          page,
          pageSize,
          quantityByOrderStatus: data.quantityByOrderStatus,
          response: normalize(orders, schema.arrayOfOrders),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          let { data } = error.response;

          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchOrder = orderId => {
  return dispatch => {
    return axios
      .get(`/api/production_orders/` + orderId)
      .then(({ data }) => {
        let order = data;
        let id = orderId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));

        let normalized = normalize(
          { ...order, fullyLoaded: true },
          schema.order,
        );

        dispatch({ type: ORDER_FETCH_SUCCESS, response: normalized, id });
        dispatch({ type: CUTTING_ENTRIES_FETCH_SUCCESS, response: normalized });
        dispatch({ type: WAGE_ENTRIES_FETCH_SUCCESS, response: normalized });
        dispatch({ type: PROD_ENTRIES_FETCH_SUCCESS, response: normalized });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createOrder = order => {
  return dispatch => {
    return axios
      .post("/api/production_orders/", order)
      .then(({ data }) => {
        // Status looks good
        let insertedOrder = data;
        dispatch({
          type: ORDER_CREATE_SUCCESS,
          response: normalize(insertedOrder, schema.order),
        });
        openNotificationWithIcon(
          "Успешно",
          "Заказ успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateOrder = (orderId, order) => {
  return dispatch => {
    return axios
      .put("/api/production_orders/" + orderId, order)
      .then(({ data }) => {
        // Status looks good
        let updatedOrder = data;
        dispatch({
          type: ORDER_UPDATE_SUCCESS,
          response: normalize(updatedOrder, schema.order),
        });
        openNotificationWithIcon(
          "Успешно",
          "Заказ успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateOrderStatus = (orderId, orderStatusId) => {
  return dispatch => {
    return axios
        .put(`/api/production_orders/${orderId}/status`, {
          orderStatus_id: orderStatusId
        })
        .then(({ data }) => {
          dispatch({
            type: ORDER_UPDATE_STATUS_SUCCESS,
            statusId: orderStatusId,
            orderId
          });
          openNotificationWithIcon(
              "Успешно",
              "Заказ успешно обновлён",
              "success",
          );
          return data;
        })
        .catch(error => {
          if (error.handledGlobally) {
            return Promise.reject();
          }

          if (error.response) {
            let { data } = error.response;
            openNotificationWithIcon(
                "Ошибка",
                data.errorMessage || "Произошла неизвестная ошибка",
                "error",
            );
            throw error;
          } else {
            openNotificationWithIcon(
                "Ошибка",
                "Невозможно подключиться к серверу",
                "error",
            );
            throw error;
          }
        });
  };
};

export const completeOrder = formData => {
  return dispatch => {
    return axios
      .post(`/api/production_order/complete`, formData)
      .then(({ data }) => {
        let updatedOrder = data[0];
        console.log("updatedOrder", updatedOrder);
        dispatch({
          type: ORDER_COMPLETE_SUCCESS,
          response: normalize(updatedOrder, schema.order),
        });
        openNotificationWithIcon(
          "Успешно",
          "Заказ успешно завершён",
          "success",
        );
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.validationError !== true)
            openNotificationWithIcon(
              "Ошибка",
              error.response.data.errorMessage ||
                "Произошла неизвестная ошибка",
              "error",
            );
          else {
            dispatch({
              type: ORDER_COMPLETE_VALIDATION_ERROR,
              response: error.response.data,
            });
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const discardOrderCompletion = orderId => {
  return dispatch => {
    return axios
      .put(`/api/production_orders/order/discardCompletion/` + orderId, {
        orderId: orderId,
      })
      .then(({ data }) => {
        let updatedOrder = data[0];
        dispatch({
          type: ORDER_DISCARD_SUCCESS,
          response: normalize(updatedOrder, schema.order),
        });
        openNotificationWithIcon(
          "Успешно",
          "Завершение заказа успешно отменено",
          "success",
        );
      })
      .catch(error => {
        if (error.response) {
          openNotificationWithIcon(
            "Ошибка",
            error.response.data.errorMessage,
            "error",
          );
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Произошла неизвестная ошибка",
            "error",
          );
        }
        throw error;
      });
  };
};

export const deleteOrder = orderId => {
  return dispatch => {
    return axios
      .delete("/api/production_orders/" + orderId)
      .then(({ data }) => {
        dispatch({ type: ORDER_DELETE_SUCCESS, orderId });
        openNotificationWithIcon("Успешно", "Заказ успешно удален", "success");
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. У данного заказа есть записи кроя/производства";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
          //console.log("Parsing eror: Most likely server crashed");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteOrderWithRecords = orderId => {
  return dispatch => {
    return axios
      .delete("/api/production_orders/deletewithrecords/" + orderId)
      .then(({ data }) => {
        //let product=json;
        dispatch({ type: ORDER_DELETE_SUCCESS, orderId });
        openNotificationWithIcon("Успешно", "Заказ успешно удален", "success");
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. У данного заказа есть записи кроя/производства";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
          //console.log("Parsing eror: Most likely server crashed");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateOrderSpecification = orderId => {
  return dispatch => {
    return axios
      .post(`/api/production_orders/update_specification/` + orderId)
      .then(({ data }) => {
        let order = data;
        let id = orderId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));

        let normalized = normalize(order, schema.order);

        dispatch({ type: ORDER_FETCH_SUCCESS, response: normalized, id });
        dispatch({ type: CUTTING_ENTRIES_FETCH_SUCCESS, response: normalized });
        dispatch({ type: WAGE_ENTRIES_FETCH_SUCCESS, response: normalized });
        dispatch({ type: PROD_ENTRIES_FETCH_SUCCESS, response: normalized });

        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
