import { combineReducers } from "redux";

import domainSettings from "../data/domainSettings/reducer";
import userUIConfigs from "../data/userUIConfigs/reducer";
import notifications from "../data/notification/reducer";
import { reducer as dataReducer } from "../data/reducer";
import { reducer as sceneReducer } from "../scenes/reducer";

const appReducer = combineReducers({
  notifications,
  domainSettings,
  userUIConfigs,
  scenes: sceneReducer,
  data: dataReducer,
});

export default appReducer;
