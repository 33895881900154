//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Input, Popconfirm } from "antd";
import matchSorter from "match-sorter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ReactTable from "react-table";

import { unClaimWageEntryManually } from "../../../../../data/wages/actions";
import {
  setOpenedWageId,
  setShowCuttingWageModal,
  setShowProdWageModal,
  setShowWageClaimModal,
} from "../../../actions";
import {
  getCuttingWagesForSelectedProdBom,
  getOpenedPattern,
  getProdWagesForSelectedProdBom,
} from "../../../selectors";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        //maxWidth: '800px',
        minWidth: "900px",
      },
      root: {
        alignItems: "initial",
        zIndex: 1000,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class ProdWageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      activeTab: 1,
    };
  }
  close = () => {
    // this.props.setOpenedPattern("")
    this.props.setShowProdWageModal(false);
  };

  create = () => {};

  handleUpdate = () => {};

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  handleNameChange = e => {
    //this.setState({ name: e.target.value });
  };

  onClaimClick = (id, e) => {
    this.props.setOpenedWageId(id);
    this.props.setShowWageClaimModal(true);
  };

  handleDeleteConfirm = (wage, e) => {
    this.props.unClaimWageEntry(null, wage).then(data => {}, error => {});
  };

  handleDeleteCancel = e => {};

  render() {
    const { showModal } = this.props;
    //coming from HOC
    const { fullScreen, classes, wages } = this.props;
    //console.log("cutting wages",wages);

    //console.log("cuttingentries",cuttingEntries)
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        Cell: props => <span className="number">{"01" + props.value}</span>, // Custom cell components!
        filterable: false,
      },
      {
        Header: "Период",
        accessor: "periodName",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
        filterable: false,
      },
      {
        Header: "Название",
        accessor: "prodOperation",
        Cell: props => <span className="number">{props.value.operation}</span>, // Custom cell components!
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, {
            keys: ["prodOperation.operation"],
          });
        },
        filterAll: true,
      },
      {
        Header: "Размер",
        accessor: "size",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
        filterable: true,
      },
      {
        Header: "Количество",
        accessor: "quantity",
        Cell: props => <span className="number">{props.value}</span>, // Custom cell components!
        filterable: false,
      },
      {
        Header: "Фамилия",
        accessor: "employee.last_name",
        Cell: props => <span>{props.value}</span>, // Custom cell components!,
        filterable: false,
      },
      {
        Header: "Имя",
        accessor: "employee.first_name",
        Cell: props => <span>{props.value}</span>, // Custom cell components!,
        filterable: false,
      },
      {
        Header: "",
        maxWidth: 100,
        Cell: row => (
          <div>
            {row.original.employee === null ? (
              <Button
                onClick={e => this.onClaimClick(row.original.id, e)}
                type="secondary"
                size="small"
              >
                Указать
              </Button>
            ) : (
              <Popconfirm
                title="Вы уверены, что хотите очистить эту запись?"
                onConfirm={e => this.handleDeleteConfirm(row.original, e)}
                onCancel={this.handleDeleteCancel}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="secondary" size="small">
                  Очистить
                </Button>
              </Popconfirm>
            )}
          </div>
        ),
        filterable: false,
      },
    ];

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Коды</DialogTitle>
            <DialogContent>
              {/* <div className="form-inline">
                                <div className="form-group">
                                    <label htmlFor="name"> Название детали:</label>&nbsp;&nbsp;
                                    <input placeholder="Введите название" value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="name" />
                                </div>
                            </div> */}

              <p />
              <ReactTable
                data={wages}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={columns}
                className=" -highlight"
                minRows={5}
                showPagination={true}
              />

              <p />
            </DialogContent>
            <DialogActions>
              {/* {openedPattern == undefined && <Button type="primary" disabled={this.state.name.length <= 0} onClick={this.create}>Создать</Button>}
                        {openedPattern != undefined && <Button type="primary" disabled={this.state.name.length <= 0} onClick={this.handleUpdate}>Сохранить</Button>} */}

              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

ProdWageModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

//export default withMobileDialog()(SpreadDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //selectedSpreadId: state.scenes.product.selectedSpread,
    //selectedSpreadId: state.scenes.forms.specform.selectedSpread,
    showModal: state.scenes.cutting.showProdWageModal,
    //openedPattern: getOpenedPattern(state),
    wages: getProdWagesForSelectedProdBom(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowProdWageModal: showModal =>
      dispatch(setShowProdWageModal(showModal)),
    setShowWageClaimModal: showModal =>
      dispatch(setShowWageClaimModal(showModal)),

    setOpenedWageId: id => dispatch(setOpenedWageId(id)),
    //addPattern:  (pattern, spreadId) => dispatch(addPattern(pattern, spreadId)),
    //savePattern: ( pattern) => dispatch(savePattern( pattern)),
    //setOpenedPattern: (patternId) => dispatch(setOpenedPattern(patternId))
    unClaimWageEntry: (employeeId, wage) =>
      dispatch(unClaimWageEntryManually(employeeId, wage)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProdWageModal),
);
