import { normalize, schema } from "normalizr";

// Define a users schema

export const fabStock = new schema.Entity(
  "fabStocks",
  {},
  { idAttribute: "id" },
);

export const arrayOfFabStocks = [fabStock];
