import { combineReducers } from "redux";

import { EMPLOYEE_REPORT_FETCH_SUCCESS } from "./actions";

const employeeReport = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_REPORT_FETCH_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  employeeReport,
});
