import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Button,
  DatePicker,
  Empty,
  InputNumber,
  Select as SelectAntD,
  Spin,
  notification,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AsyncSelect from "react-select/lib/Async";

import { fetchDepotLocations } from "../../../../data/depotLocations/actions";
import { getAllDepotLocations } from "../../../../data/depotLocations/reducer";
import {
  createFabStockMovementRequest,
  deleteFabStockMovementRequest,
  fetchFabStockMovementRequest,
  updateFabStockMovementRequest,
} from "../../../../data/fabStockMovementRequest/actions";
import { getFabStockMovementRequest } from "../../../../data/fabStockMovementRequest/reducer";
import {
  approveFabStockMovementRequest,
  declineFabStockMovementRequest,
} from "../../../../data/fabStocks/actions";
import { setOpenedFabStockMovementRequestId } from "../../actions";
import FormValidator from "./FormValidator";

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

const Option = SelectAntD.Option;

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

let validator = new FormValidator([
  {
    field: "weight",
    method: "isEmpty",
    validWhen: false,
    message: "Введите вес",
  },
  {
    field: "weight",
    method: (value, formData) => {
      let val = parseInt(value);
      if (Number.isInteger(val) && val <= 0) {
        return true;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Вес должен быть больше 0",
  },
  {
    field: "rolls",
    method: "isEmpty",
    validWhen: false,
    message: "Введите количество рулонов",
  },
  {
    field: "rolls",
    method: (value, formData) => {
      let val = parseInt(value);
      if (Number.isInteger(val) && val < 0) {
        return true;
      } else {
        return false;
      }
    },
    validWhen: false,
    message: "Значение не может быть меньше 0",
  },
  {
    field: "entry_dateField",
    method: (value, formData) => {
      if (formData.entry_date) {
        return false;
      } else {
        return true;
      }
    },
    validWhen: false,
    message: "Укажите дату",
  },
  {
    field: "fabricField",
    method: (args, formData) => {
      if (formData.fabric) {
        return false;
      } else {
        return true;
      }
    },
    validWhen: false,
    message: "Выберите материал",
  },
  {
    field: "fromDepotLocationField",
    method: (args, formData) => {
      if (Number.isInteger(formData.fromDepotLocation)) {
        return false;
      } else return true;
    },
    validWhen: false,
    message: "Выберите склад",
  },
  {
    field: "toDepotLocationField",
    method: (args, formData) => {
      if (Number.isInteger(formData.toDepotLocation)) {
        return false;
      } else return true;
    },
    validWhen: false,
    message: "Выберите склад",
  },
]);

function FabStockDetail(props) {
  const {
    history,
    match,
    createFabStockMovementRequest,
    updateFabStockMovementRequest,
    fetchDepotLocations,
    fetchFabStockMovementRequest,
    approveFabStockMovementRequest,
    declineFabStockMovementRequest,
    setOpenedFabStockMovementRequestId,
    user,
    classes,
    openedFabStockMovementRequest,
  } = props;
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [saving, setSaving] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [isInEditState, toggleEditState] = useState(false);
  const [isFetching, setIsFetching] = useState(!!match.params.id);
  const [formData, setFormData] = useState({
    weight: "",
    rolls: "",
    entry_date: null,
    entry_dateField: "",
    fabric: null,
    fabricField: "",
    fromDepotLocation: null,
    fromDepotLocationField: "",
    toDepotLocation: null,
    toDepotLocationField: "",
  });

  const [depotLocations, setDepotLocations] = useState([]);

  const onRemove = value => {
    const { deleteEmployee } = this.props;
    deleteEmployee(value.id);
  };

  const createFabStockMovementRequestFromValues = () => {
    return {
      weight: formData.weight,
      rolls: formData.rolls,
      entry_date: new moment(formData.entry_date).format("YYYY-MM-DD HH:mm:ss"),
      fabric: formData.fabric,
      fromDepotLocation: formData.fromDepotLocation,
      toDepotLocation: formData.toDepotLocation,
    };
  };

  const handleSubmit = () => {
    setSubmitted(true);

    const validated = validator.validate(formData);
    setValidation(validated);

    if (validated.isValid) {
      var fabStockMovementRequest = createFabStockMovementRequestFromValues();

      setSaving(true);
      //Handle update
      if (match.params.id) {
        return updateFabStockMovementRequest(
          fabStockMovementRequest,
          match.params.id,
        )
          .then(
            json => {
              toggleEditState(false);
              setSaving(false);
              fetchDepotLocationsWithStock();
              history.push(`/fabstock/movementrequest/` + json.id);
            },
            error => {
              setSaving(false);
            },
          )
          .catch(e => setSaving(false));
      }
      //Handle create
      else {
        return createFabStockMovementRequest(fabStockMovementRequest)
          .then(data => {
            history.push("/fabstock");
          })
          .catch(e => setSaving(false));
      }
    }
  };

  const handleInputChange = e => {
    let value = e.target.value;
    const name = e.target.name;

    setFormData(prevData => {
      return { ...prevData, [name]: value };
    });
  };

  const handleRollsChange = value => {
    if (value == undefined) {
      setFormData(prevData => {
        return { ...prevData, rolls: "" };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, rolls: value };
      });
    }
  };
  const handleWeightChange = value => {
    if (value == undefined) {
      setFormData(prevData => {
        return { ...prevData, weight: "" };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, weight: value };
      });
    }
  };

  const handleDateChange = day => {
    let value = day;
    setFormData(prevData => {
      return { ...prevData, entry_date: value };
    });
  };

  const getFabricOptions = (input, callback) => {
    if (!input) {
      return Promise.resolve([]);
    }
    return axios(`/api/fabrics/searchwithstock?q=${input}`).then(({ data }) => {
      return data.map(item => {
        let totalWeight = 0;
        if (item.totalWeight) {
          totalWeight = item.totalWeight;
        }
        if (
          isInEditState &&
          match.params.id &&
          item.id === openedFabStockMovementRequest.fabricId
        ) {
          totalWeight += openedFabStockMovementRequest.weight;
        }
        return {
          value: item.id,
          label: item.name + " { остаток: " + totalWeight + " }",
          id: item.id,
          ...item,
        };
      });
    });
  };

  const handleFabricSelect = fabric => {
    if (fabric) {
      setFormData(prevData => {
        return { ...prevData, fabric: fabric };
      });
    } else {
      setFormData(prevData => {
        return { ...prevData, fabric: null };
      });
    }
  };

  const addLabelFieldsToContractor = contractor => {
    if (contractor) {
      let selectedContractor = {
        value: contractor.id,
        label: contractor.company_name,
        ...contractor,
      };
      return selectedContractor;
    }
    return null;
  };

  const addLabelFieldsToFabric = fabric => {
    if (fabric) {
      let selectedFabric = {
        value: fabric.id,
        label: fabric.name,
        ...fabric,
      };
      return selectedFabric;
    }
    return null;
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(formData));
    }
  }, [formData]);

  const fetchDepotLocationsWithStock = () => {
    let fabricId = "";
    if (formData.fabric) {
      fabricId = formData.fabric.id;
    }
    axios(`/api/depotlocations/searchwithstock?q=${fabricId}`).then(
      ({ data }) => {
        setDepotLocations(data);
      },
    );
  };

  const isAdmin = user ? user.roles.map(role => role.id).includes(1) : false;

  useEffect(() => {
    fetchDepotLocationsWithStock();
  }, [formData.fabric]);

  useEffect(() => {
    setOpenedFabStockMovementRequestId("");
    if (match.params.id) {
      fetchFabStockMovementRequest(match.params.id)
        .then(entry => {
          toggleEditState(false);
          fetchDepotLocations();
          setFormData(prev => {
            return {
              ...prev,
              price: entry.price,
              weight: entry.weight,
              rolls: entry.rolls,
              entry_date: entry.entryDate ? new moment(entry.entryDate) : null,
              fabric: addLabelFieldsToFabric(entry.fabric),
              fromDepotLocation: entry.fromDepotLocation_id,
              toDepotLocation: entry.toDepotLocation_id,
            };
          });
          setOpenedFabStockMovementRequestId(entry.id);
          setIsFetching(false);
        })
        .catch(err => {
          setIsFetching(false);
        });
    } else {
      toggleEditState(true);
    }
  }, [match.params.id]);

  let validated = validation;

  const createDepotLocationOptions = depotLocationsList =>
    depotLocationsList.map(depot => {
      let weight = 0;
      if (depot.totalWeight) {
        weight = depot.totalWeight;
      }
      if (match.params.id && openedFabStockMovementRequest) {
        if (
          depot.id === openedFabStockMovementRequest.fromDepotLocation_id &&
          formData.fabric.id === openedFabStockMovementRequest.fabricId
        ) {
          weight += openedFabStockMovementRequest.weight;
        }
      }
      if (match.params.id && isInEditState) {
        return (
          <Option key={depot.id} value={depot.id}>
            {depot.name}, остаток: {weight}
          </Option>
        );
      } else if (match.params.id) {
        return (
          <Option key={depot.id} value={depot.id}>
            {depot.name}
          </Option>
        );
      } else {
        return (
          <Option key={depot.id} value={depot.id}>
            {depot.name}, остаток: {weight}
          </Option>
        );
      }
    });

  const handleFromDepotLocationChange = location => {
    setFormData(prevData => {
      return { ...prevData, fromDepotLocation: location };
    });
  };

  const handleToDepotLocationChange = location => {
    setFormData(prevData => {
      return { ...prevData, toDepotLocation: location };
    });
  };

  const handleApproveMovementRequest = () => {
    setIsApproving(true);
    approveFabStockMovementRequest(openedFabStockMovementRequest.id)
      .then(entry => {
        setIsApproving(false);
        history.push("/fabstock");
      })
      .catch(entry => {
        setIsApproving(false);
      });
  };

  const handleDeclineMovementRequest = () => {
    setIsDeclining(true);
    declineFabStockMovementRequest(openedFabStockMovementRequest.id)
      .then(entry => {
        setIsDeclining(false);
        history.push("/fabstock");
      })
      .catch(entry => {
        setIsDeclining(false);
      });
  };

  const disabledDate = current => {
    // Can not select days before today and today
    return current > moment().endOf("day");
  };

  if (openedFabStockMovementRequest || isFetching || !match.params.id) {
    return (
      <Spin spinning={isFetching}>
        <div className="mt-paper">
          <div>
            <h2>Запрос на движение материалов</h2>
          </div>
          {match.params.id && isInEditState && (
            <Button type="primary" onClick={handleSubmit} loading={saving}>
              Сохранить
            </Button>
          )}
          {match.params.id == undefined && (
            <Button type="primary" onClick={handleSubmit} loading={saving}>
              Создать запрос
            </Button>
          )}
          {match.params.id && !isInEditState && (
            <span>
              <Button onClick={() => toggleEditState(true)}>Изменить</Button>
              &nbsp; &nbsp;
              {match.params.id &&
                user &&
                openedFabStockMovementRequest !== undefined &&
                (openedFabStockMovementRequest.toDepotLocation
                  .assignedUser_id === user.id ||
                  isAdmin) && (
                  <span>
                    <Button
                      loading={isApproving}
                      type={"primary"}
                      onClick={handleApproveMovementRequest}
                    >
                      Подтвердить запрос
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      loading={isDeclining}
                      onClick={handleDeclineMovementRequest}
                      type={"danger"}
                    >
                      Отклонить запрос
                    </Button>
                  </span>
                )}
            </span>
          )}
          <br />
          &nbsp;
          <div style={{ marginBottom: 15 }}>
            <label className="mt-label" htmlFor="prodDate">
              Дата перемещения
            </label>
            <br />
            <DatePicker
              disabledDate={disabledDate}
              id="entry_date"
              name="date"
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onChange={handleDateChange}
              value={formData.entry_date}
              style={{ zIndex: 9999 }}
              disabled={!isInEditState}
            />
            <br />
            <span className="help-block">
              {validation.entry_dateField.message}
            </span>
          </div>
          <div
            style={{ marginBottom: 15 }}
            className={validation.fabricField.isInvalid ? "has-error" : ""}
          >
            <label className="mt-label" htmlFor="product">
              Материал
            </label>
            <AsyncSelect
              name="fabric"
              value={formData.fabric}
              loadOptions={getFabricOptions}
              onChange={handleFabricSelect}
              isDisabled={!isInEditState}
              styles={customStyles}
              noOptionsMessage={() => {
                return "Печатайте для поиска...";
              }}
              menuPortalTarget={document.body}
            />
            <span className="help-block">{validation.fabricField.message}</span>
          </div>
          <div
            style={{ marginBottom: 15 }}
            className={
              validation.fromDepotLocationField.isInvalid ? "has-error" : ""
            }
          >
            <label className="mt-label" htmlFor="job_title">
              Откуда
            </label>
            <br />
            <SelectAntD
              disabled={!isInEditState}
              value={formData.fromDepotLocation}
              style={{ width: 300 }}
              onChange={handleFromDepotLocationChange}
            >
              {user && createDepotLocationOptions(depotLocations)}
            </SelectAntD>
            <br />
            <span className="help-block">
              {validation.fromDepotLocationField.message}
            </span>
          </div>
          <div
            style={{ marginBottom: 15 }}
            className={
              validation.toDepotLocationField.isInvalid ? "has-error" : ""
            }
          >
            <label className="mt-label" htmlFor="job_title">
              Куда
            </label>
            <br />
            <SelectAntD
              disabled={!isInEditState}
              value={formData.toDepotLocation}
              style={{ width: 300 }}
              onChange={handleToDepotLocationChange}
            >
              {createDepotLocationOptions(
                depotLocations.filter(depot => {
                  if (formData.fromDepotLocation) {
                    return depot.id !== formData.fromDepotLocation;
                  } else {
                    return true;
                  }
                }),
              )}
            </SelectAntD>
            <br />
            <div className="help-block">
              {validation.toDepotLocationField.message}
            </div>
          </div>
          <div
            style={{ marginBottom: 15 }}
            className={validated.weight.isInvalid ? "has-error" : ""}
          >
            <label className="mt-label" htmlFor="weight">
              Вес
            </label>
            <br />
            <InputNumber
              style={{ width: 300 }}
              name="weight"
              placeholder="Вес"
              //onChange={this.handleInputChange}
              value={formData.weight}
              onChange={handleWeightChange}
              disabled={!isInEditState}
            />
            <div className="help-block">{validated.weight.message}</div>
          </div>
          <div
            style={{ marginBottom: 15 }}
            className={validated.rolls.isInvalid ? "has-error" : ""}
          >
            <label className="mt-label" htmlFor="rolls">
              Кол-во рулонов
            </label>
            <br />
            <InputNumber
              style={{ width: 300 }}
              name="rolls"
              placeholder="Кол-во рулонов"
              //onChange={this.handleInputChange}
              value={formData.rolls}
              onChange={handleRollsChange}
              disabled={!isInEditState}
            />
            <div className="help-block">{validated.rolls.message}</div>
          </div>
        </div>
      </Spin>
    );
  } else
    return (
      <div className={"mt-paper"}>
        <Empty
          description={
            "Запроса с таким номером не существует. Возможно, он уже был принят или отклонён."
          }
        />
      </div>
    );
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.data.users.user.user,
    openedFabStockMovementRequest: getFabStockMovementRequest(
      state,
      state.scenes.fabStock.openedFabStockMovementId,
    ),
    depotLocations: getAllDepotLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFabStockMovementRequest: id =>
      dispatch(fetchFabStockMovementRequest(id)),
    approveFabStockMovementRequest: id =>
      dispatch(approveFabStockMovementRequest(id)),
    declineFabStockMovementRequest: id =>
      dispatch(declineFabStockMovementRequest(id)),
    setOpenedFabStockMovementRequestId: id =>
      dispatch(setOpenedFabStockMovementRequestId(id)),
    createFabStockMovementRequest: entry =>
      dispatch(createFabStockMovementRequest(entry)),
    updateFabStockMovementRequest: (id, entry) =>
      dispatch(updateFabStockMovementRequest(id, entry)),
    fetchDepotLocations: () => dispatch(fetchDepotLocations()),
  };
};

FabStockDetail = withStyles(styles)(FabStockDetail);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FabStockDetail),
);
