import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const SHIPMENTS_FETCH_SUCCESS = "SHIPMENTS_FETCH_SUCCESS";
export const SHIPMENT_FETCH_SUCCESS = "SHIPMENT_FETCH_SUCCESS";
export const SHIPMENT_CREATE_SUCCESS = "SHIPMENT_CREATE_SUCCESS";
export const SHIPMENT_UPDATE_SUCCESS = "SHIPMENT_UPDATE_SUCCESS";
export const SHIPMENT_DELETE_SUCCESS = "SHIPMENT_DELETE_SUCCESS";
export const SET_SHIPMENT_STATUS_SUCCESS = "SET_SHIPMENT_STATUS_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const updateShipmentStatus = (shipmentId, shipmentStatusTypeId) => {
  return dispatch => {
    return axios
      .put(`/api/shipments/${shipmentId}/status`, {
        shipmentStatusTypeId,
      })
      .then(({ data }) => {
        dispatch({
          type: SET_SHIPMENT_STATUS_SUCCESS,
          shipment: data,
          shipmentId,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchShipments = () => {
  return dispatch => {
    return axios
      .get(`/api/shipments/`)
      .then(({ data }) => {
        dispatch({
          type: SHIPMENTS_FETCH_SUCCESS,
          response: normalize(data, schema.arrayOfShipments),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchShipment = entryId => {
  return dispatch => {
    return axios
      .get(`/api/shipments/` + entryId)
      .then(({ data }) => {
        dispatch({
          type: SHIPMENT_FETCH_SUCCESS,
          response: normalize(data, schema.shipment),
          id: entryId,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateShipment = (id, entry) => {
  return dispatch => {
    return axios
      .put("/api/shipments/" + id, entry)
      .then(({ data }) => {
        dispatch({
          type: SHIPMENT_UPDATE_SUCCESS,
          response: normalize(data, schema.shipment),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
export const createShipment = entry => {
  return dispatch => {
    return axios
      .post("/api/shipments/", entry)
      .then(({ data }) => {
        dispatch({
          type: SHIPMENT_CREATE_SUCCESS,
          response: normalize(data, schema.shipment),
        });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно добавлена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteShipment = shipmentId => {
  return dispatch => {
    return axios
      .delete("/api/shipments/" + shipmentId)
      .then(({ data }) => {
        dispatch({ type: SHIPMENT_DELETE_SUCCESS, shipmentId });
        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данные этой записи используются в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
