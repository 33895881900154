import ProductionProcessTypes from "@app/shared/constants/productionProcessTypes";
import * as Yup from "yup";

export default productionProcessType_id => {
  const schema = {
    period: Yup.object()
      .nullable()
      .required("Выберите период"),
    team: Yup.object()
      .nullable()
      .required("Выберите бригаду"),
    fabricConsumptions: Yup.array().of(
      Yup.array().of(
        Yup.array().of(
          Yup.object().shape({
            fabric: Yup.object()
              .nullable()
              .required("Выберите сотрудника"),
            depotLocation: Yup.object()
              .nullable()
              .required("Выберите сотрудника"),
            weight: Yup.number()
              .min(0, "Значение должно быть неотрицательным")
              .max(999999, "Значение слишком большое")
              .required("Обязательное поле"),
          }),
        ),
      ),
    ),
  };

  if (productionProcessType_id === ProductionProcessTypes.id.PASSPORT) {
    schema.prodBoms = Yup.array().of(
      Yup.array().of(
        Yup.array().of(
          Yup.object().shape({
            quantity: Yup.number()
              .min(1, "Значение должно быть неотрицательным")
              .max(999999, "Значение слишком большое")
              .integer("Число должно быть целым")
              .required("Обязательное поле"),
            employee: Yup.object()
              .nullable()
              .required("Выберите сотрудника"),
          }),
        ),
      ),
    );
  }
  return Yup.object().shape(schema);
};
