import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Col, Input, Modal, Switch, notification } from "antd";
import { InputNumber } from "antd";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Select } from 'antd';

const { Option } = Select;

import {
  checkAvailable,
  decodeStatus,
  generateProdEntryData,
  getDefaultPrinter,
  readPrinterStatus,
  sendToPrinter,
} from "../../../../../printHelper";
import { setShowPrintCodeModal } from "../../../../actions";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 300,
  }),
  menu: base => ({ ...base, width: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9998 }),
};

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "400px",
        minWidth: "400px",
      },
      root: {
        alignItems: "initial",
        zIndex: 300,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

function PrintCodeModal(props) {
  const {showModal, setShowPrintCodeModal, fullScreen, codes, printPack, sizes} = props;

  const generateCodeDataForPrinter = (code, article) => `
^XA
^CI28
^MMT
^PW464
^LL0256
^LS0
^BY3,3,102^FT400,54^BCI,,Y,N
^FD>;${code}^FS
^FT395,172^A0I,28,28^FH\\^FD${article}^FS
^PQ1,0,1,Y^XZ
`;

  const generateEanCodeForPrinter = (code, l1, l2) => `
^XA
^MMT
^PW464
^LL240
^LS0
^FT110,34^A0N,20,20^FH\\^CI28^FDПроизведено в Узбекистане^FS^CI27
^FO25,50^A0N,20,20^TBN,410,100^FH\\^CI28^CF0,19,19^FD${l1}^FS^CI27
^FO25,132^A0N,20,20^FH\\^CI28^CF0,19,22^FD${l2}^FS^CI27
^BY3,2,39^FT99,200^BEN,,Y,N
^FD${code}^FS
^PQ1,,,Y
^XZ
`;

  const printCode = (code, count, article) =>
      getDefaultPrinter()
          .then(json => {
            //check if the default printer is available for printing
            return checkAvailable().then(available => {
              let foundPrinter = undefined;
              if (available.printer && available.printer.length > 0) {
                //return json
                available.printer.forEach(availablePrinter => {
                  if (availablePrinter.name == json.name) {
                    foundPrinter = availablePrinter;
                  }
                });
              }
              if (foundPrinter == undefined) {
                throw "Принтер не подключен";
              } else {
                //send read printer status request
                return sendToPrinter(json, "~HQES").then(data => {
                  return json;
                });
              }
            });
          })
          .then(printer => {
            //check if the printer is ready to print
            return readPrinterStatus(printer).then(text => {
              let status = decodeStatus(text);
              if (status == "Ready to Print") {
                let data = "";
                for (let i = 0; i < count; i++) {

                  if (isPack) {
                    let firstSize = sizes[0].label;
                    let lastSize = sizes[sizes.length - 1].label;
                    data += generateEanCodeForPrinter(code, article, "Фасовка " + firstSize + "-" + lastSize);
                  } else {
                    data += generateEanCodeForPrinter(code, article, "Размер: " + selectedSize);
                  }

                }
                return sendToPrinter(printer, data).then(res => {
                  this.close();
                });
              } else {
                throw status;
              }
            });
            // .catch(error => {
            //   this.showConfirm(prodEntry, error);
            // });
          });
  // .catch(error => {
  //   this.showConfirm(prodEntry, error);
  // });

  const close = () => {
    setShowPrintCodeModal(false);
  };

  const [saving, setSaving] = useState(false);
  const [count, setCount] = useState(1);

  const [isPack, setIsPack] = useState(true);

  const [selectedSize, setSelectedSize] = useState("");

  const [ean13, setEan13] = useState("");

  useEffect(() => {
    if (codes && codes.length > 1) {
      if (isPack) {
        setEan13(codes.find(e => e.single == false).ean13)
      } else {
        setEan13(codes.find(e => e.single == true).ean13)
      }
    }

  }, [codes, isPack]); // Only re-run the effect if count changes

  const handlePrint = () => {
    setSaving(true);
    printCode(ean13, count, props.article).then(
        () => setSaving(false),
        () => setSaving(false),
    );
  };

  function handleChange(value) {
    if (value == "pack") {
      setIsPack(true)
    } else {
      setIsPack(false);
    }
  }

  function handleSizeChange(value) {
    setSelectedSize(value)
  }

  let availableSizeOptions = sizes.map(size => {
    return <Option key={size.label} value={size.label}>{size.label}</Option>
  })

  let enablePrint = true;
  if (selectedSize == "" && !isPack) {
    enablePrint = false;
  }

  return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
              fullScreen={fullScreen}
              open={showModal}
              aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Распечатать штрих код
            </DialogTitle>
            <DialogContent>
              <Select defaultValue="pack" style={{width: '100%'}} onChange={handleChange}>
                <Option value="pack">Фасовка</Option>
                <Option value="single">Штука</Option>
              </Select>
              <br/>
              <br/>
              {!isPack && (
                  <div>
                    <Select defaultActiveFirstOption style={{width: '100%'}} onChange={handleSizeChange}>
                      {availableSizeOptions}
                    </Select>
                    <br/>
                    <br/>
                  </div>)}

              <label>Штрих код</label>
              <br/>
              <Input value={ean13} disabled={true} style={{width: "100%"}}/>
              <br/> <br/>
              <label>Количество</label>
              <br/>
              <InputNumber
                  value={count}
                  style={{width: "100%"}}
                  onChange={e => {
                    if (e >= 1) setCount(e);
                    else setCount(1);
                  }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                  type="primary"
                  loading={saving}
                  onClick={handlePrint}
                  disabled={!props.article || !enablePrint}
              >
                Распечатать
              </Button>

              <Button onClick={close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
  );
}

PrintCodeModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    showModal: state.scenes.product.showPrintCodeModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShowPrintCodeModal: showPrintCodeModal =>
      dispatch(setShowPrintCodeModal(showPrintCodeModal)),
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PrintCodeModal),
);
