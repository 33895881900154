import { notification } from "antd";
import axios from "axios";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const ME_FROM_TOKEN_SUCCESS = "ME_FROM_TOKEN_SUCCESS";
export const ME_FROM_TOKEN_FAILURE = "ME_FROM_TOKEN_FAILURE";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";

export const USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";

export const RESET_TOKEN = "RESET_TOKEN";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const resetToken = () => {
  //used for logout
  return {
    type: "RESET_TOKEN",
  };
};

export const meFromToken = tokenFromStorage => {
  //check if the token is still valid, if so, get me from the server

  return dispatch => {
    return axios({
      method: "post",
      url: `/api/users/refreshtoken`,
      headers: {
        //'Authorization': `Bearer ${tokenFromStorage}`,
        "Content-Type": "application/json",
      },
      data: { token: tokenFromStorage },
    })
      .then(({ data }) => {
        let token = data;

        localStorage.setItem("jwtToken", data.token);
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({ type: ME_FROM_TOKEN_SUCCESS, data });
        return token;
      })
      .catch(({ response }) => {
        let { data } = response;
        localStorage.removeItem("jwtToken");
        // openNotificationWithIcon('Ошибка', data.errorMessage || "Произошла неизвестная ошибка", 'error');
        dispatch({ type: ME_FROM_TOKEN_FAILURE, data });
        throw data;
      });
  };
};

export const signInUser = user => {
  return dispatch => {
    return axios
      .post("/api/users/signin", user)
      .then(({ data }) => {
        let user = data;
        localStorage.setItem("jwtToken", data.token);
        dispatch({ type: SIGNIN_USER_SUCCESS, data });
        //openNotificationWithIcon('Успешно', "Пользователь успешно добавлен", 'success');
        return data;
      })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchUsers = () => {
  return dispatch => {
    return axios
      .get(`/api/users/`)
      .then(({ data }) => {
        let users = data;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: USERS_FETCH_SUCCESS,
          response: normalize(users, schema.arrayOfUsers),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchUser = userId => {
  return dispatch => {
    return axios
      .get(`/api/users/` + userId)
      .then(({ data }) => {
        let user = data;
        let id = userId;
        //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
        dispatch({
          type: USER_FETCH_SUCCESS,
          response: normalize(user, schema.user),
          id,
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createUser = user => {
  return dispatch => {
    return axios
      .post("/api/users/", user)
      .then(({ data }) => {
        // Status looks good
        let user = data;
        dispatch({
          type: USER_CREATE_SUCCESS,
          response: normalize(user, schema.user),
        });
        openNotificationWithIcon(
          "Успешно",
          "Пользователь успешно добавлен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data, status } = error.response;
          if (status == 409) {
            throw error;
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            throw error;
          }
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const updateUser = (userId, user) => {
  return dispatch => {
    return axios
      .put("/api/users/" + userId, user)
      .then(({ data }) => {
        // Status looks good
        let user = data;
        dispatch({
          type: USER_UPDATE_SUCCESS,
          response: normalize(user, schema.user),
        });
        openNotificationWithIcon(
          "Успешно",
          "Пользователь успешно сохранен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data, status } = error.response;
          if (status == 409) {
            throw error;
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
            throw error;
          }
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteUser = userId => {
  return dispatch => {
    return axios
      .delete("/api/users/" + userId)
      .then(({ data }) => {
        let user = data;
        dispatch({ type: USER_DELETE_SUCCESS, userId });
        openNotificationWithIcon(
          "Успешно",
          "Пользователь успешно удален",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;
          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данные пользователя используются в других записях";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
