import { notification } from "antd";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const generateFabStockEntryData = (
  fabStockEntryId,
  entryDate,
  fabricName,
  contractor,
  weight,
  rolls,
  domain,
) => {
  var entryDate = entryDate.substring(0, 22);
  var contractor = contractor.substring(0, 22);
  var fabricName = fabricName.substring(0, 22);

  let old = `
  ^XA
  ^MMT
  ^PW448
  ^LL0240
  ^LS0
  ^CI28
  ^FT25,226^BQN,2,7
  ^FH\^FDLA,${fabStockEntryId}^FS
  ^FT171,215^A0I,25,24^FH\^FD${domain}^FS
  ^FT447,214^A0I,25,24^FH\^FDПриход: ${fabStockEntryId}^FS
  ^FT448,179^A0I,25,24^FH\^FD${entryDate}^FS
  ^FT447,143^A0I,25,24^FH\^FD${fabricName}^FS
  ^FT448,107^A0I,25,24^FH\^FD${contractor}^FS
  ^FT448,71^A0I,25,24^FH\^FDВес: ${weight} кг^FS
  ^FT447,35^A0I,25,24^FH\^FDРулонов: ${rolls}^FS
  ^FT171,34^A0I,23,24^FH\^FD${fabStockEntryId}^FS
  ^PQ1,0,1,Y^XZ`;

  return old;
};

export const generateProdEntryData = (
  id,
  operation,
  quantity,
  operationType,
  sum,
  size,
  orderNumber,
  product,
  domain,
) => {
  var productName = product.substring(0, 22);
  var operationName = operation.substring(0, 22);

  return `
    ^XA
    ^MMT
    ^PW448
    ^LL0240
    ^LS0
    ^CI28
    ^FT25,226^BQN,2,7
    ^FH\^FDLA,${id}^FS
    ^FT171,215^A0I,25,24^FH\^FD${domain}^FS
    ^FT447,214^A0I,25,24^FH\^FDЗаказ: ${orderNumber}^FS
    ^FT448,179^A0I,25,24^FH\^FD${productName}^FS
    ^FT447,143^A0I,25,24^FH\^FD${operationName}^FS
    ^FT448,107^A0I,25,24^FH\^FDР:${size} ${operationType}^FS
    ^FT448,71^A0I,25,24^FH\^FDКоличество: ${quantity}^FS
    ^FT171,34^A0I,23,24^FH\^FD${id}^FS
    ^PQ1,0,1,Y^XZ`;
};

export const generateCuttingData = (
  id,
  operation,
  quantity,
  operationType,
  sum,
  orderNumber,
  product,
  domain,
) => {
  var productName = product.substring(0, 22);
  var operationName = operation.substring(0, 22);

  // This code is for backup only since it includes sum, NOT used now
  let not_used =
    `
  ^XA
  ^MMT
  ^PW448
  ^LL0240
  ^LS0
  ^CI28
  ^FT25,226^BQN,2,7
  ^FH\^FDLA,${id}^FS
  ^FT171,215^A0I,25,24^FH\^FD${domain}^FS
  ^FT447,214^A0I,25,24^FH\^FDЗаказ: ${orderNumber}^FS
  ^FT448,179^A0I,25,24^FH\^FD${productName}^FS
  ^FT447,143^A0I,25,24^FH\^FD${operationName}^FS
  ^FT448,107^A0I,25,24^FH\^FD${operationType}^FS
  ^FT448,71^A0I,25,24^FH\^FDКоличество: ${quantity}^FS` +
    //`^FT447,35^A0I,25,24^FH\^FDCумма: `+sum+`^FS`+
    `^FT171,34^A0I,23,24^FH\^FD${id}^FS
  ^PQ1,0,1,Y^XZ`;

  return `
    ^XA
    ^MMT
    ^PW448
    ^LL0240
    ^LS0
    ^CI28
    ^FT25,226^BQN,2,7
    ^FH\^FDLA,${id}^FS
    ^FT171,215^A0I,25,24^FH\^FD${domain}^FS
    ^FT447,214^A0I,25,24^FH\^FDЗаказ: ${orderNumber}^FS
    ^FT448,179^A0I,25,24^FH\^FD${productName}^FS
    ^FT447,143^A0I,25,24^FH\^FD${operationName}^FS
    ^FT448,107^A0I,25,24^FH\^FD${operationType}^FS
    ^FT448,71^A0I,25,24^FH\^FDКоличество: ${quantity}^FS
    ^FT171,34^A0I,23,24^FH\^FD${id}^FS
    ^PQ1,0,1,Y^XZ`;
};

export const getDefaultPrinter = () => {
  return (
    fetch(`http://localhost:9100/default?type=printer`)
      // Try to parse the response
      .then(response =>
        response.json().then(json => ({
          status: response.status,
          json,
        })),
      )
      .then(
        // Both fetching and parsing succeeded!
        ({ status, json }) => {
          if (status >= 400) {
            // Status looks bad
            openNotificationWithIcon(
              "Ошибка",
              json.errorMessage || "Произошла неизвестная ошибка1",
              "error",
            );
            throw json;
          } else {
            //let cuttingEntries=json;
            //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
            //dispatch({ type: 'CUTTING_ENTRIES_FETCH_DATA_SUCCESS', response: normalize(cuttingEntries, schema.arrayOfCuttingEntries) })
            return json;
          }
        },
        // Either fetching or parsing failed!
        err => {
          //console.log("Parsing eror: Most likely server crashed");
          openNotificationWithIcon(
            "Ошибка",
            "Ошибка убедитесь что работает Browser Print",
            "error",
          );
          throw "Ошибка убедитесь что работает Browser Print";
        },
      )
  );
};

export const checkAvailable = () => {
  return (
    fetch(`http://localhost:9100/available`)
      // Try to parse the response
      .then(response =>
        response.json().then(json => ({
          status: response.status,
          json,
        })),
      )
      .then(
        // Both fetching and parsing succeeded!
        ({ status, json }) => {
          if (status >= 400) {
            // Status looks bad
            openNotificationWithIcon(
              "Ошибка",
              json.errorMessage || "Произошла неизвестная ошибка1",
              "error",
            );
            throw json;
          } else {
            //let cuttingEntries=json;
            //console.log("NORMALIZED", normalize(materials, schema.arrayOfMaterials));
            //dispatch({ type: 'CUTTING_ENTRIES_FETCH_DATA_SUCCESS', response: normalize(cuttingEntries, schema.arrayOfCuttingEntries) })
            return json;
          }
        },
        // Either fetching or parsing failed!
        err => {
          //console.log("Parsing eror: Most likely server crashed");
          openNotificationWithIcon(
            "Ошибка",
            "Ошибка убедитесь что работает Browser Print",
            "error",
          );
          throw "Ошибка убедитесь что работает Browser Print";
        },
      )
  );
};

export const sendToPrinter = (printer, data) => {
  return (
    fetch("http://localhost:9100/write", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "text/plain;charset=UTF-8",
      },
      body: JSON.stringify({ data: data, device: printer }),
    })
      // Try to parse the response
      .then(response =>
        response.json().then(json => ({
          status: response.status,
          json,
        })),
      )
      .then(
        // Both fetching and parsing succeeded!
        ({ status, json }) => {
          if (status >= 400) {
            // Status looks bad
            openNotificationWithIcon(
              "Ошибка",
              json.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );

            throw json;
          } else {
            // Status looks good
            //let employee=json;
            //dispatch({type: 'EMPLOYEE_CREATE_SUCCESS', response: normalize(employee, schema.employee)})
            //penNotificationWithIcon('Успешно', "Сотрудник успешно добавлен", 'success');
            return json;
          }
        },
        // Either fetching or parsing failed!
        err => {
          openNotificationWithIcon(
            "Ошибка",
            "Ошибка убедитесь что работает Browser Print",
            "error",
          );
          throw "Ошибка убедитесь что работает Browser Print";
        },
      )
  );
};

export const readPrinterStatus = printer => {
  return (
    fetch("http://localhost:9100/read", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "text/plain;charset=UTF-8",
      },
      body: JSON.stringify({ device: printer }),
    })
      // Try to parse the response
      .then(response =>
        response.text().then(text => ({
          status: response.status,
          text,
        })),
      )
      .then(
        // Both fetching and parsing succeeded!
        ({ status, text }) => {
          if (status >= 400) {
            // Status looks bad
            openNotificationWithIcon(
              "Ошибка",
              "Произошла неизвестная ошибка",
              "error",
            );

            throw text;
          } else {
            // Status looks good
            //let employee=json;
            //dispatch({type: 'EMPLOYEE_CREATE_SUCCESS', response: normalize(employee, schema.employee)})
            //penNotificationWithIcon('Успешно', "Сотрудник успешно добавлен", 'success');
            return text;
          }
        },
        // Either fetching or parsing failed!
        err => {
          //console.log(err);
          openNotificationWithIcon(
            "Ошибка",
            "Ошибка убедитесь что работает Browser Print",
            "error",
          );
          throw "Ошибка убедитесь что работает Browser Print";
        },
      )
  );
};

export const decodeStatus = text => {
  var that = this;
  var statuses = new Array();
  var ok = false;
  var is_error = text.charAt(70);
  var media = text.charAt(88);
  var head = text.charAt(87);
  var pause = text.charAt(84);
  // check each flag that prevents printing
  if (is_error == "0") {
    ok = true;
    statuses.push("Ready to Print");
  }
  if (media == "1") statuses.push("Бумага закончилась");
  if (media == "2") statuses.push("Ribbon Out");
  if (media == "4") statuses.push("Открыта крышка принтера");
  if (media == "8") statuses.push("Ошибка каттера");
  if (head == "1") statuses.push("Головка перегрелась");
  if (head == "2") statuses.push("Мотор перегрелся");
  if (head == "4") statuses.push("Ошибка головки");
  if (head == "8") statuses.push("Неправильная головка");
  if (pause == "1") statuses.push("Принтер на паузе");
  if (!ok && statuses.Count == 0) statuses.push("Ошибка: Неизвестная ошибка");

  return statuses.join();
};
