import { combineReducers } from "redux";

const openedSpecId = (state = "", action) => {
  switch (action.type) {
    case "SET_OPENED_SPEC_ID":
      return action.specId;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  openedSpecId,
});
