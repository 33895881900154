import {
  Button,
  Card,
  Select,
  Col,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Table,
  Tabs,
  notification,
} from "antd";
import { message } from "antd";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import orderStatusLabels from "@app/shared/constants/orderStatusLabels";
import PasswordConfirmationModal from "../../../components/PasswordConfirmationModal";
import {
  completeOrder,
  deleteOrderWithRecords,
  discardOrderCompletion,
  fetchOrder,
  updateOrderSpecification, updateOrderStatus,
} from "../../../data/orders/actions";
import { fetchProdDepotLocations } from "../../../data/prodDepotLocations/actions";
import { getAllProdDepotLocations } from "../../../data/prodDepotLocations/reducer";
import { deleteProdEntry } from "../../../data/prodEntries/actions";
import { setShowPasswordConfirmationModal } from "../../Common/actions";
import {
  resetCuttingPassportKey,
  resetCuttingSubmitPassportKey,
  resetSewingPassportKey,
  resetSewingSubmitPassportKey,
  setModalSelectedSpread,
  setOpenedCuttingOrder,
  setOpenedProdEntryId,
  setOrderIdForCompletionModalForCutting,
  setSelectedOrderValue,
  setSelectedProdBomId,
  setShowCompletionModalForCutting,
  setShowCuttingPassportModal,
  setShowCuttingSubmitPassportModal,
  setShowProdEntryModal,
  setShowSewingPassportModal,
  setShowRealPriceCalculationModal,
  resetRealPriceCalculationModalKey,
  setShowSewingSubmitPassportModal,
} from "../actions";
import {
  getOpenedCuttingOrder,
  getOpenedCuttingOrderId,
  getProdBomsByOpenedOrderWithSpreadsPatternsEntries,
  getSelectedProdBomId,
} from "../selectors";
import CompletionOrderModal from "./components/CompletionOrderModal/CompletionOrderModal";
import CuttingOperationsModal from "./components/CuttingOperationsModal";
import CuttingPassportInfo from "./components/CuttingPassportInfo";
import CuttingPassportModal from "./components/CuttingPassportModal";
import CuttingWageModal from "./components/CuttingWageModal";
import ProdBomTabPane from "./components/ProdBomTabPane";
import ProdEntryDetail from "./components/ProdEntryDetail";
import ProdWageModal from "./components/ProdWageModal";
import SewingOperationsModal from "./components/SewingOperationsModal";
import SewingPassportInfo from "./components/SewingPassportInfo";
import SewingPassportModal from "./components/SewingPassportModal";
import CuttingSubmitPassportModal from "./components/SubmitCuttingPassport";
import SewingSubmitPassportModal from "./components/SubmitSewingPassport";
import WageClaimModal from "./components/WageClaimModal";
import RealPriceCalculationModal from "./components/RealPriceCalculationModal";
import {WarningOutlined} from "@ant-design/icons";

const TabPane = Tabs.TabPane;

let prodBomIdFromLocationState = null;
const setProdBomIdFromLocationState = id => {
  prodBomIdFromLocationState = id;
};

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

function CuttingDetail(props) {
  const {
    match,
    setOpenedCuttingOrder,
    setSelectedProdBomId,
    setSelectedOrderValue,
    deleteOrderWithRecords,
    openedOrderId,
    user,
    fetchOrder,
    history,
    location,
    prodBoms,
    selectedProdBomId,
    prodEntryDetailKey,
    cuttingOperationsModalKey,
    cuttingPassportModalKey,
    sewingPassportModalKey,
    cuttingSubmitPassportModalKey,
    sewingSubmitPassportModalKey,
    sewingOperationsModalKey,
    openedOrder,
    updateOrderSpecification,
    discardOrderCompletion,
    prodDepotLocations,
    setShowCompletionModal,
    setOrderIdForCompletionModal,
    completeOrder,
    resetCuttingPassportModalKey,
    resetSewingPassportModalKey,
    resetCuttingSubmitPassportModalKey,
    resetSewingSubmitPassportModalKey,
    setShowCuttingPassportModal,
    setShowCuttingSubmitPassportModal,
    setShowSewingSubmitPassportModal,
    setShowSewingPassportModal,
    resetRealPriceCalculationModalKey,
    setShowRealPriceCalculationModal,
    updateOrderStatus
  } = props;

  const [loading, setLoading] = useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = useState(false);
  //const [prodBomIdFromLocationState, setProdBomIdFromLocationState] = useState(null);

  useEffect(() => {
    fetchProdDepotLocations();
  }, []);

  useEffect(() => {
    //console.log("yes effect is called", location.state.prodBomId);
    if (match.params.orderId) {
      fetchAndSetOpenedOrder(match.params.orderId);
    } else {
      setSelectedProdBomId(null);
      setOpenedCuttingOrder(null);
      setSelectedOrderValue(null);
      setLoading(false);
    }
  }, [match.params.orderId]);

  const fetchAndSetOpenedOrder = orderId => {
    //Need to grab the prodBomId from location if exists and clear history
    setProdBomIdFromLocationState(null);
    if (location.state && location.state.prodBomId) {
      // this.prodBomIdFromLocationState =
      setProdBomIdFromLocationState(location.state.prodBomId);

      props.history.replace({
        pathname: props.location.pathname,
        state: {},
      });
    }

    //check if coming back from cuttingEntryList
    if (prodBomIdExistInRouteState()) {
      setSelectedProdBomId(prodBomIdFromLocationState);
    }

    if (openedOrder && openedOrder.fullyLoaded && isFromCuttingEntryList()) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    fetchOrder(orderId).then(
      order => {
        //set the value for the dropdown of the parent component
        setSelectedOrderValue({
          label: order.orderNumberStr,
          value: order.id,
          ...order,
        });
        setOpenedCuttingOrder(parseInt(orderId));
        if (order && order.prodBoms.length > 0) {
          if (!prodBomIdExistInRouteState())
            setSelectedProdBomId(order.prodBoms[0].id + "");
        }
        setLoading(false);
      },
      error => {},
    );
  };

  const handleCompletion = order => {
    setOrderIdForCompletionModal(order.id);
    setShowCompletionModal(true);
  };

  const prodBomIdExistInRouteState = () => {
    if (prodBomIdFromLocationState == null) {
      return false;
    } else {
      return true;
    }
  };

  const isFromCuttingEntryList = () => {
    return location.state && location.state.isFromCuttingEntryList;
  };

  const handleBomSelection = key => {
    setSelectedProdBomId(key);
  };

  const isUserAdmin = () => {
    if (!user.user) {
      return false;
    }

    let admin = user.user.roles.find(role => role.id == 1);
    if (admin) {
      return true;
    } else {
      return false;
    }
  };

  const handlePermanentDelete = () => {
    deleteOrderWithRecords(openedOrderId).then(deleted => {
      history.push("/production/");
    });
  };

  const openPasswordConfirmationModal = () =>
    props.setShowPasswordConfirmationModal(true);

  const handleUpdateOrderSpec = () => {
    updateOrderSpecification(openedOrderId).then(order => {
      let prodBomId = "";
      if (order.prodBoms.length > 0) {
        prodBomId = order.prodBoms[0].id;
      }
      setSelectedProdBomId(prodBomId);
      message.success("Спецификация обновлена");
    });
  };

  const handleDiscardOrderCompletion = () => {
    discardOrderCompletion(match.params.orderId).then(() => {
      if (match.params.orderId) {
        fetchAndSetOpenedOrder(match.params.orderId);
      }
    });
  };

  const isOrderFullyLoaded = () => {
    return openedOrder && openedOrder.spec != undefined;
  };

  //return <DummyComponent/>
  //console.log("yes inside cuttingDetail")
  if (!match.params.orderId) {
    return <div />;
  }

  if (!isFromCuttingEntryList() && loading) {
    //return <div></div>

    return <DummyComponent isAdmin={isUserAdmin()} />;
  }

  if (!isOrderFullyLoaded()) {
    return <div />;
  }

  let tabPanes = prodBoms.map((prodBom, index) => {
    return (
      <TabPane key={prodBom.id + ""} tab={prodBom.name}>
        <ProdBomTabPane key={index} prodBom={prodBom} />
      </TabPane>
    );
  });

  const openCuttingPassportModal = () => {
    resetCuttingPassportModalKey();
    setShowCuttingPassportModal(true);
  };

  const openSewingPassportModal = () => {
    resetSewingPassportModalKey();
    setShowSewingPassportModal(true);
  };

  const openRealPriceCalculationModal = () => {
    resetRealPriceCalculationModalKey();
    setShowRealPriceCalculationModal(true);
  }

  const openCuttingSubmitPassportModal = () => {
    resetCuttingSubmitPassportModalKey();
    setShowCuttingSubmitPassportModal(true);
  };

  const openSewingSubmitPassportModal = () => {
    resetSewingSubmitPassportModalKey();
    setShowSewingSubmitPassportModal(true);
  };

  const submitPassportMenu = (
    <Menu>
      <Menu.Item onClick={() => openCuttingSubmitPassportModal()}>
        Раскройный
      </Menu.Item>
      <Menu.Item
        disabled={!openedOrder.cuttingPassportEntry}
        onClick={() => openSewingSubmitPassportModal()}
      >
        Швейный
      </Menu.Item>
    </Menu>
  );
  const printPassportMenu = (
    <Menu>
      <Menu.Item onClick={() => openCuttingPassportModal()}>
        Раскройный
      </Menu.Item>
      <Menu.Item
        disabled={!openedOrder.cuttingPassportEntry}
        onClick={() => openSewingPassportModal()}
      >
        Швейный
      </Menu.Item>
    </Menu>
  );

  const passportModeModals = openedOrder && (
    <>
      <CuttingPassportModal
        key={cuttingPassportModalKey}
        productionProcessType_id={openedOrder.productionProcessType_id}
      />
      {openedOrder.cuttingPassportEntry && (
        <SewingPassportModal
          key={sewingPassportModalKey}
          productionProcessType_id={openedOrder.productionProcessType_id}
        />
      )}
      <CuttingSubmitPassportModal
        key={cuttingSubmitPassportModalKey}
        productionProcessType_id={openedOrder.productionProcessType_id}
      />
      <SewingSubmitPassportModal
        key={sewingSubmitPassportModalKey}
        productionProcessType_id={openedOrder.productionProcessType_id}
      />
    </>
  );

  return (
    <div className="mt-content">
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div style={{}}>
          <label>Заказ номер:</label> {openedOrder.orderNumberStr}
          <br />
          <label>Модель: </label> {openedOrder.product.name}
          <br />
          <label>Артикул: </label> {openedOrder.product.article}
          <br />
          {openedOrder.cuttingPassportEntry && openedOrder.cuttingPassportEntry.realPrice &&
          (<>
            <label>Рассчётная стоимость: </label> {openedOrder.product.estimatedPrice} $
            <br />
            <label>Реальная стоимость: </label>
            <span
              style={{color: openedOrder.product.estimatedPrice < openedOrder.cuttingPassportEntry.realPrice ?
                    "red" : "green"
              }}
            >
              {Number(openedOrder.cuttingPassportEntry.realPrice).toFixed(2)}
            </span> $
            <br />
          </>)
          }
          &nbsp;
        </div>
        <PasswordConfirmationModal onSuccess={handleUpdateOrderSpec} />

        <div>
          <Select
              placeholder={"Статус"}
              value={openedOrder.orderStatus_id}
              onChange={(id) => updateOrderStatus(openedOrder.id, id)}
              style={{
                width: "200px"
              }}
          >
            {Object.keys(orderStatusLabels).map(status_id => (
                <Select.Option value={Number(status_id)}>
                  {orderStatusLabels[status_id]}
                </Select.Option>
            ))}
          </Select>
          {isUserAdmin() &&
            <Dropdown
                overlay={
                  <Menu>
                    {!openedOrder.is_completed && (
                        <Menu.Item onClick={() => handleCompletion(openedOrder)}>
                          Завершить заказ
                        </Menu.Item>
                    )}
                    {openedOrder.is_completed && (
                        <Menu.Item onClick={null}>
                          <Popconfirm
                              title={
                                "Вы действительно хотите отменить завершение заказа?"
                              }
                              onConfirm={() => handleDiscardOrderCompletion()}
                              onCancel={null}
                          >
                            Отменить завершение заказа
                          </Popconfirm>
                        </Menu.Item>
                    )}
                    {!openedOrder.is_completed ? (
                        <Menu.Item onClick={null}>
                          <Popconfirm
                              title="Уверены что хотите обновить спецификацию?"
                              onConfirm={openPasswordConfirmationModal}
                              onCancel={null}
                              okText="Да"
                              cancelText="Нет"
                          >
                            Обновить спецификацию
                          </Popconfirm>
                        </Menu.Item>
                    ) : (
                        <Menu.Item onClick={null} disabled={true}>
                          Обновить спецификацию
                        </Menu.Item>
                    )}
                    {!openedOrder.is_completed ? (
                        <Menu.Item onClick={null}>
                          <Popconfirm
                              title="Уверены что хотите удалить эту запись?"
                              onConfirm={handlePermanentDelete}
                              onCancel={null}
                              okText="Да"
                              cancelText="Нет"
                          >
                            Удалить все
                          </Popconfirm>
                        </Menu.Item>
                    ) : (
                        <Menu.Item onClick={null} disabled={true}>
                          Удалить все
                        </Menu.Item>
                    )}
                  </Menu>
                }
                placement="bottomCenter"
            >
              <Button style={{ marginLeft: "0.5em" }}>...</Button>
            </Dropdown>
          }
        </div>
      </div>
      {openedOrder && openedOrder.productionProcessType_id === 1 && (
        <Tabs
          activeKey={selectedProdBomId + ""}
          onTabClick={handleBomSelection}
          type="card"
          animated={false}
        >
          {tabPanes}
        </Tabs>
      )}
      {openedOrder && [2, 3].includes(openedOrder.productionProcessType_id) && (
        <div>
          <span>
            <Dropdown overlay={printPassportMenu}>
              <Button type={"default"} size={"default"}>
                Распечатать паспорт
              </Button>
            </Dropdown>
            <Dropdown overlay={submitPassportMenu}>
              <Button
                style={{
                  float: "right",
                  marginLeft: "10px"
                }}
                type={"primary"}
                size={"default"}
              >
                Принять паспорт
              </Button>
            </Dropdown>
            {openedOrder && openedOrder.cuttingPassportEntry &&
              (<Button
                  style={{
                    float: "right",
                  }}
                  danger={!openedOrder.cuttingPassportEntry.realPrice}
                  icon={openedOrder.cuttingPassportEntry.realPrice ? null
                      : <WarningOutlined style={{color: "red"}} /> }
                  onClick={openRealPriceCalculationModal}
              >
                {openedOrder.cuttingPassportEntry.realPrice ? "Пересчитать стоимость модели"
                    : "Рассчитать стоимость модели"}
              </Button>)
            }
          </span>
          <div>
            <br />
            <CuttingPassportInfo />
            <br />
            <SewingPassportInfo />
          </div>
        </div>
      )}
      {passportModeModals}
      <ProdEntryDetail key={prodEntryDetailKey} />
      <CuttingWageModal />
      <WageClaimModal />
      <ProdWageModal />
      <CuttingOperationsModal key={cuttingOperationsModalKey} />
      <SewingOperationsModal key={sewingOperationsModalKey} />
      <CompletionOrderModal
        prodDepotLocationOptions={prodDepotLocations}
        completeOrder={completeOrder}
        fetchAndSetOpenedOrder={fetchAndSetOpenedOrder}
      />
      {openedOrder && openedOrder.cuttingPassportEntry && <RealPriceCalculationModal />}
    </div>
  );
}

const DummyComponent = ({ loading, isAdmin }) => (
  <div className="mt-content">
    <Row>
      <Col span={12}>
        <label className="af-skeleton">Заказ номер: asdfas</label>
        <br />
        <label className="af-skeleton">Модель: asdgegharewfrg</label>
        <br />
        <label className="af-skeleton">Артикул: gaseg</label>
        <br />
        &nbsp;
      </Col>

      {isAdmin && (
        <Row type="flex" justify="end">
          <Button disabled type="danger">
            Удалить вместе с записями
          </Button>
        </Row>
      )}
    </Row>

    <Tabs defaultActiveKey="1" type="card">
      <TabPane tab="Загрузка" key="1">
        <br />
        <Card
          loading
          title={" "}
          extra={<Button type="primary">Добавить запись</Button>}
        />
        <br />
        <Card loading title={" "} />
      </TabPane>
      {/* <TabPane tab="Tab 2" key="2">Content of Tab Pane 2</TabPane> */}
    </Tabs>
  </div>
);

const mapStateToProps = (state, ownProps) => {
  const domainSettings = state.domainSettings;
  return {
    productionProcessType: domainSettings
      ? domainSettings.find(setting => setting.id === 1).value
      : null,
    openedOrder: getOpenedCuttingOrder(state),
    openedOrderId: getOpenedCuttingOrderId(state),
    prodEntryDetailKey: state.scenes.cutting.prodEntryDetailKey,
    cuttingPassportModalKey: state.scenes.cutting.cuttingPassportKey,
    cuttingSubmitPassportModalKey:
      state.scenes.cutting.cuttingSubmitPassportModalKey,
    cuttingOperationsModalKey: state.scenes.cutting.cuttingOperationsModalKey,
    sewingOperationsModalKey: state.scenes.cutting.sewingOperationsModalKey,
    prodBoms: getProdBomsByOpenedOrderWithSpreadsPatternsEntries(state),
    selectedProdBomId: getSelectedProdBomId(state),
    user: state.data.users.user,
    prodDepotLocations: getAllProdDepotLocations(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowPasswordConfirmationModal: show =>
      dispatch(setShowPasswordConfirmationModal(show)),
    setModalSelectedSpread: spreadId =>
      dispatch(setModalSelectedSpread(spreadId)),
    setSelectedProdBomId: prodBomId =>
      dispatch(setSelectedProdBomId(prodBomId)),
    setOpenedProdEntryId: entryId => dispatch(setOpenedProdEntryId(entryId)),
    setShowProdEntryModal: show => dispatch(setShowProdEntryModal(show)),
    deleteProdEntry: prodEntry => dispatch(deleteProdEntry(prodEntry)),
    deleteOrderWithRecords: id => dispatch(deleteOrderWithRecords(id)),
    fetchOrder: id => dispatch(fetchOrder(id)),
    updateOrderSpecification: id => dispatch(updateOrderSpecification(id)),
    updateOrderStatus: (id, statusId) => dispatch(updateOrderStatus(id, statusId)),
    setOpenedCuttingOrder: order => dispatch(setOpenedCuttingOrder(order)),
    setSelectedOrderValue: order => dispatch(setSelectedOrderValue(order)),
    discardOrderCompletion: orderId =>
      dispatch(discardOrderCompletion(orderId)),
    completeOrder: formData => dispatch(completeOrder(formData)),
    fetchProdDepotLocations: () => dispatch(fetchProdDepotLocations()),
    setShowCompletionModal: showCompletionModal =>
      dispatch(setShowCompletionModalForCutting(showCompletionModal)),
    setOrderIdForCompletionModal: orderId =>
      dispatch(setOrderIdForCompletionModalForCutting(orderId)),
    resetCuttingPassportModalKey: () => dispatch(resetCuttingPassportKey()),
    resetCuttingSubmitPassportModalKey: () =>
      dispatch(resetCuttingSubmitPassportKey()),
    resetSewingSubmitPassportModalKey: () =>
      dispatch(resetSewingSubmitPassportKey()),
    setShowCuttingSubmitPassportModal: show =>
      dispatch(setShowCuttingSubmitPassportModal(show)),
    setShowSewingSubmitPassportModal: show =>
      dispatch(setShowSewingSubmitPassportModal(show)),
    setShowCuttingPassportModal: show =>
      dispatch(setShowCuttingPassportModal(show)),
    setShowSewingPassportModal: show =>
      dispatch(setShowSewingPassportModal(show)),
    setShowRealPriceCalculationModal: show =>
        dispatch(setShowRealPriceCalculationModal(show)),
    resetSewingPassportModalKey: () => dispatch(resetSewingPassportKey()),
    resetRealPriceCalculationModalKey: () => dispatch(resetRealPriceCalculationModalKey()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CuttingDetail),
);
