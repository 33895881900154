import { message } from "antd";
import PropTypes from "prop-types";
import React, { Component, useState } from "react";
import { withRouter } from "react-router";
import RTable from "react-table";

function ReactTable(props) {
  const { data, isLoading } = props;

  //const { a, ...noA } = props;

  return (
    <div>
      <RTable
        ref={props.innerRef}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        className="-striped -highlight"
        data={isLoading ? [] : data}
        getTdProps={(state, rowInfo, column) => {
          //console.log(column);
          if (isLoading) {
            return {
              className: "pt-skeleton",
            };
          } else {
            return {};
          }
        }}
        //columns={columns}
        defaultPageSize={50}
        previousText="Пред"
        nextText="След"
        loadingText="Загрузка..."
        noDataText={isLoading ? "" : "Нет записей"}
        pageText="Страница"
        ofText="из"
        rowsText="строк"
        {...props}
        //loading={props.loading}
      />
    </div>
  );
}

ReactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.forwardRef((props, ref) => (
  <ReactTable innerRef={ref} {...props} />
));

//export default withRouter(ProductionTable);
