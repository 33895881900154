import {Button, Dropdown, Menu, notification} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import ProductTable from "./components/ProductTable";
import { deleteProduct, fetchProducts } from "./data/products/actions";
import { getAllProducts } from "./data/reducer";
import isMobile from "../../utils/mobileCheck";
import XLSX from "xlsx";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const appendCheckSum = (code) => {
  const digits = code.split("").map(c => Number(c));
  const weights = [1,3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];

  const sum = digits.reduce((acc, digit, index) => acc + digit * weights[index], 0);
  const remainder = (sum % 10);
  return code + (remainder > 0 ? 10 - remainder : 0).toString(10);
}

class ProductMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
    }
  }

  componentDidMount() {
    this.props.fetchProducts();
  }

  handleProductDoubleClick = value => {
    this.props.history.push(`/product-admin/` + value.id);
  };

  handleProductRemove = value => {
    this.props.deleteProduct(value.id);
  };

  handleCreate = () => {
    this.props.history.push(`/product-admin/new`);
  };

  generateExcelTable = async rows => {
    try {
      const headers = ["Название модели", "Описание", "Код", "Код (пачка)"];


      const data = rows.map(row => {
        const eanCodes = row.eanCodes || [];
        const singleEan = eanCodes.find(code => code.single);
        const packEan = eanCodes.find(code => !code.single)
        return [
          row.name,
          row.description,
          singleEan ? appendCheckSum(singleEan.ean13) : '',
          packEan ? appendCheckSum(packEan.ean13) : '',
        ]
      })

      data.unshift(headers);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Модели");

      XLSX.writeFile(workbook, "Модели.xlsx");
    } catch (error) {
      openNotificationWithIcon("Ошибка", error.message, "error");
    }
  };

  handleExport = async () => {
    this.setState({ isExporting: true });
    try {
      await this.generateExcelTable(this.props.products);
      this.setState({ isExporting: false });
    } catch (e) {
      this.setState({ isExporting: false });
    }
  };

  render() {
    const hasAccessToModify = this.props.user
      ? this.props.user.roles
          .map(role => role.id)
          .filter(roleId => [1, 2, 3].includes(roleId)).length > 0
      : false;

    return (
      <div>
        <div>
          <h2>Продукция</h2>
          {hasAccessToModify && (
            <Button type="primary" onClick={this.handleCreate}>
              Создать
            </Button>
          )}
          <Button
              onClick={this.handleExport}
              style={{ float: "right" }}
              loading={this.state.isExporting}
          >
            Экспортировать в Excel
          </Button>
          <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => this.props.history.push("/logs/product")}>
                    Посмотреть в журнале
                  </Menu.Item>
                </Menu>
              }
          >
            <Button style={ isMobile() ? { width: "100%", marginTop: "10px" } : {marginLeft: "0.5em"}}>...</Button>
          </Dropdown>
        </div>
        <br />
        <ProductTable
          onEditClick={this.handleProductDoubleClick}
          onRemove={this.handleProductRemove}
          products={this.props.products}
          domainSettings={this.props.domainSettings}
          hasAccessToModify={hasAccessToModify}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    domainSettings: state.domainSettings,
    user: state.data.users.user.user,
    products: getAllProducts(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    fetchProducts: () => dispatch(fetchProducts()),
    deleteProduct: id => dispatch(deleteProduct(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductMain);
