import { combineReducers } from "redux";

import {
  REVISIONS_FETCH_SUCCESS,
  REVISION_CREATE_SUCCESS,
  REVISION_DELETE_SUCCESS,
  REVISION_FETCH_SUCCESS,
  REVISION_UPDATE_SUCCESS,
} from "./actions";

//import { WAGE_ENTRY_CLAIM_SUCCESS, WAGE_ENTRY_UNCLAIM_SUCCESS, WAGE_ENTRY_DELETE_SUCCESS } from '../wages/actions'

const byId = (state = {}, action) => {
  switch (action.type) {
    case REVISIONS_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockRevisions,
      };
    case REVISION_FETCH_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockRevisions,
      };
    case REVISION_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockRevisions,
      };
    case REVISION_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.response.entities.fabStockRevisions,
      };
    case REVISION_DELETE_SUCCESS:
      var filtered = Object.keys(state)
        .filter(key => key != action.revisionId)
        .map(key => ({ [key]: state[key] }));
      if (filtered.length == 0) {
        return {};
      } else {
        return Object.assign(...filtered);
      }

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case REVISIONS_FETCH_SUCCESS:
      return action.response.result;
    case REVISION_CREATE_SUCCESS:
      return [...state, action.response.result];
    case REVISION_DELETE_SUCCESS:
      return [...state.filter(id => id != action.revisionId)];
    default:
      return state;
  }
};

export const fabStockRevisions = combineReducers({
  allIds,
  byId,
});

export const getAllFabStockRevisions = state => {
  //console.log("entrie",state.data.fabDepotEntries);

  return state.scenes.fabStockRevision.data.fabStockRevisions.allIds.map(
    id => state.scenes.fabStockRevision.data.fabStockRevisions.byId[id],
  );
};

export const getFabStockRevision = (state, id) => {
  return state.scenes.fabStockRevision.data.fabStockRevisions.byId[id];
};
