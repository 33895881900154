import { Button, notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const createAttendanceRecord = (employeeId, entry) => {
  //const defaultHeaders = JSON.parse(JSON.stringify(axios.defaults.headers));

  // change something on your instance(s)
  //instance.defaults.headers['Authorization'] = 'XXX'

  //headers.common.Authorization = `JWT ${savedToken}`;
  //instance.defaults.headers.common.Authorization = "asdfasdfasdf";

  // re-set the default settings in the global axios
  //axios.defaults.headers = defaultHeaders;

  return axios
    .post("/api/attendance/" + employeeId, entry, {})
    .then(({ data }) => {
      // Status looks good
      let employee = data;
      // dispatch({ type: EMPLOYEE_CREATE_SUCCESS, response: normalize(employee, schema.employee) })
      openNotificationWithIcon(
        "Успешно",
        "Сотрудник успешно отмечен",
        "success",
      );
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключится к серверу",
          "error",
        );
        throw error;
      }
    });
};
