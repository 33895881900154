import { Field, getIn } from "formik";
import React from "react";

import { FormGroup } from "./FormGroup";

export function FormField({ name, label, render }) {
  return (
    <Field
      name={name}
      render={({ form, field }) => {
        const errorMessage =
          getIn(form.touched, name) && getIn(form.errors, name);

        return (
          <FormGroup name={name} label={label} hasError={Boolean(errorMessage)}>
            {render({ form, field })}

            {errorMessage && <span className="help-block">{errorMessage}</span>}
          </FormGroup>
        );
      }}
    />
  );
}
