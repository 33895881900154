// import React, { Component } from 'react';
// import PropTypes from 'prop-types'
// import { withRouter } from 'react-router'
// import ReactTable from 'react-table'

import { Button, Popconfirm } from "antd";
import ReactTable from "components/ReactTable";
import matchSorter from "match-sorter";
import React, { Component } from "react";
import { withRouter } from "react-router";

function StatsByTeamTable(props) {
  const { data, onEditClick, onRemove, classes, loading } = props;
  const columns = [
    {
      Header: "",
      accessor: "PR",
      maxWidth: 100,
      filterable: false,
      Cell: row => (
        <a
          onClick={e => {
            e.preventDefault();
            onEditClick(row.original, e);
          }}
          href={"/cutting/" + row.original.PR}
        >
          {"PR-" + row.value}
        </a>
      ),
    },
    {
      Header: "Название модели",
      accessor: "name", // String-based value accessors!
      id: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true,
    },
    {
      Header: "Количество",
      accessor: "quantity",
      Cell: props => <span className="text">{props.value}</span>, // Custom cell components!
      filterable: false,
    },
  ];

  if (props.productionProcessType === "barcode") {
    columns.splice(2, {
      Header: "Спецификация",
      accessor: "specification", // String-based value accessors!
      id: "specification",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["specification"] }),
      filterAll: true,
    });
  }

  return (
    <div>
      <ReactTable
        data={data}
        columns={columns}
        isLoading={loading}
        defaultPageSize={20}
      />
    </div>
  );
}

export default withRouter(StatsByTeamTable);
