//import Button from 'material-ui/Button';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Button, Col, Input, InputNumber, Row } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import OperationTable from "../../../../../components/OperationTable";
import { saveCuttingProdOperations } from "../../../../../data/prodOperations/actions";
import {
  setOpenedWageId,
  setShowCuttingOperationsModal,
  setShowCuttingWageModal,
  setShowWageClaimModal,
} from "../../../actions";
import {
  getCuttingWagesForSelectedProdBom,
  getOpenedCuttingOrder,
  getOpenedPattern,
  getProdOperationsForSelectedProdBom,
  getSelectedProdBomId,
} from "../../../selectors";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "800px",
        minWidth: "800px",
      },
      root: {
        alignItems: "initial",

        zIndex: 500,
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class CuttingOperationsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      activeTab: 1,
      operation: "",
      price: "",
      cuttingOperations: [],
    };

    this.state.cuttingOperations = [
      ...this.props.prodOperations.filter(ops => ops.operationType_id == 1),
    ];
  }
  close = () => {
    // this.props.setOpenedPattern("")
    this.props.setShowCuttingOperationsModal(false);
  };

  create = () => {};

  handleCuttingRemove = e => {
    console.log("index is ", e);
    var newArr = [...this.state.cuttingOperations];
    newArr.splice(e, 1);
    this.setState({ cuttingOperations: newArr });
  };

  handleCuttingAdd = () => {
    var cuttingOperation = {
      operation: this.state.operation,
      price: this.state.price,
      operationType_id: 1,
    };
    this.setState(state => ({
      cuttingOperations: state.cuttingOperations.concat(cuttingOperation),
    }));
    this.setState({ operation: "", price: "" });
  };

  handleUpdate = () => {
    const { saveCuttingProdOperations, selectedProdBomId } = this.props;

    let oldProdOperations = this.props.prodOperations.filter(
      ops => ops.operationType_id == 1,
    );

    saveCuttingProdOperations(
      selectedProdBomId,
      this.state.cuttingOperations,
      oldProdOperations,
    ).then(res => {
      this.close();
    });
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  handleNameChange = e => {
    //this.setState({ name: e.target.value });
  };

  handlePriceChange = e => {
    this.setState({ price: e });
  };
  handleOperationChange = e => {
    this.setState({ operation: e.target.value });
  };

  render() {
    const { showModal, openedOrder } = this.props;
    //coming from HOC
    const { fullScreen, classes, wages } = this.props;
    //console.log("cutting wages",wages);

    let disabled = !!openedOrder.is_completed;

    //let cuttingProdOperations=prodOperations.filter(operation=>operation.operationType_id==1);

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
            fullScreen={fullScreen}
            open={showModal}
            //onClose={this.close}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Закройные Операции
            </DialogTitle>
            <DialogContent>
              {/* <div className="form-inline">
                                <div className="form-group">
                                    <label htmlFor="name"> Название детали:</label>&nbsp;&nbsp;
                                    <input placeholder="Введите название" value={this.state.name} onChange={this.handleNameChange} type="text" className="form-control" id="name" />
                                </div>
                            </div> */}

              <Row>
                <Col sm={24}>
                  <label className="mt-label" htmlFor="operation">
                    {" "}
                    Операция:&nbsp;
                  </label>
                  <Input
                    style={{ width: 200 }}
                    placeholder="Операция"
                    value={this.state.operation}
                    onChange={this.handleOperationChange}
                    type="text"
                    id="operation"
                    disabled={disabled}
                  />
                  &nbsp;&nbsp;
                  <label className="mt-label" htmlFor="price">
                    {" "}
                    Цена:&nbsp;
                  </label>
                  <InputNumber
                    placeholder="Цена"
                    value={this.state.price}
                    onChange={this.handlePriceChange}
                    type="text"
                    id="price"
                    disabled={disabled}
                  />
                  &nbsp;&nbsp;
                  <Button
                    disabled={
                      this.state.operation.length <= 0 ||
                      this.state.price <= 0 ||
                      this.state.price == undefined ||
                      disabled
                    }
                    onClick={this.handleCuttingAdd}
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>

              <p />

              <OperationTable
                disabled={disabled}
                operations={this.state.cuttingOperations}
                onRemove={this.handleCuttingRemove}
              />
            </DialogContent>
            <DialogActions>
              {/* {openedPattern == undefined && <Button type="primary" disabled={this.state.name.length <= 0} onClick={this.create}>Создать</Button>}
                        {openedPattern != undefined && <Button type="primary" disabled={this.state.name.length <= 0} onClick={this.handleUpdate}>Сохранить</Button>} */}

              <Button
                disabled={disabled}
                type="primary"
                onClick={this.handleUpdate}
              >
                Сохранить
              </Button>
              <Button onClick={this.close}>Закрыть</Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

CuttingOperationsModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

//export default withMobileDialog()(SpreadDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    // You can now say this.props.books

    //selectedSpreadId: state.scenes.product.selectedSpread,
    //selectedSpreadId: state.scenes.forms.specform.selectedSpread,
    showModal: state.scenes.cutting.showCuttingOperationsModal,
    //openedPattern: getOpenedPattern(state),
    //wages: getCuttingWagesForSelectedProdBom(state),
    prodOperations: getProdOperationsForSelectedProdBom(state),
    selectedProdBomId: getSelectedProdBomId(state),
    openedOrder: getOpenedCuttingOrder(state),
  };
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
  return {
    setShowCuttingOperationsModal: showModal =>
      dispatch(setShowCuttingOperationsModal(showModal)),
    //setShowWageClaimModal: (showModal) => dispatch(setShowWageClaimModal(showModal)),
    saveCuttingProdOperations: (prodBomId, operations, oldProdOperations) =>
      dispatch(
        saveCuttingProdOperations(prodBomId, operations, oldProdOperations),
      ),

    //setOpenedCuttingWageId: (id) => dispatch(setOpenedWageId(id)),
    //addPattern:  (pattern, spreadId) => dispatch(addPattern(pattern, spreadId)),
    //savePattern: ( pattern) => dispatch(savePattern( pattern)),
    //setOpenedPattern: (patternId) => dispatch(setOpenedPattern(patternId))
  };
};

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CuttingOperationsModal),
);
