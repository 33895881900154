import {
  Dialog,
  DialogContent,
  MuiThemeProvider,
  createMuiTheme,
  withMobileDialog,
} from "@material-ui/core";
import React, { useState } from "react";

import { WorkingScheduleForm } from "../../../WorkingSchedule/components/WorkingScheduleForm";
import { WorkingScheduleType } from "../../../WorkingSchedule/helpers/WorkingScheduleTypeHelpers";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      // Name of the styleSheet
      root: { zIndex: 300, alignItems: "initial" },
    },
  },
  typography: { useNextVariants: true },
});

function WorkingScheduleFormDialog({ onSubmit, initialValues, ...props }) {
  const [type, setType] = useState(WorkingScheduleType.CommonWorkingHours);

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog {...props}>
        <DialogContent>
          <WorkingScheduleForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            type={type}
            onTypeChange={nextType => setType(nextType)}
          />
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default withMobileDialog()(WorkingScheduleFormDialog);
