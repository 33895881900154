export const setOpenedShipmentId = shipmentId => ({
  type: "SET_OPENED_SHIPMENT_ID",
  shipmentId,
});

export const setShowShipmentsReportModal = show => ({
  type: "SET_SHOW_SHIPMENTS_REPORT_MODAL",
  show,
});

export const setShowShipmentStatusModal = (show, shipmentId) => ({
  type: "SET_SHOW_SHIPMENT_STATUS_MODAL",
  show,
  shipmentId,
});
