import { notification } from "antd";
import axios from "axios";
import { serialize } from "helpers/utils";
import { normalize } from "normalizr";

import * as schema from "./schema";

export const ATTENDANCE_REPORT_SET_CURRENT_PAGE =
  "ATTENDANCE_REPORT_SET_CURRENT_PAGE";
export const ATTENDANCE_REPORT_SET_PAGE_SIZE =
  "ATTENDANCE_REPORT_SET_PAGE_SIZE";
export const ATTENDANCE_REPORTS_FETCH_SUCCESS =
  "ATTENDANCE_REPORTS_FETCH_SUCCESS";
export const ATTENDANCE_REPORT_FETCH_SUCCESS =
  "ATTENDANCE_REPORT_FETCH_SUCCESS";
export const ATTENDANCE_REPORT_CREATE_SUCCESS =
  "ATTENDANCE_REPORT_CREATE_SUCCESS";
export const ATTENDANCE_REPORT_DELETE_SUCCESS =
  "ATTENDANCE_REPORT_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const setCurrentAttendanceReportPage = currentPage => ({
  type: ATTENDANCE_REPORT_SET_CURRENT_PAGE,
  currentPage,
});

export const setAttendanceReportPageSize = pageSize => ({
  type: ATTENDANCE_REPORT_SET_PAGE_SIZE,
  pageSize,
});

export const fetchAttendanceReports = (type, page, pageSize) => {
  return dispatch => {
    let str = serialize({ page, size: pageSize });
    return axios
      .get(`/api/attendance_report/${type}?${str}`)
      .then(({ data }) => {
        dispatch({
          type: ATTENDANCE_REPORTS_FETCH_SUCCESS,
          page,
          pageSize,
          response: normalize(data.results, schema.arrayOfAttendanceReports),
        });
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }

        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const fetchAttendanceReport = (id, type) => {
  return dispatch => {
    return axios
      .get(`/api/attendance_report/${type}/${id}`)
      .then(({ data }) => {
        dispatch({
          id,
          type: ATTENDANCE_REPORT_FETCH_SUCCESS,
          response: normalize(data, schema.attendanceReport),
        });

        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const createAttendanceReport = values => {
  return dispatch => {
    return axios
      .post(`/api/attendance_report/${values.type}`, values)
      .then(({ data }) => {
        // Status looks good
        dispatch({
          type: ATTENDANCE_REPORT_CREATE_SUCCESS,
          response: normalize(data, schema.attendanceReport),
        });
        openNotificationWithIcon(
          "Успешно",
          "График успешно добавлен",
          "success",
        );
        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          let { data } = error.response;

          if (data.errno && (data.errno == 1062 || data.errno == 19)) {
            let message = "Такой график уже существует";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};

export const deleteAttendanceReport = (id, type) => {
  return dispatch => {
    return axios
      .delete(`/api/attendance_report/${type}/${id}`)
      .then(({ data }) => {
        dispatch({ type: ATTENDANCE_REPORT_DELETE_SUCCESS, id });

        openNotificationWithIcon(
          "Успешно",
          "Запись успешно удалена",
          "success",
        );

        return data;
      })
      .catch(error => {
        if (error.handledGlobally) {
          return Promise.reject();
        }
        if (error.response) {
          //let errorMessage
          let { data } = error.response;

          if (data.errno && (data.errno == 1451 || data.errno == 19)) {
            let message =
              "Удаление невозможно. Данные используются в других записях.";
            openNotificationWithIcon("Ошибка", message, "error");
          } else {
            openNotificationWithIcon(
              "Ошибка",
              data.errorMessage || "Произошла неизвестная ошибка",
              "error",
            );
          }
          throw error;
        } else {
          openNotificationWithIcon(
            "Ошибка",
            "Невозможно подключиться к серверу",
            "error",
          );
          throw error;
        }
      });
  };
};
