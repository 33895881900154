import tables from "@app/shared/constants/airTableSchema";
import { Card, Input } from "antd";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TablePropertiesForm from "./TablePropertiesForm";

function AirTableConfiguration(props) {
  const {
    values,
    handleChangeTableConfig,
    isInEditMode,
    handleChangeApiKeyConfig,
    handleChangeBase,
  } = props;

  return (
    <div style={{ marginTop: "10px" }}>
      <div style={{ marginBottom: "20px", height: "35px" }}>
        <span style={{ display: "inline-block", verticalAlign: "middle" }}>
          API_KEY
        </span>
        <span
          style={{
            width: "300px",
            float: "right",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <Input
            disabled={!isInEditMode}
            value={values["apiKey"]}
            onChange={e => handleChangeApiKeyConfig(e.target.value)}
          />
        </span>
      </div>
      <div style={{ marginBottom: "20px", height: "35px" }}>
        <span style={{ display: "inline-block", verticalAlign: "middle" }}>
          Base
        </span>
        <span
          style={{
            width: "300px",
            float: "right",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <Input
            disabled={!isInEditMode}
            value={values["base"]}
            onChange={e => handleChangeBase(e.target.value)}
          />
        </span>
      </div>
      {tables.map((table, index) => (
        <TablePropertiesForm
          key={index}
          style={{
            marginBottom: "10px",
          }}
          properties={table.metaAndKeys}
          isInEditMode={isInEditMode}
          handleChangeTableConfig={handleChangeTableConfig}
          values={values}
          table={table.tableName}
          keyOfTableName={table.keyOfTableName}
          title={table.titleMeta}
        />
      ))}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const domainSettings = state.domainSettings;
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AirTableConfiguration),
);
