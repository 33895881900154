import { notification } from "antd";
import axios from "axios";

export const CLIENTS_FETCH_SUCCESS = "CLIENTS_FETCH_SUCCESS";
export const CLIENT_FETCH_SUCCESS = "CLIENT_FETCH_SUCCESS";
export const CLIENT_CREATE_SUCCESS = "CLIENTS_CREATE_SUCCESS";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";

const openNotificationWithIcon = (message, description, type) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchClient = id => dispatch =>
  axios
    .get(`/api/clients/` + id)
    .then(({ data }) => {
      dispatch({
        type: CLIENT_FETCH_SUCCESS,
        response: data,
      });

      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const fetchClients = () => dispatch =>
  axios
    .get(`/api/clients/`)
    .then(({ data }) => {
      dispatch({
        type: CLIENTS_FETCH_SUCCESS,
        response: data,
      });

      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        openNotificationWithIcon(
          "Ошибка",
          data.errorMessage || "Произошла неизвестная ошибка",
          "error",
        );
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const updateClient = (id, client) => dispatch =>
  axios
    .post("/api/clients/" + id, client)
    .then(({ data }) => {
      dispatch({
        type: CLIENT_UPDATE_SUCCESS,
        response: data,
      });

      openNotificationWithIcon("Успешно", "Клиент успешно сохранён", "success");
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        if (data.errno && (data.errno == 1062 || data.errno == 19)) {
          let message = "Такой клиент уже существует";
          openNotificationWithIcon("Ошибка", message, "error");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
        }
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const createClient = client => dispatch =>
  axios
    .post("/api/clients/", client)
    .then(({ data }) => {
      dispatch({
        type: CLIENT_CREATE_SUCCESS,
        response: data,
      });

      openNotificationWithIcon("Успешно", "Клиент успешно сохранён", "success");
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;
        if (data.errno && (data.errno == 1062 || data.errno == 19)) {
          let message = "Такой клиент уже существует";
          openNotificationWithIcon("Ошибка", message, "error");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
        }
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });

export const deleteClient = clientId => dispatch =>
  axios
    .delete("/api/clients/" + clientId)
    .then(({ data }) => {
      dispatch({ type: CLIENT_DELETE_SUCCESS, clientId });
      openNotificationWithIcon("Успешно", "Клиент успешно удален", "success");
      return data;
    })
    .catch(error => {
      if (error.handledGlobally) {
        return Promise.reject();
      }
      if (error.response) {
        let { data } = error.response;

        if (data.errno && (data.errno == 1451 || data.errno == 19)) {
          let message =
            "Удаление невозможно, запись используется в платежах или выгрузках";
          openNotificationWithIcon("Ошибка", message, "error");
        } else {
          openNotificationWithIcon(
            "Ошибка",
            data.errorMessage || "Произошла неизвестная ошибка",
            "error",
          );
        }
        throw error;
      } else {
        openNotificationWithIcon(
          "Ошибка",
          "Невозможно подключиться к серверу",
          "error",
        );
        throw error;
      }
    });
