import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ProdStockRevisionDetail from "./components/ProdStockRevisionDetail";
import ProdStockRevisionMain from "./index.js";

class ProdStockRevisionRoute extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/prodstockrevision"
            component={ProdStockRevisionMain}
          />
          <Route
            exact
            path={`${this.props.match.url}/new`}
            component={ProdStockRevisionDetail}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id`}
            component={ProdStockRevisionDetail}
          />
        </Switch>
      </div>
    );
  }
}

export default ProdStockRevisionRoute;
